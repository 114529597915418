import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { useAuth } from '../../context/AuthContext';
import { firestore } from '../../firebase/config';
import { PageTitle } from '../../utils/PageTitle';
import { collection, doc, query, where, onSnapshot, orderBy } from 'firebase/firestore';


const Vineyards_regis = () => {
  PageTitle("Vineyards Registration");
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [vineyardsList, setVineyardsList] = useState([]);
  const [vineyardsStepList, setVineyardsStepList] = useState([]);
  const [selectVineyard, setVineyardValue] = useState('');
  const [selectVineyardName, setVineyardLabel] = useState('');
  const [selectStep, setStepValue] = useState('');
  const [selectStepName, setStepName] = useState('');
  const [userData, setUserData] = useState('');
  const [winegrowerId, setWinegrowerId] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    // Referenties naar firestore database (bv : ophalen van collecties uit firestore db)
    // Alle waarden in de variabelen gaan verloren éénmaal de useEffect volledig uitgevoerd is

    const usersRef = query(
      collection(firestore, 'users'),
      where('email', '==', currentUser.email)
    );

    const unsubscribe = onSnapshot(usersRef, (querySnapshot) => {
      // huidige user opvragen
      const userLoggedIn = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setWinegrowerId(userLoggedIn[0].winegrower);
      setUserData(userLoggedIn[0]);
    });

    // alle wijngaarden van een bepaalde wijnboer opvragen
    const vineyardsRef = collection(firestore, 'winegrowers', winegrowerId, 'vineyards');

    const unsubscribeVineyards = onSnapshot(vineyardsRef, (querySnapshot) => {
      const vineyard = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setVineyardsList(vineyard);
    });

    // opvragen van alle wijngaardstappen
    const vineyardStepRef = query(
      collection(firestore, 'vineyardsteps'),
      orderBy('number')
    );

    const unsubscribeSteps = onSnapshot(vineyardStepRef, (querySnapshot) => {
      const vineyardSteps = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setVineyardsStepList(vineyardSteps);
    });
    return () => {
      unsubscribeVineyards();
      unsubscribeSteps();
      unsubscribe();
    };
}, []);

// Event die uitgevoerd wordt bij het wijzigen van een wijngaard
const VineyardChange = (selection) => {
  setVineyardValue(selection.value);
  setVineyardLabel(selection.label);
};

// Event die uitgevoerd wordt bij het wijzigen van een wijngaardstap
const StepChange = (selection) => {
  setStepValue(selection.value);
  setStepName(selection.label);
};


return (
  <div style={{ position: 'relative', marginTop: '90px' }}>
    <h1>{t("vertaling.vineyard_regis")}</h1>

    <br />
    <div className="vineyardList" style={{ justifyContent: 'center' }}>
      <div className="sixteen wide field history-table zIndex">
        <label
          style={{ width: '50%', marginBottom: '70px', marginTop: '30px' }}
        >
          <p>{t('vertaling.select_vineyard')}</p>
          <Select
            placeholder={t('vertaling.select_vineyard')}
            options={vineyardsList.map((name, id) => ({
              value: name.id,
              label: name.name,
            }))}
            onChange={(selection) => VineyardChange(selection)}
            className="specialty"
            style={{ justifyContent: 'center' }}
            theme={(theme) => ({
              ...theme,
              borderRadius: 5,
              colors: {
                ...theme.colors,
                primary25: '#dacfa4b2',
                primary: '#AC9E66',
              },
            })}
          />
        </label>
      </div>
    </div>

    <div className="vineyardList" style={{ justifyContent: 'center' }}>
      <div className="sixteen wide field history-table">
        <label style={{ width: '50%' }}>
          <p>{t('vertaling.select_vineyardstep')}</p>
          <Select
            placeholder={t('vertaling.select_vineyardstep')}
            options={vineyardsStepList.map((name, id) => ({
              value: name.id,
              label: t(`vertaling.${name.name.replace(/\s/g, '').replace('-', '').replace('/', '')}`)
            }))}
            onChange={(selection) => StepChange(selection)}
            className="specialty"
            theme={(theme) => ({
              ...theme,
              borderRadius: 5,
              colors: {
                ...theme.colors,
                primary25: '#dacfa4b2',
                primary: '#AC9E66',
              },
            })}
          />
        </label>
      </div>
    </div>

    {selectStep !== '' && selectVineyard !== '' ? (
      <Link
        to={{
          pathname: `vineyardsRegistration/new`,
          state: {
            selectStep,
            selectStepName,
            selectVineyard,
            selectVineyardName,
          },
        }}
      >
        <button
          className="ui primary button login"
          style={{
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%',
            marginTop: '30px',
          }}
        >
          {t('vertaling.create_new')}
        </button>
      </Link>
    ) : (
      <button
        className="ui primary button login"
        style={{
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%',
          marginTop: '30px',
        }}
      >
        {t('vertaling.selectvineyardandvineyardstep')}
      </button>
    )}
    <br />
  </div>
);
};

export default Vineyards_regis;