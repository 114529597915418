import React from 'react'
import { Outlet } from 'react-router-dom'
import Nav from './Navbar/Nav'

const Layout = () => {
    return (
        <>
            <Nav />
            <div className="App">
                <Outlet />
            </div>
        </>
    )
}

export default Layout