import React, { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import RequireAuth from './components/RequireAuth';
import './firebase/config';
import { Roles } from './logic/Roles';
import DeleteAccount from './pages/Account/DeleteAccount';
import FinishRegistration from './pages/Account/FinishRegistration';
import Profile from './pages/Account/Profile';
import EditBarrel from './pages/Barrels/EditBarrel';
import Batches from './pages/Batches/Batches';
import EditBatch from './pages/Batches/BatchInfo';
import CellarStepContentDetail from "./pages/CellarSteps/CellarStepContentDetail";
import CellarStepDetail from "./pages/CellarSteps/CellarStepDetail";
import CellarStepsList from "./pages/CellarSteps/CellarStepsList";
import MyCellarSteps from "./pages/CellarSteps/MyCellarSteps";
import NewCellarStep from "./pages/CellarSteps/NewCellarStep";
import NewCellarStepContent from "./pages/CellarSteps/NewCellarStepContent";
import Complete from './pages/CompleteWinegrower';
import CreateBarrel from './pages/Barrels/CreateBarrel';
import CreateBatch from './pages/Batches/CreateBatch';
import CreateCellar from './pages/CreateCellar';
import CreateSpray from './pages/CreateSpray';
import Dashboard from './pages/Dashboard/Dashboard';
import EditRegistration from './pages/Registrations/History/EditRegistration';
import GovernmentDocuments from './pages/Government/GovernmentDocuments';
import AddHarvestRegistration from "./pages/HarvestSteps/AddHarvestRegistration";
import SelectAgriculturalParcelsNewHarvest from "./pages/HarvestSteps/SelectAgriculturalParcelsNewHarvest";
import CreateHarvestYear from './pages/HarvestYears/CreateHarvestYear';
import EditHarvestYear from './pages/HarvestYears/EditHarvestYear';
import HarvestYears from './pages/HarvestYears/HarvestYears';
import History from './pages/Registrations/History/History';
import Login from './pages/Login';
import MobileApps from './pages/MobileApps';
import Barrels from './pages/Barrels/Barrels';
import MyCellars from './pages/MyCellars';
import MyWineries from './pages/MyWineries';
import PageNotFound from './pages/PageNotFound/PageNotFound';
import AgriculturalParcelDetail from './pages/Parcel/AgriculturalParcelDetail';
import MyParcels from './pages/Parcel/MyParcels';
import NewAgriculturalParcel from './pages/Parcel/NewAgriculturalParcel';
import NewParcel from './pages/Parcel/NewParcel';
import ParcelDetails from './pages/Parcel/ParcelDetails';
import Addregistration from './pages/Registrations/Addregistration';
import Cellarsregistration from './pages/Registrations/Cellars_registration';
import CreateCellarRegistration from './pages/Registrations/CreateCellarRegistration';
import CreateVineyardRegistration from './pages/Registrations/CreateVineyardRegistration';
import Vineyardsregistration from './pages/Registrations/Vineyards_registration';
import Resetpassword from './pages/Resetpassword';
import ReviewRegistrations from './pages/ReviewRegistration';
import ReviewRegistration from './pages/ReviewRegistrationDetail';
import TraceBatch from './pages/TraceBatch';
import TraceVineyard from './pages/TraceVineyard';
import CreateGrapeVariety from './pages/Vineyards/CreateGrapeVariety';
import CreateVineyard from './pages/Vineyards/CreateVineyard';
import GrapeVarietyDetail from './pages/Vineyards/GrapeVarietyDetail';
import MyVineyards from './pages/Vineyards/MyVineyards';
import VineyardInfo from './pages/Vineyards/VineyardInfo';
import AddRegistrationVineyardStep from './pages/VineyardStepRegistration/AddRegistrationVineyardStep';
import SelectAgriculturalParcelRegistration from './pages/VineyardStepRegistration/SelectAgriculturalParcelRegistration';
import MyVineyardSteps from './pages/VineyardSteps/MyVineyardSteps';
import NewVineyardStep from './pages/VineyardSteps/NewVineyardStep';
import NewVineyardStepContent from './pages/VineyardSteps/NewVineyardStepContent.js';
import ResetVineyardStepsConfirmation from './pages/VineyardSteps/ResetVineyardStepsConfirmation';
import VineyardStepContentDetail from './pages/VineyardSteps/VineyardStepContentDetail';
import VineyardStepDetail from './pages/VineyardSteps/VineyardStepDetail';
import VineyardStepsList from './pages/VineyardSteps/VineyardStepsList';
import ActivateEmployee from './pages/WinegrowerEmployees/ActivateEmployee';
import Addemployee from './pages/WinegrowerEmployees/AddEmployee';
import DeactivateEmployee from './pages/WinegrowerEmployees/DeactivateEmployee';
import DeleteEmployee from './pages/WinegrowerEmployees/DeleteEmployee';
import Employees from './pages/WinegrowerEmployees/Employees';
import EmployeesDisabledList from './pages/WinegrowerEmployees/EmployeesDisabledList';
import WinegrowerAdd from './pages/Winegrowers/AddWinegrower';
import WinegrowersList from './pages/Winegrowers/WinegrowerList';
import WinegrowerProfile from './pages/Winegrowers/WinegrowerProfile';
import WineryInvitations from './pages/Winery/Invitations';
import './Styles/App.css';
import './Styles/approveUser.css';
import './Styles/index.css';
import AddBatch from "./pages/HarvestSteps/AddBatch";
import AddWinestorage from "./pages/HarvestSteps/AddWinestorage";
import AddLitersWineStorage from "./pages/HarvestSteps/AddLitersWineStorage";
import SelectCellarStep from "./pages/CellarStepRegistration/SelectCellarStep";
import SelectVineyardStep from "./pages/VineyardStepRegistration/SelectVineyardStep";
import CustomNonDbStep from "./pages/CellarStepRegistration/CustomNonDbStep";
import SelectBatch from "./pages/CellarStepRegistration/SelectBatch";
import CustomWoodAging from "./pages/CellarStepRegistration/CustomWoodAging";
import CustomTransferNewBatch from "./pages/CellarStepRegistration/CustomTransferNewBatch";
import CustomMerge from "./pages/CellarStepRegistration/CustomMerge";
import CustomBottling from "./pages/CellarStepRegistration/CustomBottling";
import TraceBatchUpdated from "./pages/TraceBatchUpdated";
import MyHarvestSteps from "./pages/MyHarvestSteps/MyHarvestSteps";
import EditHarvestStep from "./pages/MyHarvestSteps/EditHarvestStep";
import WinegrowerDetailAdmin from './pages/Winegrowers/WinegrowerDetailAdmin';
import RegisterCellarStep from './pages/CellarStepRegistration/RegisterCellarStep';
import WinegrowerDelete from './pages/Winegrowers/WinegrowerDelete';
import BarrelReport from './pages/Reports/BarrelReport';
import ChemicalsImporter from './pages/ChemicalsImporter/ChemicalsImporter'

function App() {
    return (
        <Routes>
            {/* routes with headerbar */}
            <Route path="/" element={<Layout />}>
                {/* public pages */}
                <Route path="login" element={<Login />} />
                <Route exact path="Reset" element={<Resetpassword />} />
                <Route exact path="MobileApp" element={<MobileApps />} />

                {/* pages protected by login & permissionlevel */}
                <Route element={<RequireAuth requiredPermissionLevel={Roles().EMPLOYEE.permissionLevel} />}>
                    <Route path="" element={<Dashboard />} />
                    <Route exact path="FinishRegistration" element={<FinishRegistration />} />
                    <Route exact path="completeWinegrower" element={<Complete />} />
                    <Route exact path="profile" element={<Profile />} />
                    <Route exact path="tracebatch" element={<TraceBatch />} />
                    <Route exact path="tracebatch/:batch" element={<TraceBatch />} />
                    <Route exact path="tracevineyard" element={<TraceVineyard />} />
                    <Route exact path="tracevineyard/:vineyard" element={<TraceVineyard />} />
                    <Route exact path="history" element={<History />} />
                    <Route exact path="invitations" element={<WineryInvitations />} />
                    <Route exact path="MyWineries" element={<MyWineries />} />
                    <Route exact path="harvestRegistration/selectAgriculturalParcels" element={<SelectAgriculturalParcelsNewHarvest />} />
                    <Route exact path="harvestRegistration/addHarvestRegistration/:id" element={<AddHarvestRegistration />} />
                    <Route exact path="/harvestRegistration/addBatch" element={<AddBatch />} />
                    <Route exact path="/harvestRegistration/winestorages" element={<AddWinestorage />} />
                    <Route exact path="/harvestRegistration/addLitersWineStorage" element={<AddLitersWineStorage />} />
                    <Route exact path="/cellarRegistration/selectStep" element={<SelectCellarStep />} />
                    <Route exact path="/vineyardsRegistration/selectVineyardStep" element={<SelectVineyardStep />} />
                    <Route exact path="/cellarRegistration/registerCustomCellarStep" element={<CustomNonDbStep />} />
                    <Route exact path="/cellarRegistration/selectBatch/:stepId" element={<SelectBatch />} />
                    <Route exact path="/cellarRegistration/addWineStorage" element={<AddWinestorage />} />
                    <Route exact path="/cellarRegistration/addLitersWineStorage" element={<AddLitersWineStorage />} />
                    <Route exact path="/cellarRegistration/customWoodAging" element={<CustomWoodAging />} />
                    <Route exact path="/cellarRegistration/customTransferNewBatch" element={<CustomTransferNewBatch />} />
                    <Route exact path="/cellarRegistration/customMerge" element={<CustomMerge />} />
                    <Route exact path="/cellarRegistration/customBottling" element={<CustomBottling />} />
                    <Route exact path="/traceBatchUpdated" element={<TraceBatchUpdated />} />
                    <Route exact path="/traceBatchUpdated/:batch" element={<TraceBatchUpdated />} />
                    <Route exact path="/cellarRegistration/registerCellarStep/:stepId" element={<RegisterCellarStep />} />
                    <Route exact path="/barrelReport" element={<BarrelReport />} />
                    <Route exact path='vineyardstepregistation/selectagriculturalparcel/:id' element={<SelectAgriculturalParcelRegistration />} />
                </Route>

                <Route element={<RequireAuth requiredPermissionLevel={Roles().WINEGROWER.permissionLevel} />}>
                    <Route exact path="employees" element={<Employees />} />
                    <Route exact path="employees/add" element={<Addemployee />} />
                    <Route exact path="employees/disabled" element={<EmployeesDisabledList />} />
                    <Route exact path="employees/deactivate/:email" element={<DeactivateEmployee />} />
                    <Route exact path="employees/activate/:email" element={<ActivateEmployee />} />
                    <Route exact path="employees/delete/:email" element={<DeleteEmployee />} />
                    <Route exact path="batches" element={<Batches />} />
                    <Route exact path="activatewinegrowers/newwinegrower" element={<WinegrowerAdd />} />
                    <Route exact path="history/edit/:id" element={<EditRegistration />} />
                    <Route exact path="vineyardsRegistration" element={<Vineyardsregistration />} />
                    <Route exact path="cellarsRegistration" element={<Cellarsregistration />} />
                    <Route exact path="Addregistration" element={<Addregistration />} />
                    <Route exact path="vineyardsRegistration/new" element={<CreateVineyardRegistration />} />
                    <Route exact path="cellarsRegistration/new" element={<CreateCellarRegistration />} />
                    <Route exact path="reviewregistrations" element={<ReviewRegistrations />} />
                    <Route exact path="mycellars" element={<MyCellars />} />
                    <Route exact path="MyVineyards" element={<MyVineyards />} />
                    <Route exact path="MyParcels" element={<MyParcels />} />
                    <Route exact path="CreateVineyard" element={<CreateVineyard />} />
                    <Route exact path="vineyardinfo/:id" element={<VineyardInfo />} />
                    <Route exact path="creategrapevariety/:id" element={<CreateGrapeVariety />} />
                    <Route exact path="vineyardinfo/grapevarietydetail/:id" element={<GrapeVarietyDetail />} />
                    <Route exact path="mycellars/createCellar" element={<CreateCellar />} />
                    <Route exact path="mycellars/barrels" element={<Barrels />} />
                    <Route exact path="mycellars/barrels/:id" element={<Barrels />} />
                    <Route exact path="mycellars/barrels/create/:id" element={<CreateBarrel />} />
                    <Route exact path="mycellars/barrels/edit/:id" element={<EditBarrel />} />
                    <Route exact path="batchedit/:id" element={<EditBatch />} />
                    <Route exact path="reviewregistration/:id" element={<ReviewRegistration />} />
                    <Route exact path="winegrowers/profile" element={<WinegrowerProfile />} />
                    <Route exact path="winegrowers/profile/:id" element={<WinegrowerDetailAdmin />} />
                    <Route exact path="createbatch" element={<CreateBatch />} />
                    <Route exact path="createspray" element={<CreateSpray />} />
                    <Route exact path="reviewRegistration" element={<ReviewRegistration />} />
                    <Route exact path="myparcels/create" element={<NewParcel />} />
                    <Route exact path="parcels/create" element={<NewParcel />} />
                    <Route exact path="myparcels/details/:id" element={<ParcelDetails />} />
                    <Route exact path="myparcels/myagriculturalparcels/create" element={< NewAgriculturalParcel />} />
                    <Route exact path='myparcels/myagriculturalparcels/create/:id' element={<NewAgriculturalParcel />} />
                    <Route exact path="agriculturalparcel/details/:id/:parcelId/:vineyardId" element={<AgriculturalParcelDetail />} />
                    <Route exact path='vineyardstepregistration/addregistration' element={<AddRegistrationVineyardStep />} />
                    <Route exact path='government' element={<GovernmentDocuments />} />
                    <Route exact path='harvestyears' element={<HarvestYears />} />
                    <Route exact path='harvestyears/create/' element={<CreateHarvestYear />} />
                    <Route exact path='harvestyears/edit/:id' element={<EditHarvestYear />} />
                </Route>

                <Route element={<RequireAuth requiredPermissionLevel={Roles().ADMIN.permissionLevel} />}>
                    <Route exact path="winegrowers/add" element={<WinegrowerAdd />} />
                    <Route exact path="winegrowers/delete/:id" element={<WinegrowerDelete />} />
                    <Route exact path="winegrowers" element={<WinegrowersList />} />
                    <Route exact path='vineyardsteps/editvineyardstep/:id/:stepnumber' element={<VineyardStepContentDetail />} />
                    <Route exact path='vineyardsteps/vineyardstepslist/:id' element={<VineyardStepsList />} />
                    <Route exact path='vineyardsteps/newvineyardstep' element={<NewVineyardStep />} />
                    <Route exact path='vineyardsteps/newvineyardstepcontent/:id' element={<NewVineyardStepContent />} />
                    <Route exact path='vineyardsteps/myvineyardsteps' element={<MyVineyardSteps />} />
                    <Route exact path='vineyardsteps/editvineyardstep/:id' element={<VineyardStepDetail />} />
                    <Route exact path='vineyardsteps/reset' element={<ResetVineyardStepsConfirmation />} />
                    <Route exact path='cellarsteps/mycellarsteps' element={<MyCellarSteps />} />
                    <Route exact path='cellarsteps/newcellarstep' element={<NewCellarStep />} />
                    <Route exact path='cellarsteps/cellarstepslist/:id' element={<CellarStepsList />} />
                    <Route exact path='/cellarsteps/newcellarstepcontent/:id' element={<NewCellarStepContent />} />
                    <Route exact path='/cellarsteps/cellarstepdetail/:id' element={<CellarStepDetail />} />
                    <Route exact path='/cellarsteps/cellarstepcontentdetail/:id/:stepnumber' element={<CellarStepContentDetail />} />
                    <Route exact path="/harvestSteps/myHarvestSteps" element={<MyHarvestSteps />} />
                    <Route exact path="/harvestSteps/editHarvestStep/:stepNumber" element={<EditHarvestStep />} />
                    <Route exact path="/chemicalsImporter" element={<ChemicalsImporter />} />
                </Route>
            </Route>

            {/* routes without headerbar */}
            <Route exact path="/DeleteAccount" element={<DeleteAccount />} />
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    );
}

export default App;