import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { createVineyardDoc } from '../../firebase/vineyard';
import { PageTitle } from '../../utils/PageTitle';
import { useSessionStorage } from '../../logic/useSessionStorage';
import GoBackButton from "../../components/FormComponents/GoBackButton";
import { firestore } from '../../firebase/config';
import { getFirestore, collection, onSnapshot } from 'firebase/firestore';

const CreateVineyard = () => {
    PageTitle("Create Vineyard");
    const navigate = useNavigate();
    const { register, handleSubmit } = useForm();
    const [isLoading, setLoading] = useState([]);
    const [sun, setSun] = useState([]);
    const { i18n } = useTranslation();
    const lang = i18n.language;
    const [selectedSunId, setSelectedSunId] = useState('');
    const params = useParams();
    const [showErrorOrientation, setShowErrorOrientation] = useState('hidden');
    const [currentWinery, setCurrentWinery] = useSessionStorage(
        'currentwinery',
        ''
    );
    const { t } = useTranslation();
    let regLang = '';
    const sunRef = collection(firestore, 'sunorientation');


    // Referenties naar firestore database (bv : ophalen van collecties uit firestore db)
    // Alle waarden in de variabelen gaan verloren éénmaal de useEffect volledig uitgevoerd is

    useEffect(() => {

        //sun orientations
        const unsubscribeSun = onSnapshot(sunRef, (querySnapshot) => {
            const sunorientation = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));

            setSun(sunorientation);
            setLoading(false);
        });
        return () => {
            unsubscribeSun();
        };
    }, []);


    if (lang === 'en') {
        regLang = 'nameEN';
    } else if (lang === 'fr') {
        regLang = 'nameFR';
    } else if (lang === 'nl') {
        regLang = 'nameNL';
    }


    //submitting form
    const onSubmit = async (data) => {
        try {
            setLoading(true);
            if (selectedSunId === '') {
               data.sunorientation = 'ypP8zNVY2WVSLkxyoclL';
            }
            data.sunorientation = selectedSunId;
            setShowErrorOrientation('hidden');
            await createVineyardDoc({ uid: params.id, ...data });
            setLoading(false);
            navigate(`/myVineyards`);
        } catch (error) {
            console.error(error);
        }
    };

    //Sunorientation selected
    const handleSunChange = (selection, action) => {
        setSelectedSunId(selection.value);
    };

    const formClassname = `ui big form twelve wide column ${isLoading ? 'loading' : ''} `;

    // Functie die zorgt voor het weergeven van een formulier waardoor een wijngaard aangemaakt kan worden
    return (
        <div
            className="add-form-container"
            style={{ maxWidth: 960, margin: '50px auto', marginTop: '90px' }}
        >
            <h1>{t("vertaling.new_vineyard")}</h1>
            <GoBackButton />
            <br />
            <div
                className="ui grid stackable"
                style={{ justifyContent: 'center', marginTop: '-50px' }}
            >
                <form
                    className={formClassname}
                    style={{
                        border: '3px solid #B3B3B3',
                        marginTop: '100px',
                        borderRadius: '50px',
                        borderColor: '#ac9e66',
                    }}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <div
                        className="fields"
                        style={{ justifyContent: 'center', marginTop: '20px' }}
                    >
                    </div>

                    <div className="fields" style={{ justifyContent: 'center' }}>
                        <div className="twelve wide field">
                            <label>
                                <p>{t("translation.name")}</p>
                                <input
                                    type="text"
                                    {...register('name')}
                                    htmlFor="name"
                                    required="required"
                                    style={{ textAlign: "center" }} />
                            </label>
                        </div>
                    </div>

                    <div className="fields" style={{ justifyContent: 'center' }}>
                        <div className="twelve wide field">
                            <label>
                                <p>{t("translation.greenArea")}</p>
                                <input
                                    type="text"
                                    {...register('greenArea')}
                                    htmlFor="greenArea"
                                    required="required"
                                    step={0.0001}
                                    style={{ textAlign: "center" }} />
                            </label>
                        </div>
                    </div>

                    <div className="fields" style={{ justifyContent: 'center' }}>
                        <div className="twelve wide field">
                            <label>
                                <p>{t("vertaling.height")}</p>
                                <input
                                    type="number"
                                    {...register('height')}
                                    htmlFor="height"
                                    min="0"
                                    step=".1"
                                    style={{ textAlign: "center" }} />
                            </label>
                        </div>
                    </div>
                    <div className="fields" style={{ justifyContent: 'center' }}>
                        <div className="twelve wide field">
                            <label>
                                <p>{t("vertaling.soil")}</p>
                                <input
                                    type="text"
                                    {...register('soil')}
                                    htmlFor="soil"
                                    style={{ textAlign: "center" }} />
                            </label>
                        </div>
                    </div>
                    <div className="fields" style={{ justifyContent: 'center' }}>
                        <div className="twelve wide field">
                            <label>
                                {t("vertaling.sunorientation")}
                                <Select
                                    placeholder={t('vertaling.selectsunorientation')}
                                    options={sun
                                        .sort((a, b) => (a.order > b.order ? 1 : -1))
                                        .map((name, id) => ({
                                            value: name.id,
                                            label: name[regLang],
                                        }))
                                    }
                                    onChange={(selection, action) =>
                                        handleSunChange(selection, action)
                                    }
                                    className="specialty"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#dacfa4b2',
                                            primary: '#AC9E66',
                                        },
                                    })}
                                />
                            </label>
                            <p className={showErrorOrientation + ' errorMethod'}>
                                {t("vertaling.errorOrientation")}
                            </p>
                        </div>
                    </div>
                    <div className="fields" style={{ justifyContent: 'center' }}>
                        <div className="twelve wide field">
                            <label>
                                <p>{t("vertaling.adress")}</p>
                                <input
                                    type="text"
                                    {...register('adress')}
                                    htmlFor="adress"
                                    style={{ textAlign: "center" }} />
                            </label>
                        </div>
                    </div>
                    <div className="fields" style={{ justifyContent: 'center' }}>
                        <div className="twelve wide field">
                            <label>
                                <p>{t("vertaling.city")}</p>
                                <input
                                    type="text"
                                    {...register('city')}
                                    htmlFor="city"
                                    style={{ textAlign: "center" }} />
                            </label>
                        </div>
                    </div>
                    <div className='fields' style={{ justifyContent: 'center' }}>
                        <div className='twelve wide field'>
                            <label>
                                <p>{t('translation.country')}</p>
                                <input
                                    type='text'
                                    {...register('country')}
                                    htmlFor='country'
                                    style={{ textAlign: "center" }} />
                            </label>
                        </div>
                    </div>

                    <div className="fields">
                        <div className="field" style={{ display: 'none' }}>
                            <label>
                                <p>{t("vertaling.vineyard1")}</p>
                                <input
                                    {...register('winegrower')}
                                    htmlFor="winegrower"
                                    value={currentWinery}
                                    style={{ textAlign: "center" }} />
                            </label>
                            <label>
                                <p>{t("vertaling.sunorientation")}</p>
                                <input
                                    {...register('sunorientation')}
                                    htmlFor="sunorientation"
                                    value={selectedSunId}
                                    readOnly />
                            </label>
                        </div>
                    </div>
                    <button
                        type="submit"
                        className="ui submit large grey button right floated button-vineyards"
                        style={{ float: 'none', marginTop: '30px', width: '25%' }}
                    >
                        {t("vertaling.createvineyard")}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default CreateVineyard;