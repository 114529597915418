import { useSessionStorage } from "../../logic/useSessionStorage";
import { useTranslation } from 'react-i18next';
import { useFirestore } from "../../context/FirestoreContext";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import GoBackButton from "../../components/FormComponents/GoBackButton";
import { updateBatch } from "../../firebase/batch";


const CustomMerge = () => {
    const [cellarRegistrationData, setCellarRegistrationData] = useSessionStorage("cellarReg", "");
    const [batchData, setBatchData] = useSessionStorage("batchData", "");
    const { getCurrentCellarStep } = useFirestore();
    const { getWineStorages } = useFirestore();
    const { getBatchById } = useFirestore();
    const [currentStep, setCurrentStep] = useState(undefined);
    const [wineStorages, setWineStorages] = useState(undefined);
    const { register, handleSubmit } = useForm();
    const formClassname = "ui big form twelve wide column";
    const { currentUser } = useAuth();
    const navigate = useNavigate();
    const [displayInput, setDisplayInput] = useState("none");
    const [currentWinery, setCurrentWinery] = useSessionStorage("currentwinery","");
    let batchesToKeep = [];
    const { t } = useTranslation();

    useEffect(() => {
        const getCurrentCellarStepFromDb = async () => {
            let cellarStep = await getCurrentCellarStep(cellarRegistrationData.stepId, currentWinery);
            setCurrentStep(cellarStep);
        }

        const getWinestoragesFromDb = async () => {
            let listOfWineStorages = await getWineStorages(currentWinery);
            setWineStorages(listOfWineStorages);
        }

        if (currentStep === undefined) getCurrentCellarStepFromDb();
        if (wineStorages === undefined) getWinestoragesFromDb();

    }, [currentStep]);
    const generateKeepBatches = () => {
        let form = [];
        if (cellarRegistrationData.batchesToMerge !== undefined) {


            cellarRegistrationData.batchesToMerge.forEach((batch) => {
                form.push(
                    <div className="fields" style={{ justifyContent: "center", marginTop: "20px" }}>
                        <div className="twelve wide field">
                            <label>
                                <div>
                                    {t("translation.cellarRegistration.keepBatch")} {batch.name}?
                                </div>
                                <input
                                    type="checkbox"
                                    {...register(`keepOldBatch${batch.id}`)}
                                    style={{ marginTop: "10px" }}
                                    onClick={functionDisplayInput} />


                                <div style={{ display: displayInput }}>
                                    {generateWineStorageForm(batch.wineStorages, batch.id)}
                                </div>
                            </label>
                        </div>
                    </div>
                )
            })
        }
        return form
    }

    const generateWineStorageForm = (arrayWineStorages, batchId) => {
        let form = [];
        if (arrayWineStorages !== undefined && wineStorages !== undefined) {
            arrayWineStorages.forEach((arrayWineStorage) => {
                wineStorages.forEach((wineStoragesDb) => {
                    if (arrayWineStorage.id === wineStoragesDb.id) {
                        form.push(
                            <div
                                className="fields"
                                style={{ justifyContent: "center", marginTop: "20px" }}
                            >
                                <div className="twelve wide field">
                                    <label>
                                        {t("translation.cellarRegistration.litersFor")} {wineStoragesDb.name}
                                        <input
                                            type="number"
                                            {...register(`${batchId}::litersFor${wineStoragesDb.id}`)}
                                            min="0" />
                                    </label>
                                </div>

                            </div>
                        )
                    }
                })
            })
        }
        return form;
    }

    function functionDisplayInput() {
        setDisplayInput("block");
    }

    function determineWhichBatchesToKeep(data) {
        for (const [key, value] of Object.entries(data)) {
            if (key.includes("keepOldBatch")) {
                if (value === true) {
                    const batchId = key.substring(12)
                    batchesToKeep.push(batchId);
                }
            }
        }
    }

    const onSubmit = async (data) => {
        determineWhichBatchesToKeep(data)
        let newCellarRegistration = {};
        let newBatch = {};
        newCellarRegistration.stepId = cellarRegistrationData.stepId;
        let dateString = data.date.split("-");
        newCellarRegistration.date = new Date(dateString[0], dateString[1] - 1, dateString[2]);
        newCellarRegistration.SO2GramPerLiter = Number(data.SO2GramPerLiter);
        newCellarRegistration.email = currentUser.email;
        newCellarRegistration.type = "cellarStep";
        newCellarRegistration.visualasition = true;
        newCellarRegistration.waitingForReview = false;

        let sourceBatchesForRegistration = [];
        for (const [key, value] of Object.entries(data)) {
            if (key.includes("::")) {
                const batchId = key.substring(0, 20);
                const wineStorageId = key.substring(31);
                //add source batches to registration
                let sourceBatch = {};
                sourceBatch.id = batchId;
                //update batches in db
                let currentBatch = await getBatchById(batchId, currentWinery);
                let newBatch = { ...currentBatch }
                if (batchesToKeep.includes(currentBatch.id)) {
                    newBatch.wineStorages.forEach((wineStorage) => {
                        if (wineStorage.id === wineStorageId) {
                            wineStorage.litersForReport = value;
                            updateBatch(currentWinery, batchId, newBatch);
                        }
                    })
                } else {
                    newBatch.wineStorages = [];
                    updateBatch(currentWinery, batchId, newBatch);
                }
                let found = false;
                let sourceBatches = [];
                sourceBatches.forEach((batch) => {
                    if (batch.id === sourceBatch.id) {
                        found = true;
                    }
                })
                if (found === false) {
                    sourceBatches.push(sourceBatch);
                }
                for (const batch of sourceBatches) {
                    let currentBatch = await getBatchById(batch.id, currentWinery);
                    let newSourceBatch = { ...currentBatch };
                    let wineStorages = [];
                    currentBatch.wineStorages.forEach((wineStorage) => {
                        let wineStorageObject = {}
                        wineStorageObject.id = wineStorage.id;
                        wineStorageObject.litersForReport = Number(wineStorage.litersForReport);
                        wineStorages.push(wineStorageObject);
                    })
                    newSourceBatch.wineStorages = wineStorages;
                    sourceBatchesForRegistration.push(newSourceBatch);

                }

            }

        }

        for (let i = 0; i < sourceBatchesForRegistration.length; i++) {
            if (sourceBatchesForRegistration[i - 1] !== undefined) {
                if (sourceBatchesForRegistration[i].id === sourceBatchesForRegistration[i - 1].id) {
                    sourceBatchesForRegistration.splice(i - 1, 1);
                }
            }
        }


        //add agricultural parcels to registration
        let agriculturalParcels = [];
        let totalLiters = 0;
        cellarRegistrationData.batchesToMerge.forEach((batch) => {
            batch.wineStorages.forEach((wineStorage) => {
                totalLiters += Number(wineStorage.litersForReport)

            })
        })
        cellarRegistrationData.batchesToMerge.forEach((batch) => {
            let totalLitersBatch = 0;
            //get total liters from batch
            batch.wineStorages.forEach((wineStorage) => {
                totalLitersBatch += Number(wineStorage.litersForReport);
            })
            batch.agriculturalParcels.forEach((agriculturalParcel) => {
                const liters = Number(agriculturalParcel.percentage) * totalLitersBatch / 100;
                let newPercentage = (liters / totalLiters) * 100;
                let newAgriculturalParcel = {};
                newAgriculturalParcel.id = agriculturalParcel.id;
                newAgriculturalParcel.percentage = newPercentage;
                agriculturalParcels.push(newAgriculturalParcel);
            })
        })

        //create new batch
        newBatch.agriculturalParcels = agriculturalParcels;
        newBatch.createDate = new Date(dateString[0], dateString[1] - 1, dateString[2]);
        newBatch.email = currentUser.email;
        newBatch.harvestYearId = cellarRegistrationData.batchesToMerge[0].harvestYearId;
        newBatch.historic = "Created in step merge batches";
        newBatch.name = data.newBatchName;
        setBatchData(newBatch);


        //add harvest year
        newCellarRegistration.harvestYearId = cellarRegistrationData.batchesToMerge[0].harvestYearId;
        newCellarRegistration.sourceBatches = sourceBatchesForRegistration;
        newCellarRegistration.agriculturalParcels = agriculturalParcels;
        setCellarRegistrationData(newCellarRegistration);

        setTimeout(() => {
            navigate("/cellarRegistration/addWineStorage");
        }, 1000);
    }
    return (
        <div>
            {currentStep !== undefined &&
                <div>
                    <h1>
                        {t('translation.cellarRegistration.registrationOf')} {currentStep.nameEN}
                    </h1>
                    <GoBackButton />
                    <br />
                    <br />
                    <div className="ui grid stackable">
                        <form
                            className={formClassname}
                            style={{
                                border: "3px solid #B3B3B3",
                                marginTop: "100px",
                                margin: "auto",
                                borderRadius: "50px",
                                background: "#edeaea",
                                borderColor: "#ac9e66",
                                maxWidth: '960px'
                            }}
                            onSubmit={handleSubmit(onSubmit)}>
                            <div
                                className="fields"
                                style={{ justifyContent: "center", marginTop: "20px" }}
                            >
                                <div className="twelve wide field">
                                    <label>
                                        <p>{t("translation.cellarRegistration.registrationDate")}</p>
                                        <input
                                            type="date"
                                            {...register('date')}
                                            htmlFor="date"
                                            required
                                            style={{ textAlign: "center" }} />
                                    </label>
                                </div>
                            </div>
                            <div
                                className="fields"
                                style={{ justifyContent: "center", marginTop: "20px" }}>
                                <div className="twelve wide field">
                                    <label>
                                        <p>{t("translation.cellarRegistration.newBatchName")}</p>
                                        <input
                                            type="text"
                                            {...register('newBatchName')}
                                            htmlFor="newBatchName"
                                            required
                                            style={{ textAlign: "center" }} />
                                    </label>
                                </div>
                            </div>
                            {generateKeepBatches()}
                            <div className="fields"
                                style={{ justifyContent: "center", marginTop: "20px" }}>
                                <div className="twelve wide field">
                                    <label>
                                        <p>{t("translation.cellarRegistration.possibleAdditionOfSo")}</p>
                                        <input type="number" {...register('SO2GramPerLiter')} min="0" />
                                    </label>
                                </div>
                            </div>
                            <button
                                type="submit"
                                className="ui submit large grey button right floated button-vineyards"
                                style={{ float: "none", marginTop: "10px", width: "25%" }}
                            >
                                {t("translation.words.next")}
                            </button>
                        </form>
                    </div>
                </div>
            }
        </div>
    );
}
export default CustomMerge;