import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import your language files
import en from './lang/en';
import fr from './lang/fr';
import nl from './lang/nl';
import it from './lang/it';

i18n
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    resources: {
      en: {
        translation: en,
      },
      fr: {
        translation: fr,
      },
      nl: {
        translation: nl,
      },
      // it: {
      //   translation: it,
      // },
    },
    lng: 'nl', 
    fallbackLng: 'nl', // use en if detected lng is not available

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
