import AddBatchComponent from "../../components/AddBatchComponent";
import {useEffect, useState} from "react";
import {useSessionStorage} from "../../logic/useSessionStorage";
import {firestore} from "../../firebase/config";
import { collection, doc, query, onSnapshot } from "firebase/firestore";

const AddBatch = () => {

    const [currentWinery, setCurrentWinery] = useSessionStorage("currentwinery", "");
    const [harvestData, setHarvestData] = useSessionStorage("harvestData", "");
    const [agriculturalParcels, setAgriculturalParcels] = useState();

    useEffect(observer => {
        const agriculturalParcelColRef = collection(firestore, "winegrowers", currentWinery, "agriculturalParcels");

        const unsubscribe = onSnapshot(query(agriculturalParcelColRef), (querySnapshot) => {
            const agriculturalParcelsDoc = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data()
            }));

            const agriculturalParcelsFromList = agriculturalParcelsDoc.filter(parcel =>
                harvestData.agriculturalParcels.includes(parcel.id)
            );

            setAgriculturalParcels(agriculturalParcelsFromList);
        })
        return () => {
            unsubscribe();
        };
    }, [])
    return (
        <div>
            {agriculturalParcels !== undefined &&
                <AddBatchComponent agriculturalParcels={agriculturalParcels}/>
            }
        </div>
    );
}

export default AddBatch;