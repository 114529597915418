import { useState, useEffect } from 'react';
import { useFirestore } from '../../context/FirestoreContext';
import { useSessionStorage } from '../../logic/useSessionStorage';
import { useAuth } from '../../context/AuthContext';

export const GovernmentHarvestStockMapping = () => {
    const { currentUser } = useAuth();
    const { currentUserDoc, getWineryDocument, getHarvestYears, getAgriculturalParcels, getParcels, getRegistrations, getCellarSteps, getBatches, getBottleTypes, getCellars } = useFirestore();
    const [currentWinery, setCurrentWinery] = useSessionStorage('currentwinery', '');
    const [currentWineryDoc, setCurrentWineryDoc] = useState(undefined);
    const [harvestYears, setHarvestYears] = useState(undefined);
    const [agriculturalParcels, setAgriculturalParcels] = useState(undefined);
    const [registrations, setRegistrations] = useState(undefined);
    const [cellarSteps, setCellarSteps] = useState(undefined);
    const [batches, setBatches] = useState(undefined);
    const [bottleTypes, setBottleTypes] = useState(undefined);
    const [harvestData, setHarvestData] = useState([]);
    const [bottlingData, setBottlingData] = useState([]);
    const [currentHarvestYear, setCurrentHarvestYear] = useSessionStorage("harvestyear", "");


    const [placeholders, setPlaceholders] = useState({
        Year: '',
        VineyardName: '',
        VineyardAdress: '',
        VineyardTel: '',
        VineyardEmail: '',
        VineyardBusinessNumber: '',
        VineyardWinegrowerName: '',
        harvestData: [],
        TradeAmount: '',
        TradeItems: '',
        TradeKind: '',
        TradePartner: '',
        Day: '',
        Month: ''
    });

    useEffect(() => {
        const fetchUserData = async () => {
            let updatedPlaceholders = { ...placeholders };
            const userDocData = currentUserDoc;

            if (userDocData) {
                const {
                    email,
                    firstname,
                    lastname,
                    wineries
                } = userDocData;

                if (currentWinery) {
                    const wineryData = await getWineryDocument(currentWinery);

                    if (wineryData) {
                        const {
                            adress,
                            name,
                            btw,
                            zip,
                            phone,
                        } = wineryData;

                        updatedPlaceholders.VineyardName = name || updatedPlaceholders.VineyardName;
                        updatedPlaceholders.VineyardAdress = adress || updatedPlaceholders.VineyardAdress;
                        updatedPlaceholders.VineyardTel = phone || updatedPlaceholders.VineyardTel;
                        updatedPlaceholders.VineyardEmail = email || updatedPlaceholders.VineyardEmail;
                        updatedPlaceholders.VineyardWinegrowerName = name || updatedPlaceholders.VineyardWinegrowerName;
                        const businessNumber = btw.replace(/\D/g, '');
                        updatedPlaceholders.VineyardBusinessNumber = businessNumber;
                        const currentDate = new Date();
                        updatedPlaceholders.Year = currentDate.getFullYear();
                        updatedPlaceholders.Month = currentDate.getMonth() + 1; // Months get used 0 based, Jan = 0
                        updatedPlaceholders.Day = currentDate.getDate();

                    }
                    const registrations = await getRegistrations(currentWinery);
                    const agriculturalParcels = await getAgriculturalParcels(currentWinery);
                    const parcels = await getParcels(currentWinery);

                    if (registrations) {
                        const harvestRegistrations = registrations.filter(registration => registration.type === 'harvestStep');

                        const harvestData = harvestRegistrations.map((registration, index) => {

                            const wineKinds = [];
                            const areas = [];
                            const plants = [];
                            const pids= [];

                            registration.agriculturalParcels.forEach(parcel => {
                                const foundAgriculturalParcel = agriculturalParcels.find(ap => ap.id === parcel.id);
                                if (foundAgriculturalParcel) {
                                    if (foundAgriculturalParcel.grapeVariety) {
                                        wineKinds.push(foundAgriculturalParcel.grapeVariety);
                                    }

                                    if(foundAgriculturalParcel.numberOfPlants) {
                                        plants.push(foundAgriculturalParcel.numberOfPlants);
                                    }

                                    if (foundAgriculturalParcel.areaPlantedAre) {
                                        let area = foundAgriculturalParcel.areaPlantedAre;
                                        if (foundAgriculturalParcel.areaPlantedCentiare > 50) {
                                            area += 1; // Round up if centiare is greater than 50
                                        }
                                        // Construct the area description with the adjusted area
                                        areas.push(`${area} are`);

                                        const foundParcels = parcels.find(p => p.id === foundAgriculturalParcel.parcelId);
                                        if (foundParcels.agriculturalRegion && foundParcels.landRegistrationNumber) {
                                            const pid = `${foundParcels.agriculturalRegion}-${foundParcels.landRegistrationNumber}-${area} are`;
                                            pids.push(pid);
                                        }
                                    }
                                }
                            });

                            const aggregatedWineKind = wineKinds.join(', \n');
                            const aggregatedArea = areas.join(', \n');
                            const aggregatedPID = pids.join(', \n');
                            const aggregatedPlants = plants.join(', \n');
                            const totalLiters = registration.wineStorages.reduce((sum, storage) => sum + storage.litersForReport, 0);

                            return {
                                HarvestNr: index + 1 || '',
                                WineKind: aggregatedWineKind || '',
                                Area: aggregatedArea || '',
                                PID: aggregatedPID || '',
                                AmountOfVines: aggregatedPlants || '',
                                HarvestInLiter: totalLiters || '',
                                Color: registration.color || '',
                                Sugar: registration.sugarsForReport || '',
                                Sour: registration.acidityForReport || '',
                            };
                        });
                        updatedPlaceholders.harvestData = harvestData;
                    }
                }
                setPlaceholders(updatedPlaceholders);
            }
        }

        fetchUserData();
    }, [currentWinery]);


    return [placeholders, setPlaceholders];
};