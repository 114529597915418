import React, { useState } from 'react';
import { firestore } from '../../firebase/config'
import { getDocs, query, where, doc, setDoc, updateDoc, writeBatch, collection } from 'firebase/firestore';
import { ChemicalsExcelFilter } from '../ImporterFilter/ChemicalsExcelFilter';
import { useTranslation } from 'react-i18next';
import dutch from '../../lang/nl';
import english from '../../lang/en';
import french from '../../lang/fr';


const languageMappings = {
  dutch: dutch.translation.excelImportKeys,
  english: english.translation.excelImportKeys,
  french: french.translation.excelImportKeys,
};

const languageSuffixMappings = {
  dutch: 'NL',
  english: 'EN',
  french: 'FR',
};

const specificColumns = [
  'product',
  'activeSubstance',
  'nature',
  'formulationType',
  'signalWord',
  'hazardStatements',
  'hazardPictograms'
];

export const ChemicalsExcelImporter = () => {
  const [importedData, setImportedData] = useState([]);
  const [importedLanguage, setImportedLanguage] = useState('');
  const [error, setError] = useState(null);
  const importedLangSuffix = languageSuffixMappings[importedLanguage];
  const { t } = useTranslation();

  const splitDashedString = (str) => {
    if (typeof str !== 'string') {
      str = String(str); // Convert to string to continue processing
    }
    return str.split('- ').filter(statement => statement.trim() !== '');
  };

  const splitSlashedString = (str) => {
    if (typeof str !== 'string') {
      str = String(str); // Convert to string to continue processing
    }
    return str.split('/').filter(statement => statement.trim() !== '');
  };


  const processFilteredData = (filteredData, language) => {
    let mappedData = [];
    const mapping = languageMappings[language];
    const langSuffix = languageSuffixMappings[language];

    const toISOStringIfValid = (date) => {
      if (date && date instanceof Date) {
        return date.toISOString();
      }
      return null;  // Or another default value if preferred.
    };

    mappedData = filteredData.map(row => {
      const newRow = {};
      specificColumns.forEach(key => {
        newRow[`${key}_${langSuffix}`] = row[mapping[key]];
      });

      return {
        ...newRow,
        number: row[mapping.number],
        authorizationHolder: row[mapping.authorizationHolder],
        latestDeliveryCertificate: toISOStringIfValid(row[mapping.latestDeliveryCertificate]),
        initialAuthorizationStart: toISOStringIfValid(row[mapping.initialAuthorizationStart]),
        endOfSalesHolder: toISOStringIfValid(row[mapping.endOfSalesHolder]),
        endOfSalesDistributor: toISOStringIfValid(row[mapping.endOfSalesDistributor]),
        endOfUse: toISOStringIfValid(row[mapping.endOfUse])
      };
    });
    setImportedData(mappedData);
    setImportedLanguage(language);
  };

  const saveToFirebase = async () => {
    const chemicalsCollection = collection(firestore, 'chemicalsData');

    try {
      // Fetch existing chemicals from Firestore
      const snapshot = await getDocs(query(chemicalsCollection));

      // Construct a map to easily check if a chemical (by its number) already exists
      const existingChemicalsMap = {};
      snapshot.docs.forEach(doc => {
        const data = doc.data();
        const number = data.number;
        existingChemicalsMap[number] = {
          ref: doc.ref,
          data: data
        };
      });

      // Initialize variables for batch processing
      const batches = [];        // Will store multiple batches if there are more than 500 writes
      let currentBatch = writeBatch(firestore);
      let batchCounter = 0;

      for (let item of importedData) {
        const sanitizedNumber = encodeURIComponent(item.number.toString());
        if (existingChemicalsMap[item.number]) {  
          const updateData = { };
  
          for (const [key, value] of Object.entries(item)) {
            if (key === 'number') continue;  // Skip the 'number' key
            updateData[key] = value;  // Replace or insert the new key-value pair
          }
  
          currentBatch.update(existingChemicalsMap[item.number].ref, updateData);
          batchCounter++;
        } else {
          const docRef = doc(chemicalsCollection, sanitizedNumber);
          currentBatch.set(docRef, { ...item});
          batchCounter++;
        }

        // If we've added 500 writes to the batch, push it to the batches array and reset (firestore limit)
        if (batchCounter >= 500) {
          batches.push(currentBatch);
          currentBatch = writeBatch(firestore);
          batchCounter = 0;
        }
      }

      // If there are remaining operations after the loop, add them to the batches array
      if (batchCounter > 0) {
        batches.push(currentBatch);
      }

      // Commit (write) all batches to Firestore
      for (let batch of batches) {
        await batch.commit();
      }

      console.log('Data successfully saved/updated in Firestore!');

    } catch (error) {
      // Handle and log any errors
      console.error("Error saving/updating data to Firestore: ", error);
    }
  };


  return (
    <div>
      <ChemicalsExcelFilter onFilteredData={processFilteredData} />
      <br />
      <button onClick={saveToFirebase}>{t('excelImportPage.save')}</button>
      <br />
      {error && <div className="error-message">{error}</div>}
      <br />
      <h3 style={{ color: "#7f7f7f" }}>{t('excelImportPage.preview')}</h3>
      <table border="1">
        <thead>
          {importedData[0] && (
            <tr>
              {Object.keys(importedData[0]).map((key, index) => (
                <th key={index}>{key}</th>
              ))}
            </tr>
          )}
        </thead>
        <tbody>
          {importedData.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {Object.keys(row).filter(key => key.endsWith(importedLangSuffix) || !specificColumns.some(col => key.startsWith(col))).map((key, cellIndex) => (
                <td key={cellIndex}>{row[key]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
