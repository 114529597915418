import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { resetPassword } from "../firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { PageTitle } from "../utils/PageTitle";
import GoBackButton from "../components/FormComponents/GoBackButton";

const Resetpassword = () => {
    PageTitle("Reset Password");
    const { currentUser } = useAuth();
    const navigate = useNavigate();
    const { register, handleSubmit } = useForm();
    const [isLoading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const { t } = useTranslation();
    var placeholder = t('vertaling.placeholder_reset');

    if (currentUser !== null) {
        navigate("/");
    }

    //submit form
    const onSubmit = async (data) => {
        let email;
        try {
            setLoading(true);
            setMessage("");
            setError("");
            email = await resetPassword(data.email);
            setMessage(t('vertaling.succes_reset'));
        } catch (error) {
            console.error(error);
            setError(t("vertaling.error_reset"));
        }
    };

    // Het weergeven van alle gegevens die nodig zijn om het wachtwoord te herstellen

    return (
        <div>
            <section className="banner">
                <br />
                <div className="bannertext">
                <h2 style={{ justifyContent: "center", color: "#AC9E66" }}> {t('vertaling.title_reset')} </h2>

                    <div
                        className="login-container"
                        style={{ backgroundColor: "#AC9E66", marginTop: "0px" }}
                    >
                        <div className="ui card login-card">
                            <div className="content login-content">
                                {error && <h5 variant="danger">{error}</h5>}
                                {message && <h5 variant="success">{message}</h5>}
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div
                                        className="input-div login-div one"
                                        style={{ marginTop: "30px" }}
                                    >
                                        <div className="i">
                                            <i className="fas fa-user"></i>
                                        </div>
                                        <div className="div">
                                            <input
                                                type="email"
                                                {...register('email')}
                                                placeholder={placeholder}
                                                className="input" />
                                        </div>
                                    </div>

                                    <div className="field actions" style={{ textAlign: "right" }}>
                                        <Link to="/login" style={{ color: "white" }}>
                                            <GoBackButton />
                                        </Link>
                                        <button
                                            className="ui primary button nav-button-border"
                                            type="submit"
                                        >
                                            {t("vertaling.title_reset")}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Resetpassword;