// Importeren van React packages en bibliotheken en andere React componenten

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useFirestore } from '../../context/FirestoreContext';
import { createWineryDocument } from '../../firebase/winegrower';
import { PageTitle } from '../../utils/PageTitle';
import GoBackButton from "../../components/FormComponents/GoBackButton";
import Spinner from "../../components/Spinner/Spinner";

const WinegrowerAdd = () => {
    PageTitle("Add Winegrower");
    const { register, handleSubmit } = useForm();
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { currentUserDoc } = useFirestore();
    const { t } = useTranslation();

    // Referenties naar firestore database (bv : ophalen van collecties uit firestore db)
    // Alle waarden in de variabelen gaan verloren éénmaal de useEffect volledig uitgevoerd is

    const onSubmit = async (data) => {
        setLoading(true)
        try {
            var winegrower = {
                name: data.winegrower_name,
                phone: data.phone,
                active: true,
                approved: true,
                city: data.city,
                adress: data.adress,
                country: data.country,
                zip: Number(data.zip),
                btw: data.btw,
                excise: data.excise,
                website: data.website,
            };

            await createWineryDocument(winegrower);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
            navigate('/winegrowers', { replace: true });
        }
    };

    // Het weergeven van alle gegevens van een wijnboer

    return <>
        <Spinner isLoading={isLoading} />
        <div className="add-form-container" style={{ maxWidth: 960, margin: '50px auto', marginTop: '90px' }}>
            <div className={`ui grid stackable `}>
                <form className="ui big form twelve wide column" onSubmit={handleSubmit(onSubmit)}>
                    <h2>{t("vertaling.title_registratie")}</h2>
                    <GoBackButton />
                    <br />


                    <div className="fields">
                        <div className="eight wide field">
                            <label>
                                {t("vertaling.winery_name")}
                                <input {...register('winegrower_name')} htmlFor="winegrower_name" required />
                            </label>
                        </div>
                        <div className="eight wide field">
                            <label>
                                {t("vertaling.companyTel")}
                                <input type="tel" {...register('phone')} htmlFor="phone" required />
                            </label>
                        </div>
                    </div>

                    <div className="fields">
                        <div className="eight wide field">
                            <label>
                                {t("vertaling.adress")}
                                <input type="text" {...register('adress')} htmlFor="adress" required />
                            </label>
                        </div>
                        <div className="eight wide field">
                            <label>
                                {t("vertaling.city")}
                                <input type="text" {...register('city')} htmlFor="city" required />
                            </label>
                        </div>
                    </div>

                    <div className="fields">
                        <div className="eight wide field">
                            <label>
                                {t("vertaling.companyZip")}
                                <input type="number" {...register('zip')} htmlFor="zip" required />
                            </label>
                        </div>

                        <div className="eight wide field">
                            <label>
                                {t("vertaling.companyCountry")}
                                <input type="text" {...register('country')} htmlFor="country" required />
                            </label>
                        </div>
                    </div>

                    <div className="fields">
                        <div className="eight wide field">
                            <label>
                                {t("vertaling.companyVat")}
                                <input type="text" {...register('btw')} htmlFor="btw" required />
                            </label>
                        </div>

                        <div className="eight wide field">
                            <label>
                                {t("vertaling.companyExcise")}
                                <input type="text" {...register('excise')} htmlFor="excise" required />
                            </label>
                        </div>
                    </div>

                    <div className="sixteen wide field">
                        <label>
                            {t("vertaling.companyWebsite")}
                            <input type="text" {...register('website')} htmlFor="website" required />
                        </label>
                    </div>

                    <button type="submit" className="ui submit large grey button right floated">
                        {t("vertaling.create_winegrower")}
                    </button>
                </form>
            </div>
        </div>
    </>;
};

export default WinegrowerAdd;