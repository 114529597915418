import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { PageTitle } from '../utils/PageTitle';
import { useSessionStorage } from '../logic/useSessionStorage';
import '../Styles/TraceVineyard.css';
import GoBackButton from "../components/FormComponents/GoBackButton";
import { useLocation } from 'react-router-dom';
import Spinner from "../components/Spinner/Spinner";
import { useFirestore } from '../context/FirestoreContext';

const TraceVineyard = (props) => {
    PageTitle("Trace Vineyard");
    const params = useParams();
    const [currentWinery, setCurrentWinery] = useSessionStorage('currentwinery', '');
    const location = useLocation();
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const lang = i18n.language;

    const { getVineyards } = useFirestore();
    const [vineyards, setVineyards] = useState([]);

    const { getVineyardSteps } = useFirestore();
    const [vineyardSteps, setVineyardSteps] = useState([]);

    const { getAgriculturalParcels } = useFirestore();
    const [agriculturalParcels, setAgriculturalParcels] = useState([]);

    const { getRegistrations } = useFirestore();
    const [allRegistrations, setAllRegistrations] = useState([]);

    const [fyto, setFyto] = useState(false);
    const [openStates, setOpenStates] = useState({});
    const [selectedVineyard, setSelectedVineyard] = useState(null);
    const [regOfSelectedVineyard, setRegOfSeletectVineyard] = useState([]);
    const [filteredRegistrations, setFilteredRegistrations] = useState([]);
    const [firstDate, setFirstDate] = useState(location.state?.firstdate);
    const [lastDate, setLastDate] = useState(location.state?.lastdate || new Date().toISOString().split("T")[0]);
    const [isLoading, setLoading] = useState(false);


    const fytoReg = ['Crop protection', 'Fertilization', 'Treating Biostimulants or Others'];
    const regExeption = [...fytoReg, 'Irrigation']
    const excludeKeys = ["email", "stepId", "agriculturalParcelId", "date", "name", "harvestYearId", "imageUrl", "accuracy", "id", "type", "origin", "location", "visualisation", "waitingForReview", "agriculturalParcels"];
    const computeValues = ["weighedQuantity", "weighedMeasuredQuantity", "litersOfWater", "waterTreatment", "areaToBeTreated", "areaTreated", "yearPlanted"]

    let currentLang = "textNL";
    let currentLangStepName = "nameNL";

    useEffect(() => {
        const getVineyardStepsFromDB = () => {
            return getVineyardSteps(currentWinery);
        };
        let vineyardsteps = getVineyardStepsFromDB();
        setVineyardSteps(vineyardsteps);
    }, [currentWinery, getVineyardSteps])

    useEffect(() => {
        const getRegistrationsFromDB = () => {
            return getRegistrations(currentWinery);
        };
        let registrations = getRegistrationsFromDB();
        setAllRegistrations(registrations);
    }, [currentWinery, getRegistrations])

    useEffect(() => {
        const getAgriculturalParcelsFromDB = () => {
            return getAgriculturalParcels(currentWinery);
        };
        let agriculturalParcels = getAgriculturalParcelsFromDB();
        setAgriculturalParcels(agriculturalParcels);
    }, [currentWinery, getAgriculturalParcels])

    useEffect(() => {
        const getVineyardsFromDB = () => {
            return getVineyards(currentWinery);
        };
        let vineyards = getVineyardsFromDB();
        setVineyards(vineyards);
    }, [currentWinery, getVineyards])

    // useEffect that bind parcel name to registrations and filter them by vineyards
    useEffect(() => {
        const setRegistrations = async () => {
            let filterRegs = [];
            const filterParcels = agriculturalParcels.filter(parcel => parcel.vineyardId === selectedVineyard.value);
            filterParcels.forEach(parcel => {
                let regsOfSelectedVineyardByParcel = allRegistrations.filter(reg => parcel.id === reg.agriculturalParcelId);
                regsOfSelectedVineyardByParcel.forEach(reg => {
                    reg["agriculturalParcelName"] = parcel.name;
                });
                filterRegs.push(...regsOfSelectedVineyardByParcel);
            });
            setRegOfSeletectVineyard(filterRegs);
        }
        setRegistrations();
    }, [selectedVineyard])

    useEffect(() => {
        if (lang === 'en') {
            currentLang = "textEN";
            currentLangStepName = "nameEN";
        } else if (lang === 'fr') {
            currentLang = "textFR";
            currentLangStepName = "nameFR";
        } else if (lang === 'it') {
            currentLang = "textIT";
            currentLangStepName = "nameIT";
        } else if (lang === 'nl') {
            currentLang = "textNL";
            currentLangStepName = "nameNL";
        }

        let tempFilteredRegistrations = regOfSelectedVineyard
            .filter(registration => {
                const registrationDate = convertTimestampToDate(registration.date);
                const startDate = new Date(firstDate);
                const endDate = new Date(lastDate);
                endDate.setHours(23, 59, 59, 999);
                return registrationDate >= startDate && registrationDate <= endDate;
            })
            .filter(registration => {
                if (fyto) {
                    // Check if a registration is a fyto one
                    const nameEn = vineyardSteps.find(step => step.id === registration.stepId)['nameEN'];
                    return fytoReg.includes(nameEn);
                }
                return true;
            })
            .sort((a, b) => {
                const dateA = convertTimestampToDate(a.date).getTime();
                const dateB = convertTimestampToDate(b.date).getTime();
                return dateB - dateA; // Sort in descending order
            })
            .map(reg => {
                let data = new Map();
                // hardcoded tags, either there is no translation, either it is a different layer than the others
                
                data.set("name", vineyardSteps.find(step => step.id === reg.stepId)?.[currentLangStepName]);
                data.set("email", reg.email)
                data.set("imageUrl", reg.imageUrl)
                data.set("stepId", reg.stepId)
                data.set("agriculturalParcelName", reg.agriculturalParcelName)
                if (reg.yearPlanted !== undefined) {
                    data.set("yearPlanted", reg.yearPlanted)
                }
                if (reg.rows !== undefined) {
                    data.set("rows", reg.rows)
                }

                // Get the step's tags translation
                const stepContent = vineyardSteps.find(step => step.id === reg.stepId)?.content;
                const stepContGroupedByKey = stepContentGroupedByKey(stepContent);

                // Convert the object into an array to sort fields by order
                let stepContGroupedByKeyArray = Object.entries(stepContGroupedByKey);
                stepContGroupedByKeyArray.sort((a, b) => a[1][0].order - b[1][0].order);

                // Get the common keys for translation
                let commonKeys = stepContGroupedByKeyArray.map(([key, _]) => key);
                // Bind the tags translation 
                commonKeys.forEach(key => {
                    if (vineyardSteps.find(step => step.id === reg.stepId)?.nameEN === "Other vineyard step") {
                        data.set("name", vineyardSteps.find(step => step.id === reg.stepId)?.[currentLangStepName] + ": " + reg["rows"] );
                    }
                    if (reg[key] !== undefined && reg[key] !== "") {
                        let content = stepContGroupedByKey[key]
                        if (content.length > 1) { // means it is a selected value 
                            let labelStep = content.find(step => step.type === "label");

                            if (labelStep) {
                                data.set(key, labelStep[currentLang] + " : " + reg[key]);
                            } else {
                                data.set(key, vineyardSteps.find(step => step.id === reg.stepId)?.[currentLangStepName] + " : " + reg[key])
                            }

                        } else {
                            switch (content[0].type) {
                                case "label":
                                    if (content[0].key === "product") {
                                        data.set(key, content[0][currentLang] + " : " + reg[key]);
                                    } else {
                                        data.set(key, content[0][currentLang] + " :");
                                    }
                                    break;
                                case "timestamp":
                                    if (key === "dateEnd") {
                                        data.set(key, content[0][currentLang] + " : " + reg[key]);
                                    } else {
                                        data.set(key, reg[key]);
                                    }
                                    break;
                                case "boolean":
                                    if (reg[key] === 1) {
                                        data.set(key, content[0][currentLang] + " : Yes")
                                    }
                                    break;
                                default:
                                    if (computeValues.includes(key)) {
                                        data.set(key, reg[key]);
                                    } else {
                                        data.set(key, content[0][currentLang] + " : " + reg[key]);
                                    }
                            }
                        }
                    }
                });
                return data;
            });
        let groupAndFilteredReg = groupCommonReg(tempFilteredRegistrations);
        setFilteredRegistrations(groupAndFilteredReg);

        const initialOpenStates = {};
        tempFilteredRegistrations.forEach((_, index) => {
            initialOpenStates[index] = false;
        });
        setOpenStates(initialOpenStates);
    }, [vineyards, lang, firstDate, lastDate, regOfSelectedVineyard, fyto])

    const stepContentGroupedByKey = (stepCont) => {
        return stepCont.reduce((acc, curr) => {
            if (!acc[curr.key]) {
                acc[curr.key] = [];
            }
            acc[curr.key].push(curr);
            return acc;
        }, {});
    }

    const groupCommonReg = (nonGroupedRegs) => {
        const regs = [...nonGroupedRegs];
        let ret = [];

        regs.forEach(key => {
            // Get the step's tags translation
            const stepContent = vineyardSteps.find(step => step.id === key.get("stepId"))?.content;
            const stepContGroupedByKey = stepContentGroupedByKey(stepContent);

            const existingReg = ret.find(reg => reg.get("stepId") === key.get("stepId"));
            const nameEn = vineyardSteps.find(step => step.id === key.get("stepId"))['nameEN'];
            if (existingReg && existingReg.get("date").toDate().getTime() === key.get("date").toDate().getTime()) {
                // Add the grape variety name to the first reg
                existingReg.set("agriculturalParcelName", `${existingReg.get("agriculturalParcelName")}, ${key.get("agriculturalParcelName")}`);

                // Add the computed value of the other agriculturals parcels
                if (regExeption.includes(nameEn)) {
                    computeValues.forEach(value => {
                        if (existingReg.get(value) !== undefined) {
                            existingReg.set(value, `${existingReg.get(value)}\n${key.get("agriculturalParcelName")} = ${key.get(value)}`);
                        }
                    });
                }
            } else {
                // When that is a computed value and it is the first reg to be added to the group
                if (regExeption.includes(nameEn)) {
                    computeValues.forEach(value => {
                        let content = stepContGroupedByKey[value]
                        if (key.get(value) !== undefined) {
                            if (content && content.length > 0 && content[0] && content[0][currentLang]) {
                                key.set(value, `${content[0][currentLang]} :\n ${key.get("agriculturalParcelName")} = ${key.get(value)}`);
                            } else {
                                console.error('content is not properly initialized, or currentLang is not set correctly');
                            }
                        }
                    });
                }
                ret.push(new Map(key));
            }
        });
        return ret;
    };

    const handleVineyardSelect = async (vineyardId) => {
        setSelectedVineyard(vineyardId);
    };

    const formatDate = (timestamp) => {
        if (!timestamp) return '';
        const date = timestamp.toDate();
        const langToLocale = {
            en: 'en-US',
            fr: 'fr-FR',
            it: 'it-IT',
            nl: 'nl-NL',
        };
        const locale = langToLocale[lang] || 'en-US';

        return date.toLocaleString(locale, { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' });
    };

    const convertTimestampToDate = (timestamp) => {
        return timestamp ? timestamp.toDate() : null;
    };

    const handleFytoChange = (e) => {
        const isChecked = e.target.checked;
        setFyto(isChecked);
    };

    const openAllRegistrations = (e) => {
        const isChecked = e.target.checked;
        setOpenStates(prevOpenStates => {
            const newOpenStates = { ...prevOpenStates };
            for (let key in newOpenStates) {
                newOpenStates[key] = isChecked;
            }
            return newOpenStates;
        });
    };

    const computeTotalAmount = (stringNumber) => {
        let numbers = stringNumber.map(line => {
            let parts = line.split('=');
            if (parts[1]) {
                return parseFloat(parts[1]);
            }
            return 0;
        });
        return numbers.reduce((a, b) => a + b, 0); // Compute the sum
    }

    return (
        <div>
            <Spinner isLoading={isLoading} />
            <h1>{t("vertaling.trace_vineyard")}</h1>
            <GoBackButton />

            <div className={'field'}>
                <label>{t("vertaling.crop_filter")}
                    <div className="checkbox" style={{ textAlign: 'center' }}>
                        <input
                            type="checkbox"
                            onChange={handleFytoChange}
                        />
                    </div>
                </label>
            </div>

            <div className={'field'}>
                <label>{t("vertaling.open_registrations")}
                    <div className="checkbox" style={{ textAlign: 'center' }}>
                        <input
                            type="checkbox"
                            onChange={openAllRegistrations}
                            checked={Object.keys(openStates).length > 0 && !Object.values(openStates).includes(false)} // Unchecked when changing vineyard
                        />
                    </div>
                </label>
            </div>

            <div>
                <div className='container tracebatch-content'>
                    <div className='row header'>
                        <div style={{ width: '350px' }}>
                            <Select
                                id="batch-select"
                                placeholder={t("vertaling.selectVineyard")}
                                options={vineyards.map((name, id) => ({
                                    value: name.id,
                                    label: name.name,
                                }))}
                                value={params.vineyard && vineyards.find((v) => v.name === params.vineyard) && {
                                    value: params.vineyard ? vineyards.find((v) => v.name === params.vineyard)?.id : '',
                                    label: params.vineyard,
                                }}
                                onChange={handleVineyardSelect}
                                className="specialty"
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 4,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#dacfa4b2',
                                        primary: '#AC9E66',
                                    },
                                })}
                            />
                        </div>


                        <br />
                    </div>

                    <div className='date-filter'>
                        <input
                            type="date"
                            value={firstDate}
                            max={lastDate}
                            onChange={(e) => setFirstDate(e.target.value)}>
                        </input>
                        <span>-</span>
                        <input
                            type="date"
                            value={lastDate}
                            min={firstDate}
                            max={new Date().toISOString().split("T")[0]}
                            onChange={(e) => setLastDate(e.target.value)}>
                        </input>
                    </div>
                    {isLoading !== undefined && filteredRegistrations.length > 0 ? (
                        <div className='steps'>
                            {
                                filteredRegistrations.map((object, index) => {
                                    return (
                                        <div key={index}>
                                            <div
                                                style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}
                                                onClick={() => setOpenStates(prevOpenStates => ({ ...prevOpenStates, [index]: !prevOpenStates[index] }))}
                                            >
                                                <div style={{ flex: 1, display: 'flex', gap: '10px' }}>
                                                    <div className='count'>{filteredRegistrations.length - index}</div>
                                                    <div style={{ background: 'none', border: 'none', padding: 0 }}>
                                                        {object.get('name') + ' ' + t("vertaling.on") + ' ' + formatDate(object.get('date'))}
                                                    </div>
                                                </div>
                                                {object.get('email') && <p style={{ margin: 0, whiteSpace: 'nowrap' }}>{object.get('email')}</p>}
                                            </div>
                                            {openStates[index] && (
                                                <div>
                                                    <div>
                                                        {[...object.entries()].map(([key, value]) => {
                                                            if (!excludeKeys.includes(key) && value !== null) {
                                                                if (key === "agriculturalParcelName") {
                                                                    return <p><b style={{ fontSize: '18px' }}>{value}</b></p>;
                                                                } else if (key === "yearPlanted") {
                                                                    return <p>{t("translation.yearPlanted")} {value}</p>;
                                                                } else if (key === "combinedTreatment") {
                                                                    return <p><b>{value}</b></p>;
                                                                } else if (computeValues.includes(key)) {
                                                                    let lines = value.split('\n'); // split the string into lines
                                                                    const total = computeTotalAmount(lines)
                                                                    return (
                                                                        <div style={{ whiteSpace: "pre-line" }}>
                                                                            <b>{lines[0]}</b><br />
                                                                            {lines.slice(1).join('\n')}<br />
                                                                            Total = {total}<br />
                                                                        </div>
                                                                    );
                                                                } else {
                                                                    return <p>{value}</p>;
                                                                }
                                                            }
                                                        })}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )
                                })
                            }
                        </div>
                    ) : (
                        <div className='steps'>
                            <h1 style={{ color: "#ac9e66db" }}>{t('vertaling.no_vineyard_registrations')}</h1>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
};

export default TraceVineyard;