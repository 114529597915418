import React from 'react';
import '../../Styles/App.css';
import { useAuth } from '../../context/AuthContext';
import NavUserLoggedOut from './NavUserLoggedOut';
import NavUserLoggedIn from './NavUserLoggedIn';
import './Nav.css';


const Nav = () => {
  const { currentUser } = useAuth();

  if (currentUser || currentUser === undefined) {
    return (
      <nav className='navbar navbar-expand-sm'>
        <NavUserLoggedIn />
      </nav>
    )
  } else {
    return (
      <nav className='navbar navbar-expand-sm'>
        <NavUserLoggedOut />
        {/* <LanguageSelector /> */}
      </nav>
    )
  }
}

export default Nav;
