import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { PageTitle } from '../../utils/PageTitle';
import { useSessionStorage } from '../../logic/useSessionStorage';
import GoBackButton from '../../components/FormComponents/GoBackButton';
import { firestore } from '../../firebase/config';
import { timestampToDate } from '../../utils/DateConversions';
import { sortHarvestYearsArray } from '../../utils/HarvestYear';
import { collection, onSnapshot } from 'firebase/firestore';
import MaterialTable from '@material-table/core';

// import "./HarvestYears.css";

const HarvestYears = () => {
  PageTitle('Harvestyears');
  const navigate = useNavigate();
  const [currentWinery, setCurrentWinery] = useSessionStorage(
    'currentwinery',
    ''
  );
  const [harvestYears, setHarvestYears] = useState(undefined);
  const { t } = useTranslation();

  useEffect(() => {
    const harvestYearRef = collection(firestore, 'winegrowers', currentWinery, 'harvestYear');

    const unsubscribeHarvestYear = onSnapshot(harvestYearRef, (querySnapshot) => {
      const harvestYearList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      if (harvestYearList !== undefined) {
        harvestYearList.forEach((year) => {
          year.startDate = timestampToDate(year.startDate);
          year.endDate = timestampToDate(year.endDate);
        });
        //sorting array based on name
        sortHarvestYearsArray(harvestYearList);
        setHarvestYears(harvestYearList);
      }

    });
    return () => unsubscribeHarvestYear();
  }, [currentWinery]);

  if (harvestYears !== undefined) {
    return (
      <div style={{ position: 'relative', marginTop: '90px' }}>
        <h1 style={{ color: '#7f7f7f' }}>{t('translation.harvestYears.myHarvestYears')}</h1>
        <GoBackButton />
        <div className="container">
          <div className="harvestYearTable">
            <MaterialTable
              title=""
              data={harvestYears}
              columns={[
                {
                  title: t('translation.words.name'),
                  field: 'name',
                },
                {
                  title: t('translation.harvestYears.startDate'),
                  field: 'startDate',
                },
                {
                  title: t('translation.harvestYears.endDate'),
                  field: 'endDate',
                },
                {
                  title: '',
                  field: '',
                  cellStyle: {
                    cellWidth: '6%',
                  },
                  sorting: false,
                  render: (rowData) => (
                    <>
                      <Link
                        to={'/harvestyears/delete/' + rowData.id}
                        style={{ color: 'white' }}
                      >
                        <button className="ui primary button login tableButton">
                          {t("translation.words.delete")}
                        </button>
                      </Link>

                      <Link
                        to={'/harvestyears/edit/' + rowData.id}
                        style={{ color: 'white' }}
                      >
                        <button className="ui primary button login tableButton">
                          {t("translation.words.edit")}
                        </button>
                      </Link>
                    </>
                  ),
                },
              ]}
              options={{
                headerStyle: { backgroundColor: '#cecaca', color: '#FFF' },
                emptyRowsWhenPaging: false,
                paging: true,
                pageSize: 50,
                pageSizeOptions: [
                  10,
                  20,
                  25,
                  50,
                ],
              }}
            />
          </div>

          <Link to="/harvestyears/create" style={{ color: 'white' }}>
            <button
              className="ui primary button login"
              style={{ marginTop: '50px' }}
            >
              {t("translation.words.create")}
            </button>
          </Link>
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default HarvestYears;