import {useSessionStorage} from "../../logic/useSessionStorage";
import { useTranslation } from 'react-i18next';
import {useFirestore} from "../../context/FirestoreContext";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import GoBackButton from "../../components/FormComponents/GoBackButton";
import {useAuth} from "../../context/AuthContext";
import {useNavigate} from "react-router-dom";

const CustomWoodAging = () => {

    const [cellarRegistrationData, setCellarRegistrationData] = useSessionStorage("cellarReg", "");
    const {getCurrentCellarStep} = useFirestore();
    const {getBatchById} = useFirestore();
    const [currentStep, setCurrentStep] = useState(undefined);
    const [currentBatch, setCurrentBatch] = useState(undefined);
    const {register, handleSubmit} = useForm();
    const formClassname = "ui big form twelve wide column";
    const {currentUser} = useAuth();
    const navigate = useNavigate();
    const [currentWinery, setCurrentWinery] = useSessionStorage("currentwinery", "");
    const { t } = useTranslation();

    useEffect(() => {
    const getCurrentCellarStepFromDb = async () => {
        let cellarStep = await getCurrentCellarStep(cellarRegistrationData.stepId, currentWinery);
        setCurrentStep(cellarStep);
    }
    const getCurrentBatchFromDb = async () => {
        let batch = await getBatchById(cellarRegistrationData.batchId, currentWinery);
        setCurrentBatch(batch)
    }

        if (currentStep === undefined) getCurrentCellarStepFromDb();
        if (currentBatch === undefined) getCurrentBatchFromDb();
    }, [ currentBatch, currentStep])

    const onSubmit = async (data) => {
        let newCellarRegistration = {};
        newCellarRegistration = {...cellarRegistrationData};
        let startDateString = data.startDate.split("-");
        let endDateString = data.endDate.split("-");
        newCellarRegistration.startDate = new Date(startDateString[0], startDateString[1] - 1, startDateString[2]);
        newCellarRegistration.endDate = new Date(endDateString[0], endDateString[1] - 1, endDateString[2]);
        newCellarRegistration.SO2GramPerLiter = Number(data.SO2GramPerLiter);
        newCellarRegistration.email = currentUser.email;
        newCellarRegistration.type = "cellarStep";
        newCellarRegistration.visualisation = true;
        newCellarRegistration.waitingForReview = false;
        setCellarRegistrationData(newCellarRegistration)
        setTimeout(() => {
            navigate("/cellarRegistration/addWineStorage");
        }, 500)
    }
    return (
        <div>
            {currentStep !== undefined && currentBatch !== undefined &&
                <div>
                    <h1>
                        {t('translation.cellarRegistration.registrationOf')} {currentStep.nameEN} {t('translation.words.for')} {currentBatch.name}
                    </h1>
                    <GoBackButton/>
                    <br/>
                    <br/>
                    <div className="ui grid stackable">
                        <form
                            className={formClassname}
                            style={{
                                border: "3px solid #B3B3B3",
                                marginTop: "100px",
                                margin: "auto",
                                borderRadius: "50px",
                                background: "#edeaea",
                                borderColor: "#ac9e66",
                                maxWidth: '960px'
                            }}
                            onSubmit={handleSubmit(onSubmit)}
                        >

                            <div
                                className="fields"
                                style={{justifyContent: "center", marginTop: "20px"}}
                            >
                                <div className="twelve wide field">
                                    <label>
                                        <p>{t("translation.cellarRegistration.startDate")}</p>
                                        <input type="date" {...register('startDate')} htmlFor="startDate" required />
                                    </label>
                                </div>
                            </div>
                            <div
                                className="fields"
                                style={{justifyContent: "center", marginTop: "20px"}}
                            >
                                <div className="twelve wide field"
                                     style={{justifyContent: "center"}}>
                                    <label>
                                        <p>{t("translation.cellarRegistration.endDate")}</p>
                                        <input type="date" {...register('endDate')} htmlFor="endDate" required />
                                    </label>
                                </div>
                            </div>
                            <div
                                className="fields"
                                style={{justifyContent: "center", marginTop: "20px"}}
                            >
                                <div
                                    className="twelve wide field">
                                    <label>
                                        <p>{t("translation.cellarRegistration.possibleAdditionOfSo")}</p>
                                        <input
                                            type="number"
                                            {...register('SO2GramPerLiter')}
                                            htmlFor="SO2GramPerLiter"
                                            style={{textAlign: "center"}} />
                                    </label>
                                </div>
                            </div>
                            <button
                                type="submit"
                                className="ui submit large grey button right floated  button-vinyards"
                                style={{float: "none", marginTop: "30px", width: "25%"}}
                            >
                                {t("translation.words.next")}
                            </button>
                        </form>
                    </div>
                </div>
            }

        </div>
    );
}
export default CustomWoodAging;