import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { useAuth } from '../../context/AuthContext';
import { createBatchFromDrying } from '../../firebase/batch';
import { firestore } from '../../firebase/config';
import { createRegistration } from '../../firebase/registrations';
import { PageTitle } from '../../utils/PageTitle';
import GoBackButton from "../../components/FormComponents/GoBackButton";
import { doc, collection, query, where, orderBy, onSnapshot } from 'firebase/firestore';

const CreateVineyardRegistration = (props) => {
    PageTitle("Create Vineyard Registration");
    const { currentUser } = useAuth();
    const navigate = useNavigate();
    const [userData, setUserData] = useState('');
    const selectedStep = props.location.state.selectStep;
    const selectedStepName = props.location.state.selectStepName;
    const selectedVineyard = props.location.state.selectVineyard;
    const selectedVineyardName = props.location.state.selectVineyardName;
    const [contentKeys, setContentKeys] = useState('');
    const [batches, setBatches] = useState('');
    const [barrels, setBarrels] = useState('');
    const [winegrowerId, setWinegrowerId] = useState('');
    const [isHarvest, setIsHarvest] = useState(false);
    const [isDrying, setIsDrying] = useState(false);
    const [hasBatchId, setHasBatchId] = useState(false);
    const [optionSelectBatch, setOptionSelectBatch] = useState(false);
    const [hasWintestorageId, setHasWinestorageId] = useState(false);
    const [wayOfHarvest, setWayOfHarvest] = useState('');
    const [selectedBatch, setSelectedBatch] = useState('');
    const [selectedWinestorage, setSelectedWinestorage] = useState('');
    const [vineyardStepContent, setVineyardStepContent] = useState([]);
    const { register, handleSubmit } = useForm();
    const [isLoading, setLoading] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        // Referenties naar firestore database (bv : ophalen van collecties uit firestore db)
        // Alle waarden in de variabelen gaan verloren éénmaal de useEffect volledig uitgevoerd is

        const usersQuery = query(collection(firestore, 'users'), where('email', '==', currentUser.email));

        const unsubscribeUsers = onSnapshot(usersQuery, (querySnapshot) => {
            // huidige user opvragen
            const userLoggedIn = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setWinegrowerId(userLoggedIn[0].winegrower);
            setUserData(userLoggedIn[0]);

        });

            // opvragen van de inhoud van alle wijngaardstappen
            const vineyardStepContentQuery = query(
                collection(doc(firestore, 'vineyardsteps', selectedStep), 'content'),
                orderBy('order')
              );

              const unsubscribeVineyardStepContent = onSnapshot(vineyardStepContentQuery, (querySnapshot) => {
                const vineyardStepContent = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setVineyardStepContent(vineyardStepContent);
            });
                // opvragen van alle wijnboeren en daarbinnen de batches

                const batchesQuery = query(collection(doc(firestore, 'winegrowers', userData.winegrower), 'batches'));

                const unsubscribeBatches = onSnapshot(batchesQuery, (querySnapshot) => {          
                    const batchesList = querySnapshot.docs.map((doc) => ({
                        id: doc.id,
                        ...doc.data(),
                    }));

                    var batchesDrying = [];

                    // overlopen van alle batches

                    for (let h = 0; h < batchesList.length; h++) {
                        if (batchesList[h].historic === 'Batch created in step Drying') {
                            batchesDrying.push(batchesList[h]);
                        }
                    }

                    setBatches(batchesDrying);

                });

                    const barrelQuery = query(collection(doc(firestore, 'winegrowers', userData.winegrower), 'wineStorages'));

                    const unsubscribeBarrel = onSnapshot(barrelQuery, (querySnapshot) => {
                        const barrelList = querySnapshot.docs.map((doc) => ({
                            id: doc.id,
                            ...doc.data(),
                        }));

                        setBarrels(barrelList);

                    });

                        // overlopen van de inhoud van alle wijngaardstappen

                        var contentKeysArray = [];
                        for (let i = 0; i < vineyardStepContent.length; i++) {
                            if (
                                vineyardStepContent[i].key !== null &&
                                vineyardStepContent[i].key !== undefined &&
                                vineyardStepContent[i].key !== 'img'
                            ) {
                                if (vineyardStepContent[i].key === 'wayOfHarvest') {
                                    setIsHarvest(true);
                                }
                                if (vineyardStepContent[i].key === 'batchId') {
                                    setHasBatchId(true);

                                    if (vineyardStepContent[i].type === 'listBatchDrying') {
                                        setOptionSelectBatch(true);
                                    }
                                }

                                if (vineyardStepContent[i].key === 'nameBatch') {
                                    setIsDrying(true);
                                }

                                if (vineyardStepContent[i].key === 'winestorageId') {
                                    setHasWinestorageId(true);
                                }

                                contentKeysArray.push(vineyardStepContent[i]);
                            }
                        }
                        setContentKeys(contentKeysArray);
                    return () => {
                        unsubscribeBarrel();
                      };
    }, []);

    // Event die uitgevoerd wordt bij het wijzigen van het oogsttype
    const changeHarvestType = (selection) => {
        setWayOfHarvest(selection.value);
    };

    // Event die uitgevoerd wordt bij het wijzigen van een batch
    const changeBatch = (selection) => {
        setSelectedBatch(selection.value);
    };

    // Event die uitgevoerd wordt bij het wijzigen van een vat
    const changeWinestorage = (selection) => {
        setSelectedWinestorage(selection.value);
    };

    const onSubmit = async (data) => {
        setLoading(true);
        var batchId = '';

        if (data.newBatchDrying !== undefined) {
            var newBatch = new Object();
            newBatch.batch = data.newBatchDrying;
            delete data.newBatchDrying;
            newBatch.createdate = new Date();
            newBatch.hectoliters = Number(data.amountDrying);
            newBatch.available = true;
            newBatch.email = userData.email;
            newBatch.historic = 'Batch created in step ' + selectedStepName;

            try {
                batchId = (await createBatchFromDrying(winegrowerId, newBatch)).id;
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        }

        if (data.newBatch !== undefined) {
            var newBatch = new Object();
            newBatch.batch = data.newBatchDrying;
            delete data.newBatchDrying;
            newBatch.createdate = new Date();
            newBatch.hectoliters = Number(data.amountDrying);
            newBatch.available = true;
            newBatch.email = userData.email;
            newBatch.historic = 'Batch created in step ' + selectedStepName;

            try {
                batchId = (await createBatchFromDrying(winegrowerId, newBatch)).id;
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        }

        data.waitingForReview = false;
        data.date = new Date();
        data.visualisation = true;
        data.type = 'vineyardStep';
        data.stepId = selectedStep;
        data.email = userData.email;
        data.batchId = "";
        if (userData.trustedForRegistration === false) {
            data.waitingForReview = true;
        }
        if (wayOfHarvest !== '') {
            data.wayOfHarvest = wayOfHarvest;
        }
        if (selectedBatch !== '') {
            data.batchId = selectedBatch;
        }
        if (selectedWinestorage !== '') {
            data.winestorageId = selectedWinestorage;
        }
        if (data.alcohol !== undefined) {
            data.alcohol = Number(data.alcohol);
        }
        if (data.ph !== undefined) {
            data.ph = Number(data.ph);
        }
        if (data.sugars !== undefined) {
            data.sugars = Number(data.sugars);
        }
        if (data.acidity !== undefined) {
            data.acidity = Number(data.acidity);
        }
        if (data.amount !== undefined) {
            data.amount = Number(data.amount);
        }
        if (data.amountDrying !== undefined) {
            data.amountDrying = Number(data.amountDrying);
        }
        if (batchId !== '') {
            data.batchId = batchId;
        }
        data.locationId = selectedVineyard;

        try {
            const userQuery = query(
                collection(firestore, 'users'),
                where('email', '==', userData.email)
            );

            onSnapshot(userQuery, (querySnapshot) => {
                const userLoggedIn = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                if (userLoggedIn.length > 0) {
                    data.createdBy = userLoggedIn[0].name;
                    createRegistration(winegrowerId, data);
                }
            });
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
            navigate(`/Addregistration`);
        }
    };

    return (
        <div style={{ marginTop: '90px' }}>
            <h1>
                {t('vertaling.create_new')} {selectedStepName}
                {t('vertaling.regisforvineyard')}{' '}
                {selectedVineyardName}
            </h1>
            <GoBackButton />
            <br />
            <br />
            <br />
            <div>
                <div>
                    {contentKeys.length >= 1 && (
                        <div>
                            <form
                                onSubmit={handleSubmit(onSubmit)}
                                className="ui big form twelve wide column"
                                style={{ fontSize: '1rem' }}
                            >
                                {contentKeys.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            style={{
                                                width: '80%',
                                                margin: 'auto',
                                            }}
                                        >
                                            {item.key === 'description' ? (
                                                <div
                                                    className="fields"
                                                    style={{ justifyContent: 'center' }}
                                                >
                                                    <div className="eight wide field">
                                                        <label>
                                                            {t('vertaling.beschrijving')}
                                                            <input type="text" {...register(item.key)} />
                                                        </label>
                                                    </div>
                                                </div>
                                            ) : (
                                                ''
                                            )}

                                            {item.key === 'amount' ? (
                                                <div
                                                    className="fields"
                                                    style={{ justifyContent: 'center' }}
                                                >
                                                    <div className="eight wide field">
                                                        <label>
                                                            {t('vertaling.amount_report')}
                                                            <input type="number" min="0" step="0.1" {...register(item.key)} />
                                                        </label>
                                                    </div>
                                                </div>
                                            ) : (
                                                ''
                                            )}

                                            {item.key === 'product' ? (
                                                <div
                                                    className="fields"
                                                    style={{ justifyContent: 'center' }}
                                                >
                                                    <div className="eight wide field">
                                                        <label>
                                                            {t('vertaling.product')}
                                                            <input type="text" {...register(item.key)} />
                                                        </label>
                                                    </div>
                                                </div>
                                            ) : (
                                                ''
                                            )}

                                            {item.key === 'alcohol' ? (
                                                <div
                                                    className="fields"
                                                    style={{ justifyContent: 'center' }}
                                                >
                                                    <div className="eight wide field">
                                                        <label>
                                                            {t('vertaling.alcohol_report')}
                                                            <input type="number" min="0" step="0.1" {...register(item.key)} />
                                                        </label>
                                                    </div>
                                                </div>
                                            ) : (
                                                ''
                                            )}

                                            {item.key === 'massDensity' ? (
                                                <div
                                                    className="fields"
                                                    style={{ justifyContent: 'center' }}
                                                >
                                                    <div className="eight wide field">
                                                        <label>
                                                            {t('vertaling.massDensity_report')}
                                                            <input type="text" {...register(item.key)} />
                                                        </label>
                                                    </div>
                                                </div>
                                            ) : (
                                                ''
                                            )}

                                            {item.key === 'ph' ? (
                                                <div
                                                    className="fields"
                                                    style={{ justifyContent: 'center' }}
                                                >
                                                    <div className="eight wide field">
                                                        <label>
                                                            {t('vertaling.ph_report')}
                                                            <input type="number" min="0" step="0.1" {...register(item.key)} />
                                                        </label>
                                                    </div>
                                                </div>
                                            ) : (
                                                ''
                                            )}

                                            {item.key === 'sugars' ? (
                                                <div
                                                    className="fields"
                                                    style={{ justifyContent: 'center' }}
                                                >
                                                    <div className="eight wide field">
                                                        <label>
                                                            {t('vertaling.sugars_report')}
                                                            <input type="number" min="0" step="0.1" {...register(item.key)} />
                                                        </label>
                                                    </div>
                                                </div>
                                            ) : (
                                                ''
                                            )}

                                            {item.key === 'acidity' ? (
                                                <div
                                                    className="fields"
                                                    style={{ justifyContent: 'center' }}
                                                >
                                                    <div className="eight wide field">
                                                        <label>
                                                            {t('vertaling.acidity_report')}
                                                            <input type="number" min="0" step="0.1" {...register(item.key)} />
                                                        </label>
                                                    </div>
                                                </div>
                                            ) : (
                                                ''
                                            )}

                                            {item.key === 'products' ? (
                                                <div
                                                    className="fields"
                                                    style={{ justifyContent: 'center' }}
                                                >
                                                    <div className="eight wide field">
                                                        <label>
                                                            {t('vertaling.products')}
                                                            <input type="text" {...register(item.key)} />
                                                        </label>
                                                    </div>
                                                </div>
                                            ) : (
                                                ''
                                            )}

                                            {item.key === 'warehouse' ? (
                                                <div
                                                    className="fields"
                                                    style={{ justifyContent: 'center' }}
                                                >
                                                    <div className="eight wide field">
                                                        <label>
                                                            {t('vertaling.warehouse_report')}
                                                            <input type="text" {...register(item.key)} />
                                                        </label>
                                                    </div>
                                                </div>
                                            ) : (
                                                ''
                                            )}

                                            {item.key === 'amountDrying' ? (
                                                <div
                                                    className="fields"
                                                    style={{ justifyContent: 'center' }}
                                                >
                                                    <div className="eight wide field">
                                                        <label>
                                                            {t('vertaling.drying_amount')}
                                                            <input type="number" min="0" step="0.1" {...register(item.key)} />
                                                        </label>
                                                    </div>
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    );
                                })}

                                <div style={{ width: '80%', margin: 'auto' }}>
                                    {isDrying ? (
                                        <div
                                            className="fields"
                                            style={{ justifyContent: 'center' }}
                                        >
                                            <div className="eight wide field">
                                                <label>
                                                    {t('vertaling.title_createBatch')}
                                                    <input type="text" {...register('newBatchDrying')} required />
                                                </label>
                                            </div>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>

                                <div style={{ width: '80%', margin: 'auto' }}>
                                    {hasBatchId ? (
                                        <div>
                                            <b>{t('vertaling.batchdrying')}</b>
                                            <div
                                                className="fields"
                                                style={{ justifyContent: 'center' }}
                                            >
                                                <div className="eight wide field">
                                                    <input
                                                        type="text"
                                                        {...register('newBatch')}
                                                        placeholder={t('vertaling.create_batch')} />
                                                </div>
                                            </div>
                                            {optionSelectBatch ? (
                                                <div>
                                                    <label className="eight wide field">
                                                        <Select
                                                            placeholder="Select batch*"
                                                            options={batches.map((name, id) => ({
                                                                value: name.id,
                                                                label: name.batch,
                                                            }))}
                                                            onChange={(selection) => changeBatch(selection)}
                                                            className="specialty"
                                                            theme={(theme) => ({
                                                                ...theme,
                                                                borderRadius: 5,
                                                                colors: {
                                                                    ...theme.colors,
                                                                    primary25: '#dacfa4b2',
                                                                    primary: '#AC9E66',
                                                                },
                                                            })}
                                                        />
                                                    </label>
                                                    <br />
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>

                                <div style={{ width: '80%', margin: 'auto' }}>
                                    {hasWintestorageId ? (
                                        <div>
                                            <label className="eight wide field">
                                                <b> {t('vertaling.select_tank')}</b>
                                                <Select
                                                    required
                                                    placeholder=""
                                                    options={barrels.map((name, id) => ({
                                                        value: name.id,
                                                        label: name.name,
                                                    }))}
                                                    onChange={(selection) => changeWinestorage(selection)}
                                                    className="specialty"
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 5,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#dacfa4b2',
                                                            primary: '#AC9E66',
                                                        },
                                                    })}
                                                />
                                            </label>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>

                                <div style={{ width: '80%', margin: 'auto' }}>
                                    {isHarvest ? (
                                        <div>
                                            <label className="eight wide field">
                                                <b>
                                                    {' '}
                                                    {t('vertaling.harvest_method_report')}
                                                </b>
                                                <Select
                                                    placeholder="Select way of harvest*"
                                                    options={[
                                                        {
                                                            value: 'mechanical',
                                                            label: 'Mechanical',
                                                        },
                                                        {
                                                            value: 'manually',
                                                            label: 'Manually',
                                                        },
                                                    ]}
                                                    onChange={(selection, action) =>
                                                        changeHarvestType(selection, action)
                                                    }
                                                    className="specialty"
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 5,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#dacfa4b2',
                                                            primary: '#AC9E66',
                                                        },
                                                    })}
                                                />
                                            </label>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                <br />
                                <button
                                    type="submit"
                                    className="ui primary button login"
                                    style={{
                                        position: 'absolute',
                                        left: '50%',
                                        transform: 'translateX(-50%',
                                    }}
                                >
                                    <b>{t("vertaling.submit")}</b>
                                </button>
                            </form>
                        </div>
                    )}
                    {contentKeys.length < 1 && <p></p>}
                </div>
                <br />
            </div>
            <br />
        </div>
    );
};

export default CreateVineyardRegistration;