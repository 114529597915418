import { useTranslation } from 'react-i18next';
import { useEffect, useState, useRef} from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { firestore } from "../../firebase/config";
import { updateAgriculturalParcel } from "../../firebase/parcel";
import { PageTitle } from "../../utils/PageTitle";
import { useSessionStorage } from "../../logic/useSessionStorage";
import GoBackButton from "../../components/FormComponents/GoBackButton";
import { doc, onSnapshot, collection, getDoc} from "firebase/firestore";

const AgriculturalParcelDetail = () => {
    PageTitle("Agricultural Parcel Detail");
    const [currentWinery, setCurrentWinery] =
        useSessionStorage("currentwinery", "");
    const params = useParams();
    let parcelData = "";
    let vineyardData = "";
    const navigate = useNavigate();
    const formClassname = "ui big form twelve wide column";
    const { register, setValue, handleSubmit } = useForm();
    const [agriculturalParcel, setAgriculturalParcel] = useState("");
    const [error, setError] = useState("hidden");
    const [parcels, setParcels] = useState([]);
    const [vineyards, setVineyards] = useState([]);
    const [selectedParcel, setSelectedParcel] = useState('');
    const [selectedVineyard, setSelectedVineyard] = useState('');
    const [preSelectedVineyard, setPreSelectedVineyard] = useState("");
    const [preSelectedParcel, setPreSelectedParcel] = useState("");
    const [isLoading, setLoading] = useState(true);
    const [varieties, setVarieties] = useState([]);
    const [selectedVariety, setSelectedVariety] = useState("");
    const [isFocused, setFocus] = useState(false);
    const inputRef = useRef(null);
    const { t } = useTranslation();

    useEffect(() => {
        setLoading(true);
        
        const wineryRef = doc(firestore, "winegrowers", currentWinery);
        const agriculturalParcelDoc = doc(wineryRef, "agriculturalParcels", params.id);
        const parcelsCollectionDoc = collection(wineryRef, "parcels");
        const vineyardsCollectionDoc = collection(wineryRef, "vineyards");
        const vineyardDoc = doc(wineryRef, "vineyards", params.vineyardId);
        const parcelDoc = doc(wineryRef, "parcels", params.parcelId);

        const unsubscribeAgricultural = onSnapshot(agriculturalParcelDoc, (doc) => {
            if (doc.exists) {
                const docData = doc.data();
                docData.id = doc.id;
                setAgriculturalParcel(docData);
                setSelectedVineyard(docData.vineyardId);
                setSelectedParcel(docData.parcelId);
                setSelectedVariety(docData.grapeVariety);

                const unsubscribeParcels = onSnapshot(parcelsCollectionDoc, (querySnapshot) => {
                    try {
                        parcelData = querySnapshot.docs.map((doc) => ({
                            id: doc.id,
                            ...doc.data(),
                        }));
                        setParcels(parcelData);
                    } catch (error) {
                        console.error(error);
                    }
                });

                const unsubscribeVineyards = onSnapshot(vineyardsCollectionDoc, (querySnapshot) => {
                    try {
                        vineyardData = querySnapshot.docs.map((doc) => ({
                            id: doc.id,
                            ...doc.data(),
                        }));
                        setVineyards(vineyardData);
                    } catch (error) {
                        console.error(error);
                    }
                });

                const unsubscribeVineyard = onSnapshot(vineyardDoc, (doc) => {
                        if (doc.exists) {
                            const docData = doc.data();
                            docData.id = doc.id;
                            setPreSelectedVineyard(docData);

                        }
               });

               const unsubscribeParcel = onSnapshot(parcelDoc, (doc) => {
                    if (doc.exists) {
                        const docData = doc.data();
                        docData.id = doc.id;
                        setPreSelectedParcel(doc.data());
                    }
                });
                setLoading(false);
                return () => {
                    unsubscribeParcels();
                    unsubscribeVineyards();
                    unsubscribeVineyard();
                    unsubscribeParcel();
                };

            }
            return () => {
                unsubscribeAgricultural();
            };
        });
        const grapeVarietiesDocRef = doc(firestore, "enums", "grapeVarieties");
        getDoc(grapeVarietiesDocRef)
        .then((doc) => {
                if (doc.exists) {
                    const data = doc.data();
                    if (data.varietiesBelgium) {
                        const varietiesArray = Object.values(data.varietiesBelgium);
                        varietiesArray.sort(); // Sort alphabetically
                        setVarieties(varietiesArray);
                    }
                }
            })
            .catch((error) => {
                console.error("Error getting document:", error);
            });

    }, [setValue, params.id]);

    const onSubmit = async (data) => {
        try {
            if ((selectedParcel === "") || (selectedVineyard === "")) {
                    setError("visible");
                } else {
                    setError("hidden");
                    const totalAreaInSquareMeters = data.rowDistance * data.plantDistance * data.numberOfPlants;
                    data.areaPlantedAre = Math.floor(totalAreaInSquareMeters / 100);
                    data.areaPlantedCentiare = Math.round(totalAreaInSquareMeters - (data.areaPlantedAre * 100));
                    const extendedData = {
                        ...data,
                        grapeVariety: selectedVariety,
                    };
                    await updateAgriculturalParcel(currentWinery, params.id, extendedData);
                    navigate(-1);
           }
        } catch (error) {
            console.error(error);
        }
    };

    const handleParcelChange = (selection, action) => {
        setSelectedParcel(selection.value);
        setPreSelectedParcel({"id": selection.value, "name": selection.label});
    };

    const handleVineyardChange = (selection, action) => {
        setSelectedVineyard(selection.value);
        setPreSelectedVineyard({"id": selection.value, "name": selection.label});
    };

    const handleGrapeChange = (e) => {
        setSelectedVariety(e.target.value);
        setFocus(true);
    };
    

    const filteredVarieties = varieties.filter((option) =>
        option.toLowerCase().startsWith(selectedVariety.toLowerCase())
    );

    return (!isLoading) && (
    <div>
            <div
                className="add-form-container"
                style={{
                    maxWidth: 960,
                    margin: "50px auto",
                    marginTop: "110px",
                }}
            >
                <h1 style={{ color: "#7f7f7f" }}>{t('translation.agriculturalParcelInfo')}</h1>
                <GoBackButton />
                <br />

                <div className="ui grid stackable">
                    <form
                        className={formClassname}
                        style={{
                            border: "3px solid #B3B3B3",
                            marginTop: "100px",
                            borderRadius: "50px",
                            borderColor: "#ac9e66",
                        }}
                        onSubmit={handleSubmit(onSubmit)}
                    >

                        <div
                            className="fields"
                            style={{ justifyContent: "center", marginTop: "20px" }}
                        >
                            <div className="twelve wide field">
                                <label>
                                    <p>{t("translation.name")}</p>
                                    <input
                                        type="text"
                                        {...register('name')}
                                        htmlFor="name"
                                        defaultValue={agriculturalParcel.name}
                                        style={{ textAlign: "center" }} />
                                </label>
                            </div>
                        </div>
                        <div
                                className="fields"
                                style={{ justifyContent: "center", marginTop: "20px" }}
                            >
                                <div className="twelve wide field">
                                    <label>
                                        <p>{t("translation.myAgriParcels.rowDistance")}</p>
                                        <input
                                            type="number"
                                            step={0.01}
                                            {...register('rowDistance')}
                                            htmlFor="rowDistance"
                                            required="required"
                                            defaultValue={agriculturalParcel.rowDistance}
                                            style={{ textAlign: "center" }} />
                                    </label>
                                </div>
                                <div className="twelve wide field">
                                    <label>
                                        <p>{t("translation.myAgriParcels.plantDistance")}</p>
                                        <input
                                            type="number"
                                            step={0.01}
                                            {...register('plantDistance')}
                                            htmlFor="plantDistance"
                                            required="required"
                                            defaultValue={agriculturalParcel.plantDistance}
                                            style={{ textAlign: "center" }} />
                                    </label>
                                </div>
                            </div>
                        <div
                            className="fields"
                            style={{ justifyContent: "center", marginTop: "20px" }}
                        >
                            <div className="twelve wide field">
                                <label>
                                    <p>{t("translation.areaPlantedAre")}</p>
                                    <input
                                        type="text"
                                        {...register('areaPlantedAre')}
                                        htmlFor="areaPlantedAre"
                                        required="required"
                                        defaultValue={agriculturalParcel.areaPlantedAre}
                                        style={{ textAlign: "center" }}
                                        disabled={true} />
                                </label>
                            </div>
                            <div className="twelve wide field">
                                <label>
                                    <p>{t("translation.areaPlantedCentiare")}</p>
                                    <input
                                        type="text"
                                        {...register('areaPlantedCentiare')}
                                        htmlFor="areaPlantedAreCentiare"
                                        required="required"
                                        defaultValue={agriculturalParcel.areaPlantedCentiare}
                                        style={{ textAlign: "center" }}
                                        disabled={true} />
                                </label>
                            </div>
                        </div>
                        <div
                            className="fields"
                            style={{ justifyContent: "center", marginTop: "20px" }}
                        >
                            <div className="twelve wide field">
                                <label>
                                    <p>{t("translation.clone")}</p>
                                    <input
                                        type="text"
                                        {...register('clone')}
                                        htmlFor="clone"
                                        defaultValue={agriculturalParcel.clone}
                                        style={{ textAlign: "center" }} />
                                </label>
                            </div>
                        </div>

                        <div
                                className="fields"
                                style={{ justifyContent: "center", marginTop: "20px" }}
                            >
                                <div className="twelve wide field">
                                    <label>
                                        <p>{t("translation.grapeVariety")}</p>
                                        <input
                                            ref={inputRef}
                                            type="text"
                                            defaultValue={selectedVariety}
                                            value={selectedVariety}
                                            onChange={handleGrapeChange}
                                            onFocus={() => setFocus(true)}
                                            onBlur={() => setTimeout(() => setFocus(false), 100)}
                                        />
                                    </label>
                                    {isFocused && (
                                        <div
                                            style={{
                                                border: "1px solid #000",
                                                position: "absolute",
                                                width: inputRef.current ? inputRef.current.offsetWidth : "200px",
                                                maxHeight: "300px",
                                                overflowY: "scroll",
                                                backgroundColor: "rgba(255, 255, 255, 0.9)",
                                            }}
                                            onMouseDown={(e) => e.preventDefault()}
                                        >
                                            {filteredVarieties.map((option, index) => (
                                                <div
                                                    key={`${option}-${index}`}
                                                    onClick={() => {
                                                        setSelectedVariety(option);
                                                        setFocus(false);
                                                    }}
                                                >
                                                    {option}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>

                        <div
                            className="fields"
                            style={{ justifyContent: "center", marginTop: "20px" }}
                        >
                            <div className="twelve wide field">
                                <label>
                                    <p>{t("translation.numberOfPlants")}</p>
                                    <input
                                        type="number"
                                        {...register('numberOfPlants')}
                                        htmlFor="numberOfPlants"
                                        required="required"
                                        defaultValue={agriculturalParcel.numberOfPlants}
                                        style={{ textAlign: "center" }} />
                                </label>
                            </div>
                        </div>
                        <div
                            className="fields"
                            style={{ justifyContent: "center", marginTop: "20px" }}
                        >
                            <div className="twelve wide field">
                                <label>
                                    <p>{t("translation.rootStock")}</p>
                                    <input
                                        type="text"
                                        {...register('rootStock')}
                                        htmlFor="rootStock"
                                        required="required"
                                        defaultValue={agriculturalParcel.rootStock}
                                        style={{ textAlign: "center" }} />
                                </label>
                            </div>
                        </div>

                        <div
                            className="fields"
                            style={{ justifyContent: "center", marginTop: "20px" }}
                        >
                            <div className="twelve wide field">
                                <label>
                                    <p>{t("translation.yearPlanted")}</p>
                                    <input
                                        type="text"
                                        {...register('yearPlanted')}
                                        htmlFor="yearPlanted"
                                        required="required"
                                        defaultValue={agriculturalParcel.yearPlanted}
                                        style={{ textAlign: "center" }} />
                                </label>
                            </div>
                        </div>
                        <div
                            className="fields"
                            style={{ justifyContent: "center", marginTop: "20px", color: "red" }}
                        >
                            <div className="twelve wide field">
                                <label>
                                    <p>{t("translation.connectedParcel")}</p>
                                    <Select
                                        label="parcelId"
                                        htmlFor="parcelId"
                                        onChange={(selection, action) =>
                                            handleParcelChange(selection, action)
                                        }
                                        options={parcels.map((parcel) => ({
                                            value: parcel.id,
                                            label: parcel.name,
                                        }))}
                                        value={{
                                            label: preSelectedParcel.name,
                                            value: selectedParcel
                                        }}


                                    />
                                </label>
                            </div>
                            <div style={{ display: "none" }}>
                                <label>
                                    <p>{t("translation.connectedParcel")}</p>
                                    <input
                                        {...register('parcelId')}
                                        htmlFor="parcelId"
                                        value={selectedParcel}
                                        readOnly />
                                </label>
                            </div>
                        </div>

                        <div
                            className="fields"
                            style={{ justifyContent: "center", marginTop: "20px" }}
                        >
                            <div className="twelve wide field">
                                <label>
                                    <p>{t("translation.connectedVineyard")}</p>
                                    <Select
                                        label="vineyardId"
                                        htmlFor="vineyardId"
                                        onChange={(selection, action) =>
                                            handleVineyardChange(selection, action)
                                        }
                                        options={vineyards.map((vineyard) => ({
                                            value: vineyard.id,
                                            label: vineyard.name,
                                        }))}
                                        value={{
                                            label: preSelectedVineyard.name,
                                            value: selectedVineyard
                                        }}


                                    />
                                </label>
                            </div>
                            <div style={{ display: "none" }}>
                                <label>
                                    <p>{t("translation.connectedVineyard")}</p>
                                    <input
                                        {...register('vineyardId')}
                                        htmlFor="vineyardId"
                                        value={selectedVineyard}
                                        readOnly />
                                </label>
                            </div>
                        </div>
                        <p style={{ visibility: error }} className="text-danger">
                            {t("translation.connectedParcelNotSelected")}
                        </p>

                        <button
                            type="submit"
                            className="ui submit large grey button right floated  button-vinyards"
                            style={{ float: "none", marginTop: "30px", width: "25%" }}
                        >
                            {t("translation.updateAgriculturalParcel")}
                        </button>
                    </form>
                </div>
            </div>
    </div>
    );
};

export default AgriculturalParcelDetail;