import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { createCellar } from '../firebase/Cellar';
import { useForm } from 'react-hook-form';
import { useSessionStorage } from '../logic/useSessionStorage';
import { PageTitle } from '../utils/PageTitle';
import GoBackButton from "../components/FormComponents/GoBackButton";

const CreateBarrel = () => {
    PageTitle("Create Barrel");
    const navigate = useNavigate();
    const { register, handleSubmit } = useForm();
    const params = useParams();
    const [currentWinery, setCurrentWinery] = useSessionStorage('currentwinery', '');
    const { t } = useTranslation();

    // Referenties naar firestore database (bv : ophalen van collecties uit firestore db)
    // Alle waarden in de variabelen gaan verloren éénmaal de useEffect volledig uitgevoerd is

    const onSubmit = async (data) => {
        try {
            await createCellar({ uid: params.id, ...data });
        } catch (error) {
            console.error(error);
        } finally {
            navigate(`/myCellars`);
        }
    };

    // Het weergeven van alle gegevens om een wijnkelder aan te maken

    return (
        <div
            className="add-form-container"
            style={{ maxWidth: 960, margin: '50px auto', marginTop: '130px' }}
        >
             <h2 style={{ paddingBottom: '20px' }}>{t('vertaling.title_createCellar')}</h2>
            <GoBackButton />
            <br />
            <div
                className="ui grid stackable"
                style={{ justifyContent: 'center', margin: 'auto' }}
            >
                <form
                    className="ui big form twelve wide column"
                    style={{ margin: 'auto', marginTop: '50px' }}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <div className="eight wide field" style={{ margin: 'auto' }}>
                        <label>
                            {t("vertaling.name")}
                            <input type="text" {...register('name')} htmlFor="name" required />
                        </label>
                    </div>
                    <br />
                    <div className="eight wide field" style={{ margin: 'auto' }}>
                        <label>
                            {t("vertaling.adress")}
                            <input type="text" {...register('adress')} htmlFor="adress" required />
                        </label>
                    </div>
                    <br />
                    <div className="eight wide field" style={{ margin: 'auto' }}>
                        <label>
                            {t("vertaling.city")}
                            <input type="text" {...register('city')} htmlFor="city" required />
                        </label>
                    </div>

                    <div className="eight wide field" style={{ display: 'none' }}>
                        <label>
                            {t('vertaling.cellar')}
                            <input {...register('winegrower')} htmlFor="winegrower" value={currentWinery} />
                        </label>
                    </div>

                    <button
                        type="submit"
                        className="ui submit large grey button right floated create-cellar"
                        style={{ marginTop: '-55px' }}
                    >
                        {t("vertaling.submit")}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default CreateBarrel;