import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom'
import Spinner from '../../components/Spinner/Spinner';
import { useFirestore } from '../../context/FirestoreContext';


const WinegrowerDelete = () => {
    const [winery, setWinery] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [inputValid, setInputValid] = useState(false);

    const { getWineryDocument, deleteWinery } = useFirestore();
    const { register, handleSubmit } = useForm();
    const params = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        const getWineryDocumentFromDB = async () => {
            const wineryDoc = await getWineryDocument(params.id);

            setWinery(wineryDoc);
            setLoading(false);
        }

        getWineryDocumentFromDB();
    }, []);

    const validateInput = (data) => {
        let isInputValid = false;
        if (data !== undefined && winery !== undefined) {
            isInputValid = data.toLowerCase() === winery.name.toLowerCase();
            setInputValid(isInputValid);
        }

        return isInputValid;
    }

    const onSubmit = async (data) => {
        setError("");
        if (validateInput(data.wineryName)) {
            try {
                setLoading(true);
                await deleteWinery(winery.id);
            } catch (ex) {
                console.error(ex)
                setLoading(false);
            } finally {
                navigate(-1);
            }

        } else {
            setError("Entered winery name is incorrect.");
        }
    }


    if (winery === undefined) return <Spinner />

    return (
        <div style={{ marginTop: "50px" }}>
            <Spinner isLoading={loading} />
            <p>Are you sure you want to delete {winery.name}?</p>
            <p>Write the name '<b>{winery.name}</b>' in the input field and click the button to delete the winery for good!</p>

            <form onSubmit={handleSubmit(onSubmit)}>
                <input
                    type="text"
                    {...register('wineryName')}
                    required="required"
                    onChange={(event) => validateInput(event.target.value)}
                    style={{ height: 40, padding: 5 }} />

                <button type="submit" className="ui submit red button" style={{ marginTop: '30px', }} disabled={!inputValid}>
                    {t("translation.words.delete")}
                </button>
            </form>

            {error !== "" && (
                <p style={{ color: "red", fontWeight: "bold" }}>{error}</p>
            )}
        </div>
    );
}

export default WinegrowerDelete