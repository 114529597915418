import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from "../../logic/useSessionStorage";
import { useForm } from "react-hook-form";
import { firestore } from "../../firebase/config";
import { createCellarRegistration, createRegistration } from "../../firebase/registrations";
import { useNavigate } from "react-router-dom";
import { serverTimestamp, collection, doc, onSnapshot, query } from 'firebase/firestore';
import GoBackButton from "../../components/FormComponents/GoBackButton";
import { createBatchDocument, updateBatch } from "../../firebase/batch";
import { useFirestore } from "../../context/FirestoreContext";

const AddLitersWineStorage = () => {
    const [wineStorages, setWineStorages] = useState([]);

    const navigate = useNavigate();
    const { getBatchById } = useFirestore();
    const [harvestData, setHarvestData] = useSessionStorage("harvestData", "");
    const [cellarRegistrationData, setCellarRegistrationData] = useSessionStorage("cellarReg", "");
    const [batchData, setBatchData] = useSessionStorage("batchData", "");
    const { register, handleSubmit } = useForm();
    const formClassname = "ui big form twelve wide column";
    const [currentWinery, setCurrentWinery] = useSessionStorage(
        "currentwinery",
        ""
    );
    const [batch, setBatch] = useSessionStorage("batch", "");
    const { t } = useTranslation();

    useEffect(() => {
        const wineStoragesColRef = collection(firestore, "winegrowers", currentWinery, "wineStorages");

        const unsubscribe = onSnapshot(query(wineStoragesColRef), (snapshot) => {
            const wineStorageDoc = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            let selectedWineStorages = [];
            //check if we are doing a harvest registration or cellar registration (different object in sessionStorage)
            if (harvestData !== "") {
                for (let i = 0; i < wineStorageDoc.length; i++) {
                    for (let j = 0; j < harvestData.wineStorages.length; j++) {
                        if (wineStorageDoc[i].id === harvestData.wineStorages[j].id) {
                            selectedWineStorages.push(wineStorageDoc[i]);
                        }
                    }
                }
            } else {
                for (let i = 0; i < wineStorageDoc.length; i++) {
                    for (let j = 0; j < cellarRegistrationData.wineStorages.length; j++) {
                        if (wineStorageDoc[i].id === cellarRegistrationData.wineStorages[j].id) {
                            selectedWineStorages.push(wineStorageDoc[i]);
                        }
                    }
                }
            }
            setWineStorages(selectedWineStorages);
        })
        return () => unsubscribe();
    }, [])

    const onSubmit = (data) => {

        // again a check for harvestRegistration or cellarRegistration
        if (harvestData !== "") {
            let newHarvestData;
            newHarvestData = {
                ...harvestData
            }


            let wineStorages = [];
            harvestData.wineStorages.forEach((storage) => {
                let wineStorageObject = {};
                wineStorageObject.id = storage.id;
                wineStorageObject.litersForReport = Number(data[storage.id]);
                wineStorages.push(wineStorageObject);
            })
            newHarvestData.wineStorages = wineStorages;

            let newBatchData;


            let dataForDb = { ...newHarvestData };
            dataForDb.date = serverTimestamp();
            try {
                getBatchById(batch, currentWinery).then((batch) => {
                    newBatchData = {
                        ...batch
                    }
                    newBatchData.wineStorages = wineStorages
                    updateBatch(currentWinery, harvestData.batchId, newBatchData).then(
                        createRegistration(currentWinery, dataForDb).then(
                            sessionStorage.removeItem("cellarReg"),
                            navigate("/")
                        )
                    )
                })
            } catch (error) {
                console.error(error)
            }
        } else {
            let newCellarData = { ...cellarRegistrationData }
            let newBatchData = { ...batchData }
            let wineStorages = [];
            cellarRegistrationData.wineStorages.forEach((storage) => {
                let wineStorageObject = {};
                wineStorageObject.id = storage.id;
                wineStorageObject.litersForReport = Number(data[storage.id])
                wineStorages.push(wineStorageObject);
            })
            newCellarData.wineStorages = wineStorages;
            newBatchData.wineStorages = wineStorages;
            newBatchData.createDate = serverTimestamp();

            //check if the old batches needs to be deactivated
            if (newCellarData.keepOldBatch == false) {
                let batchToDelete = {};
                getBatchById(newCellarData.batchId, currentWinery).then((batch) => {
                    batchToDelete = { ...batch };
                    batchToDelete.wineStorages = [];
                    try {
                        updateBatch(currentWinery, newCellarData.batchId, batchToDelete).then((doc) => {
                            cellarRegistrationData.batchId = doc.id
                        }
                        )
                    } catch (error) {
                        console.error(error);
                    }
                })


            }

            // check if registration use startDate and endDate or just date
            if (cellarRegistrationData.date !== undefined) {
                newCellarData.date = new Date(cellarRegistrationData.date);
            } else {
                newCellarData.startDate = new Date(cellarRegistrationData.startDate);
                newCellarData.endDate = new Date(cellarRegistrationData.endDate);
                newCellarData.date = serverTimestamp();
            }

            try {
                createBatchDocument(currentWinery, newBatchData).then((batchRegistration) => {
                    newCellarData.batchId = batchRegistration.id
                    createCellarRegistration(currentWinery, newCellarData).then(
                        sessionStorage.removeItem("cellarReg"),
                        sessionStorage.removeItem("batchData"),
                        navigate("/")
                    )
                })

            } catch (error) {
                console.error(error);
            }
        }
    }


    const generateForm = () => {
        let form = [];
        wineStorages.forEach((storage) => {
            form.push(
                <div
                    className="fields"
                    style={{ justifyContent: "center", marginTop: "20px" }}>
                    <div className="twelve wide field">
                        <label>
                            {t("translation.harvestRegistration.amountFor")} {storage.name}
                            <input
                                type="number"
                                {...register(storage.id)}
                                style={{ textAlign: "center" }}
                                required />
                        </label>
                    </div>
                </div>
            )
        })
        return form
    }

    return (
        <div>
            <h1>{t("translation.harvestRegistration.addAmount")}</h1>
            <GoBackButton />
            <form
                className={formClassname}
                style={{
                    border: "3px solid #B3B3B3",
                    marginTop: "50px",
                    margin: "auto",
                    borderRadius: "50px",
                    background: "#edeaea",
                    borderColor: "#ac9e66",
                    maxWidth: "960px"
                }}
                onSubmit={handleSubmit(onSubmit)}>
                {generateForm()}
                <button
                    type="submit"
                    className="ui submit large grey button right floated  button-vinyards"
                    style={{ float: "none", marginTop: "30px", marginBottom: "30px", width: "25%" }}
                >
                    {t("translation.harvestRegistration.finishRegistration")}
                </button>
            </form>
        </div>
    )

}
export default AddLitersWineStorage;