import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFirestore } from '../context/FirestoreContext';
import { firestore } from '../firebase/config';
import { PageTitle } from '../utils/PageTitle';
import GoBackButton from "../components/FormComponents/GoBackButton";
import { onSnapshot, collection, where, query, getDocs } from 'firebase/firestore';
import MaterialTable from '@material-table/core';


const Wineries = () => {
  PageTitle("Wineries");
  const { currentUserDoc } = useFirestore();
  const navigate = useNavigate();
  const [wineries, setWineries] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (currentUserDoc !== undefined) {
      const winegrowerIds = currentUserDoc.wineries
        .filter((w) => w.approved === true && w.accepted === true)
        .map((w) => w.winegrower);

      if (winegrowerIds.length > 0) {
        const wineryCollection = collection(firestore, 'winegrowers');
        const wineryRef = query(wineryCollection, where('__name__', 'in', winegrowerIds));
  
        const unsubscribe = onSnapshot(wineryRef, (querySnapshot) => {
          const wineries = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            role: currentUserDoc.wineries.find((w) => w.winegrower === doc.id).role,
            ...doc.data(),
          }));

          setWineries(wineries);
          setLoading(false);
        });
        return () => {
          unsubscribe();
        };
      } else {
        setLoading(false);
      }
    }
  }, [currentUserDoc]);



  return (
    // Het weergeven van alle gegevens van alle wineries in een tabel
    <div style={{ position: 'relative' }}>
      <h1 style={{ marginTop: '90px', color: '#bcbcbc' }}>{t('vertaling.h13_dashboard')}</h1>
      <GoBackButton />
      <br />
      <br />
      <div className="materialList">
        <MaterialTable
          title=""
          data={wineries}
          columns={[
            {
              title: t('vertaling.winery_name'),
              field: 'name',
            },
            {
              title: t('vertaling.adress'),
              field: 'adress',
            },
            {
              title: t('vertaling.city'),
              field: 'city',
            },
            {
              title: t('vertaling.companyTel'),
              field: 'phone',
            },
            {
              title: t('vertaling.colYourRole'),
              field: 'role',
            },
          ]}
          options={{
            search: true,
            filtering: true,
            exportButton: true,
            exportAllData: true,
            headerStyle: {
              backgroundColor: '#cecaca',
              color: '#FFF',
            },
            emptyRowsWhenPaging: false,
            paging: true,
            pageSize: 50,
            pageSizeOptions: [
              10,
              20,
              25,
              50,
            ],
          }}
          // Dit wordt gebruikt om alles te vertalen in de tabel die hierboven wordt gedefineerd
          localization={{
            body: {
              emptyDataSourceMessage: t('vertaling.emptyDataSourceMessage'),
              addTooltip: t('vertaling.addTooltip'),
              deleteTooltip: t('vertaling.deleteTooltip'),
              editTooltip: t('vertaling.editTooltip'),
              filterRow: {
                filterTooltip: t('vertaling.filterTooltip'),
              },
              editRow: {
                deleteText: t('vertaling.deleteText'),
                cancelTooltip: t('vertaling.cancelTooltip'),
                saveTooltip: t('vertaling.submit '),
              },
            },
            grouping: {
              placeholder: t('vertaling.placeholder'),
              groupedBy: t('vertaling.groupedBy'),
            },
            header: {
              actions: t('vertaling.actions'),
            },
            pagination: {
              labelDisplayedRows: t('vertaling.labelDisplayedRows'),
              labelRowsPerPage: t('vertaling.labelRowsPerPage'),
              firstAriaLabel: t('vertaling.firstAriaLabel'),
              firstTooltip: t('vertaling.firstAriaLabel'),
              previousAriaLabel: t('vertaling.previousAriaLabel'),
              previousTooltip: t('vertaling.previousAriaLabel'),
              nextAriaLabel: t('vertaling.nextAriaLabel'),
              nextTooltip: t('vertaling.nextAriaLabel'),
              lastAriaLabel: t('vertaling.lastAriaLabel'),
              lastTooltip: t('vertaling.lastAriaLabel'),
            },
            toolbar: {
              addRemoveColumns: t('vertaling.addRemoveColumns'),
              nRowsSelected: t('vertaling.nRowsSelected'),
              showColumnsTitle: t('vertaling.showColumnsTitle'),
              showColumnsAriaLabel: t('vertaling.showColumnsTitle'),
              exportTitle: t('vertaling.exportTitle'),
              exportAriaLabel: t('vertaling.exportTitle'),
              exportName: t('vertaling.exportName'),
              searchTooltip: t('vertaling.searchTooltip'),
              searchPlaceholder: t('vertaling.searchTooltip'),
              exportCSVName: t('vertaling.exportName'),
              exportPDFName: t('vertaling.exportPDFName'),
            },
          }}
        />
      </div>
      <br />
    </div>
  );
};

export default Wineries;