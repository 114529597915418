import { PageTitle } from "../../utils/PageTitle";
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from "react";
import { doc, onSnapshot } from 'firebase/firestore';
import { firestore } from "../../firebase/config";
import { useSessionStorage } from "../../logic/useSessionStorage";
import { Link, useParams } from "react-router-dom";
import GoBackButton from "../../components/FormComponents/GoBackButton";
import { updateContentCellarStep } from "../../firebase/cellarSteps";
import { useNavigate } from "react-router-dom";
import MaterialTable from '@material-table/core';


const CellarStepsList = () => {
    PageTitle('Cellar Step List');
    const params = useParams();
    const navigate = useNavigate();
    const [currentWinery, setCurrentWinery] = useSessionStorage("currentwinery", "");

    const [contentCellarStep, setContentCellarStep] = useState([]);
    const [currentCellarStep, setCurrentCellarStep] = useState([]);
    const { t } = useTranslation();


    let currentStep;
    useEffect(() => {
        const cellarStepDocRef = doc(firestore, 'winegrowers', currentWinery, 'cellarSteps', params.id);

        const unsubscribe = onSnapshot(cellarStepDocRef, (doc) => {
            if (doc.exists) {
                currentStep = {
                    ...doc.data(),
                    id: doc.id
                }
                if (
                    currentStep.content === undefined ||
                    currentStep.content.length === 0
                ) {
                } else {
                    if (currentStep.content[0].key === null) {
                        currentStep.content[0].key = "Action";
                    }
                    currentStep.content.sort((a, b) => (a.order > b.order ? 1 : -1));
                    setContentCellarStep(currentStep.content);
                    setCurrentCellarStep(currentStep);
                }
            }
        })
        return () => {
            unsubscribe();
        };

    }, [currentWinery, params.id])

    async function deleteCellarStepContent(cellarStepContentKey) {
        let newDoc = { ...currentCellarStep }
        for (let i = 0; i < newDoc.content.length; i++) {
            if (newDoc.content[i].key === cellarStepContentKey) {
                newDoc.content.splice(i, 1);
            }
        }

        await updateContentCellarStep(currentWinery, currentCellarStep.id, newDoc);

    }

    return (
        <div
            style={{
                margin: "50px 50px",
                marginTop: "110px",
            }}>
            <div>
                <div>
                <h1 style={{ color: "#7f7f7f" }}>{t('translation.cellarSteps.cellarStepContent')}</h1>

                </div>
                <GoBackButton />
            </div>
            <div className="newvineyard">
                <Link to={`/cellarsteps/newcellarstepcontent/${params.id}`}
                    style={{ color: "white" }}
                >
                    <button className="ui primary button login" style={{ marginTop: '25px' }}>
                        {t('translation.cellarSteps.addCellarStepContent')}
                    </button>
                </Link>
            </div>
            <div className="batchesList">
                <MaterialTable
                    title=""
                    data={contentCellarStep}
                    columns={[
                        {
                            title: t('translation.vineyardSteps.order'),
                            field: "order",
                            sort: true
                        },
                        {
                            title: "type",
                            field: "key",
                        },
                        {
                            title: "",
                            field: "",
                            sorting: false,
                            render: (rowData) => (
                                <button
                                    className="ui primary button login"
                                    onClick={() => {
                                        deleteCellarStepContent(rowData.key);
                                    }}
                                >
                                    {t("translation.words.delete")}
                                </button>
                            ),
                        },
                    ].map((c) => ({
                        render: (row) => (
                            <Link
                                to={`/cellarsteps/cellarstepcontentdetail/${params.id}/${row.order}`}
                            >
                                {c.lookup ? c.lookup[row[c.field]] : row[c.field]}
                            </Link>
                        ),
                        ...c
                    }))}
                    options={{
                        filtering: true,
                        exportButton: true,
                        exportAllData: true,
                        headerStyle: {
                            backgroundColor: "#cecaca",
                            color: "#FFF",
                        },
                        emptyRowsWhenPaging: false,
                        paging: true,
                        pageSize: 50,
                        pageSizeOptions: [
                            10,
                            20,
                            25,
                            50,
                        ],
                    }}
                    components={{
                        Action: (props) => (
                            <div className="flex_grape">
                                <button
                                    className="ui primary button login"
                                    onClick={(event) => props.action.onClick(event, props.data)}
                                    color="primary"
                                    style={{ textTransform: "none" }}
                                >
                                    {t("vertaling.details")}
                                </button>
                                <button
                                    className="ui primary button login "
                                    onClick={(event) => props.action.create(event, props.data)}
                                    color="primary"
                                    style={{ textTransform: "none", minWidth: 175 }}
                                >
                                    {t("vertaling.grapevariety_btn")}
                                </button>
                            </div>
                        ),
                    }}
                />
            </div>
        </div>
    )

}
export default CellarStepsList;