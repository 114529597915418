import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useSessionStorage } from "../../logic/useSessionStorage";
import Select from "react-select";
import { firestore } from "../../firebase/config";
import { useNavigate, useParams } from "react-router-dom";
import { updateVineyardStep } from "../../firebase/vineyardstep";
import GoBackButton from "../../components/FormComponents/GoBackButton";
import { doc, onSnapshot } from 'firebase/firestore';

const VineyardStepDetail = () => {
  const navigate = useNavigate();
  const formClassname = "ui big form twelve wide column";
  const { register, setValue, handleSubmit } = useForm();
  const selectionTypes = ["multi", 'single'];
  const [selectedSelection, setSelectedSelection] = useState("");
  const [currentVineyardStep, setCurrentVineyardStep] = useState([]);
  const [error, setError] = useState("hidden");
  const [loading, setLoading] = useState(true);
  const [currentWinery, setCurrentWinery] = useSessionStorage("currentwinery", "");
  const params = useParams();
  const { t } = useTranslation();

  const onSubmit = async (data) => {
    try {
      if (selectedSelection == "") {
        setError("visible");
      } else {
        setError("hidden");
        let newDoc = { ...currentVineyardStep };
        newDoc = data;
        newDoc.selection = selectedSelection;
        await updateVineyardStep(currentWinery, params.id, newDoc);
        navigate(-1);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleSelectionChange = (selection, action) => {
    setSelectedSelection(selection.value);
  };

  var currentStep;
  useEffect(() => {
    const vineyardStepRef = doc(firestore, "winegrowers", currentWinery, "vineyardSteps", params.id);

    const unsubscribe = onSnapshot(vineyardStepRef, (doc) => {
      if (doc.exists) {
        currentStep = doc.data();
        if (doc.data().selection != null) {
          setSelectedSelection(doc.data().selection);
        }
      }
      setCurrentVineyardStep(currentStep);
      setLoading(false);
    });
    return () => unsubscribe();
  }, [params.id]);

  return (
    <div>
      <div
        className="add-form-container"
        style={{
          maxWidth: 960,
          margin: "50px auto",
          marginTop: "110px",
        }}
      >
        <h1 style={{ color: "#7f7f7f" }}> {t("translation.vineyardSteps.updateVineyardStep")} </h1>
        <GoBackButton />

        {currentVineyardStep.content != undefined && (
          <div className="ui grid stackable">
            <form
              className={formClassname}
              style={{
                border: "3px solid #B3B3B3",
                marginTop: "100px",
                borderRadius: "50px",
                background: "#edeaea",
                borderColor: "#ac9e66",
              }}
              onSubmit={handleSubmit(onSubmit)}
            >
              <div
                className="fields"
                style={{ justifyContent: "center", marginTop: "20px" }}
              >
                <div className="twelve wide field">
                  <label>
                    <p>{t("translation.vineyardSteps.nameEn")}</p>
                    <input
                      type="text"
                      {...register('nameEN')}
                      htmlFor="nameEN"
                      required="required"
                      defaultValue={currentVineyardStep.nameEN}
                      style={{ textAlign: "center" }} />
                  </label>
                </div>
              </div>
              <div
                className="fields"
                style={{ justifyContent: "center", marginTop: "20px" }}
              >
                <div className="twelve wide field">
                  <label>
                    <p>{t("translation.vineyardSteps.nameFr")}</p>
                    <input
                      type="text"
                      {...register('nameFR')}
                      htmlFor="nameFR"
                      required="required"
                      defaultValue={currentVineyardStep.nameFR}
                      style={{ textAlign: "center" }} />
                  </label>
                </div>
              </div>
              <div
                className="fields"
                style={{ justifyContent: "center", marginTop: "20px" }}
              >
                <div className="twelve wide field">
                  <label>
                    <p>{t("translation.vineyardSteps.nameIt")}</p>
                    <input
                      type="text"
                      {...register('nameIT')}
                      htmlFor="nameIT"
                      required="required"
                      defaultValue={currentVineyardStep.nameIT}
                      style={{ textAlign: "center" }} />
                  </label>
                </div>
              </div>
              <div
                className="fields"
                style={{ justifyContent: "center", marginTop: "20px" }}
              >
                <div className="twelve wide field">
                  <label>
                    <p>{t("translation.vineyardSteps.nameNl")}</p>
                    <input
                      type="text"
                      {...register('nameNL')}
                      htmlFor="nameNL"
                      required="required"
                      defaultValue={currentVineyardStep.nameNL}
                      style={{ textAlign: "center" }} />
                  </label>
                </div>
              </div>
              <div
                className="fields"
                style={{ justifyContent: "center", marginTop: "20px" }}
              >
                <div className="twelve wide field">
                  <label>
                    <p>{t("translation.vineyardSteps.number")}</p>
                    <input
                      type="number"
                      {...register('number')}
                      htmlFor="number"
                      required="required"
                      defaultValue={currentVineyardStep.number}
                      style={{ textAlign: "center" }} />
                  </label>
                </div>
              </div>
              <div
                className="fields"
                style={{ justifyContent: "center", marginTop: "20px" }}
              >
                <div className="twelve wide field">
                  <label>
                    <p>{t("translation.vineyardSteps.selectionType")}</p>
                  </label>
                  <Select
                    required="required"
                    label="selection"
                    htmlFor="selection"
                    options={selectionTypes.map((label) => ({
                      label: label,
                      value: label,
                    }))}
                    defaultValue={{
                      label: currentVineyardStep.selection,
                      value: currentVineyardStep.selection,
                    }}
                    onChange={(selection, action) =>
                      handleSelectionChange(selection, action)
                    }
                  />
                </div>
              </div>
              <div style={{ display: "none" }}>
                <input
                  {...register('selection')}
                  htmlFor="selection"
                  value={selectedSelection}
                  readOnly />
              </div>
              <p style={{ visibility: error }} className="text-danger">
                {t("translation.vineyardSteps.selectionTypeNotSelected")}
              </p>
              <button
                type="submit"
                className="ui submit large grey button right floated  button-vinyards"
                style={{ float: "none", marginTop: "30px", width: "25%" }}
              >
                {t("translation.vineyardSteps.updateVineyardStep")}
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default VineyardStepDetail;