import React from 'react';
import { read, utils } from 'xlsx';

export const ExcelImporter = ({ onFileProcessed }) => {

  const looksLikeDate = (str) => {
    // Simple regex to check if a string looks like dd/mm/yyyy or dd-mm-yyyy
    return /^\d{2}[\/\-]\d{2}[\/\-]\d{4}$/.test(str);
  };

  const handleFileChange = (e) => {
    if (!e.target.files[0]) {
      // No file selected, no need to throw anything
      return;
    }

    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = read(data, { type: 'array', cellDates: true });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      // Convert the worksheet to JSON with a default value for empty cells (this is important for recognising the language!)
      var jsonData = utils.sheet_to_json(worksheet, { defval: "" }); 

      // Check and adjust cells that look like dates but are strings
      jsonData = jsonData.map(row => {
        for (let key in row) {
          if (typeof row[key] === 'string' && looksLikeDate(row[key])) {
            let [day, month, year] = row[key].split(/\/|-/);
            row[key] = new Date(year, month - 1, day);
          }
        }
        return row;
      });

      // Send data to the parent component
      onFileProcessed(jsonData);
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <input type="file" accept=".xlsx,.xls" onChange={handleFileChange} />
  );
};
