import React from 'react';
import { ExcelImporter } from '../Importers/ExcelImporter';
import dutch from '../../lang/nl';
import english from '../../lang/en';
import french from '../../lang/fr';

const languageMappings = {
  dutch: dutch.translation.excelImport,
  english: english.translation.excelImport,
  french: french.translation.excelImport,
};

const filterData = (data, categoryKey, subcategoryValue, anyCrop) => {
  return data.filter(row => {
    const categoryValue = row[categoryKey];
    return categoryValue && (categoryValue.includes(subcategoryValue) || categoryValue.includes(anyCrop));
  });
};

export const ChemicalsExcelFilter = ({onFilteredData}) => {

  const processExcel = (data) => {
    let filteredData = null;
    let deteminedLanguage = null;

    for (const [language, { category, subcategory, anyCrop }] of Object.entries(languageMappings)) {
      const result = filterData(data, category, subcategory, anyCrop);
      if (result.length > 0) {
        filteredData = result;
        deteminedLanguage = language;
        break; // Exit loop once a match is found
      }
    }

    if (filteredData) {
      onFilteredData(filteredData, deteminedLanguage);
    } else {
      alert("Document not recognized. Please upload a valid file.");
    }
  };

  return (
    <div>
      <ExcelImporter onFileProcessed={processExcel} />
    </div>
  );
};
