import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { doc, collection, where, onSnapshot, query, orderBy } from 'firebase/firestore';
import { firestore } from "../firebase/config";
import { useParams, useNavigate, Link } from "react-router-dom";
import Select from "react-select";
import GoBackButton from "../components/FormComponents/GoBackButton";
import { useSessionStorage } from "../logic/useSessionStorage";
import { dateObjectToDate, timestampToDate } from "../utils/DateConversions";
import { PageTitle } from "../utils/PageTitle";

const TraceBatchUpdated = () => {
    PageTitle("Trace Batch");
    const navigate = useNavigate();
    const params = useParams();
    const { i18n } = useTranslation();
    const lang = i18n.language;
    const [currentWinery, setCurrentWinery] = useSessionStorage(
        "currentwinery",
        ""
    );
    const [batches, setBatches] = useState([]);
    const [vineyardContent, setVineyardContent] = useState(false);
    const [cellarContent, setCellarContent] = useState(true);
    const [harvestToggle, setHarvestToggle] = useState(true);
    const [agriculturalParcels, setAgriculturalParcels] = useState(undefined);
    const [harvestYears, setHarvestYears] = useState(undefined);

    const [vineyardRegs, setVineyardRegs] = useState([]);
    const [vineyardDateState, setVineyardDateState] = useState();
    const [cellarRegs, setCellarRegs] = useState([]);
    const [vineyards, setVineyards] = useState([]);
    const [selectedBatch, setSelectedBatch] = useState(undefined);
    const [wineStorages, setWineStorages] = useState(undefined);
    const [bottleTypes, setBottleTypes] = useState(undefined);
    const [harvestContent, setHarvestContent] = useState([]);
    const [cellarSteps, setCellarSteps] = useState([]);
    const [vineyardSteps, setVineyardSteps] = useState([]);
    const [harvestSteps, setHarvestSteps] = useState([]);
    const { t } = useTranslation();
    let currentLang = "nameNL";
    // Referenties naar firestore database (bv : ophalen van collecties uit firestore db)
    // Alle waarden in de variabelen gaan verloren éénmaal de useEffect volledig uitgevoerd is

    const getImageUrl = async (id) => {
        const storage = getStorage();
        const storageRef = ref(storage, 'images/' + id + '.png');

        try {
            const url = await getDownloadURL(storageRef);
            return url;
        } catch (error) {
            return '';
        }
    };

    const useFirestoreSnapshot = (ref, callback) => {
        useEffect(() => {
            const unsubscribe = onSnapshot(ref, (snapshot) => {
                const docs = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                callback(docs);
            });

            return () => {
                unsubscribe();
            };
        }, []);
    };

    function useConditionalFirestoreSnapshot(query, onDataChanged, condition) {
        useFirestoreSnapshot(query, snapshot => {
            if (!condition) return;
            onDataChanged(snapshot);
        });
    }

    useFirestoreSnapshot(collection(firestore, "winegrowers", currentWinery, "wineStorages"), (wineStorages) => {
        setWineStorages(wineStorages);
    });


    useFirestoreSnapshot(collection(firestore, "winegrowers", currentWinery, "bottletypes"), (types) => {
        setBottleTypes(types);
    });

    useFirestoreSnapshot(collection(firestore, "winegrowers", currentWinery, "batches"), (batches) => {
        batches.forEach((batch) => {
            if (batch.id === params.batch) {
                setSelectedBatch(batch);
            }
        });

        batches.sort(function (a, b) {
            return b.createDate - a.createDate;
        });

        // overlopen van alle batches
        for (let i = 0; i < batches.length; i++) {
            batches[i].createDate = timestampToDate(
                batches[i].createDate,
                "DD MMM YYYY, H:mm"
            );
        }
        setBatches(batches);
    });

    useFirestoreSnapshot(collection(firestore, "winegrowers", currentWinery, "agriculturalParcels"), (agriParcels) => {
        setAgriculturalParcels(agriParcels);
    });

    useFirestoreSnapshot(collection(firestore, "winegrowers", currentWinery, "harvestYear"), (years) => {
        setHarvestYears(years);
    });



    useConditionalFirestoreSnapshot(collection(firestore, "winegrowers", currentWinery, "vineyards"), (snapshot) => {
        const vineyards = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        }));
        setVineyards(vineyards);
    });

    useEffect(() => {
        const vineyardStepsRef = collection(firestore, 'winegrowers', currentWinery, 'vineyardSteps');
        const cellarStepsRef = collection(firestore, 'winegrowers', currentWinery, 'cellarSteps');
        const harvestStepDocRef = doc(firestore, 'winegrowers', currentWinery, 'customSteps', 'harvestStep');

        const unsubscribe = onSnapshot(vineyardStepsRef, (snapshot) => {
            const vineyardSteps = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setVineyardSteps(vineyardSteps);
        })

        const unsubscribe2 = onSnapshot(cellarStepsRef, (snapshot) => {
            const cellarSteps = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setCellarSteps(cellarSteps);
        }
        )

        const unsubscribe3 = onSnapshot(harvestStepDocRef, (docSnapshot) => {
            if (docSnapshot.exists()) {
                const harvestStepData = {
                    id: docSnapshot.id,
                    ...docSnapshot.data(),
                };
                setHarvestSteps(harvestStepData);
            } else {
                // Handle the case where the document does not exist (e.g., set to null or an empty object)
                setHarvestSteps(null);
            }
        }
        )

        return () => {
            unsubscribe();
            unsubscribe2();
            unsubscribe3();
        }
    }, [currentWinery]);

    useEffect(() => {
        if (lang === 'en') {
            currentLang = "nameEN";
        } else if (lang === 'fr') {
            currentLang = "nameFR";
        } else if (lang === 'it') {
            currentLang = "nameIT";
        } else if (lang === 'nl') {
            currentLang = "nameNL";

        }

        if (params.batch) {
            const regRefQuery = query(
                collection(firestore, "winegrowers", currentWinery, "registrations"),
                where("waitingForReview", "==", false),
                orderBy("date")
            );

            const unsubscribe = onSnapshot(regRefQuery, async (snapshot) => {
                let regs = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                // overlopen van alle registraties
                for (let i = 0; i < regs.length; i++) {
                    regs[i].date = timestampToDate(
                        regs[i].date,
                        "DD MMM YYYY, H:mm"
                    );

                    //overlopen van alle registratie die een harvestStap zijn


                    if (regs[i].type === "harvestStep") {
                        if (harvestSteps.id === regs[i].stepId) {
                            if (lang === 'en') {
                                regs[i].stepId = harvestSteps.nameEN;
                            } else if (lang === 'fr') {
                                regs[i].stepId = harvestSteps.nameFR;
                            } else if (lang === 'it') {
                                regs[i].stepId = harvestSteps.nameIT;
                            } else if (lang === 'nl') {
                                regs[i].stepId = harvestSteps.nameNL;
                            } else console.log("Translation Failure, please contact support");
                        }

                    }
                    // overlopen van alle registraties die een wijngaardstap zijn en het toekennen van de correct naam in de juiste taal
                    else if (regs[i].type === "vineyardStep") {
                        for (let h = 0; h < vineyardSteps.length; h++) {
                            if (vineyardSteps[h].id === regs[i].stepId) {
                                if (lang === 'en') {
                                    regs[i].stepId = vineyardSteps[h].nameEN;
                                } else if (lang === 'fr') {
                                    regs[i].stepId = vineyardSteps[h].nameFR;
                                } else if (lang === 'it') {
                                    regs[i].stepId = vineyardSteps[h].nameIT;
                                } else if (lang === 'nl') {
                                    regs[i].stepId = vineyardSteps[h].nameNL;
                                } else console.log("Translation Failure, please contact support");
                            }
                        }

                        // overlopen van alle registraties die een kelderstap zijn en het toekennen van de correct naam in de juiste taal

                    } else if (regs[i].type === "cellarStep") {
                        for (let h = 0; h < cellarSteps.length; h++) {
                            if (cellarSteps[h].id === regs[i].stepId) {
                                if (lang === 'en') {
                                    regs[i].stepId = cellarSteps[h].nameEN;
                                } else if (lang === 'fr') {
                                    regs[i].stepId = cellarSteps[h].nameFR;
                                } else if (lang === 'it') {
                                    regs[i].stepId = cellarSteps[h].nameIT;
                                } else if (lang === 'nl') {
                                    regs[i].stepId = cellarSteps[h].nameNL;
                                } else console.log("Translation Failure, please contact support");
                            }
                        }
                    }
                }

                var cellarRegsFilter = [];
                // overlopen van alle registraties en toevoegen van alle registraties aan regsFilter array
                //waarbij batchId OF sourceBatchId gelijk zijn aan opgevraagde batch in de selectlist.
                //Ook kijken we of er een split of merge is waarbij we de correct splitBatch opslaan of verschillende sourceBatchIds.
                for (let i = 0; i < regs.length; i++) {
                    if (regs[i].type === "cellarStep") {
                        if (regs[i].batchId === params.batch) {
                            cellarRegsFilter.push(regs[i]);
                        }

                        if (regs[i].sourceBatches !== undefined) {
                            regs[i].sourceBatches.forEach((batch) => {
                                if (batch.id === params.batch) {
                                    cellarRegsFilter.push(regs[i]);
                                }
                            });
                        }
                    }
                }

                if (cellarRegsFilter.length > 0) {
                    cellarRegsFilter.sort(function (a, b) {
                        return new Date(a.date) - new Date(b.date);
                    });

                    await Promise.all(
                        cellarRegsFilter.map(async (reg) => {
                            const url = await getImageUrl(reg.id);
                            reg.imageUrl = url;
                        })
                    );
                    setCellarRegs(cellarRegsFilter);
                } else {
                    setCellarRegs([]);
                }


                var vineyardRegsFilter = [];

                let lastVineyardStep;
                for (let i = 0; i < regs.length; i++) {
                    if (regs[i].type === "vineyardStep") {
                        if (regs[i].batchId === params.batch) {
                            if (
                                !lastVineyardStep ||
                                new Date(regs[i].date) > new Date(lastVineyardStep.date)
                            ) {
                                vineyardRegsFilter.push(regs[i]);
                                lastVineyardStep = regs[i];
                            }
                        }
                    }
                }

                let harvestRegistration = [];
                for (let i = 0; i < regs.length; i++) {
                    if (regs[i].type === "harvestStep") {
                        if (regs[i].batchId === params.batch) {
                            harvestRegistration.push(regs[i]);
                        }
                    }
                }
                setHarvestContent(harvestRegistration);

                if (vineyardRegsFilter.length > 0) {
                    vineyardRegsFilter.sort(function (a, b) {
                        return new Date(a.date) - new Date(b.date);
                    });

                    await Promise.all(
                        vineyardRegsFilter.map(async (reg) => {
                            const url = await getImageUrl(reg.id);
                            reg.imageUrl = url;
                        })
                    );

                    const firstdate = undefined;
                    const lastdate =
                        vineyardRegsFilter[vineyardRegsFilter.length - 1].date;

                    setVineyardDateState({
                        firstdate: firstdate ? dateObjectToDate(firstdate) : "",
                        lastdate: lastdate ? dateObjectToDate(lastdate) : "",
                    });

                    setVineyardRegs(vineyardRegsFilter);
                } else {
                    setVineyardRegs([]);
                }
            });
            return () => {
                unsubscribe();
            }
        };
    }, [params.batch, vineyardSteps]);


    return (
        <div>
            <h1>{t("vertaling.trace_batch")}</h1>
            <GoBackButton location={"/"}/>
            <div className="container tracebatch-content">
                <div className="row header">
                    <div style={{ width: "350px" }}>
                        <Select
                            id="batch-select"
                            placeholder={
                                selectedBatch ? selectedBatch.name : t("vertaling.select_batch")
                            }
                            options={batches.map((name, id) => ({
                                value: name.id,
                                label: name.name,
                            }))}
                            value={
                                params.batch &&
                                batches.some((b) => b.batch === params.batch) && {
                                    value: params.batch
                                        ? batches.find((b) => b.batch === params.batch)?.id
                                        : "",
                                    label: selectedBatch.name,
                                }
                            }
                            onChange={(selection, action) =>
                                navigate(`/traceBatchUpdated/${selection.value}`)
                            }
                            className="specialty"
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 4,
                                colors: {
                                    ...theme.colors,
                                    primary25: "#dacfa4b2",
                                    primary: "#AC9E66",
                                },
                            })}
                        />
                    </div>
                    <br />
                </div>
                {vineyardRegs.length > 0 && (
                    <div
                        className="dropdown"
                        onClick={() => setVineyardContent(!vineyardContent)}
                    >
                        <span> {t('vertaling.vineyard_steps')} </span>
                        <span
                            className={`arrow ${vineyardContent ? "arrow-down" : "arrow-right"
                                }`}
                        >
                            {">"}
                        </span>
                    </div>
                )}

                {vineyardContent && vineyardRegs.length > 0 && (
                    <div className="steps">
                        <div id="vineyard-link">
                            <div
                                onClick={() => {
                                    navigate({
                                        pathname: `/tracevineyard/${vineyards.find(
                                            (v) => v.vid === vineyardRegs[0].locationId
                                        ).name
                                            }`,
                                        state: vineyardDateState,
                                    });
                                }}
                            >
                                {
                                    vineyards.find((v) => v.vid === vineyardRegs[0].locationId)
                                        ?.name
                                }
                            </div>
                        </div>
                        {vineyardRegs.map((object, index) => (
                            <div>
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div style={{ flex: 1, display: 'flex', gap: '10px' }}>
                                        <div className='count'>{index + 1}</div>
                                        <div style={{ background: 'none', border: 'none', padding: 0 }}>
                                            {object.stepId + ' ' + t("vertaling.on") + ' ' + (object.date)}
                                        </div>
                                    </div>
                                    {object.email && <p style={{ margin: 0, whiteSpace: 'nowrap' }}>{object.email}</p>}
                                </div>
                                <div>
                                    <div key={index}>
                                        {object.description ? <p>{object.description}</p> : ""}
                                        {object.batchId ? <p>Batch: {object.batchId}</p> : ""}
                                        {object.sourceBatchId ? (
                                            <p>
                                                {t('vertaling.source_batch')}{" "}
                                                {object.sourceBatchId}
                                            </p>
                                        ) : (
                                            ""
                                        )}
                                        {object.temperature ? (
                                            <p>
                                                {t('vertaling.temperature')}{" "}
                                                {object.temperature}
                                            </p>
                                        ) : (
                                            ""
                                        )}
                                        {object.amountOfBottles ? (
                                            <p>
                                                {t('vertaling.amountOfBottles')}{" "}
                                                {object.amountOfBottles}
                                            </p>
                                        ) : (
                                            ""
                                        )}
                                        {object.amount ? (
                                            <p>
                                                {t('vertaling.amount')}{" "}
                                                {object.amount}
                                            </p>
                                        ) : (
                                            ""
                                        )}

                                        {object.amountDrying ? (
                                            <p>
                                                {t('vertaling.amountDrying')}{" "}
                                                {object.amountDrying}
                                            </p>
                                        ) : (
                                            ""
                                        )}

                                        {object.wayOfHarvest ? (
                                            <p>
                                                {t('vertaling.harvestMethod')}{" "}
                                                {object.wayOfHarvest}
                                            </p>
                                        ) : (
                                            ""
                                        )}

                                        {object.density ? (
                                            <p>
                                                {t('vertaling.density')}{" "}
                                                {object.density}
                                            </p>
                                        ) : (
                                            ""
                                        )}

                                        {object.acidity ? (
                                            <p>
                                                {t('vertaling.acidity')}{" "}
                                                {object.acidity}
                                            </p>
                                        ) : (
                                            ""
                                        )}

                                        {object.alcohol ? (
                                            <p>
                                                {t('vertaling.alcohol')}{" "}
                                                {object.alcohol}
                                            </p>
                                        ) : (
                                            ""
                                        )}

                                        {object.massDensity ? (
                                            <p>
                                                {t('vertaling.massDensity')}{" "}
                                                {object.massDensity}
                                            </p>
                                        ) : (
                                            ""
                                        )}

                                        {object.ph ? (
                                            <p>
                                                {t('vertaling.ph')} {object.ph}
                                            </p>
                                        ) : (
                                            ""
                                        )}

                                        {object.sugars ? (
                                            <p>
                                                {t('vertaling.sugars')}{" "}
                                                {object.sugars}
                                            </p>
                                        ) : (
                                            ""
                                        )}

                                        {object.typeOfFermentation ? (
                                            <p>
                                                {t('vertaling.fermentationType')}{" "}
                                                {object.typeOfFermentation}
                                            </p>
                                        ) : (
                                            ""
                                        )}

                                        {object.products ? (
                                            <p>
                                                {t('vertaling.productsAdded')}{" "}
                                                {object.products}
                                            </p>
                                        ) : (
                                            ""
                                        )}

                                        {object.productsUsed ? (
                                            <p>
                                                {t('vertaling.productUsed')}{" "}
                                                {object.productsUsed}
                                            </p>
                                        ) : (
                                            ""
                                        )}

                                        {object.amountUsed ? <p> {object.amountUsed}</p> : ""}

                                        {object.sourceBatchIds ? (
                                            <p>
                                                {t('vertaling.merged')}{" "}
                                                {object.sourceBatchIds.map((item2, index2) => {
                                                    return (
                                                        <Link
                                                            to={`/tracebatch/${item2}`}
                                                            key={index2}
                                                            style={{
                                                                display: "contents",
                                                                color: "#ac9e66",
                                                                cursor: "pointer",
                                                            }}
                                                        >
                                                            {" "}
                                                            <span style={{ textDecoration: "underline" }}>
                                                                {item2}
                                                            </span>{" "}
                                                        </Link>
                                                    );
                                                })}
                                            </p>
                                        ) : (
                                            ""
                                        )}

                                        {object.splitBatches ? (
                                            <p>
                                                {t('vertaling.Split')}{" "}
                                                {t('vertaling.into')}:
                                                {object.splitBatches.map((item3, index3) => {
                                                    return (
                                                        <Link
                                                            to={`/tracebatch/${item3}`}
                                                            key={index3}
                                                            style={{
                                                                display: "contents",
                                                                color: "#ac9e66",
                                                                cursor: "pointer",
                                                            }}
                                                        >
                                                            {" "}
                                                            <span style={{ textDecoration: "underline" }}>
                                                                {item3}
                                                            </span>{" "}
                                                        </Link>
                                                    );
                                                })}
                                            </p>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div>{object.imageUrl && <img src={object.imageUrl} />}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}

                <div
                    className="dropdown"
                    onClick={() => setHarvestToggle(!harvestToggle)}
                >
                    <span> {t('vertaling.harvest_steps')} </span>
                    <span
                        className={`arrow ${harvestToggle ? "arrow-down" : "arrow-right"}`}
                    >
                        {">"}
                    </span>
                </div>


                {harvestToggle ? (
                    <div className="steps">
                        {harvestContent.map((object, index) => (
                            <div>
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div style={{ flex: 1, display: 'flex', gap: '10px' }}>
                                        <div className='count'>{index + 1}</div>
                                        <div style={{ background: 'none', border: 'none', padding: 0 }}>
                                            {object.stepId + ' ' + t("vertaling.on") + ' ' + (object.date)}
                                        </div>
                                    </div>
                                    {object.email && <p style={{ margin: 0, whiteSpace: 'nowrap' }}>{object.email}</p>}
                                </div>
                                <div>
                                    <div key={index}>
                                        {object.agriculturalParcels ? (
                                            <div>
                                                <h5>{t("translation.traceBatch.origin")}</h5>
                                                {object.agriculturalParcels.map((item2, index2) => {
                                                    const agriParcel = agriculturalParcels.find((a) => a.id === item2.id);
                                                    if (!agriParcel) return null; // Guard clause in case parcel isn't found
                                                    const navigateUrl = `/agriculturalparcel/details/${agriParcel.id}/${agriParcel.parcelId}/${agriParcel.vineyardId}`;
                                                    return (
                                                        <div>
                                                            <div style={{
                                                                float: "left",
                                                                marginTop: "1em",
                                                                marginRight: "2em"
                                                            }}>
                                                                <p>

                                                                    {t("vertaling.grapevariety_vineyard")}
                                                                    {": "}
                                                                    <span
                                                                        style={{ color: "#ac9e66", textDecoration: "underline", cursor: "pointer" }}
                                                                        onClick={() => navigate(navigateUrl)}>
                                                                        {agriParcel.name}
                                                                    </span>
                                                                    <br />
                                                                    {t("translation.traceBatch.percentage")}
                                                                    {": "}
                                                                    {item2.percentage}%
                                                                </p>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        ) : (
                                            ""
                                        )}


                                        <div style={{ marginTop: "2em" }}>
                                            <h5>{t("translation.traceBatch.registrationData")}</h5>
                                        </div>
                                        {object.description ? (
                                            <div>
                                                <p>
                                                    {t("vertaling.beschrijving")}{": "}
                                                    {object.description}
                                                </p>
                                            </div>
                                        ) : (
                                            " "
                                        )}

                                        {object.acidityForReport ? (
                                            <div>
                                                <p>
                                                    {t("vertaling.acidity")}{" "}
                                                    {object.acidityForReport}
                                                </p>
                                            </div>
                                        ) : (
                                            ""
                                        )}

                                        {object.bruised ? (
                                            <div>
                                                <p>
                                                    {t("translation.traceBatch.bruised")}
                                                    : {object.bruised.toString()}
                                                </p>
                                            </div>
                                        ) : (
                                            ""
                                        )}

                                        {object.destemmed ? (
                                            <div>
                                                <p>
                                                    {t("translation.traceBatch.destemmed")}
                                                    : {object.destemmed.toString()}
                                                </p>
                                            </div>
                                        ) : (
                                            ""
                                        )}

                                        {object.harvestYearId ? (
                                            <div>
                                                <p>
                                                    {t("translation.traceBatch.harvestYear")}
                                                    :{" "}
                                                    {
                                                        harvestYears.find(
                                                            (h) => h.id === object.harvestYearId
                                                        ).name
                                                    }
                                                </p>
                                            </div>
                                        ) : (
                                            ""
                                        )}

                                        {object.ph ? (
                                            <div>
                                                <p>Ph: {object.ph}</p>
                                            </div>
                                        ) : (
                                            ""
                                        )}

                                        {object.sugarsForReport ? (
                                            <div>
                                                <p>
                                                    {t("vertaling.sugars")}{" "}
                                                    {object.sugarsForReport}
                                                </p>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    " "
                )}

                {cellarRegs.length > 0 && (
                    <div
                        className="dropdown"
                        onClick={() => setCellarContent(!cellarContent)}
                    >
                        <span> {t('vertaling.cellar_steps')} </span>
                        <span
                            className={`arrow ${cellarContent ? "arrow-down" : "arrow-right"
                                }`}
                        >
                            {">"}
                        </span>
                    </div>
                )}
                {cellarContent && cellarRegs.length > 0 ? (
                    <div className="steps">
                        {cellarRegs.map((object, index) => (
                            <div>
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div style={{ flex: 1, display: 'flex', gap: '10px' }}>
                                        <div className='count'>{index + 1}</div>
                                        <div style={{ background: 'none', border: 'none', padding: 0 }}>
                                            {object.stepId + ' ' + t("vertaling.on") + ' ' + (object.date)}
                                        </div>
                                    </div>
                                    {object.email && <p style={{ margin: 0, whiteSpace: 'nowrap' }}>{object.email}</p>}
                                </div>
                                <div>
                                    <div key={index}>
                                        {object.description ? <p>{object.description}</p> : ""}
                                        {object.temperature ? (
                                            <p>
                                                {t('vertaling.temperature')}{" "}
                                                {object.temperature}
                                            </p>
                                        ) : (
                                            ""
                                        )}
                                        {object.amountOfBottles ? (
                                            <p>
                                                {t('vertaling.amountOfBottles')}{" "}
                                                {object.amountOfBottles}
                                            </p>
                                        ) : (
                                            ""
                                        )}
                                        {object.amount ? (
                                            <p>
                                                {t('vertaling.amount')}{" "}
                                                {object.amount}
                                            </p>
                                        ) : (
                                            ""
                                        )}

                                        {object.amountDrying ? (
                                            <p>
                                                {t('vertaling.amountDrying')}{" "}
                                                {object.amountDrying}
                                            </p>
                                        ) : (
                                            ""
                                        )}

                                        {object.wayOfHarvest ? (
                                            <p>
                                                {t('vertaling.harvestMethod')}{" "}
                                                {object.wayOfHarvest}
                                            </p>
                                        ) : (
                                            ""
                                        )}

                                        {object.density ? (
                                            <p>
                                                {t('vertaling.density')}{" "}
                                                {object.density}
                                            </p>
                                        ) : (
                                            ""
                                        )}

                                        {object.acidity ? (
                                            <p>
                                                {t('vertaling.acidity')}{" "}
                                                {object.acidity}
                                            </p>
                                        ) : (
                                            ""
                                        )}

                                        {object.alcohol ? (
                                            <p>
                                                {t('vertaling.alcohol')}{" "}
                                                {object.alcohol}
                                            </p>
                                        ) : (
                                            ""
                                        )}

                                        {object.massDensity ? (
                                            <p>
                                                {t('vertaling.massDensity')}{" "}
                                                {object.massDensity}
                                            </p>
                                        ) : (
                                            ""
                                        )}

                                        {object.ph ? (
                                            <p>
                                                {t('vertaling.ph')} {object.ph}
                                            </p>
                                        ) : (
                                            ""
                                        )}

                                        {object.sugars ? (
                                            <p>
                                                {t('vertaling.sugars')}{" "}
                                                {object.sugars}
                                            </p>
                                        ) : (
                                            ""
                                        )}

                                        {object.typeOfFermentation ? (
                                            <p>
                                                {t('vertaling.fermentationType')}{" "}
                                                {object.typeOfFermentation}
                                            </p>
                                        ) : (
                                            ""
                                        )}

                                        {object.products ? (
                                            <p>
                                                {t('vertaling.productsAdded')}{" "}
                                                {object.products}
                                            </p>
                                        ) : (
                                            ""
                                        )}


                                        {object.SO2GramPerLiter ? (
                                            <p>
                                                {t('translation.traceBatch.SO2GramPerLiter')}
                                                {": "}
                                                {object.SO2GramPerLiter}
                                            </p>
                                        ) : (
                                            ""
                                        )}

                                        {object.SO2Gram ? (
                                            <p
                                                style={{ verticalAlign: "top", width: "50%", float: "left" }}>
                                                {t('translation.traceBatch.SO2Gram')}{" "}
                                                {": "}
                                                {object.SO2Gram}
                                            </p>
                                        ) : (
                                            ""
                                        )}

                                        {object.wineStorages ? (
                                            <div
                                                style={{
                                                    marginTop: "2em",
                                                    marginBottom: "2em"
                                                }}
                                            >
                                                <h5>{t("translation.traceBatch.destination")}</h5>
                                                {object.wineStorages.map((item2, index2) => {
                                                    return (
                                                        <div>
                                                            <div
                                                            //style={{width: "100%", float: "right"}}
                                                            >

                                                                <p>
                                                                    {t("translation.traceBatch.typeOfWineStorage")}
                                                                    {": "}
                                                                    {
                                                                        wineStorages.find((w) => w.id === item2.id)
                                                                            ?.name
                                                                    }
                                                                    <br />
                                                                    {t("translation.traceBatch.amountLiters")}
                                                                    {": "}
                                                                    {item2.litersForReport}
                                                                    <br />
                                                                </p>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        ) : (
                                            " "
                                        )}

                                        {object.bottling ? (
                                            <p>
                                                {object.bottling.map((item2, index2) => {
                                                    return (
                                                        <div>
                                                            <div
                                                                style={{
                                                                    float: "left",
                                                                    marginTop: "1em",
                                                                }}
                                                            >
                                                                <p>
                                                                    <p>{t("translation.traceBatch.origin")}</p>
                                                                    {t("vertaling.wine_storage")}
                                                                    {": "}
                                                                    {
                                                                        wineStorages.find(
                                                                            (w) => w.id === item2.winestorageId
                                                                        ).name
                                                                    }
                                                                </p>
                                                            </div>
                                                            <div
                                                                style={{
                                                                    float: "right",
                                                                    width: "20em",
                                                                    marginTop: "1em",
                                                                }}
                                                            >
                                                                <h5>{t("translation.traceBatch.destination")}</h5>
                                                                <p>
                                                                    {t("translation.traceBatch.bottleType")}
                                                                    {": "}
                                                                    {
                                                                        bottleTypes.find(
                                                                            (b) => b.id === item2.bottleId
                                                                        )?.name
                                                                    }
                                                                    <br />
                                                                    {t("translation.traceBatch.amountLiters")}
                                                                    {": "}
                                                                    {item2.liters}
                                                                    <br />
                                                                    {t("translation.traceBatch.amountOfBottles")}{" "}
                                                                    {": "}
                                                                    {Math.round(
                                                                        item2.liters /
                                                                        bottleTypes.find(
                                                                            (b) => b.id === item2.bottleId
                                                                        )?.size
                                                                    )}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </p>
                                        ) : (
                                            ""
                                        )}

                                        {object.productsUsed ? (
                                            <p>
                                                {t('vertaling.productUsed')}{" "}
                                                {object.productsUsed}
                                            </p>
                                        ) : (
                                            ""
                                        )}

                                        {object.amountUsed ? <p>c {object.amountUsed}</p> : ""}

                                        {object.sourceBatches ? (
                                            <div className="merge">
                                                <div>
                                                    {object.sourceBatches.map((item2, index2) => {
                                                        if (params.batch === item2) {
                                                            return (
                                                                <span
                                                                    style={{
                                                                        color: "#ac9e66",
                                                                        fontWeight: "bold",
                                                                    }}
                                                                >
                                                                    {
                                                                        batches.find(({ id }) => id === item2.id)
                                                                            .name
                                                                    }
                                                                </span>
                                                            );
                                                        } else {
                                                            return (
                                                                <Link
                                                                    to={`/traceBatchUpdated/${item2.id}`}
                                                                    key={index2}
                                                                    style={{
                                                                        display: "contents",
                                                                        color: "#ac9e66",
                                                                        cursor: "pointer",
                                                                    }}
                                                                >
                                                                    {" "}
                                                                    <span style={{ textDecoration: "underline" }}>
                                                                        {
                                                                            batches.find(({ id }) => id === item2.id)
                                                                                .name
                                                                        }
                                                                    </span>{" "}
                                                                </Link>
                                                            );
                                                        }
                                                    })}
                                                </div>
                                                <div className="bracket">
                                                    <div>
                                                        <div></div>
                                                    </div>
                                                </div>
                                                <div>
                                                    {params.batch === object.batchId ? (
                                                        <span
                                                            style={{
                                                                color: "#ac9e66",
                                                                fontWeight: "bold",
                                                            }}
                                                        >
                                                            {
                                                                batches.find(({ id }) => id === object.batchId)
                                                                    .name
                                                            }
                                                        </span>
                                                    ) : (
                                                        <Link
                                                            to={`/traceBatchUpdated/${object.batchId}`}
                                                            style={{
                                                                display: "contents",
                                                                color: "#ac9e66",
                                                                cursor: "pointer",
                                                            }}
                                                        >
                                                            {" "}
                                                            <span style={{ textDecoration: "underline" }}>
                                                                {
                                                                    batches.find(
                                                                        ({ id }) => id === object.batchId
                                                                    ).name
                                                                }
                                                            </span>{" "}
                                                        </Link>
                                                    )}
                                                </div>
                                            </div>
                                        ) : (
                                            ""
                                        )}

                                        {object.splitBatches ? (
                                            <div className="split">
                                                <div>
                                                    {params.batch === object.sourceBatchId ? (
                                                        <span
                                                            style={{
                                                                color: "#ac9e66",
                                                                fontWeight: "bold",
                                                            }}
                                                        >
                                                            {object.sourceBatchId}
                                                        </span>
                                                    ) : (
                                                        <Link
                                                            to={`/tracebatch/${object.sourceBatchId}`}
                                                            style={{
                                                                display: "contents",
                                                                color: "#ac9e66",
                                                                cursor: "pointer",
                                                            }}
                                                        >
                                                            {" "}
                                                            <span style={{ textDecoration: "underline" }}>
                                                                {object.sourceBatchId}
                                                            </span>{" "}
                                                        </Link>
                                                    )}
                                                </div>
                                                <div className="bracket">
                                                    <div>
                                                        <div></div>
                                                    </div>
                                                </div>
                                                <div>
                                                    {object.splitBatches.map((item3, index3) => {
                                                        if (params.batch === item3) {
                                                            return (
                                                                <span
                                                                    style={{
                                                                        color: "#ac9e66",
                                                                        fontWeight: "bold",
                                                                    }}
                                                                >
                                                                    {item3}
                                                                </span>
                                                            );
                                                        } else {
                                                            return (
                                                                <Link
                                                                    to={`/tracebatch/${item3}`}
                                                                    key={index3}
                                                                    style={{
                                                                        display: "contents",
                                                                        color: "#ac9e66",
                                                                        cursor: "pointer",
                                                                    }}
                                                                >
                                                                    {" "}
                                                                    <span style={{ textDecoration: "underline" }}>
                                                                        {item3}
                                                                    </span>{" "}
                                                                </Link>
                                                            );
                                                        }
                                                    })}
                                                </div>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div>{object.imageUrl && <img src={object.imageUrl} />}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div style={{ height: 40 }}></div>
                )}

                {vineyardRegs.length === 0 &&
                    cellarRegs.length === 0 &&
                    batches.some((b) => b.batch === params.batch) && (
                        <h1 style={{ color: "#ac9e66db" }}>{t('vertaling.no_batch_registrations')}</h1>
                    )}
            </div>
        </div>
    );
};

export default TraceBatchUpdated;