import { useTranslation } from 'react-i18next';
import GoBackButton from "../../components/FormComponents/GoBackButton";
import { PageTitle } from "../../utils/PageTitle";
import { useEffect, useState } from "react";
import { useFirestore } from "../../context/FirestoreContext";
import { useSessionStorage } from "../../logic/useSessionStorage";
import { useNavigate } from "react-router-dom";
import MaterialTable from '@material-table/core';

const MyHarvestSteps = () => {
  PageTitle("My Harvest Steps");
  const { getHarvestSteps } = useFirestore();
  const navigate = useNavigate();
  const [harvestSteps, setHarvestSteps] = useState(undefined);
  const [currentWinery, setCurrentWinery] = useSessionStorage("currentwinery","");
  const { t } = useTranslation();

  useEffect(() => {
    const getHarvestStepsFromDb = async () => {
      let steps = await getHarvestSteps(currentWinery);
      setHarvestSteps(steps);
    };

    if (harvestSteps === undefined) getHarvestStepsFromDb();
  }, []);



  return (
    <div>
      {harvestSteps !== undefined && (
        <div>
         <h1 style={{ color: "#7f7f7f" }}>{t('translation.myHarvestSteps.myHarvestSteps')}</h1>
          <GoBackButton />
          <br />
          <br />

          <div className="batchesList">
            <MaterialTable
              title=""
              data={harvestSteps.content}
              columns={[
                {
                  title: t('translation.myHarvestSteps.key'),
                  field: "key",
                },
                {
                    title: t('translation.myHarvestSteps.nameEN'),
                    field: "textEN"
                },
                {
                    title: t('translation.myHarvestSteps.type'),
                    field: "type"
                },
                {
                    render: (rowData) => (
                        <>
                        <button
                                    className="ui primary button login"
                                    onClick={() => {
                                        navigate(`/harvestSteps/editHarvestStep/${rowData.order}`)
                                    }}
                                >
                                    {t("translation.words.edit")}
                                </button>
                        </>
                    )

                }
              ]}
              options={{
                tableLayout: "auto",
                filtering: true,
                exportButton: true,
                exportAllData: true,
                headerStyle: {
                    backgroundColor: "#cecaca", color: "#FFF",
                },

                emptyRowsWhenPaging: false,
                paging: true,
                pageSize: 50,
                pageSizeOptions: [10, 20, 25, 50,],
            }}
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default MyHarvestSteps;