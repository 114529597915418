import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { firestore } from '../../firebase/config';
import { useSessionStorage } from '../../logic/useSessionStorage';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, onSnapshot, collection } from 'firebase/firestore';
import MaterialTable from '@material-table/core';
import GoBackButton from '../../components/FormComponents/GoBackButton';

const SelectAgriculturalParcelRegistration = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedGrapes, setSelectedGrapes] = useState([]);
  const [agriculturalParcels, setAgriculturalParcels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [vineyards, setVineyards] = useState([]);
  const [selectedVineyard, setSelectedVineyard] = useState([]);
  const [currentStep, setCurrentStep] = useState({});
  const [currentStepData, setCurrentStepData] = useState({});
  const [VineyardRegistrationData, setVineyardRegistrationData] = useSessionStorage('vineyardReg', '');
  const [agriculturalParcelsWithVineyard, setAgriculturalParcelsWithVineyard] = useState([]);
  const [grape, setGrape] = useSessionStorage('grape', '');
  const [vineyard, setVineyard] = useSessionStorage('vineyard', '');
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const { t } = useTranslation();
  const params = useParams();
  const [currentWinery] = useSessionStorage('currentwinery', '');
  
  let currentLang = '';

  useEffect(() => {
    if (lang === 'en') {
      currentLang = 'nameEN';
    } else if (lang === 'fr') {
      currentLang = 'nameFR';
    } else if (lang === 'nl') {
      currentLang = 'nameNL';
    }

    let hasVineyardsLoaded = false;
    let hasParcelsLoaded = false;
    let agriculturalParcels = [];
    let vineyards = [];

    // Function to process data once both datasets are loaded
    const processData = () => {
      if (vineyards.length !== 0 && agriculturalParcels.length !== 0 && hasVineyardsLoaded && hasParcelsLoaded) {
        const parcelsWithVineyard = agriculturalParcels.map(parcel => {
          const vineyard = vineyards.find(v => v.id === parcel.vineyardId);
          const vineyardName = vineyard ? vineyard.name : 'No vineyard';
          return { ...parcel, vineyardName};
        });
        setAgriculturalParcelsWithVineyard(parcelsWithVineyard);
        setLoading(false);
      }
    };

    const stepRef = doc(firestore, 'winegrowers', currentWinery, 'vineyardSteps', params.id);
    const unsubscribeStep = onSnapshot(stepRef, (doc) => {
      if (doc.exists) {
        setCurrentStep(doc.data()[currentLang]);
        setCurrentStepData(doc.data());
      }
    });

    const agriculturalParcelRef = collection(firestore, 'winegrowers', currentWinery, 'agriculturalParcels');
    const unsubscribeParcels = onSnapshot(agriculturalParcelRef, (snapshot) => {
      agriculturalParcels = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAgriculturalParcels(agriculturalParcels);
      hasParcelsLoaded = true;
      processData();
    });

    const vineyardRef = collection(firestore, 'winegrowers', currentWinery, 'vineyards');
    const unsubscribeVineyards = onSnapshot(vineyardRef, (snapshot) => {
      vineyards = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setVineyards(vineyards);
      hasVineyardsLoaded = true;
      processData();
    });

    return () => {
      unsubscribeParcels();
      unsubscribeVineyards();
    };
  }, [currentWinery, params.id]);

  const handleOnSubmit = () => {
    if(selectedRows.length !== 0) {
    setGrape(selectedGrapes.join(', '));
    const uniqueVineyardNames = new Set(selectedVineyard.map(vineyard => vineyard))
    setVineyard(Array.from(uniqueVineyardNames).join(', '));
    navigateToRegistration('', selectedRows);
    }
  };

   const isMultiSelect = () => {
     return currentStepData.selection === 'multi';
   };

  const handleRowSelectionChange = (rows) => {
    setSelectedGrapes(rows.map(row => row.name));
    setSelectedRows(rows.map(row => row.id));
    setSelectedVineyard(rows.map(row => row.vineyardName));
  };

  const navigateToRegistration = (rowData) => {
    const parcelRegData = {
      stepId: params.id,
      parcelId: rowData.id,
    };
    // Check whether the step allows to select multiple parcels
    if (isMultiSelect() === false || rowData.length === 1) {
      agriculturalParcels.forEach((parcel) => {
        if (parcel.id === rowData.id) {
          parcelRegData.agriculturalParcels = parcel.agriculturalParcels;
        }
      });
    } else {
      let parcelsToMerge = [];
      agriculturalParcels.forEach((parcel) => {
        selectedRows.forEach((row) => {
          if (parcel.id === row) {
            parcelsToMerge.push(parcel);
          }
        });
      });
      parcelRegData.agriculturalParcels = parcelsToMerge;
      parcelRegData.parcelId = parcelsToMerge.id;
    }

    setVineyardRegistrationData(parcelRegData);
    setTimeout(() => {
      navigate(`/vineyardstepregistration/addregistration`);
    }, 500);
  };

  return (
    <>
    {loading ? (<p>Loading ...</p>) : (
      <div style={{ marginTop: "30px" }}>
        <h1>{t('translation.vineyardRegistration.selectedVineyardStep')+ " " + currentStep}</h1>
        <GoBackButton />
        <h3 style={{ color: "#7f7f7f" }}>{t('translation.vineyardRegistration.selectParcel')}</h3>
        <div style={{ maxWidth: "95%", margin: "auto" }}>
          <MaterialTable
            title=""
            data={agriculturalParcelsWithVineyard}
            columns={[
              {
                title: t('translation.name'),
                field: "name",
              },
              {
                title: t('translation.harvestRegistration.vineyard'),
                field: "vineyardName",
                defaultGroupOrder: 0,
              },
              {
                title: t('translation.numberOfPlants'),
                field: "numberOfPlants",
              },
              {
                title: t('translation.words.grape'),
                field: "grapeVariety",
                defaultGroupOrder: 1,
              }
            ]}
            onSelectionChange={(rows) => handleRowSelectionChange(rows)}
            options={{
              headerStyle: {
                backgroundColor: "#cecaca",
                color: "#FFF",
                textAlign: "left",
              },
              filterCellStyle: {
                alignContent: "left",
              },
              emptyRowsWhenPaging: false,
              selection: isMultiSelect(),
              paging: true,
              pageSize: 50,
              pageSizeOptions: [5, 10, 20, 25, 50],
              grouping: true,
            }}
          />
          {isMultiSelect() === true && (
            <button
              onClick={handleOnSubmit}
              className="ui submit large grey button right floated button-vinyards"
              style={{ float: "none", marginTop: "30px", marginBottom: "30px", width: "25%" }}
            >
              {t('translation.words.next')}
            </button>
          )}
        </div>
      </div>
      )}
    </>
  );
};

export default SelectAgriculturalParcelRegistration;
