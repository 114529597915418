import { doc, setDoc, updateDoc, deleteDoc, collection, addDoc, query, where, getDocs } from 'firebase/firestore';
import { firestore } from './config';

export const createRegistration = async (winegrower, data) => {
  const registrationRef = collection(firestore, `winegrowers/${winegrower}/registrations`);
  return addDoc(registrationRef, data);
};

export const createCellarRegistration = async (winegrower, data) => {
  const registrationRef = collection(firestore, `winegrowers/${winegrower}/registrations`);
  return addDoc(registrationRef, data);
};

export const updateRegistrationDocument = async (winegrower, reg) => {
  const docRef = doc(firestore, `winegrowers/${winegrower}/registrations/${reg.id}`);

  if (reg.createdBy === undefined || reg.createdBy === "") {
    const userQuery = query(
      collection(firestore, 'users'),
      where('email', '==', reg.email)
    );

    const querySnapshot = await getDocs(userQuery);
    const userLoggedIn = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    if (userLoggedIn[0] === undefined) {
      reg.createdBy = reg.email;
    } else {
      reg.createdBy = userLoggedIn[0].name;
    }
  }

  return updateDoc(docRef, reg);
};

export const updateRegistration = async (winegrower, regId, regData) => {
  const docRef = doc(firestore, `winegrowers/${winegrower}/registrations/${regId}`);
  return updateDoc(docRef, regData);
};

export const deleteRegistration = async (winegrower, regId) => {
  const docRef = doc(firestore, `winegrowers/${winegrower}/registrations/${regId}`);
  return deleteDoc(docRef);
};
