import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import Select from "react-select";
import { firestore } from "../../firebase/config";
import { useSessionStorage } from "../../logic/useSessionStorage";
import { useNavigate, useParams } from "react-router-dom";
import { createVineyardStepContent } from "../../firebase/vineyardstep";
import GoBackButton from "../../components/FormComponents/GoBackButton";
import { doc, onSnapshot } from 'firebase/firestore';

const NewVineyardStepContent = () => {
    const formClassname = "ui big form twelve wide column";
    const typeOptions = [
        "geopoint",
        "multilinetext",
        "image",
        "label",
        "option",
        "decimal",
        "text",
        "timestamp",
        'section'
    ];

    const validationTypes = ["notEmpty", "null"];
    const validation1Types = ["greaterThen", "lesserThen", 'null'];

    const navigate = useNavigate();
    const { register, handleSubmit } = useForm();
    const [selectedType, setSelectedType] = useState([]);
    const [selectedValidation, setSelectedValidation] = useState("");
    const [selectedValidation1, setSelectedValidation1] = useState("");
    const [selectedValidation2, setSelectedValidation2] = useState([]);
    const params = useParams();
    const [currentVineyardStep, setCurrentVineyardStep] = useState("");
    const [error, setError] = useState('hidden');
    const [currentWinery, setCurrentWinery] = useSessionStorage(
        "currentwinery",
        ""
    );
    const { t } = useTranslation();

    let currentStep;
    useEffect(() => {
        const vineyardStepRef = doc(firestore, "winegrowers", currentWinery, "vineyardSteps", params.id)

        const unsubscribe = onSnapshot(vineyardStepRef, (doc) => {
            if (doc.exists) {
                currentStep = doc.data();
                setCurrentVineyardStep(currentStep);
            }
        });
        return () => unsubscribe();
    }, [params.id]);

    const onSubmit = async (data) => {
        const newDoc = generateVineyardStep(data);
        try {
            if (selectedType === "") {
                setError('visible');
                return
            } else {
                setError('hidden')
                await createVineyardStepContent(currentWinery, params.id, newDoc);
                navigate(-1);
            }
        } catch (error) {
            console.error(error);
        }
    };
    const handleTypeChange = (selection, action) => {
        setSelectedType(selection.value);
    };

    const handleValidationChange = (selection, action) => {
        setSelectedValidation(selection.value);
    }
    const handleValidation1Change = (selection, action) => {
        setSelectedValidation1(selection.value);
    }

    const handleValidation2Change = (selection, action) => {
        setSelectedValidation2(selection.value);
    }

    function generateVineyardStep(data) {
        var newDoc;
        data["order"] = Number(data["order"]);

        if (data["validation"] === "") {
            data["validation"] = null;
        }
        if (data["validationValue"] === "") {
            data["validationValue"] = null;
        }
        if (data["validation1"] === "") {
            data["validation1"] = null;
        }
        if (data["validation1Value"] === "") {
            data["validation1Value"] = null;
        } else {
            data['validation1Value'] = Number(data['validation1Value']);
        }
        if (data["validation2"] === "") {
            data["validation2"] = null;
        }
        if (data["validation2Value"] === "") {
            data["validation2Value"] = null;
        } else {
            data['validation2Value'] = Number(data['validation2Value']);
        }
        if (data['key'] === '') {
            data['key'] = null;
        }

        newDoc = { ...currentVineyardStep };
        if (newDoc.content === undefined) {
            newDoc.content = [];
        }
        newDoc.content.push(data);
        return newDoc;
    }

    return (
        <div>
            <div
                className="add-form-container"
                style={{
                    maxWidth: 960,
                    margin: "50px auto",
                    marginTop: "110px",
                }}
            >
                <h1 style={{ color: "#7f7f7f" }}>{t('translation.vineyardSteps.newVineyardStepContent')}</h1>
                <GoBackButton />
                <div className="ui grid stackable">
                    <form
                        className={formClassname}
                        style={{
                            border: "3px solid #B3B3B3",
                            marginTop: "100px",
                            borderRadius: "50px",
                            background: "#edeaea",
                            borderColor: "#ac9e66",
                        }}
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <div
                            className="fields"
                            style={{ justifyContent: "center", marginTop: "20px" }}
                        >
                            <div className="twelve wide field">
                                <label>
                                    <p>{t("translation.vineyardSteps.key")}</p>
                                    <input
                                        type="text"
                                        {...register('key')}
                                        htmlFor="key"
                                        style={{ textAlign: "center" }} />
                                </label>
                            </div>
                        </div>
                        <div
                            className="fields"
                            style={{ justifyContent: "center", marginTop: "20px" }}
                        >
                            <div className="twelve wide field">
                                <label>
                                    <p>{t("translation.vineyardSteps.order")}</p>
                                    <input
                                        type="number"
                                        {...register('order')}
                                        htmlFor="order"
                                        required="required"
                                        style={{ textAlign: "center" }} />
                                </label>
                            </div>
                        </div>
                        <div
                            className="fields"
                            style={{ justifyContent: "center", marginTop: "20px" }}
                        >
                            <div className="twelve wide field">
                                <label>
                                    <p>{t("translation.vineyardSteps.textEN")}</p>
                                    <input
                                        type="text"
                                        {...register('textEN')}
                                        htmlFor="textEN"
                                        required="required"
                                        style={{ textAlign: "center" }} />
                                </label>
                            </div>
                        </div>
                        <div
                            className="fields"
                            style={{ justifyContent: "center", marginTop: "20px" }}
                        >
                            <div className="twelve wide field">
                                <label>
                                    <p>{t("translation.vineyardSteps.textFR")}</p>
                                    <input
                                        type="text"
                                        {...register('textFR')}
                                        htmlFor="textFR"
                                        required="required"
                                        style={{ textAlign: "center" }} />
                                </label>
                            </div>
                        </div>
                        <div
                            className="fields"
                            style={{ justifyContent: "center", marginTop: "20px" }}
                        >
                            <div className="twelve wide field">
                                <label>
                                    <p>{t("translation.vineyardSteps.textIT")}</p>
                                    <input
                                        type="text"
                                        {...register('textIT')}
                                        htmlFor="textIT"
                                        required="required"
                                        style={{ textAlign: "center" }} />
                                </label>
                            </div>
                        </div>
                        <div
                            className="fields"
                            style={{ justifyContent: "center", marginTop: "20px" }}
                        >
                            <div className="twelve wide field">
                                <label>
                                    <p>{t("translation.vineyardSteps.textNL")}</p>
                                    <input
                                        type="text"
                                        {...register('textNL')}
                                        htmlFor="textNL"
                                        required="required"
                                        style={{ textAlign: "center" }} />
                                </label>
                            </div>
                        </div>
                        <div
                            className="fields"
                            style={{ justifyContent: "center", marginTop: "20px" }}
                        >
                            <div className="twelve wide field">
                                <label>
                                    <span>{t('translation.vineyardSteps.type')}</span>
                                    <Select
                                        label={t('translation.vineyardSteps.type')}
                                        htmlFor="type"
                                        onChange={(selection, action) =>
                                            handleTypeChange(selection, action)
                                        }
                                        options={typeOptions.map((value) => ({
                                            label: value,
                                            value: value,
                                        }))}
                                    />
                                </label>
                                <div style={{ display: "none" }}>
                                    <input {...register('type')} htmlFor="type" value={selectedType} readOnly />
                                </div>
                            </div>
                        </div>
                        <div
                            className="fields"
                            style={{ justifyContent: "center", marginTop: "20px" }}
                        >
                            <div className="twelve wide field">
                                <label>
                                    <p>{t("translation.vineyardSteps.validation")}</p>
                                    <Select
                                        label='validation'
                                        htmlFor='validation'
                                        onChange={(selection, action) => {
                                            handleValidationChange(selection, action);
                                        }}
                                        options={validationTypes.map((value) => ({
                                            label: value,
                                            value: value
                                        }))}
                                    />
                                </label>
                                <div style={{ display: 'none' }}>
                                    <input
                                        type="text"
                                        {...register('validation')}
                                        value={selectedValidation}
                                        readOnly />
                                </div>
                            </div>
                        </div>
                        <div
                            className="fields"
                            style={{ justifyContent: "center", marginTop: "20px" }}
                        >
                            <div className="twelve wide field">
                                <label>
                                    <p>{t("translation.vineyardSteps.validation1")}</p>
                                    <Select
                                        label='validation1'
                                        htmlFor='validation1'
                                        onChange={(selection, action) =>
                                            handleValidation1Change(selection, action)
                                        }
                                        options={validation1Types.map((value) => ({
                                            label: value,
                                            value: value
                                        }))}
                                    />
                                    <div style={{ display: "none" }}>
                                        <input
                                            type="text"
                                            {...register('validation1')}
                                            value={selectedValidation1}
                                            style={{ textAlign: "center" }}
                                            readOnly />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div
                            className="fields"
                            style={{ justifyContent: "center", marginTop: "20px" }}
                        >
                            <div className="twelve wide field">
                                <label>
                                    <p>{t("translation.vineyardSteps.validation1Value")}</p>
                                    <input
                                        type="text"
                                        {...register('validation1Value')}
                                        style={{ textAlign: "center" }} />
                                </label>
                            </div>
                        </div>
                        <div
                            className="fields"
                            style={{ justifyContent: "center", marginTop: "20px" }}
                        >
                            <div className="twelve wide field">
                                <label>
                                    <p>{t("translation.vineyardSteps.validation2")}</p>
                                    <Select
                                        label='validation2'
                                        htmlFor='validation2'
                                        onChange={(selection, action) =>
                                            handleValidation2Change(selection, action)
                                        }
                                        options={validation1Types.map((value) => ({
                                            label: value,
                                            value: value
                                        }))}
                                    />
                                    <div style={{ display: 'none' }}>
                                        <input
                                            type="text"
                                            {...register('validation2')}
                                            htmlFor="validation2"
                                            value={selectedValidation2}
                                            readOnly />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div
                            className="fields"
                            style={{ justifyContent: "center", marginTop: "20px" }}
                        >
                            <div className="twelve wide field">
                                <label>
                                    <p>{t("translation.vineyardSteps.validation2Value")}</p>
                                    <input
                                        type="text"
                                        {...register('validation2Value')}
                                        htmlFor="validation2Value"
                                        style={{ textAlign: "center" }} />
                                </label>
                            </div>
                        </div>
                        <p style={{ visibility: error }} className="text-danger">
                            {t("translation.vineyardSteps.stepTypeNotSelected")}
                        </p>
                        <button
                            type="submit"
                            className="ui submit large grey button right floated  button-vinyards"
                            style={{ float: "none", width: "40%" }}
                        >
                            {t("translation.vineyardSteps.createVineyardStepContent")}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default NewVineyardStepContent;