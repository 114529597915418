import { useEffect, useState, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { firestore } from "../../firebase/config";
import { createAgriculturalParcel } from "../../firebase/parcel";
import { PageTitle } from "../../utils/PageTitle";
import { useSessionStorage } from "../../logic/useSessionStorage";
import GoBackButton from "../../components/FormComponents/GoBackButton";
import { doc, collection, onSnapshot, getDoc } from "firebase/firestore";

const NewAgriculturalParcel = () => {
    PageTitle("Create Agricultural Parcel");
    const [currentWinery, setCurrentWinery] =
        useSessionStorage("currentwinery", "");
    const params = useParams();
    let parcelData = "";
    let vineyardData = "";
    const navigate = useNavigate();
    const formClassname = "ui big form twelve wide column";
    const { register, setValue, handleSubmit } = useForm();
    const [parcels, setParcels] = useState([]);
    const [selectedParcel, setSelectedParcel] = useState("");
    const [error, setError] = useState("hidden");
    const [vineyards, setVineyards] = useState([]);
    const [selectedVineyard, setSelectedVineyard] = useState("");
    const [preSelectedVineyard, setPreSelectedVineyard] = useState("");
    const [preSelectedParcel, setPreSelectedParcel] = useState("");
    const [varieties, setVarieties] = useState([]);
    const [selectedVariety, setSelectedVariety] = useState("");
    const [isFocused, setFocus] = useState(false);

    const inputRef = useRef(null);
    const { t } = useTranslation();

    useEffect(() => {
        const parcelsCollectionRef = collection(firestore, "winegrowers", currentWinery, "parcels");
        const parcelsUnsubscribe = onSnapshot(parcelsCollectionRef, (querySnapshot) => {
            try {
                parcelData = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setParcels(parcelData);
            } catch (error) {
                console.error(error);
            }
        });

        const vineyardsCollectionRef = collection(firestore, "winegrowers", currentWinery, "vineyards");
        const vineyardsUnsubscribe = onSnapshot(vineyardsCollectionRef, (querySnapshot) => {
            try {
                vineyardData = querySnapshot.docs.map((doc) =>
                    doc && doc.data() ? { id: doc.id, ...doc.data() } : {}
                );
                setVineyards(vineyardData);
            } catch (error) {
                console.error(error);
            }
        });

            const vineyardDocRef = doc(firestore, 'winegrowers', currentWinery, 'vineyards', params.id);
            const vineyardUnsub = onSnapshot(vineyardDocRef, (doc) => {
                if (doc.exists) {
                    const docData = doc.data();
                    if(docData) {
                    docData.id = doc.id;
                    setPreSelectedVineyard(docData);
                    setSelectedVineyard(docData.id);
                    }
                }
            })

            const parcelDocRef = doc(firestore, 'winegrowers', currentWinery, 'parcels', params.id);
            const parcelUnsub = onSnapshot(parcelDocRef, (doc) => {
                if (doc.exists() && doc.data() !== null) {
                    const docData = doc.data();
                    docData.id = doc.id;
                    setPreSelectedParcel(docData);
                    setSelectedParcel(docData.id);
                }
            })
        const grapeVarietiesDocRef = doc(firestore, "enums", "grapeVarieties");
        getDoc(grapeVarietiesDocRef)
        .then((doc) => {
                if (doc.exists) {
                    const data = doc.data();
                    if (data.varietiesBelgium) {
                        const varietiesArray = Object.values(data.varietiesBelgium);
                        varietiesArray.sort(); // Sort alphabetically
                        setVarieties(varietiesArray);
                    }
                }
            })
            .catch((error) => {
                console.error("Error getting document:", error);
            });

            if(preSelectedVineyard && preSelectedVineyard.id) {
                setValue('vineyardId', preSelectedVineyard.id);
              }
              
            return () => {
                parcelsUnsubscribe();
                vineyardsUnsubscribe();
                vineyardUnsub();
                parcelUnsub();
            };
    }, [setValue, params.id]);

    const onSubmit = async (data) => {
        try {
            if ((selectedParcel === "") || (selectedVineyard === "")) {
                setError("visible");
            } else {
                setError("hidden");
                const totalAreaInSquareMeters = data.rowDistance * data.plantDistance * data.numberOfPlants;
                data.areaPlantedAre = Math.floor(totalAreaInSquareMeters / 100);
                data.areaPlantedCentiare = Math.round(totalAreaInSquareMeters - (data.areaPlantedAre * 100));
                const extendedData = {
                    ...data,
                    grapeVariety: selectedVariety,
                };
                
                await createAgriculturalParcel(currentWinery, extendedData);
                navigate("/myvineyards");
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleParcelChange = (selection, action) => {
        setSelectedParcel(selection.value);
        setValue('parcelId', selection.value);
    };

    const handleVineyardChange = (selection, action) => {
        setSelectedVineyard(selection.value);
        setValue('vineyardId', selection.value);
    };

    const handleGrapeChange = (e) => {
        setSelectedVariety(e.target.value);
        setFocus(true);
    };

    const filteredVarieties = varieties.filter((option) =>
        option.toLowerCase().startsWith(selectedVariety.toLowerCase())
    );

    function checkIfParcelOrVineyardWasPreSelected() {
        let valid = preSelectedParcel.id !== undefined || preSelectedVineyard.id !== undefined;
        return valid;
    }

    return (
        <div>
            {checkIfParcelOrVineyardWasPreSelected() === true && (
                <div
                    className="add-form-container"
                    style={{
                        maxWidth: 960,
                        margin: "50px auto",
                        marginTop: "110px",
                    }}
                >
                   <h1 style={{ color: "#7f7f7f" }}>{t('translation.agriculturalParcelInfo')}</h1>

                    <GoBackButton />

                    <div className="ui grid stackable">
                        <form
                            className={formClassname}
                            style={{
                                border: "3px solid #B3B3B3",
                                marginTop: "100px",
                                borderRadius: "50px",
                                borderColor: "#ac9e66",
                            }}
                            onSubmit={handleSubmit(onSubmit)}
                        >
                        
                            <div
                                className="fields"
                                style={{ justifyContent: "center", marginTop: "20px" }}
                            >
                                <div className="twelve wide field">
                                    <label>
                                        <p>{t("translation.name")}</p>
                                        <input
                                            type="text"
                                            {...register('name')}
                                            htmlFor="name"
                                            style={{ textAlign: "center" }} />
                                    </label>
                                </div>
                            </div>
                            <div
                                className="fields"
                                style={{ justifyContent: "center", marginTop: "20px" }}
                            >
                                <div className="twelve wide field">
                                    <label>
                                        <p>{t("translation.myAgriParcels.rowDistance")}</p>
                                        <input
                                            type="number"
                                            step={0.01}
                                            {...register('rowDistance')}
                                            htmlFor="rowDistance"
                                            required="required"
                                            style={{ textAlign: "center" }} />
                                    </label>
                                </div>
                                <div className="twelve wide field">
                                    <label>
                                        <p>{t("translation.myAgriParcels.plantDistance")}</p>
                                        <input
                                            type="number"
                                            step={0.01}
                                            {...register('plantDistance')}
                                            htmlFor="plantDistance"
                                            required="required"
                                            style={{ textAlign: "center" }} />
                                    </label>
                                </div>
                            </div>
                            <div
                                className="fields"
                                style={{ justifyContent: "center", marginTop: "20px" }}
                            >
                                <div className="twelve wide field">
                                    <label>
                                        <p>{t("translation.clone")}</p>
                                        <input
                                            type="text"
                                            {...register('clone')}
                                            htmlFor="clone"
                                            style={{ textAlign: "center" }} />
                                    </label>
                                </div>
                            </div>

                            <div
                                className="fields"
                                style={{ justifyContent: "center", marginTop: "20px" }}
                            >
                                <div className="twelve wide field">
                                    <label>
                                        <p>{t("translation.grapeVariety")}</p>
                                        <input
                                            ref={inputRef}
                                            type="text"
                                            value={selectedVariety}
                                            onChange={handleGrapeChange}
                                            onFocus={() => setFocus(true)}
                                            onBlur={() => setTimeout(() => setFocus(false), 100)}
                                        />
                                    </label>
                                    {isFocused && (
                                        <div
                                            style={{
                                                border: "1px solid #000",
                                                position: "absolute",
                                                width: inputRef.current ? inputRef.current.offsetWidth : "200px",
                                                maxHeight: "300px",
                                                overflowY: "scroll",
                                                backgroundColor: "rgba(255, 255, 255, 0.9)",
                                            }}
                                            onMouseDown={(e) => e.preventDefault()}
                                        >
                                            {filteredVarieties.map((option, index) => (
                                                <div
                                                    key={`${option}-${index}`}
                                                    onClick={() => {
                                                        setSelectedVariety(option);
                                                        setFocus(false);
                                                    }}
                                                >
                                                    {option}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div
                                className="fields"
                                style={{ justifyContent: "center", marginTop: "20px" }}
                            >
                                <div className="twelve wide field">
                                    <label>
                                        <p>{t("translation.numberOfPlants")}</p>
                                        <input
                                            type="number"
                                            {...register('numberOfPlants')}
                                            htmlFor="numberOfPlants"
                                            required="required"
                                            style={{ textAlign: "center" }} />
                                    </label>
                                </div>
                            </div>
                            <div
                                className="fields"
                                style={{ justifyContent: "center", marginTop: "20px" }}
                            >
                                <div className="twelve wide field">
                                    <label>
                                        <p>{t("translation.rootStock")}</p>
                                        <input
                                            type="text"
                                            {...register('rootStock')}
                                            htmlFor="rootStock"
                                            required="required"
                                            style={{ textAlign: "center" }} />
                                    </label>
                                </div>
                            </div>

                            <div
                                className="fields"
                                style={{ justifyContent: "center", marginTop: "20px" }}
                            >
                                <div className="twelve wide field">
                                    <label>
                                        <p>{t("translation.yearPlanted")}</p>
                                        <input
                                            type="text"
                                            {...register('yearPlanted')}
                                            htmlFor="yearPlanted"
                                            required="required"
                                            style={{ textAlign: "center" }} />
                                    </label>
                                </div>
                            </div>
                            <div
                                className="fields"
                                style={{ justifyContent: "center", marginTop: "20px" }}
                            >
                                <div className="twelve wide field">
                                    <label>
                                        <p>{t("translation.connectedParcel")}</p>
                                        <Select
                                            label="parcelId"
                                            htmlFor="parcelId"
                                            placeholder={t('translation.selectParcel')}
                                            onChange={(selection, action) =>
                                                handleParcelChange(selection, action)
                                            }
                                            options={parcels.map((name, vid) => ({
                                                value: name.id,
                                                label: name.name,
                                            }))}

                                            defaultValue={{
                                                label: preSelectedParcel.name,
                                                value: preSelectedParcel.id,
                                                disabled: true
                                            }}
                                        />
                                    </label>
                                </div>
                                <div style={{ display: "none" }}>
                                    <label>
                                        <p>{t("translation.connectedParcel")}</p>
                                        <input
                                            {...register('parcelId')}
                                            htmlFor="parcelId"
                                            value={selectedParcel}
                                            readOnly />
                                    </label>
                                </div>
                            </div>

                            <div
                                className="fields"
                                style={{ justifyContent: "center", marginTop: "20px" }}
                            >
                                <div className="twelve wide field">
                                    <label>
                                        <p>{t("translation.connectedVineyard")}</p>
                                        <Select
                                            label="vineyardId"
                                            htmlFor="vineyardId"
                                            onChange={(selection, action) =>
                                                handleVineyardChange(selection, action)
                                            }
                                            options={vineyards.map((name, vid) => ({
                                                value: name.id,
                                                label: name.name,

                                            }))}

                                            defaultValue={{
                                                label: preSelectedVineyard.name,
                                                value: preSelectedVineyard.id,
                                                disabled: true
                                            }}
                                        />
                                    </label>
                                </div>
                                <div style={{ display: "none" }}>
                                    <label>
                                        <p>{t("translation.connectedVineyard")}</p>
                                        <input
                                            {...register('vineyardId')}
                                            htmlFor="vineyardId"
                                            value={selectedVineyard}
                                            readOnly />
                                    </label>
                                </div>
                            </div>
                            <p style={{ visibility: error }} className="text-danger">
                                {t("translation.connectedParcelNotSelected")}
                            </p>
                            <button
                                type="submit"
                                className="ui submit large grey button right floated  button-vineyards"
                                style={{ float: "none", marginTop: "30px", width: "30%" }}
                            >
                                {t("translation.createAgriculturalParcel")}
                            </button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default NewAgriculturalParcel;