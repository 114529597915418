import { doc, deleteDoc, updateDoc, collection, addDoc, Timestamp } from 'firebase/firestore';
import { firestore } from './config';
import { timestampToDate } from '../utils/DateConversions';

export const createSprayDocument = async (spray) => {
  // Get a reference to the Firestore collection
  const colRef = collection(firestore, `winegrowers/${spray.winegrower}/sprays`);

  // Initialize date
  let date = "";

  // Create spray object
  if (spray.purchasedate !== null) {
    date = Timestamp.fromDate(new Date(spray.purchasedate));
  } else {
    date = "Not Available";
  }

  spray.liters = Number(spray.liters);

  const sprayProfile = {
    ...spray,
    purchasedate: date,
  };

  // Convert Firestore Timestamp to JavaScript Date
  sprayProfile.purchasedate = timestampToDate(sprayProfile.purchasedate);

  // Write to Firestore
  return addDoc(colRef, sprayProfile);
};

export const deleteSpray = async (winegrower, sprayId) => {
  const docRef = doc(firestore, `winegrowers/${winegrower}/sprays/${sprayId}`);
  return deleteDoc(docRef);
};

export const updateSprayDocument = async (spray) => {
  const docRef = doc(firestore, `winegrowers/${spray.winegrower}/sprays/${spray.id}`);

  spray.liters = Number(spray.liters);
  spray.purchasedate = Timestamp.fromDate(new Date(spray.purchasedate));

  return updateDoc(docRef, spray);
};
