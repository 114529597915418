import { useTranslation } from 'react-i18next';
import { send } from 'emailjs-com';
import 'firebase/auth';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { getFirestore, collection, query, where, onSnapshot, getDocs } from "firebase/firestore";
import { approveUserDocument } from '../../firebase/user';
import { PageTitle } from '../../utils/PageTitle';
import { useSessionStorage } from '../../logic/useSessionStorage';
import { firestore } from '../../firebase/config';

const ActivateEmployee = () => {
  PageTitle("Activate Employee");
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const { handleSubmit } = useForm();
  const [userDocument, setUserDocument] = useState(null);
  const [approvedUser, approveUser] = useState(null);
  const [currentWinery, setCurrentWinery] = useSessionStorage('currentwinery', '');
  const { t } = useTranslation();

  const [toSend, setToSend] = useState({
    email_WN: params.email,
    tastefever: 'TasteFever',
    email: '',
    firstname: '',
    lastname: '',
    download: t('vertaling.download'),
    subject: t('vertaling.subject_activate'),
    aanspreking: t('vertaling.aanspreking_general'),
    employee_activated_deactivated: t('vertaling.employee_activated'),
    label1: t('vertaling.label1'),
    label2: t('vertaling.label2'),
    afsluiting: t('vertaling.afsluiting'),
    alert: t('translation.emails.moreInfo'),
    land: t('translation.words.belgium'),
  });

  // Referenties naar firestore database (bv : ophalen van collecties uit firestore db)
  // Alle waarden in de variabelen gaan verloren éénmaal de useEffect volledig uitgevoerd is

  useEffect(() => {
    if (currentUser !== undefined) {
      const userQuery = query(
        collection(firestore, "users"),
        where("email", "==", currentUser.email)
      );

      const unsubscribe = onSnapshot(userQuery, (querySnapshot) => {
        const userLoggedIn = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUserDocument(userLoggedIn[0]);
      });

      // De mail wordt verzonden door middel van deze functie
      const handleChange = (e) => {
        setToSend({ ...toSend, [e.target.name]: e.target.value });
      };

      /*Functie die alle variabelen in mailingplatform emailjs hier definieert en vertaalt*/

      const userRef = query(
        collection(firestore, "users"),
        where("email", "==", params.email)
      );

      getDocs(userRef).then((querySnapshot) => {
        const userToActivate = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        if (userToActivate.length !== 0 && userToActivate[0].wineries.some(winery => winery.winegrower === currentWinery)) {
          approveUser(userToActivate[0]);
        } else {
          navigate(`/employees/disabled`, { replace: true });
        }
      });
      return () => unsubscribe();
    }
  }, [currentUser, params.id]);

  const onSubmit = async (e) => {
    try {
      approveUserDocument(approvedUser.id, approvedUser.wineries, currentWinery);
      //e.preventDefault();
      toSend.email = approvedUser.email;
      toSend.firstname = approvedUser.firstname;
      toSend.lastname = approvedUser.lastname;

      const wineryRef = query(
        collection(firestore, "winegrowers"),
        where("__name__", "==", currentWinery)
      );

      getDocs(wineryRef).then((querySnapshot) => {
        toSend.winery = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))[0].name;

        send(
          'service_4g61fk7',
          'template_bdlyxo7',
          toSend,
          'user_EDqZc5RPaMddv4DU2Jmmo'
        ).then((response) => {
          // console.log('SUCCESS!', response.status, response.text);
        });
      })
    } catch (error) {
      console.error(error);
    } finally {
      navigate(`/employees/disabled`, { replace: true });
    }
  };
  if (approvedUser != null) {
    // Functie die zorgt voor het weergeven van alle gegevens van een user
    return (
      <div className="noCenter wrapper">
        <h1 className="userDetailTitle">{t("vertaling.title_activate")}</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="wrapperUserInfo">
            <h2 className="detailUserLabel">{t("vertaling.firstname")}</h2>
            <p className="userValueTxt">{approvedUser.firstname}</p>
          </div>
          <div className="wrapperUserInfo">
            <h2 className="detailUserLabel">{t("vertaling.lastname")}</h2>
            <p className="userValueTxt">{approvedUser.lastname}</p>
          </div>
          <div className="wrapperUserInfo">
            <h2 className="detailUserLabel">{t("vertaling.email")}</h2>
            <p className="userValueTxt">{approvedUser.email}</p>
          </div>
          <button className="approveBtn" type="submit">
            {t("vertaling.activate")}
          </button>
        </form>
      </div>

    );
  } else {
    return (
      <div></div>
    );
  }
};
export default ActivateEmployee;