import React from 'react';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { firestore } from '../../firebase/config';
import { useSessionStorage } from '../../logic/useSessionStorage';
import Select from 'react-select';
import { onSnapshot, collection, doc, query } from "firebase/firestore";

const AddWineStorage = React.forwardRef(({ register, index }, ref) => {
  const [wineStorages, setWineStorages] = useState([]);
  const [selectedWineStorage, setSelectedWineStorage] = useState(undefined);
  const [currentWinery, setCurrentWinery] = useSessionStorage('currentwinery', '');
  const { t } = useTranslation();

  useEffect(() => {
    const wineStorageRef = query(
      collection(
        firestore,
        'winegrowers',
        currentWinery,
        'wineStorages'
      )
    );

    const unsubscribeWineStorages = onSnapshot(wineStorageRef, (snapshot) => {
      snapshot.docs.map((doc) => {
        let wineStorage = doc.data();
        wineStorage.id = doc.id;
        setWineStorages((prevState) => [...prevState, wineStorage]);
      });
    });
    return () => { unsubscribeWineStorages() };
  }, [currentWinery]);

  const handleWineStorageChange = (selection, action) => {
    setSelectedWineStorage(selection.value);
  };

  return (
    <div ref={ref}>
      <div className="fields" style={{ justifyContent: 'center' }}>
        <div className="twelve wide field">
          <label>
            <p>{t("translation.createBatch.wineStorage")}</p>
            <Select
              name={`id${index}`}
              htmlFor={`id${index}`}
              ref={register}
              required
              options={wineStorages.map((wineStorage) => ({
                label: wineStorage.name,
                value: wineStorage.id,
              }))}
              onChange={(selection, action) => {
                handleWineStorageChange(selection, action);
              }}
            />
          </label>
        </div>
      </div>
      <div style={{ display: 'none' }}>
        <input
          name={`wineStorageId${index}`}
          htmlFor={`wineStorageId${index}`}
          value={selectedWineStorage}
          ref={register}
          readOnly
          required
        />
      </div>
      <div className="fields" style={{ justifyContent: 'center' }}>
        <div className="twelve wide field">
          <label>
            <p>{t("translation.createBatch.liters")}</p>
            <input
              type="number"
              name={`liters${index}`}
              htmlFor={`liters${index}`}
              ref={register}
              required
              style={{ textAlign: 'center' }}
              min="0"
            />
          </label>
        </div>
      </div>
    </div>
  );
});
export default AddWineStorage;