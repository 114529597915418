// Import required classes for Firebase v9
import { createUserWithEmailAndPassword, sendPasswordResetEmail, signOut } from 'firebase/auth';
import { auth, firestore, secondaryAuth } from '../firebase/config'
import { collection, getDocs} from 'firebase/firestore';
import { generate } from 'generate-password';
import { createEmployeeDocument, updateUserDocument } from './user';

/*Functie die er voor zorgt dat wanneer een user inlogt op de webapp , het account van deze user ook automatish wordt toegevoegd aan firebase authenticatie*/

export const signupEmployee = async ({ email, trustedForRegistration, role, winery }, _callback) => {
  const usersRef = collection(firestore, 'users');
  const querySnapshot = await getDocs(usersRef);

  const users = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  const userDoc = users.find((u) => u.email.toLowerCase() === email.toLowerCase());

  if (userDoc === undefined) {
    let password = generate({ length: 10, numbers: true });
    
    const resp = await createUserWithEmailAndPassword(secondaryAuth, email, password);
    const user = resp.user;
    await signOut(secondaryAuth);

    createEmployeeDocument(user.uid, {
      email: email,
      trustedForRegistration: trustedForRegistration,
      role: role,
      winery: winery,
    });

    _callback(password);
  } else if (userDoc.wineries !== undefined && userDoc.wineries?.some((w) => w.winegrower === winery)) {
    throw new Error("User already exists in winery");
  } else {
    if (userDoc.wineries === undefined) {
      userDoc.wineries = [];
    }

    userDoc.wineries.push({
      approved: true,
      accepted: false,
      trustedForRegistration: trustedForRegistration,
      role: role,
      winegrower: winery,
    });

    updateUserDocument(userDoc.id, userDoc);
    _callback();
  }
};

export const resetPassword = (email) => {
  return sendPasswordResetEmail(auth, email);
};
