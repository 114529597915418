import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { createSprayDocument } from '../firebase/spray';
import { PageTitle } from '../utils/PageTitle';
import { useSessionStorage } from '../logic/useSessionStorage';
import GoBackButton from "../components/FormComponents/GoBackButton";

var winegrowerid = '';

// Functie die zorgt voor het aanmaken van een spray

const CreateSpray = () => {
    PageTitle("Create Spray");
    const navigate = useNavigate();
    const params = useParams();
    const { register, setValue, handleSubmit } = useForm();
    const [isLoading, setLoading] = useState(false);
    const [currentWinery, setCurrentWinery] = useSessionStorage(
        'currentwinery',
        ''
    );
    var userData = '';
    const { t } = useTranslation();

    useEffect(() => {
        // Referenties naar firestore database (bv : ophalen van collecties uit firestore db)
        // Alle waarden in de variabelen gaan verloren éénmaal de useEffect volledig uitgevoerd is
        winegrowerid = currentWinery;
    }, []);

    const onSubmit = async (data) => {
        try {
            setLoading(true);
            data.winegrower = winegrowerid;
            createSprayDocument(data);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
            navigate(`/mySprays`);
        }
    };

    const formClassname = `ui big form twelve wide column ${isLoading ? 'loading' : ''
        }`;

    // Het weergeven van alle gegevens om een spray te creëeren

    return (
        <div
            className="add-form-container"
            style={{ maxWidth: 960, margin: 'auto', marginTop: '100px' }}
        >
            <h2 tyle={{ paddingBottom: '20px' }}>{t('vertaling.title_createSpray')}</h2>
            <GoBackButton />
            <br />
            <div
                className="ui grid stackable"
                style={{ margin: '50px auto', marginTop: '30px' }}
            >
                <form
                    className={formClassname}
                    onSubmit={handleSubmit(onSubmit)}
                    style={{ margin: 'auto' }}
                >
                    <div className="twelve wide field" style={{ margin: '40px auto' }}>
                        <label>
                            <input
                                type="text"
                                {...register('lotnr')}
                                placeholder={t('vertaling.colLotNr')}
                                required />
                        </label>
                    </div>
                    <div className="twelve wide field" style={{ margin: '40px auto' }}>
                        <label>
                            <input
                                type="text"
                                {...register('sort')}
                                placeholder={t('vertaling.colSort')}
                                required />
                        </label>
                    </div>
                    <div className="twelve wide field" style={{ margin: '40px auto' }}>
                        <label>
                            <input
                                type="text"
                                {...register('supplier')}
                                placeholder={t('vertaling.colSupplier')}
                                required />
                        </label>
                    </div>
                    <div
                        className="twelve wide field"
                        style={{
                            margin: '40px auto',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <label
                            style={{
                                display: 'inline-block',
                                width: '40%',
                                paddingLeft: '4%',
                                textAlign: 'left',
                                fontSize: 24,
                                color: '#ac9e66',
                                fontWeight: 'normal',
                            }}
                        >
                            {' '}
                            {t('vertaling.colPurchasedate') + ':'}{' '}
                        </label>
                        <input
                            style={{ width: '60%' }}
                            type="date"
                            {...register('purchasedate')}
                            defaultValue={new Date().toISOString().substr(0, 10)}
                            required />
                    </div>
                    <div className="twelve wide field" style={{ margin: '40px auto' }}>
                        <label>
                            <input
                                type="number"
                                {...register('liters')}
                                min="0"
                                step="0.01"
                                placeholder={t('vertaling.colLiters')}
                                required />
                        </label>
                    </div>
                    <div className="twelve wide field" style={{ margin: '40px auto' }}>
                        <div
                            style={{
                                float: 'left',
                                width: '40%',
                                paddingLeft: '4%',
                                fontSize: 24,
                                color: '#ac9e66',
                                fontWeight: 'normal',
                                textAlign: 'start',
                            }}
                        >
                            {' '}
                            {t('vertaling.colResale') + ' ?'}{' '}
                        </div>
                        <div
                            className="container"
                            style={{ float: 'left', width: '60%', textAlign: 'start' }}
                        >
                            <label className="switch" htmlFor="checkbox">
                                <input type="checkbox" {...register('resale')} id="checkbox"></input>
                                <div className="slider round"></div>
                            </label>
                        </div>
                    </div>

                    <button
                        type="submit"
                        className="ui submit grey large button right floated"
                        style={{ marginRight: '12%' }}
                    >
                        {' '}
                        {t("vertaling.submit")}{' '}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default CreateSpray;