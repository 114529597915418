import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import LanguageSelector from './LanguageSelector'

const NavUserLoggedOut = () => {
    const { t } = useTranslation();
    return (
        <>
            <span className="nav_logo">Craft Your Taste - By Tastefever</span>
            <div className="collapse navbar-collapse">
                <div className='navbar-nav ml-auto' style={{ paddingRight: "120px" }}>
                    <Link to="/login">
                        <button className="ui primary button nav-button">
                            {t("vertaling.login_nav")}
                        </button>
                    </Link>
                    <LanguageSelector />
                </div>
            </div>
        </>
    )
}

export default NavUserLoggedOut