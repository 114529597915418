import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { firestore } from '../../firebase/config';
import { useSessionStorage } from '../../logic/useSessionStorage';
import { PageTitle } from '../../utils/PageTitle';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, onSnapshot, collection } from 'firebase/firestore';
import MaterialTable from '@material-table/core';
import GoBackButton from '../../components/FormComponents/GoBackButton';

const SelectBatch = () => {
  const [currentStep, setCurrentStep] = useState([]);
  const [currentNavStep, setCurrentNavStep] = useState([]);
  const [batches, setBatches] = useState([]);
  const [harvestYears, setHarvestYears] = useState([]);
  const [harvestYearsDoc, setHarvestYearsDoc] = useState([]);
  const [batchesWithName, setBatchesWithName] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [cellarRegistrationData, setCellarRegistrationData] = useSessionStorage('cellarReg', '');
  const navigate = useNavigate();
  const [currentWinery, setCurrentWinery] = useSessionStorage('currentwinery', '');
  const [batch, setBatch] = useSessionStorage('batch', '');
  const params = useParams();
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const lang = i18n.language;
  let currentLang = '';

  PageTitle('Select Batch');
  useEffect(() => {
    if (lang === 'en') {
      currentLang = 'nameEN';
    } else if (lang === 'fr') {
      currentLang = 'nameFR';
    } else if (lang === 'nl') {
      currentLang = 'nameNL';
    }

    const stepNavRef = doc(firestore, 'winegrowers', currentWinery, 'cellarSteps', params.stepId);
    const unsubscribeNavStep = onSnapshot(stepNavRef, (doc) => {
      if (doc.exists) {
        setCurrentNavStep(doc.data()[currentLang]);
      }
    });

    const stepRef = doc(firestore, 'winegrowers', currentWinery, 'cellarSteps', params.stepId);
    const unsubscribeStep = onSnapshot(stepRef, (doc) => {
      let step;
      if (doc.exists) {
        step = doc.data();
        step.id = doc.id;
      }
      setCurrentStep(step);
    });

    const harvestYearRef = collection(firestore, 'winegrowers', currentWinery, 'harvestYear');

    const unsubscribeHarvestYear = onSnapshot(harvestYearRef, (querySnapshot) => {
      const harvestYearDoc = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setHarvestYearsDoc(harvestYearDoc);
    });

    const batchesRef = collection(firestore, 'winegrowers', currentWinery, 'batches');

    const unsubscribeBatches = onSnapshot(batchesRef, (querySnapshot) => {
      const batchesDoc = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBatches(batchesDoc);
      let batchesWithNameDoc = [];
      batchesDoc.forEach((batch) => {
        harvestYearsDoc.forEach((harvestYear) => {
          if (batch.harvestYearId === harvestYear.id) {
            batch.harvestYearName = harvestYear.name;
              batchesWithNameDoc.push(batch);
          }
        });
      });
      setBatchesWithName(batchesWithNameDoc);
      setHarvestYears(harvestYears);

  });
  return () => {
    unsubscribeStep();
    unsubscribeHarvestYear();  
    unsubscribeBatches();
  };
}, [currentWinery, params.stepId]);

useEffect(() => {
  // Update 'batchesWithName' every time 'batches' or 'harvestYearsDoc' changes
  let batchesWithNameDoc = [];
  batches.forEach((batch) => {
    harvestYearsDoc.forEach((harvestYear) => {
      if (batch.harvestYearId === harvestYear.id) {
        batch.harvestYearName = harvestYear.name;
        batchesWithNameDoc.push(batch);
      }
    });
  });
  setBatchesWithName(batchesWithNameDoc);
}, [batches, harvestYearsDoc]); 

const isMultiSelect = () => {
  return currentStep.selection === 'multi';
};

const handleOnSubmit = () => {
  navigateToRegistration('', selectedRows);
};

const onRowSelectionChange = (rows) => {
  let arraySelectedRowsId = [];
  for (let i = 0; i < rows.length; i++) {
    arraySelectedRowsId.push(rows[i].id);
  }
  setSelectedRows(arraySelectedRowsId);
};

const navigateToRegistration = (event, rowData) => {
  setBatch(rowData.name);
  const cellarReg = {
    stepId: params.stepId,
    batchId: rowData.id,
  };

  //Check whether the step allows to select multiple batches
  if (isMultiSelect() === false) {
    batches.forEach((batch) => {
      if (batch.id === rowData.id) {
        cellarReg.agriculturalParcels = batch.agriculturalParcels;
      }
    });
  } else {
    let batchesToMerge = [];
    batches.forEach((batch) => {
      selectedRows.forEach((row) => {
        if (batch.id === row) {
          batchesToMerge.push(batch);
        }
      });
    });
    cellarReg.batchesToMerge = batchesToMerge;
  }
  setCellarRegistrationData(cellarReg);
  if (
    currentStep.type === 'customPressing' ||
    currentStep.type === 'customFiltering' ||
    currentStep.type === 'customTransferSameBatch'
  ) {
    setTimeout(() => {
      navigate('/cellarRegistration/registerCustomCellarStep');
    }, 500);
  } else if (currentStep.type === 'customWoodAging') {
    setTimeout(() => {
      navigate('/cellarRegistration/customWoodAging');
    }, 500);
  } else if (currentStep.type === 'customTransferNewBatch') {
    setTimeout(() => {
      navigate('/cellarRegistration/customTransferNewBatch');
    }, 500);
  } else if (currentStep.type === 'customMerge') {
    setTimeout(() => {
      navigate('/cellarRegistration/customMerge');
    }, 500);
  } else if (currentStep.type === 'customBottling') {
    setTimeout(() => {
      navigate('/cellarRegistration/customBottling');
    }, 500);
  } else {
    setTimeout(() => {
      navigate(`/cellarRegistration/registerCellarStep/${params.stepId}`);
    }, 500);
  }
};

return (
  <div>
    <h1 style={{ color: '#7f7f7f' }}>
      {t('translation.cellarRegistration.selectBatchForRegistration') + " " + currentNavStep}
    </h1>
    <GoBackButton />
    <br />
    <div className="batchesList">
      <MaterialTable
          title=""
          data={batchesWithName}
          onRowClick={navigateToRegistration}
          columns={[
            {
              title: t('translation.cellarRegistration.harvestYearName'),
              field: 'harvestYearName',
            },
            {
              title: t('translation.cellarRegistration.batchName'),
              field: 'name',
            },
          ]}
          onSelectionChange={(rows) => onRowSelectionChange(rows)}
          options={{
            selection: isMultiSelect(),
            tableLayout: 'auto',
            filtering: true,
            exportButton: true,
            exportAllData: true,
            headerStyle: {
              backgroundColor: '#cecaca',
              color: '#FFF',
            },

            emptyRowsWhenPaging: false,
            paging: true,
            pageSize: 50,
            pageSizeOptions: [
              10,
              20,
              25,
              50,
            ],
          }}
        />
      {isMultiSelect() === true && (
        <button
          onClick={handleOnSubmit}
          className="ui submit large grey button right floated button-vineyards"
          style={{
            float: 'none',
            marginTop: '30px',
            marginBottom: '30px',
            width: '25%',
          }}
        >
          {t("translation.words.next")}
        </button>
      )}
    </div>
  </div>
);
};
export default SelectBatch;