import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react'
import DashboardButton from '../../../components/Dashboard/Button'
import Divider from '../../../components/Dashboard/Divider'
import { Roles } from '../../../logic/Roles'

const ReportingButtons = ({ userPermissionLevel }) => {
    const [hidden, setHidden] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        let element = document.querySelector(".reportingButtons");
        element.childNodes.length === 0 ? setHidden(true) : setHidden(false);
    });


    return (
        <>
            <Divider title={t('translation.words.reporting')} hidden={hidden} />
            <div className="reportingButtons row" style={{ margin: "0", width: "100%" }}>
                <DashboardButton
                    url={"/government"}
                    imgAlt={"dashboard-icon"}
                    imgSrc={"/images/DashboardIcons/Government.svg"}
                    title={t("translation.dashboard.government.title")}
                    text={t("translation.dashboard.government.body")}
                    userPermissionLevel={userPermissionLevel}
                    btnPermissionLevel={Roles().WINEGROWER.permissionLevel}
                />

                <DashboardButton
                    url={"/history"}
                    imgAlt={"dashboard-icon"}
                    imgSrc={"/images/DashboardIcons/History.svg"}
                    title={t("vertaling.h10_dashboard")}
                    text={t("vertaling.p10_dashboard")}
                    userPermissionLevel={userPermissionLevel}
                    btnPermissionLevel={Roles().WINEGROWER.permissionLevel}
                />

                <DashboardButton
                    url={"/traceBatchUpdated"}
                    imgAlt={"dashboard-icon"}
                    imgSrc={"/images/DashboardIcons/TraceBatch.svg"}
                    title={t("vertaling.trace_batch")}
                    text={t("vertaling.trace_h1")}
                    userPermissionLevel={userPermissionLevel}
                    btnPermissionLevel={Roles().ADMIN.permissionLevel}
                />

                <DashboardButton
                    url={"/tracevineyard"}
                    imgAlt={"dashboard-icon"}
                    imgSrc={"/images/DashboardIcons/TraceVineyard.svg"}
                    title={t("vertaling.trace_vineyard")}
                    text={t("vertaling.trace_vineyard_h1")}
                    userPermissionLevel={userPermissionLevel}
                    btnPermissionLevel={Roles().WINEGROWER.permissionLevel}
                />
                <DashboardButton
                    url={"/barrelReport"}
                    imgAlt={"dashboard-icon"}
                    imgSrc={"/images/DashboardIcons/TraceTank.svg"}
                    title={t("translation.barrelReport.title")}
                    text={t("translation.barrelReport.subTitle")}
                    userPermissionLevel={userPermissionLevel}
                    btnPermissionLevel={Roles().ADMIN.permissionLevel}
                />
            </div>
        </>
    )
}

export default ReportingButtons