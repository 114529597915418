import { useTranslation } from 'react-i18next';
import { send } from 'emailjs-com';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../context/AuthContext';
import { signupEmployee } from '../../firebase/auth';
import { collection, query, where, doc, getDoc, onSnapshot } from 'firebase/firestore';
import { PageTitle } from '../../utils/PageTitle';
import { Roles } from '../../logic/Roles';
import { useSessionStorage } from '../../logic/useSessionStorage';
import GoBackButton from "../../components/FormComponents/GoBackButton";
import { firestore } from '../../firebase/config';

const Addemployee = () => {
    PageTitle("Add Employee");
    const { currentUser } = useAuth();
    const { register, handleSubmit } = useForm();
    const [isLoading, setLoading] = useState(false);
    const [userDocument, setUserDocument] = useState(null);
    const [currentWinery, setCurrentWinery] = useSessionStorage('currentwinery', '');
    const [errorMessage, setErrorMessage] = useState(false);
    const [inviteMessage, setInviteMessage] = useState('');
    const [inviteSend, setInviteSend] = useState(false);
    const [email, setEmail] = useState('');
    const { t } = useTranslation();

    /*Functie die alle variabelen in mailingplatform emailjs hier definieert en vertaalt*/

    const [emailWithInfoNewUser, setEmailWithInfoNewUser] = useState({
        subject: t('translation.emails.registrationInfoNewUser.subject'),
        greeting: t('translation.emails.welcome'),
        message1: '',
        message2: t('translation.emails.registrationInfoNewUser.message2'),
        urlToWebsite: 'https://poc.craftyourtaste.com/login',
        emailLabel: t('translation.words.email'),
        email: '',
        thanks: t('translation.emails.thanks'),
        moreInfo: t('translation.emails.moreInfo'),
        country: t('translation.words.belgium'),
    });

    const [emailWithPasswordNewUser, setEmailWithPasswordNewUser] = useState({
        subject: t('translation.emails.registrationInfoNewUser.subject'),
        greeting: t('translation.emails.welcome'),
        message1: t('translation.emails.registrationPasswordNewUser.message1'),
        passwordLabel: t('translation.words.password'),
        password: '',
        thanks: t('translation.emails.thanks'),
        moreInfo: t('translation.emails.moreInfo'),
        country: t('translation.words.belgium'),
    });

    const [emailExistingUser, setEmailExistingUser] = useState({
        subject: t('translation.emails.registrationInfoNewUser.subject'),
        greeting: t('translation.emails.welcome'),
        message1: t('translation.emails.registrationInfoExistingUser.message1'),
        urlToWebsite: 'https://poc.craftyourtaste.com/login',
        emailLabel: t('translation.words.email'),
        email: '',
        thanks: t('translation.emails.thanks'),
        moreInfo: t('translation.emails.moreInfo'),
        country: t('translation.words.belgium'),
    });

    useEffect(() => {
        if (currentUser !== undefined) {
            const docRef = query(collection(firestore, 'users'), where('email', '==', currentUser.email));
            const unsubscribe = onSnapshot(docRef, (querySnapshot) => {
                const userLoggedIn = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setUserDocument(userLoggedIn[0]);

                const docRef = doc(firestore, 'winegrowers', currentWinery);
                getDoc(docRef).then((doc) => {
                    if (doc.exists) {
                        let winegrowerName = doc.data().name;
                        var newToSend = emailWithInfoNewUser;
                        newToSend.message1 = t('translation.emails.registrationInfoNewUser.message1') + winegrowerName + '.';
                        setEmailWithInfoNewUser(newToSend);
                    } else {
                        throw 'Winery with id ' + currentWinery + ' does not exist';
                    }
                });
            });
            return () => {
                unsubscribe();
            };
        }
    }, [currentUser]);

    const onSubmit = async (data, e) => {
        try {
            setLoading(true);
            setInviteSend(false);
            // if (data.trustedForRegistration === 'true') {
            data.trustedForRegistration = true;
            // } else if (data.trustedForRegistration === 'false') {
            //     data.trustedForRegistration = false;
            // }
            data.winery = currentWinery;
            await signupEmployee(data, sendMail);
            setInviteMessage(data.email);
            setInviteSend(true);
            setEmail('');
        } catch (error) {
            setLoading(false);
            setInviteSend(false);
            setErrorMessage(error);
        }
    };

    const sendMail = (password) => {
        setErrorMessage('');

        if (password !== undefined) {
            send('service_7hgpmpf', 'template_hda1mat', emailWithInfoNewUser, 'user_EDqZc5RPaMddv4DU2Jmmo').then((response) => {
                // console.log('SUCCESS!', response.status, response.text);
            });

            let temp = emailWithPasswordNewUser;
            temp.password = password;
            setEmailWithPasswordNewUser(temp);

            send('service_7hgpmpf', 'template_2dko46z', emailWithPasswordNewUser, 'user_EDqZc5RPaMddv4DU2Jmmo').then((response) => {
                // console.log('SUCCESS!', response.status, response.text);
            });
        } else {
            send('service_7hgpmpf', 'template_hda1mat', emailExistingUser, 'user_EDqZc5RPaMddv4DU2Jmmo').then((response) => {
                // console.log('SUCCESS!', response.status, response.text);
            });
        }

        setLoading(false);
    };

    // upddate email input
    const handleChange = (e) => {
        setEmailWithInfoNewUser({ ...emailWithInfoNewUser, [e.target.name]: e.target.value });
        setEmailWithPasswordNewUser({ ...emailWithPasswordNewUser, [e.target.name]: e.target.value });
        setEmailExistingUser({ ...emailExistingUser, [e.target.name]: e.target.value, });
        setEmail(e.target.value);
    };

    const formClassname = `ui big form twelve wide column ${isLoading ? 'loading' : ''}`;
    let showAdmin = `hidden`;
    let showAcces = `show`;
    if (userDocument != null) {
        if (userDocument.role === Roles().ADMIN.name || userDocument.wineries?.find((w) => w.winegrower === currentWinery).role === Roles().WINEGROWER.name) {
            showAdmin = `show`;
            showAcces = `hidden`;
        }
    }

    return (
        // Het weergeven van alle gegevens van een werknemer
        <div
            className="add-form-container"
            style={{ maxWidth: 960, margin: '50px auto', marginTop: '90px' }}
        >
            <h2>{t("vertaling.title_gebruikersgegevens")}</h2>
            <GoBackButton />
            <br />
            <div className={`ui grid stackable ` + showAdmin}>
                <form className={formClassname} onSubmit={handleSubmit(onSubmit)}>
                    <div className="fields">
                        <div className="sixteen wide center field">
                            <label>
                                <p>{t("vertaling.email")}</p>
                                <input
                                    type="email"
                                    {...register('email')}
                                    htmlFor="email"
                                    onChange={handleChange}
                                    value={email} />
                            </label>
                        </div>
                    </div>

                    <div className="fields">
                        <div className="sixteen wide field">
                            <label>
                                <p>{t("vertaling.role")}</p>
                                <select
                                    className="specialty"
                                    {...register('role')}
                                    htmlFor="role"
                                    defaultValue={Roles().WINEGROWER.name}
                                >
                                    <option value={Roles().EMPLOYEE.name}>
                                        {Roles().EMPLOYEE.name}
                                    </option>
                                    <option value={Roles().WINEGROWER.name}>
                                        {Roles().WINEGROWER.name}
                                    </option>
                                </select>
                            </label>
                        </div>

                        {/* <div className="eight wide field">
                            <label>
                                <p>{t("vertaling.trusted_regis")}</p>
                                <select
                                    className="specialty"
                                    {...register('trustedForRegistration')}
                                    htmlFor="trustedForRegistration"
                                    defaultValue="true"
                                >
                                    <option value="true">
                                        {t('vertaling.optie_yes')}
                                    </option>
                                    <option value="false">
                                        {' '}
                                        {t('vertaling.optie_no')}
                                    </option>
                                </select>
                            </label>
                        </div> */}
                    </div>
                    <button
                        type="submit"
                        className="ui submit large grey button center floated"
                    >
                        <b>{t("vertaling.submit")}</b>
                    </button>
                </form>

                {/* errors */}
                {errorMessage && (
                    <div>
                        <p style={{ color: 'red', fontWeight: 'bold', fontSize: '25px' }}>
                            {String(errorMessage)}
                        </p>
                    </div>
                )}
                {inviteSend && (
                    <div>
                        <p style={{ color: 'black', fontWeight: 'bold', fontSize: '25px' }}>
                            {t("vertaling.inviteSend")} {inviteMessage}
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Addemployee;