import React, { createContext, useContext, useEffect, useState, useRef } from "react";
import { useSessionStorage } from "../logic/useSessionStorage";
import { useAuth } from "./AuthContext";
import { firestore } from '../firebase/config';
import { collection, doc, getDoc, getDocs, updateDoc, deleteDoc, onSnapshot, writeBatch, query, where, orderBy, Timestamp, addDoc } from "firebase/firestore";

const FirestoreContext = createContext({});

export const useFirestore = () => {
    return useContext(FirestoreContext);
}

export const FirestoreProvider = ({ children }) => {
    const { currentUser } = useAuth();
    const [currentUserDoc, setCurrentUserDoc] = useState(undefined);
    const [allUserDocs, setAllUserDocs] = useState(undefined);
    const [approvedWineries, setApprovedWineries] = useState([]);
    const [currentWinery] = useSessionStorage('currentwinery', '');
    const [data, setData] = useState({});
    const subscriptions = useRef({});

    //initializers
    useEffect(() => {
        async function initialize() {
            await getCurrentUserDoc();
        }
        initialize();
    }, [currentUser]);

    useEffect(() => {
        async function initialize() {
            if (currentUserDoc) {
                await getApprovedWineries();
                await getAllUserDocs();
            }
        }
        initialize();
    }, [currentUserDoc])

    const getCurrentUserDoc = async () => {
        if (currentUser) {
            const userDocRef = doc(firestore, 'users', currentUser.uid);
            const unsubscribe = onSnapshot(userDocRef, (doc) => {
                if (doc.exists()) {
                    let userDoc = doc.data();
                    if (userDoc) {
                        userDoc.id = doc.id;
                        setCurrentUserDoc(userDoc);
                    }
                } else {
                    console.log("No such document!");
                }
            });
            return () => {
                unsubscribe();
            };
        }
    }

    const subscribeToCollection = (collectionPath, param) => {
        const existingSubscription = subscriptions.current[collectionPath];
    
        if (existingSubscription) {
            // Immediately return the current data for the collectionPath
            return { data: data[collectionPath] || [], unsubscribe: () => {} };
        }
    
        const isCollection = !param;
        let unsubscribe;
    
        if (isCollection) {
            const ref = collection(firestore, ...collectionPath.split('/'));
            unsubscribe = onSnapshot(ref, (querySnapshot) => {
                const docs = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                setData(prevData => ({
                    ...prevData,
                    [collectionPath]: docs,
                }));
            });
        } else {
            const docRef = doc(firestore, `${collectionPath}/${param}`);
            unsubscribe = onSnapshot(docRef, (docSnapshot) => {
                if (docSnapshot.exists()) {
                    const docData = { id: docSnapshot.id, ...docSnapshot.data() };
                    setData(prevData => ({
                        ...prevData,
                        [collectionPath]: docData,
                    }));
                } else {
                    console.log("No such document!");
                }
            });
        }
    
        // Store the unsubscribe function to manage the subscription
        subscriptions.current[collectionPath] = unsubscribe;
        
        return {
            data: data[collectionPath] || [],
            unsubscribe: () => {
                unsubscribe();
                delete subscriptions.current[collectionPath];
            },
        };
    };

    const getCellarSteps = (winery) => {
        let cellarSubscription = subscribeToCollection(`winegrowers/${winery}/cellarSteps`);
        return cellarSubscription.data;
    }

    const getVineyardSteps = (winery) => {
        let vineyardSubscription = subscribeToCollection(`winegrowers/${winery}/vineyardSteps`);
        return vineyardSubscription.data;
}

    const getBatches = (winery) => {
        let batchesSubscription = subscribeToCollection(`winegrowers/${winery}/batches`);
        return batchesSubscription.data;
    }

    const getVineyards = (winery) => {
        let vineyardsSubscription = subscribeToCollection(`winegrowers/${winery}/vineyards`);
        return vineyardsSubscription.data;
    };

    const getAgriculturalParcels = (winery) => {
        let parcelsSubscription = subscribeToCollection(`winegrowers/${winery}/agriculturalParcels`);
        return parcelsSubscription.data;
    }

    const getRegistrations = (winery) => {
        let registrationSubscription = subscribeToCollection(`winegrowers/${winery}/registrations`);
        return registrationSubscription.data;
    };


    const getCellars = (winery) => {
        let cellarsSubscription = subscribeToCollection(`winegrowers/${winery}/cellars`);
        return cellarsSubscription.data;
    };

    const updateCurrentUserDoc = async (data) => {
        if (currentUser) {
            delete data.id;
            const userDocRef = doc(firestore, 'users', currentUser.uid);
            await updateDoc(userDocRef, data);
        }
    }

    const updateUserDoc = async (data) => {
        const id = data.id;
        delete data.id;
        const userDocRef = doc(firestore, 'users', id);
        await updateDoc(userDocRef, data);
    }

    const getAllUserDocs = async () => {
        const userCollectionRef = collection(firestore, 'users');
        const unsubscribe = onSnapshot(userCollectionRef, (snapshot) => {
            const list = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setAllUserDocs(list);
        });
        return () => {
            unsubscribe();
        };
    }

    const getApprovedWineries = async () => {
        const q = query(collection(firestore, 'winegrowers'), where('approved', '==', true), orderBy("name"));
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const list = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setApprovedWineries(list);
        });
        return () => {
            unsubscribe();
        };
    }

    const removeEmployeeFromWinery = async (id, userWineries, currentWinery) => {
        const userDocRef = doc(firestore, 'users', id);
        await updateDoc(userDocRef, {
            wineries: userWineries.filter(winery => winery.winegrower !== currentWinery),
        });
    }

    const ClearWineriesArray = async (uid) => {
        try {
            const userDocRef = doc(firestore, 'users', uid);
            await updateDoc(userDocRef, {
                wineries: []
            });
        } catch (error) {
            console.error("Error updating document: ", error);
        }
    }

    const resetVineyardSteps = async () => {
        const vineyardStepsRootQuery = query(collection(firestore, 'vineyardSteps'));
        const vineyardStepsRootSnapshot = await getDocs(vineyardStepsRootQuery);
        const vineyardStepsRoot = vineyardStepsRootSnapshot.docs.map(doc => doc.data());

        setTimeout(() => { }, 1000);
        const vineyardStepsRefQuery = collection(firestore, "winegrowers", currentWinery, "vineyardSteps");
        const vineyardStepsRefSnapshot = await getDocs(vineyardStepsRefQuery);

        const deleteBatch = writeBatch(firestore);

        //deleting old documents
        vineyardStepsRefSnapshot.docs.forEach((doc) => {
            deleteBatch.delete(doc.ref);
        });
        await deleteBatch.commit()

        //adding root documents
        const addBatch = writeBatch(firestore);
        vineyardStepsRoot.forEach(vineyardStep => {
            let docRef = doc(collection(firestore, 'winegrowers', currentWinery, 'vineyardSteps'));
            addBatch.set(docRef, vineyardStep);
        });
        await addBatch.commit();
    }

    const getHarvestYears = async (winery) => {
        const q = query(collection(firestore, `winegrowers/${winery}/harvestYear`));
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        }));
    };

    const createHarvestYear = async (winery, harvestYear) => {
        const startDateTimestamp = Timestamp.fromDate(harvestYear.startDate);
        const endDateTimestamp = Timestamp.fromDate(harvestYear.endDate);

        const docRef = collection(firestore, 'winegrowers', winery, 'harvestYear');

        await addDoc(docRef, {
            name: harvestYear.name,
            startDate: startDateTimestamp,
            endDate: endDateTimestamp,
        });
    };

    const updateHarvestYear = async (winery, harvestYearId, harvestYear) => {
        const startDateTimestamp = Timestamp.fromDate(harvestYear.startDate);
        const endDateTimestamp = Timestamp.fromDate(harvestYear.endDate);

        const docRef = doc(firestore, "winegrowers", winery, "harvestYear", harvestYearId);

        await updateDoc(docRef, {
            name: harvestYear.name,
            startDate: startDateTimestamp,
            endDate: endDateTimestamp,
        });
    };

    const getParcels = async (winery) => {
        const q = query(collection(firestore, `winegrowers/${winery}/parcels`));
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        }));
    };

    const getRegistration = async (winery, registrationId) => {
        const docRef = doc(firestore, `winegrowers/${winery}/registrations/${registrationId}`);
        const docSnap = await getDoc(docRef);
        const registration = docSnap.data();

        // Converting firestore timestamps to javascript date objects
        if (registration) {
            if (registration.date) registration.date = registration.date.toDate();
            if (registration.endDate !== undefined) registration.endDate = registration.endDate.toDate();
            if (registration.startDate !== undefined) registration.startDate = registration.startDate.toDate();
        }
        return registration;
    };

    const getBatch = async (winery, batchId) => {
        const docRef = doc(firestore, `winegrowers/${winery}/batches/${batchId}`);
        const docSnap = await getDoc(docRef);
        return docSnap.data();
    }

    const getWineStorages = async (winery) => {
        const q = query(collection(firestore, `winegrowers/${winery}/wineStorages`));
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data()
        }));
    }

    const getWineryDocument = async (winery) => {
        const docRef = doc(firestore, `winegrowers/${winery}`);
        const docSnap = await getDoc(docRef);
        const data = docSnap.data();
        if (data !== undefined) {
            data.id = docSnap.id;
        }
        return data;
    }

    const deleteWinery = async (winery) => {
        const wineryDocRef = doc(firestore, 'winegrowers', winery);
        try {
            await deleteDoc(wineryDocRef);
        } catch (error) {
            console.error("Error removing document: ", error);
        }

        const usersQuery = query(collection(firestore, 'users'));
        const querySnapshot = await getDocs(usersQuery);
        let users = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        }));

        // Getting users that have the deleted winery in their array & remove the corresponding object
        users = users.filter(user => user.wineries.some(w => w.winegrower === winery));
        users.forEach(user => {
            user.wineries = user.wineries.filter(w => w.winegrower !== winery);
            updateUserDoc(user);
        });
    }

    const getCurrentCellarStep = async (stepId, winery) => {
        const docRef = doc(firestore, `winegrowers/${winery}/cellarSteps/${stepId}`);
        const docSnap = await getDoc(docRef);
        const data = docSnap.data();
        if (data !== undefined) {
            data.id = docSnap.id;
        }
        return data;
    }

    const getBatchById = async (batchId, winery) => {
        const batchDocRef = doc(firestore, 'winegrowers', winery, 'batches', batchId);
        const docSnap = await getDoc(batchDocRef);
        if (docSnap.exists()) {
            let data = docSnap.data();
            data.id = docSnap.id;
            return data;
        } else {
            console.log("No such document!");
            return null;
        }
    };

    const getWineStorageById = async (wineStorageId, winery) => {
        const docRef = doc(firestore, `winegrowers/${winery}/wineStorages/${wineStorageId}`);
        const docSnap = await getDoc(docRef);
        const data = docSnap.data();
        if (data !== undefined) {
            data.id = docSnap.id;
        }
        return data;
    }

    const getBottleTypes = async () => {
        const querySnapshot = await getDocs(collection(firestore, "bottletypes"));
        const bottles = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data()
        }));
        return bottles;
    }

    const getHarvestSteps = async (winery) => {
        const docRef = doc(firestore, `winegrowers/${winery}/customSteps/harvestStep`);
        const docSnap = await getDoc(docRef);
        const data = docSnap.data();
        if (data !== undefined) {
            data.id = docSnap.id;
        }
        return data;
    }

    const getChemicals = async () => {
        const docsRef = collection(firestore, `chemicalsData`);
        const querySnapshot = await getDocs(docsRef);
        return querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        }));
    };

    useEffect(() => {
        return () => {
            // Cleanup subscriptions on component unmount
            Object.keys(subscriptions.current).forEach(key => {
                const unsubscribe = subscriptions.current[key];
                if (typeof unsubscribe === 'function') {
                    unsubscribe();
                }
            });
        };
    }, []);



    return (
        <FirestoreContext.Provider value={{
            currentUserDoc,
            allUserDocs,
            approvedWineries,
            updateCurrentUserDoc,
            updateUserDoc,
            getApprovedWineries,
            removeEmployeeFromWinery,
            ClearWineriesArray,
            getVineyardSteps,
            resetVineyardSteps,
            getCellarSteps,
            getHarvestYears,
            createHarvestYear,
            updateHarvestYear,
            getAgriculturalParcels,
            getParcels,
            getRegistrations,
            getRegistration,
            getCellars,
            getBatches,
            getBatch,
            getWineryDocument,
            deleteWinery,
            getCurrentCellarStep,
            getBatchById,
            getWineStorages,
            getWineStorageById,
            getBottleTypes,
            getHarvestSteps,
            getVineyards,
            getChemicals
        }}>
            {children}
        </FirestoreContext.Provider>
    )
}

export default FirestoreContext;