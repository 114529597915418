import React, { useContext } from 'react';
import { createRoot } from 'react-dom/client';
import './bootstrap.css';
import './Styles/App.css';
import './Styles/semantic-ui.custom.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import { FirestoreProvider } from './context/FirestoreContext.js';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';



const RootComponent = () => {
  return (
    <I18nextProvider i18n={i18n}> {/* Using I18nextProvider */}
      <React.StrictMode>
        <AuthProvider>
          <FirestoreProvider>
            <Router>
              <Routes>
                <Route path="/*" element={<App />} />
              </Routes>
            </Router>
          </FirestoreProvider>
        </AuthProvider>
      </React.StrictMode>
    </I18nextProvider>
  );
};

createRoot(document.getElementById('root')).render(
  <RootComponent />
);

reportWebVitals();