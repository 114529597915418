import { Link } from "react-router-dom";

const DashboardButton = ({ url, imgAlt, imgSrc, title, text, btnPermissionLevel, userPermissionLevel, hidden }) => {
    if ((btnPermissionLevel !== undefined && userPermissionLevel !== undefined) && userPermissionLevel < btnPermissionLevel) return null;
    
    return (
        <div className={`col-lg-4 col-sm-6 ${hidden ? 'hidden' : ''}`}>
            <Link to={url}>
                <div className="single-service ss6">
                    <div className="service-img">
                        <img alt={imgAlt} className="dash-icon" src={imgSrc} />
                    </div>
                    <div className="service-content">
                        <h3>{title}</h3>
                        <p>{text}</p>
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default DashboardButton;