import React, { useState } from 'react';

function MissingDataModal({ data, onContinue, onCancel }) {
  const [formData, setFormData] = useState(data);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Missing Data, please fill in manually.</h2>
        <p>Selling and buying grapes/most/wine is currently not supported.</p>
        <br></br>

        <form>
          {Object.keys(formData).map((key) => (
            <div key={key} className="input-group">
              <label>The {key}</label>
              <input
                type="text"
                name={key}
                value={formData[key]}
                onChange={handleChange}
              />
            </div>
          ))}
        </form>

        <div className="modal-actions">
          <button onClick={onCancel}>Cancel</button>
          <button onClick={() => onContinue(formData)}>Continue</button>
        </div>
      </div>
    </div>
  );
}

export default MissingDataModal;
