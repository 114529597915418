import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { firestore } from "../../firebase/config";
import { useSessionStorage } from "../../logic/useSessionStorage";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { updateCellarStep } from "../../firebase/cellarSteps";
import GoBackButton from "../../components/FormComponents/GoBackButton";
import { doc, onSnapshot } from 'firebase/firestore'; 

const CellarStepDetail = () => {
    const [currentWinery, setCurrentWinery] = useSessionStorage("currentwinery", "");
    const params = useParams();
    const formClassname = "ui big form twelve wide column";
    const [currentCellarStep, setCurrentCellarStep] = useState([]);
    const { register, handleSubmit, setValue } = useForm({
        defaultValues: {
          nameEN: currentCellarStep.nameEN,
          nameFR: currentCellarStep.nameFR,
          nameIT: currentCellarStep.nameIT,
          nameNL: currentCellarStep.nameNL,
          number: currentCellarStep.number,
          selection: currentCellarStep.selection,
        }
      });
    const selectionTypes = ["multi", 'single'];
    const navigate = useNavigate();
    const [selectedSelection, setSelectedSelection] = useState("");

    const [error, setError] = useState('hidden');
    const { t } = useTranslation();

    let currentStep;
    useEffect(() => {
        const cellarStepDocRef = doc(firestore, 'winegrowers', currentWinery, 'cellarSteps', params.id);

        const unsubscribe = onSnapshot(cellarStepDocRef, (doc) => {
            if (doc.exists) {
                const data = doc.data();
            setCurrentCellarStep(data);

            // Setting form values based on Firestore document
            setValue("nameEN", data.nameEN);
            setValue("nameFR", data.nameFR);
            setValue("nameIT", data.nameIT);
            setValue("nameNL", data.nameNL);
            setValue("number", data.number);
            setValue("selection", data.selection);

            if (data.selection != null) {
                setSelectedSelection(data.selection);
            }
        }
    })
        return () => {
            unsubscribe();
        };
    }, [currentWinery, params.id])

    const onSubmit = async (data) => {
        try {
            if (selectedSelection === '') {
                setError('visible');
            } else {
                setError('hidden');
                let newDoc = { ...currentCellarStep, ...data };
                newDoc.selection = selectedSelection;
                newDoc.number = Number(newDoc.number);
                await updateCellarStep(currentWinery, params.id, newDoc);
                navigate(-1)
            }
        } catch (error) {
            console.error(error);
        }
    }

    const handleSelectionChange = (selection) => {
        setSelectedSelection(selection.value);
    }


    return (
        <div>
            <div
                className="add-form-container"
                style={{
                    maxWidth: 960,
                    margin: "50px auto",
                    marginTop: "70px",
                }}
            >
               <h1 style={{ color: "#7f7f7f" }}>{t('translation.cellarSteps.updateCellarStep')}</h1>
                <GoBackButton />

                {currentCellarStep != undefined && (
                    <div className="ui grid stackable">
                        <form
                            className={formClassname}
                            style={{
                                border: "3px solid #B3B3B3",
                                marginTop: "50px",
                                borderRadius: "50px",
                                background: "#edeaea",
                                borderColor: "#ac9e66",
                            }}
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <div
                                className="fields"
                                style={{ justifyContent: "center", marginTop: "20px" }}
                            >
                                <div className="twelve wide field">
                                    <label>
                                        <p>{t("translation.vineyardSteps.nameEn")}</p>
                                        <input
                                            type="text"
                                            {...register('nameEN')}
                                            htmlFor="nameEN"
                                            required="required"
                                            defaultValue={currentCellarStep.nameEN}
                                            style={{ textAlign: "center" }} />
                                    </label>
                                </div>
                            </div>
                            <div
                                className="fields"
                                style={{ justifyContent: "center", marginTop: "20px" }}
                            >
                                <div className="twelve wide field">
                                    <label>
                                        <p>{t("translation.vineyardSteps.nameFr")}</p>
                                        <input
                                            type="text"
                                            {...register('nameFR')}
                                            htmlFor="nameFR"
                                            required="required"
                                            defaultValue={currentCellarStep.nameFR}
                                            style={{ textAlign: "center" }} />
                                    </label>
                                </div>
                            </div>
                            <div
                                className="fields"
                                style={{ justifyContent: "center", marginTop: "20px" }}
                            >
                                <div className="twelve wide field">
                                    <label>
                                        <p>{t("translation.vineyardSteps.nameIt")}</p>
                                        <input
                                            type="text"
                                            {...register('nameIT')}
                                            htmlFor="nameIT"
                                            required="required"
                                            defaultValue={currentCellarStep.nameIT}
                                            style={{ textAlign: "center" }} />
                                    </label>
                                </div>
                            </div>
                            <div
                                className="fields"
                                style={{ justifyContent: "center", marginTop: "20px" }}
                            >
                                <div className="twelve wide field">
                                    <label>
                                        <p>{t("translation.vineyardSteps.nameNl")}</p>
                                        <input
                                            type="text"
                                            {...register('nameNL')}
                                            htmlFor="nameNL"
                                            required="required"
                                            defaultValue={currentCellarStep.nameNL}
                                            style={{ textAlign: "center" }} />
                                    </label>
                                </div>
                            </div>
                            <div
                                className="fields"
                                style={{ justifyContent: "center", marginTop: "20px" }}
                            >
                                <div className="twelve wide field">
                                    <label>
                                        <p>{t("translation.vineyardSteps.number")}</p>
                                        <input
                                            type="number"
                                            {...register('number')}
                                            htmlFor="number"
                                            required="required"
                                            defaultValue={currentCellarStep.number}
                                            style={{ textAlign: "center" }} />
                                    </label>
                                </div>
                            </div>
                            <div
                                className="fields"
                                style={{ justifyContent: "center", marginTop: "20px" }}
                            >
                                <div className="twelve wide field">
                                    <label>
                                        <p>{t("translation.vineyardSteps.selectionType")}</p>
                                    </label>
                                    <Select
                                        required="required"
                                        label="selection"
                                        htmlFor="selection"
                                        options={selectionTypes.map((label) => ({
                                            label: label,
                                            value: label,
                                        }))}
                                        value={{ label: selectedSelection, value: selectedSelection }}
                                        onChange={(selection, action) =>
                                            handleSelectionChange(selection, action)
                                        }
                                    />
                                </div>
                            </div>
                            <div style={{ display: "none" }}>
                                <input
                                    {...register('selection')}
                                    htmlFor="selection"
                                    value={selectedSelection}
                                    readOnly />
                            </div>
                            <p style={{ visibility: error }} className="text-danger">
                                {t("translation.vineyardSteps.selectionTypeNotSelected")}
                            </p>
                            <button
                                type="submit"
                                className="ui submit large grey button right floated  button-vinyards"
                                style={{ float: "none", width: "25%" }}
                            >
                                {t("translation.cellarSteps.updateCellarStep")}
                            </button>
                        </form>
                    </div>
                )}
            </div>
        </div>
    );
}

export default CellarStepDetail;