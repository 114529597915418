import { useTranslation } from 'react-i18next';
import GoBackButton from "./FormComponents/GoBackButton";
import { useForm } from "react-hook-form";
import { useAuth } from "../context/AuthContext";
import { useEffect, useState } from "react";
import { useSessionStorage } from "../logic/useSessionStorage";
import { onSnapshot, collection, query, serverTimestamp } from 'firebase/firestore';
import { firestore } from "../firebase/config";
import { createBatchDocument } from "../firebase/batch";
import { useNavigate } from "react-router-dom";


const AddBatchComponent = (agriculturalParcels) => {

    const [harvestYear, setHarvestYear] = useState("");
    const navigate = useNavigate();

    const { currentUser } = useAuth();
    const { register, handleSubmit } = useForm();
    const formClassname = `ui big form twelve wide column`;
    const [currentWinery, setCurrentWinery] = useSessionStorage('currentwinery', '');
    const [harvestData, setHarvestData] = useSessionStorage("harvestData", "");
    const [batch, setBatch] = useSessionStorage("batch", "");
    const [shouldNavigate, setShouldNavigate] = useState(false);
    const { t } = useTranslation();


    useEffect(() => {
        const harvestYearsRef = query(
            collection(
                firestore,
                'winegrowers',
                currentWinery,
                'harvestYear'
            )
        );

        const unsubscribe = onSnapshot(harvestYearsRef, (snapshot) => {
            const harvestYearsDb = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data()
            }))
            setHarvestYear(harvestYearsDb)
        })
        return () => { unsubscribe() };
    }, [])

    useEffect(() => {
        if (batch && shouldNavigate) {
            navigate('/harvestRegistration/winestorages');
            setShouldNavigate(false); // Reset the flag
        }
      }, [batch, shouldNavigate]);


    const onSubmit = (data) => {
        let newBatchDoc = createBatchDoc(data)
        createBatchDocument(currentWinery, newBatchDoc).then((docRef) => {
            const firestoreIdNewDoc = docRef.id;
            setBatch(firestoreIdNewDoc);
            let newHarvestData;
            newHarvestData = {
                ...harvestData
            }
            newHarvestData.agriculturalParcels = newBatchDoc.agriculturalParcels;
            newHarvestData.batchId = firestoreIdNewDoc;
            setHarvestData(newHarvestData);
            setShouldNavigate(true);
        }
        )
    }

    const createBatchDoc = (data) => {
        let newBatch = {};
        let arrayAgriculturalParcelsId = [];
        let totalAmount = 0;
        agriculturalParcels.agriculturalParcels.forEach((parcel) => {
            totalAmount += Number(data[parcel.id]);
        })

        //create array with object
        agriculturalParcels.agriculturalParcels.forEach((parcel) => {
            let arrayContent = {};
            arrayContent.id = parcel.id;
            arrayContent.percentage = (Number(data[parcel.id]) / totalAmount) * 100;
            arrayAgriculturalParcelsId.push(arrayContent);
        })
        newBatch.agriculturalParcels = arrayAgriculturalParcelsId;
        newBatch.email = currentUser.email;
        newBatch.name = data.name;
        newBatch.historic = "Batch created in step harvest";
        newBatch.harvestYearId = findActiveHarvestYear();
        newBatch.createDate = serverTimestamp();
        return newBatch
    }

    const findActiveHarvestYear = () => {
        let today = Math.floor(new Date().getTime() / 1000);
        let currentHarvestYearId;
        for (let i = 0; i < harvestYear.length; i++) {
            if (
                harvestYear[i].startDate.seconds < today &&
                harvestYear[i].endDate.seconds > today
            ) {
                currentHarvestYearId = harvestYear[i].id;
            }
        }
        return currentHarvestYearId;
    }

    const generateBatchContentForm = () => {
        let form = [];

        agriculturalParcels.agriculturalParcels.forEach((parcel) => {
            form.push(
                <div className="fields"
                    style={{ justifyContent: "center", marginTop: "20px" }}>
                    <div className="twelve wide field" style={{ justifyContent: "center" }}>
                        <label>
                        {t("translation.harvestRegistration.juiceInLiters")}: {parcel.name}
                            <input
                                type="number"
                                {...register(parcel.id)}
                                required
                                min='0'
                                style={{ textAlign: "center" }} />
                        </label>
                    </div>
                </div>
            )
        })

        return form;
    }
    return (
        <div>
            <div
                className="add-form-container"
                style={{
                    maxWidth: 960,
                    margin: "50px auto",
                    marginTop: "70px",
                }}
            >
                <h1>{t("translation.harvestRegistration.addBatchForHarvest")}</h1>

                <GoBackButton />
                <div className="ui grid stackable">
                    <form
                        className={formClassname}
                        style={{
                            border: '3px solid #B3B3B3',
                            marginTop: '50px',
                            borderRadius: '50px',
                            background: '#edeaea',
                            borderColor: '#ac9e66',

                        }}
                        onSubmit={handleSubmit(onSubmit)}>


                        <div className="fields"
                            style={{ justifyContent: "center", marginTop: "20px" }}>
                            <div className="twelve wide field" style={{ justifyContent: "center" }}>
                                <label>
                                {t("translation.words.name")}
                                    <input
                                        type="text"
                                        {...register('name')}
                                        required
                                        style={{ textAlign: "center" }} />
                                </label>
                            </div>
                        </div>

                        {generateBatchContentForm()}
                        <button
                            type="submit"
                            className="ui submit large grey button right floated  button-vinyards"
                            style={{ float: "none", marginTop: "30px", width: "25%" }}
                        >
                             {t("translation.harvestRegistration.addWineStorage")}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}
export default AddBatchComponent;