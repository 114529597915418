import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import GoBackButton from "../../components/FormComponents/GoBackButton";
import { useEffect, useState } from "react";
import { useSessionStorage } from "../../logic/useSessionStorage";
import { useNavigate, useParams } from "react-router-dom";
import { useFirestore } from "../../context/FirestoreContext";
import { PageTitle } from "../../utils/PageTitle";
import Select from "react-select";
import { updateContentHarvestStep } from "../../firebase/customSteps";

const EditHarvestStep = () => {
    PageTitle("Edit Harvest Step");
    const [harvestSteps, setHarvestSteps] = useState(undefined);
    const [currentHarvestStep, setCurrentHarvestStep] = useState(undefined);
    const [error, setError] = useState("hidden");
    const { register, handleSubmit } = useForm();
    const { getHarvestSteps } = useFirestore();
    const params = useParams();
    const navigate = useNavigate()
    const [selectedType, setSelectedType] = useState([]);
    const [selectedValidation, setSelectedValidation] = useState([]);
    const [selectedValidation1, setSelectedValidation1] = useState([]);
    const [selectedValidation2, setSelectedValidation2] = useState([]);
    const [currentWinery, setCurrentWinery] = useSessionStorage(
        "currentwinery",
        ""
    );
    const typeOptions = [
        "geopoint",
        "multilinetext",
        "image",
        "label",
        "option",
        "decimal",
        "text",
        "timestamp",
        'section'
    ];

    const validationTypes = ["notEmpty", "null"];
    const validation1Types = ["greaterThen", "lesserThen", 'null'];
    const { t } = useTranslation();

    useEffect(() => {
        const getHarvestStepsFromDb = async () => {
            let currentStep
            await getHarvestSteps(currentWinery).then((steps) => {
                setHarvestSteps(steps)
                currentStep = steps.content.find((s) =>
                    s.order === Number(params.stepNumber)
                )
                setCurrentHarvestStep(currentStep)
            },
            )
        }
        if (harvestSteps === undefined) getHarvestStepsFromDb()

    }, [])

    const onSubmit = async (data) => {
        let newDoc = generateNewHarvestStep(data);
        try {
            await updateContentHarvestStep(currentWinery, newDoc).then(
                navigate("/")
            )
        } catch (error) {
            console.error(error);
        }

    }

    const generateNewHarvestStep = (data) => {
        let newDoc
        if (selectedType == "") {
            data.type = currentHarvestStep["type"];
        }
        if (selectedValidation1 == "") {
            data.validation1 = currentHarvestStep["validation1"];
        }
        if (selectedValidation2 == "") {
            data.validation2 = currentHarvestStep["validation2"];
        }
        if (selectedValidation == "") {
            data.validation = currentHarvestStep["validation"];
        }

        data["order"] = Number(data["order"]);

        if (data["validation"] === "") {
            data["validation"] = null;
        }
        if (data["validationValue"] === "") {
            data["validationValue"] = null;
        }
        if (data["validation1"] === "") {
            data["validation1"] = null;
        }
        if (data["validation1Value"] === "") {
            data["validation1Value"] = null;
        } else {
            data["validation1Value"] = Number(data["validation1Value"]);
        }
        if (data["validation2"] === "") {
            data["validation2"] = null;
        }
        if (data["validation2Value"] === "") {
            data["validation2Value"] = null;
        } else {
            data['validation2Value'] = Number(data["validation2Value"]);
        }
        newDoc = { ...harvestSteps };
        const index = harvestSteps.content
            .map((x) => x.key)
            .indexOf(data["key"]);
        newDoc.content[index] = data;
        return newDoc;

    }

    function generateUserForm() {
        let form = [];
        if (currentHarvestStep !== undefined) {
            const sortedContent = Object.keys(currentHarvestStep)
                .sort()
                .reduce((accumulator, key) => {
                    accumulator[key] = currentHarvestStep[key];
                    return accumulator;
                }, {});
                let counter = 0;
            for (const [key, value] of Object.entries(sortedContent)) {
                if (key === "key") {
                    form.push(
                        <div
                            key={counter++}
                            className="fields"
                            style={{ justifyContent: "center", marginTop: "20px" }}
                        >
                            <div className="twelve wide field">
                                <label>
                                    {t(`translation.vineyardSteps.${key}`)}
                                    <input
                                        type="text"
                                        {...register(key)}
                                        htmlFor={key}
                                        defaultValue={value}
                                        style={{ textAlign: "center" }}
                                        readOnly />
                                </label>
                            </div>
                        </div>
                    );
                } else if (key === "order") {
                    form.push(
                        <div
                            className="fields"
                            style={{ justifyContent: "center", marginTop: "20px" }}
                        >
                            <div className="twelve wide field">
                                <label>
                                    {t(`translation.vineyardSteps.${key}`)}
                                    <input
                                        type="number"
                                        {...register(key)}
                                        htmlFor={key}
                                        required="required"
                                        defaultValue={value}
                                        style={{ textAlign: "center" }} />
                                </label>
                            </div>
                        </div>
                    );
                } else if (key === "type") {
                    form.push(
                        <div
                            className="fields"
                            style={{ justifyContent: "center", marginTop: "20px" }}
                        >
                            <div className="twelve wide field">
                                <label>
                                    {t(`translation.vineyardSteps.${key}`)}

                                    <Select
                                        label={key}
                                        htmlFor={key}
                                        onChange={(selection, action) =>
                                            handleTypeChange(selection, action)
                                        }
                                        options={typeOptions.map((value) => ({
                                            label: value,
                                            value: value,
                                        }))}
                                        defaultValue={{
                                            label: value,
                                            value: value,
                                        }}
                                    ></Select>
                                </label>
                                <div style={{ display: "none" }}>
                                    <input
                                        {...register(key)}
                                        htmlFor={key}
                                        value={selectedType}
                                        defaultValue={selectedType}
                                        readOnly />
                                </div>
                            </div>
                        </div>
                    );
                } else if (key === "validation1") {
                    form.push(
                        <div
                            className="fields"
                            style={{ justifyContent: "center", marginTop: "20px" }}
                        >
                            <div className="twelve wide field">
                                <label style={{ justifyContent: "center" }}>
                                    {t(`translation.vineyardSteps.${key}`)}

                                    <Select
                                        label={key}
                                        htmlFor={key}
                                        onChange={(selection, action) =>
                                            handleValidation1Change(selection, action)
                                        }
                                        options={validation1Types.map((value) => ({
                                            label: value,
                                            value: value,
                                        }))}
                                        defaultValue={{
                                            label: value,
                                            value: value,
                                        }}
                                    />
                                </label>
                                <div style={{ display: "none" }}>
                                    <input
                                        {...register(key)}
                                        htmlFor={key}
                                        value={selectedValidation1}
                                        defaultValue={selectedValidation1}
                                        readOnly />
                                </div>
                            </div>
                        </div>
                    );
                } else if (key === "validation2") {
                    form.push(
                        <div
                            className="fields"
                            style={{ justifyContent: "center", marginTop: "20px" }}
                        >
                            <div className="twelve wide field">
                                <label>
                                    {t(`translation.vineyardSteps.${key}`)}

                                    <Select
                                        label={key}
                                        htmlFor={key}
                                        onChange={(selection, action) =>
                                            handleValidation2Change(selection, action)
                                        }
                                        options={validation1Types.map((value) => ({
                                            label: value,
                                            value: value,
                                        }))}
                                        defaultValue={{
                                            label: value,
                                            value: value,
                                        }}
                                    />
                                </label>
                                <div style={{ display: "none" }}>
                                    <input
                                        {...register(key)}
                                        htmlFor={key}
                                        value={selectedValidation2}
                                        defaultValue={selectedValidation2}
                                        readOnly />
                                </div>
                            </div>
                        </div>
                    );
                } else if (key === "validation") {
                    form.push(
                        <div className='fields'
                            style={{ justifyContent: "center", marginTop: "20px" }}
                        >
                            <div className='twelve wide field'>
                                <label style={{ justifyContent: "center" }}>
                                    {t(`translation.vineyardSteps.${key}`)}

                                    <Select
                                        label={key}
                                        htmlFor={key}
                                        onChange={(selection, action) => {
                                            handleValidationChange(selection, action);
                                        }}
                                        options={validationTypes.map((value) => ({
                                            label: value,
                                            value: value
                                        }))}
                                        defaultValue={{
                                            label: currentHarvestStep.validation,
                                            value: currentHarvestStep.validation
                                        }}
                                    />
                                </label>
                                <div style={{ display: "none" }}>
                                    <input
                                        {...register(key)}
                                        htmlFor={key}
                                        value={selectedValidation}
                                        defaultValue={selectedValidation}
                                        readOnly />
                                </div>
                            </div>
                        </div>
                    )
                } else {
                    form.push(
                        <div
                            className="fields"
                            style={{ justifyContent: "center", marginTop: "20px" }}
                        >
                            <div className="twelve wide field">
                                <label>
                                    {t(`translation.vineyardSteps.${key}`)}
                                    <input
                                        type="text"
                                        {...register(key)}
                                        htmlFor={key}
                                        defaultValue={value}
                                        style={{ textAlign: "center" }} />
                                </label>
                            </div>
                        </div>
                    );
                }
            }
        }
        return form;
    }

    const handleTypeChange = (selection, action) => {
        setSelectedType(selection.value);
    };

    const handleValidation1Change = (selection, action) => {
        setSelectedValidation1(selection.value);
    };

    const handleValidation2Change = (selection, action) => {
        setSelectedValidation2(selection.value);
    };

    const handleValidationChange = (selection, action) => {
        setSelectedValidation(selection.value);
    }


    return (
        <div>
            <div
                className="add-form-container"
                style={{
                    maxWidth: 960,
                    margin: "50px auto",
                }}
            >
                <h1 style={{ color: "#7f7f7f" }}>
                    {t('translation.myHarvestSteps.editHarvestStep')}
                </h1>
                <GoBackButton />
                <div
                    className="ui grid stackable"
                    style={{ justifyContent: "center", marginTop: "-90px" }}
                >
                    <form
                        className="ui big form twelve wide column" // Replace with your actual form classname
                        style={{
                            border: "3px solid #B3B3B3",
                            marginTop: "100px",
                            borderRadius: "50px",
                            background: "#edeaea",
                            borderColor: "#ac9e66",
                        }}
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        {generateUserForm()}
                        <p style={{ visibility: error }} className="text-danger">
                            {t('translation.vineyardSteps.stepTypeNotSelected')}
                        </p>
                        <button
                            type="submit"
                            className="ui submit large grey button right floated button-vinyards"
                            style={{ float: "none", width: "25%" }}
                        >
                            {t('translation.myHarvestSteps.editHarvestStep')}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};
export default EditHarvestStep;