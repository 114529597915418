import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { updateCellar } from '../firebase/Cellar';
import { firestore } from '../firebase/config';
import { PageTitle } from '../utils/PageTitle';
import { useSessionStorage } from '../logic/useSessionStorage';
import GoBackButton from "../components/FormComponents/GoBackButton";
import { onSnapshot, doc, collection } from 'firebase/firestore';
import MaterialTable from '@material-table/core';


const MyCellars = () => {
    PageTitle("Cellars");
    const navigate = useNavigate();
    const [cellars, setCellars] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showError, setShowError] = useState('hidden');
    const [currentWinery, setCurrentWinery] = useSessionStorage('currentwinery', '');
    const { t } = useTranslation();

    // Referenties naar firestore database (bv : ophalen van collecties uit firestore db)
    // Alle waarden in de variabelen gaan verloren éénmaal de useEffect volledig uitgevoerd is

    useEffect(() => {
        const cellarsRef = collection(doc(firestore, 'winegrowers', currentWinery), 'cellars');

        const unsubscribe = onSnapshot(cellarsRef, (querySnapshot) => {
            const cellars = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setCellars(cellars);
        });

        return () => { unsubscribe() };
    }, [currentWinery]);

    const onSubmit = async (newData) => {
        try {
            setLoading(true);
            if (newData.name === '' || newData.adress === '' || newData.city === '') {
                setShowError('show');
            } else {
                setShowError('hidden');
                await updateCellar(newData);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    // Het weergeven van alle gegevens van alle wijnkelders in een tabel

    return (
        <div style={{ position: 'relative', marginTop: '90px' }}>
            <h1 style={{ marginTop: '100px', color: '#7f7f7f' }}>
                {t('vertaling.h8_dashboard')}
            </h1>
            <GoBackButton />
            <br />
            <h4>{t('translation.cellars.myCellarsExplanation')}</h4>
            <div style={{ margin: 10 }}>
                <p className={showError + ' flex_error_red'}>
                    {t("vertaling.error_required")}
                </p>
            </div>
            <div className="newbatch" style={{ marginTop: '60px', marginLeft: '11%' }}>
                <Link to="/mycellars/createCellar" style={{ color: 'white' }}>
                    <button className="ui primary button login">
                        {t("vertaling.add_cellar")}
                    </button>
                </Link>
            </div>
            <div className="batchesList cellarlist">
                <MaterialTable
                    title=""
                    data={cellars}
                    columns={[
                        {
                            title: t('vertaling.name'),
                            field: 'name',
                        },
                        {
                            title: t('vertaling.adress'),
                            field: 'adress',
                        },
                        {
                            title: t('vertaling.city'),
                            field: 'city',
                        },
                    ].map((c) => ({
                        render: (row) => (
                            <Link to={`/mycellars/barrels/${row.id}`}>
                                {c.lookup ? c.lookup[row[c.field]] : row[c.field]}
                            </Link>
                        ),
                        ...c,
                    }))}
                    options={{
                        search: true,
                        filtering: true,
                        exportButton: true,
                        exportAllData: true,
                        headerStyle: {
                            backgroundColor: '#cecaca',
                            color: '#FFF',
                        },
                        emptyRowsWhenPaging: false,
                        paging: true,
                        pageSize: 50,
                        pageSizeOptions: [
                            10,
                            20,
                            25,
                            50,
                        ],
                    }}
                    // editable={{
                    //     onRowUpdate: (newData, oldData) =>
                    //         new Promise((resolve, reject) => {
                    //             onSubmit(newData);
                    //             resolve();
                    //         }),
                    // }}
                    // Dit wordt gebruikt om alles te vertalen in de tabel die hierboven wordt gedefineerd
                    localization={{
                        body: {
                            emptyDataSourceMessage: t('vertaling.emptyDataSourceMessage'),
                            addTooltip: t('vertaling.addTooltip'),
                            deleteTooltip: t('vertaling.deleteTooltip'),
                            editTooltip: t('vertaling.editTooltip'),
                            filterRow: {
                                filterTooltip: t('vertaling.filterTooltip'),
                            },
                            editRow: {
                                deleteText: t('vertaling.deleteText'),
                                cancelTooltip: t('vertaling.cancelTooltip'),
                                saveTooltip: t('vertaling.submit'),
                            },
                        },
                        grouping: {
                            placeholder: t('vertaling.placeholder'),
                            groupedBy: t('vertaling.groupedBy'),
                        },
                        header: {
                            actions: t('vertaling.actions'),
                        },
                        pagination: {
                            labelDisplayedRows: t('vertaling.labelDisplayedRows'),
                            labelRowsPerPage: t('vertaling.labelRowsPerPage'),
                            firstAriaLabel: t('vertaling.firstAriaLabel'),
                            firstTooltip: t('vertaling.firstAriaLabel'),
                            previousAriaLabel: t('vertaling.previousAriaLabel'),
                            previousTooltip: t('vertaling.previousAriaLabel'),
                            nextAriaLabel: t('vertaling.nextAriaLabel'),
                            nextTooltip: t('vertaling.nextAriaLabel'),
                            lastAriaLabel: t('vertaling.lastAriaLabel'),
                            lastTooltip: t('vertaling.lastAriaLabel'),
                        },
                        toolbar: {
                            addRemoveColumns: t('vertaling.addRemoveColumns'),
                            nRowsSelected: t('vertaling.nRowsSelected'),
                            showColumnsTitle: t('vertaling.showColumnsTitle'),
                            showColumnsAriaLabel: t('vertaling.showColumnsTitle'),
                            exportTitle: t('vertaling.exportTitle'),
                            exportAriaLabel: t('vertaling.exportTitle'),
                            exportName: t('vertaling.exportName'),
                            searchTooltip: t('vertaling.searchTooltip'),
                            searchPlaceholder: t('vertaling.searchTooltip'),
                            exportCSVName: t('vertaling.exportName'),
                            exportPDFName: t('vertaling.exportPDFName'),
                        },
                    }}
                />
            </div>

            <br />
        </div>
    );
};

export default MyCellars;