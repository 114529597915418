import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { useAuth } from '../../context/AuthContext';
import { createBatchFromDrying } from '../../firebase/batch';
import { firestore } from '../../firebase/config';
import { collection, doc, query, where, orderBy, onSnapshot } from 'firebase/firestore'; // Firebase v9
import { createRegistration } from '../../firebase/registrations';
import { PageTitle } from '../../utils/PageTitle';
import SplitBatch from './CreateSplitBatches';
import GoBackButton from "../../components/FormComponents/GoBackButton";

const CreateCellarRegistration = (props) => {
    PageTitle("Create Cellar Registration");
    const navigate = useNavigate();
    const { currentUser } = useAuth();
    const [userData, setUserData] = useState('');
    const selectedStep = props.location.state.selectStep;
    const selectedStepName = props.location.state.selectStepName;
    const selectedCellar = props.location.state.selectCellar;
    const selectedCellarName = props.location.state.selectCellarName;
    const [contentKeys, setContentKeys] = useState('');
    const [batches, setBatches] = useState('');
    const [cellarSteps, setCellarSteps] = useState('');
    const [cellarStepContent, setCellarStepContent] = useState('');
    const [barrels, setBarrels] = useState('');
    const [bottleTypes, setBottleTypes] = useState('');
    const [winegrowerId, setWinegrowerId] = useState('');
    const [isBottling, setIsBottling] = useState(false);
    const [isMerge, setIsMerge] = useState(false);
    const [isSplit, setIsSplit] = useState(false);
    const [isTransfer, setIsTransfer] = useState(false);
    const [isFermentation, setIsFermentation] = useState(false);
    const [hasBatchId, setHasBatchId] = useState(false);
    const [needsSourceBatch, setNeedsSourceBatch] = useState(true);
    const [hasWintestorageId, setHasWinestorageId] = useState(false);
    const [hasCellarStepId, setHasCellarStepId] = useState(false);
    const [selectedWinestorage, setSelectedWinestorage] = useState('');
    const [selectedWinestorageTransfer, setSelectedWinestorageTransfer] =
        useState('');
    const [selectedFermentationType, setSelectedFermentationType] = useState('');
    const [selectedBottleType, setSelectedBottleType] = useState('');
    const [selectedCellarStepId, setSelectedCellarStepId] = useState('');
    const [selectedSourceBatch, setSelectedSourceBatch] = useState('');
    const [selectedSourceBatches, setSelectedSourceBatches] = useState('');
    const [totalHectoliters, setTotalHectoliters] = useState(0);
    const [timesToSplit, setTimesToSplit] = useState(0);
    const { register, handleSubmit } = useForm();
    const [isLoading, setLoading] = useState(false);
    var batchesToCreate = [];
    var IdList = [];
    const { t } = useTranslation();

    useEffect(() => {
        // Referenties naar firestore database (bv : ophalen van collecties uit firestore db)
        // Alle waarden in de variabelen gaan verloren éénmaal de useEffect volledig uitgevoerd is

        const usersRef = query(
            collection(firestore, 'users'),
            where('email', '==', currentUser.email)
        );

        const unsubscribe = onSnapshot(usersRef, (querySnapshot) => {
            // Huidige user opvragen

            const userLoggedIn = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setWinegrowerId(userLoggedIn[0].winegrower);
            setUserData(userLoggedIn[0])

        });

        // Collectie kelderstappen opvragen en daaruit de juiste inhoud opvragen

        const cellarStepContentRef = query(
            collection(firestore, 'cellarsteps', selectedStep, 'content'),
            orderBy('order')
        );

        const unsubscribeCellarStepContent = onSnapshot(cellarStepContentRef, (querySnapshot) => {
            const cellarStepContent = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setCellarStepContent(cellarStepContent);
        });
        // Collectie kelderstappen opvragen
        const cellarStepsRef = collection(firestore, 'cellarsteps');

        const unsubscribeCellarStepList = onSnapshot(cellarStepsRef, (querySnapshot) => {
            const cellarstepsList = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));

            setCellarSteps(cellarstepsList);
        });

        // Collectie winegrowers opvragen en daaruit de collectie batches opvragen op basis van ingelogde gebruiker

        const batchesRef = collection(firestore, 'winegrowers', userData.winegrower, 'batches');

        const unsubscribeBatches = onSnapshot(batchesRef, (querySnapshot) => {
            const batchesList = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));

            setBatches(batchesList);

        });

        // Collectie winegrowers opvragen en daaruit de collectie winestorages opvragen op basis van ingelogde gebruiker

        const barrelRef = collection(firestore, 'winegrowers', userData.winegrower, 'wineStorages');

        const unsubscribeBarrels = onSnapshot(barrelRef, (querySnapshot) => {
            const barrelList = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));

            setBarrels(barrelList);
        });
        // Collectie bottletypes opvragen

        const bottleRef = query(
            collection(firestore, 'bottletypes'),
            orderBy('size')
        );

        const unsubscribeBottles = onSnapshot(bottleRef, (querySnapshot) => {
            const bottleList = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));

            setBottleTypes(bottleList);
        });

        // Controleren of de geselecteerde kelderstap = product addition

        if (selectedStep === 'WUSVJvG1GNTrR41JzZHL') {
            setNeedsSourceBatch(false);
        }

        // Controleren of de geselecteerde kelderstap = assemble

        if (selectedStep === '9WlTkw2LafhNgoErp2J7') {
            setIsMerge(true);
        }

        // Controleren of de geselecteerde kelderstap = split

        if (selectedStep === 'BuwrEmxyGAdMWG3Fq0bs') {
            setIsSplit(true);
        }
        if (selectedStep === 'X5TP3l5bwVYDKKgVfjWa') {
            setIsTransfer(true);
        }

        var contentKeysArray = [];

        // Overlopen van alle inhoud van de variabele cellarStepContent

        for (let i = 0; i < cellarStepContent.length; i++) {
            if (
                // Controleren van de waarde van de key

                cellarStepContent[i].key !== null &&
                cellarStepContent[i].key !== undefined &&
                cellarStepContent[i].key !== 'img'
            ) {
                if (cellarStepContent[i].key === 'batchId') {
                    setHasBatchId(true);
                }

                if (cellarStepContent[i].key === 'typeOfFermentation') {
                    setIsFermentation(true);
                }

                if (cellarStepContent[i].key === 'bottleTypeId') {
                    setIsBottling(true);
                }

                if (cellarStepContent[i].key === 'winestorageId') {
                    setHasWinestorageId(true);
                }

                if (cellarStepContent[i].key === 'cellarStepId') {
                    setHasCellarStepId(true);
                }

                contentKeysArray.push(cellarStepContent[i]);
            }
        }
        setContentKeys(contentKeysArray);
        return () => {
            unsubscribe();
            unsubscribeCellarStepContent();
            unsubscribeCellarStepList();
            unsubscribeBatches();
            unsubscribeBarrels();
            unsubscribeBottles();
        };
    }, []);


    // Event die uitgevoerd wordt bij het wijzigen van een wijnvat
    const changeWinestorage = (selection) => {
        setSelectedWinestorage(selection.value);
    };

    const changeWinestorageTransfer = (selection) => {
        setSelectedWinestorageTransfer(selection.value);
    };

    const changeFermentationType = (selection) => {
        setSelectedFermentationType(selection.value);
    };

    // Event die uitgevoerd wordt bij het wijzigen van het flestype
    const changeBottleType = (selection) => {
        setSelectedBottleType(selection.value);
    };

    // Event die uitgevoerd wordt bij het wijzigen van de oorspronkelijke batch
    const changeSourceBatch = (selection) => {
        setSelectedSourceBatch(selection.value);
    };

    const changeSourceBatches = (selection) => {
        var selectedBatches = [];
        var totalHectoliters = 0;
        for (let i = 0; i < batches.length; i++) {
            for (let j = 0; j < selection.length; j++) {
                if (selection[j].value === batches[i].id) {
                    selectedBatches.push(batches[i].id);
                    totalHectoliters += batches[i].hectoliters;
                }
            }
        }
        setSelectedSourceBatches(selectedBatches);
        setTotalHectoliters('Total hectoliters: ' + totalHectoliters);
    };

    const changeCellarStepId = (selection) => {
        setSelectedCellarStepId(selection.value);
    };

    const onSubmit = async (data) => {
        setLoading(true);
        var batchId = '';
        // aanmaken en instellen van batch als aan deze voorwaarde wordt voldaan

        if (data.newBatch !== undefined && data.newBatch !== '') {
            var newBatch = new Object();
            newBatch.batch = data.newBatch;
            delete data.newBatch;
            newBatch.createdate = new Date();
            newBatch.hectoliters = 0;
            if (totalHectoliters !== 0) {
                newBatch.hectoliters = totalHectoliters;
            }
            newBatch.available = true;
            newBatch.email = userData.email;
            newBatch.historic = 'Batch created in step ' + selectedStepName;

            try {
                batchId = (await createBatchFromDrying(winegrowerId, newBatch)).id;
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        }

        if (isSplit && batchesToCreate.length !== 0) {
            for (let i = 0; i < batchesToCreate.length; i++) {
                var newBatch = new Object();
                newBatch.batch = batchesToCreate[i].batchName;
                newBatch.createdate = new Date();
                newBatch.hectoliters = Number(batchesToCreate[i].batchHectoliters);
                newBatch.available = true;
                newBatch.email = userData.email;
                newBatch.historic = 'Batch created in step Split';

                try {
                    batchId = (await createBatchFromDrying(winegrowerId, newBatch)).id;
                    IdList.push(batchId);
                } catch (error) {
                    console.error(error);
                } finally {
                    setLoading(false);
                }
            }
        }

        // verder instellen van resterende velden batch
        delete data.newBatch;
        data.waitingForReview = false;
        if (userData.trustedForRegistration === false) {
            data.waitingForReview = true;
        }
        data.locationId = selectedCellar;
        data.date = new Date();
        data.visualisation = true;
        data.type = 'cellarStep';
        data.stepId = selectedStep;
        data.email = userData.email;
        data.batchId = "";
        if (selectedWinestorage !== "") {
            data.sourceWinestorageId = selectedWinestorage;
        }
        if (selectedWinestorageTransfer !== '') {
            data.winestorageId = selectedWinestorageTransfer;
        }
        if (selectedSourceBatch !== '') {
            data.sourceBatchId = selectedSourceBatch;
        }
        if (selectedSourceBatches !== '') {
            data.sourceBatchIds = selectedSourceBatches;
        }
        if (selectedCellarStepId !== '') {
            data.cellarStepId = selectedCellarStepId;
        }
        if (selectedBottleType !== '') {
            data.bottleTypeId = selectedBottleType;
        }
        if (batchId !== '' && !isSplit) {
            data.batchId = batchId;
        }
        if (IdList.length > 0) {
            data.batchIds = IdList;
        }
        if (selectedFermentationType !== '') {
            data.typeOfFermentation = selectedFermentationType;
        }
        if (data.temperature !== undefined) {
            data.temperature = Number(data.temperature);
        }
        if (data.density !== undefined) {
            data.density = Number(data.density);
        }
        if (data.massDensity !== undefined) {
            data.massDensity = Number(data.massDensity);
        }
        if (data.amount !== undefined) {
            data.amount = Number(data.amount);
        }
        if (data.amountOfBottles !== undefined) {
            data.amountOfBottles = Number(data.amountOfBottles);
        }
        if (data.amountUsed !== undefined) {
            data.amountUsed = Number(data.amountUsed);
        }
        if (data.refillAmount !== undefined) {
            data.refillAmount = Number(data.refillAmount);
        }
        if (data.amountOtherAging !== undefined) {
            data.amountOtherAging = Number(data.amountOtherAging);
        }

        try {
            const usersQuery = query(
                collection(firestore, 'users'),
                where('email', '==', userData.email)
            );

            onSnapshot(usersQuery, (querySnapshot) => {
                const userLoggedIn = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                data.createdBy = userLoggedIn[0].name;

                createRegistration(winegrowerId, data);
            });

        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
            navigate('/Addregistration');
        }
    };

    // Aanmaken en instellen van de waarden van de slider
    const marks = [
        {
            value: 2,
            label: '2',
        },
        {
            value: 3,
            label: '3',
        },
        {
            value: 4,
            label: '4',
        },
        {
            value: 5,
            label: '5',
        },
        {
            value: 6,
            label: '6',
        },
        {
            value: 7,
            label: '7',
        },
        {
            value: 8,
            label: '8',
        },
        {
            value: 9,
            label: '9',
        },
        {
            value: 10,
            label: '10',
        },
    ];

    // Instellen van alle waarden van de slider
    function valueLabelFormat(value) {
        return marks.findIndex((mark) => mark.value === value) + 1;
    }

    const parentFunction = (data_from_child) => {
        batchesToCreate.push(data_from_child);
    };

    const parentFunction2 = (data) => {
        // get index of object with id:37
        var removeIndex = batchesToCreate
            .map(function (item) {
                return item.batchName;
            })
            .indexOf(data.batchName);

        // remove object
        batchesToCreate.splice(removeIndex, 1);
    };

    return (
        <div style={{ marginTop: '90px' }}>
            <h1>
                {t('vertaling.create_new')} {selectedStepName}{' '}
                {t('vertaling.regisforcellar')} {selectedCellarName}
            </h1>
            <GoBackButton />
            <br />
            <br />
            <br />
            <div>
                <div>
                    {contentKeys.length >= 1 && (
                        <div>
                            <form
                                onSubmit={handleSubmit(onSubmit)}
                                className="ui big form twelve wide column"
                                style={{ fontSize: '1rem' }}
                            >
                                {contentKeys.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            style={{
                                                width: '80%',
                                                margin: 'auto',
                                            }}
                                        >
                                            {item.key === 'description' ? (
                                                <div
                                                    className="fields"
                                                    style={{ justifyContent: 'center' }}
                                                >
                                                    <div className="eight wide field">
                                                        <label>
                                                            {t('vertaling.beschrijving')}
                                                            <input type="text" {...register(item.key)} />
                                                        </label>
                                                    </div>
                                                </div>
                                            ) : (
                                                ''
                                            )}

                                            {item.key === 'amount' ? (
                                                <div
                                                    className="fields"
                                                    style={{ justifyContent: 'center' }}
                                                >
                                                    <div className="eight wide field">
                                                        <label>
                                                            {t('vertaling.amount_report')}
                                                            <input type="number" min="0" step="0.1" {...register(item.key)} />
                                                        </label>
                                                    </div>
                                                </div>
                                            ) : (
                                                ''
                                            )}

                                            {item.key === 'productsUsed' ? (
                                                <div
                                                    className="fields"
                                                    style={{ justifyContent: 'center' }}
                                                >
                                                    <div className="eight wide field">
                                                        <label>
                                                            {t('vertaling.productUsed_report')}
                                                            <input type="text" {...register(item.key)} />
                                                        </label>
                                                    </div>
                                                </div>
                                            ) : (
                                                ''
                                            )}

                                            {item.key === 'temperature' ? (
                                                <div
                                                    className="fields"
                                                    style={{ justifyContent: 'center' }}
                                                >
                                                    <div className="eight wide field">
                                                        <label>
                                                            {t('vertaling.temperature_report')}
                                                            <input type="number" min="0" step="0.1" {...register(item.key)} />
                                                        </label>
                                                    </div>
                                                </div>
                                            ) : (
                                                ''
                                            )}

                                            {item.key === 'density' ? (
                                                <div
                                                    className="fields"
                                                    style={{ justifyContent: 'center' }}
                                                >
                                                    <div className="eight wide field">
                                                        <label>
                                                            {t('vertaling.density_report')}
                                                            <input type="number" min="0" step="0.1" {...register(item.key)} />
                                                        </label>
                                                    </div>
                                                </div>
                                            ) : (
                                                ''
                                            )}

                                            {item.key === 'method' ? (
                                                <div
                                                    className="fields"
                                                    style={{ justifyContent: 'center' }}
                                                >
                                                    <div className="eight wide field">
                                                        <label>
                                                            {/* {item.text} */}
                                                            {t('vertaling.method_used')}
                                                            <input type="number" min="0" step="0.1" {...register(item.key)} />
                                                        </label>
                                                    </div>
                                                </div>
                                            ) : (
                                                ''
                                            )}

                                            {item.key === 'amountOfBottles' ? (
                                                <div
                                                    className="fields"
                                                    style={{ justifyContent: 'center' }}
                                                >
                                                    <div className="eight wide field">
                                                        <label>
                                                            {t('vertaling.amountOfBottles_report')}
                                                            <input type="number" min="0" step="1" {...register(item.key)} />
                                                        </label>
                                                    </div>
                                                </div>
                                            ) : (
                                                ''
                                            )}

                                            {item.key === 'amountUsed' ? (
                                                <div
                                                    className="fields"
                                                    style={{ justifyContent: 'center' }}
                                                >
                                                    <div className="eight wide field">
                                                        <label>
                                                            {t('vertaling.amount_used')}
                                                            <input type="number" min="0" step="0.1" {...register(item.key)} />
                                                        </label>
                                                    </div>
                                                </div>
                                            ) : (
                                                ''
                                            )}

                                            {item.key === 'refillAmount' ? (
                                                <div
                                                    className="fields"
                                                    style={{ justifyContent: 'center' }}
                                                >
                                                    <div className="eight wide field">
                                                        <label>
                                                            {/* {item.text} */}
                                                            {t('vertaling.refill_amount')}
                                                            <input type="number" min="0" step="0.1" {...register(item.key)} />
                                                        </label>
                                                    </div>
                                                </div>
                                            ) : (
                                                ''
                                            )}

                                            {item.key === 'amountOtherAging' ? (
                                                <div
                                                    className="fields"
                                                    style={{ justifyContent: 'center' }}
                                                >
                                                    <div className="eight wide field">
                                                        <label>
                                                            {/* {item.text} */}
                                                            {t('vertaling.amount_report')}
                                                            <input type="number" min="0" step="0.1" {...register(item.key)} />
                                                        </label>
                                                    </div>
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    );
                                })}

                                <div style={{ width: '80%', margin: 'auto' }}>
                                    {isFermentation ? (
                                        <div>
                                            <label className="eight wide field">
                                                <b>
                                                    {' '}
                                                    {t('vertaling.fermentationType_report')}
                                                </b>
                                                <Select
                                                    placeholder={t('vertaling.type_of_fermentation')}
                                                    options={[
                                                        {
                                                            value: 'On barrel',
                                                            label:
                                                                t('vertaling.onBarrel'),
                                                        },
                                                        {
                                                            value: 'On bottle',
                                                            label:
                                                                t('vertaling.onBottle'),
                                                        },
                                                        {
                                                            value: 'Other',
                                                            label:
                                                                t('vertaling.manually'),
                                                        },
                                                    ]}
                                                    onChange={(selection) =>
                                                        changeFermentationType(selection)
                                                    }
                                                    className="specialty"
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 5,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#dacfa4b2',
                                                            primary: '#AC9E66',
                                                        },
                                                    })}
                                                />
                                            </label>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>

                                <div style={{ width: '80%', margin: 'auto' }}>
                                    {hasCellarStepId ? (
                                        <label className="eight wide field">
                                            <b>
                                                {t('vertaling.select_cellarstep')}
                                            </b>
                                            <Select
                                                required
                                                placeholder=""
                                                options={cellarSteps.map((name, id) => ({
                                                    value: name.id,
                                                    label: name.name,
                                                }))}
                                                onChange={(selection) => changeCellarStepId(selection)}
                                                className="specialty"
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 5,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: '#dacfa4b2',
                                                        primary: '#AC9E66',
                                                    },
                                                })}
                                            />
                                        </label>
                                    ) : (
                                        ''
                                    )}
                                </div>

                                <div style={{ width: '80%', margin: 'auto' }}>
                                    {needsSourceBatch && !isMerge ? (
                                        <label className="eight wide field">
                                            <b>
                                                {t('vertaling.select_sourcebatch')}
                                            </b>
                                            <Select
                                                required
                                                placeholder=""
                                                options={batches.map((name, id) => ({
                                                    value: name.id,
                                                    label: name.batch,
                                                }))}
                                                onChange={(selection) => changeSourceBatch(selection)}
                                                className="specialty"
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 5,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: '#dacfa4b2',
                                                        primary: '#AC9E66',
                                                    },
                                                })}
                                            />
                                        </label>
                                    ) : (
                                        ''
                                    )}
                                </div>

                                <div style={{ width: '80%', margin: 'auto' }}>
                                    {isMerge ? (
                                        <div>
                                            <label className="eight wide field">
                                                <b>
                                                    {t('vertaling.select_sourcebatch')}
                                                </b>
                                                <Select
                                                    isMulti
                                                    isClearable
                                                    required
                                                    placeholder=""
                                                    options={batches.map((name, id) => ({
                                                        value: name.id,
                                                        label: name.batch,
                                                    }))}
                                                    onChange={(selection) =>
                                                        changeSourceBatches(selection)
                                                    }
                                                    className="specialty"
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 5,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#dacfa4b2',
                                                            primary: '#AC9E66',
                                                        },
                                                    })}
                                                />
                                            </label>
                                            <div>
                                                <b>
                                                    {' '}
                                                    {t('vertaling.title_createBatch')}
                                                </b>
                                                <div
                                                    className="fields"
                                                    style={{ justifyContent: 'center' }}
                                                >
                                                    <div className="eight wide field">
                                                        <input
                                                            required
                                                            type="text"
                                                            {...register('newBatch')}
                                                            placeholder={t('vertaling.create_batch')} />
                                                    </div>
                                                </div>
                                            </div>
                                            <label className="eight wide field">
                                                <b>{t('vertaling.select_tank')}</b>
                                                <Select
                                                    required
                                                    placeholder=""
                                                    options={barrels.map((name, id) => ({
                                                        value: name.id,
                                                        label: name.name,
                                                    }))}
                                                    onChange={(selection) => changeWinestorage(selection)}
                                                    className="specialty"
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 5,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#dacfa4b2',
                                                            primary: '#AC9E66',
                                                        },
                                                    })}
                                                />
                                            </label>
                                            <div
                                                className="fields"
                                                style={{ justifyContent: 'center' }}
                                            >
                                                <div className="eight wide field">
                                                    <input
                                                        disabled
                                                        readOnly
                                                        type="text"
                                                        value={totalHectoliters}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>

                                <div style={{ width: '80%', margin: 'auto' }}>
                                    {isTransfer ? (
                                        <div>
                                            <label className="eight wide field">
                                                <b>{t('vertaling.source_tank')}</b>
                                                <Select
                                                    required
                                                    placeholder=""
                                                    options={barrels.map((name, id) => ({
                                                        value: name.id,
                                                        label: name.name,
                                                    }))}
                                                    onChange={(selection) => changeWinestorage(selection)}
                                                    className="specialty"
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 5,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#dacfa4b2',
                                                            primary: '#AC9E66',
                                                        },
                                                    })}
                                                />
                                            </label>
                                            <div>
                                                <b>
                                                    {t('vertaling.amount_hectoliters')}                        </b>
                                                <div
                                                    className="fields"
                                                    style={{ justifyContent: 'center' }}
                                                >
                                                    <div className="eight wide field">
                                                        <input required type="number" min="0" step="0.1" {...register('amount')} />
                                                    </div>
                                                </div>
                                            </div>
                                            <label className="eight wide field">
                                                <b>
                                                    {t('vertaling.destination_batch')}
                                                </b>
                                                <Select
                                                    required
                                                    placeholder=""
                                                    options={barrels.map((name, id) => ({
                                                        value: name.id,
                                                        label: name.name,
                                                    }))}
                                                    onChange={(selection) =>
                                                        changeWinestorageTransfer(selection)
                                                    }
                                                    className="specialty"
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 5,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#dacfa4b2',
                                                            primary: '#AC9E66',
                                                        },
                                                    })}
                                                />
                                            </label>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>

                                <div style={{ width: '80%', margin: 'auto' }}>
                                    {isSplit ? (
                                        <div>
                                            <div className="eight wide field" style={{ margin: '0 auto' }}>
                                                <br />
                                                <b>{t('vertaling.select_countbatch')}</b>
                                                <br />
                                                <br />
                                                <input
                                                    type="range"
                                                    min="2"
                                                    max="10"
                                                    step="1"
                                                    defaultValue="2"
                                                    onChange={(event) => setTimesToSplit(parseInt(event.target.value, 10))}
                                                />
                                                <br />
                                                Value: {timesToSplit}
                                            </div>
                                            <br />
                                            {timesToSplit > 0
                                                ? Array.from(Array(timesToSplit), (e, i) => {
                                                    return (
                                                        <div key={i}>
                                                            <SplitBatch
                                                                functionCallFromParent={parentFunction.bind(this)}
                                                                functionCallFromParent2={parentFunction2.bind(this)}
                                                            />
                                                        </div>
                                                    );
                                                })
                                                : ''}
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>

                                <div style={{ width: '80%', margin: 'auto' }}>
                                    {hasBatchId ? (
                                        <div>
                                            <b>
                                                {t('vertaling.title_createBatch')}
                                            </b>
                                            <div
                                                className="fields"
                                                style={{ justifyContent: 'center' }}
                                            >
                                                <div className="eight wide field">
                                                    <input
                                                        type="text"
                                                        {...register('newBatch')}
                                                        placeholder={t('vertaling.create_batch')} />
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>

                                <div style={{ width: '80%', margin: 'auto' }}>
                                    {isBottling ? (
                                        <div>
                                            <label className="eight wide field">
                                                <b>
                                                    {t('vertaling.selectbottletype')}
                                                </b>
                                                <Select
                                                    required
                                                    placeholder=""
                                                    options={bottleTypes.map((name, id) => ({
                                                        value: name.id,
                                                        label: name.name,
                                                    }))}
                                                    onChange={(selection) => changeBottleType(selection)}
                                                    className="specialty"
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 5,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#dacfa4b2',
                                                            primary: '#AC9E66',
                                                        },
                                                    })}
                                                />
                                            </label>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>

                                <div style={{ width: '80%', margin: 'auto' }}>
                                    {hasWintestorageId ? (
                                        <div>
                                            <label className="eight wide field">
                                                <b>{t('vertaling.select_tank')}</b>
                                                <Select
                                                    required
                                                    placeholder=""
                                                    options={barrels.map((name, id) => ({
                                                        value: name.id,
                                                        label: name.name,
                                                    }))}
                                                    onChange={(selection) => changeWinestorage(selection)}
                                                    className="specialty"
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 5,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#dacfa4b2',
                                                            primary: '#AC9E66',
                                                        },
                                                    })}
                                                />
                                            </label>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                <br />
                                <button
                                    type="submit"
                                    className="ui primary button login"
                                    style={{
                                        position: 'absolute',
                                        left: '50%',
                                        transform: 'translateX(-50%',
                                    }}
                                >
                                    <b>{t("vertaling.submit")}</b>
                                </button>
                            </form>
                        </div>
                    )}
                    {contentKeys.length < 1 && <p></p>}
                </div>
                <br />
            </div>
            <br />
        </div>
    );
};

export default CreateCellarRegistration;