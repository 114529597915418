import React from 'react'
import "./Spinner.css"

const Spinner = ({ isLoading }) => {
    if (isLoading === undefined || isLoading === true) {
        return <div id="spinner"></div>;
    } else {
        return <div id="spinner" style={{ visibility: 'hidden' }}></div >;
    }
}

export default Spinner