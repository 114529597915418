import { isEmptyObject } from "jquery";
import { useLocation, Outlet, Navigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { useFirestore } from "../context/FirestoreContext.js";
import { Roles } from "../logic/Roles";

//URLS that are allowed to skip user permission checks, usually URLs that have nothing to do with wineries
const allowedURLs = ["", "finishregistration", "profile", "winegrowers", "mywineries", "invitations"];

const RequireAuth = ({ requiredPermissionLevel }) => {
    const currentWinery = sessionStorage.getItem("currentwinery") ? sessionStorage.getItem("currentwinery").replace(/['"]+/g, '') : "";
    const { currentUser } = useAuth();
    const { currentUserDoc } = useFirestore();
    const location = useLocation();
    let permissionLevel = findPermissionLevel(currentWinery, currentUserDoc);
    let url;

    if (currentUser === null) url = "/login";
    if (isCurrentWineryEmpty(currentWinery, location.pathname)) url = "/";

    if (!isEmptyObject(currentUser) && currentUserDoc !== undefined && userIsntAdmin(currentUserDoc)) {
        if (permissionLevel < requiredPermissionLevel) url = "/";
        if (UserNoPermissionToWinery(location.pathname, currentUserDoc, currentWinery)) url = "/";
        if (hasUserFinishedRegistration(location.pathname, currentUserDoc)) url = "/finishRegistration";
    }

    return (
        url ? <Navigate to={url} state={{ from: location }} replace /> : <Outlet />
    )
}

const findPermissionLevel = (currentWinery, currentUserDoc) => {
    if (currentUserDoc) {
        if (currentUserDoc.role === Roles().ADMIN.name) return Roles().ADMIN.permissionLevel;
        let winery = currentUserDoc.wineries.find((w) => w.winegrower === currentWinery);

        if (currentWinery !== undefined &&
            currentWinery !== "" &&
            currentUserDoc.wineries !== undefined &&
            winery !== undefined) {
            if (winery.role === Roles().WINEGROWER.name) return Roles().WINEGROWER.permissionLevel;
        }
    }

    return Roles().EMPLOYEE.permissionLevel;
}

const isCurrentWineryEmpty = (currentWinery, location) => {
    if (allowedURLs.includes(location.toLowerCase().split("/")[1])) return false;
    if (currentWinery !== undefined && currentWinery !== "") return false;

    //in all other cases when current winery is empty the site should return to dashboard
    return true;
}

const UserNoPermissionToWinery = (location, currentUserDoc, currentWinery) => {
    if (allowedURLs.includes(location.toLowerCase().split("/")[1])) return false;
    let userWineries = currentUserDoc.wineries;

    return !userWineries.some(w => w.winegrower === currentWinery);
}

const userIsntAdmin = (currentUser) => {
    return currentUser.role !== 'admin';
}

const hasUserFinishedRegistration = (location, currentUserDoc) => {
    return location.toLowerCase() !== "/finishregistration" && currentUserDoc && currentUserDoc.registrationFinished === false
}

export default RequireAuth;