import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import GoBackButton from '../../components/FormComponents/GoBackButton';
import { useFirestore } from '../../context/FirestoreContext';
import { PageTitle } from '../../utils/PageTitle';
import { useSessionStorage } from '../../logic/useSessionStorage';

const CreateHarvestYear = () => {
    PageTitle("Create Harvestyear");
    const { register, handleSubmit } = useForm();
    const { createHarvestYear } = useFirestore();
    const { getHarvestYears } = useFirestore();
    const [currentWinery, setCurrentWinery] = useSessionStorage('currentwinery', '');
    const [errorMessage, setErrorMessage] = useState(null);
    const [harvestYears, setHarvestYears] = useState(undefined);
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        getHarvestYearsFromDB();
    }, [])

    const getHarvestYearsFromDB = async () => {
        let harvestYears = await getHarvestYears(currentWinery);

        harvestYears.forEach(year => {
            year.startDate = year.startDate.toDate();
            year.endDate = year.endDate.toDate();
        })

        setHarvestYears(harvestYears);
    }

    const onSubmit = async (data) => {
        let startDateString = data.startDate.split("-");
        let endDateString = data.endDate.split("-");
        const startDate = new Date(startDateString[0], startDateString[1] - 1, startDateString[2]);
        const endDate = new Date(endDateString[0], endDateString[1] - 1, endDateString[2]);

        const newHarvestYear = {
            name: data.name,
            startDate: startDate,
            endDate: endDate,
        }

        const validationResult = await validateInput(newHarvestYear);

        if (validationResult) {
            createHarvestYear(currentWinery, newHarvestYear);
            navigate("/harvestyears");
        }
    }

    const validateInput = async ({ name, startDate, endDate }) => {
        //checking if date range start and end is set correctly
        if (startDate.valueOf() >= endDate.valueOf()) {
            setErrorMessage(t('translation.harvestYears.errorMessages.endBeforeStartDate') + ` ${startDate.getDate()}-${startDate.getMonth() + 1}-${startDate.getFullYear()}`);
            return false;
        } else {
            setErrorMessage(null);
        }

        //checking if date range overlaps with any existing HarvestYear
        const state = harvestYears.every(harvestYear => {
            if (startDate.getTime() >= harvestYear.startDate.getTime() && startDate.getTime() <= harvestYear.endDate.getTime() ||
                harvestYear.startDate.getTime() >= startDate.getTime() && harvestYear.startDate.getTime() <= endDate.getTime()) {
                setErrorMessage(t('translation.harvestYears.errorMessages.datesOverlapping') + ": " + harvestYear.name);
                return false;
            } else {
                setErrorMessage(null);
                return true;
            }
        });
        if (!state) return false;
        return true;
    }


    const formClassname = "ui big form twelve wide column";
    return <>
       <h1 style={{ color: "#7f7f7f" }}>{t('translation.harvestYears.harvestYearInfo')}</h1>
        <div className='container'>

            <div className="ui grid stackable" style={{ justifyContent: 'center' }}>

                <GoBackButton />

                <form
                    className={formClassname}
                    style={{
                        border: '3px solid #B3B3B3',
                        marginTop: '50px',
                        borderRadius: '50px',
                        borderColor: '#ac9e66',
                    }}
                    onSubmit={handleSubmit(onSubmit)}>

                    <div className="fields" style={{ justifyContent: 'center' }}>
                        <div className="twelve wide field">
                            <label>
                                {t("translation.words.name")}
                                <input type="text" {...register('name')} htmlFor="name" required="required" />

                            </label>
                        </div>
                    </div>

                    <div className="fields" style={{ justifyContent: 'center' }}>
                        <div className="twelve wide field">
                            <label>
                                {t("translation.harvestYears.startDate")}
                                <input
                                    type="date"
                                    {...register('startDate')}
                                    htmlFor="startDate"
                                    required="required" />
                            </label>
                        </div>
                    </div>

                    <div className="fields" style={{ justifyContent: 'center' }}>
                        <div className="twelve wide field">
                            <label>
                                {t("translation.harvestYears.endDate")}
                                <input
                                    type="date"
                                    {...register('endDate')}
                                    htmlFor="endDate"
                                    required="required" />
                            </label>
                        </div>
                    </div>

                    <div style={{ textAlign: "center" }}>
                        <button type="submit" className="ui submit large grey button right floated bttn-profile"
                            style={{ float: 'none', marginTop: '30px' }}>
                            {t("translation.words.create")}
                        </button>
                        {errorMessage &&
                            <p style={{ color: 'red', fontWeight: 'bold', fontSize: '25px' }}>{errorMessage}</p>
                        }
                    </div>
                </form>
            </div>
        </div>
    </>;
}

export default CreateHarvestYear