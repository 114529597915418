import { useTranslation } from 'react-i18next';
import { useSessionStorage } from "../../logic/useSessionStorage";
import { useFirestore } from "../../context/FirestoreContext";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import 'react-day-picker/dist/style.css';
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import GoBackButton from "../../components/FormComponents/GoBackButton";


const CustomNonDbStep = () => {

    const [cellarRegistrationData, setCellarRegistrationData] = useSessionStorage("cellarReg", "");
    const { getCurrentCellarStep } = useFirestore()
    const { getBatchById } = useFirestore();
    const [currentStep, setCurrentStep] = useState(undefined);
    const [currentBatch, setCurrentBatch] = useState(undefined);
    const formClassname = "ui big form twelve wide column";
    const { register, handleSubmit } = useForm();
    const { i18n } = useTranslation();
    const lang = i18n.language;
    const navigate = useNavigate();
    const { currentUser } = useAuth();
    const [currentLangKey, setCurrentLangKey] = useState('nameEN');

    const [currentWinery, setCurrentWinery] = useSessionStorage("currentwinery", "");
    const { t } = useTranslation();


    const getCurrentCellarStepFromDb = async () => {
        let cellarStep = await getCurrentCellarStep(cellarRegistrationData.stepId, currentWinery);
        setCurrentStep(cellarStep);
    }

    const getCurrentBatchFromDb = async () => {
        let batch = await getBatchById(cellarRegistrationData.batchId, currentWinery);
        setCurrentBatch(batch);
    }

    useEffect(() => {
        if (currentStep === undefined) getCurrentCellarStepFromDb();
        if (currentBatch === undefined) getCurrentBatchFromDb();
    }, []);

    useEffect(() => {
        switch (lang) {
            case 'en':
                setCurrentLangKey('nameEN');
                break;
            case 'fr':
                setCurrentLangKey('nameFR');
                break;
            case 'nl':
                setCurrentLangKey('nameNL');
                break;
            default:
                setCurrentLangKey('nameEN');
        }
    }, [lang]);


    const onSubmit = async (data) => {
        let newCellarRegistration = {};
        newCellarRegistration = { ...cellarRegistrationData };
        let dateString = data.date.split("-");
        newCellarRegistration.date = new Date(dateString[0], dateString[1] - 1, dateString[2]);
        newCellarRegistration.SO2GramPerLiter = Number(data.SO2GramPerLiter);
        newCellarRegistration.email = currentUser.email;
        newCellarRegistration.type = "cellarStep";
        newCellarRegistration.visualisation = true;
        newCellarRegistration.waitingForReview = false;
        setCellarRegistrationData(newCellarRegistration)
        setTimeout(() => {
            navigate('/cellarRegistration/addWineStorage');
        }, 500)


    }

    return (
        <div>
            {currentStep !== undefined && currentBatch !== undefined &&
                <>
                    <h1>
                        {t('translation.cellarRegistration.registrationOf')} {currentStep[currentLangKey]} {t('translation.words.for')} {currentBatch.name}
                    </h1>
                    <GoBackButton />
                    <br />
                    <br />

                    <div className="ui grid stackable">
                        <form
                            className={formClassname}
                            style={{
                                border: "3px solid #B3B3B3",
                                marginTop: "100px",
                                margin: "auto",
                                borderRadius: "50px",
                                background: "#edeaea",
                                borderColor: "#ac9e66",
                                maxWidth: '960px'
                            }}
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <div
                                className="fields"
                                style={{ justifyContent: "center", marginTop: "20px" }}
                            >
                                <div
                                    className="twelve wide field"
                                    style={{ justifyContent: "center" }}>
                                    <label>
                                        <p>{t("translation.cellarRegistration.registrationDate")}</p>
                                        <input
                                            type="date"
                                            {...register('date')}
                                            htmlFor="date"
                                            required="required"
                                            style={{textAlign: "center"}} />
                                    </label>
                                </div>
                            </div>


                            <div
                                className="fields"
                                style={{ justifyContent: "center", marginTop: "20px" }}
                            >
                                <div
                                    className="twelve wide field">
                                    <label>
                                        <p>{t("translation.cellarRegistration.possibleAdditionOfSo")}</p>
                                        <input
                                            type="number"
                                            {...register('SO2GramPerLiter')}
                                            htmlFor="SO2GramPerLiter"
                                            style={{ textAlign: "center" }} />
                                    </label>
                                </div>
                            </div>
                            <button
                                type="submit"
                                className="ui submit large grey button right floated  button-vinyards"
                                style={{ float: "none", marginTop: "30px", width: "25%" }}
                            >
                                {t("translation.words.next")}
                            </button>
                        </form>
                    </div>

                </>
            }
        </div>
    );
}
export default CustomNonDbStep;