import { doc, updateDoc, deleteDoc, collection, addDoc } from 'firebase/firestore';
import { firestore } from "./config";

export const updateParcelDocument = async (winegrowerId, parcelId, data) => {
    const docRef = doc(firestore, `winegrowers/${winegrowerId}/parcels/${parcelId}`);
    const parcel = buildParcel(data);
    return updateDoc(docRef, parcel);
};

export const deleteParcelDocument = async(winegrowerId, parcelId) => {
    const docRef = doc(firestore, `winegrowers/${winegrowerId}/parcels/${parcelId}`);
    return deleteDoc(docRef);
};

export const updateAgriculturalParcel = async (winegrowerId, agriculturalParcelId, data) => {
    const docRef = doc(firestore, `winegrowers/${winegrowerId}/agriculturalParcels/${agriculturalParcelId}`);
    const agriculturalParcel = buildAgriculturalParcel(data);
    return updateDoc(docRef, agriculturalParcel);
};

export const deleteAgriculturalParcel = async (winegrowerId, agriculturalParcelId) => {
    const docRef = doc(firestore, `winegrowers/${winegrowerId}/agriculturalParcels/${agriculturalParcelId}`);
    return deleteDoc(docRef);
};

export const createParcel = async (winegrowerId, data) => {
    const colRef = collection(firestore, `winegrowers/${winegrowerId}/parcels`);
    const parcel = buildParcel(data);

    const docSnapshot = await addDoc(colRef, parcel);
    return updateParcelDocument(winegrowerId, docSnapshot.id, {
        ...parcel,
        id: docSnapshot.id
    });
};

export const createAgriculturalParcel = async (winegrowerId, data) => {
    const colRef = collection(firestore, `winegrowers/${winegrowerId}/agriculturalParcels`);
    const agriculturalParcel = buildAgriculturalParcel(data);

    const docSnapshot = await addDoc(colRef, agriculturalParcel);
    return updateAgriculturalParcel(winegrowerId, docSnapshot.id, {
        ...agriculturalParcel,
        id: docSnapshot.id
    });
};


function buildParcel(data) {
    const parcel = {
        address: data.address,
        agriculturalRegion: data.agriculturalRegion,
        areaSizeAre: data.areaSizeAre,
        areaSizeCentiare: data.areaSizeCentiare,
        country: data.country,
        landRegistrationNumber: data.landRegistrationNumber,
        municipality: data.municipality,
        name: data.name,
        number: Number(data.number),
        zipCode: data.zipCode
    };
    return parcel;
}

function buildAgriculturalParcel(data) {
    const agriculturalParcel = {
        grapeVariety: data.grapeVariety,
        areaPlantedAre: data.areaPlantedAre,
        areaPlantedCentiare: data.areaPlantedCentiare,
        rowDistance: data.rowDistance,
        plantDistance: data.plantDistance,
        numberOfPlants: Number(data.numberOfPlants),
        rootStock: data.rootStock,
        clone: data.clone,
        yearPlanted: Number(data.yearPlanted),
        parcelId: data.parcelId,
        name: data.name,
        mainCropCode: Number(9716),
        mainCropGroup: String("Fruit en noten"),
        vineyardId: data.vineyardId
        
    };
    return agriculturalParcel;
}