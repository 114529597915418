import { useTranslation } from 'react-i18next';
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Spinner from "../../../components/Spinner/Spinner";
import { useFirestore } from "../../../context/FirestoreContext";
import { useSessionStorage } from "../../../logic/useSessionStorage";
import { timestampToDate } from "../../../utils/DateConversions";
import { PageTitle } from "../../../utils/PageTitle";
import MaterialTable from '@material-table/core';
import GoBackButton from "../../../components/FormComponents/GoBackButton";

const History = () => {
    PageTitle("History")
    const [regs, setRegs] = useState([]);
    const [filteredRegs, setFilteredRegs] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const { i18n } = useTranslation();
    const lang = i18n.language;
    const [currentWinery, setCurrentWinery] = useSessionStorage("currentwinery", "");
    const { t } = useTranslation();

    const { getCellarSteps } = useFirestore();
    const [cellarSteps, setCellarSteps] = useState(undefined);

    const { getVineyardSteps } = useFirestore()
    const [vineyardSteps, setVineyardSteps] = useState(undefined);

    const { getBatches } = useFirestore();
    const [batches, setBatches] = useState(undefined);

    const { getVineyards } = useFirestore();
    const [vineyards, setVineyards] = useState([]);

    const { getAgriculturalParcels } = useFirestore();
    const [agriculturalParcels, setAgriculturalParcels] = useState([]);

    const { getRegistrations } = useFirestore();

    const { getCellars } = useFirestore();
    const [cellars, setCellars] = useState([]);


    useEffect(() => {
        const getCellarStepsFromDB = () => {
            return getCellarSteps(currentWinery);
        };

        let cellarSteps = getCellarStepsFromDB();
        setCellarSteps(cellarSteps);

    }, [currentWinery, getCellarSteps])

    useEffect(() => {
        const getVineyardStepsFromDB = () => {
            return getVineyardSteps(currentWinery);
        };

        let vineyardsteps = getVineyardStepsFromDB();
        setVineyardSteps(vineyardsteps);

    }, [currentWinery, getVineyardSteps])

    useEffect(() => {
        const getBatchesFromDB = () => {
            return getBatches(currentWinery);
        };

        let batches = getBatchesFromDB();
        setBatches(batches);

    }, [currentWinery, getBatches])

    useEffect(() => {
        const getVineyardsFromDB = () => {
            return getVineyards(currentWinery);
        };

        let vineyards = getVineyardsFromDB();
        setVineyards(vineyards);

    }, [currentWinery, getVineyards])

    useEffect(() => {
        const getAgriculturalParcelsFromDB = () => {
            return getAgriculturalParcels(currentWinery);
        };

        let agriculturalParcels = getAgriculturalParcelsFromDB();
        setAgriculturalParcels(agriculturalParcels);

    }, [currentWinery, getAgriculturalParcels])

    useEffect(() => {
        const getRegistrationsFromDB = () => {
            return getRegistrations(currentWinery);
        };

        let registrations = getRegistrationsFromDB();
        registrations.sort((a, b) => (a.date < b.date ? 1 : -1));
        setRegs(registrations);

    }, [currentWinery, getRegistrations])

    useEffect(() => {
        const getCellarFromDB = () => {
            return getCellars(currentWinery);
        };

        let cellars = getCellarFromDB();
        setCellars(cellars);

    }, [currentWinery, getCellars])

    useEffect(() => {
        let filterRegistration = structuredClone(regs)
        filterRegistration.forEach((reg, _) => {
            if (reg.date !== undefined) {
                reg.date = timestampToDate(reg.date);
            }

            if (batches !== undefined && batches.length !== 0) {
                // overlopen van alle batches van de ingelogde wijnboer
                let currentBatch = batches.find(
                    (batch) => batch.id === reg.batchId
                );
                if (currentBatch !== undefined) {
                    reg.batchId = currentBatch.name;
                } else {
                    reg.batchId = "";
                }
            }
            // bij een registratie onderscheid maken tussen wijngaardstap en kelderstap
            if (reg.type === "vineyardStep") {
                // overlopen van alle wijngaardstappen
                if (vineyards.length !== 0 && agriculturalParcels.length !== 0) {
                    let currentAgriculturalParcel;

                    if (reg.agriculturalParcelId !== undefined) {
                        currentAgriculturalParcel = agriculturalParcels.find(
                            (agriParcel) => agriParcel.id === reg.agriculturalParcelId
                        );
                    } else if (reg.agriculturalParcels !== undefined) {
                        currentAgriculturalParcel = agriculturalParcels.find(
                            (agriParcel) => agriParcel.id === reg.agriculturalParcels
                        );
                    }

                    if (!currentAgriculturalParcel) {
                        // Handle the case where the agricultural parcel is not found
                        console.log("Agricultural parcel not found for ID");
                        return; // Skip to next iteration
                    }

                    let currentVineyard = vineyards.find(
                        (vineyard) => vineyard.id === currentAgriculturalParcel.vineyardId
                    );

                    if (!currentVineyard) {
                        // Handle the case where the vineyard is not found
                        console.log("Vineyard not found for ID:", currentAgriculturalParcel.vineyardId);
                        return; // Skip to next iteration
                    }

                    reg.vineyardName = currentVineyard.name;
                }

                if (vineyardSteps !== undefined) {
                    for (let h = 0; h < vineyardSteps.length; h++) {
                        if (vineyardSteps[h].id === reg.stepId) {
                            if (lang === 'en') {
                                reg.stepId = vineyardSteps[h].nameEN;
                            } else if (lang === 'fr') {
                                reg.stepId = vineyardSteps[h].nameFR;
                            } else if (lang === 'it') {
                                reg.stepId = vineyardSteps[h].nameIT;
                            } else if (lang === 'nl') {
                                reg.stepId = vineyardSteps[h].nameNL;
                            }
                        }
                    }
                }
            } else if (reg.type === "cellarStep") {
                if (cellarSteps !== undefined) {
                    for (let h = 0; h < cellarSteps.length; h++) {
                        if (cellarSteps[h].id === reg.stepId) {
                            if (lang === 'en') {
                                reg.stepId = cellarSteps[h].nameEN;
                            } else if (lang === 'fr') {
                                reg.stepId = cellarSteps[h].nameFR;
                            } else if (lang === 'it') {
                                reg.stepId = cellarSteps[h].nameIT;
                            } else if (lang === 'nl') {
                                reg.stepId = cellarSteps[h].nameNL;
                            }
                        }
                    }
                }
            } else {
                // overlopen van alle wijnkelders
                for (let z = 0; z < cellars.length; z++) {
                    if (cellars[z].id === reg.locationId) {
                        reg.locationId = cellars[z].name;
                    }
                }

                // overlopen van alle kelderstappen

            }

                // bind a grape variety only for vineyardStep
                if (agriculturalParcels !== undefined && reg.agriculturalParcelId !== undefined) {
                    if (reg.type === "vineyardStep") {
                        const parcel = agriculturalParcels.find(aParcel => aParcel.id === reg.agriculturalParcelId);
                        if (parcel !== undefined) {
                            reg.grape = parcel.grapeVariety;
                        }
                    }
                }
            return reg;
        })
        setFilteredRegs(filterRegistration);

        if (cellarSteps !== undefined && vineyardSteps !== undefined && batches !== undefined) {
            setLoading(false);
        }

    }, [cellarSteps, vineyardSteps, batches, agriculturalParcels, lang])

    const customDateSort = (a, b) => {
        // Helper function to convert date from DD/MM/YYYY to YYYY-MM-DD
        const convertDate = (dateString) => {
            const parts = dateString.split("/");
            return `${parts[2]}-${parts[1]}-${parts[0]}`; // Reformat to YYYY-MM-DD
        };

        // Convert the dates and create Date objects
        const dateA = a.date ? new Date(convertDate(a.date)) : new Date(0);
        const dateB = b.date ? new Date(convertDate(b.date)) : new Date(0);

        // Check if dates are valid
        if (isNaN(dateA) || isNaN(dateB)) {
            console.log("Invalid comparison between", dateA, "and", dateB);
            return 0;
        }

        return dateA - dateB;
    };

    return (
        <div style={{ position: "relative" }}>
            <Spinner isLoading={isLoading} />
            <h1 style={{ marginTop: "50px", color: "#7f7f7f" }}>
                {t('vertaling.title_history')}
            </h1>
            <GoBackButton />
            <br />
            <div
                className="forms regs-filter"
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginTop: "50px",
                }}
            >
                {isLoading !== undefined && (
                    <div className="batchesList filterList" style={{ marginTop: "10px" }}>
                        <MaterialTable
                            title=""
                            data={filteredRegs}
                            columns={[
                                {
                                    title: t('vertaling.registratiedatum'),
                                    field: "date",
                                    sorting: true,
                                    defaultSort: "desc",
                                    customSort: customDateSort
                                },
                                {
                                    title: t('translation.history.stepType'),
                                    field: "type",
                                },
                                {
                                    title: t('translation.history.stepName'),
                                    field: "stepId",
                                },
                                {
                                    title: t('vertaling.add_batch'),
                                    field: "batchId",
                                },
                                {
                                    title: t('vertaling.colCreatedBy'),
                                    field: "email",
                                },
                                {
                                    title: t('vertaling.beschrijving'),
                                    field: "description",
                                },
                                {
                                    title: t('translation.words.grape'),
                                    field: "grape",
                                },
                                {
                                    title: t('translation.history.vineyardName'),
                                    field: "vineyardName",
                                },
                            ].map((c) => ({
                                render: (row) => (
                                    <Link to={`/history/edit/${row.id}`}>
                                        {c.lookup ? c.lookup[row[c.field]] : row[c.field]}
                                    </Link>
                                ),
                                ...c,
                            }))}
                            options={{
                                search: true,
                                filtering: true,
                                exportButton: true,
                                exportAllData: true,
                                headerStyle: {
                                    backgroundColor: "#cecaca",
                                    color: "#FFF",
                                },
                                emptyRowsWhenPaging: false,
                                paging: true,
                                pageSize: 50,
                                pageSizeOptions: [
                                    10,
                                    20,
                                    25,
                                    50,
                                ],
                            }}
                            // Dit zorgt ervoor dat we alle onderdelen van de tabel kunnen vertalen
                            localization={{
                                body: {
                                    emptyDataSourceMessage: t('vertaling.emptyDataSourceMessage'),
                                    addTooltip: t('vertaling.addTooltip'),
                                    deleteTooltip: t('vertaling.deleteTooltip'),
                                    editTooltip: t('vertaling.editTooltip'),
                                    filterRow: {
                                        filterTooltip: t('vertaling.filterTooltip'),
                                    },
                                    editRow: {
                                        deleteText: t('vertaling.deleteText'),
                                        cancelTooltip: t('vertaling.cancelTooltip'),
                                        saveTooltip: t('vertaling.submit '),
                                    },
                                },
                                grouping: {
                                    placeholder: t('vertaling.placeholder'),
                                    groupedBy: t('vertaling.groupedBy'),
                                },
                                header: {
                                    actions: t('vertaling.actions'),
                                },
                                pagination: {
                                    labelDisplayedRows: t('vertaling.labelDisplayedRows'),
                                    labelRowsPerPage: t('vertaling.labelRowsPerPage'),
                                    firstAriaLabel: t('vertaling.firstAriaLabel'),
                                    firstTooltip: t('vertaling.firstAriaLabel'),
                                    previousAriaLabel: t('vertaling.previousAriaLabel'),
                                    previousTooltip: t('vertaling.previousAriaLabel'),
                                    nextAriaLabel: t('vertaling.nextAriaLabel'),
                                    nextTooltip: t('vertaling.nextAriaLabel'),
                                    lastAriaLabel: t('vertaling.lastAriaLabel'),
                                    lastTooltip: t('vertaling.lastAriaLabel'),
                                },
                                toolbar: {
                                    addRemoveColumns: t('vertaling.addRemoveColumns'),
                                    nRowsSelected: t('vertaling.nRowsSelected'),
                                    showColumnsTitle: t('vertaling.showColumnsTitle'),
                                    showColumnsAriaLabel: t('vertaling.showColumnsTitle'),
                                    exportTitle: t('vertaling.exportTitle'),
                                    exportAriaLabel: t('vertaling.exportTitle'),
                                    exportName: t('vertaling.exportName'),
                                    searchTooltip: t('vertaling.searchTooltip'),
                                    searchPlaceholder: t('vertaling.searchTooltip'),
                                    exportCSVName: t('vertaling.exportName'),
                                    exportPDFName: t('vertaling.exportPDFName'),
                                },
                            }}
                        />
                    </div>
                )}
                <br />
            </div>
        </div>
    );
}
export default History