import { useTranslation } from 'react-i18next';
import { send } from 'emailjs-com';
import 'firebase/auth';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { query, where, getDocs, collection, onSnapshot } from 'firebase/firestore';
import { disapproveUserDocument } from '../../firebase/user';
import { PageTitle } from '../../utils/PageTitle';
import { Roles } from '../../logic/Roles';
import { useSessionStorage } from '../../logic/useSessionStorage';
import { firestore } from '../../firebase/config';

const DeactivateEmployee = () => {
  PageTitle("Deactivate Employee");
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const params = useParams();
  const { handleSubmit } = useForm();
  const [userDocument, setUserDocument] = useState(null);
  const [deactivatedUser, disapproveUser] = useState(null);
  const [currentWinery, setCurrentWinery] = useSessionStorage('currentwinery', '');
  const { t } = useTranslation();

  const [toSend, setToSend] = useState({
    email_WN: params.email,
    tastefever: 'TasteFever',
    email: '',
    firstname: '',
    lastname: '',
    download: t('vertaling.download'),
    subject: t('vertaling.subject_deactivate'),
    aanspreking: t('vertaling.aanspreking_general'),
    employee_activated_deactivated: t('vertaling.employee_deactivated'),
    label1: t('vertaling.label1'),
    label2: t('vertaling.label2'),
    afsluiting: t('vertaling.afsluiting'),
    alert: t('translation.emails.moreInfo'),
    land: t('translation.words.belgium'),
  });

  // Referenties naar firestore database (bv : ophalen van collecties uit firestore db)
  // Alle waarden in de variabelen gaan verloren éénmaal de useEffect volledig uitgevoerd is

  useEffect(() => {
    if (currentUser) {
      const userQuery = query(collection(firestore, 'users'), where('email', '==', currentUser.email));
      const unsub = onSnapshot(userQuery, (querySnapshot) => {
        const userLoggedIn = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUserDocument(userLoggedIn[0]);
      });

      // De mail wordt verzonden door middel van deze functie
      const handleChange = (e) => {
        setToSend({ ...toSend, [e.target.name]: e.target.value });
      };

      /*Functie die alle variabelen in mailingplatform emailjs hier definieert en vertaalt*/

      const userToDeactivateQuery = query(collection(firestore, 'users'), where('email', '==', params.email));
      const unsub2 = onSnapshot(userToDeactivateQuery, (querySnapshot) => {
        const userToDeactivate = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        if (userToDeactivate.length !== 0 && userToDeactivate[0].wineries.some(winery => winery.winegrower === currentWinery)) {
          disapproveUser(userToDeactivate[0]);
        } else {
          navigate(`/employees`, { replace: true });
        }
      });
      return () => {
        unsub();
        unsub2();
      };
    }
  }, [currentUser, params.id]);

  const onSubmit = async (e) => {
    try {
      disapproveUserDocument(deactivatedUser.id, deactivatedUser.wineries, currentWinery);
      //e.preventDefault();
      toSend.email = deactivatedUser.email;
      toSend.firstname = deactivatedUser.firstname;
      toSend.lastname = deactivatedUser.lastname;

      const wineryQuery = query(collection(firestore, 'winegrowers'), where("__name__", "==", currentWinery));
      const querySnapshot = await getDocs(wineryQuery);

      toSend.winery = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))[0].name;

        send('service_4g61fk7', 'template_bdlyxo7', toSend, 'user_EDqZc5RPaMddv4DU2Jmmo').then((response) => {
          // console.log('SUCCESS!', response.status, response.text);
        });
    } catch (error) {
      console.error(error);
    } finally {
      navigate(`/employees`, { replace: true });
    }
  };

  if (deactivatedUser != null) {
    // Functie die zorgt voor het weergeven van alle gegevens van een user
    return (
      <div className="noCenter wrapper">
        <h1 className="userDetailTitle">
          {t('vertaling.title_deactivate')}
        </h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="wrapperUserInfo">
            <h2 className="detailUserLabel">
              {t('vertaling.firstname')}
            </h2>
            <p className="userValueTxt">{deactivatedUser.firstname}</p>
          </div>
          <div className="wrapperUserInfo">
            <h2 className="detailUserLabel">
              {t('vertaling.lastname')}
            </h2>
            <p className="userValueTxt">{deactivatedUser.lastname}</p>
          </div>
          <div className="wrapperUserInfo">
            <h2 className="detailUserLabel">
              {t('vertaling.email')}
            </h2>
            <p className="userValueTxt">{deactivatedUser.email}</p>
          </div>
          <button className="approveBtn" type="submit">
            {t('vertaling.deactivate')}
          </button>
        </form>
      </div>
    );
  } else {
    return (
      <div></div>
    );
  }
};
export default DeactivateEmployee;