import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { firestore } from '../../firebase/config';
import { PageTitle } from '../../utils/PageTitle';
import { useSessionStorage } from '../../logic/useSessionStorage';
import GoBackButton from "../../components/FormComponents/GoBackButton";
import { useTranslation } from 'react-i18next';
import { collection, doc, query, getDocs, onSnapshot, orderBy } from 'firebase/firestore';

const Cellars_regis = () => {
    PageTitle("Cellar Registration");
    const navigate = useNavigate();
    const [cellarsList, setCellarsList] = useState([]);
    const [cellarsStepList, setCellarsStepList] = useState([]);
    const [selectCellar, setCellarValue] = useState('');
    const [selectCellarName, setCellarLabel] = useState('');
    const [selectStep, setStepValue] = useState('');
    const [selectStepName, setStepName] = useState('');
    const [currentWinery, setCurrentWinery] = useSessionStorage('currentwinery', '');
    const { t } = useTranslation();

    // Referenties naar firestore database (bv : ophalen van collecties uit firestore db)
    // Alle waarden in de variabelen gaan verloren éénmaal de useEffect volledig uitgevoerd is

    useEffect(() => {
        const cellarsRef = collection(firestore, 'winegrowers', currentWinery, 'cellars');
        const cellarsUnsubscribe = onSnapshot(cellarsRef, (querySnapshot) => {
            const cellar = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setCellarsList(cellar);
        });

        const cellarStepsQuery = query(collection(firestore, 'cellarsteps'), orderBy('number'));
        const stepsUnsubscribe = onSnapshot(cellarStepsQuery, (querySnapshot) => {
           const cellarSteps = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setCellarsStepList(cellarSteps);
        });
        return () => {
            cellarsUnsubscribe();
            stepsUnsubscribe();
        };
    }, [currentWinery]);

    // Bij het aanpassen van een waarde in dropdownlist wordt automatisch ook het label aangepast op basis van de geselecteerde waarde
    const CellarChange = (selection) => {
        setCellarValue(selection.value);
        setCellarLabel(selection.label);
    };

    // Bij het wijzigen van een kelderstap wordt volgend event meegegeven
    const StepChange = (selection) => {
        setStepValue(selection.value);
        setStepName(selection.label);
    };


    // Het weergeven van alle een dropdownlijst met alle wijngaarden en wijngaardstappen

    return (
        <div style={{ position: 'relative', marginTop: '90px' }}>
            <h3>{t('vertaling.cellar_regis')}</h3>
            <GoBackButton />
            <br />
            <br />
            <div className="vineyardList" style={{ justifyContent: 'center' }}>
                <div className="sixteen wide field history-table zIndex">
                    <label
                        style={{ width: '50%', marginBottom: '70px', marginTop: '30px' }}
                    >
                        <p>{t('vertaling.selectCellar')}</p>
                        <Select
                            placeholder={t('vertaling.selectCellar')}
                            options={cellarsList.map((name, id) => ({
                                value: name.id,
                                label: name.name,
                            }))}
                            // Bij het wijzigen van een wijnkelder wordt hier een event meegegeven
                            onChange={(selection) => CellarChange(selection)}
                            className="specialty"
                            style={{ justifyContent: 'center' }}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 5,
                                colors: {
                                    ...theme.colors,
                                    primary25: '#dacfa4b2',
                                    primary: '#AC9E66',
                                },
                            })}
                        />
                    </label>
                </div>
            </div>

            <div className="vineyardList" style={{ justifyContent: 'center' }}>
                <div className="sixteen wide field history-table">
                    <label style={{ width: '50%' }}>
                        <p>{t('vertaling.select_cellarstep')}</p>
                        <Select
                            placeholder={t('vertaling.select_cellarstep')}
                            options={cellarsStepList.map((name, id) => ({
                                value: name.id,
                                label: t(`vertaling.${name.name.replace(/\s/g, "").replace("-", "").replace("/", "")}`)
                            }))}
                            // Bij het wijzigen van een kelderstap wordt hier een event meegegeven

                            onChange={(selection) => StepChange(selection)}
                            className="specialty"
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 5,
                                colors: {
                                    ...theme.colors,
                                    primary25: '#dacfa4b2',
                                    primary: '#AC9E66',
                                },
                            })}
                        />
                    </label>
                </div>
            </div>
            {selectStep !== '' && selectCellar !== '' ? (
                <Link
                    to={{
                        pathname: `cellarsRegistration/new`,
                        state: {
                            selectStep,
                            selectStepName,
                            selectCellar,
                            selectCellarName,
                        },
                    }}
                >
                    <button
                        className="ui primary button login"
                        style={{
                            position: 'absolute',
                            left: '50%',
                            transform: 'translateX(-50%',
                            marginTop: '30px',
                        }}
                    >
                        {t('vertaling.create_new')}
                    </button>
                </Link>
            ) : (
                <button
                    className="ui primary button login"
                    style={{
                        position: 'absolute',
                        left: '50%',
                        transform: 'translateX(-50%',
                        marginTop: '30px',
                    }}
                >
                    {t('vertaling.selectcellarandcellarstep')}
                </button>
            )}
            <br />
        </div>
    );
};

export default Cellars_regis;
