import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import GoBackButton from '../../components/FormComponents/GoBackButton';
import { firestore } from '../../firebase/config';
import { useFirestore } from '../../context/FirestoreContext'
import { useSessionStorage } from '../../logic/useSessionStorage';
import { createRegistration } from '../../firebase/registrations';
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, onSnapshot, serverTimestamp } from 'firebase/firestore';
import Select from "react-select";
import { useForm } from 'react-hook-form';
import { Timestamp } from 'firebase/firestore';
import { unstable_renderSubtreeIntoContainer } from 'react-dom';


const AddRegistrationVineyardStep = () => {
  const [vineyardStepData, setVineyardStepData] = useState(undefined);
  const [vineyardStepDataName, setVineyardStepDataName] = useState(undefined);
  const [currentParcels, setCurrentParcels] = useState(undefined);
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const { currentUser } = useAuth();
  const [currentVineyardUser, setCurrentVineyardUser] = useState(undefined);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { currentUserDoc } = useFirestore();
  const [selectedOptions, setSelectedOptions] = useState("");
  const [booleanFields, setBooleanFields] = useState({});

  const [currentWinery, setCurrentWinery] = useSessionStorage(
    'currentwinery',
    ''
  );
  const [VineyardRegistrationData, setVineyardRegistrationData] = useSessionStorage('vineyardReg', '');
  const [grape, setGrape] = useSessionStorage('grape', '');
  const [vineyard, setVineyard] = useSessionStorage('vineyard', '');
  const params = useParams();
  const formClassname = 'ui big form twelve wide column';
  const { register, handleSubmit } = useForm();
  let regLang = '';

  const {getChemicals} = useFirestore()
  const [chemicalsData, setChemicalsData] = useState();

  const [fytoProducts, setFytoProducts] = useState([]);

  const prorateSteps = ['Crop protection', 'Fertilization', 'Treating Biostimulants or Others'];
  const prorateFields = ['areaTreated', 'amount', 'weighedQuantity', 'waterTreatment', 'areaToBeTreated', 'weighedMeasuredQuantity', 'litersOfWater'];

  function getLocalDateTimeForInput() {
    const now = new Date();
    // getTimezoneOffset returns the difference in minutes, so it's converted to milliseconds
    const timezoneOffset = now.getTimezoneOffset() * 60000; // Convert offset to milliseconds
    const localDateTime = new Date(now.getTime() - timezoneOffset); // Adjust for timezone
    return localDateTime.toISOString().slice(0, 16);
  }

  useEffect(() => {
    const getChemicalsFromDB = async () => {
      const chemicals = await getChemicals(currentWinery);
      setChemicalsData(chemicals);
    };
    getChemicalsFromDB();
  }, [currentWinery]);

  useEffect(() => {
    if(chemicalsData !== undefined) {
      let fytoProd = []
      chemicalsData.forEach(chemical => {
        fytoProd.push(chemical.product_EN[0]);
      })
      setFytoProducts(fytoProd)
    }
  }, [currentWinery, chemicalsData])

  useEffect(() => {
    setCurrentVineyardUser(currentUserDoc);
    const vineyardStepRef = doc(firestore, 'winegrowers', currentWinery, 'vineyardSteps', VineyardRegistrationData.stepId);

    const unsubscribeVineyard = onSnapshot(vineyardStepRef, (doc) => {
      if (doc.exists) {
        if (lang === 'en') {
          regLang = 'nameEN';
        } else if (lang === 'fr') {
          regLang = 'nameFR';
        } else if (lang === 'nl') {
          regLang = 'nameNL';
        }
        setVineyardStepDataName(doc.data()[regLang]);
        setVineyardStepData(doc.data());
      }
    });

    let parcelDataMap = {};
    for (const parcelId in VineyardRegistrationData.parcelId) {
      const parcelRef = doc(firestore, 'winegrowers', currentWinery, 'agriculturalParcels', parcelId);
      const docSnapshot = getDoc(parcelRef);

      if (docSnapshot.exists) {
        parcelDataMap.push(docSnapshot.data());
      }
    }
    setCurrentParcels(parcelDataMap);
    return () => {
      unsubscribeVineyard();
    }
  }, [VineyardRegistrationData.parcelId, currentWinery, params.id]);

  const handleBooleanChange = (fieldKey) => (e) => {
    const isChecked = e.target.checked;
    const updatedBooleanFields = {
      ...booleanFields,
      [fieldKey]: isChecked,
    };
    setBooleanFields(updatedBooleanFields);
  };

  const onSubmit = async (data) => {
    const finalData = { ...data, ...selectedOptions };
    try {
      let totalArea = 0;

      for (const parcel of VineyardRegistrationData.agriculturalParcels) {
        const areaPlantedAre = parcel.areaPlantedAre || 0;
        const areaPlantedCentiare = parcel.areaPlantedCentiare || 0;
        totalArea += parseFloat(areaPlantedAre + areaPlantedCentiare / 100);
      }

      const originalValues = {};
      prorateFields.forEach(key => {
        originalValues[key] = finalData[key];
      });

      for (const parcel of VineyardRegistrationData.agriculturalParcels) {
        if (prorateSteps.includes(vineyardStepData.nameEN)) {
          for (const key in finalData) {
            if (prorateFields.includes(key)) {
              const originalValue = parseFloat(originalValues[key]) || 0;
              const areaPlantedAre = parseFloat(parcel.areaPlantedAre) || 0;
              const areaPlantedCentiare = parseFloat(parcel.areaPlantedCentiare) || 0;
              const parcelArea = areaPlantedAre + (areaPlantedCentiare / 100);
              const proratedValue = (originalValue / totalArea) * parcelArea;
              finalData[key] = Math.round(proratedValue * 100) / 100;
            }
          }
        }
        generateRegistration(finalData, parcel).then(async (newReg) => {
          await createRegistration(currentWinery, newReg);
        });
      }
      navigate("/vineyardsRegistration/selectVineyardStep")
    } catch (error) {
      console.error(error);
    }
  };

  const handleKeyDown = (event) => {
    // Check if the pressed key is 'Enter'
    if (event.key === 'Enter' && event.target.type !== 'textarea') {
      // Prevent the form from submitting
      event.preventDefault();
    }
  };

  const handleChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedOptions(prevOptions => ({
        ...prevOptions,
        [selectedOption.value["key"]]: selectedOption.value["textEN"]
      }));
    } else {
      console.log("No option selected or an error occurred");
    }
  };

  const generateRegistration = async (data, parcel) => {
    let registration;
    for (const [key, value] of Object.entries(data)) {
      if (!isNaN(Number(value))) {
        data[key] = Number(value);
      }
    }
    registration = { ...data };
    registration.agriculturalParcelId = parcel.id;
    registration.date = Timestamp.fromDate(new Date(registration.date));
    registration.email = currentUser.email;
    registration.yearPlanted = parcel.yearPlanted;
    registration.stepId = VineyardRegistrationData.stepId;
    registration.type = "vineyardStep";
    registration.visualisation = true;
    registration.waitingForReview = false;

    return registration;
  };

  let form = [];
  let inForm = [];

  function generateForm() {
    let currentLang = '';

    if (vineyardStepData.content !== undefined) {
      let options = {};
      for (const [key, value] of Object.entries(vineyardStepData.content)) {
        if (value["type"] === "option") {
          if (!options.hasOwnProperty(value["key"])) {
            options[value["key"]] = [];
          }
          // Create an object for each option containing all language texts
          const optionObject = {
            key: value["key"],
            textEN: value["textEN"],
            textFR: value["textFR"],
            textNL: value["textNL"],
            textIT: value["textIT"],
            // Add other languages as needed
          };
          options[value["key"]].push(optionObject);
        }
      }

      if (lang === 'en') {
        currentLang = 'textEN';
      } else if (lang === 'fr') {
        currentLang = 'textFR';
      } else if (lang === 'nl') {
        currentLang = 'textNL';
      }

      let requiredField;
      for (const [key, value] of Object.entries(vineyardStepData.content)) {
        requiredField = value['validation'] === 'notEmpty';
        if ((value['dependsOn'] !== undefined && booleanFields[value['dependsOn']]) || value['dependsOn'] === undefined) {
          if (value['type'] === 'multilinetext' || value['type'] === 'text') {
            form.push(
              <div
                className="fields"
                style={{ justifyContent: 'center', marginTop: '20px' }}
              >
                {requiredField === true && (
                  <div className="twelve wide field">
                    <label>
                      {value[currentLang]}
                      <input
                        type="text"
                        {...register(value['key'])}
                        htmlFor={value['key']}
                        style={{ textAlign: 'center' }}
                        required="required" />
                    </label>
                  </div>
                )}
                {requiredField === false && (
                  <div className="twelve wide field">
                    <label>
                      {value[currentLang]}
                      <input
                        type="text"
                        {...register(value['key'])}
                        htmlFor={value['key']}
                        style={{ textAlign: 'center' }} />
                    </label>
                  </div>
                )}
              </div>
            );
            inForm.push(value['key']);
          } else if (value['type'] === 'decimal') {
            form.push(
              <div
                className="fields"
                style={{ justifyContent: 'center', marginTop: '20px' }}
              >
                <div className="twelve wide field">
                  <label>
                    {value[currentLang]}
                    {requiredField === true && (
                      <input
                        type="number"
                        {...register(value['key'])}
                        htmlFor={value['key']}
                        style={{ textAlign: 'center' }}
                        step="0.01"
                        required />
                    )}
                    {requiredField === false && (
                      <input
                        type="number"
                        {...register(value['key'])}
                        htmlFor={value['key']}
                        style={{ textAlign: 'center' }}
                        step="0.01" />
                    )}
                  </label>
                </div>
              </div>
            );
            inForm.push(value['key']);
          } else if (value['type'] === 'int') {
            form.push(
              <div
                className="fields"
                style={{ justifyContent: 'center', marginTop: '20px' }}
              >
                <div className="twelve wide field">
                  <label>
                    {value[currentLang]}
                    {requiredField === true && (
                      <input
                        type="number"
                        {...register(value['key'])}
                        htmlFor={value['key']}
                        style={{ textAlign: 'center' }}
                        required />
                    )}
                    {requiredField === false && (
                      <input
                        type="number"
                        {...register(value['key'])}
                        htmlFor={value['key']}
                        style={{ textAlign: 'center' }} />
                    )}
                  </label>
                </div>
              </div>
            );
            inForm.push(value['key']);
          } else if (value['type'] === 'boolean') {
            form.push(
              <div
                className="fields"
                style={{ justifyContent: 'center', marginTop: '20px' }}
              >
                <div className="twelve wide field">
                  <label>
                    {value[currentLang]}
                    <div className="ui toggle checkbox" style={{ textAlign: 'center' }}>
                      <input
                        type="checkbox"
                        tabIndex="0"
                        {...register(value['key'])}
                        htmlFor={value['key']}
                        required={requiredField}
                        onChange={handleBooleanChange(value['key'])}
                      />
                      <label htmlFor={value['key']}></label>
                    </div>
                  </label>
                </div>
              </div>
            );
            inForm.push(value['key']);
          } else if (value["type"] === "label") {
            if(value["key"] === "product") {
              form.push(
                <div
                  key={value["key"] + "_label_div"}
                  className="fields"
                  style={{
                    justifyContent: "center",
                    marginTop: "20px",
                    marginBottom: "-20px ",
                    fontWeight: "bold",
                  }}
                >
                  <label>{value[currentLang]}</label>
                </div>);
              form.push(
                <div
                  key={value["key"] + "_option_div"}
                  className="fields"
                  style={{ justifyContent: "center", marginTop: "20px" }}
                >
                  <div className="twelve wide field">
                    <label>
                      <Select
                        label={value["key"]}
                        htmlFor={value["key"]}
                        required="required"
                        style={{
                          justifyContent: "center",
                          marginTop: "20px",
                          marginBottom: "-20px ",
                          fontWeight: "bold"
                        }}
                        options={fytoProducts.map(product => ({ label: product, value: {key: "product", textEN: product}}))}
                        onChange={(selection) =>
                          handleChange(selection)
                        }
                      />
                    </label>
                  </div>
                  <div style={{ display: "none" }}>
                    <input
                      {...register(value["key"])}
                      htmlFor={value["key"]}
                      value={selectedOptions[value["key"]]}
                      readOnly />
                  </div>
                </div>
              );
            } else {
              form.push(
                <div
                  key={value["key"] + "_label_div"}
                  className="fields"
                  style={{
                    justifyContent: "center",
                    marginTop: "20px",
                    marginBottom: "-20px ",
                    fontWeight: "bold",
                  }}
                >
                  <label>{value[currentLang]}</label>
                </div>
              );
            }
          } else if (value["type"] === "option" && inForm.includes(value["key"]) === false) {
            form.push(
              <div
                key={value["key"] + "_option_div"}
                className="fields"
                style={{ justifyContent: "center", marginTop: "20px" }}
              >
                <div className="twelve wide field">
                  {requiredField === true && (
                    <label>
                      <Select
                        label={value["key"]}
                        htmlFor={value["key"]}
                        required="required"
                        options={options[value["key"]].map((option) => ({
                          label: option[currentLang],
                          value: option,
                        }))}
                        onChange={(selection) =>
                          handleChange(selection)
                        }
                      />
                    </label>
                  )}

                  {requiredField === false && (
                    <label>
                      <Select
                        label={value["key"]}
                        htmlFor={value["key"]}
                        options={options[value["key"]].map((option) => ({
                          label: option[currentLang],
                          value: option,
                        }))}
                        onChange={(selection) =>
                          handleChange(selection)
                        }
                      />
                    </label>
                  )}
                  <div style={{ display: "none" }}>
                    <input
                      {...register(value["key"])}
                      htmlFor={value["key"]}
                      value={selectedOptions[value["key"]]}
                      readOnly />
                  </div>
                </div>
              </div>
            );
            inForm.push(value["key"]);
          }
          else if (value['type'] === 'timestamp') {
            form.push(
              <div
                className="fields"
                style={{ justifyContent: 'center', marginTop: '20px' }}
              >
                <div className="twelve wide field">
                  <label>
                    {value[currentLang]}
                    <input
                      type="datetime-local"
                      {...register(value['key'])}
                      htmlFor={value['key']}
                      style={{ textAlign: 'center' }}
                      required={requiredField}
                      defaultValue={getLocalDateTimeForInput()}
                      max={getLocalDateTimeForInput()}
                    />
                  </label>
                </div>
              </div>
            );
            inForm.push(value['key']);
          }
        }
      }
      return form;
    }
  }

  return (
    <div>
      <h1>{t("translation.vineyardRegistration.addRegistration") + " " + vineyardStepDataName + " " + t("vertaling.on") + " " + grape + " " + t("vertaling.in") + " " + vineyard}</h1>
      <GoBackButton />
      <br />
      <br />
      <div>
        <form
          className={formClassname}
          style={{
            border: '3px solid #B3B3B3',
            marginTop: '150px',
            margin: 'auto',
            borderRadius: '50px',
            background: '#edeaea',
            borderColor: '#ac9e66',
            maxWidth: '960px',
          }}
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={handleKeyDown}
        >
          {vineyardStepData !== undefined && (
            <div>
              {generateForm()}
              <button
                type="submit"
                className="ui submit large grey button right floated button-vineyards"
                style={{
                  float: 'none',
                  marginTop: '20px',
                  marginBottom: '20px',
                  width: '25%',
                }}
              >
                {t("translation.vineyardRegistration.createRegistration")}
              </button>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default AddRegistrationVineyardStep;