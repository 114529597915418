import { useSessionStorage } from "../../logic/useSessionStorage";
import { useTranslation } from 'react-i18next';
import { useFirestore } from "../../context/FirestoreContext";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import GoBackButton from "../../components/FormComponents/GoBackButton";

const CustomTransferNewBatch = () => {

    const [cellarRegistrationData, setCellarRegistrationData] = useSessionStorage("cellarReg", "");
    const [batchData, setBatchData] = useSessionStorage("batchData", "");
    const { getCurrentCellarStep } = useFirestore();
    const { getBatchById } = useFirestore();
    const [currentStep, setCurrentStep] = useState(undefined);
    const [currentBatch, setCurrentBatch] = useState(undefined);
    const { register, handleSubmit } = useForm();
    const formClassname = "ui big form twelve wide column";
    const { currentUser } = useAuth();
    const navigate = useNavigate();
    const [currentWinery, setCurrentWinery] = useSessionStorage("currentwinery", "");
    const { t } = useTranslation();

    const getCurrentCellarStepFromDb = async () => {
        let cellarStep = await getCurrentCellarStep(cellarRegistrationData.stepId, currentWinery);
        setCurrentStep(cellarStep);
    }

    const getCurrentBatchFromDb = async () => {
        let batch = await getBatchById(cellarRegistrationData.batchId, currentWinery);
        setCurrentBatch(batch);
    }

    useEffect(() => {
        if (currentStep === undefined) getCurrentCellarStepFromDb();
        if (currentBatch === undefined) getCurrentBatchFromDb();
    }, [currentBatch, currentStep, getCurrentBatchFromDb, getCurrentCellarStepFromDb]);

    const onSubmit = async (data) => {
        let newCellarRegistration = {};
        newCellarRegistration = { ...cellarRegistrationData };
        let dateString = data.date.split("-");
        newCellarRegistration.date = new Date(dateString[0], dateString[1] - 1, dateString[2]);
        newCellarRegistration.SO2GramPerLiter = Number(data.SO2GramPerLiter);
        newCellarRegistration.email = currentUser.email;
        newCellarRegistration.type = "cellarStep";
        newCellarRegistration.visualasition = true;
        newCellarRegistration.waitingForReview = false;
        newCellarRegistration.keepOldBatch = data.keepOldBatch;
        let newBatch = {};
        newBatch = { ...currentBatch }
        newBatch.name = data.newBatchName;
        newBatch.createDate = new Date(dateString[0], dateString[1] - 1, dateString[2]);
        newBatch.historic = "Batch created in cellarStep customTransferNewBatch";
        setBatchData(newBatch);
        setCellarRegistrationData(newCellarRegistration);
        setTimeout(() => {
            navigate("/cellarRegistration/addWineStorage");
        }, 500);
    }
    return (
        <div>
            {currentStep !== undefined && currentBatch !== undefined && <div>
                <h1>
                    {t('translation.cellarRegistration.registrationOf')} {currentStep.nameEN} {t('translation.words.for')} {currentBatch.name}
                </h1>
                <GoBackButton />
                <br />
                <br />
                <div className="ui grid stackable">
                    <form
                        className={formClassname}
                        style={{
                            border: "3px solid #B3B3B3",
                            marginTop: "100px",
                            margin: "auto",
                            borderRadius: "50px",
                            background: "#edeaea",
                            borderColor: "#ac9e66",
                            maxWidth: '960px'
                        }}
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <div className="fields" style={{ justifyContent: "center", marginTop: "20px" }}>
                            <div className="twelve wide field">
                                <label>
                                    <p>{t("translation.cellarRegistration.registrationDate")}</p>
                                    <input
                                        type="date"
                                        {...register('date')}
                                        htmlFor="date"
                                        required
                                        style={{ textAlign: "center" }} />
                                </label>
                            </div>
                        </div>
                        <div className="fields" style={{ justifyContent: "center", marginTop: "20px" }}>
                            <div className="twelve wide field">
                                <label>
                                    <p>{t("translation.cellarRegistration.newBatchName")}</p>
                                    <input
                                        type="text"
                                        {...register('newBatchName')}
                                        htmlFor="newBatchName"
                                        required
                                        style={{ textAlign: "center" }} />
                                </label>
                            </div>
                        </div>
                        <div className="fields" style={{ justifyContent: "center", marginTop: "20px" }}>
                            <div className="twelve wide field">
                                <label>
                                    <p>{t("translation.cellarRegistration.keepOldBatch")}</p>
                                    <input
                                        type="checkbox"
                                        {...register('keepOldBatch')}
                                        htmlFor="keepOldBatch"
                                        defaultChecked />
                                </label>
                            </div>
                        </div>
                        <div className="fields" style={{ justifyContent: "center", marginTop: "20px" }}>
                            <div className="twelve wide field">
                                <label>
                                    <p>{t("translation.cellarRegistration.possibleAdditionOfSo")}</p>
                                    <input
                                        type="number"
                                        {...register('SO2GramPerLiter')}
                                        htmlFor="SO2GramPerLiter"
                                        style={{ textAlign: "center" }} />
                                </label>
                            </div>
                        </div>
                        <button
                            type="submit"
                            className="ui submit large grey button right floated  button-vinyards"
                            style={{ float: "none", marginTop: "30px", width: "25%" }}
                        >
                            {t("translation.words.next")}
                        </button>
                    </form>
                </div>
            </div>


            }
        </div>
    );
}
export default CustomTransferNewBatch;