// Vertaaltabel met alle vertalingen bestaande uit een sleutel en de bijhorende waarde

const translation = {
  vertaling: {
    new_vineyard: 'Create new vineyard',
    select_batch: 'Select batch',
    name: 'Name',
    perceel_nr: 'Plot number',
    kadaster_nr: 'Land registry number',
    perceel_opp: 'Total plot area',
    grapevariety_vineyard: 'Grape variety',
    vineyard_grapevarieties: 'Grape varieties',
    vineyard_general: 'General',
    updatevineyardvariety: 'Update variety',
    myvarieties: 'My varieties',
    size: 'Area (ha)',
    pruningmethod: 'Pruning method',
    sunorientation: 'Sun orientation',
    north: 'North',
    west: 'West',
    south: 'South',
    east: 'East',
    northeast: 'North-East',
    northwest: 'North-West',
    southeast: 'South-East',
    southwest: 'South-West',
    unspecified: 'Unspecified',
    height: 'Height',
    soil: 'Soil',
    adress: 'Address',
    city: 'City',
    gpslocation: 'GPS location',
    latitude: 'Latitude',
    longtitude: 'Longitude ',
    checklocation: 'Check location',
    vineyard1: 'Vineyard',
    createvineyard: 'Create vineyard',
    selectpruningmethod: 'Select pruning method',
    selectsunorientation: 'Select sun orientation',
    grapevariety_title: 'Create new variety',
    grapevariety_btn: 'Create variety',
    planting_year: 'Planting year',
    perceel_deel_opp: 'Plot part area',
    tendrils: 'Tendrils',
    grapevariety: 'Grape variety',
    createvineyardvariety: 'Create variety',
    myvineyards: 'My vineyards',
    details: 'Details',
    colSize: 'Area',
    colSizeHa: 'Area (ha)',

    vineyardInfo: 'Vineyard info',
    updatevineyard: 'Update vineyard',
    explainVineyardDetailUI: 'Click on a vineyard row to see the agricultural parcels/grape varieties of this vineyard',
    title_deactivate: 'Deactivate employee?',
    title_activate: 'Activate winegrower?',
    title_registratie: 'Register winery',
    companyName: 'Company name',
    companyAdress: 'Company address',
    companyCountry: 'Company country',
    companyZip: 'Company zip',
    companyTel: 'Company telephone number',
    companyVat: 'VAT number',
    companyExcise: 'Excise number',
    companyWebsite: 'Website',
    title_activate_winegrower: 'Approve winegrower',
    add_winegrower: 'Add winery',
    colWinegrower: 'Winegrower',
    title_addemployee: 'Access forbidden',
    title_gebruikersgegevens: 'User details',
    role: 'Role',
    password: 'Password',
    optie2: 'Employee',
    submit: 'Save',
    active: 'Active',
    title_add_user: 'Activate employee',
    complete_employee: 'Complete your new account or log out',
    toevoegen_werknemer: 'Add employee',
    title_await: 'Await confirmation',
    title_tankInfo: 'Edit barrel',
    volume_vat: 'Barrel volume (l)',
    type_vat: 'Type',
    typeConcrete: 'Concrete',
    wood: 'Wood',
    type_wood: 'Wood type',
    date: 'Birthdate',
    title_batches: 'My batches',
    batchNotInUse: 'This batch is not use in yet',
    add_batches: 'Create batch',
    add_batch: 'Batch',
    colCreatieDatum: 'Creation date',
    colCreatedBy: 'Created by',
    colHectoliters: 'Hectoliters',
    colHistoric: 'History',
    colAvailable: 'Available',
    optie_juist: 'yes',
    optie_fout: 'no',
    title_batchEdit: 'Edit batch',
    submit_batchEdit: 'Save changes',
    colNoAvailable: 'Not available',
    step1: 'Step 1',
    step1Text: ' Add vineyard here',
    step2: 'Step 2',
    step2Text: ' Add wine cellar here',
    step3: 'Step 3 ',
    step3Text: ' Add barrel here',
    error: 'Complete all the steps above!',
    error_required: 'Please fill in all fields',
    errorPruningMethod: 'Select a method',
    errorOrientation: 'Select a sunorientation',
    errorCellar: 'Select a cellar',
    title_createTank: 'Create barrel',
    cellar: 'Cellar',
    harvest: 'Harvest',
    winegrower: 'Winegrower',
    selectCellar: 'Select a cellar',
    title_createBatch: 'Create batch',
    batch_name: 'Batch name',
    batch_hectoliters: 'Hectoliters',
    title_createCellar: 'Create cellar',
    title_sprays: 'My sprays',
    title_createSpray: 'Create spray',
    add_sprays: 'Create spray',
    colSort: 'Sort',
    colLotNr: 'Lot number',
    colSupplier: 'Supplier',
    colPurchasedate: 'Purchase date',
    colLiters: 'Liters',
    colResale: 'Resale',
    colYourRole: 'Your role',
    title_dashboard: 'Overview',
    h2_dashboard: 'Wineries overview',
    p2_dashboard: 'View all registered wineries',
    h3_dashboard: 'My profile',
    p3_dashboard: 'View or edit your profile',
    selectWinegrower: 'Select winegrower',
    selectWinery: 'Select winery',
    selectWineryPrompt: 'Select a winery from the list below.',
    h4_dashboard: 'My winery profile',
    p4_dashboard: 'View or edit your profile',
    h5_dashboard: 'My employees',
    p5_dashboard: 'View your registered employees',
    p6_dashboard: 'Activate or add a new employee',
    p7_dashboard: 'View or edit your vineyards',
    h8_dashboard: 'My cellars',
    p8_dashboard: 'View or edit your cellars',
    p9_dashboard: 'View or edit your batches',
    h10_dashboard: 'History',
    p10_dashboard: 'View your registrations history',
    h11_dashboard: 'Report',
    p11_dashboard: 'View reports of your registrations',
    h12_dashboard: 'My sprays',
    p12_dashboard: 'View or edit your sprays',
    h13_dashboard: 'My wineries',
    p13_dashboard: 'View or leave your wineries',
    h14_dashboard: 'Winery invitations',
    p14_dashboard: 'Accept new wineries',
    h15_dashboard: 'Import Crop Protectors',
    p15_dashboard: 'Import a list of crop protectors',    
    changeRegistration: 'Edit registration',
    submit_wijziging: 'Save changes',
    delete_reg: 'Delete registration',
    gotoapp: 'Log in to the mobile app to use your account',
    title_history: 'My history',
    selectBatch: 'Select a batch',
    selectVineyard: 'Select a vineyard',
    vineyard_steps: 'Vineyard Steps',
    harvest_steps: 'Harvest Steps',
    cellar_steps: 'Cellar Steps',
    rangeDatum: 'Filter date',
    stap: 'Step',
    typeStap: 'Step type',
    registratiedatum: 'Registration date',
    beschrijving: 'Description',
    locatie: 'Location',
    title_login: "Don't have an account yet?",
    error1_login: 'Email or password is wrong, please try again or signup',
    error2_login: 'Please fill in email and password',
    forgotpsw: 'Oops, I forgot my password',
    title_tanks: 'My tanks & barrels',
    add_tank: 'Create barrel',
    colType: 'Type',
    colContent: 'Content',
    colVolume: 'Volume (l)',
    colDate: 'Date first use',
    colDelete: 'Delete',
    add_cellar: 'Create cellar',
    cfpassword: 'Confirm password',
    winery_name: 'Winery name',
    create_winegrower: 'Create!',
    title_profile: 'Your profile',
    title2_profile: 'Your winery profile',
    winery: 'Winery name',
    accept: 'Accept',
    no_wineries_to_accept: 'There are no wineries to accept!',
    controlvineyards: 'Check my vineyards',
    controlcellars: 'Check my cellars',
    controlemployees: 'Check my employees',
    update_profile: 'Update profile',
    title_reset: 'Reset password',
    succes_reset: 'Check your inbox for further instructions',
    error_reset: 'Failed to reset password',
    placeholder_reset: 'Your Email',
    winegrower_signup: 'Register as a Winegrower',
    employee_signup: 'Register as an Employee',
    signin_signup: 'Already have an account',
    signup: 'Sign Up',
    errorFirstname: 'Please enter your first name.',
    errorLastname: 'Please enter your last name.',
    errorEmail: 'Please enter an email adress.',
    errorPsw: 'Please enter a password.',
    errorWinery1: 'Please enter a winery name.',
    errorWinery2: 'Winery does not exist or is not yet approved.',
    errorChoose: 'Choose one of the options.',
    errorPswMatch: 'Your passwords do not match.',
    errorVerified: 'Please make sure you are not a robot',
    actief_userprofile: 'Active',
    disapprove: 'Deactivate',
    inactief_userprofile: 'Unactive',
    update_gebruiker: 'Update user',
    title_winegrowers: 'Your registered wineries',
    update_wijnhuis: 'Update winery',
    update_wijnboer: 'Update winegrower',
    goback: 'Go back',
    approve: 'approve',
    activate: 'Activate',
    deactivate: 'Deactivate',
    firstname: 'First name',
    lastname: 'Last name',
    email: 'Email',
    onderwerp: 'Craft Your Taste – Registration confirmation ',
    aanspreking:
      "Thank you for registering! It's nice to hear that you want to be part of the Craft Your Taste community! ",
    message:
      'We do everything we can to confirm your registration as soon as possible. Our team is currently reviewing your details and will give you access to your profile once all details have been checked. After all, we want this web portal to be used only by professionals in order to guarantee the quality of the community.',
    slot: 'After approval you will also receive a link to download the Craft Your Taste application for smartphone (IOS and Android).See you soon!',
    afsluiting: 'Team Craft Your Taste – by Tastefever',
    tastefever: 'Tastefever',
    tastefever_login: 'Login to Tastefever ',
    moduleMaintenance: 'This modules is under maintenance.',

    //bevestigingsmail als werknemer toegevoegd is aan winery

    employeeAccepted_emp:
      'You can now logon in the app to the following winery: ',

    employeeAccepted_wg: 'The following user has accepted your invitation:  ',

    employeeApproved_message:
      'Your account has been activated, you can now login on the app',

    employeeDisapproved_message: ' Your account has been deactivated.',

    app_link_android:
      'https://play.google.com/store/apps/details?id=be.exanteit.tastefever',
    app_link_ios:
      'https://apps.apple.com/be/app/craft-your-taste-by-tastefever',
    download: 'Download the app below and start the adventure',
    ownload: '',

    inlog: 'These are your login details',
    onderwerp_1: 'Craft Your Taste profile activation',
    aanspreking_general: 'Dear Sir or Madam',
    message1:
      'The administrator of your company has now given you access to your profile.  ',
    message2:
      'From now on you can log in to our application with the login details that you have received from your administrator. You can download the Craft your Taste application from the Apple App Store or the Google Play Store and start using the application. If you have further questions, you can contact the administrator of your company.',
    message3: 'Follow this link to reset your password for your',
    message4: 'account',
    label1: 'Email',
    label2: 'Password',
    slot_1: 'Good luck!',
    onderwerp_invite_emp: 'accepted invite',
    inviteSend: 'Your invite has been send to',

    begin:
      'Our team has checked your registration and we have now given you access to your profile. ',
    message_1:
      'From now on you can log in to our portal with your chosen login details. Go to ',
    message_2:
      'and log in to complete your profile and fill in all the details of your company. When all data is registered online, you can start using the application and you can start registering your entire production process. ',
    message_3:
      'Our team remains available if you need help using the details below. ',

    //activate/deactvate employee account
    subject_activate: 'Account activated',
    subject_deactivate: 'Account deactivated',
    subject_deleted: 'Account deleted',
    employee_activated: 'Your account has been activated from ',
    employee_deactivated: 'Your account has been deactivated from ',
    employee_deleted: 'Your account has been deleted from ',

    login_nav: 'Login',
    signup_nav: 'Signup',
    welcome_nav: 'Welcome',
    logout_nav: 'Logout',

    bericht_mail: 'Message',

    pdftitle1: 'Registrations from',
    pdftitle2: 'till',
    amount: 'Amount: ',
    batch_pdf: 'Batch: ',
    location_pdf: 'Location: ',
    amountDrying: 'Amount drying: ',
    warehouse: 'Warehouse: ',
    harvestMethod: 'Harvest method: ',
    tank: 'Tank: ',
    accuracy: 'Accuracy: ',
    density: 'Density (g/ml): ',
    temperature: 'Temperature (°C): ',
    acidity: 'Acidity: ',
    alcohol: 'Alcohol: ',
    massDensity: 'Mass density: ',
    ph: 'PH: ',
    sugars: 'Sugars: ',
    fermentationType: 'Fermentation type: ',
    productsAdded: 'Products added: ',
    method: 'Method: ',
    amountOfBottles: 'Amount of bottles: ',
    bottletype: 'Bottletype: ',
    productUsed: 'Product used: ',
    amountOfProductUsed: 'Amount of product used: ',
    registrationDate_pdf: 'Registration date: ',
    on: 'on',
    in: 'in',

    emptyDataSourceMessage: 'No records to display',
    addTooltip: 'Add',
    deleteTooltip: 'Delete',
    editTooltip: 'Edit',
    filterTooltip: 'Filter',
    deleteText: 'Do you want to delete this line?',
    cancelTooltip: 'Cancel',
    placeholder: 'Drag headers ...',
    groupedBy: 'Grouped By:',
    actions: 'Actions',
    labelDisplayedRows: '{from}-{to} of {count}',
    labelRowsSelect: 'rows',
    labelRowsPerPage: 'Rows per page:',
    firstAriaLabel: 'First Page',
    previousAriaLabel: 'Previous Page',
    nextAriaLabel: 'Next Page',
    lastAriaLabel: 'Last Page',
    addRemoveColumns: 'Add or remove columns',
    nRowsSelected: '{0} row(s) selected',
    showColumnsTitle: 'Show Columns',
    exportTitle: 'Export',
    exportName: 'Export as CSV',
    searchTooltip: 'Search',
    exportPDFName: 'Export as PDF',

    /*Report*/

    placeholder1_report: 'Select step type: ',
    type_report: 'Select a type: ',
    cellartype_report: 'Cellar step',
    vineyardtype_report: 'Vineyard step',
    day_report: 'All registrations of this day',
    placeholder2_report: 'Select a step: ',
    title_report: 'Registration report',
    colStorage_report: 'Storage',
    refill_report: 'Refill quantity',
    output_report: 'Amount of output  ',
    choose_period: 'Choose period',
    report_titleh1: 'Registration report',
    amount_report: 'Amount ',
    temperature_report: 'Temperature (°C) ',
    density_report: 'Density (g/ml) ',
    drying_amount: 'Droog hoeveelheid',
    location_report: 'Location',
    warehouse_report: 'Warehouse',
    harvest_method_report: 'Harvest method',
    productUsed_report: 'Used products ',
    amountOfProductUsed_report: 'Number of used products ',
    alcohol_report: 'Alcohol ',
    ph_report: 'PH ',
    sugars_report: 'Sugar ',
    acidity_report: 'Acidity ',
    method_report: 'Method ',
    bottletype_report: 'Bottle type ',
    amountOfBottles_report: 'Amount of bottles ',
    fermentationType_report: 'Fermentation type ',
    massDensity_report: 'Mass density ',
    source_batch_report: 'Source batch ',
    product_amount_report: 'Product amount',

    /*Steps*/

    Trainingandtyingbranches: 'Training and tying branches',
    Soilmaintenance: 'Soil maintenance',
    Ripeness: 'Ripeness',
    Crushingformaceration: 'Crushing for maceration',
    Foliage: 'Foliage',
    Destemming: 'Destemming',
    Pruning: 'Pruning',
    MaintenanceProtectionvineyard: 'Maintenance / Protection vineyard',
    Greenharvest: 'Green harvest',
    Priming: 'Priming',
    Other: 'Other',
    Drying: 'Drying',
    CrushingPressingwithoutmaceration: 'Crushing / Pressing without maceration',
    Harvest: 'Harvest',
    Fermentation: 'Fermentation',
    Agingbottle: 'Aging bottle',
    Stabilisation: 'Stabilisation',
    Decantingandtreatments: 'Decanting and treatments',
    Assemble: 'Assemble',
    Aeration: 'Aeration',
    Rackingandtreatments: 'Racking and treatments',
    Split: 'Split',
    Clarification: 'Clarification',
    Certification: 'Certification',
    Bottling: 'Bottling',
    Productaddition: 'Product addition',
    Otherpractiquesandtreatments: 'Other practises and treatments',
    Transfer: 'Transfer',
    Acidification: 'Acidification',
    Agingbarrel: 'Aging barrel',
    Pressingaftermaceration: 'Pressing after maceration',
    Overpumping: 'Over pumping',
    Clearbarreltank: 'Clear barrel / tank',
    Labelling: 'Labelling',
    Otheraging: 'Other aging',
    Malolacticfermentation: 'Malolactic fermentation',
    Secondfermentation: 'Second fermentation',
    Maceration: 'Maceration',

    /*Methods*/

    Pergola: 'Pergola',
    Doublecordon: 'Double cordon',
    Basket: 'Basket',
    Singlecordon: 'Single cordon',
    Goblet: 'Goblet pruning',
    other: 'Other',
    Lyre: 'Lyre waist',
    Singleguyot: 'Single guyot ',
    Scotthenry: 'Scott henry',
    Doubleguyot: 'Double guyot',
    Genevadoublecurtain: 'Geneva double curtain',

    //My History
    current_batch_history: 'Current batch ',

    /*Trace batch */
    trace_batch: 'Trace batch',
    trace_h1: 'View or track a batch',
    source_batch: 'Source batch: ',
    current_batch: 'Current batch: ',
    wine_storage: 'Winestorage',
    trusted_regis: 'Trusted for registrations ',
    optie_yes: 'yes',
    optie_no: 'no',
    no_batch_registrations: 'No steps are registered on this batch',

    /*Trace vineyard */
    trace_vineyard: 'Trace vineyard',
    trace_vineyard_h1: 'View or track a vineyard',
    open_registrations: 'Open all the registrations',
    crop_filter: 'Filter on crop protections, fertilization and treatement of biostimulants',
    no_vineyard_registrations: 'No steps are registered on this vineyard',

    /*Toevoegen registratie*/

    title_addregis: 'Add registration ',
    p_regis: 'Add a registration ',
    cellar_regis: 'Cellar registration',
    vineyard_regis: 'Vineyard registration ',
    select_vineyard: 'Select vineyard',
    register: 'Register',
    register_WG_title: 'Make you account here and start your adventure!',

    select_vineyardstep: 'Select a vineyardstep',
    title_regis: 'Create new vineyard registration',
    product: 'Product',
    products: 'Products',
    type_of_fermentation: 'Select type of fermentation',
    create_batch: 'Create new batch',
    method_used: 'Method used',
    amount_used: 'Amount used',
    amount_hectoliters: 'Amount of hectoliters',
    refill_amount: 'Refill amount',

    /* Goedkeuren registratie */

    approve_registration: 'Approve registrations',
    approve_registrations: 'Registrations to approve',
    trusted: 'Approved for registrations',

    /* Split batches */
    select_tank: 'Select barrel / tank*',
    remove_batch: 'Remove batch',
    create_new: 'Create new',
    regisforcellar: 'Registration for cellar',
    regisforvineyard: 'Registration for vineyard',
    onBarrel: 'On barrel',
    onBottle: 'On bottle',
    manually: 'Manually',
    select_cellarstep: 'Select a cellarstep',
    select_sourcebatch: 'Select a source batch*',
    select_countbatch: ' Into how many new batches do you want to split ? *',
    selectbottletype: 'Select a bottle type*',
    selectcellarandcellarstep: 'Select a cellar and a cellarstep',
    selectvineyardandvineyardstep: 'Select a vineyard and a vineyardstep',
    batchdrying: 'Select batch (drying) or create new batch*',
    selectLocation: 'Select a location',
    waiting_for_approval: 'There are registrations waiting for approval,',
    click_here: 'Click here',
    to_approve_it: 'to approve it ',
    finish: 'Finish',

    /*Merge*/
    merged: 'Merged from: ',
    source_tank: 'Source barrel / tank*',
    destination_batch: 'Destination barrel / tank',
    into: '',
  },

  translation: {
    myParcels: 'My agricultural parcels',
    myParcelsDescription: 'View or edit your agricultural parcels',
    createParcel: 'Create land registry parcel',
    parcelNumber: 'Parcel number',
    landRegistrationNumber: 'Land registration number',
    goback: 'Go back',
    name: 'Name',
    greenArea: 'Surface area including green/buffer area (ha)',
    number: 'Number',
    colSizeCa: 'Area (ca)',
    colSize: 'Area',
    parcelId: 'Parcel Id',
    none: 'None',
    grapeVariety: 'Grape variety',
    grapeVarietyOverview: 'Overvieuw grape varieties',
    agriculturalParcelExplanation: 'Per vinyard you can have one or more grape varieties connected to different agricultural parcel.',
    numberOfPlants: 'Number of plants',
    areaPlanted: 'Planted area',
    agriculturalParcel: 'Agricultural parcel',
    deleteEmployee: 'Delete employee',
    deleteAccount: 'Delete account',
    deleteAccountText:
      'Are you sure you want to delete your account? This action is not reversible.',
    parcelInfo: 'Land registry parcel info',
    agriculturalRegion: 'Agricultural region',
    zipCode: 'Zip code',
    municipality: 'Municipality',
    country: 'Country',
    selectParcel: 'Select the desired vineyard',
    updateParcel: 'Update parcel',
    connectedVineyard: 'Connected to vineyard:',
    connectedParcel: 'Connected to land registry parcel: ',
    connectedVineyardNotSelected: 'An agricultural parcel must be connected to a vineyard as well as a cadastral parcel.',
    connectedParcelNotSelected: 'An agricultural parcel must be connected to a land registry parcel as well as a cadastral parcel.',
    selectedVineyard: 'Selected vineyard: ',
    selectedParcel: 'Selected land registry parcel: ',
    selectedVineyardNotSelected: 'A vineyard must be connected to a land registry parcel as well as a cadastral parcel.',
    selectedParcelNotSelected: 'A vineyard must be connected to a land registry parcel as well as a cadastral parcel.',
    agriculturalParcelInfo: 'Info grape variety',
    parcelName: 'Land registry parcel name',
    clone: 'Clone',
    rootStock: 'Root stock',
    areaPlantedAre: 'Planted area (a)',
    areaPlantedCentiare: 'Planted area (ca)',
    yearPlanted: 'Planted in year: ',
    updateAgriculturalParcel: 'Update grape variety',
    createAgriculturalParcel: 'Create grape variety',
    vineyardNotSelected:
      'Please select the vineyard the agricultural parcel should be connected to',
    parcelNotSelected:
      'Please select the parcel the agricultural parcel should be connected to',
    myParcelsExplanation:
      "Click the button 'Details' to modify the the parcel or select the parcel to create a grape varieties.",

    history: {
      stepType: 'Step type',
      stepName: 'Step name',
      cropName: 'Crop name',
      vineyardName: 'Vineyard name',
    },

    vineyardSteps: {
      myVineyardSteps: 'My vineyard steps',
      myVineyardStepsDescription: 'View or edit your vineyard steps',
      vineyardStepInfo: 'Vineyard step info',
      nameEn: 'English name',
      nameFr: 'French name',
      nameIt: 'Italian name',
      nameNl: 'Dutch name',
      number: 'Step number',
      stepName: 'Step name',
      order: 'Order',
      key: 'Key',
      textEN: 'English name',
      textFR: 'French name',
      textIT: 'Italian name',
      textNL: 'Dutch name',
      type: 'Step type',
      validation: 'Validation',
      validation1: 'Validation 1',
      validation1Value: 'Validation 1 value',
      validation2: 'Validation 2',
      validation2Value: 'Validation 2 value',
      updateVineyardStep: 'Update vineyard step',
      selectionType: 'Selection type',
      newVineyardStep: 'New vineyard step',
      createVineyardStep: 'Create vineyard step',
      resetToDefault: 'Reset to default',
      vineyardStepContent: 'Vineyard step content',
      addVineyardStepContent: 'Add vineyard step content',
      newVineyardStepContent: 'New vineyard step content',
      selectionTypeNotSelected: 'Selection type is not selected',
      stepTypeNotSelected: 'Step type is not selected',
      createVineyardStepContent: 'Create vineyard step content',
    },

    barrels: {
      numberOfBarrels: 'Number of barrels / tanks',
      totalVolume: 'Total volume (l)',
      myBarrels: 'My barrels / tanks',
      barrelInfo: 'Update barrel / tank',
      createBarrel: 'Create barrel / tank',
    },
    invitationAccepted: 'invitation accepted',

    words: {
      belgium: 'Belgium',
      email: 'Email',
      name: 'Name',
      address: 'Address',
      location: 'Location',
      BTWnumber: 'VAT number',
      password: 'Password',
      delete: 'Delete',
      edit: 'Edit',
      create: 'Create',
      next: 'Next',
      for: 'for',
      configuration: 'Configuration',
      registration: 'Registration',
      reporting: 'Reporting',
      save: "Save",
      grape: "Grape",
    },

    emails: {
      welcome: 'Welcome to the Craft Your Taste community!',
      thanks: 'Thanks and have fun!',
      moreInfo: 'More information',

      registrationInfoNewUser: {
        subject: 'Invitation vineyard',
        message1: "You've been invited to register for ",
        message2:
          "You'll receive an email containing a temporary password soon. Log in on our website with the email shown underneath to finish creating your account.",
        existinguser_message2:
          'Click on the link and log in with your email address and password to accept the invite: ',
        your_password: 'your password',
      },

      registrationPasswordNewUser: {
        message1:
          'Your temporary password to log in and finish your registration:',
      },

      registrationInfoExistingUser: {
        message1:
          "You've been added to a new winery. Go to the websites dashboard and click on 'winery invitations' to accept.",
      },
    },

    pageNotFound: {
      title: 'Page not found',
      body: 'There is no page attached to the current URL. Please check the URL for mistakes and try again.',
    },

    dashboard: {
      harvestYears: {
        title: 'Harvest years',
        body: 'View or edit your harvest years',
      },
      government: {
        title: 'Government',
        body: 'Check document data for the government',
      },
      barrels: {
        title: 'My tanks & barrels',
        body: 'View or edit your tanks & barrels',
      },
    },

    harvestYears: {
      startDate: 'Start date',
      endDate: 'End date',
      myHarvestYears: 'My harvest years',
      harvestYearInfo: 'Harvest year info',
      errorMessages: {
        datesOverlapping: 'Dates are overlapping with an existing Harvestyear',
        nothingChanged: 'Nothing has been changed',
        endBeforeStartDate: 'End date must be after',
      },
    },

    governmentDocuments: {
      personalData: 'Personal data',
      wineryData: 'Winery data',
      selectYear: 'Select a harvest year',

      harvestDeclaration: {
        title: 'Harvest declaration',
        harvestNumber: 'Harvest number',
        vineVariety: 'Vine variety',
        acreage: 'Acreage (estimate)',
        parcelIdentification: 'Parcel identification',
        vineAmount: 'Vine amount',
        harvestInOwnCellar: 'Harvest in own cellar',
        color: 'Color',
        sugar: 'Sugar',
        acidity: 'Acidity',
        litersForHarvest: 'Liters harvested',
        generateHarvestStockReport: 'Generate Report',
      },

      stockDeclaration: {
        title: 'Stock declaration',
        name: 'Name of wine',
        stock: 'Stock in bulk',
        productionYear: 'Production year',
        recognition: 'Recognition',
      },
      bottlingDeclaration: {
        title: 'Bottling declaration',
        name: 'Name of wine',
        litersInBottle: 'Stock in bottles',
        bottleVolume: 'Bottle volume',
        productionYear: 'Production year',
        recognition: 'Recognition',
      },
    },
    cellars: {
      myCellarsExplanation: "Click on the cellar's name to add barrels / tanks",
    },

    cellarSteps: {
      myCellarSteps: 'My cellar steps',
      myCellarStepsDescription: 'View or edit your cellar steps',
      newCellarStep: 'New cellar step',
      cellarStepContent: 'Cellar step content',
      addCellarStepContent: 'Add cellar step content',
      newCellarStepContent: 'New cellar step content',
      updateCellarStep: 'Update cellar step',
      createCellarStep: 'Create cellar step',
      createCellarStepContent: 'Create cellar step content',
    },

    harvestRegistration: {
      registerHarvest: 'Register harvest step',
      registerHarvestDescription: 'Register a new harvest step',
      addBatchForHarvest: 'Create new batch for harvest step',
      addWineStorage: 'Add wine storage',
      selectWineStorage: 'Select winestorage',
      finishRegistration: 'Finish registration',
      juiceInLiters: 'Juice in liters',
      addAmount: 'Add amount',
      amountFor: 'Amount for',
      vineyard: "Vineyard",
      activeHarvestYearNotFound: 'No active harvest year found. Please create a harvest year.',
      harvestYearsNotAvailable: 'No harvest years available. Please create a harvest year.',
    },

    cellarRegistration: {
      registerCellarStep: 'Register cellar step',
      registerCellarStepDescription: 'Register a new cellar step',
      selectCellarStep: 'Select a cellar step',
      registrationOf: 'Registration of',
      selectBatchForRegistration: 'Select batche(s) for registration',
      harvestYearName: 'Harvest year name',
      batchName: 'Batch Name',
      registrationDate: 'Registration date',
      possibleAdditionOfSo: 'Possible addition of SO2',
      startDate: 'Start date',
      endDate: 'End date',
      newBatchName: 'New batch name',
      keepOldBatch: 'Keep old batch?',
      keepBatch: 'Keep batch ',
      litersFor: 'Liters for ',
      bottlingOf: 'Bottling of wine in ',
      selectTypeOfBottle: 'Select type of bottle',
      createCellarRegistration: 'Create cellar registration',
    },

    vineyardRegistration: {
      addRegistration: 'Register vineyard step',
      registerVineyardStepDescription: 'Register a new Vineyard step',
      selectParcel: 'Select vineyard or grape variety',
      vineyardStepRegistration: 'Register vineyard step',
      description: 'Description',
      wayOfHarvest: 'Way of harvest',
      warehouse: 'Warehouse',
      amountDrying: 'Amount drying',
      product: 'Product',
      amount: 'Amount',
      products: 'Products',
      massDensity: 'Mass density',
      createRegistration: 'Create Registration',
      selectVineyardStep: 'Select a vineyard step',
      selectedVineyardStep: 'Selected vineyard step',
    },


    traceBatch: {
      SO2GramPerLiter: 'SO2 (gr/l)',
      SO2Gram: 'SO2 (gr)',
      amountLiters: 'Amount (l)',
      bottleType: 'Type of bottle',
      destination: 'Destination',
      origin: 'Origin',
      amountOfBottles: 'Amount of bottles',
      percentage: 'Percentage',
      typeOfWineStorage: 'Type of wine storage',
      bruised: 'Bruised',
      destemmed: 'Destemmed',
      harvestYear: 'Harvest year',
      washingSulfite: 'Sulfite',
      registrationData: 'Registration data',
    },
    myHarvestSteps: {
      myHarvestSteps: 'My harvest steps',
      myHarvestStepsDescription: 'View or edit your harvest steps',
      key: 'Key',
      nameEN: 'English name',
      type: 'Type of step',
      editHarvestStep: 'Edit harvest step',

    },
    winegrowerDetailAdmin: {
      editWinegrowerDetails: 'Edit winegrower details',
    },
    createBatch: {
      createBatch: 'Create batch',
      createDate: "Create date",
      harvestYear: "Harvest year",
      historic: "Where did this batch originate from?",
      name: "Name of the batch",
      createNewBatch: "Create new batch",
      addGrapeVariety: "Add grape variety",
      grapeVariety: "Grape variety",
      percentage: "Percentage",
      wineStorages: "Wine storages",
      addWineStorage: "Add wine storage",
      wineStorage: "Select type of wine storage",
      liters: "Liters",
    },
    barrelReport: {
      title: "Trace tank or barrel",
      subTitle: "View or track a barrel type"
    },
    myAgriParcels: {
      rowDistance: "Row distance (m)",
      plantDistance: "Plant distance (m)",
    },
    excelImportPage: {
      excelImport: "Importing excel chemicals list",
      source: "Please import list from https://apps.health.belgium.be/fytoweb/pages/public/index.xhtml, choose the correct language, filter the crop on grapevines (wine production) and export.",
      stepsBeforeUpload: "Its important to import the table you just dowloaded in a fresh xslx file. use the Data tab, get data button, from file, from excel workbook. Before importing, you will need to remove any empty rows on the top, select the header row, and if it is not the table header yet, press the button to promote it to table header. Once this is done, save the table and you should be able to upload.",
      save: "Save to firebase",
      preview: "Imported data preview:"
    },
    excelImport: {
      category: "Crops",
      subcategory: "grapevines (wine production)",
      anyCrop: "any crop",
    },
    excelImportKeys: {
      product: "Product",
      number: "Number",
      activeSubstance: "Active Substance",
      authorizationHolder: "Authorisation Holder",
      nature: "Nature",
      formulationType: "Formulation type",
      signalWord: "Signal word",
      hazardPictograms: "Hazard pictograms",
      hazardStatements: "Hazard statements",
      latestDeliveryCertificate: "Delivery most recent certificate",
      initialAuthorizationStart: "Start first authorisation",
      endOfSalesHolder: "End of sale (authorisation holder)",
      endOfSalesDistributor: "End sale (distributor)",
      endOfUse: "End use"
    }
  },
};

export default translation;
