import React, { useState } from 'react';
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import HarvestStockTemplate from '../../templates/OogstAangifte_Template.docx';
import { GovernmentHarvestStockMapping } from './GovernmentHarvestStockMapping';
import MissingDataModal from './MissingDataModal';

function GovernmentHarvestStockReport() {
  const [harvestStockMapping, setHarvestStockMapping] = GovernmentHarvestStockMapping();
  const [showModal, setShowModal] = useState(false);
  const [missingData, setMissingData] = useState({});

  const checkForMissingData = () => {
    let missing = {};
    for (let key in harvestStockMapping) {
      if (!harvestStockMapping[key]) {
        missing[key] = "";
      }
    }
    setMissingData(missing);
    return Object.keys(missing).length;
  };

  const generateReport = () => {
    console.log("Generating report...");
    fetch(HarvestStockTemplate)
      .then(response => response.blob())
      .then(blob => {
        const reader = new FileReader();
        reader.onload = function (event) {
          const content = event.target.result;
          let zip = new PizZip(content);
          let doc = new Docxtemplater().loadZip(zip);
  
          doc.setData(harvestStockMapping);
          doc.render();
  
          const out = doc.getZip().generate({
            type: "blob",
            mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          });
  
          const url = window.URL.createObjectURL(out);
          const a = document.createElement("a");
          a.href = url;
          a.download = "processed.docx";
  
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        };
  
        reader.readAsBinaryString(blob);
      }).catch(error => console.error('Fetch Error:', error));
  };

  const handleReportGeneration = () => {
    if (checkForMissingData() > 0) {
      setShowModal(true);
    } else {
      generateReport();
    }
  };

  const handleModalContinue = (updatedData) => {
    // Merge user-inputted data with the existing data
    setHarvestStockMapping({
      ...harvestStockMapping,
      ...updatedData
    });
    setShowModal(false);
    generateReport();
  };

  return (
    <div>
      <button
        className="ui primary button login"
        style={{ marginTop: "0.75em" }}
        onClick={handleReportGeneration}
      >
        Generate Report
      </button>

      {showModal && (
        <MissingDataModal 
          data={missingData} 
          onContinue={handleModalContinue}
          onCancel={() => setShowModal(false)}
        />
      )}
    </div>
  );
}

export default GovernmentHarvestStockReport;
