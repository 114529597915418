import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { updateWinegrowerDocument } from '../firebase/winegrower';
import { useSessionStorage } from '../logic/useSessionStorage';
import { send } from 'emailjs-com';
import { useAuth } from '../context/AuthContext';
import { PageTitle } from '../utils/PageTitle';

const Complete = (props) => {
  PageTitle("Complete Winegrower");
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const { register, setValue, handleSubmit } = useForm();
  const [isLoading, setLoading] = useState(false);
  const [currentWinery, setCurrentWinery] = useSessionStorage('currentwinery', '');
  const { t } = useTranslation();

  const [toSend, setToSend] = useState({
    email: props.location.state?.email,
    subject: t('vertaling.onderwerp'),
    aanspreking: t('vertaling.aanspreking'),
    message: t('vertaling.message'),
    slot: t('vertaling.slot'),
    afsluiting: t('vertaling.afsluiting'),
    alert: t('translation.emails.moreInfo'),
    land: t('translation.words.belgium'),
  });
  /* */

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      data.winegrower = currentWinery;
      data.userId = currentUser.uid;
      await updateWinegrowerDocument(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);

      send(
        'service_ny3p5ah',
        'template_36ddf8g',
        toSend,
        'user_EDqZc5RPaMddv4DU2Jmmo'
      ).then((response) => {
        // console.log('SUCCESS!', response.status, response.text);
      });

      navigate(`/notconfirmed`);
    }
  };

  const formClassname = `ui big form twelve wide column ${isLoading ? 'loading' : ''
    }`;

  // Het weergeven van alle gegevens van een wijnboer die nog aangevuld moeten worden

  return (
    <div
      className="add-form-container"
      style={{ maxWidth: 960, margin: '50px auto', marginTop: '90px' }}
    >
      <div className="ui grid stackable">
        <form className={formClassname} onSubmit={handleSubmit(onSubmit)}>
          <div className="completeWGField">
            <label>
              {t("vertaling.companyTel")}
              <input type="tel" {...register('phone')} required />
            </label>
          </div>

          <div className="completeWGField">
            <label>
              {t("vertaling.adress")}
              <input type="text" {...register('adress')} required />
            </label>
          </div>

          <div className="completeWGField">
            <label>
              {t("vertaling.city")}
              <input type="text" {...register('city')} required />
            </label>
          </div>

          <div className="completeWGField">
            <label>
              {t("vertaling.companyZip")}
              <input type="number" {...register('zip')} required />
            </label>
          </div>

          <div className="completeWGField">
            <label>
              {t("vertaling.companyCountry")}
              <input type="text" {...register('country')} required />
            </label>
          </div>

          <div className="completeWGField">
            <label>
              {t("vertaling.companyVat")}
              <input type="text" {...register('btw')} required />
            </label>
          </div>

          <div className="completeWGField">
            <label>
              {t("vertaling.companyExcise")}
              <input type="text" {...register('excise')} required />
            </label>
          </div>

          <div className="completeWGField">
            <label>
              {t("vertaling.companyWebsite")}
              <input type="text" {...register('website')} required />
            </label>
          </div>

          <button
            type="submit"
            className="ui submit large grey button right floated"
          >
            {t('vertaling.submit')}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Complete;