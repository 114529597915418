import { getDocs, collection, addDoc, doc, setDoc, updateDoc } from 'firebase/firestore';
import { firestore } from './config';

export const createWineryDocument = async (data) => {
  const vineyardSteps = (await getDocs(collection(firestore, 'vineyardSteps'))).docs.map(doc => doc.data());
  const cellarSteps = (await getDocs(collection(firestore, 'cellarSteps'))).docs.map(doc => doc.data());
  const customStepsSnapshot = await getDocs(collection(firestore, 'customSteps'));
  const customSteps = customStepsSnapshot.docs.map(doc => {
    return {
      id: doc.id,
      data: { ...doc.data() },
    }
  });

  try {
    const docRef = await addDoc(collection(firestore, 'winegrowers'), data);

    for await (const vineyardStep of vineyardSteps) {
      await addDoc(collection(firestore, `winegrowers/${docRef.id}/vineyardSteps`), vineyardStep);
    }

    for await (const cellarStep of cellarSteps) {
      await addDoc(collection(firestore, `winegrowers/${docRef.id}/cellarSteps`), cellarStep);
    }

    for await (const customStep of customSteps) {
      await setDoc(doc(firestore, `winegrowers/${docRef.id}/customSteps/${customStep.id}`), customStep.data);
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateWinegrowerDocument = async (batch) => {
  const docRef = doc(firestore, `winegrowers/${batch.winegrower}`);
  return updateDoc(docRef, batch);
};

export const approveWinegrowerDocument = async (wineDocumentId) => {
  const docRef = doc(firestore, `winegrowers/${wineDocumentId.trim()}`);
  return updateDoc(docRef, {
    active: true,
    approved: true,
  });
};

export const updateWinegrowerProfileDocument = async (id, data) => {
  const docRef = doc(firestore, `winegrowers/${id}`);
  return updateDoc(docRef, data);
};
