import { useEffect, useState } from "react";
import Select from "react-select";
import { useFirestore } from "../../context/FirestoreContext";
import { firestore } from "../../firebase/config";
import { PageTitle } from "../../utils/PageTitle";
import { useTranslation } from 'react-i18next';
import { collection, onSnapshot } from "firebase/firestore";

const SplitBatch = (props) => {
  PageTitle("Split Batch");
  const { currentUserDoc } = useFirestore();
  const [userData, setUserData] = useState("");
  const [winegrowerId, setWinegrowerId] = useState("");
  const [barrels, setBarrels] = useState([]);
  const [batchName, setBatchName] = useState("");
  const [batchBarrel, setBatchBarrel] = useState("");
  const [batchHectoliters, setBatchHectoliters] = useState("");
  const [DeleteOption, setDeleteOption] = useState("");
  var batchId = "";
  const [createdBatchId, setCreatedBatchId] = useState("");
  var batchToCreate = "";
  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setWinegrowerId(currentUserDoc.winegrower);
    setUserData(currentUserDoc);

    // Collectie winegrowers opvragen en daaruit de collectie wijnvaten opvragen op basis van ingelogde wijnboer
    const barrelRef = collection(firestore, "winegrowers", currentUserDoc.winegrower, "wineStorages");

    const unsubscribe = onSnapshot(barrelRef, (querySnapshot) => {
      const barrelList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setBarrels(barrelList);
    });
    return () => unsubscribe();
  }, [currentUserDoc]);


  // Deze functie zorgt ervoor dat een batch wordt opgeslagen zodat deze later nog gebruikt kan worden
  const childFunction = (e) => {
    if (!DeleteOption) {
      e.preventDefault();
      const batchToCreate = { batchName, batchBarrel, batchHectoliters };
      props.functionCallFromParent(batchToCreate);
      setDeleteOption(true);
    }
  };

  // Deze functie zorgt ervoor dat een batch wordt verwijderd 
  const childFunction2 = (e) => {
    if (DeleteOption) {
      e.preventDefault();
      const batchToDelete = { batchName, batchBarrel, batchHectoliters };
      props.functionCallFromParent2(batchToDelete);
      setDeleteOption(false);
    }
  };



  return (
    <div>
      <div className="eight wide field" style={{ margin: "0 auto" }}>
        <input
          required
          type="text"
          placeholder={t('vertaling.create_batch')}
          onChange={(e) => setBatchName(e.target.value)}
        />
      </div>

      <label className="eight wide field">
        <b>{t('vertaling.select_tank')}</b>
        <Select
          required
          placeholder=""
          options={barrels.map((name, id) => ({
            value: name.id,
            label: name.name,
          }))}
          onChange={(selection) => setBatchBarrel(selection.value)}
          className="specialty"
          theme={(theme) => ({
            ...theme,
            borderRadius: 5,
            colors: {
              ...theme.colors,
              primary25: "#dacfa4b2",
              primary: "#AC9E66",
            },
          })}
        />
      </label>

      <div className="eight wide field" style={{ margin: "0 auto" }}>
        <input
          placeholder={t('vertaling.amount_hectoliters')}
          required
          type="text"
          onChange={(e) => setBatchHectoliters(e.target.value)}
        />
      </div>
      {!DeleteOption ? (
        <div>
          <br />
          <b
            onClick={childFunction.bind(this)}
            style={{
              background: "green",
              padding: "5px 10px",
              borderRadius: "15px",
              color: "white",
              cursor: "pointer",
            }}
          >
            <i className="fas fa-check"></i> {t('vertaling.add_batches')}
          </b>
        </div>
      ) : (
        <div>
          <br />
          <b
            onClick={childFunction2.bind(this)}
            style={{
              background: "red",
              padding: "5px 10px",
              borderRadius: "15px",
              color: "white",
              cursor: "pointer",
            }}
          >
            <i className="fas fa-times"></i> {t('vertaling.remove_batch')}
          </b>
        </div>
      )}
      <br />
    </div>
  );
};

export default SplitBatch;
