import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { PageTitle } from "../../utils/PageTitle";
import { firestore } from "../../firebase/config";
import { useSessionStorage } from "../../logic/useSessionStorage";
import GoBackButton from "../../components/FormComponents/GoBackButton";
import { useNavigate } from "react-router-dom";
import { collection, doc, onSnapshot, query } from 'firebase/firestore';
import MaterialTable from '@material-table/core';

const SelectVineyardStep = () => {

    const [vineyardSteps, setVineyardSteps] = useState([]);
    const [currentWinery, setCurrentWinery] = useSessionStorage("currentwinery", "");
    const { i18n } = useTranslation();
    const lang = i18n.language;
    const navigate = useNavigate();
    const { t } = useTranslation();
    let currentLang ='';

    PageTitle('Select Vineyard Step');

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        const vineyardStepColRef = collection(doc(firestore, "winegrowers", currentWinery), "vineyardSteps");

        const unsubscribe = onSnapshot(query(vineyardStepColRef), async (querySnapshot) => {
            const vineyardStepDoc = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data()
            }));
            vineyardStepDoc.sort((a, b) => (a.number > b.number ? 1 : -1));
            setVineyardSteps(vineyardStepDoc);
            setIsLoading(false);
        })
        return () => {
            unsubscribe();
        };
    }, [currentWinery, lang]);

        if (lang === 'en') {
            currentLang = "nameEN";
         } else if (lang === 'fr') {
             currentLang = "nameFR";
         } else if (lang === 'it') {
             currentLang = "nameIT";
         } else if (lang === 'nl') {
             currentLang = "nameNL";
         }
 
    const navigateToRegisterStep = (event, rowData) => {
        let stepId = rowData.id;
        navigate(`/vineyardstepregistation/selectagriculturalparcel/${stepId}`);

    }
    return (
        <div>
            <div style={{ position: "relative", marginTop: "20px" }}>
            <h1 style={{ color: "#7f7f7f" }}>{t('translation.vineyardRegistration.selectVineyardStep')}</h1>
            </div>
            <GoBackButton location={"/"}/>
            <br />
            {isLoading ? <div>Loading...</div> : (
            <div className="batchesList">

                <MaterialTable
                    title=""
                    data={vineyardSteps}
                    onRowClick={navigateToRegisterStep}
                    columns={[{
                        title: t('translation.vineyardSteps.number'),
                        field: "number",
                        sorting: false,
                    }, {
                        title: t('translation.vineyardSteps.stepName'),
                        field: currentLang
                    }]}
                    options={{
                        filtering: true,
                        headerStyle: {
                            backgroundColor: "#cecaca",
                            color: "#FFF",
                            textAlign: "left",
                        },
                        filterCellStyle: {
                            alignContent: "left",
                        },
                        emptyRowsWhenPaging: false,
                        paging: true,
                        pageSize: 50,
                        pageSizeOptions: [
                            10,
                            20,
                            25,
                            50,
                        ],
                    }}
                />

            </div>
            )}
        </div>
    );
}

export default SelectVineyardStep;