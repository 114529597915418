import { Link } from "react-router-dom";

const DashboardButtonWithNumber = ({ url, imgAlt, imgSrc, title, text, number, btnPermissionLevel, userPermissionLevel }) => {
    if ((btnPermissionLevel !== undefined && userPermissionLevel !== undefined) && userPermissionLevel < btnPermissionLevel) return null;

    return (
        <div className="col-lg-4 col-sm-6">
            <Link to={url}>
                <div className="single-service ss6">
                    {number > 0 && (
                        <div className="tile-melding">
                            {number}
                        </div>
                    )}
                    <div className="service-img">
                        <img alt={imgAlt} className="dash-icon" src={imgSrc} />
                    </div>
                    <div className="service-content">
                        <h3>{title}</h3>
                        <p>{text}</p>
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default DashboardButtonWithNumber;