import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import GoBackButton from "../../../components/FormComponents/GoBackButton";
import Spinner from "../../../components/Spinner/Spinner";
import { useFirestore } from "../../../context/FirestoreContext";
import { updateRegistration, deleteRegistration } from '../../../firebase/registrations';
import { useSessionStorage } from "../../../logic/useSessionStorage";
import { PageTitle } from "../../../utils/PageTitle";
import { Timestamp } from 'firebase/firestore';
import "./EditRegistration.css"
import { MTableGroupRow } from "@material-table/core";

const EditRegistration = () => {
    PageTitle("Edit Registration");
    const navigate = useNavigate();
    const { register, handleSubmit } = useForm();
    const [isLoading, setIsLoading] = useState(true);
    const params = useParams();
    const { i18n } = useTranslation();
    const lang = i18n.language;
    const [currentWinery, setCurrentWinery] = useSessionStorage("currentwinery", "");

    const { getRegistration } = useFirestore();
    const [currentRegistration, setCurrentRegistration] = useState(undefined);

    const { getChemicals } = useFirestore()
    const [chemicalsData, setChemicalsData] = useState();

    const { getAgriculturalParcels } = useFirestore();
    const [agriculturalParcels, setAgriculturalParcels] = useState(undefined);

    const { getBatch } = useFirestore();
    const [batch, setBatch] = useState(undefined);

    const { getWineStorages } = useFirestore();
    const [wineStorages, setWineStorages] = useState(undefined);

    const { getHarvestSteps } = useFirestore();
    const [harvestSteps, setHarvestSteps] = useState(undefined);

    const [fytoProducts, setFytoProducts] = useState([]);

    const { t } = useTranslation();

    const { getVineyardSteps } = useFirestore();
    const [vineyardSteps, setVineyardSteps] = useState(undefined);

    const { getCellarSteps } = useFirestore();
    const [cellarSteps, setCellarSteps] = useState(undefined);

    const getRegistrationFromDB = async (regId) => {
        let registration = await getRegistration(currentWinery, regId);
        if (registration === undefined) return navigate('/history');

        setCurrentRegistration(registration);
        setIsLoading(false);
    }

    const getAgriculturalParcelsFromDB = async () => {
        let agriculturalParcelsDB = await getAgriculturalParcels(currentWinery);
        setAgriculturalParcels(agriculturalParcelsDB);
    }

    const getBatchFromDB = async (batchId) => {
        let batchDB = await getBatch(currentWinery, batchId);
        setBatch(batchDB);
    }

    const getWineStoragesFromDB = async () => {
        let wineStoragesDB = await getWineStorages(currentWinery);
        setWineStorages(wineStoragesDB);
    }

    const getHarvestStepsFromDB = async () => {
        setIsLoading(true);
        let harvestStepsDB = await getHarvestSteps(currentWinery);
        setHarvestSteps(harvestStepsDB);
        setIsLoading(false);
    }

    const getVineyardStepsFromDB = async () => {
        setIsLoading(true);
        let vineyardStepsDB = await getVineyardSteps(currentWinery);
        setVineyardSteps(vineyardStepsDB);
        setIsLoading(false);
    }

    const getCellarStepsFromDB = async () => {
        setIsLoading(true);
        let cellarStepsDB = await getCellarSteps(currentWinery);
        setCellarSteps(cellarStepsDB);
        setIsLoading(false);
    }

    useEffect(() => {
        const getChemicalsFromDB = async () => {
            const chemicals = await getChemicals(currentWinery);
            setChemicalsData(chemicals);
        };
        getChemicalsFromDB();
    }, [currentWinery]);

    useEffect(() => {
        if (chemicalsData !== undefined) {
            let fytoProd = []
            chemicalsData.forEach(chemical => {
                fytoProd.push(chemical.product_EN[0]);
            })
            setFytoProducts(fytoProd)
        }
    }, [currentWinery, chemicalsData])

    //DB Calls
    useEffect(() => {
        if (params.id !== "") {
            getRegistrationFromDB(params.id);
        }
    }, [])

    useEffect(() => {
        if (currentRegistration !== undefined && (currentRegistration.type === "cellarStep" || currentRegistration.type === "harvestStep" || currentRegistration.type === "vineyardStep")) {
            if (agriculturalParcels === undefined) getAgriculturalParcelsFromDB();
            if (batch === undefined) getBatchFromDB(currentRegistration.batchId);
            if (wineStorages === undefined) getWineStoragesFromDB();
            if (harvestSteps === undefined) getHarvestStepsFromDB();
            if (vineyardSteps === undefined) getVineyardStepsFromDB();
            if (cellarSteps === undefined) getCellarStepsFromDB();
        }
    }, [currentRegistration]);

    const onSubmit = async () => {
        setIsLoading(true);
        try {
            currentRegistration.date = Timestamp.fromDate(new Date(currentRegistration.date));
            await updateRegistration(currentWinery, params.id, currentRegistration);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
            navigate(`/history`);
        }
    };

    const handleDeleteConfirmation = () => {
        const isConfirmed = window.confirm("Are you sure you want to delete this registration? Weet je zeker dat je deze registratie wilt verwijderen? Sais-tu vraiment supprimer cette inscription?");
        if (isConfirmed) {
            onDelete(); // Call the onDelete function if confirmed
        }
    };

    const onDelete = async () => {
        setIsLoading(true);
        try {
            await deleteRegistration(currentWinery, params.id);
            navigate(`/history`);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const stepContentGroupedByKey = (stepCont) => {
        return stepCont.reduce((acc, curr) => {
            if (!acc[curr.key]) {
                acc[curr.key] = [];
            }
            acc[curr.key].push(curr);
            return acc;
        }, {});
    }

    const handleChange = (fieldKey) => (e) => {
        const inputValue = e.target.value;
        setCurrentRegistration({
            ...currentRegistration,
            [fieldKey]: inputValue,
        });
    };

    const getSelectBox = (content, language, value) => {
        let labelStep;
        let options;
        let fieldKey;
        if (content.length === 1) {
            labelStep = content[0]
            fieldKey = labelStep.key;
            options = fytoProducts;
            return (
                <div className={'field'}>
                    <label>
                        {labelStep[language]}
                        <select defaultValue={value} required onChange={handleChange(fieldKey)}>
                            {options.map((product, index) =>
                                <option key={index} value={product}>
                                    {product}
                                </option>
                            )}
                        </select>
                    </label>
                </div>
            );
        } else {
            labelStep = content.find(step => step.type === "label");
            options = content.filter(step => step.type === "option");
            const fieldKey = labelStep.key;
            return (
                <div className={'field'}>
                    <label>
                        {labelStep[language]}
                        <select defaultValue={value} required onChange={handleChange(fieldKey)}>
                            {options.map((option, index) =>
                                <option key={index} value={option[language]}>
                                    {option[language]}
                                </option>
                            )}
                        </select>
                    </label>
                </div>
            );
        }
    }

    const getLabelStepForm = (content, language) => {
        return (
            <div className={'field'}>
                <label> {content[0][language]}  </label>
            </div >
        );
    }

    const handleBooleanChange = (fieldKey) => (e) => {
        const isChecked = e.target.checked;
        setCurrentRegistration({
            ...currentRegistration,
            [fieldKey]: isChecked ? 1 : 0,
        });
    };

     // Function to format dates according to the user's local time zone
     const formatDateForInput = (date) => {
        if (!date) return '';
        const formattedDate = new Date(date).toISOString().substring(0, 16);
        return formattedDate;
    };

    // Function to parse input date string and convert it to UTC
    const parseInputDate = (inputDate) => {
        if (!inputDate) return null;
        // Parse the input date string as UTC
        const parsedDate = new Date(inputDate + 'Z');
        return parsedDate;
    };

    const handleDateChange = (fieldKey) => (e) => {
        const inputValue = e.target.value;
        const parsedDate = parseInputDate(inputValue);
        setCurrentRegistration({
            ...currentRegistration,
            [fieldKey]: parsedDate,
        });
    };


    const getCheckBox = (content, language, value) => {
        const fieldKey = content[0].key;
        return (
            <div className={'field'}>
                <label> {content[0][language]}
                    <div className="checkbox" style={{ textAlign: 'center' }}>
                        <input
                            type="checkbox"
                            checked={value === 1}
                            onChange={handleBooleanChange(fieldKey)}
                        />
                    </div>
                </label>
            </div>
        );
    }

    const getTextInput = (content, language, value, type) => {
        const fieldKey = content[0].key;
        const formattedValue = type === 'datetime-local' ? formatDateForInput(value) : value;
        return (
            <div className={'field'}>
                <label> {content[0][language]}
                    <input
                        type={type}
                        defaultValue={formattedValue}
                        onChange={handleDateChange(fieldKey)} // Handle date input change
                        style={{ textAlign: "center" }}
                    />
                </label>
            </div >
        );
    }


    const generateStepForm = () => {
        if (currentRegistration !== undefined && (vineyardSteps !== undefined || cellarSteps !== undefined || harvestSteps !== undefined)) {
            let data = [];
            const language = `text${lang.replaceAll('"', '').toUpperCase()}`;
            const stepsMap = {
                'cellarStep': cellarSteps,
                'vineyardStep': vineyardSteps,
                'harvestStep': harvestSteps
            };
            let stepContGroupedByKey = [];
            const steps = stepsMap[currentRegistration.type];
            if (currentRegistration.type === 'harvestStep') { // one layer above for harvestStep because only one step
                stepContGroupedByKey = stepContentGroupedByKey(steps.content)
            } else {
                // get the content of the specific step
                const stepContent = steps.find(step => step.id === currentRegistration.stepId)?.content;
                stepContGroupedByKey = stepContentGroupedByKey(stepContent);
            }

            if (stepContGroupedByKey !== undefined) {
                // Convert the object into an array to sort fields by order
                let stepContGroupedByKeyArray = Object.entries(stepContGroupedByKey);
                stepContGroupedByKeyArray.sort((a, b) => a[1][0].order - b[1][0].order);

                // Get the common keys for translation
                let commonKeys = stepContGroupedByKeyArray.map(([key, _]) => key);

                commonKeys.forEach(key => {
                    if (key !== "location") {
                        let content = stepContGroupedByKey[key];
                        if (content.length > 1) { // means it is a select box
                            data.push(getSelectBox(content, language, currentRegistration[key]))
                        } else {
                            switch (content[0].type) {
                                case "text":
                                    data.push(getTextInput(content, language, currentRegistration[key], "text"))
                                    break;
                                case "multilinetext":
                                    data.push(getTextInput(content, language, currentRegistration[key], "text"))
                                    break;
                                case "decimal":
                                    data.push(getTextInput(content, language, currentRegistration[key], "number"))
                                    break;
                                case "boolean":
                                    data.push(getCheckBox(content, language, currentRegistration[key]))
                                    break;
                                case "timestamp":
                                    let date;

                                    if (!(currentRegistration[key] instanceof Date)) {
                                        if (currentRegistration[key] instanceof Timestamp) {
                                            const seconds = currentRegistration[key].seconds;
                                            const milliseconds = seconds * 1000; 
                                            date = new Date(milliseconds);
                                        } else {
                                            date = new Date(currentRegistration[key]);
                                        }
                                    } else {
                                        date = currentRegistration[key];
                                    }
                                    
                                    // Ensure `date` is a valid Date object
                                    if (!(date instanceof Date) || isNaN(date.getTime())) {
                                        console.log("Invalid date object:", date);
                                    }
                                    let formattedDate = date.toISOString().substring(0, 16);
                                    data.push(getTextInput(content, language, formattedDate, "datetime-local"))
                                    break;
                                case "label":
                                    if (content[0].key === "product") {
                                        data.push(getSelectBox(content, language, currentRegistration[key]))
                                    } else {
                                        data.push(getLabelStepForm(content, language, currentRegistration[key]))
                                    }
                                    break;
                                default:
                                    // handle default case here
                                    break;
                            }
                        }
                    }
                });
            }
            return data;
        }
    }

    const getRegistrationName = () => {
        const languageName = `name${lang.replaceAll('"', '').toUpperCase()}`;
        switch (currentRegistration.type) {
            case 'cellarStep':
                try {
                    return cellarSteps.find(step => step.id === currentRegistration.stepId)?.[languageName];
                } catch (e) {
                    return currentRegistration.name;
                }
            case 'vineyardStep':
                try {
                    return vineyardSteps.find(step => step.id === currentRegistration.stepId)?.[languageName];
                } catch (e) {
                    return currentRegistration.name;
                }
            case 'harvestStep':
                try {
                    return harvestSteps[languageName];
                } catch (e) {
                    return currentRegistration.name;
                }
        }
    }

    const getAgriculturalParcelData = () => {
        let data = [];
        currentRegistration.agriculturalParcels.map(({ id, percentage }, index) => {
            let agParcel = agriculturalParcels.find(parcel => parcel.id === id)

            if (agParcel !== undefined) {
                data.push(
                    <li key={index}>{agParcel.name}: {Math.round(percentage * 100) / 100}%</li>
                );
            } else {
                data.push(
                    <li key={index}>{id}: {Math.round(percentage * 100) / 100}%</li>
                );
            }
        });

        return data;
    }

    const getWineStorageData = () => {
        let data = [];
        currentRegistration.wineStorages.map(({ id, litersForReport }) => {
            if (wineStorages !== undefined) {
                let wineStorage = wineStorages.find(winestorage => winestorage.id === id);

                if (wineStorage !== undefined) {
                    data.push(
                        <li>{wineStorage.name}: {litersForReport}l</li>
                    );
                } else {
                    data.push(
                        <li>{id}: {litersForReport}l</li>
                    );
                }
            }
        });
        return data;
    }


    return <>
        <Spinner isLoading={isLoading} />
        <div style={{marginTop: '50px'}}>
        <GoBackButton />
        </div>
        <div className="add-form-container" style={{ maxWidth: 960, margin: "50px auto" }}>
            
            <span style={{ fontWeight: "bold", fontSize: "1.5em" }}>{t('vertaling.changeRegistration')}</span>
            <br />
            <div style={{ marginTop: "10px", marginBottom: '10px' }}>
            </div>
            <br />
            <button
                    type="button"
                    className="ui delete large red button floated"
                    style={{ marginBottom: "10px" }}
                    onClick={handleDeleteConfirmation}
                >
                    {t("vertaling.delete_reg")}
                </button>

            <form className="ui big form twelve wide column" onSubmit={handleSubmit(onSubmit)}>
                {currentRegistration !== undefined &&
                    <>
                        <div className={'field'}>
                            <span style={{ marginTop: "24px", fontWeight: "bold", fontSize: "1.5em" }}>
                                {getRegistrationName()}
                            </span>
                        </div>
                        <div className={'field'}>
                            <label> {t("vertaling.email")}
                                <input
                                    type="text"
                                    {...register('email')}
                                    defaultValue={currentRegistration.email}
                                    style={{ textAlign: "center" }}
                                    disabled />
                            </label>
                        </div>

                        <div className={'field'}>
                            <label> Type
                                <input
                                    type="text"
                                    {...register('type')}
                                    defaultValue={currentRegistration.type}
                                    style={{ textAlign: "center" }}
                                    disabled />
                            </label>
                        </div>

                        <div className={'field'}>
                            <label> WaitingForReview
                                <input
                                    type="text"
                                    {...register('waitingForReview')}
                                    defaultValue={currentRegistration.waitingForReview}
                                    style={{ textAlign: "center" }}
                                    disabled />
                            </label>
                        </div>

                        <div className={'field'}>
                            <label> Visualisation
                                <input
                                    type="text"
                                    {...register('visualisation')}
                                    defaultValue={currentRegistration.visualisation}
                                    style={{ textAlign: "center" }} />
                            </label>
                        </div>

                    </>
                }

                {currentRegistration !== undefined && generateStepForm()}
                {/* {currentRegistration !== undefined && getAgriculturalParcelData()}
                {currentRegistration !== undefined && getWineStorageData()} */}


                <button
                    type="submit"
                    className="ui submit large grey button right floated"
                    onClick={handleSubmit}
                >
                    {t("vertaling.submit_wijziging")}
                </button>
                
            </form>
        </div>
    </>;
};

export default EditRegistration;