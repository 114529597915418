import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import GoBackButton from '../../components/FormComponents/GoBackButton';
import { doc, onSnapshot } from 'firebase/firestore';
import { firestore } from '../../firebase/config';
import { PageTitle } from '../../utils/PageTitle';
import { useForm } from 'react-hook-form';
import { updateWinegrowerProfileDocument } from '../../firebase/winegrower';


const WinegrowerDetailAdmin = () => {
  PageTitle('Winegrower Profile Admin');
  const { register, handleSubmit } = useForm();
  const [winegrowerInfo, setWinegrowerInfo] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const formClassname = `ui big form twelve wide column ${isLoading ? 'loading' : ''
    }`;

  useEffect(() => {
    const winegrowerRef = doc(firestore, 'winegrowers', params.id);

  const unsubscribe = onSnapshot(winegrowerRef, (doc) => {
      if (doc.exists) {
        const data = doc.data();
        setWinegrowerInfo(data);
        setIsLoading(false);
      }
    });
    return () => {
      unsubscribe();
    };
  }, [params.id]);

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const winegrowerProfile = {
        active: true,
        adress: data.adress,
        approved: true,
        btw: data.btw,
        city: data.city,
        country: data.country,
        excise: data.excise,
        name: data.winegrowername,
        phone: data.phone,
        website: data.website,
        zip: Number(data.zip),
      };
      await updateWinegrowerProfileDocument(params.id, winegrowerProfile);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      navigate(-1);
    }
  };

  return (
    <div>
      <h1>{t("translation.winegrowerDetailAdmin.editWinegrowerDetails")}</h1>
      <GoBackButton />
      <br />

      <div
        className="add-form-container"
        style={{
          maxWidth: 960,
          margin: '50px auto',
          marginTop: '30px',
        }}
      >
        {winegrowerInfo !== undefined && (
          <form
            className={formClassname}
            style={{
              border: '3px solid #B3B3B3',
              marginTop: '50px',
              borderRadius: '50px',
              borderColor: '#ac9e66',
            }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <div
              className="fields"
              style={{ justifyContent: 'center', marginTop: '30px' }}
            >
              <div className="six wide field">
                <label>
                  {t("vertaling.winery")}
                  <input
                    type="text"
                    {...register('winegrowername')}
                    htmlFor="winegrowername"
                    required="required"
                    defaultValue={winegrowerInfo.name} />
                </label>
              </div>

              <div className="six wide field">
                <label>
                  {t("vertaling.companyTel")}
                  <input
                    type="tel"
                    {...register('phone')}
                    htmlFor="phone"
                    defaultValue={winegrowerInfo.phone}
                    required="required" />
                </label>
              </div>
            </div>

            <div className="fields" style={{ justifyContent: 'center' }}>
              <div className="six wide field">
                <label>
                  {t("vertaling.companyAdress")}
                  <input
                    type="text"
                    {...register('adress')}
                    htmlFor="adress"
                    defaultValue={winegrowerInfo.adress}
                    required="required" />
                </label>
              </div>

              <div className="six wide field">
                <label>
                  {t("vertaling.city")}
                  <input
                    type="text"
                    {...register('city')}
                    htmlFor="city"
                    defaultValue={winegrowerInfo.city}
                    required="required" />
                </label>
              </div>
            </div>

            <div className="fields" style={{ justifyContent: 'center' }}>
              <div className="six wide field">
                <label>
                  {t("vertaling.companyZip")}
                  <input
                    type="number"
                    {...register('zip')}
                    htmlFor="zip"
                    defaultValue={winegrowerInfo.zip}
                    required="required" />
                </label>
              </div>

              <div className="six wide field">
                <label>
                  {t("vertaling.companyCountry")}
                  <input
                    type="text"
                    {...register('country')}
                    htmlFor="country"
                    defaultValue={winegrowerInfo.country}
                    required="required" />
                </label>
              </div>
            </div>

            <div className="fields" style={{ justifyContent: 'center' }}>
              <div className="six wide field">
                <label>
                  {t("vertaling.companyVat")}
                  <input
                    type="text"
                    {...register('btw')}
                    htmlFor="btw"
                    required="required"
                    defaultValue={winegrowerInfo.btw} />
                </label>
              </div>

              <div className="six wide field">
                <label>
                  {t("vertaling.companyExcise")}
                  <input
                    type="text"
                    {...register('excise')}
                    htmlFor="excise"
                    required="required"
                    defaultValue={winegrowerInfo.excise} />
                </label>
              </div>
            </div>

            <div className="fields" style={{ justifyContent: 'center' }}>
              <div className="twelve wide field">
                <label>
                  {t("vertaling.companyWebsite")}
                  <input
                    type="text"
                    {...register('website')}
                    htmlFor="website"
                    defaultValue={winegrowerInfo.website}
                    required="required" />
                </label>
              </div>
            </div>

            <div style={{ justifyContent: 'center', paddingBottom: '20px' }}>
              <button
                type="submit"
                className="ui submit large grey button right floated bttn-profile"
                style={{ float: 'none', marginTop: '10px', width: '25%' }}
              >
                {t("vertaling.update_wijnhuis")}
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};
export default WinegrowerDetailAdmin;