import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { firestore } from '../../firebase/config';
import { createBarrel } from '../../firebase/Cellar';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { useSessionStorage } from '../../logic/useSessionStorage';
import { PageTitle } from '../../utils/PageTitle';
import { doc, collection, onSnapshot, query } from 'firebase/firestore';

const CreateBarrel = () => {
    PageTitle("Create Barrel");
    const params = useParams();
    const { register, handleSubmit } = useForm();
    const [isLoading, setLoading] = useState(true);
    const [cellarsList, setCellarsList] = useState([]);
    const [cellarValue, setCellarValue] = useState(null);
    const [isWood, setIsWood] = useState(false);
    const [woodTypeRequired, setWoodTypeRequired] = useState(false);
    const [cellarError, setCellarError] = useState('hidden');
    const [currentCellar, setCurrentCellar] = useState([]);
    const navigate = useNavigate();
    const [currentWinery, setCurrentWinery] = useSessionStorage('currentwinery', '');
    const [barrelType, setBarrelType] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        let unsubscribeCellars;
        let unsubscribeCellar;

        const cellarsRef = collection(firestore, 'winegrowers', currentWinery, 'cellars');
        unsubscribeCellars = onSnapshot(cellarsRef, (querySnapshot) => {
            const cellars = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setCellarsList(cellars);

            setLoading(false);
        });
        const cellarRef = doc(collection(firestore, 'winegrowers', currentWinery, 'cellars'), params.id);
        unsubscribeCellar = onSnapshot(cellarRef, (doc) => {
            let currentCellarDb = doc.data();
            currentCellarDb.id = doc.id;
            setCurrentCellar(currentCellarDb);
        })


        setLoading(false)

        return () => {
            if (unsubscribeCellars) unsubscribeCellars();
            if (unsubscribeCellar) unsubscribeCellar();
        };
    }, []);


    const onSubmit = async (data) => {
        if (cellarValue == null && currentCellar.id === undefined) {
            setCellarError('show');
        } else {
            setCellarError('hidden');
            try {
                setLoading(true);
                if (cellarValue !== null) {
                    data.cellar = cellarValue;
                } else {
                    data.cellar = currentCellar.id;
                }
                if (data.birthdate === '') {
                    delete data.birthdate;
                }
                await createBarrel(data);
            } catch (error) {
                console.error(error);
            } finally {
                navigate(-1);
            }
        }
    };

    const CellarChange = (selection) => {
        setCellarValue(selection.value);
    };
    // const barrelTypeChange = (selection) => {
    //     if(selection.value === 'wood'){
    //         setIsWood(true)
    //     } else {
    //         setIsWood(false);
    //     }
    // }

    const handleTypeChange = (e) => {  // New Function
        const selectedType = e.target.value;
        setBarrelType(selectedType);
    
        if (selectedType === 'wood') {
            setIsWood(true);
            setWoodTypeRequired(true);
        } else {
            setIsWood(false);
            setWoodTypeRequired(false);
        }
    };

    let showWood = `hidden`;
    if (isWood) {
        showWood = `show`;
    }

    const formClassname = `ui big form twelve wide column ${isLoading ? 'loading' : ''
        }`;

    // Het weergeven van alle gegevens om een wijnvat aan te maken

    return (
        <div>

            <div
                className="add-form-container"
                style={{ maxWidth: 960, margin: '50px auto', marginTop: '130px' }}
            >

                <h2 style={{marginTop: '100px', color: '#7f7f7f' }}> {t('translation.barrels.createBarrel')} </h2>
                <br />
                <div className="ui grid stackable" style={{ marginTop: '30px' }}>
                    <form
                        className={formClassname}
                        style={{ margin: 'auto' }}
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <div className="fields">
                            <div className="eight wide field">
                                <label>
                                    {t("vertaling.name")}
                                    <input type="text" {...register('name')} htmlFor="name" required />
                                </label>
                            </div>

                            <div className="eight wide field">
                                <label>
                                    {t("translation.barrels.totalVolume")}
                                    <input
                                        type="number"
                                        {...register('totalVolume')}
                                        htmlFor="totalVolume"
                                        required
                                        step="1"
                                        min="0" />
                                </label>
                            </div>
                        </div>
                        <div className='field'>
                            <label>
                                {t('translation.barrels.numberOfBarrels')}
                                <input
                                    type='number'
                                    {...register('number')}
                                    htmlFor='number'
                                    min='0'
                                    step='1'
                                    required />
                            </label>
                        </div>
                        <div className="fields" style={{ justifyContent: 'center' }}>
                            <div className="sixteen wide field history-table">
                                <label>
                                    {t("vertaling.cellar")}
                                    <Select
                                        placeholder={t('vertaling.selectCellar')}
                                        options={cellarsList.map((name, id) => ({
                                            value: name.id,
                                            label: name.name,
                                        }))}
                                        defaultValue={{
                                            label: currentCellar.name,
                                            value: currentCellar.id
                                        }}
                                        onChange={(selection, action) =>
                                            CellarChange(selection, action)
                                        }
                                        className="specialty"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#dacfa4b2',
                                                primary: '#AC9E66',
                                            },
                                        })}
                                    />
                                </label>
                            </div>
                        </div>

                        <div className="field">
                            <label>
                                {t("vertaling.type_vat")}
                                <select
                                    id="type-select"
                                    className="specialty"
                                    {...register('type')}
                                    onChange={handleTypeChange}
                                    htmlFor="type"
                                    required>
                                    <option value="inox">Inox</option>
                                    <option value="wood">
                                        {t('vertaling.wood')}
                                    </option>
                                    <option value="concrete">
                                        {t('vertaling.typeConcrete')}
                                    </option>
                                    <option value="amfora">Amfora</option>
                                    <option value="egg">Egg</option>
                                    <option value="plastic">Plastic</option>
                                    <option value="glass">Glass</option>

                                </select>
                            </label>
                        </div>

                        <div className="fields">
                            <div className={showWood + ' eight wide field'}>
                                <label>
                                    {t("vertaling.wood")}
                                    <input
                                        {...register('woodtype')}
                                        htmlFor="woodtype"
                                        type="text"
                                        required={woodTypeRequired} />
                                </label>
                            </div>
                            <div className="eight wide field">
                                <label>
                                    {t("vertaling.date")}
                                    <input
                                        type="date"
                                        {...register('birthdate')}
                                        htmlFor="birthdate"
                                        disabled={!isWood} />
                                </label>
                            </div>
                        </div>
                        <div className="eight wide field" style={{ display: 'none' }}>
                            <label>
                                Cellar:
                                <input {...register('winegrower')} htmlFor="winegrower" value={currentWinery} />
                            </label>
                        </div>
                        <button
                            type="submit"
                            className="ui submit large grey button right floated"
                        >
                            {t("vertaling.submit")}
                        </button>
                    </form>

                    <div style={{ width: '100%' }}>
                        <p className={cellarError + ' flex_error_red flex'}>
                            {t("vertaling.errorCellar")}
                        </p>
                    </div>
                </div>
            </div>


        </div>
    );
};

export default CreateBarrel;