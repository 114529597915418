import { doc, setDoc, updateDoc, addDoc, deleteDoc, collection } from "firebase/firestore";
import { firestore } from "./config";


export const createCellarStep = async (winegrower, data) => {
    const colRef = collection(firestore, `winegrowers/${winegrower}/cellarSteps`);
    const cellarStep = {
        ...data,
        number: Number(data.number),
    };
    return await addDoc(colRef, cellarStep);
};

export const createCellarStepContent = async (winegrower, cellarStepId, data) => {
    const docRef = doc(firestore, `winegrowers/${winegrower}/cellarSteps/${cellarStepId}`);
    return await setDoc(docRef, data);
};

export const updateCellarStep = async (winegrower, cellarStepId, data) => {
    const docRef = doc(firestore, `winegrowers/${winegrower}/cellarSteps/${cellarStepId}`);
    return await updateDoc(docRef, data);
};

export const updateContentCellarStep = async (winegrower, cellarStep, data) => {
    const docRef = doc(firestore, `winegrowers/${winegrower}/cellarSteps/${cellarStep}`);
    return await setDoc(docRef, data);
};

export const removeCellarStep = async (winegrower, cellarStepId) => {
    const docRef = doc(firestore, `winegrowers/${winegrower}/cellarSteps/${cellarStepId}`);
    return await deleteDoc(docRef);
};
