import { doc, query, where, onSnapshot, getDoc, collection } from "firebase/firestore";
import { firestore, auth } from '../firebase/config';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import GoBackButton from "../components/FormComponents/GoBackButton";
import { useAuth } from '../context/AuthContext';
import { deleteRegistration, updateRegistration } from '../firebase/registrations';
import { timestampToDate } from '../utils/DateConversions';
import { PageTitle } from '../utils/PageTitle';

const ReviewRegistration = () => {
    PageTitle("Review Registration");
    const navigate = useNavigate();
    const { currentUser } = useAuth();
    const [reg, setReg] = useState([]);
    const [originalReg, setOriginalReg] = useState([]);
    const { register, handleSubmit } = useForm();
    const lang = localStorage.getItem('Lang');
    const params = useParams();
    const [isLoading, setLoading] = useState(false);
    var winegrowerid = '';
    const [winegrower, setWinegrower] = useState('');
    const [vineyardsList, setVineyards] = useState('');
    const [cellarsList, setCellars] = useState('');
    const [cellarStepsList, setCellarStepList] = useState('');
    const [vineyardStepsList, setVineyardStepsList] = useState('');
    const [batchesList, setBatches] = useState('');
    const [wineStoragesList, setWineStorages] = useState('');
    const [bottleTypesList, setBottleTypes] = useState('');
    const [location, setLocation] = useState('');
    const [selectBatch, setSelectBatch] = useState('');
    const [selectSourceBatch, setSelectSourceBatch] = useState('');
    const [selectStorage, setSelectStorage] = useState('');
    const [selectBottleType, setSelectBottleType] = useState('');
    const [regToApprove, setRegsToApprove] = useState('');
    const { t } = useTranslation();

    // Referenties naar firestore database (bv : ophalen van collecties uit firestore db)
    // Alle waarden in de variabelen gaan verloren éénmaal de useEffect volledig uitgevoerd is

    const subscribeToCollection = (collectionRef, setStateFunc) => {
        return onSnapshot(collectionRef, (querySnapshot) => {
            const data = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setStateFunc(data);
        });
    };

    const findNameById = (list, id) => {
        const item = list.find((e) => e.id === id);
        return item ? item.name : id;
    };

    const findStepNameById = (list, id, lang) => {
        const step = list.find((e) => e.id === id);
        return step ? step[`name${lang.replace(/"/g, '')}`] : id;
    };

    const updateRegToApprove = (regToApprove, lists, lang) => {
        const { vineyardsList, cellarsList, bottleTypesList, wineStoragesList, batchesList, vineyardStepsList, cellarStepsList } = lists;

        regToApprove.locationId = vineyardsList.find(v => v.id === regToApprove.locationId)?.name ||
            cellarsList.find(c => c.id === regToApprove.locationId)?.name ||
            regToApprove.locationId;

        regToApprove.bottleTypeId = bottleTypesList.find(b => b.id === regToApprove.bottleTypeId)?.name || regToApprove.bottleTypeId;

        regToApprove.winestorageId = wineStoragesList.find(w => w.id === regToApprove.winestorageId)?.name || regToApprove.winestorageId;

        regToApprove.batchId = batchesList.find(b => b.id === regToApprove.batchId)?.batch || regToApprove.batchId;
        regToApprove.sourceBatchId = batchesList.find(b => b.id === regToApprove.sourceBatchId)?.batch || regToApprove.sourceBatchId;

        if (regToApprove.type === 'vineyardStep') {
            regToApprove.stepId = vineyardStepsList.find(v => v.id === regToApprove.stepId)?.[getLanguageKey(lang)] || regToApprove.stepId;
        } else {
            regToApprove.stepId = cellarStepsList.find(c => c.id === regToApprove.stepId)?.[getLanguageKey(lang)] || regToApprove.stepId;
        }

        return regToApprove;
    };

    const getLanguageKey = (lang) => {
        const langMap = {
            '"en"': 'name',
            '"fr"': 'nameFR',
            '"it"': 'nameIT',
            '"ne"': 'nameNL'
        };
        return langMap[lang];
    };


    useEffect(() => {
        const q = query(collection(firestore, "users"), where("email", "==", currentUser.email));

        const unsubscribeUser = onSnapshot(q, (querySnapshot) => {
            const userLoggedIn = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            winegrowerid = userLoggedIn[0].winegrower;
            setWinegrower(userLoggedIn[0].winegrower);
        });

        const unsubscribeBatch = subscribeToCollection(collection(firestore, 'winegrowers', winegrowerid, 'batches'), setBatches);
        const unsubscribeCellarSteps = subscribeToCollection(collection(firestore, 'cellarsteps'), setCellarStepList);
        const unsubscribeVineyardSteps = subscribeToCollection(collection(firestore, 'vineyardsteps'), setVineyardStepsList)
        const unsubscribeVineyards = subscribeToCollection(collection(firestore, 'winegrowers', winegrowerid, 'vineyards'), setVineyards);
        const unsubscribeCellars = subscribeToCollection(collection(firestore, 'winegrowers', winegrowerid, 'cellars'), setCellars);
        const unsubscribeBottles = subscribeToCollection(collection(firestore, 'bottletypes'), setBottleTypes);
        const unsubscribeStorage = subscribeToCollection(collection(firestore, 'winegrowers', winegrowerid, 'wineStorages'), setWineStorages);

        const regDoc = doc(firestore, 'winegrowers', winegrowerid, 'registrations', params.id);

        getDoc(regDoc)
            .then((snapshot) => {
                setRegsToApprove(snapshot.data());
                setOriginalReg(snapshot.data());
            
                regToApprove.date = timestampToDate(regToApprove.date, "DD MMM YYYY, H:mm:ss");
            
                const lists = {
                  vineyardsList,
                  cellarsList,
                  bottleTypesList,
                  wineStoragesList,
                  batchesList,
                  vineyardStepsList,
                  cellarStepsList
                };
            
                regToApprove = updateRegToApprove(regToApprove, lists, lang);
            
                setReg(regToApprove);
              })
              .catch((e) => console.error(e));

        return () => {
            unsubscribeUser();
            unsubscribeBatch();
            unsubscribeBottles();
            unsubscribeCellarSteps();
            unsubscribeCellars();
            unsubscribeStorage();
            unsubscribeVineyardSteps();
            unsubscribeVineyards();
        };
    }, []);

    const handleLocationChange = (selection) => {
        setLocation(selection.value);
    };

    const handleBatchChange = (selection) => {
        setSelectBatch(selection.value);
    };

    const handleSourceBatchChange = (selection) => {
        setSelectSourceBatch(selection.value);
    };

    const handleStorageChange = (selection) => {
        setSelectStorage(selection.value);
    };

    const handleBottleTypeChange = (selection) => {
        setSelectBottleType(selection.value);
    };

    const onSubmit = async (data) => {
        setLoading(true);
        originalReg.waitingForReview = false;
        if (location !== '') {
            originalReg.locationId = location;
        }
        if (selectBatch !== '') {
            originalReg.batchId = selectBatch;
        }
        if (selectSourceBatch !== '') {
            originalReg.sourceBatchId = selectSourceBatch;
        }
        if (selectBottleType !== '') {
            originalReg.bottleTypeId = selectBottleType;
        }
        if (selectStorage !== '') {
            originalReg.storageId = selectStorage;
        }
        if (location !== '') {
            originalReg.locationId = location;
        }
        if (data.warehouse !== undefined) {
            originalReg.warehouse = data.warehouse;
        }
        if (data.description !== undefined) {
            originalReg.description = data.description;
        }
        if (data.email !== undefined) {
            originalReg.email = data.email;
        }
        if (data.accuracy !== undefined) {
            originalReg.accuracy = data.accuracy;
        }
        if (data.temperature !== undefined) {
            originalReg.temperature = data.temperature;
        }
        if (data.amountOfBottles !== undefined) {
            originalReg.amountOfBottles = data.amountOfBottles;
        }
        if (data.amount !== undefined) {
            originalReg.amount = data.amount;
        }
        if (data.amountDrying !== undefined) {
            originalReg.amountDrying = data.amountDrying;
        }
        if (data.wayOfHarvest !== undefined) {
            originalReg.wayOfHarvest = data.wayOfHarvest;
        }
        if (data.density !== undefined) {
            originalReg.density = data.density;
        }
        if (data.acidity !== undefined) {
            originalReg.acidity = data.acidity;
        }
        if (data.alcohol !== undefined) {
            originalReg.alcohol = data.alcohol;
        }
        if (data.massDensity !== undefined) {
            originalReg.massDensity = data.massDensity;
        }
        if (data.ph !== undefined) {
            originalReg.ph = data.ph;
        }
        if (data.sugars !== undefined) {
            originalReg.sugars = data.sugars;
        }
        if (data.typeOfFermentation !== undefined) {
            originalReg.typeOfFermentation = data.typeOfFermentation;
        }
        if (data.method !== undefined) {
            originalReg.method = data.method;
        }
        if (data.products !== undefined) {
            originalReg.products = data.products;
        }
        if (data.productsUsed !== undefined) {
            originalReg.productsUsed = data.productsUsed;
        }
        if (data.amountUsed !== undefined) {
            originalReg.amountUsed = data.amountUsed;
        }
        try {
            updateRegistration(winegrower, params.id, originalReg);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
            navigate(`/reviewregistrations`);
        }
    };

    const onDelete = () => {
        setLoading(true);
        try {
            deleteRegistration(winegrower, params.id);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
            navigate(`/reviewregistrations`);
        }
    };


    // Het weergeven van alle detailgegevens van een registratie

    return (
        <div>
            <h1>{t("vertaling.approve_registration")}</h1>
            <GoBackButton />
            <br />
            <br />
            <div className="regToApprove">
                {reg !== null >= 1 && (
                    <div
                        className="ui grid stackable"
                        style={{
                            justifyContent: 'center',
                            marginTop: '-90px',
                            width: '100%',
                        }}
                    >
                        <form
                            className="ui big form twelve wide column"
                            style={{
                                marginTop: '100px',
                                overflowX: 'hidden',
                            }}
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <div className="fields" style={{ justifyContent: 'center' }}>
                                <div className="eight wide field">
                                    <label>
                                        <span>{t("vertaling.stap")}</span>
                                        <input
                                            style={{ background: '#E8E8E8' }}
                                            type="text"
                                            {...register('stepId')}
                                            readOnly
                                            defaultValue={reg.stepId} />
                                    </label>
                                </div>
                            </div>

                            {reg.date ? (
                                <div className="fields" style={{ justifyContent: 'center' }}>
                                    <div className="eight wide field">
                                        <label>
                                            <span>{t("vertaling.colDate")}</span>
                                            :{' '}
                                            <input
                                                style={{ background: '#E8E8E8' }}
                                                type="text"
                                                {...register('date')}
                                                readOnly
                                                defaultValue={reg.date} />
                                        </label>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}

                            {reg.locationId ? (
                                <div className="fields" style={{ justifyContent: 'center' }}>
                                    <div className="eight wide field">
                                        <label>
                                            <span>{t("vertaling.location_pdf")}</span>
                                            {reg.type === 'vineyardStep' && (
                                                <Select
                                                    defaultValue={{
                                                        label: reg.locationId,
                                                        value: originalReg.locationId,
                                                    }}
                                                    options={vineyardsList.map((name, id) => ({
                                                        value: name.id,
                                                        label: name.name,
                                                    }))}
                                                    onChange={(selection) =>
                                                        handleLocationChange(selection)
                                                    }
                                                    className="specialty"
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 5,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#dacfa4b2',
                                                            primary: '#AC9E66',
                                                        },
                                                    })}
                                                />
                                            )}
                                            {reg.type === 'cellarStep' && (
                                                <Select
                                                    defaultValue={{
                                                        label: reg.locationId,
                                                        value: originalReg.locationId,
                                                    }}
                                                    options={cellarsList.map((name, id) => ({
                                                        value: name.id,
                                                        label: name.name,
                                                    }))}
                                                    onChange={(selection) =>
                                                        handleLocationChange(selection)
                                                    }
                                                    className="specialty"
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 5,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#dacfa4b2',
                                                            primary: '#AC9E66',
                                                        },
                                                    })}
                                                />
                                            )}
                                        </label>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}

                            {reg.batchId ? (
                                <div className="fields" style={{ justifyContent: 'center' }}>
                                    <div className="eight wide field">
                                        <label>
                                            <span>{t("vertaling.batch_pdf")}</span>
                                            <Select
                                                defaultValue={{
                                                    label: reg.batchId,
                                                    value: originalReg.batchId,
                                                }}
                                                options={batchesList.map((name, id) => ({
                                                    value: name.id,
                                                    label: name.batch,
                                                }))}
                                                onChange={(selection) => handleBatchChange(selection)}
                                                className="specialty"
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 5,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: '#dacfa4b2',
                                                        primary: '#AC9E66',
                                                    },
                                                })}
                                            />
                                        </label>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}

                            {reg.sourceBatchId ? (
                                <div className="fields" style={{ justifyContent: 'center' }}>
                                    <div className="eight wide field">
                                        <label>
                                            <span>{t("vertaling.source_batch")}</span>
                                            :{' '}
                                            <Select
                                                defaultValue={{
                                                    label: reg.sourceBatchId,
                                                    value: originalReg.sourceBatchId,
                                                }}
                                                options={batchesList.map((name, id) => ({
                                                    value: name.id,
                                                    label: name.batch,
                                                }))}
                                                onChange={(selection) =>
                                                    handleSourceBatchChange(selection)
                                                }
                                                className="specialty"
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 5,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: '#dacfa4b2',
                                                        primary: '#AC9E66',
                                                    },
                                                })}
                                            />
                                        </label>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}

                            {reg.warehouse ? (
                                <div className="fields" style={{ justifyContent: 'center' }}>
                                    <div className="eight wide field">
                                        <label>
                                            <span>{t("vertaling.warehouse")}</span>
                                            :{' '}
                                            <input
                                                type="text"
                                                {...register('warehouse')}
                                                htmlFor="warehouse"
                                                defaultValue={reg.warehouse} />
                                        </label>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}

                            {reg.winestorageId ? (
                                <div className="fields" style={{ justifyContent: 'center' }}>
                                    <div className="eight wide field">
                                        <label>
                                            <span>{t("vertaling.wine_storage")}</span>
                                            :{' '}
                                            <Select
                                                defaultValue={{
                                                    label: reg.winestorageId,
                                                    value: originalReg.winestorageId,
                                                }}
                                                options={wineStoragesList.map((name, id) => ({
                                                    value: name.id,
                                                    label: name.name,
                                                }))}
                                                onChange={(selection) => handleStorageChange(selection)}
                                                className="specialty"
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 5,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: '#dacfa4b2',
                                                        primary: '#AC9E66',
                                                    },
                                                })}
                                            />
                                        </label>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}

                            {reg.description ? (
                                <div className="fields" style={{ justifyContent: 'center' }}>
                                    <div className="eight wide field">
                                        <label>
                                            <span>{t("vertaling.beschrijving")}</span>
                                            :{' '}
                                            <input
                                                type="text"
                                                {...register('description')}
                                                htmlFor="description"
                                                defaultValue={reg.description} />
                                        </label>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}

                            {reg.email ? (
                                <div className="fields" style={{ justifyContent: 'center' }}>
                                    <div className="eight wide field">
                                        <label>
                                            <span>{t("vertaling.email")}</span>:{' '}
                                            <input
                                                type="text"
                                                {...register('email')}
                                                htmlFor="email"
                                                defaultValue={reg.email} />
                                        </label>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}

                            {reg.accuracy ? (
                                <div className="fields" style={{ justifyContent: 'center' }}>
                                    <div className="eight wide field">
                                        <label>
                                            <span>{t("vertaling.accuracy")}</span>
                                            <input
                                                type="text"
                                                {...register('accuracy')}
                                                htmlFor="accuracy"
                                                defaultValue={reg.accuracy} />
                                        </label>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}

                            {reg.temperature ? (
                                <div className="fields" style={{ justifyContent: 'center' }}>
                                    <div className="eight wide field">
                                        <label>
                                            <span>{t("vertaling.temperature")}</span>
                                            <input
                                                type="text"
                                                {...register('temperature')}
                                                htmlFor="temperature"
                                                defaultValue={reg.temperature} />
                                        </label>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}

                            {reg.amountOfBottles ? (
                                <div className="fields" style={{ justifyContent: 'center' }}>
                                    <div className="eight wide field">
                                        <label>
                                            <span>{t("vertaling.amountOfBottles")}</span>
                                            <input
                                                type="text"
                                                {...register('amountOfBottles')}
                                                htmlFor="amountOfBottles"
                                                defaultValue={reg.amountOfBottles} />
                                        </label>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}

                            {reg.bottleTypeId ? (
                                <div className="fields" style={{ justifyContent: 'center' }}>
                                    <div className="eight wide field">
                                        <label>
                                            <span>{t("vertaling.bottleTypeId")}</span>
                                            <Select
                                                defaultValue={{
                                                    label: reg.bottleTypeId,
                                                    value: originalReg.bottleTypeId,
                                                }}
                                                options={bottleTypesList.map((name, id) => ({
                                                    value: name.id,
                                                    label: name.name,
                                                }))}
                                                onChange={(selection) =>
                                                    handleBottleTypeChange(selection)
                                                }
                                                className="specialty"
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 5,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: '#dacfa4b2',
                                                        primary: '#AC9E66',
                                                    },
                                                })}
                                            />
                                        </label>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}

                            {reg.amount ? (
                                <div className="fields" style={{ justifyContent: 'center' }}>
                                    <div className="eight wide field">
                                        <label>
                                            <span>{t("vertaling.amount")}</span>
                                            <input
                                                type="text"
                                                {...register('amount')}
                                                htmlFor="amount"
                                                defaultValue={reg.amount} />
                                        </label>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}

                            {reg.amountDrying ? (
                                <div className="fields" style={{ justifyContent: 'center' }}>
                                    <div className="eight wide field">
                                        <label>
                                            <span>{t("vertaling.amountDrying")}</span>
                                            <input
                                                type="text"
                                                {...register('amountDrying')}
                                                htmlFor="amountDrying"
                                                defaultValue={reg.amountDrying} />
                                        </label>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}

                            {reg.wayOfHarvest ? (
                                <div className="fields" style={{ justifyContent: 'center' }}>
                                    <div className="eight wide field">
                                        <label>
                                            <span>{t("vertaling.wayOfHarvest")}</span>
                                            <input
                                                type="text"
                                                {...register('wayOfHarvest')}
                                                htmlFor="wayOfHarvest"
                                                defaultValue={reg.wayOfHarvest} />
                                        </label>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}

                            {reg.density ? (
                                <div className="fields" style={{ justifyContent: 'center' }}>
                                    <div className="eight wide field">
                                        <label>
                                            <span>{t("vertaling.density")}</span>
                                            <input
                                                type="text"
                                                {...register('density')}
                                                htmlFor="density"
                                                defaultValue={reg.density} />
                                        </label>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}

                            {reg.acidity ? (
                                <div className="fields" style={{ justifyContent: 'center' }}>
                                    <div className="eight wide field">
                                        <label>
                                            <span>{t("vertaling.acidity")}</span>
                                            <input
                                                type="text"
                                                {...register('acidity')}
                                                htmlFor="acidity"
                                                defaultValue={reg.acidity} />
                                        </label>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}

                            {reg.alcohol ? (
                                <div className="fields" style={{ justifyContent: 'center' }}>
                                    <div className="eight wide field">
                                        <label>
                                            <span>{t("vertaling.alcohol")}</span>
                                            <input
                                                type="text"
                                                {...register('alcohol')}
                                                htmlFor="alcohol"
                                                defaultValue={reg.alcohol} />
                                        </label>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}

                            {reg.massDensity ? (
                                <div className="fields" style={{ justifyContent: 'center' }}>
                                    <div className="eight wide field">
                                        <label>
                                            <span>{t("vertaling.massDensity")}</span>
                                            <input
                                                type="text"
                                                {...register('massDensity')}
                                                htmlFor="massDensity"
                                                defaultValue={reg.massDensity} />
                                        </label>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}

                            {reg.ph ? (
                                <div className="fields" style={{ justifyContent: 'center' }}>
                                    <div className="eight wide field">
                                        <label>
                                            <span>{t("vertaling.ph")}</span>
                                            <input type="text" {...register('ph')} htmlFor="ph" defaultValue={reg.ph} />
                                        </label>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}

                            {reg.sugars ? (
                                <div className="fields" style={{ justifyContent: 'center' }}>
                                    <div className="eight wide field">
                                        <label>
                                            <span>{t("vertaling.sugars")}</span>
                                            <input
                                                type="text"
                                                {...register('sugars')}
                                                htmlFor="sugars"
                                                defaultValue={reg.sugars} />
                                        </label>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}

                            {reg.typeOfFermentation ? (
                                <div className="fields" style={{ justifyContent: 'center' }}>
                                    <div className="eight wide field">
                                        <label>
                                            <span>{t("vertaling.typeOfFermentation")}</span>
                                            <input
                                                type="text"
                                                {...register('typeOfFermentation')}
                                                htmlFor="typeOfFermentation"
                                                defaultValue={reg.typeOfFermentation} />
                                        </label>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}

                            {reg.method ? (
                                <div className="fields" style={{ justifyContent: 'center' }}>
                                    <div className="eight wide field">
                                        <label>
                                            <span>{t("vertaling.method")}</span>
                                            <input
                                                type="text"
                                                {...register('method')}
                                                htmlFor="method"
                                                defaultValue={reg.method} />
                                        </label>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}

                            {reg.products ? (
                                <div className="fields" style={{ justifyContent: 'center' }}>
                                    <div className="eight wide field">
                                        <label>
                                            <span>{t("vertaling.productsAdded")}</span>
                                            <input
                                                type="text"
                                                {...register('products')}
                                                htmlFor="products"
                                                defaultValue={reg.products} />
                                        </label>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}

                            {reg.productsUsed ? (
                                <div className="fields" style={{ justifyContent: 'center' }}>
                                    <div className="eight wide field">
                                        <label>
                                            <span>{t("vertaling.productUsed")}</span>
                                            <input
                                                type="text"
                                                {...register('productsUsed')}
                                                htmlFor="productsUsed"
                                                defaultValue={reg.productsUsed} />
                                        </label>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}

                            {reg.amountUsed ? (
                                <div className="fields" style={{ justifyContent: 'center' }}>
                                    <div className="eight wide field">
                                        <label>
                                            <span>{t("vertaling.amountOfProductUsed")}</span>
                                            <input
                                                type="text"
                                                {...register('amountUsed')}
                                                htmlFor="amountUsed"
                                                defaultValue={reg.amountUsed} />
                                        </label>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}

                            <button type="submit" className="ui button">
                                <b>{t("vertaling.approve")}</b>
                            </button>

                            <button className={`ui button`} onClick={onDelete}>
                                <b>{t("vertaling.colDelete")}</b>
                            </button>
                        </form>
                    </div>
                )}
            </div>
            <br />
        </div>
    );
};

export default ReviewRegistration;