import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '../../utils/PageTitle';
import "./PageNotFound.css";

const PageNotFound = () => {
  PageTitle("Not Found");
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div id="message">
      <h2>404</h2>
      <h1>{t("translation.pageNotFound.title")}</h1>
      <p>{t("translation.pageNotFound.body")}</p>
      <button className="approveBtn" onClick={() => navigate(-1)}>
        {t("vertaling.goback")}
      </button>
    </div>
  )
}

export default PageNotFound