import React from 'react'
import { useFirestore } from '../../context/FirestoreContext';
import { useNavigate } from "react-router-dom";

const ResetVineyardStepsConfirmation = () => {
  const { resetVineyardSteps } = useFirestore();
  const navigate = useNavigate();

  const buttonClicked = async () => {
    await resetVineyardSteps();
    navigate("/vineyardsteps/myvineyardsteps");
  }

  return (
    <div className="container" style={{marginTop: 50}}>
      <h2>Are you sure you want to reset the VineyardSteps? This action is not reversable. ID's of the VineyardSteps will change!</h2>
      <button onClick={buttonClicked} className="ui submit large red button right floated bttn-profile" style={{ float: 'none', width: '250px' }}>
        Reset VineyardSteps
      </button>
    </div>
  )
}

export default ResetVineyardStepsConfirmation