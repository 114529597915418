import { firestore } from "./config";
import { doc, addDoc, updateDoc, Timestamp, collection } from "firebase/firestore";


// Functie voor het aanmaken van een wijnvat

export const createBarrel = async (barrel) => {
    // get a reference to the firestore document
    const docRef = collection(firestore, 'winegrowers', barrel.winegrower.trim(), 'wineStorages');

    const newBarrel = {
        name: barrel.name,
        type: barrel.type,
        totalVolume: Number(barrel.totalVolume),
        dateFirstUsed: barrel.birthdate ? Timestamp.fromDate(new Date(barrel.birthdate)) : null,
        woodtype: barrel.woodtype,
        cellarId: barrel.cellar.trim(),
        number: Number(barrel.number),
        endDate: null,
      };

      return addDoc(docRef, newBarrel);
};

export const updateBarrel = async (barrel, barrelId, winegrower) => {
    const docRef = doc(firestore, 'winegrowers', winegrower, 'wineStorages', barrelId);

    const barrelBirthDate = (barrel.birthdate !== undefined && barrel.birthdate !== null && barrel.birthdate !== "") ? Timestamp.fromDate(new Date(barrel.birthdate)) : null;

    const updatedBarrel = {
        name: barrel.name,
        type: barrel.type,
        totalVolume: Number(barrel.totalVolume),
        dateFirstUsed: barrelBirthDate,
        woodtype: barrel.woodtype,
        cellarId: barrel.cellarId.trim(),
        number: Number(barrel.number),
        endDate: null,
    };

    return updateDoc(docRef, updatedBarrel);
}

// Functie voor het aanpassen van een wijnvat

export const updateBarrelDocument = async (barrel) => {
    const docRef = doc(firestore, 'winegrowers', barrel.winegrower, 'wineStorages', barrel.uid);
  
    const updatedBarrel = {
      ...barrel,
      volume: Number(barrel.volume),
      dateFirstUsed: Timestamp.fromDate(new Date(barrel.birthdate))
    };
  
    return updateDoc(docRef, updatedBarrel);
  };

// Functie voor het aanmaken van een wijnkelder

export const createCellar = async (cellar) => {
    // get a reference to the firestore document
    const docRef = collection(firestore, 'winegrowers', cellar.winegrower.trim(), 'cellars');

    const newCellar = {
        name: cellar.name,
        adress: cellar.adress,
        city: cellar.city,
        winegrower: cellar.winegrower
    };

    addDoc(docRef, newCellar).then((docRef) => {
        updateCellar({
            ...newCellar,
            id: docRef.id
        });
    }).catch((error) => {
        console.error("Error adding document: ", error);
    });
};

// Functie voor het aanpassen van een wijnkelder

export const updateCellar = async (cellar) => {
    const docRef = doc(firestore, `winegrowers/${cellar.winegrower}/cellars/${cellar.id}`);
    return await updateDoc(docRef, cellar);
};

// Functie voor het deactiveren van een wijnkelder

export const deactivateBarrel = async (winegrower, barrel) => {
    // get a reference to the firestore document
    const docRef = doc(firestore, 'winegrowers', winegrower, 'wineStorages', barrel.id);

    return updateDoc(docRef, {
        endDate: Timestamp.fromDate(new Date()),
      });
};