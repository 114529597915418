// Vertaaltabel met alle vertalingen bestaande uit een sleutel en de bijhorende waarde

const translation = {
  vertaling: {
    new_vineyard: 'Créer un vignoble',
    select_batch: 'Sélectionnez un lot',
    name: 'Nom',
    grapevariety_vineyard: 'Cépage',
    size: 'Surface (ha)',
    pruningmethod: "Méthode d'élagage",
    sunorientation: 'Orientation du soleil',
    height: 'Hauteur',
    soil: 'Sol',
    adress: 'Adresse',
    city: 'Ville',
    gpslocation: 'localisation GPS',
    latitude: 'Latitude',
    longtitude: 'Longitude ',
    checklocation: "Vérifier l'emplacement",
    vineyard1: 'Vignoble',
    createvineyard: 'Créer vignoble',
    selectpruningmethod: "Sélectionner la méthode d'élagage ",
    selectsunorientation: "Sélectionnez l'orientation du soleil ",
    myvineyards: 'Mes vignobles',
    details: 'Détails',
    colSize: 'Surface (ha)',
    vineyardInfo: 'Informations sur le vignoble ',
    updatevineyard: 'Mettre à jour un vignoble',
    explainVineyardDetailUI: 'Cliquez sur une rangée de vignobles pour voir les parcelles agricoles/les cépages de ce vignoble',
    title_activate: 'Activer cet utilisateur ?',
    title_registratie: 'Inscription des vignerons',
    companyName: 'Nom de la compagnie ',
    companyAdress: 'Adresse de la compagnie ',
    companyCountry: 'Pays de la compagnie ',
    companyCity: 'Ville de la compagnie ',
    companyZip: 'Code postal de la compagnie ',
    companyTel: 'Numéro de téléphone de la compagnie ',
    companyVat: "Numéro de TVA de l'entreprise ",
    companyExcise: "Numéro d'accises de la compagnie ",
    companyWebsite: 'Site web de la compagnie ',
    title_activate_winegrower: 'Approuver des vignerons',
    add_winegrower: 'Ajouter un vigneron',
    colWinegrower: 'Vigneron',
    title_addemployee: 'Accès interdit',
    title_gebruikersgegevens: "Données d'utilisateur",
    role: 'Rôle',
    password: 'Mot de passe',
    optie2: 'Employé',
    submit: 'Sauvegarder',
    title_add_user: "Activer l'employé",
    toevoegen_werknemer: 'Ajouter un employé',
    title_await: 'Attendre La Confirmation',
    title_tankInfo: 'Modifier cuve',
    volume_vat: 'Volume de la cuve (l)',
    type_vat: 'Type',
    typeConcrete: 'Béton',
    wood: 'Bois',
    complete_employee: 'Complétez votre nouveau compte ou déconnectez-vous',
    type_wood: 'Type de bois',
    date: 'Date de naissance',
    title_batches: 'Mes Lots',
    batchNotInUse: "Ce lot n'est pas encore utilisé",
    add_batches: 'Créer un lot',
    add_batch: 'Lot',
    colCreatieDatum: 'Date de création',
    colCreatedBy: 'Créé par',
    colHectoliters: 'Hectolitres',
    colHistoric: 'Historique',
    colAvailable: 'Disponible',
    optie_juist: 'vrai',
    optie_fout: 'faux',
    title_batchEdit: 'Modifier le lot',
    submit_batchEdit: 'Sauvegarder les modifications',
    colNoAvailable: 'Indisponible',
    step1: 'Étape 1 ',
    step1Text: 'Ajoutez un vignoble ici',
    step2: 'Étape 2 ',
    step2Text: 'Ajoutez une cave ici',
    step3: 'Étape 3 ',
    step3Text: 'Ajoutez une cuve ici',
    error: 'Veuillez compléter toutes les étapes !',
    error_required: 'Veuillez remplir tous données',
    errorPruningMethod: 'Choisissez une méthode',
    errorOrientation: 'Sélectionnez l\'orientation du soleil',
    errorCellar: 'Sélectionnez une cave',
    title_createTank: 'Nouveau cuve',
    cellar: 'Cave',
    harvest: 'Récolte',
    winegrower: 'Vigneron',
    selectCellar: 'Étape de cave',
    title_createBatch: 'Nouveau Lot',
    batch_name: 'Nom du lot',
    batch_hectoliters: 'Hectolitres',
    title_createCellar: 'Nouvelle cave',
    title_dashboard: 'Tableau De Bord',
    h2_dashboard: 'Liste des caves',
    p2_dashboard: 'Voir toutes les caves enregistrées',
    h3_dashboard: 'Mon Profil',
    p3_dashboard: 'Affichez ou modifiez votre profil.',
    selectWinegrower: 'Sélectionnez Vigneron :',
    selectWineryPrompt: 'Sélectionnez un vigneron dans la liste ci-dessous.',
    selectWinery: 'Sélectionnez vigneron',
    h4_dashboard: 'Mon profil de cave',
    p4_dashboard: 'Afficher ou modifier vos employés',
    h5_dashboard: 'Mes Employés',
    p5_dashboard: 'Afficher ou personnaliser votre profil',
    p6_dashboard: 'Approuver ou ajouter un nouvel employé',
    p7_dashboard: 'Affichez ou modifiez vos vignobles.',
    h8_dashboard: 'Mes Caves',
    p8_dashboard: 'Affichez ou modifiez vos caves.',
    p9_dashboard: 'Affichez ou modifiez vos lots.',
    h10_dashboard: 'Histoire',
    p10_dashboard: "Consultez l'historique de vos inscriptions.",
    h11_dashboard: 'Rapport',
    p11_dashboard: 'Consultez les rapports sur vos enregistrements',
    h14_dashboard: 'Invitations de caves',
    p14_dashboard: 'Accepter nouvelles caves',
    h15_dashboard: 'Importer des protecteurs de cultures',
    p15_dashboard: 'Importer une liste de protecteurs de cultures',
    changeRegistration: "Modifier l'enregistrement",
    submit_wijziging: 'Sauvegarder les modifications',
    delete_reg: 'Supprimer l\'enregistrement',
    gotoapp:
      "Veuillez vous connecter à l'application pour utiliser votre compte.",
    title_history: 'Mon Histoire',
    selectBatch: 'Sélectionner un lot',
    vineyard_steps: 'Étapes de vignoble',
    harvest_steps: 'Étapes de récolte',
    cellar_steps: 'Étapes de cave',
    rangeDatum: 'Filtrer sur la date ',
    stap: 'Étape',
    typeStap: "Type d'étape",
    registratiedatum: "Date d'enregistrement",
    beschrijving: 'Description',
    locatie: 'Localisation',
    title_login: "Vous n'avez pas de compte ?",
    error1_login: 'Email ou mot de passe est incorrect, veuillez réesseyer',
    error2_login: 'Veuillez entrer votre email et mot de passe',
    forgotpsw: "Oups, j'ai oublié mon mot de passe",
    title_tanks: 'Mes cuves et barriques',
    add_tank: 'Nouveau cuve',
    colType: 'Type',
    colContent: 'Contenu',
    colVolume: 'Volume (l)',
    colDate: 'En service à partir de',
    colDelete: 'Supprimer',
    add_cellar: 'Nouvelle cave',
    cfpassword: 'Confirmez le mot de passe',
    winery_name: 'Nom du vigneron',
    create_winegrower: 'Créer!',
    title_profile: 'Votre profil',
    title2_profile: 'Votre profil de vigneron',
    winery: 'Nom du vignoble ',
    controlvineyards: 'Vérifiez mes vignobles',
    controlcellars: 'Vérifier mes caves',
    controlemployees: 'Vérifier mes employés',
    update_profile: 'Mise à jour du profil',
    title_reset: 'Réinitialiser le mot de passe',
    succes_reset:
      'Vérifiez votre boîte de réception pour de nouvelles instructions',
    error_reset: 'Échec de la réinitialisation du mot de passe',
    placeholder_reset: 'Votre courriel',
    winegrower_signup: "S'inscrire comme vigneron",
    employee_signup: "S'inscrire comme employé",
    signin_signup: "Vous disposez déjà d'un compte",
    signup: "S'inscrire",
    errorFirstname: 'Veuillez saisir votre prénom.',
    errorLastname: 'Veuillez saisir votre nom.',
    errorEmail: 'Veuillez entrer une adresse e-mail.',
    errorPsw: 'Veuillez entrer un mot de passe.',
    errorWinery1: 'Veuillez entrer un nom de vignoble.',
    errorWinery2:
      "L'établissement vinicole n'existe pas ou n'est pas encore approuvé.",
    errorChoose: "Choisissez l'une des options suivantes.",
    errorPswMatch: 'Vos mots de passe ne correspondent pas.',
    actief_userprofile: 'Actif',
    inactief_userprofile: 'Inactif',
    update_gebruiker: 'Mise à jour utilisatuer',
    title_winegrowers: 'Vos Vignobles Enregistrés',
    update_wijnhuis: 'Mise à jour du vignoble',
    update_wijnboer: 'Mise à jour vigneron',
    goback: 'Retourner',
    approve: 'Approuver',
    activate: 'Activer',
    firstname: 'Prénom',
    lastname: 'Nom de famille',
    email: 'Courriel',
    onderwerp: "Craft Your Taste – Confirmation d'enregistrement",
    aanspreking:
      "Merci de votre inscription! C'est agréable d'entendre que vous souhaitez faire partie de la communauté Craft Your Taste!",
    message:
      'Nous mettons tout en œuvre pour confirmer votre inscription dans les plus brefs délais. Notre équipe examine actuellement vos coordonnées et vous donnera accès à votre profil une fois que tous les détails auront été vérifiés. Après tout, nous voulons que ce portail Web soit utilisé uniquement par des professionnels afin de garantir la qualité de la communauté.',
    slot: "Après approbation, vous recevrez également un lien pour télécharger l'application Craft Your Taste pour smartphone (IOS et Android).À bientôt!",
    afsluiting: 'Team Craft Your Taste – par Tastefever',
    moduleMaintenance: 'Ce module est en maintenance',

    //bevestigingsmail als werknemer toegevoegd is aan winery
    app_link_android:
      'https://play.google.com/store/apps/details?id=be.exanteit.tastefever',
    app_link_ios:
      'https://apps.apple.com/be/app/craft-your-taste-by-tastefever',
    employeeAdded_message:
      " était ajuter comme employé. Vous pouvez voir tous vos employés à 'Mes employés'.",
    employeeApproved_message:
      " était approuvé. Vous pouvez voir tous vos employés à 'Mes employés'.",

    inlog: 'Ce sont vos informations de connexion',
    onderwerp_1: 'Activation du profil Craft Your Taste',
    message1:
      "L'administrateur de votre entreprise vous a maintenant donné accès à votre profil. ",
    message2:
      "Désormais, vous pouvez vous connecter à notre application avec les informations de connexion que vous avez reçues de votre administrateur. Vous pouvez télécharger l'application Craft your Taste depuis l'App Store d'Apple ou le Google Play Store et commencer à utiliser l'application. Si vous avez d'autres questions, vous pouvez contacter l'administrateur de votre entreprise.",
    message3: 'Suivez ce lien pour réinitialiser votre mot de passe pour votre',
    message4: 'compte',
    label1: 'Courriel',
    label2: 'Mot de passe',
    slot_1: 'Bonne chance!',

    begin:
      'Notre équipe a vérifié votre inscription et nous vous avons maintenant donné accès à votre profil. ',
    message_1:
      'À partir de maintenant, vous pouvez vous connecter à notre portail avec les informations de connexion que vous avez choisies. Aller à  ',
    message_2:
      "et connectez-vous pour compléter votre profil et remplir tous les détails de votre entreprise. Lorsque toutes les données sont enregistrées en ligne, vous pouvez commencer à utiliser l'application et vous pouvez commencer à enregistrer l'ensemble de votre processus de production. ",
    message_3:
      "Notre équipe reste disponible si vous avez besoin d'aide en utilisant les détails ci-dessous. ",

    login_nav: 'Login',
    signup_nav: "S'inscrire",
    welcome_nav: 'Bienvenue',
    logout_nav: 'Sortir',

    bericht_mail: 'Message',

    pdftitle1: 'Enregistrements du ',
    pdftitle2: ' au  ',
    amount: 'Quantité: ',
    batch: 'Lot: ',
    location_pdf: 'Localisation: ',
    amountDrying: 'Quantité de séchage: ',
    warehouse: 'Entrepôt: ',
    harvestMethod: 'Méthode de récolte: ',
    tank: 'Cuve: ',
    accuracy: 'Précision: ',
    density: 'Densité (g/ml): ',
    temperature: 'Température (°C): ',
    acidity: 'Acidité: ',
    alcohol: 'Alcool: ',
    massDensity: 'Densité de masse: ',
    ph: 'PH: ',
    sugars: 'Sucres: ',
    fermentationType: 'Type de fermentation: ',
    productsAdded: 'Produits ajoutés: ',
    method: 'Méthode: ',
    amountOfBottles: 'Quantité de bouteilles: ',
    bottletype: 'Type de bouteille: ',
    productUsed: 'Produit utilisé: ',
    amountOfProductUsed: 'Quantité de produit utilisée: ',
    registrationDate: "Date d'enregistrement: ",

    emptyDataSourceMessage: "Pas d'enregistreent à afficher",
    addTooltip: 'Ajouter',
    deleteTooltip: 'Supprimer',
    editTooltip: 'Editer',
    filterTooltip: 'Filtrer',
    deleteText: 'Voulez-vous supprimer cette ligne?',
    cancelTooltip: 'Annuler',
    placeholder: "Tirer l'entête ...",
    groupedBy: 'Grouper par:',
    actions: 'Actions',
    labelDisplayedRows: '{from}-{to} de {count}',
    labelRowsSelect: 'lignes',
    labelRowsPerPage: 'lignes par page:',
    firstAriaLabel: 'Première page',
    previousAriaLabel: 'Page précédente',
    nextAriaLabel: 'Page suivante',
    lastAriaLabel: 'Dernière page',
    addRemoveColumns: 'Ajouter ou supprimer des colonnes',
    nRowsSelected: '{0} ligne(s) sélectionée(s)',
    showColumnsTitle: 'Voir les colonnes',
    exportTitle: 'Exporter',
    exportName: 'Exporter en CSV',
    searchTooltip: 'Chercher',
    exportPDFName: 'Exporter en PDF',

    /*Report*/

    placeholder1_report: "Sélectionnez le type d'étape: ",
    type_report: 'Sélectionnez un type: ',
    cellartype_report: 'Étape du sous-sol',
    vineyardtype_report: 'Étape du vignoble',
    day_report: 'Toutes les inscriptions de ce jour',
    placeholder2_report: 'Sélectionnez une étape: ',
    title_report: "Rapport d'inscription",
    colStorage_report: 'Stockage',
    refill_report: 'Quantité de recharge',
    output_report: 'Quantité de sortie   ',
    choose_period: 'Choisir la période ',
    report_titleh1: "Rapport d'inscription ",
    on: 'au',
    in: 'dans',
    amount_report: 'Quantité ',
    temperature_report: 'Température (°C) ',
    density_report: 'Densité (g/ml) ',
    drying_amount: 'Quantité à sécher',
    location_report: 'Localisation',
    warehouse_report: 'Êntrepot',
    harvest_method_report: 'Méthode de récolte',
    productUsed_report: 'Produits utilisés ',
    amountOfProductUsed_report: 'Nombre de produits utilisés ',
    alcohol_report: "L'alcool ",
    ph_report: 'PH ',
    sugars_report: 'Sucre ',
    acidity_report: 'Acidité ',
    method_report: 'Méthode ',
    bottletype_report: 'Type de bouteille ',
    amountOfBottles_report: 'Nombre de bouteilles ',
    fermentationType_report: 'Type de fermentation ',
    massDensity_report: 'Densité de masse ',
    source_batch_report: 'Lot source ',
    product_amount_report: 'Nombre de produits',

    /*Steps */

    Trainingandtyingbranches: 'Former et attacher des branches ',
    Soilmaintenance: 'Entretien des sols ',
    Ripeness: 'Maturité ',
    Crushingformaceration: 'Concassage pour macération',
    Foliage: 'Feuillage ',
    Destemming: 'Egrappage ',
    Pruning: 'Taille ',
    MaintenanceProtectionvineyard: 'Entretien / Protection du vignoble ',
    Greenharvest: 'Récolte verte ',
    Priming: 'Amorçage ',
    Other: 'Autre',
    Drying: 'Séchage ',
    CrushingPressingwithoutmaceration: 'Concassage / Pressage sans macération ',
    Harvest: 'Récolte',
    Fermentation: 'Fermentation',
    Agingbottle: 'Vieillissement en bouteille',
    Stabilisation: 'Stabilisation',
    Decantingandtreatments: 'Décantation et traitements',
    Assemble: 'Assemblage',
    Aeration: 'Aération',
    Rackingandtreatments: 'Rayonnages et traitements',
    Split: 'Séparation',
    Clarification: 'Clarification',
    Certification: 'Certification',
    Bottling: 'Embouteillage',
    Productaddition: 'Ajout de produit',
    Otherpractiquesandtreatments: 'Autres pratiques et traitements',
    Transfer: 'Transfert',
    Acidification: 'Acidification',
    Agingbarrel: 'Vieillissement en baril',
    Pressingaftermaceration: 'Pressage après macération',
    Overpumping: 'Sur pompage ',
    Clearbarreltank: 'Baril / réservoir clair ',
    Labelling: 'Étiquetage ',
    Otheraging: 'Autre vieillissement ',
    Malolacticfermentation: 'Fermentation malolactique',
    Secondfermentation: 'Seconde fermentation',
    Maceration: 'Macération',

    /*Methods*/

    Pergola: 'Pergola',
    Doublecordon: 'Double cordon',
    Basket: 'Panier',
    Singlecordon: 'Cordon unique',
    Goblet: 'Gobelet',
    other: 'Autre',
    Lyre: 'Lyre',
    Singleguyot: 'Guyot célibataire ',
    Scotthenry: 'Scott henry',
    Doubleguyot: 'Guyot double',
    Genevadoublecurtain: 'Rideau double Genève ',
    southeast: 'Sud-est',
    north: 'Nord',
    south: 'Sud',
    northwest: 'Nord-Ouest',
    southwest: 'Sud-Ouest',
    west: 'Ouest',
    northeast: 'Nord-est',
    east: 'Est',
    unspecified: 'Indéfini',

    //My History
    current_batch_history: 'Lot actuel ',

    /*Trace batch */
    trace_batch: 'Suivre un lot',
    trace_h1: 'Afficher ou suivre un lot',
    source_batch: 'Lot source: ',
    current_batch: 'Lot actuel: ',
    wine_storage: 'Stockage du vin ',
    trusted_regis: 'Approuvé pour les inscriptions ',
    optie_yes: 'oui',
    optie_no: 'non',
    no_batch_registrations: 'Il n\'y a aucun enregistrement pour ce lot',

    /*Trace vineyard */
    trace_vineyard: 'Suivre un vignoble',
    trace_vineyard_h1: 'Afficher ou suivre un vignoble',
    open_registrations: 'Ouvrir tous les enregistrements',
    crop_filter: 'Filtrer les enregistrements liés à la protection des terres, la fertilisation et le traitement des biostimulants',
    no_vineyard_registrations: 'Il n\'y a pas de vignoble à afficher',

    /*Toevoegen registratie*/
    title_addregis: 'Ajouter une inscription ',
    p_regis: 'Ajouter une inscription ',
    cellar_regis: 'Enregistrement de la cave ',
    vineyard_regis: 'Enregistrement du vignoble ',
    select_vineyard: 'Sélectionnez vignoble',
    register: 'Enregistrement',
    select_vineyardstep: 'Sélectionnez un étape de vignoble',
    title_regis: 'Créer un nouvel enregistrement de vignoble ',
    product: 'Produit',
    products: 'Produits',
    type_of_fermentation: 'Choisis le type de fermentation',
    create_batch: 'Crée un nouveau lot',
    method_used: 'Méthode utilisée',
    amount_used: 'Quantité utilisée',
    amount_hectoliters: "Nombre d'hectolitres",
    refill_amount: 'Quantité de remplissage',

    /* Goedkeuren registratie */

    approve_registration: "Approuver l'inscription ",
    approve_registrations: 'Inscriptions à approuver',
    trusted: 'Approuvé pour les inscriptions ',

    /* Split batches */
    select_tank: 'Sélectionnez navire / réservoir*',
    remove_batch: 'Supprimer le lot ',
    create_new: 'Créer un nouveau',
    regisforcellar: 'enregistrement pour cave ',
    regisforvineyard: 'enregistrement au vignoble ',
    onBarrel: 'Sur canon',
    onBottle: 'Sur bouteille',
    manually: 'Manuellement ',
    select_cellarstep: 'Sélectionnez une étape de cave',
    select_sourcebatch: 'Sélectionnez une lot source*',
    select_countbatch: 'En combien de nouveaux lots voulez-vous diviser ?*',
    selectbottletype: 'Sélectionnez un type de bouteille*',
    selectcellarandcellarstep: 'Sélectionnez une cave et une étape de cave',
    selectvineyardandvineyardstep:
      'Sélectionnez un vignoble et une étape de vignoble ',
    batchdrying: 'Sélectionnez le lot (séchage) ou créez un nouveau lot* ',
    selectLocation: 'Sélectionnez un emplacement ',
    waiting_for_approval: "Il y a des inscriptions en attente d'approbation,",
    click_here: 'Cliquer ici',
    to_approve_it: "l'approuver  ",
    finish: 'Terminer ',

    /*Merge*/
    merged: 'Fusionné à partir de : ',
    source_tank: "Canon / réservoir d'origine*",
    destination_batch: 'Baril / réservoir de destination ',
    into: 'dans',

    vineyard_grapevarieties: 'Cépages',
  },

  translation: {
    myParcels: 'Mes parcelles cadastrales',
    myParcelsDescription: 'Afficher ou modifier vos parcelles agricoles',
    createParcel: 'Créer une parcelle cadastrale',
    parcelNumber: 'Numéro de la parcelle',
    landRegistrationNumber: 'Numéro de cadastre',
    goback: 'Retourner',
    name: 'Nom',
    greenArea: 'Surface incluant la zone verte/tampon (ha)',
    number: 'Numéro',
    none: 'Aucun',
    colSizeCa: 'Surface (ca)',
    colSize: 'Surface',
    grapeVariety: 'Cépage',
    grapeVarietyOverview: "Vue d'ensemble des variétés de raisins",
    agriculturalParcelExplanation: 'Pour chaque vignoble, vous pouvez avoir une ou plusieurs variétés de raisins connectées à différentes parcelles agricoles.',
    numberOfPlants: 'Nombre de plantes',
    areaPlanted: 'Zone plantée',
    agriculturalParcel: 'Parcelle agricole / cépage',
    parcelInfo: 'Information sur la parcelle cadastrale',
    agriculturalRegion: 'Région agricole',
    zipCode: 'Code postal',
    municipality: 'Municipalité',
    country: 'Pays',
    selectParcel: 'Sélectionnez la parcelle souhaitée',
    updateParcel: 'Mise à jour de la parcelle',
    connectedVineyard: 'Connecté à vignoble',
    connectedParcel: 'Connecté à parcelle cadastrale',
    connectedVineyardNotSelected:
      "Une parcelle agricole doit être reliée à un vignoble ainsi qu'à une parcelle cadastrale.",
    connectedParcelNotSelected: "Une parcelle agricole doit être reliée à un vignoble ainsi qu'à une parcelle cadastrale.",
    selectedVineyard: 'Vignoble selectionnée: ',
    selectedParcel: 'Parcelle selectionnée: ',
    nameParcel: 'Nom de la parcelle cadastrale',
    selectVineyardStep: 'Sélectionnez un étape de vignoble',
    selectVineyardStep1: 'Sélectionnez un étape de vignoble',
    selectVineyardStep2: 'Sélectionnez un étape de vignoble',
    agriculturalParcelInfo: 'Informations sur le Cépage',
    clone: 'Clone',
    rootStock: 'Porte-greffe',
    areaPlantedAre: 'Zone brevetée (a)',
    areaPlantedCentiare: 'Zone brevetée (ca)',
    yearPlanted: "Plantés dans l'année",
    updateAgriculturalParcel: 'mise à jour parcelle agricole',
    createAgriculturalParcel: 'Créer des raisins',
    invitationAccepted: 'Invitation acceptée',
    deleteEmployee: "Supprimer l'employé",
    vineyardNotSelected:
      'Veuillez sélectionner le vignoble auquel la parcelle agricole doit être rattachée',
    parcelNotSelected:
      'Veuillez sélectionner la parcelle à laquelle la parcelle agricole doit être reliée.',
    myParcelsExplanation:
      "Cliquez sur le bouton 'Détails' pour modifier la parcelle ou sélectionnez la parcelle pour créer des variétés de raisins.",

    words: {
      belgium: 'Belgique',
      email: 'adresse e-mail',
      name: 'Nom',
      address: 'adresse',
      location: 'lieu',
      BTWnumber: 'numéro de TVA',
      delete: 'Supprimer',
      edit: 'Modifier',
      create: 'Créer',
      next: 'Suivant',
      for: 'pour',
      configuration: 'Configuration',
      registration: 'Inscription',
      reporting: 'Création de rapports',
      save: 'Enregistrer',
      grape: "Grain de raisin",
    },

    history: {
      stepType: "Type d'étape",
      stepName: "Nom de l'étape",
      cropName: 'Année de récolte',
      vineyardName: 'Nom du vignoble',
    },

    vineyardSteps: {
      myVineyardSteps: 'Les parties de mon vignoble',
      myVineyardStepsDescription:
        'Visualiser ou modifier les étapes de votre vignoble',
      vineyardStepInfo: 'Info sur le pas de la vigne',
      nameEn: 'Nom anglais',
      nameFr: 'Nom français',
      nameIt: 'Nom italien',
      nameNl: 'Nom néerlandais',
      number: "Numéro d'étape",
      stepName: "Nom de l'étape",
      order: 'Ordre',
      key: 'Key',
      textEN: 'Nom anglais',
      textFR: 'Nom français',
      textIT: 'Nom italien',
      textNL: 'Nom néerlandais',
      type: "Type d'étape",
      validation: 'Validation',
      validation1: 'Validation 1',
      validation1Value: 'Validation 1 valeur',
      validation2: 'Validation 2',
      validation2Value: 'Validation 2 valeur',
      updateVineyardStep: "Mettre à jour l'étape du vignoble",
      selectionType: 'Type de sélection',
      newVineyardStep: 'Nouvelle étape du vignoble',
      createVineyardStep: 'Créer une étape de vignoble',
      resetToDefault: 'Réinitialiser',
      vineyardStepContent: 'Étape du vignoble content',
      addVineyardStepContent: 'Ajouter le contenu des étapes du vignoble',
      newVineyardStepContent: 'Contenu du nouveau pas de vigne',
      selectionTypeNotSelected: "Le type de sélection n'est pas sélectionné",
      stepTypeNotSelected: "Le type d'étape n'est pas sélectionné",
      createVineyardStepContent: 'Créer du contenu sur les étapes du vignoble',
    },

    vineyardRegistration: {
      addRegistration: "Enregistrer l'inscription à l'étape Vineyard",
      registerVineyardStepDescription: "Enregistrer des inscriptions à l'étape Vineyard",
      selectParcel: 'Sélectionner un vignoble ou un cépage',
      vineyardStepRegistration: 'Enregistrement des pas de vigne',
      description: 'Description',
      wayOfHarvest: 'Mode de récolte',
      warehouse: 'Entrepôt',
      amountDrying: 'Montant du séchage',
      product: 'Produit',
      amount: 'Montant',
      products: 'Produits',
      massDensity: 'Densité de masse',
      createRegistration: 'Créer un enregistrement',
      selectVineyardStep: 'Sélectionnez un étape de vignoble',
      selectedVineyardStep: 'Étape de vignoble sélectionnée',
    },

    barrels: {
      numberOfBarrels: 'Nombre de barils / tonneaux',
      totalVolume: 'Volume total (l)',
      myBarrels: 'Mes cuves / tonneaux',
      barrelInfo: 'Modifier la cuve / le tonneau',
      createBarrel: 'Créer une cuve / un tonneau',
    },

    emails: {
      welcome: 'Bienvenue dans la communauté Craft Your Taste!',
      thanks: 'Merci et amusez-vous bien!',
      moreInfo: "Plus d'information:",
    },

    pageNotFound: {
      title: '',
      body: '',
    },

    dashboard: {
      harvestYears: {
        title: 'Années de récolte',
        body: 'Afficher et modifier vos années de récolte',
      },
      government: {
        title: 'Ministère',
        body: 'Vérifiez les données du document pour le gouvernement',
      },
      barrels: {
        title: 'Mes cuves et barriques',
        body: 'Afficher et modifier vos cuves et barriques',
      },
    },

    harvestYears: {
      startDate: 'Date de début',
      endDate: 'Date de fin',
      myHarvestYears: 'Mes années de récolte',
      harvestYearInfo: "Informations sur l'année de récolte",
      errorMessages: {
        datesOverlapping:
          'Les dates se chevauchent avec une années de récolte existante',
        nothingChanged: "Rien n'a été changé",
        endBeforeStartDate: 'La date de fin doit être après',
      },
    },

    governmentDocuments: {
      personalData: 'Données personnelles',
      wineryData: 'Données sur la cave',
      selectYear: 'Sélectionnez une année de récolte',

      harvestDeclaration: {
        title: 'Déclaration de récolte',
        harvestNumber: 'Numero de récolte',
        vineVariety: 'Variété de vigne',
        acreage: 'Superficie (estimation)',
        parcelIdentification: 'Identification des colis',
        vineAmount: 'Montant de la vigne',
        harvestInOwnCellar: 'Récolte dans la cave propre',
        color: 'Couleur',
        sugar: 'Sucre',
        acidity: 'Acidité',
      },

      stockDeclaration: {
        title: 'Déclaration de stock',
        name: 'Nom du vin',
        stock: 'Stock (vrac)',
        productionYear: 'Année de production',
        recognition: 'Reconnaissance',
      },
      bottlingDeclaration: {
        title: 'Déclaration de mise en bouteille',
        name: 'Nom du vin',
        litersInBottle: 'Stock en bouteilles',
        bottleVolume: 'Volume de bouteille',
        productionYear: 'Année de production',
        recognition: 'Reconnaissance',
      },
    },
    cellars: {
      myCellarsExplanation:
        'Cliquez sur le nom de la cave pour ajouter des cuves /  tonneaux',
    },
    cellarSteps: {
      myCellarSteps: 'Les étapes de ma cave',
      myCellarStepsDescription:
        'Visualiser ou modifier les étapes de votre cave',
      newCellarStep: 'Nouvelle étape de la cave',
      cellarStepContent: "Contenu de l'etape de la cave",
      addCellarStepContent: "Ajouter le contenu de l'étape de la cave",
      newCellarStepContent: "Nouveau contenu de l'etape de la cave",
      updateCellarStep: "Mise à jour de l'etape de la cave",
      createCellarStep: 'Créer une étape de cave',
      createCellarStepContent: "Créer le contenu de l'étape de la cave",
    },

    harvestRegistration: {
      registerHarvest: 'Enregistrer une étape de récolte',
      registerHarvestDescription: 'Enregistrer une nouvelle étape de récolte',
      addBatchForHarvest: "Créer un nouveau lot pour l'étape de récolte",
      addWineStorage: 'Ajouter une cave à vin',
      selectWineStorage: 'Choisir une cave à vin',
      finishRegistration: "Fin de l'enregistrement",
      juiceInLiters: 'Jus en litres',
      addAmount: 'Ajouter la quantité',
      amountFor: 'Montant pour',
      vineyard: 'Vignoble',
      generateHarvestStockReport: 'Générer le rapport',
      activeHarvestYearNotFound: 'Aucune année de récolte active trouvée. Veuillez créer une année de récolte.',
      harvestYearsNotAvailable: 'Aucune année de récolte disponible. Veuillez créer une année de récolte.',
    },

    cellarRegistration: {
      registerCellarStep: 'Registre de la cave',
      registerCellarStepDescription: 'Enregistrer un nouveau pas de cave',
      selectCellarStep: 'Sélectionnez une étape de la cave',
      registrationOf: 'Enregistrement de',
      selectBatchForRegistration:
        "Sélectionnez le(s) batche(s) pour l'enregistrement",
      harvestYearName: "Nom de l'année de récolte",
      batchName: 'Nom du lot',
      registrationDate: "Date d'inscription",
      possibleAdditionOfSo: 'Ajout possible de SO2',
      newBatchName: 'Nouveau nom du lot',
      keepOldBatch: "Garder l'ancien lot?",
      keepBatch: 'Garder le lot ',
      litersFor: 'Litres pour ',
      bottlingOf: 'Mise en bouteille du vin en ',
      selectTypeOfBottle: 'Sélectionnez le type de bouteille',
      createCellarRegistration: 'Créer un enregistrement de cave',
    },

    traceBatch: {
      SO2GramPerLiter: 'SO2 (gr/l)',
      SO2Gram: 'SO2 (gr)',
      amountLiters: 'Montant (l)',
      bottleType: 'Type de bouteille',
      destination: 'Destination',
      origin: 'Origine',
      amountOfBottles: 'Quantité de bouteilles',
      percentage: 'Pourcentage',
      typeOfWineStorage: 'type de tonneau à vin',
      destemmed: 'Egrappé',
      harvestYear: 'Année de récolte',
      washingSulfite: 'Sulfite',
      registrationData: "Données d'enregistrement",
      bruised: 'Meurtri',
      litersForHarvest: 'Litres récoltés',
    },

    myHarvestSteps: {
      myHarvestSteps: 'Mes étapes de récolte',
      myHarvestStepsDescription: 'Consulter ou modifier vos étapes de récolte',
      key: 'Key',
      nameEN: 'Nom anglais',
      type: "Type d'étape",
      editHarvestStep: "Modifier l'étape de la récolte",
    },
    winegrowerDetailAdmin: {
      editWinegrowerDetails: 'Modifier les coordonnées du viticulteur',
    },
    createBatch: {
      createBatch: 'Créer un lot',
      createDate: 'Date de creation',
      harvestYear: 'Année de récolte',
      historic: "D'où vient ce lot?",
      name: 'Nom du lot',
      createNewBatch: 'Créer un nouveau lot',
      addGrapeVariety: 'Ajouter une variété de raisin',
      grapeVariety: 'Variété de raisin',
      percentage: 'Pourcentage',
      wineStorages: 'Caves à vin',
      addWineStorage: 'Ajouter une cave à vin',
      wineStorage: 'Sélectionnez le type de stockage du vin',
      liters: 'Litres',
    },
    barrelReport: {
      title: 'Traçer cuves ou barriques',
      subTitle: 'Voir le rapport par type de baril',
    },
    myAgriParcels: {
      rowDistance: "Distance entre les rangées (m)",
      plantDistance: "Distance entre les plantes (m)",
    },
    excelImportPage: {
      excelImport: "Importation de la liste de produits chimiques Excel",
      source: "Veuillez importer la liste depuis https://apps.health.belgium.be/fytoweb/pages/public/index.xhtml, choisissez la bonne langue, filtrez la culture sur les vignes (production de vin) et exportez.",
      stepsBeforeUpload: "Il est important d'importer le tableau que vous venez de télécharger dans un nouveau fichier xslx. Utilisez l'onglet Données/Data, le bouton obtenir des données / get data, à partir d'un fichier / from file, à partir d'un classeur Excel. Avant d'importer, vous devrez supprimer toutes les lignes vides en haut, sélectionner la ligne d'en-tête, et si ce n'est pas encore l'en-tête du tableau, appuyez sur le bouton pour le promouvoir en tant qu'en-tête de tableau. Une fois cela fait, enregistrez le tableau et vous devriez pouvoir télécharger.",
      save: "Enregistrer dans firebase",
      preview: "Aperçu des données importées:"
    },
    excelImport: {
      category: "Cultures",
      subcategory: "vignes (production de vin)",
      anyCrop: "toutes cultures",
    },
    excelImportKeys: {
      product: "Produit",
      number: "Numéro",
      activeSubstance: "Substance active",
      authorizationHolder: "Détenteur d'autorisation",
      nature: "Nature",
      formulationType: "Type de formulation",
      signalWord: "Mention d'avertissement",
      hazardPictograms: "Pictogrammes de danger",
      hazardStatements: "Mentions de danger",
      latestDeliveryCertificate: "Délivrance dernier acte",
      initialAuthorizationStart: "Début première autorisation",
      endOfSalesHolder: "Fin de vente (détenteur d'autorisation)",
      endOfSalesDistributor: "Fin de vente (distributeur)",
      endOfUse: "Fin d'usage"
    }
  },
};

export default translation;
