import { firestore } from './config';
import { collection, doc, addDoc, updateDoc } from 'firebase/firestore';

export const createVineyardDoc = async (vineyard) => {
  // get a reference to the firestore document
  const colRef = collection(firestore, 'winegrowers', vineyard.winegrower, 'vineyards');

  const vineyardProfile = {
    name: vineyard.name,
    sunorientation: vineyard.sunorientation,
    adress: vineyard.adress,
    city: vineyard.city,
    height: Number(vineyard.height),
    soil: vineyard.soil,
    winegrower: vineyard.winegrower,
    country: vineyard.country,
    greenArea: vineyard.greenArea,
  };

  // write to CLoud Firestore
  const docRef = await addDoc(colRef, vineyardProfile);
  await updateVineyardDoc({ ...vineyardProfile, vid: docRef.id });
};

export const createVineyardVarietyDoc = async (vineyardVariety) => {
  // get a reference to the firestore document
  const colRef = collection(firestore, 'winegrowers', vineyardVariety.winegrower, 'vineyards', vineyardVariety.uid, 'varieties');

  const vineyardProfile = {
    perceel_opp_ha: Number(vineyardVariety.perceel_opp_ha),
    perceel_opp_ca: Number(vineyardVariety.perceel_opp_ca),
    pruningmethod: vineyardVariety.pruningmethod,
    grapevariety: vineyardVariety.grapevariety,
    plantingyear: vineyardVariety.plantingyear,
    tendrils: vineyardVariety.tendrils,
  };

  // write to CLoud Firestore
  const docRef = await addDoc(colRef, vineyardProfile);
  await updateVineyardVarietyDoc({ ...vineyardProfile, vid: docRef.id });
};

export const updateVineyardDoc = async (vineyard) => {
  const docRef = doc(firestore, 'winegrowers', vineyard.winegrower, 'vineyards', vineyard.vid);
  return await updateDoc(docRef, vineyard);
};

export const updateVineyardVarietyDoc = async (variety) => {
  const docRef = doc(firestore, 'winegrowers', variety.winegrower, 'vineyards', variety.vid, 'varieties', variety.vaid);
  return await updateDoc(docRef, variety);
};
