import React from 'react'
import { useTranslation } from 'react-i18next';
import './DeleteAccount.css'
import { useAuth } from '../../context/AuthContext';
import { PageTitle } from '../../utils/PageTitle';
import { useFirestore } from '../../context/FirestoreContext';

const DeleteAccount = () => {
  PageTitle("Delete Account");
  const { currentUser } = useAuth();
  const { disableCurrentUser } = useAuth();
  const { clearWineriesArray } = useFirestore();
  const { t } = useTranslation();

  const deleteButtonClicked = async () => {
    if (currentUser) {
      // clearWineriesArray();
      await disableCurrentUser();
    }
  }


  return (
    <div className="deleteAccountWrapper">
      <h2>{t("translation.deleteAccountText")}</h2>
      <button onClick={deleteButtonClicked} className="ui submit large red button right floated bttn-profile" style={{ float: 'none', width: '200px' }}>
        {t("translation.deleteAccount")}
      </button>
    </div>
  )
}

export default DeleteAccount