import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { updateVineyardVarietyDoc } from '../../firebase/vineyard';
import { PageTitle } from '../../utils/PageTitle';
import { useSessionStorage } from '../../logic/useSessionStorage';
import { firestore } from '../../firebase/config';
import { getFirestore, doc, getDoc, collection, query, onSnapshot, getDocs } from 'firebase/firestore';


const GrapeVarietyDetail = (props) => {
  PageTitle("Grape Variety");
  const navigate = useNavigate();
  const params = useParams();
  const { register, setValue, handleSubmit } = useForm();
  const [isLoading, setLoading] = useState(true);
  const [currentWinery, setCurrentWinery] = useSessionStorage('currentwinery', '');
  const [pruningmethods, SetPruningMethods] = useState([]);
  const [pruningmethod, setPruningmethod] = useState(null);
  const [vineyardDocument, setVineyardDocument] = useState([]);
  const [availableArea, setAvailableArea] = useState(0);
  const [availableAreahtmlHa, setShowAvailableAreaHa] = useState(0);
  const [availableAreahtmlCa, setShowAvailableAreaCa] = useState(0);
  const [selectedPruningId, setSelectedPruningId] = useState('');
  const [variety, setVariety] = useState(null);
  const [vaid, setVaid] = useState('');
  const [vid, setVid] = useState(props.location.state.vid);
  const vidUrl = useState('/vineyardinfo/' + vid);
  const [showErrorMethod, setShowErrorMethod] = useState('hidden');
  var docData = '';
  const { t } = useTranslation();

  var areaUsed = 0;
  var totalArea = 0;
  let help = 10000;

  // Referenties naar firestore database (bv : ophalen van collecties uit firestore db)
  // Alle waarden in de variabelen gaan verloren éénmaal de useEffect volledig uitgevoerd is

  useEffect(() => {
    setLoading(true);
    const docRef = doc(firestore, 'winegrowers', currentWinery, 'vineyards', vid);
    const varRef = collection(doc(firestore, 'winegrowers', currentWinery, 'vineyards', vid), 'varieties');
    const pruningRef = collection(firestore, 'pruningmethod');

    if (doc.exists) {
      const unsubscribeDoc = onSnapshot(docRef, (doc) => {
        const docData = doc.data();
        setVineyardDocument(docData);
        totalArea = docData.perceel_opp_ca + docData.perceel_opp_ha * help;
      });

      const unsubscribeVar = onSnapshot(query(varRef), (querySnapshot) => {
        const varieties = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        let variety = varieties.find((obj) => {
          setVaid(params.id);
          return obj.id === params.id;
        });


        if (!!variety) {
          setSelectedPruningId(variety.pruningmethod);
          setVariety(variety);
          setShowAvailableAreaCa(variety.perceel_opp_ca);
          setShowAvailableAreaHa(variety.perceel_opp_ha);
        }
        setAvailableArea(totalArea - areaUsed);
      });
      const unsubscribePruning = onSnapshot(query(pruningRef), (querySnapshot) => {
        const pruningmethods = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        if (!!variety.pruningmethod) {
          setPruningmethod(pruningmethods.find((obj) => obj.id === variety.pruningmethod));
          setLoading(false);
        }
        SetPruningMethods(pruningmethods);
      });
      return () => {
        unsubscribeDoc();
        unsubscribeVar();
        unsubscribePruning();
      }
    }
  }, []);

  //submitting form
  const onSubmit = async (data) => {
    try {
      setLoading(true);
      if (selectedPruningId === '') {
        setShowErrorMethod('show');
        setLoading(false);
      } else {
        setShowErrorMethod('hidden');
        data.winegrower = currentWinery;
        data.pruningmethod = selectedPruningId;
        data.vaid = vaid;
        data.vid = vid;
        await updateVineyardVarietyDoc({
          id: params.id,
          ...data,
        });
        setLoading(false);
        navigate(vidUrl[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const showAvailableArea = () => {
    const result =
      availableArea - availableAreahtmlHa * help - availableAreahtmlCa;
    return result;
  };

  //Pruningmethod selected
  const handlePruningChange = (selection, action) => {
    setSelectedPruningId(selection.value);
  };

  const formClassname = `ui big form twelve wide column ${isLoading ? 'loading' : ''
    }`;

  // Functie die zorgt voor het weergeven van een detailpagina van een wijngaard

  return (
    <div
      className="add-form-container"
      style={{
        maxWidth: 960,
        margin: '50px auto',
        marginTop: '110px',
      }}
    >
      <h1 style={{ color: '#7f7f7f' }}>{t('vertaling.vineyardInfo')}</h1>
      <p>
        <Link to={vidUrl[0]} className="backbutton">
          {t("vertaling.goback")}
        </Link>
      </p>

      <div
        className="ui grid stackable"
        style={{ justifyContent: 'center', marginTop: '-90px' }}
      >
        {!isLoading && (
          <form
            className={formClassname}
            style={{
              border: '3px solid #B3B3B3',
              marginTop: '100px',
              borderRadius: '50px',
              background: '#edeaea',
              borderColor: '#ac9e66',
            }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="fields" style={{ justifyContent: 'center' }}>
              <div className="twelve wide field">
                <label>
                  <p>{t("vertaling.name")}</p>
                  <input
                    type="text"
                    name="name"
                    htmlFor="name"
                    required="required"
                    disabled="true"
                    defaultValue={vineyardDocument.name}
                  />
                </label>
              </div>
            </div>
            <div className="fields" style={{ justifyContent: 'center' }}>
              <div className="twelve wide field">
                <label>
                  <p>{t("vertaling.grapevariety")}</p>
                  <input
                    type="text"
                    {...register('grapevariety')}
                    htmlFor="grapevariety"
                    required="required"
                    defaultValue={variety.grapevariety} />
                </label>
              </div>
            </div>
            <div className="fields" style={{ justifyContent: 'center' }}>
              <div className="twelve wide field">
                <label>
                  <p>{t("vertaling.planting_year")}</p>
                  <input
                    type="number"
                    {...register('plantingyear')}
                    htmlFor="plantingyear"
                    required="required"
                    defaultValue={variety.plantingyear} />
                </label>
              </div>
            </div>
            <div className="fields" style={{ justifyContent: 'center' }}>
              <div className="twelve wide field">
                <label>
                  <p>{t("vertaling.perceel_deel_opp")}</p>
                  {showAvailableArea() >= 0 ? (
                    <p>available: {showAvailableArea()} ca</p>
                  ) : (
                    <p style={{ color: 'red' }}>U hebt het limiet bereikt!</p>
                  )}
                  <div className="flex-row">
                    <input
                      style={{ marginRight: '10px' }}
                      type="number"
                      {...register('perceel_opp_ha')}
                      htmlFor="perceel_opp_ha"
                      required="required"
                      min="0"
                      step="1"
                      placeholder="ha"
                      defaultValue={variety.perceel_opp_ha}
                      onChange={(e) =>
                        setShowAvailableAreaHa(
                          e.target.value,
                          showAvailableArea()
                        )
                      } />
                    <input
                      type="number"
                      {...register('perceel_opp_ca')}
                      htmlFor="perceel_opp_ca"
                      required="required"
                      min="0"
                      step="1"
                      placeholder="ca"
                      defaultValue={variety.perceel_opp_ca}
                      onChange={(e) =>
                        setShowAvailableAreaCa(
                          e.target.value,
                          showAvailableArea()
                        )
                      } />
                  </div>
                </label>
              </div>
            </div>
            <div className="fields" style={{ justifyContent: 'center' }}>
              <div className="twelve wide field">
                <label>
                  <p>{t("vertaling.tendrils")}</p>
                  <input
                    type="number"
                    {...register('tendrils')}
                    htmlFor="tendrils"
                    min="1"
                    defaultValue={variety.tendrils}
                    required="required" />
                </label>
              </div>
            </div>
            <div className="fields" style={{ justifyContent: 'center' }}>
              <div className="twelve wide field">
                <label>
                  <p>{t("vertaling.pruningmethod")}</p>
                  <Select
                    required="required"
                    placeholder={t('vertaling.selectpruningmethod')}
                    options={pruningmethods.map((name, id) => ({
                      value: name.id,
                      label: t(`vertaling.${name.name.replace(/\s/g, '')}`)
                    }))}
                    defaultValue={{
                      label: t('vertaling.' + pruningmethod.name.replace(/\s/g, '').replace('-', '')),
                      value: selectedPruningId,
                    }}
                    onChange={(selection, action) =>
                      handlePruningChange(selection, action)
                    }
                    className="specialty"
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 5,
                      colors: {
                        ...theme.colors,
                        primary25: '#dacfa4b2',
                        primary: '#AC9E66',
                      },
                    })}
                  />
                </label>
              </div>
            </div>
            <button
              type="submit"
              disabled={showAvailableArea() >= 0 ? false : true}
              className="ui submit large grey button right floated button-vineyards"
              style={{ float: 'none', marginTop: '30px', width: '25%' }}
            >
              {t("vertaling.updatevineyardvariety")}
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default GrapeVarietyDetail;