import { useState, useEffect } from 'react';

function getSessionStorageOrDefault(key, defaultValue) {
    const stored = sessionStorage.getItem(key);
    if (stored === null) {
        return defaultValue;
    }
    try {
        return JSON.parse(stored);
    } catch (e) {
        console.error("Error parsing session storage item", e);
        return defaultValue;
    }
}

export function useSessionStorage(key, defaultValue) {
  const [value, setValue] = useState(getSessionStorageOrDefault(key, defaultValue));

  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
}