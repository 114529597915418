import moment from "moment";

export const timestampToDate = (timestamp, format) => {
    
    let date;
    if (typeof timestamp === "object") {
        if (timestamp.toDate) {
            // If timestamp is a Firebase Timestamp object
            date = timestamp.toDate();
        } else if (timestamp.seconds) {
            // If timestamp is a plain object with seconds
            date = new Date(timestamp.seconds * 1000);
        } else {
            // Handle other types of objects or invalid format
            console.error("Invalid timestamp object:", timestamp);
            return ""; // Return an empty string or some error indicator
        }

        // Use moment.js to format the date
        if (format) {
            return moment(date).format(format);
        } else {
            return moment(date).format("DD/MM/YYYY");
        }
    } else {
        return timestamp;
    }
};

export const createNewDate = (format) => {
    if (format) {
        return moment(new Date()).format(format)
    } else {
        return moment(new Date()).format("DD/MM/YYYY")
    }
}

export const dateObjectToDate = (date, format) => {
    if (format) {
        return moment(date).format(format)
    } else {
        return moment(date).format("DD/MM/YYYY")
    }
}