import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { onSnapshot, collection } from 'firebase/firestore';
import { useFirestore } from '../../context/FirestoreContext';
import { firestore } from '../../firebase/config';
import { updateUserDocument } from '../../firebase/user';
import { PageTitle } from '../../utils/PageTitle';
import GoBackButton from '../../components/FormComponents/GoBackButton';

const WineryInvitations = () => {
  PageTitle('Invitations');
  const { currentUserDoc } = useFirestore();
  const [wineriesToAccept, setWineriesToAccept] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (!currentUserDoc?.wineries) {
      setLoading(false);
      return;
    }

    const wineryIdsToAccept = currentUserDoc.wineries.filter(w => !w.accepted);
    const winRef = collection(firestore, 'winegrowers');
    const unsub = onSnapshot(winRef, (querySnapshot) => {
      const wineries = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      const toAccept = wineryIdsToAccept.map(winery => wineries.find(w => w.id === winery.winegrower)).filter(Boolean);
      
      setWineriesToAccept(toAccept);
      setLoading(false);
    }, (error) => {
      console.error("Error fetching wineries:", error);
      setLoading(false);
    });

    return () => unsub();
  }, [currentUserDoc]);

  const acceptWinery = async (wineryId) => {
    try {
      const updatedWineries = currentUserDoc.wineries.map(winery => ({
        ...winery,
        accepted: winery.winegrower === wineryId ? true : winery.accepted,
      }));
  
      const updatedUserDocument = { ...currentUserDoc, wineries: updatedWineries };
      await updateUserDocument(currentUserDoc.id, updatedUserDocument);
      setWineriesToAccept(prev => prev.filter(w => w.id !== wineryId));
    } catch (error) {
      console.error("Error accepting winery:", error);
    }
  };

  return (
    <div style={{ position: 'relative', marginTop: '90px' }}>
      <h1 style={{ marginTop: '100px', color: '#7f7f7f' }}>
        {t('vertaling.h14_dashboard')}
      </h1>
      <div style={{ marginBottom: 40 }}>
        <GoBackButton />
      </div>

      {!loading && wineriesToAccept.length > 0 ? (
        wineriesToAccept.map(w => (
          <div className="container winery-block" key={w.id}>
            <div className="winery-info">
              <div className="center" style={{ color: '#ac9e66', fontSize: 20, fontWeight: 'bold' }}>
                {w.name}
              </div>
              <div className="center">{w.adress + ', ' + w.city}</div>
              <div className="center">{w.phone}</div>
            </div>
            <div className="winery-accept-btn">
              <button onClick={() => acceptWinery(w.id)} className="ui primary button login">
                {t('vertaling.accept')}
              </button>
            </div>
          </div>
        ))
      ) : (
        <div className="no-wineries">
          {t('vertaling.no_wineries_to_accept')}
        </div>
      )}
    </div>
  );
};

export default WineryInvitations;