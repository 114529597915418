import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { firestore } from "../../firebase/config";
import { useFirestore } from '../../context/FirestoreContext'
import { collection, doc, onSnapshot, query } from "firebase/firestore";
import { useSessionStorage } from "../../logic/useSessionStorage";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import Select from "react-select";
import Checkbox from "../../components/FormComponents/Checkbox";
import { useAuth } from "../../context/AuthContext";
import GoBackButton from "../../components/FormComponents/GoBackButton";

const AddHarvestRegistration = () => {
    const [harvestStepData, setHarvestStepData] = useState([]);
    const [harvestYears, setHarvestYears] = useState([]);
    const [selectedValue, setSelectedValue] = useState("");
    const [checkedDestemmed, setCheckDestemmed] = useState(false);
    const [checkedBruised, setCheckedBruised] = useState(false);
    const { register, handleSubmit } = useForm();
    const { i18n } = useTranslation();
    const lang = i18n.language;
    const { currentUser } = useAuth();
    const [currentVineyardUser, setCurrentVineyardUser] = useState(undefined);
    const params = useParams();
    const navigate = useNavigate();
    const formClassname = "ui big form twelve wide column";
    const [currentWinery, setCurrentWinery] = useSessionStorage(
        "currentwinery",
        ""
    );
    const [harvestData, setHarvestData] = useSessionStorage("harvestData", "");
    const [shouldNavigate, setShouldNavigate] = useState(false);
    const { t } = useTranslation();
    const { currentUserDoc } = useFirestore();

    useEffect(() => {
        if (harvestData && shouldNavigate) {
          navigate('/harvestRegistration/addBatch');
          setShouldNavigate(false);
        }
      }, [harvestData, shouldNavigate]);


    useEffect(() => {
        setCurrentVineyardUser(currentUserDoc);
        const harvestStepRef = doc(firestore, "winegrowers", currentWinery, "customSteps", "harvestStep");
        const unsubscribeHarvestStep = onSnapshot(harvestStepRef, (doc) => {
            if (doc.exists()) {
                setHarvestStepData(doc.data());
            }
        });

        const harvestYearsColRef = collection(firestore, "winegrowers", currentWinery, "harvestYear");
        const unsubscribeHarvestYears = onSnapshot(query(harvestYearsColRef), (snapshot) => {
            const harvestYearsDb = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setHarvestYears(harvestYearsDb);
        });
        return () => {
            unsubscribeHarvestStep();
            unsubscribeHarvestYears();
        };
    }, [currentWinery, params.id])

    function onSubmit(data) {
        data.agriculturalParcels = JSON.parse(params.id);
        data.destemmed = checkedDestemmed;
        data.bruised = checkedBruised;
        data.acidityForReport = Number(data.acidityForReport);
        data.ph = Number(data.ph);
        data.sugarsForReport = Number(data.sugarsForReport);
        if (currentVineyardUser.wineries.some(winery => winery.winegrower === currentWinery && winery.trustedForRegistration)) {
            data.waitingForReview = false;
          } else if (currentVineyardUser.wineries.some(winery => winery.winegrower === currentWinery && !winery.trustedForRegistration)) {
            data.waitingForReview = true;
          }
        data.visualisation = true;
        data.type = "harvestStep";
        data.email = currentUser.email;
        data.harvestYearId = findActiveHarvestYear();
        data.stepId = "harvestStep";
        setHarvestData(data);
        setShouldNavigate(true);
    }

    const findActiveHarvestYear = () => {
        if (harvestYears.length > 0) {
            let today = Math.floor(new Date().getTime() / 1000);
            let currentHarvestYearId;
            for (let i = 0; i < harvestYears.length; i++) {
                if (
                    harvestYears[i].startDate.seconds < today &&
                    harvestYears[i].endDate.seconds > today
                ) {
                    currentHarvestYearId = harvestYears[i].id;
                }
            }
    
            if (currentHarvestYearId) {
                return currentHarvestYearId;
            } else {
                // No active harvest year found
                window.alert(t("translation.harvestRegistration.activeHarvestYearNotFound"));
                navigate(-1);
                return null; // You can choose to return null or throw an error here
            }
        } else {
            // No harvest years available
            window.alert(t("translation.harvestRegistration.harvestYearsNotAvailable"));
            navigate(-1);
            return null; // You can choose to return null or throw an error here
        }
    };

    const handleChange = (selection, action) => {
        setSelectedValue(selection.value);
    }

    const handleDestemmedChange = () => {
        setCheckDestemmed(!checkedDestemmed);
    }

    const handleBruisedChange = () => {
        setCheckedBruised(!checkedBruised);
    }

    let form = [];
    let inForm = [];

    function generateForm() {
        let currentLang = "";
        if (harvestStepData.content !== undefined) {
            let options = {};
            for (const [key, value] of Object.entries(harvestStepData.content)) {
                if (value["type"] === "option") {
                    if (!options.hasOwnProperty(value["key"])) {
                        options[value["key"]] = [];
                    }
                    // Create an object for each option containing all language texts
                    const optionObject = {
                        textEN: value["textEN"],
                        textFR: value["textFR"],
                        textNL: value["textNL"],
                        // Add other languages as needed
                    };
                    options[value["key"]].push(optionObject);
                }
            }


            if (lang === 'en') {
                currentLang = 'textEN';
            } else if (lang === 'fr') {
                currentLang = 'textFR';
            } else if (lang === 'nl') {
                currentLang = 'textNL';
            }

            let requiredField;
            for (const [key, value] of Object.entries(harvestStepData.content)) {
                requiredField = value["validation"] === "notEmpty";
                if (value["type"] === "multilinetext" || value["type"] === "text") {
                    form.push(
                        <div
                        key={value["key"] + "_text_div"}
                            className="fields"
                            style={{ justifyContent: "center", marginTop: "20px" }}
                        >
                            {requiredField === true && (
                                <div className="twelve wide field">
                                    <label>
                                        {value[currentLang]}
                                        <input
                                            type="text"
                                            {...register(value["key"])}
                                            htmlFor={value["key"]}
                                            style={{ textAlign: "center" }}
                                            required="required" />
                                    </label>
                                </div>
                            )}
                            {requiredField === false && (
                                <div className="twelve wide field">
                                    <label>
                                        {value[currentLang]}
                                        <input
                                            type="text"
                                            {...register(value["key"])}
                                            htmlFor={value["key"]}
                                            style={{ textAlign: "center" }} />
                                    </label>
                                </div>
                            )}
                        </div>
                    );
                    inForm.push(value["key"]);
                } else if (value["type"] === "label") {
                    form.push(
                        <div
                        key={value["key"] + "_label_div"}
                            className="fields"
                            style={{
                                justifyContent: "center",
                                marginTop: "20px",
                                marginBottom: "-20px ",
                                fontWeight: "bold",
                            }}
                        >
                            <label>{value[currentLang]}</label>
                        </div>
                    );
                } else if (
                    value["type"] === "option" &&
                    inForm.includes(value["key"]) == false
                ) {
                    form.push(
                        <div
                        key={value["key"] + "_option_div"}
                            className="fields"
                            style={{ justifyContent: "center", marginTop: "20px" }}
                        >
                            <div className="twelve wide field">
                                {requiredField === true && (
                                    <label>
                                        <Select
                                            label={value["key"]}
                                            htmlFor={value["key"]}
                                            required="required"
                                            options={options[value["key"]].map((option) => ({
                                                label: option[currentLang],
                                                value: option[currentLang],
                                            }))}
                                            onChange={(selection, action) =>
                                                handleChange(selection, action)
                                            }
                                        />
                                    </label>
                                )}

                                {requiredField === false && (
                                    <label>
                                        <Select
                                            label={value["key"]}
                                            htmlFor={value["key"]}
                                            options={options[value["key"]].map((value) => ({
                                                label: value,
                                                value: value,
                                            }))}
                                            onChange={(selection, action) =>
                                                handleChange(selection, action)
                                            }
                                        />
                                    </label>
                                )}
                                <div style={{ display: "none" }}>
                                    <input
                                        {...register(value["key"])}
                                        htmlFor={value["key"]}
                                        value={selectedValue}
                                        readOnly />
                                </div>
                            </div>
                        </div>
                    );
                    inForm.push(value["key"]);
                } else if (value["type"] === "decimal") {
                    form.push(
                        <div
                        key={value["key"] + "_decimal_div"}
                            className="fields"
                            style={{ justifyContent: "center", marginTop: "20px" }}
                        >
                            <div className="twelve wide field">
                                <label>
                                    {value[currentLang]}
                                    {requiredField === true && (
                                        <input
                                            type="number"
                                            step="0.001"
                                            {...register(value["key"])}
                                            htmlFor={value["key"]}
                                            style={{ textAlign: "center" }}
                                            
                                            required />
                                    )}
                                    {requiredField === false && (
                                        <input
                                            type="number"
                                            step="0.001"
                                            {...register(value["key"])}
                                            htmlFor={value["key"]}
                                            style={{ textAlign: "center" }} />
                                    )}
                                </label>
                            </div>
                        </div>
                    );
                    inForm.push(value["key"]);
                } else if (value["type"] === "int") {
                    form.push(
                        <div
                        key={value["key"] + "_int_div"}
                            className="fields"
                            style={{ justifyContent: "center", marginTop: "20px" }}
                        >
                            <div className="twelve wide field">
                                <label>
                                    {value[currentLang]}
                                    {requiredField === true && (
                                        <input
                                            type="number"
                                            step="0.001"
                                            {...register(value["key"])}
                                            htmlFor={value["key"]}
                                            style={{ textAlign: "center" }}
                                            required />
                                    )}
                                    {requiredField === false && (
                                        <input
                                            type="number"
                                            step="0.001"
                                            {...register(value["key"])}
                                            htmlFor={value["key"]}
                                            style={{ textAlign: "center" }} />
                                    )}
                                </label>
                            </div>
                        </div>
                    );
                    inForm.push(value["key"]);
                 } else if (value["key"] === "destemmed") {
                    form.push(
                        <div   
                        key={value["key"] + "_destemmed_div"}
                        className="fields"
                            style={{ justifyContent: "center", marginTop: "20px" }}>
                            <div className="twelve wide field">
                                <Checkbox label={value[currentLang]} id={value['key']} value={checkedDestemmed}
                                    onChange={handleDestemmedChange} />
                            </div>
                        </div>
                    )
                } else if (value["key"] === "bruised") {
                    form.push(
                        <div 
                        key={value["key"] + "_bruised_div"}
                        className="fields"
                            style={{ justifyContent: "center", marginTop: "20px" }}>
                            <div className="twelve wide field">
                                <Checkbox label={value[currentLang]} id={value['key']} value={checkedBruised}
                                    onChange={handleBruisedChange} />
                            </div>
                        </div>
                    )
                }
            }
        }
        return form;
    }

    return (
        <div>
            <h1>{t("translation.harvestRegistration.registerHarvest")}</h1>
            <GoBackButton />
            <br />
            <br />
            <div className="ui grid stackable">
                <form
                    className={formClassname}
                    style={{
                        border: "3px solid #B3B3B3",
                        marginTop: "150px",
                        margin: "auto",
                        borderRadius: "50px",
                        background: "#edeaea",
                        borderColor: "#ac9e66",
                        maxWidth: "960px",
                    }}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    {harvestStepData.content !== undefined && (
                        <div>
                            {generateForm()}
                            <button
                                type="submit"
                                className="ui submit large grey button right floated  button-vinyards"
                                style={{ float: "none", marginTop: "30px", width: "25%" }}
                            >
                                {t("translation.words.next")}
                            </button>
                        </div>


                    )}
                </form>
            </div>
        </div>
    )
}
export default AddHarvestRegistration;