import { useTranslation } from 'react-i18next';
import { PageTitle } from "../../utils/PageTitle";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { firestore } from "../../firebase/config";
import { useSessionStorage } from "../../logic/useSessionStorage";
import { updateContentVineyardStep } from "../../firebase/vineyardstep";
import GoBackButton from "../../components/FormComponents/GoBackButton";
import { doc, onSnapshot } from 'firebase/firestore';
import MaterialTable from '@material-table/core';

const VineyardStepsList = () => {
    PageTitle("Vineyard Step List");
    const [contentVineyardStep, setContentVineyardStep] = useState([]);
    const [currentVineyardStep, setCurrentVineyardStep] = useState([]);
    const navigate = useNavigate();
    const params = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [currentWinery, setCurrentWinery] = useSessionStorage("currentwinery", "");
    const { t } = useTranslation();

    var currentStep;
    useEffect(() => {
        const vineyardStepRef = doc(firestore, "winegrowers", currentWinery, "vineyardSteps", params.id);

        const unsubscribe = onSnapshot(vineyardStepRef, (doc) => {
            if (doc.exists) {
                currentStep = {
                    ...doc.data(),
                    id: doc.id
                }
                if (
                    currentStep.content === undefined ||
                    currentStep.content.length === 0
                ) {
                } else {
                    if (currentStep.content[0].key === null) {
                        currentStep.content[0].key = "Action";
                    }
                    setContentVineyardStep(currentStep.content);
                    setCurrentVineyardStep(currentStep);
                    setIsLoading(false);
                }
            }
        });
        return () => unsubscribe();
    }, [params.id]);

    async function deleteVineyardStepContent(vineyardStepContentKey) {
        let newDoc = { ...currentVineyardStep };
        for (let i = 0; i < newDoc.content.length; i++) {
            if (newDoc.content[i].key === vineyardStepContentKey) {
                newDoc.content.splice(i, 1);
            }
        }
        await updateContentVineyardStep(currentWinery, currentVineyardStep.id, newDoc);
        // navigate('/')
    }

    return (
        <div
            style={{
                margin: "50px 50px",
                marginTop: "110px",
            }}
        >
            <div>
                <div>
                <h1 style={{ color: "#7f7f7f" }}>{t("translation.vineyardSteps.vineyardStepContent")}</h1>
                </div>
                <GoBackButton />
            </div>
            <div className="newvineyard">
                <Link
                    to={`/vineyardsteps/newvineyardstepcontent/${params.id}`}
                    style={{ color: "white" }}
                >
                    <button className="ui primary button login" style={{ marginTop: '25px' }}>
                        {t("translation.vineyardSteps.addVineyardStepContent")}
                    </button>
                </Link>
            </div>
            <div className="batchesList">
                <MaterialTable
                    title=""
                    data={contentVineyardStep}
                    columns={[
                        {
                            title: t('translation.vineyardSteps.order'),
                            field: "order",
                        },
                        {
                            title: "type",
                            field: "key",
                        },
                        {
                            title: "",
                            field: "",
                            sorting: false,
                            render: (rowData) => (
                                <button
                                    className="ui primary button login"
                                    onClick={() => {
                                        deleteVineyardStepContent(rowData.key);
                                    }}
                                >
                                    {t("translation.words.delete")}
                                </button>
                            ),
                        },
                    ].map((c) => ({
                        render: (row) => (
                            <Link
                                to={`/vineyardsteps/editvineyardstep/${params.id}/${row.order}`}
                            >
                                {c.lookup ? c.lookup[row[c.field]] : row[c.field]}
                            </Link>
                        ),
                        ...c
                    }))}
                    options={{
                        filtering: true,
                        exportButton: true,
                        exportAllData: true,
                        headerStyle: {
                            backgroundColor: "#cecaca",
                            color: "#FFF",
                        },
                        emptyRowsWhenPaging: false,
                        paging: true,
                        pageSize: 50,
                        pageSizeOptions: [
                            10,
                            20,
                            25,
                            50,
                        ],
                    }}
                    components={{
                        Action: (props) => (
                            <div className="flex_grape">
                                <button
                                    className="ui primary button login"
                                    onClick={(event) => props.action.onClick(event, props.data)}
                                    color="primary"
                                    style={{ textTransform: "none" }}
                                >
                                    {t("vertaling.details")}
                                </button>
                                <button
                                    className="ui primary button login "
                                    onClick={(event) => props.action.create(event, props.data)}
                                    color="primary"
                                    style={{ textTransform: "none", minWidth: 175 }}
                                >
                                    {t("vertaling.grapevariety_btn")}
                                </button>
                            </div>
                        ),
                    }}
                />
            </div>
        </div>
    );
};

export default VineyardStepsList;