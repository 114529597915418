import { useTranslation } from 'react-i18next';
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { deactivateBarrel } from "../../firebase/Cellar";
import { firestore } from "../../firebase/config";
import { PageTitle } from "../../utils/PageTitle";
import { useSessionStorage } from "../../logic/useSessionStorage";
import Spinner from "../../components/Spinner/Spinner";
import GoBackButton from "../../components/FormComponents/GoBackButton";
import PageNotFound from "../PageNotFound/PageNotFound";
import { useFirestore } from "../../context/FirestoreContext";
import Select from 'react-select';
import { doc, collection, query, where, onSnapshot } from 'firebase/firestore';
import MaterialTable from '@material-table/core';


const Barrels = () => {
    PageTitle("Barrels");
    const params = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [barrels, setBarrels] = useState([]);
    const [cellars, setCellars] = useState([]);
    const [currentCellar, setCurrentCellar] = useState(undefined);
    const [currentWinery, setCurrentWinery] = useSessionStorage("currentwinery", "");
    const { getCellars } = useFirestore();
    const cellarId = params.id;
    const { t } = useTranslation();


    useEffect(() => {
        const getCellarsAsync = async () => {
            const cellarsResult = await getCellars(currentWinery);
            setCellars(cellarsResult);
            setLoading(false);
        }

        if (cellarId === undefined) {
            getCellarsAsync();
        }
    }, [barrels])


    useEffect(() => {
        let barrelsDb = [];
        let unsubscribeCellar;
        let unsubscribeBarrels;

        if (cellarId !== undefined) {
            const cellarRef = doc(collection(firestore, 'winegrowers', currentWinery, 'cellars'), cellarId);
            unsubscribeCellar = onSnapshot(cellarRef, (doc) => {
                let currentCellar = doc.data();
                if (currentCellar) {
                    currentCellar.id = doc.id;
                    setCurrentCellar(currentCellar);
                }
            })

            const barrelsQuery = query(
                collection(firestore, 'winegrowers', currentWinery, 'wineStorages'),
                where('cellarId', '==', cellarId)
            );

            unsubscribeBarrels = onSnapshot(barrelsQuery, (snapshot) => {
                barrelsDb = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                const activeBarrels = [];
                barrelsDb.forEach((barrel) => {
                    if (barrel.endDate === null) {
                        activeBarrels.push(barrel);
                    }
                });
                setBarrels(activeBarrels);
                setLoading(false);
            });
        }
        return () => {
            if (unsubscribeCellar) unsubscribeCellar();
            if (unsubscribeBarrels) unsubscribeBarrels();
        };
    }, [cellarId]);

    async function deleteBarrel(currentWinery, rowData) {
        await deactivateBarrel(currentWinery, rowData);
        navigate(`/mycellars/barrels/${cellarId}`);
    }

    if (!loading && cellarId !== undefined && currentCellar === undefined) return <PageNotFound />

    return (
        <div style={{ marginTop: "90px" }}>
            <h1 style={{ marginTop: "100px", color: "#7f7f7f" }}>{t('translation.barrels.myBarrels')}</h1>


            <Spinner isLoading={loading} />
            <GoBackButton />
            <br />
            <h1 style={{ color: "#7f7f7f" }}>{currentCellar ? currentCellar.name : ""}</h1>
            <br />
            <div style={{ position: 'relative', width: 400, margin: 'auto' }}>
                <div style={{ position: 'absolute', zIndex: 2, width: '100%'}}>
                    <Select
                        placeholder={t('vertaling.selectCellar')}
                        onChange={selection => {
                            setLoading(true);
                            navigate(`/mycellars/barrels/${selection.value}`, { replace: true });
                        }}
                        options={cellars.map(({ name, id }) => (
                            {
                                value: id,
                                label: name,
                            }
                        ))}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 4,
                            colors: {
                                ...theme.colors,
                                primary25: '#dacfa4b2',
                                primary: '#AC9E66',
                            },
                        })}
                    />
                    </div>
                </div>

            <>
                <div className="newbatch" style={{ marginTop: "70px", zIndex: 1 }}>
                    <button
                        className="ui primary button login"
                        onClick={() => navigate(`/mycellars/barrels/create/${currentCellar.id}`)}
                    >
                        {t("translation.barrels.createBarrel")}
                    </button>
                </div>
                <div className="batchesList">
                    <MaterialTable
                        title=""
                        data={barrels}
                        columns={[
                            {
                                title: t('vertaling.name'),
                                field: "name",
                                defaultSort: "asc",
                            },
                            {
                                title: t('translation.barrels.numberOfBarrels'),
                                field: "number",
                            },
                            {
                                title: t('vertaling.colType'),
                                field: "type",
                            },
                            {
                                title: t('translation.barrels.totalVolume'),
                                field: "totalVolume",
                            },
                            {
                                title: t('vertaling.colDate'),
                                field: "dateFirstUsed",
                                render: (rowData) => {
                                    if (rowData.dateFirstUsed != null) {
                                        var date = rowData.dateFirstUsed.toDate();
                                        return (
                                            "" +
                                            date.getUTCDate() +
                                            "/" +
                                            (date.getUTCMonth() + 1) +
                                            "/" +
                                            date.getUTCFullYear()
                                        );
                                    }
                                },
                            },
                            {
                                title: "",
                                field: "",
                                sorting: false,
                                render: (rowData) => (
                                    <button
                                        className="ui primary button login"
                                        onClick={() => {
                                            deleteBarrel(currentWinery, rowData);
                                        }}
                                    >
                                        {t("vertaling.colDelete")}
                                    </button>
                                ),
                            },
                        ].map((c) => ({
                            render: (row) => (
                                <Link to={`/mycellars/barrels/edit/${row.id}`}>
                                    {c.lookup ? c.lookup[row[c.field]] : row[c.field]}
                                </Link>
                            ),
                            ...c,
                        }))}

                        options={{
                            search: true,
                            filtering: true,
                            exportButton: true,
                            exportAllData: true,
                            headerStyle: {
                                backgroundColor: "#cecaca",
                                color: "#FFF",
                            },
                            emptyRowsWhenPaging: false,
                            paging: true,
                            pageSize: 50,
                            pageSizeOptions: [
                                10,
                                20,
                                25,
                                50,
                            ],
                        }}
                        // Dit wordt gebruikt om alles te vertalen in de tabel die hierboven wordt gedefineerd
                        localization={{
                            body: {
                                emptyDataSourceMessage: t('vertaling.emptyDataSourceMessage'),
                                addTooltip: t('vertaling.addTooltip'),
                                deleteTooltip: t('vertaling.deleteTooltip'),
                                editTooltip: t('vertaling.editTooltip'),
                                filterRow: {
                                    filterTooltip: t('vertaling.filterTooltip'),
                                },
                                editRow: {
                                    deleteText: t('vertaling.deleteText'),
                                    cancelTooltip: t('vertaling.cancelTooltip'),
                                    saveTooltip: t('vertaling.submit '),
                                },
                            },
                            grouping: {
                                placeholder: t('vertaling.placeholder'),
                                groupedBy: t('vertaling.groupedBy'),
                            },
                            header: {
                                actions: t('vertaling.actions'),
                            },
                            pagination: {
                                labelDisplayedRows: t('vertaling.labelDisplayedRows'),
                                labelRowsPerPage: t('vertaling.labelRowsPerPage'),
                                firstAriaLabel: t('vertaling.firstAriaLabel'),
                                firstTooltip: t('vertaling.firstAriaLabel'),
                                previousAriaLabel: t('vertaling.previousAriaLabel'),
                                previousTooltip: t('vertaling.previousAriaLabel'),
                                nextAriaLabel: t('vertaling.nextAriaLabel'),
                                nextTooltip: t('vertaling.nextAriaLabel'),
                                lastAriaLabel: t('vertaling.lastAriaLabel'),
                                lastTooltip: t('vertaling.lastAriaLabel'),
                            },
                            toolbar: {
                                addRemoveColumns: t('vertaling.addRemoveColumns'),
                                nRowsSelected: t('vertaling.nRowsSelected'),
                                showColumnsTitle: t('vertaling.showColumnsTitle'),
                                showColumnsAriaLabel: t('vertaling.showColumnsTitle'),
                                exportTitle: t('vertaling.exportTitle'),
                                exportAriaLabel: t('vertaling.exportTitle'),
                                exportName: t('vertaling.exportName'),
                                searchTooltip: t('vertaling.searchTooltip'),
                                searchPlaceholder: t('vertaling.searchTooltip'),
                                exportCSVName: t('vertaling.exportName'),
                                exportPDFName: t('vertaling.exportPDFName'),
                            },
                        }}
                    />
                </div>
            </>
        </div>
    );
};

export default Barrels;