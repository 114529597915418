import { PageTitle } from "../../utils/PageTitle";
import { useTranslation } from 'react-i18next';
import { firestore } from "../../firebase/config";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSessionStorage } from "../../logic/useSessionStorage";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { updateContentVineyardStep } from "../../firebase/vineyardstep";
import GoBackButton from "../../components/FormComponents/GoBackButton";
import { doc, onSnapshot } from "firebase/firestore";

const VineyardStepContentDetail = () => {
    PageTitle("Edit Vineyard step");
    const params = useParams();
    const navigate = useNavigate();
    const [currentVineyardStep, setCurrentVineyardStep] = useState("");
    const [currentStepContent, setCurrentStepContent] = useState([]);
    const { register, handleSubmit } = useForm();
    const [isLoading, setIsLoading] = useState(true);
    const [selectedType, setSelectedType] = useState([]);
    const [selectedValidation, setSelectedValidation] = useState([]);
    const [selectedValidation1, setSelectedValidation1] = useState([]);
    const [selectedValidation2, setSelectedValidation2] = useState([]);
    const [error, setError] = useState("hidden");
    const { t } = useTranslation();
    const [currentWinery, setCurrentWinery] = useSessionStorage(
        "currentwinery",
        ""
    );

    const validationTypes = ["notEmpty", "null"];
    const validation1Types = ["greaterThen", "lesserThen", 'null'];

    const typeOptions = [
        "geopoint",
        "multilinetext",
        "image",
        "label",
        "option",
        "decimal",
        "text",
        "timestamp",
        'section'
    ];

    const formClassname = "ui big form twelve wide column ";
    let currentStep;
    let currentContent;
    useEffect(() => {
        setIsLoading(true);
        const vineyardStepRef = doc(firestore, "winegrowers", currentWinery, "vineyardSteps", params.id)

        const unsubscribe = onSnapshot(vineyardStepRef, (doc) => {
            if (doc.exists) {
                currentStep = doc.data();
            }
            currentContent = currentStep.content.find(
                (s) => s.order == params.stepnumber
            );
            setCurrentVineyardStep(currentStep);
            setCurrentStepContent(currentContent);

            setIsLoading(false);
        });
        return () => unsubscribe();
    }, []);

    function generateUserForm() {
        const form = [];
    
        const generateField = (key, value, type, extraProps) => (
            <div className="fields" style={{ justifyContent: "center", marginTop: "20px" }}>
                <div className="twelve wide field">
                    <label>
                        {t(`translation.vineyardSteps.${key}`)}
                        <input
                            type={type}
                            {...register(key)}
                            htmlFor={key}
                            defaultValue={value}
                            style={{ textAlign: "center" }}
                            {...extraProps} />
                    </label>
                </div>
            </div>
        );
    
        if (currentStepContent) {
            const sortedContent = Object.keys(currentStepContent)
                .sort()
                .reduce((acc, key) => {
                    acc[key] = currentStepContent[key];
                    return acc;
                }, {});
    
            for (const [key, value] of Object.entries(sortedContent)) {
                let element;
    
                switch (key) {
                    case "key":
                        element = generateField(key, value, "text", { readOnly: true });
                        break;
                    case "order":
                        element = generateField(key, value, "number", { required: "required" });
                        break;
                    case "type":
                    case "validation1":
                    case "validation2":
                    case "validation":
                        element = (
                            <div className="fields" style={{ justifyContent: "center", marginTop: "20px" }}>
                                <div className="twelve wide field">
                                    <label>
                                        {t(`translation.vineyardSteps.${key}`)}
                                        <Select
                                            label={key}
                                            htmlFor={key}
                                            onChange={(selection, action) => {
                                                const handlerMap = {
                                                    type: handleTypeChange,
                                                    validation1: handleValidation1Change,
                                                    validation2: handleValidation2Change,
                                                    validation: handleValidationChange,
                                                };
                                                handlerMap[key](selection, action);
                                            }}
                                            options={typeOptions.map((value) => ({ label: value, value }))}
                                            defaultValue={{ label: value, value }}
                                        />
                                    </label>
                                    <div style={{ display: "none" }}>
                                        <input {...register(key)} htmlFor={key} value={value} readOnly />
                                    </div>
                                </div>
                            </div>
                        );
                        break;
                    default:
                        element = generateField(key, value, "text");
                }
    
                form.push(element);
            }
        }
    
        return form;
    }

    const onSubmit = async (data) => {
        try {
            // if (selectedType == "") {
            //   setError("visible");
            // } else {
            setError("hidden");
            const newDoc = generateNewVineyardStep(data);
            await updateContentVineyardStep(currentWinery, params.id, newDoc);
            navigate("/");
        } catch (error) {
            console.error(error);
        }
    };
    const handleTypeChange = (selection, action) => {
        setSelectedType(selection.value);
    };

    const handleValidation1Change = (selection, action) => {
        setSelectedValidation1(selection.value);
    };

    const handleValidation2Change = (selection, action) => {
        setSelectedValidation2(selection.value);
    };

    const handleValidationChange = (selection, action) => {
        setSelectedValidation(selection.value);
    }

    function generateNewVineyardStep(data) {
        let newDoc;
        if (selectedType == "") {
            data.type = currentStepContent["type"];
        }
        if (selectedValidation1 == "") {
            data.validation1 = currentStepContent["validation1"];
        }
        if (selectedValidation2 == "") {
            data.validation2 = currentStepContent["validation2"];
        }

        data["order"] = Number(data["order"]);

        if (data["validation"] === "") {
            data["validation"] = null;
        }
        if (data["validationValue"] === "") {
            data["validationValue"] = null;
        }
        if (data["validation1"] === "") {
            data["validation1"] = null;
        }
        if (data["validation1Value"] === "") {
            data["validation1Value"] = null;
        } else {
            data["validation1Value"] = Number(data["validation1Value"]);
        }
        if (data["validation2"] === "") {
            data["validation2"] = null;
        }
        if (data["validation2Value"] === "") {
            data["validation2Value"] = null;
        } else {
            data['validation2Value'] = Number(data["validation2Value"]);
        }

        newDoc = { ...currentVineyardStep };
        const index = currentVineyardStep.content
            .map((x) => x.key)
            .indexOf(data["key"]);
        newDoc.content[index] = data;
        return newDoc;
    }

    return (
        <div>
            <div
                className="add-form-class"
                style={{
                    maxWidth: 960,
                    margin: "50px auto",
                    marginTop: "110px",
                }}
            >
                <h1 style={{ color: "#7f7f7f" }}>{t('translation.vineyardSteps.vineyardStepInfo')}</h1>
                <GoBackButton />
                <div
                    className="ui grid stackable"
                    style={{ justifyContent: "center", marginTop: "-90px" }}
                >
                    <form
                        className={formClassname}
                        style={{
                            border: "3px solid #B3B3B3",
                            marginTop: "100px",
                            borderRadius: "50px",
                            background: "#edeaea",
                            borderColor: "#ac9e66",
                        }}
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        {!isLoading && generateUserForm()}
                        <p style={{ visibility: error }} className="text-danger">
                            {t('translation.vineyardSteps.stepTypeNotSelected')}
                        </p>
                        <button
                            type="submit"
                            className="ui submit large grey button right floated  button-vinyards"
                            style={{ float: "none", marginTop: "30px", width: "25%" }}
                        >
                            {t('translation.vineyardSteps.updateVineyardStep')}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};
export default VineyardStepContentDetail;