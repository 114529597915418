import React from 'react'

const Divider = ({ title, hidden }) => {
    const style = {
        display: hidden === undefined || hidden === false ? 'flex' : 'none',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        margin: '30px 15px 20px 15px',
        fontSize: '2em',
        fontWeight: 'bold',
        borderBottom: '2px solid #80795d',
    }

    return <div style={style}>{title ? title : "Title"}</div>
}

export default Divider