import { getFirestore, doc, setDoc, updateDoc, collection } from "firebase/firestore";
import { firestore, auth } from './config';

// Create Employee Document
export const createEmployeeDocument = async (uid, { email, trustedForRegistration, role, winery }) => {
  const userProfile = {
    email: email,
    registrationFinished: false,
    wineries: [{
      approved: true,
      accepted: false,
      trustedForRegistration: trustedForRegistration,
      role: role,
      winegrower: winery,
    }]
  };
  
  await setDoc(doc(firestore, "users", uid), userProfile);
};

// Update User Profile Document
export const updateUserProfileDocument = async (user) => {
  const docRef = doc(firestore, "users", user.userProfile.uid);
  return updateDoc(docRef, user.userProfile);
};

// Update User Document
export const updateUserDocument = async (id, user) => {
  delete user.id;
  const docRef = doc(firestore, "users", id);
  return updateDoc(docRef, user);
};

// Approve User Document
export const approveUserDocument = async (userDocumentId, wineries, winery) => {
  wineries.forEach(w => {
    if (w.winegrower === winery) {
      w.approved = true;
    }
  });
  
  const docRef = doc(firestore, "users", userDocumentId);
  return updateDoc(docRef, { wineries: wineries });
};

// Disapprove User Document
export const disapproveUserDocument = async (userDocumentId, wineries, winery) => {
  wineries.forEach(w => {
    if (w.winegrower === winery) {
      w.approved = false;
    }
  });
  
  const docRef = doc(firestore, "users", userDocumentId);
  return updateDoc(docRef, { wineries: wineries });
};
