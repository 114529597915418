import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GoBackButton from '../components/FormComponents/GoBackButton';
import { getFirestore, doc, collection, query, where, onSnapshot } from 'firebase/firestore';
import { updateRegistration } from '../firebase/registrations';
import { useSessionStorage } from '../logic/useSessionStorage';
import { timestampToDate } from '../utils/DateConversions';
import { PageTitle } from '../utils/PageTitle';
import { firestore } from '../firebase/config';
import MaterialTable from '@material-table/core';


const ReviewRegistrations = () => {
    PageTitle('Review Registrations');
    const [regsToApprove, setRegsToApprove] = useState([]);
    const [regsList, setRegs] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const lang = localStorage.getItem('Lang');
    const navigate = useNavigate();
    const [currentWinery, setCurrentWinery] = useSessionStorage('currentwinery', '');
    const { t } = useTranslation();
    const [cellarSteps, setCellarSteps] = useState([]);
    const [vineyardSteps, setVineyardSteps] = useState([]);

    // Referenties naar firestore database (bv : ophalen van collecties uit firestore db)
    // Alle waarden in de variabelen gaan verloren éénmaal de useEffect volledig uitgevoerd is
    
    const processRegistrations = () => {
        // Check if all necessary data is available
        if (!regsList.length || !cellarSteps.length || !vineyardSteps.length) {
            return;
        }
    
        const processedRegsList = regsList.map((reg) => {

            // Clone the original object to avoid mutating state
            const newReg = { ...reg };
            // overlopen van alle registraties
            newReg.date = timestampToDate(newReg.date, "DD MMM YYYY, H:mm:ss");

            if (newReg.type === 'vineyardStep') {
                for (let h = 0; h < vineyardSteps.length; h++) {
                    if (vineyardSteps[h].id === newReg.stepId) {
                        if (lang === '"en"') {
                            newReg.stepId = vineyardSteps[h].name;
                        } else if (lang === '"fr"') {
                            newReg.stepId = vineyardSteps[h].nameFR;
                        } else if (lang === '"it"') {
                            newReg.stepId = vineyardSteps[h].nameIT;
                        } else if (lang === '"nl"') {
                            newReg.stepId = vineyardSteps[h].nameNL;
                        }
                    }
                }
            } else {
                for (let h = 0; h < cellarSteps.length; h++) {
                    if (cellarSteps[h].id === newReg.stepId) {
                        if (lang === '"en"') {
                            newReg.stepId = cellarSteps[h].name;
                        } else if (lang === '"fr"') {
                            newReg.stepId = cellarSteps[h].nameFR;
                        } else if (lang === '"it"') {
                            newReg.stepId = cellarSteps[h].nameIT;
                        } else if (lang === '"nl"') {
                            newReg.stepId = cellarSteps[h].nameNL;
                        }
                    }
                }
            }
        
            return newReg;
        });
        setRegsToApprove(processedRegsList);
    };
    
    useEffect(() => {
        processRegistrations();
    }, [regsList, cellarSteps, vineyardSteps]);

    useEffect(() => {
        // Firestore references and subscriptions
        const regsRef = query(
            collection(firestore, 'winegrowers', currentWinery, 'registrations'),
            where('waitingForReview', '==', true)
        );

        const unsubscribeRegs = onSnapshot(regsRef, (snapshot) => {
            const newRegsList = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setRegs(newRegsList);
        });

        setLoading(false);

        const cellarStepsRef = collection(firestore, 'cellarSteps');
        const unsubscribeCellar = onSnapshot(cellarStepsRef, (querySnapshot) => {
            const steps = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setCellarSteps(steps);
        });

        const vineyardStepsRef = collection(firestore, 'vineyardSteps');
        const unsubscribeVineyard = onSnapshot(vineyardStepsRef, (querySnapshot) => {
            const steps = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setVineyardSteps(steps);
        });

        return () => {
            unsubscribeRegs();
            unsubscribeCellar();
            unsubscribeVineyard();
        };
    }, [currentWinery]);

    const onApprove = async (id) => {
        setLoading(true);
        const regToApprove = regsList.find((r) => r.id === id);

        if (!regToApprove) {
            console.error("Registration not found");
            setLoading(false);
            return;
        }

        regToApprove.waitingForReview = false;

        try {
            await updateRegistration(currentWinery, id, regToApprove);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
            navigate(`/reviewregistrations`);
        }
    };


    // Het weergeven van alle gegevens van alle registraties die nog goedgekeurd moeten worden
    return (
        <div className="flex">
            <h1 style={{ marginTop: '50px', color: '#7f7f7f' }}>{t('vertaling.approve_registration')}</h1>
            <GoBackButton />
            <br />
            <br />
            {regsToApprove.length >= 0 && (
                <div className="batchesList filterList" style={{ marginTop: '10px' }}>
                    <MaterialTable
                        title={t('vertaling.approve_registrations')}
                        data={regsToApprove}
                        columns={[
                            {
                                title: t('vertaling.stap'),
                                field: 'type',
                            },
                            {
                                title: t('vertaling.typeStap'),
                                field: 'type',
                                lookup: {
                                    vineyardStep: t('vertaling.vineyard1'),
                                    cellarStep: t('vertaling.cellar'),
                                    harvestStep: t('vertaling.harvest'),
                                },
                            },
                            {
                                title: t('vertaling.registratiedatum'),
                                field: 'date',
                                sorting: false,
                            },
                            {
                                title: t('vertaling.colCreatedBy'),
                                field: 'email',
                            },
                            {
                                title: t('vertaling.beschrijving'),
                                field: 'description',
                            },
                            {
                                title: '',
                                field: '',
                                sorting: false,
                                filtering: false,
                                render: (rowData) => (
                                    <div
                                        className="btn-wrapper"
                                        style={{
                                            display: 'flex',
                                            textAlign: 'center',
                                            margin: 'auto',
                                        }}
                                    >
                                        <button
                                            className="ui primary button login"
                                            style={{ width: 'auto' }}
                                            onClick={() =>
                                                navigate(`/history/edit/${rowData.id}`)
                                            }
                                        >
                                            {t('vertaling.details')}
                                        </button>
                                        <button
                                            className="ui primary button login"
                                            style={{ width: 'auto' }}
                                            onClick={() => onApprove(rowData.id)}
                                        >
                                            {t('vertaling.approve')}
                                        </button>
                                    </div>
                                ),
                            },
                        ]}
                        options={{
                            search: true,
                            filtering: true,
                            exportButton: true,
                            exportAllData: true,
                            headerStyle: {
                                backgroundColor: '#cecaca',
                                color: '#FFF',
                            },
                            emptyRowsWhenPaging: false,
                            paging: true,
                            pageSize: 50,
                            pageSizeOptions: [
                                10,
                                20,
                                25,
                                50,
                            ],
                        }}
                        // Dit wordt gebruikt om alles te vertalen in de tabel die hierboven wordt gedefineerd
                        localization={{
                            body: {
                                emptyDataSourceMessage: t('vertaling.emptyDataSourceMessage'),
                                addTooltip: t('vertaling.addTooltip'),
                                deleteTooltip: t('vertaling.deleteTooltip'),
                                editTooltip: t('vertaling.editTooltip'),
                                filterRow: {
                                    filterTooltip: t('vertaling.filterTooltip'),
                                },
                                editRow: {
                                    deleteText: t('vertaling.deleteText'),
                                    cancelTooltip: t('vertaling.cancelTooltip'),
                                    saveTooltip: t('vertaling.submit '),
                                },
                            },
                            grouping: {
                                placeholder: t('vertaling.placeholder'),
                                groupedBy: t('vertaling.groupedBy'),
                            },
                            header: {
                                actions: t('vertaling.actions'),
                            },
                            pagination: {
                                labelDisplayedRows: t('vertaling.labelDisplayedRows'),
                                labelRowsPerPage: t('vertaling.labelRowsPerPage'),
                                firstAriaLabel: t('vertaling.firstAriaLabel'),
                                firstTooltip: t('vertaling.firstAriaLabel'),
                                previousAriaLabel: t('vertaling.previousAriaLabel'),
                                previousTooltip: t('vertaling.previousAriaLabel'),
                                nextAriaLabel: t('vertaling.nextAriaLabel'),
                                nextTooltip: t('vertaling.nextAriaLabel'),
                                lastAriaLabel: t('vertaling.lastAriaLabel'),
                                lastTooltip: t('vertaling.lastAriaLabel'),
                            },
                            toolbar: {
                                addRemoveColumns: t('vertaling.addRemoveColumns'),
                                nRowsSelected: t('vertaling.nRowsSelected'),
                                showColumnsTitle: t('vertaling.showColumnsTitle'),
                                showColumnsAriaLabel: t('vertaling.showColumnsTitle'),
                                exportTitle: t('vertaling.exportTitle'),
                                exportAriaLabel: t('vertaling.exportTitle'),
                                exportName: t('vertaling.exportName'),
                                searchTooltip: t('vertaling.searchTooltip'),
                                searchPlaceholder: t('vertaling.searchTooltip'),
                                exportCSVName: t('vertaling.exportName'),
                                exportPDFName: t('vertaling.exportPDFName'),
                            },
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default ReviewRegistrations;