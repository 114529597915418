import React from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <div className="select" id="selectList">
      <select id="selectLang" value={i18n.language} onChange={changeLanguage}>
        <option value="en" className="option1">EN</option>
        <option value="fr" className="option1">FR</option>
        <option value="nl" className="option1">NL</option>
      </select>
    </div>
  );
};

export default LanguageSelector;
