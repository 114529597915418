import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { createBatchDocument } from '../../firebase/batch';
import { useSessionStorage } from '../../logic/useSessionStorage';
import { useAuth } from '../../context/AuthContext';
import { PageTitle } from '../../utils/PageTitle';
import GoBackButton from '../../components/FormComponents/GoBackButton';
import { firestore } from '../../firebase/config';
import Select from 'react-select';
import AddGrapeVariety from '../../components/FormComponents/addGrapeVariety';
import AddWineStorage from '../../components/FormComponents/addWineStorages';
import { collection, onSnapshot, addDoc, doc } from 'firebase/firestore';


const CreateBatch = () => {
  const [arrayGrapeVarietiesComponents, setArrayGrapeVarietiesComponents] =
    useState([]);
  const [arrayWineStoragesComponents, setArrayWineStoragesComponents] =
    useState([]);
  const [selectedHarvestYear, setSelectedHarvestYear] = useState(undefined);
  const [harvestYears, setHarvestYears] = useState([]);
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const { register, handleSubmit } = useForm();
  const [currentWinery, setCurrentWinery] = useSessionStorage(
    'currentwinery',
    ''
  );
  const formClassname = 'ui big form twelve wide column';
  const [indexGrapeVariety, setIndexGrapeVariety] = useState(0);
  const [indexWineStorage, setIndexWineStorage] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    const harvestYearCollection = collection(firestore, 'winegrowers', currentWinery, 'harvestYear');

  const unsubscribe = onSnapshot(harvestYearCollection, (snapshot) => {
      let harvestYearData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setHarvestYears(harvestYearData);
      setLoading(false);
    });
    
    return () => unsubscribe();
  }, [currentWinery]);

  const onSubmit = (data) => {
    try {
      setLoading(true);
      let agriculturalParcels = [];
      let wineStorages = [];
      data.email = currentUser.email;

      //add agricultural parcels to new batch
      for (let [key, value] of Object.entries(data)) {
        if (key.includes('agriculturalParcelId')) {
          let numberOfCurrentItem = key.substring(key.length - 1);
          let object = {};
          object.id = value;
          delete data[key];
          for (let [key2, value2] of Object.entries(data)) {
            if (
              key2.includes(
                `agriculturalParcelPercentage${numberOfCurrentItem}`
              )
            ) {
              object.percentage = Number(value2);
              agriculturalParcels.push(object);
              delete data[key2];
            }
          }
        }
      }
      data.agriculturalParcels = agriculturalParcels;

      //add wine storages to new batch
      for (let [key, value] of Object.entries(data)) {
        if (key.includes('wineStorageId')) {
          let numberOfCurrentItem = key.substring(key.length - 1);
          let object = {};
          object.id = value;
          delete data[key];
          for (let [key2, value2] of Object.entries(data)) {
            if (key2.includes(`liters${numberOfCurrentItem}`)) {
              object.litersForReport = Number(value2);
              wineStorages.push(object);
              delete data[key2];
            }
          }
        }
      }
      data.wineStorages = wineStorages;
      data.createDate = new Date(data.createDate);
      createBatchDocument(currentWinery, data).then(() => {
        setLoading(false);
        navigate('/batches');
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleHarvestYearChange = (selection, action) => {
    setSelectedHarvestYear(selection.value);
  };

  const addGrapeVariety = () => {
    arrayGrapeVarietiesComponents.push(
      <AddGrapeVariety register={register} index={indexGrapeVariety} />
    );
    setIndexGrapeVariety(indexGrapeVariety + 1);
  };

  const addWineStorage = () => {
    arrayWineStoragesComponents.push(
      <AddWineStorage register={register} index={indexWineStorage} />
    );
    setIndexWineStorage(indexWineStorage + 1);
  };

  return (
    <div>
      <h1>{t('translation.createBatch.createBatch')}</h1>
      <GoBackButton />
      <br />
      <br />
      <div className="ui grid stackable">
        <form
          className={formClassname}
          style={{
            border: '3px solid #B3B3B3',
            marginTop: '100px',
            margin: 'auto',
            borderRadius: '50px',
            background: '#edeaea',
            borderColor: '#ac9e66',
            maxWidth: '960px',
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="fields" style={{ justifyContent: 'center' }}>
            <div className="twelve wide field">
              <label>
                <p>{t("translation.createBatch.createDate")}</p>
                <input
                  type="date"
                  {...register('createDate')}
                  htmlFor="createDate"
                  required
                  style={{ textAlign: 'center' }} />
              </label>
            </div>
          </div>
          <div className="fields" style={{ justifyContent: 'center' }}>
            <div className="twelve wide field">
              <label>
                <p>{t("translation.createBatch.harvestYear")}</p>
                <Select
                  label="harvestYearId"
                  htmlFor="harvestYearId"
                  required
                  style={{ textAlign: 'center' }}
                  onChange={(selection, action) =>
                    handleHarvestYearChange(selection, action)
                  }
                  options={harvestYears.map((year) => ({
                    value: year.id,
                    label: year.name,
                  }))}
                />
              </label>
            </div>
          </div>
          <div style={{ display: 'none' }}>
            <input
              {...register('harvestYearId')}
              htmlFor="harvestYearId"
              value={selectedHarvestYear}
              readOnly />
          </div>
          <div className="fields" style={{ justifyContent: 'center' }}>
            <div className="twelve wide field">
              <label>
                <p>{t("translation.createBatch.historic")}</p>
                <input
                  type="text"
                  {...register('historic')}
                  htmlFor="historic"
                  required
                  style={{ textAlign: 'center' }} />
              </label>
            </div>
          </div>
          <div className="fields" style={{ justifyContent: 'center' }}>
            <div className="twelve wide field">
              <label>
                <p>{t("translation.createBatch.name")}</p>
                <input
                  type="text"
                  {...register('name')}
                  htmlFor="name"
                  required
                  style={{ textAlign: 'center' }} />
              </label>
            </div>
          </div>

          <h2 style={{ marginTop: '20px' }}>
            {t('vertaling.vineyard_grapevarieties')}
          </h2>
          {arrayWineStoragesComponents.map((component) => (
            <div>{component}</div>
          ))}
          <button
            onClick={addWineStorage}
            className="ui submit grey button right floated"
            style={{ float: 'none', marginBottom: '20px', height: '40px' }}
          >
            <p>{t("translation.createBatch.addWineStorage")}</p>
          </button>
          <button
            type="submit"
            className="ui submit black button right floated"
            style={{
              float: 'none',
              margin: 'auto',
              display: 'block',
              height: '40px',
            }}
          >
            <p>{t("translation.createBatch.createNewBatch")}</p>
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateBatch;