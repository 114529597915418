import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { firestore } from '../../firebase/config';
import { updateParcelDocument } from '../../firebase/parcel';
import { PageTitle } from '../../utils/PageTitle';
import { useSessionStorage } from '../../logic/useSessionStorage';
import styles from './MyParcels.module.css';
import GoBackButton from '../../components/FormComponents/GoBackButton';
import { doc, collection, query, onSnapshot, where } from 'firebase/firestore';
import MaterialTable from '@material-table/core';

const MyParcels = () => {
  PageTitle('Parcels');
  const navigate = useNavigate();
  const [currentWinery, setCurrentWinery] = useSessionStorage(
    'currentwinery',
    ''
  );
  const { t } = useTranslation();
  const [parcels, setParcels] = useState([]);
  const [agriculturalParcels, setAgriculturalParcels] = useState([]);
  const [isLoading, setLoading] = useState([]);
  const [selectedParcel, setSelectedParcel] = useState('');
  const [nameSelectedParcel, setNameSelectedParcel] = useState(t('translation.none'));
  const [errorMessage, setErrorMessage] = useState('');


  const fetchAgriculturalParcels = (event, rowData) => {
    setSelectedParcel(rowData.id);
    setNameSelectedParcel(rowData.name);
    const agriculturalParcelsQuery = query(
      collection(doc(firestore, 'winegrowers', currentWinery), 'agriculturalParcels'),
      where('parcelId', '==', rowData.id)
    );

    const unsubscribeAgriculturalParcels = onSnapshot(agriculturalParcelsQuery, (snapshot) => {
      const agriculturalParcelDoc = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAgriculturalParcels(agriculturalParcelDoc);
    });
    return () => {
      unsubscribeAgriculturalParcels();
    };
  };

  const winery = currentWinery;
  const onSubmitParcel = async (newData) => {
    try {
      setLoading(true);
      await updateParcelDocument(winery, newData.id, newData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const parcelsQuery = query(collection(doc(firestore, 'winegrowers', currentWinery), 'parcels'));

    const unsubscribeParcels = onSnapshot(parcelsQuery, (snapshot) => {
      const parcelDoc = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setParcels(parcelDoc);
    });
    return () => {
      unsubscribeParcels();
    };
  }, [currentWinery]);

  const navigateToNewArgiculturalParcel = () => {
    if (selectedParcel !== '') {
      navigate(`/myparcels/myagriculturalparcels/create/${selectedParcel}`);
    } else {
      setErrorMessage(
        <h2 className="alert alert-danger">
          {t("translation.parcelNotSelected")}
        </h2>
      );
    }
  };

  return (
    <div>
      <div>
        <h1 className={styles.title}>{t('translation.myParcels')}</h1>
      </div>

      <div>
        <GoBackButton location={"/"} />
        <br />
      </div>
      <div>
        <br />
        <p>{t('translation.myParcelsExplanation')}</p>
      </div>
      {errorMessage}
      <br />

      <div className="newvineyard">
        <Link to="/myparcels/create" style={{ color: 'white' }}>
          <button className="ui primary button login" style={{ marginTop: '25px' }}>
            {t('translation.createParcel')}
          </button>
        </Link>
      </div>

      <div className={styles.parcel_table}>
        <MaterialTable
          title=""
          data={parcels}
          onRowClick={fetchAgriculturalParcels}
          columns={[
            {
              title: t('translation.name'),
              field: 'name',
              editable: 'never',
            },
            {
              title: t('translation.parcelNumber'),
              field: 'number',
              type: 'numeric',
              defaultSort: "asc",
              cellStyle: {
                textAlign: 'left',
              },
            },
            {
              title: t('translation.colSize'),
              field: 'areaSizeAre',
              render: rowData => {
                const areaSizeAre = rowData.areaSizeAre !== null ? Number(rowData.areaSizeAre) : 0;
                const areaSizeCentiare = rowData.areaSizeCentiare !== null ? Number(rowData.areaSizeCentiare) : 0;
                const result = areaSizeAre + (areaSizeCentiare / 100);

                return <div>{isNaN(result) ? 0 : result}</div>;
              },
            }
          ]}
          options={{
            search: true,
            filtering: true,
            exportButton: true,
            exportAllData: true,
            headerStyle: {
              backgroundColor: '#cecaca',
              color: '#FFF',
              textAlign: 'left',
            },
            filterCellStyle: {
              alignContent: 'left',
            },
            emptyRowsWhenPaging: false,
            paging: true,
            pageSize: 50,
            pageSizeOptions: [
              10,
              20,
              25,
              50,
            ],
          }}
          actions={[
            {
              icon: 'save',
              tooltip: 'Save User',
              onClick: (event, rowData) =>
                navigate(`/myparcels/details/${rowData.id}`),
            },
          ]}
          components={{
            Action: (props) => (
              <div className="flex_grape">
                <button
                  className="ui primary button login"
                  onClick={(event) => props.action.onClick(event, props.data)}
                  color="primary"
                  style={{ textTransform: 'none' }}
                >
                  {t("vertaling.details")}
                </button>
              </div>
            ),
          }}
        />
      </div>
      <h1 style={{ color: '#7f7f7f', marginTop: 40, marginBottom: 0 }}>
        {t('translation.grapeVarietyOverview')}
      </h1>

      <h5 style={{ color: "#7f7f7f", marginTop: 40, marginBottom: 0 }}>
        {t('translation.selectedParcel')} {nameSelectedParcel}
      </h5>

      <div className="newvineyard">
        <button className="ui primary button login" onClick={navigateToNewArgiculturalParcel} style={{ marginTop: '25px' }}>
          {t('translation.createAgriculturalParcel')}
        </button>
      </div>
      <div className={styles.parcel_table}>
        <MaterialTable
          title=""
          data={agriculturalParcels}
          columns={[
            {
              title: t('translation.name'),
              field: 'name',
            },
            {
              title: t('translation.grapeVariety'),
              field: 'grapeVariety',
            },
            {
              title: t('translation.numberOfPlants'),
              field: 'numberOfPlants',
              type: 'numeric',
              cellStyle: {
                textAlign: 'left',
              },
            },
            {
              title: t('translation.areaPlantedAre'),
              field: 'areaPlantedAre',
              render: rowData => {
                const areaPlantedAre = rowData.areaPlantedAre !== null ? rowData.areaPlantedAre : 0;
                const areaPlantedCentiare = rowData.areaPlantedCentiare !== null ? rowData.areaPlantedCentiare : 0;
                const result = areaPlantedAre + areaPlantedCentiare / 100;
                return <div>{isNaN(result) ? 0 : result}</div>;
              },
            },
          ]}
          options={{
            search: true,
            filtering: true,
            exportButton: true,
            exportAllData: true,
            headerStyle: {
              backgroundColor: '#cecaca',
              color: '#FFF',
              textAlign: 'left',
            },
            emptyRowsWhenPaging: false,
            paging: true,
            pageSize: 50,
            pageSizeOptions: [
              10,
              20,
              25,
              50,
            ],
          }}
          actions={[
            {
              onClick: (event, rowData) =>
                navigate(`/agriculturalparcel/details/${rowData.id}/${rowData.parcelId}/${rowData.vineyardId}`),
            },
          ]}
          components={{
            Action: (props) => (
              <div className="flex_grape">
                <button
                  className="ui primary button login"
                  onClick={(event) => props.action.onClick(event, props.data)}
                  color="primary"
                  style={{ textTransform: 'none' }}
                >
                  {t("vertaling.details")}
                </button>
              </div>
            ),
          }}
        />
      </div>
    </div>
  );
};

export default MyParcels;