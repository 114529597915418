import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import LanguageSelector from './LanguageSelector';

const NavUserLoggedIn = () => {
    const { logout } = useAuth();
    const { currentUser } = useAuth();
    const { t } = useTranslation();

    const logoutUser = async () => {
        sessionStorage.clear();
        await logout();
    };
    return (
        <>
            <Link to={"/"} className="nav_logo">Craft Your Taste - By Tastefever</Link>

            <div className="collapse navbar-collapse" id="navbarNavDropdown">
                <div className='navbar-nav ml-auto' style={{ paddingRight: "120px" }}>
                    <Link to={"/profile"} className="user-button">
                        {currentUser !== undefined &&
                            <button className="ui primary button nav-button">
                                {t("vertaling.welcome_nav")}{' '}
                                <span id={"NavbarDisplayName"}>{currentUser.displayName}</span>
                            </button>
                        }
                    </Link>
                    <Link to="/login">
                        <button className="ui primary button nav-button" onClick={logoutUser}>
                            {t("vertaling.logout_nav")}
                        </button>
                    </Link>
                    <LanguageSelector />
                </div>
            </div>
        </>
    )
}

export default NavUserLoggedIn