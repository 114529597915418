import { useTranslation } from 'react-i18next';
import { send } from 'emailjs-com';
import 'firebase/auth';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useFirestore } from '../../context/FirestoreContext';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { PageTitle } from '../../utils/PageTitle';
import { useSessionStorage } from '../../logic/useSessionStorage';
import { firestore } from '../../firebase/config';

const DeleteEmployee = () => {
    PageTitle("Delete Employee");
    const [currentWinery, setCurrentWinery] = useSessionStorage('currentwinery', '');
    const navigate = useNavigate();
    const params = useParams();
    const { handleSubmit } = useForm();
    const [user, setUser] = useState(null);
    const { removeEmployeeFromWinery, getWineryDocument } = useFirestore();
    const { t } = useTranslation();
    const [emailParams, setEmailParams] = useState({
        email_WN: params.email,
        subject: t('vertaling.subject_activate'),
        aanspreking: t('vertaling.aanspreking_general'),
        employee_activated_deactivated: t('vertaling.employee_deleted'),
        afsluiting: t('vertaling.afsluiting'),
        alert: t('translation.emails.moreInfo'),
        land: t('translation.words.belgium'),
    });

    //Firestore calls
    const getCurrentWineryDoc = async () => {
        let wineryDoc = await getWineryDocument(currentWinery);
        let updatedEmailParams = emailParams;
        emailParams.winery = wineryDoc.name;
        setEmailParams(updatedEmailParams);
    }

    useEffect(() => {
        getCurrentWineryDoc();

        const userQuery = query(collection(firestore, 'users'), where('email', '==', params.email));
        getDocs(userQuery).then((querySnapshot) => {
            const user = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));

            if (user.length !== 0 && user[0].wineries.some(winery => winery.winegrower === currentWinery)) {
                setUser(user[0]);
            } else {
                navigate(`/employees/disabled`);
            }
        });


    }, [params.id]);

    const onSubmit = async () => {
        sendMail();
        await removeEmployeeFromWinery(user.id, user.wineries, currentWinery);
        navigate(`/employees/disabled`);
    };

    const sendMail = () => {
        try {
            send('service_4g61fk7', 'template_bdlyxo7', emailParams, 'user_EDqZc5RPaMddv4DU2Jmmo').then((response) => {
                // console.log('SUCCESS!', response.status, response.text);
            });
        }
        catch (error) {
            console.error(error);
        }
    }

    if (user != null) {
        return (
            <div className="noCenter wrapper">
                <h1 className="userDetailTitle">
                    {t('translation.deleteEmployee')}
                </h1>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="wrapperUserInfo">
                        <h2 className="detailUserLabel">
                            {t('vertaling.firstname')}
                        </h2>
                        <p className="userValueTxt">{user.firstname}</p>
                    </div>
                    <div className="wrapperUserInfo">
                        <h2 className="detailUserLabel">
                            {t('vertaling.lastname')}
                        </h2>
                        <p className="userValueTxt">{user.lastname}</p>
                    </div>
                    <div className="wrapperUserInfo">
                        <h2 className="detailUserLabel">
                            {t('vertaling.email')}
                        </h2>
                        <p className="userValueTxt">{user.email}</p>
                    </div>
                    <button className="approveBtn" type="submit">
                        {t('translation.words.delete')}
                    </button>
                </form>
            </div>

        );
    } else {
        return (
            <div></div>
        );
    }
};
export default DeleteEmployee;