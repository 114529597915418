import { useState } from "react";
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { createVineyardStep } from "../../firebase/vineyardstep";
import { useSessionStorage } from '../../logic/useSessionStorage';
import GoBackButton from "../../components/FormComponents/GoBackButton";


const NewVineyardStep = () => {
    const formClassname = "ui big form twelve wide column";
    const { register, handleSubmit } = useForm();
    const navigate = useNavigate();

    const selectionTypes = ["multi", 'single'];
    const [selectedSelection, setSelectedSelection] = useState("");
    const [currentWinery, setCurrentWinery] = useSessionStorage('currentwinery', '');
    const { t } = useTranslation();

    const onSubmit = async (data) => {
        try {
            if (selectedSelection == '') {
                data.selection = 'single';
            }
            await createVineyardStep(currentWinery, data);
            navigate(-1);
        } catch (error) {
            console.error(error);
        }
    };
    const handleSelectionChange = (selection, action) => {
        setSelectedSelection(selection.value);
    };
    return (
        <div>
            <div
                className="add-form-container"
                style={{
                    maxWidth: 960,
                    margin: "50px auto",
                    marginTop: "110px",
                }}
            >
               <h1 style={{ color: "#7f7f7f" }}>{t('translation.vineyardSteps.newVineyardStep')}</h1>
                <GoBackButton />
                <br />
                <div className="ui grid stackable">
                    <form
                        className={formClassname}
                        style={{
                            border: "3px solid #B3B3B3",
                            marginTop: "50px",
                            borderRadius: "50px",
                            background: "#edeaea",
                            borderColor: "#ac9e66",
                        }}
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <div
                            className="fields"
                            style={{ justifyContent: "center", marginTop: "20px" }}
                        >
                            <div className="twelve wide field">
                                <label>
                                    <p>{t("translation.vineyardSteps.nameEn")}</p>
                                    <input
                                        type="text"
                                        {...register('nameEN')}
                                        htmlFor="nameEN"
                                        required="required"
                                        style={{ textAlign: "center" }} />
                                </label>
                            </div>
                        </div>
                        <div
                            className="fields"
                            style={{ justifyContent: "center", marginTop: "20px" }}
                        >
                            <div className="twelve wide field">
                                <label>
                                    <p>{t("translation.vineyardSteps.nameFr")}</p>
                                    <input
                                        type="text"
                                        {...register('nameFR')}
                                        htmlFor="nameFR"
                                        required="required"
                                        style={{ textAlign: "center" }} />
                                </label>
                            </div>
                        </div>
                        <div
                            className="fields"
                            style={{ justifyContent: "center", marginTop: "20px" }}
                        >
                            <div className="twelve wide field">
                                <label>
                                    <p>{t("translation.vineyardSteps.nameIt")}</p>
                                    <input
                                        type="text"
                                        {...register('nameIT')}
                                        htmlFor="nameIT"
                                        required="required"
                                        style={{ textAlign: "center" }} />
                                </label>
                            </div>
                        </div>
                        <div
                            className="fields"
                            style={{ justifyContent: "center", marginTop: "20px" }}
                        >
                            <div className="twelve wide field">
                                <label>
                                    <p>{t("translation.vineyardSteps.nameNl")}</p>
                                    <input
                                        type="text"
                                        {...register('nameNL')}
                                        htmlFor="nameNL"
                                        required="required"
                                        style={{ textAlign: "center" }} />
                                </label>
                            </div>
                        </div>
                        <div
                            className="fields"
                            style={{ justifyContent: "center", marginTop: "20px" }}
                        >
                            <div className="twelve wide field">
                                <label>
                                    <p>{t("translation.vineyardSteps.number")}</p>
                                    <input
                                        type="number"
                                        {...register('number')}
                                        htmlFor="number"
                                        required="required"
                                        style={{ textAlign: "center" }} />
                                </label>
                            </div>
                        </div>
                        <div
                            className="fields"
                            style={{ justifyContent: "center", marginTop: "20px" }}
                        >
                            <div className="twelve wide field">
                                <label>
                                    <p>{t("translation.vineyardSteps.selectionType")}</p>
                                </label>
                                <Select
                                    required="required"
                                    label="selection"
                                    htmlFor="selection"
                                    options={selectionTypes.map((label) => ({
                                        label: label,
                                        value: label,
                                    }))}
                                    defaultValue={{
                                        label: 'single',
                                        value: 'single'
                                    }}
                                    onChange={(selection, action) =>
                                        handleSelectionChange(selection, action)
                                    }
                                />
                            </div>
                        </div>
                        <div style={{ display: "none" }}>
                            <input
                                {...register('selection')}
                                htmlFor="selection"
                                value={selectedSelection}
                                readOnly />
                        </div>
                        <button
                            type="submit"
                            className="ui submit large grey button right floated  button-vinyards"
                            style={{ float: "none", marginTop: "30px", width: "25%" }}
                        >
                            {t("translation.vineyardSteps.createVineyardStep")}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};
export default NewVineyardStep;