import { useTranslation } from 'react-i18next';
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { firestore } from "../../firebase/config";
import { createParcel } from "../../firebase/parcel";
import { PageTitle } from "../../utils/PageTitle";
import { useSessionStorage } from "../../logic/useSessionStorage";
import GoBackButton from "../../components/FormComponents/GoBackButton";

const NewParcel = () => {
    PageTitle("Create Parcel");
    const navigate = useNavigate();
    const [currentWinery, setCurrentWinery] = useSessionStorage("currentwinery", "");
    const formClassname = "ui big form twelve wide column";
    const { register, setValue, handleSubmit } = useForm();
    const [error, setError] = useState("hidden");
    const { t } = useTranslation();

    const onSubmit = async (data) => {
        try {
                await createParcel(currentWinery, data);
                navigate("/myparcels");
        } catch (error) {
            console.error(error);
        }
    };


    return (
        <div>
            <div
                className="add-form-container"
                style={{
                    maxWidth: 960,
                    margin: "50px auto",
                    marginTop: "110px",
                }}
            >
                <h1 style={{ color: "#7f7f7f" }}>{t('translation.createParcel')}</h1>

                <GoBackButton />
                <br />

                <div className="ui grid stackable">
                    <form
                        className={formClassname}
                        style={{
                            border: "3px solid #B3B3B3",
                            marginTop: "100px",
                            borderRadius: "50px",
                            borderColor: "#ac9e66",
                        }}
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <div
                            className="fields"
                            style={{ justifyContent: "center", marginTop: "20px" }}
                        >
                            <div className="twelve wide field">
                                <label>
                                    <p>{t("translation.areaPlantedAre")}</p>
                                    <input type="text" {...register('areaSizeAre')} htmlFor="areaSizeAre" />
                                </label>
                            </div>
                            <div className="twelve wide field">
                                <label>
                                    <p>{t("translation.areaPlantedCentiare")}</p>
                                    <input
                                        type="text"
                                        {...register('areaSizeCentiare')}
                                        htmlFor="areaSizeAreCentiare" />
                                </label>
                            </div>
                        </div>
                        <div
                            className="fields"
                            style={{ justifyContent: "center", marginTop: "20px" }}
                        >
                            <div className="twelve wide field">
                                <label>
                                    <p>{t("translation.landRegistrationNumber")}</p>
                                    <input
                                        type="text"
                                        {...register('landRegistrationNumber')}
                                        htmlFor="landRegistrationNumber" />
                                </label>
                            </div>
                        </div>
                        <div
                            className="fields"
                            style={{ justifyContent: "center", marginTop: "20px" }}
                        >
                            <div className="twelve wide field">
                                <label>
                                    <p>{t("translation.name")}</p>
                                    <input type="text" {...register('name')} htmlFor="name" required="required" />
                                </label>
                            </div>
                        </div>
                        <div
                            className="fields"
                            style={{ justifyContent: "center", marginTop: "20px" }}
                        >
                            <div className="twelve wide field">
                                <label>
                                    <p>{t("translation.parcelNumber")}</p>
                                    <input
                                        type="number"
                                        {...register('number')}
                                        htmlFor="number"
                                        required="required" />
                                </label>
                            </div>
                        </div>
                        <div
                            className="fields"
                            style={{ justifyContent: "center", marginTop: "20px" }}
                        >
                            <div className="twelve wide field">
                                <label>
                                    <p>{t("translation.agriculturalRegion")}</p>
                                    <input
                                        type="text"
                                        {...register('agriculturalRegion')}
                                        htmlFor="agriculturalRegion" />
                                </label>
                            </div>
                        </div>
                        <div
                            className="fields"
                            style={{ justifyContent: "center", marginTop: "20px" }}
                        >
                            <div className="twelve wide field">
                                <label>
                                    <p>{t("vertaling.adress")}</p>
                                    <input type="text" {...register('address')} htmlFor="address" />
                                </label>
                            </div>
                        </div>
                        <div
                            className="fields"
                            style={{ justifyContent: "center", marginTop: "20px" }}
                        >
                            <div className="twelve wide field">
                                <label>
                                    <p>{t("translation.zipCode")}</p>
                                    <input type="text" {...register('zipCode')} htmlFor="zipCode" />
                                </label>
                            </div>
                        </div>
                        <div
                            className="fields"
                            style={{ justifyContent: "center", marginTop: "20px" }}
                        >
                            <div className="twelve wide field">
                                <label>
                                    <p>{t("translation.municipality")}</p>
                                    <input type="text" {...register('municipality')} htmlFor="municipality" />
                                </label>
                            </div>
                        </div>
                        <div
                            className="fields"
                            style={{ justifyContent: "center", marginTop: "20px" }}
                        >
                            <div className="twelve wide field">
                                <label>
                                    <p>{t("translation.country")}</p>
                                    <input type="text" {...register('country')} htmlFor="country" />
                                </label>
                            </div>
                        </div>
                        <div
                            className="fields"
                            style={{ justifyContent: "center", marginTop: "20px" }}
                        >


                        </div>
                        <p style={{ visibility: error }} className='text-danger'>{t("translation.connectedVineyardNotSelected")}</p>
                        <button
                            type="submit"
                            className="ui submit large grey button right floated button-vineyards"
                            style={{ float: "none", marginTop: "30px", width: "25%" }}
                        >
                            {t("translation.createParcel")}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default NewParcel;