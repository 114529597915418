import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react'
import DashboardButton from '../../../components/Dashboard/Button'
import DashboardButtonWithNumber from '../../../components/Dashboard/ButtonWithNumber'
import Divider from '../../../components/Dashboard/Divider'
import { Roles } from '../../../logic/Roles'

const ConfigurationButtons = ({ userPermissionLevel, amountOfEmplToApprove }) => {
    const [hidden, setHidden] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        let element = document.querySelector(".configurationButtons");
        element.childNodes.length === 0 ? setHidden(true) : setHidden(false);
    });


    return (
        <>
            <Divider title={t('translation.words.configuration')} hidden={hidden} />
            <div className="configurationButtons row" style={{ margin: "0", width: "100%" }}>
                <DashboardButton
                    url={"/winegrowers/profile"}
                    imgAlt={"dashboard-icon"}
                    imgSrc={"/images/DashboardIcons/Profile.svg"}
                    title={t("vertaling.h4_dashboard")}
                    text={t("vertaling.p4_dashboard")}
                    userPermissionLevel={userPermissionLevel}
                    btnPermissionLevel={Roles().WINEGROWER.permissionLevel}
                />

                <DashboardButton
                    url={"/employees"}
                    imgAlt={"dashboard-icon"}
                    imgSrc={"/images/DashboardIcons/Users.svg"}
                    title={t("vertaling.h5_dashboard")}
                    text={t("vertaling.p5_dashboard")}
                    userPermissionLevel={userPermissionLevel}
                    btnPermissionLevel={Roles().WINEGROWER.permissionLevel}
                />

                {/* <DashboardButtonWithNumber
                    url={"/employees/disabled"}
                    imgAlt={"dashboard-icon"}
                    imgSrc={"/images/DashboardIcons/AddUser.svg"}
                    title={t("vertaling.title_add_user")}
                    text={t("vertaling.p6_dashboard")}
                    number={amountOfEmplToApprove}
                    userPermissionLevel={userPermissionLevel}
                    btnPermissionLevel={Roles().WINEGROWER.permissionLevel}
                /> */}

                <DashboardButton
                    url={"/myvineyards"}
                    imgAlt={"dashboard-icon"}
                    imgSrc={"/images/DashboardIcons/Vineyards.svg"}
                    title={t("vertaling.myvineyards")}
                    text={t("vertaling.p7_dashboard")}
                    userPermissionLevel={userPermissionLevel}
                    btnPermissionLevel={Roles().WINEGROWER.permissionLevel}
                />

                <DashboardButton
                    url={"/myparcels"}
                    imgAlt={"dashboard-icon"}
                    imgSrc={"/images/DashboardIcons/Parcels.svg"}
                    title={t("translation.myParcels")}
                    text={t("translation.myParcelsDescription")}
                    userPermissionLevel={userPermissionLevel}
                    btnPermissionLevel={Roles().WINEGROWER.permissionLevel}
                />

                <DashboardButton
                    url={"/mycellars"}
                    imgAlt={"dashboard-icon"}
                    imgSrc={"/images/DashboardIcons/MyCellars.svg"}
                    title={t("vertaling.h8_dashboard")}
                    text={t("vertaling.p8_dashboard")}
                    userPermissionLevel={userPermissionLevel}
                    btnPermissionLevel={Roles().ADMIN.permissionLevel}
                />

                <DashboardButton
                    url={"/mycellars/barrels"}
                    imgAlt={"dashboard-icon"}
                    imgSrc={"/images/DashboardIcons/Barrels.svg"}
                    title={t("translation.dashboard.barrels.title")}
                    text={t("translation.dashboard.barrels.body")}
                    userPermissionLevel={userPermissionLevel}
                    btnPermissionLevel={Roles().ADMIN.permissionLevel}
                />

                <DashboardButton
                    url={"/harvestyears"}
                    imgAlt={"dashboard-icon"}
                    imgSrc={"/images/DashboardIcons/HarvestYear.svg"}
                    title={t("translation.dashboard.harvestYears.title")}
                    text={t("translation.dashboard.harvestYears.body")}
                    userPermissionLevel={userPermissionLevel}
                    btnPermissionLevel={Roles().WINEGROWER.permissionLevel}
                />

                <DashboardButton
                    url={"/batches"}
                    imgAlt={"dashboard-icon"}
                    imgSrc={"/images/DashboardIcons/ConsultLot.svg"}
                    title={t("vertaling.title_batches")}
                    text={t("vertaling.p9_dashboard")}
                    userPermissionLevel={userPermissionLevel}
                    btnPermissionLevel={Roles().ADMIN.permissionLevel}
                />
            </div>
        </>
    )
}

export default ConfigurationButtons