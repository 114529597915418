// Vertaaltabel met alle vertalingen bestaande uit een sleutel en de bijhorende waarde

const translation = {
  vertaling: {
    new_vineyard: 'Creëer een nieuwe wijngaard',
    select_batch: 'Selecteer een batch',
    name: 'Naam',
    perceel_nr: 'Perceelnummer',
    kadaster_nr: 'Kadasternummer',
    perceel_opp: 'Perceel totaal',
    grapevariety_vineyard: 'Druifsoort',
    vineyard_grapevarieties: 'Druifsoorten',
    vineyard_general: 'Algemeen',
    updatevineyardvariety: 'Update druifsoort',
    myvarieties: 'Mijn druifsoorten',
    size: 'Grootte',
    pruningmethod: 'Snoeimethode',
    sunorientation: 'Zonoriëntatie',
    grapevariety_title: 'Creëer een nieuwe druifsoort',
    grapevariety_btn: 'Creëer druifsoort',
    planting_year: 'Aanplant jaar',
    perceel_deel_opp: 'Perceel deel opp',
    tendrils: 'Ranken',
    grapevariety: 'Druifsoort',
    createvineyardvariety: 'Creëer druifsoort',
    height: 'Hoogte',
    soil: 'Bodem',
    adress: 'Adres',
    city: 'Stad',
    gpslocation: 'Gps locatie',
    latitude: 'Breedtegraad',
    longtitude: 'Lengtegraad ',
    checklocation: 'Controleer locatie',
    vineyard1: 'Wijngaard',
    createvineyard: 'Creëer wijngaard',
    selectpruningmethod: 'Selecteer snoeimethode',
    selectsunorientation: 'Selecteer zonorientatie',
    myvineyards: 'Mijn wijngaarden',
    details: 'Details',
    colSize: 'Oppervlakte',
    colSizeHa: 'Oppervlakte (ha)',

    vineyardInfo: 'Wijngaard info',
    updatevineyard: 'Update wijngaard',
    explainVineyardDetailUI: 'Klik op een wijngaard en dan zie je de landbouwerpercelen/druifsoorten van deze wijngaard',
    title_activate: 'Activeer werknemer?',
    title_deactivate: 'Deactiveer werknemer?',
    title_registratie: 'Registratie wijnhuis',
    companyName: 'Bedrijfsnaam',
    companyAdress: 'Bedrijfsadres',
    companyCountry: 'Land',
    companyZip: 'Postcode',
    companyTel: 'Telefoonnummer',
    companyVat: 'Ondernemingsnummer',
    companyExcise: 'Accijnsnummer',
    companyWebsite: 'Website',
    title_activate_winegrower: 'Activeren wijnbouwers',
    add_winegrower: 'Toevoegen wijnhuis',
    colWinegrower: 'Wijnbouwer',
    title_addemployee: 'Toegang verboden',
    title_gebruikersgegevens: 'Gebruikersgegevens',
    role: 'Rol',
    password: 'Wachtwoord',
    optie2: 'Werknemer',
    submit: 'Opslaan',
    title_add_user: 'Werknemers activeren',
    toevoegen_werknemer: 'Toevoegen werknemer',
    active: 'Actief',
    title_await: 'Wacht op goedkeuring',
    title_tankInfo: 'Aanpassen vat',
    volume_vat: 'Volume vat (l)',
    type_vat: 'Type',
    typeConcrete: 'Beton',
    wood: 'Hout',
    type_wood: 'Type hout',
    date: 'Geboortedatum',
    title_batches: 'Mijn batches',
    batchNotInUse: 'Deze batch is nog niet in gebruik',
    add_batches: 'Batch creëren',
    add_batch: 'Batch',
    colCreatieDatum: 'Creatie datum',
    colCreatedBy: 'Gecreëerd door',
    colHectoliters: 'Hectoliters',
    colHistoric: 'Historie',
    colAvailable: 'Beschikbaar',
    optie_juist: 'ja',
    optie_fout: 'nee',
    title_batchEdit: 'Wijzig batch',
    submit_batchEdit: 'Wijziging opslaan',
    colNoAvailable: 'Niet beschikbaar',
    step1: 'Stap 1',
    step1Text: ' Voeg wijngaard hier toe',
    step2: 'Stap 2',
    step2Text: ' Voeg wijnkelder hier toe',
    step3: 'Stap 3 ',
    step3Text: ' Voeg wijnvat hier toe',
    error: 'Voltooi alle bovenstaande stappen!',
    error_required: 'Gelieve alle bovenstaande velden in te vullen',
    errorPruningMethod: 'Selecteer een methode',
    errorOrientation: 'Selecteer een zonoriëntatie',
    errorCellar: 'Selecteer een kelder',
    title_createTank: 'Nieuw vat',
    cellar: 'Kelder',
    harvest: 'Oogst',
    winegrower: 'Wijnbouwer',
    selectCellar: 'Selecteer een kelder',
    title_createBatch: 'Nieuwe batch',
    batch_name: 'Batch naam',
    batch_hectoliters: 'Hectoliters',
    title_createCellar: 'Nieuwe kelder',
    title_sprays: 'Mijn sproeistoffen',
    title_createSpray: 'Nieuwe sproeistof',
    add_sprays: 'Sproeistof creëren',
    colSort: 'Soort',
    colLotNr: 'Lot nummer',
    colSupplier: 'Leverancier',
    colPurchasedate: 'Aankoop datum',
    colLiters: 'Liters',
    colResale: 'Doorverkoop',
    colYourRole: 'Jouw rol',
    title_dashboard: 'Overzicht',
    h2_dashboard: 'Overzicht wijnhuizen',
    p2_dashboard: 'Bekijk alle geregistreerde wijnhuizen',
    h3_dashboard: 'Mijn profiel',
    p3_dashboard: 'Bekijk of pas je profiel aan',
    selectWinegrower: 'Selecteer wijnbouwer',
    selectWineryPrompt: 'Selecteer een wijnhuis in onderstaande lijst.',
    selectWinery: 'Selecteer wijnhuis',
    h4_dashboard: 'Mijn wijnhuisprofiel',
    p4_dashboard: 'Bekijk of pas je profiel aan',
    h5_dashboard: 'Mijn werknemers',
    p5_dashboard: 'Bekijk je geregistreerde werknemers',
    p6_dashboard: 'Activateren of toevoegen van een werknemer',
    p7_dashboard: 'Bekijk of pas je wijngaard aan',
    h8_dashboard: 'Mijn wijnkelders',
    p8_dashboard: 'Bekijk of pas je wijnkelders aan',
    p9_dashboard: 'Bekijk of pas je batches aan',
    h10_dashboard: 'Historiek',
    p10_dashboard: 'Bekijk of pas je historiek aan',
    h11_dashboard: 'Rapport',
    p11_dashboard: 'Bekijk rapporten van uw registraties',
    h12_dashboard: 'Mijn sproeistoffen',
    p12_dashboard: 'Bekijk of pas je sproeistoffen aan',
    h13_dashboard: 'Mijn wijnhuizen',
    p13_dashboard: 'Bekijk of verlaat je wijnhuizen',
    h14_dashboard: 'wijnhuis uitnodigingen',
    p14_dashboard: 'Accepteren van nieuwe wijnhuizen',
    h15_dashboard: 'Gewasbeschermers importeren',
    p15_dashboard: 'Importeer een lijst van Gewasbeschermers',
    changeRegistration: 'Wijzig registratie',
    submit_wijziging: 'Wijziging opslaan',
    delete_reg: 'Registratie verwijderen',
    gotoapp: 'Log in op de mobiele app om uw account te gebruiken',
    title_history: 'Mijn historieken',
    selectBatch: 'Selecteer een batch',
    selectVineyard: 'Selecteer een wijngaard',
    vineyard_steps: 'Wijngaard Stappen',
    harvest_steps: 'Oogst Stappen',
    cellar_steps: 'Wijnkelder Stappen',
    rangeDatum: 'Filter op datum',
    stap: 'Stap',
    typeStap: 'Type stap',
    registratiedatum: 'Registratiedatum',
    beschrijving: 'Beschrijving',
    locatie: 'Locatie',
    title_login: 'Nog geen account?',
    error1_login: 'Verkeerd wachtwoord, probeer opnieuw of registreer',
    error2_login: 'Inlog fout, controleer email en wachtwoord',
    forgotpsw: 'Oops, ik ben mijn wachtwoord vergeten',
    title_tanks: 'Mijn tanks en vaten',
    add_tank: 'Creëer een nieuw wijnvat',
    colType: 'Type',
    colContent: 'Inhoud',
    colVolume: 'Volume (l)',
    colDate: 'Datum eerste gebruik',
    colDelete: 'Verwijder',
    add_cellar: 'Creëer een nieuwe wijnkelder',
    cfpassword: 'Bevestig wachtwoord',
    winery_name: 'Naam wijnhuis',
    create_winegrower: 'Creëer!',
    title_profile: 'Uw profiel',
    title2_profile: 'Wijnhuisprofiel',
    winery: 'Naam wijnhuis',
    accept: 'Accepteren',
    no_wineries_to_accept: 'Er zijn geen wijnhuizen om te accepteren!',
    controlvineyards: 'Controleer mijn wijngaarden',
    controlcellars: 'Controleer mijn wijnkelders',
    controlemployees: 'Controleer mijn werknemers',
    update_profile: 'Profiel updaten',
    title_reset: 'Wachtwoord herstellen',
    succes_reset: 'Controleer uw mail voor verdere instructies',
    error_reset: 'Wachtwoord herstellen mislukt!',
    placeholder_reset: 'Uw e-mailadres',
    winegrower_signup: 'Registreer als een wijnbouwer',
    employee_signup: 'Registreer als een werknemer',
    signin_signup: 'Reeds een account',
    signup: 'Registreren',
    errorFirstname: 'Geef uw voornaam in aub.',
    errorLastname: 'Geef uw achternaam in aub.',
    errorEmail: 'Geef uw e-mail adres in aub.',
    errorPsw: 'Geef uw wachtwoord in.',
    errorWinery1: 'Geef de naam in van een bestaand wijnhuis.',
    errorWinery2: 'Het wijnhuis bestaat niet of is nog niet goedgekeurd.',
    errorChoose: 'Kies één van deze opties.',
    errorPswMatch: 'Uw wachtwoorden komen niet overeen',
    errorVerified: 'Toon aan dat je geen robot bent',
    actief_userprofile: 'Actief',
    inactief_userprofile: 'Inactief',
    update_gebruiker: 'Update gebruiker',
    title_winegrowers: 'Uw geregistreerde wijnhuizen',
    update_wijnhuis: 'Update wijnhuis',
    update_wijnboer: 'Update wijnbouwer',
    goback: 'Keer terug',
    approve: 'Activeren',
    disapprove: 'Deactiveren',
    activate: 'Activeer',
    complete_employee: 'Vervolledig uw nieuw account of log uit',
    deactivate: 'Deactiveer',
    firstname: 'Voornaam',
    lastname: 'Achternaam',
    email: 'E-mail',
    onderwerp: 'Craft Your Taste – Registratie bevestiging ',
    aanspreking:
      'Bedankt voor je registratie! Het is fijn te vernemen dat je deel wil uitmaken van de Craft Your Taste community! ',
    message:
      'We doen er alles aan om je registratie zo snel mogelijk te bevestigen. Ons team bekijkt momenteel jouw gegevens en geeft je toegang tot jouw profiel van zodra alle gegevens zijn nagekeken. We willen immers dat dit web portaal enkel door professionelen zal worden gebruikt om de kwaliteit van de community te kunnen garanderen.',
    slot: 'Na goedkeuring zal je ook een link toegestuurd krijgen om de Craft Your Taste applicatie voor smartphone (IOS en Android) te kunnen downloaden.Tot snel!',
    afsluiting: 'Team Craft Your Taste – door Tastefever',
    tastefever: 'Tastefever',
    tastefever_login: 'Login bij Tastefever ',
    moduleMaintenance: 'Deze module is in onderhoud.',

    inlog: 'Dit zijn jouw inloggegevens',
    onderwerp_1: 'Activatie Craft Your Taste profiel',
    message1:
      'De administrator van jouw bedrijf heeft je vanaf nu toegang gegeven tot jouw profiel.  ',
    message2:
      'Vanaf nu kan je inloggen op onze applicatie met de inloggegevens die je hebt gekregen van jouw administrator. Je kan de Craft your Taste applicatie downloaden via de Apple App Store of de Google Play Store en de applicatie beginnen gebruiken. Bij verdere vragen kan je de administrator van jouw bedrijf contacteren.',
    message3: 'Volg deze link om jouw paswoord te resetten voor jouw',
    message4: 'account',

    //activate/deactvate employee account
    subject_activate: 'Account geactiveerd',
    subject_deactivate: 'Account gedeactiveerd',
    subject_deleted: 'Account verwijderd',
    employee_activated: 'Uw account is geactiveerd van ',
    employee_deactivated: 'Uw account is gedeactiveerd van ',
    employee_deleted: 'Uw account is verwijderd van ',

    label1: 'E-mail',
    label2: 'Wachtwoord',
    slot_1: 'Veel succes!',
    download: 'Download hieronder de app en start het avontuur',
    begin:
      'Ons team heeft je registratie nagekeken en we hebben je vanaf nu toegang gegeven tot jouw profiel.',
    message_1:
      'Vanaf nu kan je inloggen op ons portaal met de door jouw gekozen inloggegevens. Ga naar ',
    message_2:
      'en log in om jouw profiel te vervolledigen en al de gegevens van jouw bedrijf in te vullen. Wanneer alle gegevens zijn geregistreerd online kan je de applicatie beginnen gebruiken en kan je jouw volledige productieproces beginnen te registreren. ',
    message_3:
      'Ons team blijft beschikbaar als je hulp nodig hebt via onderstaande gegevens.',

    //bevestigingsmail als werknemer toegevoegd is aan winery

    employeeAccepted_emp:
      'Je kan nu inloggen via de app bij het volgende wijndomein: ',

    employeeAccepted_wg:
      'De volgende gebruiker heeft uw invitatie voor uw wijndomein:  ',

    employeeAdded_message:
      " is nu toegevoegd als werknemer. Je kan al je werknemers bekijken bij 'Mijn werknemers'.",

    employeeApproved_message:
      'Uw account is geactiveerd, u kunt nu via de app inloggen.',

    employeeDisapproved_message:
      " is nu afgekeurd. Je kan al je werknemers goedkeuren bij 'Goedkeuren werknemers'.",
    aanspreking_general: 'Beste ',

    login_nav: 'Inloggen',
    signup_nav: 'Registreren',
    welcome_nav: 'Welkom',
    logout_nav: 'Uitloggen',
    onderwerp_invite_emp: 'Invitatie geaccepteerd',
    inviteSend: 'De uitnodiging is verstuurd naar',
    bericht_mail: 'Bericht',
    app_link_android:
      'https://play.google.com/store/apps/details?id=be.exanteit.tastefever',
    app_link_ios:
      'https://apps.apple.com/be/app/craft-your-taste-by-tastefever',

    /*PDF*/
    pdftitle1: 'Registraties van',
    pdftitle2: 'tot',
    amount: 'Hoeveelheid: ',
    batch_pdf: 'Batch: ',
    location_pdf: 'Locatie: ',
    amountDrying: 'Droog hoeveelheid: ',
    warehouse: 'Magazijn: ',
    harvestMethod: 'Oogst methode: ',
    tank: 'Vat: ',
    accuracy: 'Nauwkeurigheid: ',
    density: 'Dichtheid (g/ml): ',
    temperature: 'Temperatuur (°C): ',
    acidity: 'Zuurtegraad: ',
    alcohol: 'Alcohol: ',
    massDensity: 'Massadichtheid: ',
    ph: 'Ph: ',
    sugars: 'Suiker: ',
    fermentationType: 'Fermentatie type: ',
    productsAdded: 'Toegevoegde producten: ',
    method: 'Methode: ',
    amountOfBottles: 'Hoeveelheid flessen: ',
    bottletype: 'Flestype: ',
    productUsed: 'Gebruikte producten: ',
    amountOfProductUsed: 'Hoeveelheid gebruikte producten: ',
    registrationDate_pdf: 'Registratie datum: ',
    on: 'op',
    in: 'in',

    /*Table*/

    emptyDataSourceMessage: 'Geen records weer te geven',
    addTooltip: 'Toevoegen',
    deleteTooltip: 'Verwijderen',
    editTooltip: 'Aanpassen',
    filterTooltip: 'Filteren',
    deleteText: 'Wilt u deze lijn verwijderen?',
    cancelTooltip: 'Annuleren',
    placeholder: 'Versleep headers ...',
    groupedBy: 'Gegroepeerd door:',
    actions: 'Acties',
    labelDisplayedRows: '{from}-{to} van {count}',
    labelRowsSelect: 'rijen',
    labelRowsPerPage: 'Rijen per pagina:',
    firstAriaLabel: 'Eerste pagina',
    previousAriaLabel: 'Vorige pagina',
    nextAriaLabel: 'Volgende pagina',
    lastAriaLabel: 'Laatste pagina',
    addRemoveColumns: 'Toevoegen en verwijderen van kolommen',
    nRowsSelected: '{0} rij(en) geselecteerd',
    showColumnsTitle: 'Toon kolommen',
    exportTitle: 'Exporteer',
    exportName: 'Exporteer als CSV',
    searchTooltip: 'Zoek',
    exportPDFName: 'Exporteer als PDF',

    /*Report*/

    placeholder1_report: 'Selecteer type stap: ',
    type_report: 'Selecteer een type: ',
    cellartype_report: 'Kelderstap',
    vineyardtype_report: 'Wijngaardstap',
    day_report: 'Alle registraties van deze dag',
    placeholder2_report: 'Selecteer een stap: ',
    title_report: 'Registratierapport',
    colStorage_report: 'Opslag',
    refill_report: 'Bijvul hoeveelheid',
    output_report: 'Outputhoeveelheid',
    choose_period: 'Kies periode',
    report_titleh1: 'Registratie rapport',
    amount_report: 'Hoeveelheid ',
    temperature_report: 'Temperatuur (°C) ',
    density_report: 'Dichtheid (g/ml) ',
    drying_amount: 'Droog hoeveelheid',
    location_report: 'Locatie',
    warehouse_report: 'Magazijn',
    harvest_method_report: 'Oogstmethode',
    productUsed_report: 'Gebruikte producten ',
    amountOfProductUsed_report: 'Hoeveelheid gebruikte producten ',
    alcohol_report: 'Alcohol ',
    ph_report: 'pH ',
    sugars_report: 'Suiker ',
    acidity_report: 'Zuurtegraad ',
    method_report: 'Methode',
    bottletype_report: 'Flestype ',
    amountOfBottles_report: 'Hoeveelheid flessen ',
    fermentationType_report: 'Fermentatie type ',
    massDensity_report: 'Massadichtheid ',
    source_batch_report: 'Oorspronkelijke batch ',
    product_amount_report: 'Producthoeveelheid',

    /*Steps */

    Trainingandtyingbranches: 'Takken trainen en binden',
    Soilmaintenance: 'Bodemonderhoud',
    Ripeness: 'Rijpheid',
    Crushingformaceration: 'Breken voor maceratie',
    Foliage: 'Gebladerte',
    Destemming: 'Ontstelen',
    Pruning: 'Snoeien',
    MaintenanceProtectionvineyard: 'Onderhoud / bescherming wijngaard',
    Greenharvest: 'Groene oogst',
    Priming: 'Priming',
    Other: 'Andere',
    Drying: 'Drogen',
    CrushingPressingwithoutmaceration: 'Breken / persen zonder maceratie',
    Harvest: 'Oogst',
    Fermentation: 'Fermentatie',
    Agingbottle: 'Verouderingsfles',
    Stabilisation: 'Stabilisatie',
    Decantingandtreatments: 'Decanteren en behandelingen',
    Assemble: 'Monteren',
    Aeration: 'Beluchting',
    Rackingandtreatments: 'Rekken en behandelingen',
    Split: 'Splitsen',
    Clarification: 'Clarificatie',
    Certification: 'Certificatie',
    Bottling: 'Bottelen',
    Productaddition: 'Producttoevoeging',
    Otherpractiquesandtreatments: 'Andere praktijken en behandelingen',
    Transfer: 'Overdracht',
    Acidification: 'Verzuring',
    Agingbarrel: 'Verouderingsvat',
    Pressingaftermaceration: 'Persen na maceratie',
    Overpumping: 'Over pompen',
    Clearbarreltank: 'Leeg vat / tank',
    Labelling: 'Etikettering',
    Otheraging: 'Andere veroudering',
    Malolacticfermentation: 'Malolactische gisting',
    Secondfermentation: 'Tweede gisting',
    Maceration: 'Maceratie',

    /*Methods*/

    Pergola: 'Pergola',
    Doublecordon: 'Dubbel cordon',
    Basket: 'Mand',
    Singlecordon: 'Enkel cordon',
    Goblet: 'Goblet',
    other: 'Overig',
    Lyre: 'Lier',
    Singleguyot: 'Enkele guyot',
    Scotthenry: 'Scott henry',
    Doubleguyot: 'Dubbele guyot',
    Genevadoublecurtain: 'Genève dubbel gordijn',
    southeast: 'Zuidoosten',
    north: 'Noorden',
    south: 'Zuiden',
    northwest: 'Noordwesten',
    southwest: 'Zuidwesten',
    west: 'Westen',
    northeast: 'Noordoosten',
    east: 'Oosten',
    unspecified: 'Onbepaald',

    //My History
    current_batch_history: 'Huidige batch ',

    /*Trace batch */
    trace_batch: 'Traceer lot',
    trace_h1: 'Bekijk of volg een batch',
    source_batch: 'Oorspronkelijke batch: ',
    current_batch: 'Huidige batch: ',
    wine_storage: 'Wijnopslag',
    trusted_regis: 'Vertrouwd voor registraties',
    optie_yes: 'ja',
    optie_no: 'nee',
    no_batch_registrations: 'Er zijn geen stappen geregistreerd op deze batch',

    /*Trace vineyard */
    trace_vineyard: 'Traceer wijngaard',
    trace_vineyard_h1: 'Bekijk of volg een wijngaard',
    crop_filter: 'Filter over gewasbescherming, bemesting en behandeling van biostimulanten',
    open_registrations: 'Open alle registraties',
    no_vineyard_registrations:
      'Er zijn geen stappen geregistreerd op deze wijngaard',

    /*Toevoegen registratie*/

    title_addregis: 'Toevoegen registratie',
    p_regis: 'Voeg een registratie toe',
    cellar_regis: 'Kelder registratie',
    vineyard_regis: 'Wijngaard registratie',
    select_vineyard: 'Selecteer wijngaard',
    register: 'Registreer',
    register_WG_title: 'Maak uw account en start uw avontuur!',

    select_vineyardstep: 'Selecteer een wijngaardstap',
    title_regis: 'Creëer nieuwe wijngaardregistratie',
    product: 'Product',
    products: 'Producten',
    type_of_fermentation: 'Kies het type fermentatie',
    create_batch: 'Creëer een nieuwe batch',
    method_used: 'Gebruikte methode',
    amount_used: 'Gebruikte hoeveelheid',
    amount_hectoliters: 'Aantal hectoliters',
    refill_amount: 'Vulhoeveelheid',

    /* Goedkeuren registratie */

    approve_registration: 'Goedkeuren registraties',
    approve_registrations: 'Registraties om goed te keuren',
    trusted: 'Goedgekeurd voor registraties',

    /* Split batches */
    select_tank: 'Selecteer vat / tank*',
    remove_batch: 'Verwijderen batch',
    create_new: 'Creëer een nieuwe',
    regisforcellar: 'registratie voor de kelder',
    regisforvineyard: 'registratie voor de wijngaard',
    onBarrel: 'Op vat',
    onBottle: 'Op fles',
    manually: 'Handmatig',
    select_cellarstep: 'Selecteer een kelderstap',
    select_sourcebatch: 'Selecteer een oorspronkelijke batch*',
    select_countbatch: 'In hoeveel nieuwe batches wilt u splitsen? * ',
    selectbottletype: 'Selecteer een fles type*',
    selectcellarandcellarstep: 'Selecteer een kelder en een kelderstap',
    selectvineyardandvineyardstep:
      'Selecteer een wijngaard en een wijngaardstap',
    batchdrying: 'Selecteer batch (drogen) of maak nieuwe batch* ',
    selectLocation: 'Selecteer een locatie',
    waiting_for_approval: 'Er zijn registraties die wachten op goedkeuring,',
    click_here: 'Klik hier',
    to_approve_it: 'om deze goed te keuren',
    finish: 'Finish',

    /*Merge*/
    merged: 'Samengevoegd uit: ',
    source_tank: 'Oorspronkelijk vat / tank*',
    destination_batch: 'Bestemmingsvat / bestemmingstank ',
    into: 'in',
  },

  translation: {
    myParcels: 'Mijn kadasterpercelen',
    myParcelsDescription: 'Bekijk of bewerk je kadasterpercelen',
    createParcel: 'Maak kadasterperceel aan',
    parcelNumber: 'Perceelnummer',
    landRegistrationNumber: 'Kadasternummer',
    goback: 'Keer terug',
    name: 'Naam',
    greenArea: 'Oppervlakte inclusief groene rand/ bufferzone (ha)',
    number: 'Nummer',
    none: 'Geen',
    colSizeCa: 'Oppervlakte (ca)',
    colSize: 'Oppervlakte',
    parcelId: 'Perceelid',
    grapeVariety: 'Druifsoort',
    grapeVarietyOverview: 'Overzicht druivensoorten',
    agriculturalParcelExplanation: 'Per wijngaard kun je een of meer druivensoorten hebben die verbonden zijn met verschillende kadasterpercelen.',
    numberOfPlants: 'Aantal planten',
    areaPlanted: 'Beplant oppervlak',
    agriculturalParcel: 'Sub perceel',
    deleteEmployee: 'Verwijder werknemer',
    deleteAccount: 'Verwijder account',
    deleteAccountText:
      'Ben je zeker dat je uw account wilt verwijderen? Deze actie is niet omkeerbaar.',
    parcelInfo: 'Info kadasterperceel',
    parcelName: 'Naam kadasterperceel',
    agriculturalRegion: 'Agrarische regio',
    zipCode: 'Postcode',
    municipality: 'Gemeente',
    country: 'Land',
    selectParcel: 'Selecteer het gewenste perceel',
    updateParcel: 'Bewerk perceel',
    connectedVineyard: 'Verbonden aan wijngaard',
    connectedParcel: 'Verbonden aan kadasterperceel:',
    connectedVineyardNotSelected:
      'Een landbouwperceel moet verbonden zijn met een wijngaard én een kadasterperceel.',
    connectedParcelNotSelected: 'Een landbouwperceel moet verbonden zijn met een wijngaard én een kadasterperceel.',
    selectedVineyard: 'Geselecteerde wijngaard: ',
    selectedParcel: 'Geselecteerde kadasterperceel: ',
    agriculturalParcelInfo: 'Informatie druivensoort',
    clone: 'Kloon',
    rootStock: 'Onderstam',
    areaPlantedAre: 'Beplant gebied (a)',
    areaPlantedCentiare: 'Beplant gebied (ca)',
    yearPlanted: 'Geplant in het jaar: ',
    updateAgriculturalParcel: 'Duifsoort bijwerken',
    createAgriculturalParcel: 'Maak nieuwe druifsoort',
    invitationAccepted: 'Uitnodiging geaccepteerd',
    vineyardNotSelected:
      'Selecteer de wijngaard waaraan het subperceel verbonden moet zijn',
    parcelNotSelected:
      'Selecteer het perceel waaraan het subperceel verbonden moet zijn',
    myParcelsExplanation:
      "Klik op de knop 'Details' om het perceel te wijzigen of selecteer het perceel om druivensoorten aan te maken.",

    words: {
      belgium: 'België',
      email: 'e-mailadres',
      name: 'Naam',
      address: 'adres',
      location: 'locatie',
      BTWnumber: 'BTW-nummer',
      password: 'wachtwoord',
      delete: 'Verwijder',
      edit: 'Pas aan',
      create: 'Creëer',
      next: 'Volgende',
      for: 'voor',
      configuration: 'Configuratie',
      registration: 'Registratie',
      reporting: 'Rapportering',
      save: "Opslaan",
      grape: "Druif",
    },

    history: {
      stepType: 'Type stap',
      stepName: 'Naam stap',
      cropName: 'Oogstjaar',
      vineyardName: 'Wijngaard naam',
    },

    vineyardSteps: {
      myVineyardSteps: 'Mijn wijngaardstappen',
      myVineyardStepsDescription: 'Bekijk of pas je wijngaardstappen aan',
      vineyardStepInfo: 'Wijngaardstapinfo',
      nameEn: 'Engelse naam',
      nameFr: 'Franse naam',
      nameIt: 'Italiaanse naam',
      nameNl: 'Nederlandse naam',
      number: 'Stap nummer',
      stepName: 'Stap naam',
      order: 'Volgorde',
      key: 'Key',
      textEN: 'Engelse naam',
      textFR: 'Franse naam',
      textIT: 'Italiaanse naam',
      textNL: 'Nederlandse naam',
      type: 'Stap Type',
      validation: 'Validatie',
      validation1: 'Validatie 1',
      validation1Value: 'Validatie 1 waarde',
      validation2: 'Validatie 2',
      validation2Value: 'Validatie 2 waarde',
      updateVineyardStep: 'Update wijngaardstap',
      selectionType: 'SelectieType',
      newVineyardStep: 'Nieuwe wijngaardstap',
      createVineyardStep: 'Maak wijngaardstap aan',
      resetToDefault: 'Zet terug op standaardwaarde',
      vineyardStepContent: 'Wijngaard step content',
      addVineyardStepContent: 'Voeg wijngaardstap content toe',
      newVineyardStepContent: 'Nieuwe wijngaardstap inhoud',
      selectionTypeIsNotSelected: 'Selection type is niet geselecteerd',
      stepTypeNotSelected: 'Stap type is niet geselecteerd',
      createVineyardStepContent: 'Creëer wijngaardstap inhoud',
    },

    vineyardRegistration: {
      addRegistration: 'Registreer wijngaardstap',
      registerVineyardStepDescription: 'Registreer een nieuwe wijngaardstap',
      selectParcel: 'Selecteer wijngaard of druivensoort',
      vineyardStepRegistration: 'Wijngaardstapregistratie',
      description: 'Omschrijving',
      wayOfHarvest: 'Manier van oogsten',
      warehouse: 'Magazijn',
      amountDrying: 'Hoeveelheid drogen',
      product: 'Product',
      amount: 'Hoeveelheid',
      products: 'Producten',
      massDensity: 'Massa dichtheid',
      createRegistration: 'Registratie aanmaken',
      selectVineyardStep: 'Selecteer een wijngaardstap',
      selectedVineyardStep: 'Geselecteerde wijngaardstap',
    },

    barrels: {
      numberOfBarrels: 'Aantal vaten / tanks',
      totalVolume: 'Totaal volume (l)',
      myBarrels: 'Mijn tanks / vaten',
      barrelInfo: 'Vat / tank aanpassen',
      createBarrel: 'Maak een nieuw vat / tank aan',
    },

    emails: {
      welcome: 'Welkom bij de Craft Your Taste community!',
      thanks: 'Bedankt en veel plezier!',
      moreInfo: 'Meer informatie',

      registrationInfoNewUser: {
        subject: 'Uitnodiging wijngaard',
        message1: 'Je bent uitgenodigd om je te registeren bij ',
        message2:
          'Je zult binnenkort een email krijgen waar een tijdelijk wachtwoord in staat. Klik op de link en log in met de onderstaande email om je te registreren en de uitnodiging te accepteren.',
        existinguser_message2:
          'Klik op de link en log in met je inloggegevens om de uitnodiging te accepteren: ',
        your_password: 'je wachtwoord',
      },

      registrationPasswordNewUser: {
        message1:
          'Uw tijdelijk wachtwoord om in te loggen en uw registratie af te werken:',
      },

      registrationInfoExistingUser: {
        message1:
          "Je bent toegevoegd aan een nieuw wijnhuis. Navigeer naar de website's dashboard onder 'wijnhuis uitnodigingen' om deze te accepteren!",
      },
    },

    pageNotFound: {
      title: 'Pagina niet gevonden',
      body: 'Er bestaat geen pagina met de opgegeven link. Kijk de link na op fouten en probeer opnieuw.',
    },

    dashboard: {
      harvestYears: {
        title: 'Oogstjaren',
        body: 'Bekijk of bewerk je oogstjaren',
      },
      government: {
        title: 'Overheid',
        body: 'Bekijk documenten voor de overheid',
      },
      barrels: {
        title: 'Mijn tanks en vaten',
        body: 'Bekijk of bewerk je tanks en vaten',
      },
    },

    harvestYears: {
      startDate: 'Startdatum',
      endDate: 'Einddatum',
      myHarvestYears: 'Mijn oogstjaren',
      harvestYearInfo: 'Oogstjaar informatie',
      errorMessages: {
        datesOverlapping: 'Datums overlappen met een bestaand oogstjaar',
        nothingChanged: 'Niets is aangepast',
        endBeforeStartDate: 'Einddatum moet vallen na',
      },
    },

    governmentDocuments: {
      personalData: 'Persoonlijke gegevens',
      wineryData: 'Wijnhuis gegevens',
      selectYear: 'Selecteer een oogstjaar',

      harvestDeclaration: {
        title: 'Oogst aangifte',
        harvestNumber: 'Oogstnummer',
        vineVariety: 'Wijnstokras',
        acreage: 'Oppervlakte (raming)',
        parcelIdentification: 'Perceel identificatie',
        vineAmount: 'Wijnstokken aantal',
        harvestInOwnCellar: 'Oogst in eigen kelder',
        color: 'Kleur',
        sugar: 'Suiker',
        acidity: 'Zuurtegraad',
      },

      stockDeclaration: {
        title: 'Voorraad aangifte',
        name: 'Naam wijn',
        stock: 'Voorraad in vrac',
        productionYear: 'Productiejaar',
        recognition: 'Erkenning',
      },
      bottlingDeclaration: {
        title: 'Bottling aangifte',
        name: 'Naam wijn',
        litersInBottle: 'Voorraad in flessen',
        bottleVolume: 'Volume per fles',
        productionYear: 'Productiejaar',
        recognition: 'Erkenning',
      },
    },
    cellars: {
      myCellarsExplanation:
        'Klik op de naam van de kelder om vaten / tanks toe te voegen',
    },

    cellarSteps: {
      myCellarSteps: 'Mijn kelderstappen',
      myCellarStepsDescription: 'Bekijk of pas je kelderstappen aan',
      newCellarStep: 'Nieuwe kelderstap',
      cellarStepContent: 'Kelder stap content',
      addCellarStepContent: 'Voeg kelderstap content toe',
      newCellarStepContent: 'Nieuwe kelderstap inhoud',
      updateCellarStep: 'Update kelderstap',
      createCellarStep: 'Maak een nieuwe kelderstap',
      createCellarStepContent: 'Maak kelderstap content aan',
    },

    harvestRegistration: {
      registerHarvest: 'Registreer oogststap',
      registerHarvestDescription: 'Registreer een nieuwe oogststap',
      addBatchForHarvest: 'Maak een nieuw lot voor oogststap',
      addWineStorage: 'Wijnopslag toevoegen',
      selectWineStorage: 'Kies wijnopslag',
      finishRegistration: 'Registratie beëindigen',
      juiceInLiters: 'Sap in liters',
      addAmount: 'Voeg hoeveelheid toe',
      amountFor: 'Hoeveelheid voor',
      vineyard: 'Wijngaard',
      generateHarvestStockReport: 'Genereer Rapport',
      activeHarvestYearNotFound: 'Geen actief oogstjaar gevonden. Maak alstublieft een oogstjaar aan.',
      harvestYearsNotAvailable: 'Geen oogstjaren beschikbaar. Maak alstublieft een oogstjaar aan.',
    },

    cellarRegistration: {
      registerCellarStep: 'Registreer kelderstap',
      registerCellarStepDescription: 'Registreer een nieuwe kelderstap',
      selectCellarStep: 'Selecteer een kelderstap',
      registrationOf: 'Registratie van',
      selectBatchForRegistration: 'Selecteer lot(en) voor registratie',
      harvestYearName: 'Naam van het oogstjaar',
      batchName: 'Naam van de batch',
      registrationDate: 'Registratie datum',
      possibleAdditionOfSo: 'Eventuele toevoeging van SO2',
      startDate: 'Start datum',
      endDate: 'Eind datum',
      newBatchName: 'Naam van de nieuwe batch',
      keepOldBatch: 'Oude batch behouden?',
      keepBatch: 'Bewaar batch',
      litersFor: 'Liters voor',
      bottlingOf: 'Het bottelen van wijn in ',
      selectTypeOfBottle: 'Selecteer type fles',
      createCellarRegistration: 'Maak kelder registratie aan',
    },

    traceBatch: {
      SO2GramPerLiter: 'SO2 (gr/l)',
      SO2Gram: 'SO2 (gr)',
      amountLiters: 'Hoeveelheid (l)',
      bottleType: 'Soort fles',
      destination: 'Bestemming',
      origin: 'Oorspong',
      amountOfBottles: 'Aantal flessen',
      percentage: 'Percentage',
      typeOfWineStorage: 'Type wijn vat',
      destemmed: 'Onsteeld',
      harvestYear: 'Oogstjaar',
      washingSulfite: 'Sulfiet',
      registrationData: 'Registratie data',
      bruised: 'Gekneusd',
      litersForHarvest: 'Geoogste liters',
    },

    myHarvestSteps: {
      myHarvestSteps: 'Mijn oogststappen',
      myHarvestStepsDescription: 'Bekijk of pas je oogststappen aan',
      key: 'Key',
      nameEN: 'Engelse naam',
      type: 'Type stap',
      editHarvestStep: 'Oogststap bewerken',
    },

    winegrowerDetailAdmin: {
      editWinegrowerDetails: 'Wijzig de gegevens van de wijnbouwer',
    },

    createBatch: {
      createBatch: "Nieuwe batch aanmaken",
      createDate: "Datum van creatie",
      harvestYear: "Oogstjaar",
      historic: "Waar komt deze batch vandaan?",
      name: "Naam van de batch",
      createNewBatch: "Maak een nieuwe batch aan",
      addGrapeVariety: "Voeg druivensoort toe",
      grapeVariety: "Druivensoort",
      percentage: "Percentage",
      wineStorages: "Wijnopslag",
      addWineStorage: "Voeg wijnopslag toe",
      wineStorage: "Kies het type wijnopslag",
      liters: "Liters",
    },
    barrelReport: {
      title: "Tank of vat traceren",
      subTitle: "Bekijk het rapport per type vat"
    },
    myAgriParcels: {
      rowDistance: "Rijafstand (m)",
      plantDistance: "Plantafstand (m)",
      
    },
    excelImportPage: {
      excelImport: "Excel-lijst met chemicaliën importeren",
      source: "Importeer de lijst vanaf https://apps.health.belgium.be/fytoweb/pages/public/index.xhtml, kies de juiste taal, filter de gewassen op druivelaars (wijnproductie) en exporteer.",
      stepsBeforeUpload: "Het is belangrijk om de tabel die u zojuist heeft gedownload in een nieuw xslx-bestand te importeren. Gebruik het tabblad Gegevens, de knop gegevens ophalen, van bestand, van Excel-werkboek. Voordat u gaat importeren, moet u alle lege rijen bovenaan verwijderen, de koptekst selecteren en als het nog niet de tabelkop is, op de knop drukken om deze te promoveren tot tabelkop. Zodra dit is gedaan, slaat u de tabel op en zou u deze moeten kunnen uploaden.",
      save: "Opslaan in firebase",
      preview: "Geïmporteerde data preview:"
    },
    excelImport: {
      category: "Gewassen",
      subcategory: "druivelaars (wijnproductie)",
      anyCrop: "alle teelten",
    },
    excelImportKeys: {
      product: "Product",
      number: "Nummer",
      activeSubstance: "Werkzame stof",
      authorizationHolder: "Toelatingshouder",
      nature: "Aard",
      formulationType: "Formuleringstype",
      signalWord: "Signaalwoord",
      hazardPictograms: "Gevarenpictogrammen",
      hazardStatements: "Gevarenaanduidingen",
      latestDeliveryCertificate: "Aflevering recentste akte",
      initialAuthorizationStart: "Begin eerste toelating",
      endOfSalesHolder: "Einde verkoop (toelatingshouder)",
      endOfSalesDistributor: "Einde verkoop (distributeur)",
      endOfUse: "Einde gebruik"
    }
  },
};

export default translation;
