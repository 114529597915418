import { useTranslation } from 'react-i18next';
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { firestore } from "../../firebase/config";
import { PageTitle } from "../../utils/PageTitle";
import { useSessionStorage } from "../../logic/useSessionStorage";
import { removeVineyardStep } from "../../firebase/vineyardstep";
import GoBackButton from "../../components/FormComponents/GoBackButton";
import { onSnapshot, collection, query } from "firebase/firestore";
import MaterialTable from '@material-table/core';

const MyVineyardSteps = () => {
    PageTitle("Vineyard Steps");
    const [currentWinery, setCurrentWinery] = useSessionStorage("currentwinery", "");
    const navigate = useNavigate();
    const [vineyardSteps, setVineyardSteps] = useState([]);
    const { i18n } = useTranslation();
    const lang = i18n.language;
    const { t } = useTranslation();

    useEffect(() => {
        const vineyardStepsRef = query(
            collection(
                firestore, 
                'winegrowers', 
                currentWinery, 
                'vineyardSteps'
            )
        );

        const unsubscribe = onSnapshot(vineyardStepsRef, async (querySnapshot) => {
            const vineyardStepsDoc = querySnapshot.docs.map((doc) => ({
                id: doc.id, ...doc.data(),
            }));
            for (let i = 0; i < vineyardStepsDoc.length; i++) {
                if (lang === 'en') {
                    vineyardStepsDoc[i].nameEN = vineyardStepsDoc[i].nameEN;
                } else if (lang === 'fr') {
                    vineyardStepsDoc[i].nameEN = vineyardStepsDoc[i].nameFR;
                } else if (lang === 'it') {
                    vineyardStepsDoc[i].nameEN = vineyardStepsDoc[i].nameIT;
                } else if (lang === 'nl') {
                    vineyardStepsDoc[i].nameEN = vineyardStepsDoc[i].nameNL;
                } else console.log("Translation Failure, please contact support");
            }
            vineyardStepsDoc.sort((a, b) => (a.order > b.order ? 1 : -1));
            setVineyardSteps(vineyardStepsDoc);
        });
        return () => unsubscribe();
    }, []);

    async function deleteVineyardStep(vineyardStepId) {
        try {
            await removeVineyardStep(currentWinery, vineyardStepId);
        } catch (error) {
            console.error(error);
        }
    }

    function editVineyardStep(vineyardStepId) {
        navigate(`/vineyardsteps/editvineyardstep/${vineyardStepId}`);
    }

    return (
        <>
            <div style={{ position: "relative", marginTop: "90px" }}>
            <h1 style={{ color: "#7f7f7f" }}>{t('translation.vineyardSteps.myVineyardSteps')}</h1>
            </div>
            <GoBackButton />
            <br />
            <br />
            <div style={{ position: "relative" }}>
                <div className="newvineyard">
                    <Link to="/vineyardsteps/reset" style={{ color: "white" }}>
                        <button
                            className="ui primary button login"
                            style={{ marginTop: "65px" }}>
                            {t("translation.vineyardSteps.resetToDefault")}
                        </button>
                    </Link>
                    <Link to="/vineyardsteps/newvineyardstep" style={{ color: "white" }}>
                        <button
                            className="ui primary button login"
                            style={{ marginTop: "65px" }}>
                            {t("translation.vineyardSteps.createVineyardStep")}
                        </button>
                    </Link>
                </div>
                <br />
                <div className="batchesList">
                    <MaterialTable
                        title=""
                        data={vineyardSteps}
                        columns={[{
                            title: t('translation.vineyardSteps.number'),
                            field: "number",
                            sorting: false,
                        }, {
                            title: t('translation.vineyardSteps.stepName'), field: "nameEN",
                        }, {
                            title: "", field: "", cellStyle: {
                                cellWidth: "6%",
                            }, sorting: false, render: (rowData) => (<>
                                <button
                                    className="ui primary button login"
                                    onClick={() => {
                                        deleteVineyardStep(rowData.id);
                                    }}
                                >
                                    {t("translation.words.delete")}
                                </button>
                                <button
                                    className="ui primary button login"
                                    onClick={() => {
                                        editVineyardStep(rowData.id);
                                    }}
                                >
                                    {t("translation.words.edit")}
                                </button>
                            </>),
                        },

                        ].map((c) => ({
                            render: (row) => (<Link to={`/vineyardsteps/vineyardstepslist/${row.id}`}>
                                {c.lookup ? c.lookup[row[c.field]] : row[c.field]}
                            </Link>), ...c,
                        }))}
                        options={{
                            tableLayout: "auto",
                            filtering: true,
                            exportButton: true,
                            exportAllData: true,
                            headerStyle: {
                                backgroundColor: "#cecaca", color: "#FFF",
                            },

                            emptyRowsWhenPaging: false,
                            paging: true,
                            pageSize: 50,
                            pageSizeOptions: [10, 20, 25, 50,],
                        }}
                        components={{
                            Action: (props) => (<div className="flex_grape">
                                <button
                                    className="ui primary button login"
                                    onClick={(event) => props.action.onClick(event, props.data)}
                                    color="primary"
                                    style={{ textTransform: "none" }}
                                >
                                    {t("vertaling.details")}
                                </button>
                                <button
                                    className="ui primary button login "
                                    onClick={(event) => props.action.create(event, props.data)}
                                    color="primary"
                                    style={{ textTransform: "none", minWidth: 175 }}
                                >
                                    {t("vertaling.grapevariety_btn")}
                                </button>
                            </div>),
                        }}
                    />
                </div>
            </div>
        </>);
};

export default MyVineyardSteps;