import { useSessionStorage } from "../../logic/useSessionStorage";
import { useTranslation } from 'react-i18next';
import { useFirestore } from "../../context/FirestoreContext";
import React, { useEffect, useState } from "react";
import GoBackButton from "../../components/FormComponents/GoBackButton";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { useAuth } from "../../context/AuthContext";
import { updateBatch } from "../../firebase/batch";
import { useNavigate } from "react-router-dom";
import { createRegistration } from "../../firebase/registrations";

const CustomBottling = () => {
    const [cellarRegistrationData, setCellarRegistrationData] = useSessionStorage("cellarReg", "");
    const { getCurrentCellarStep } = useFirestore();
    const { getBatchById } = useFirestore();
    const { getWineStorageById } = useFirestore();
    const { getBottleTypes } = useFirestore();
    const [currentStep, setCurrentStep] = useState(undefined);
    const [currentBatch, setCurrentBatch] = useState(undefined);
    const [bottleTypes, setBottleTypes] = useState(undefined);
    const [wineStoragesFromCurrentBatch, setWineStoragesFromCurrentBatch] = useState(undefined);
    const { register, handleSubmit } = useForm();
    const formClassname = "ui big form twelve wide column";
    const { currentUser } = useAuth();
    const [selections, setSelections] = useState([]);
    const navigate = useNavigate();
    let updatedBatch = {};
    const { t } = useTranslation();


    const [currentWinery, setCurrentWinery] = useSessionStorage("currentwinery", "");

    useEffect(() => {
        const getCurrentCellarStepFromStepFromDb = async () => {
            let cellarStep = await getCurrentCellarStep(cellarRegistrationData.stepId, currentWinery);
            setCurrentStep(cellarStep);
        }

        const getCurrentBatchFromDb = async () => {
            let batch = await getBatchById(cellarRegistrationData.batchId, currentWinery);
            setCurrentBatch(batch);
        }

        const getWineStoragesFromCurrentBatch = async () => {
            let currentWineStorages = [];
            for (let i = 0; i < currentBatch.wineStorages.length; i++) {
                let currentWineStorage = await getWineStorageById(currentBatch.wineStorages[i].id, currentWinery);
                currentWineStorages.push(currentWineStorage);
            }
            setWineStoragesFromCurrentBatch(currentWineStorages);
        }

        const getBottleTypesFromDb = async () => {
            let bottles = await getBottleTypes();
            setBottleTypes(bottles);
        }

        if (currentStep === undefined) getCurrentCellarStepFromStepFromDb();
        if (currentBatch === undefined) getCurrentBatchFromDb();
        if (currentBatch !== undefined) getWineStoragesFromCurrentBatch();
        if (bottleTypes === undefined) getBottleTypesFromDb();
    }, [currentBatch, currentStep, bottleTypes])

    function generateWineStorages() {
        let form = [];
        if (currentBatch !== undefined) {
            if (wineStoragesFromCurrentBatch !== undefined) {

                for (let i = 0; i < wineStoragesFromCurrentBatch.length; i++) {
                    const handleChange = (selection, action) => {
                        let found = false;
                        const wineStorageId = action.name.substring(10);
                        selections.forEach((value) => {
                            if (value.wineStorageId === wineStorageId) {
                                found = true;
                            }
                        })
                        let newSelection = selections;
                        if (found === true) {
                            newSelection.forEach((currentSelection) => {
                                if (currentSelection["wineStorageId"] === wineStorageId) {
                                    currentSelection.bottleId = selection.value;
                                }
                            })
                        } else {
                            let selectionObject = {};
                            selectionObject.wineStorageId = wineStorageId;
                            selectionObject.bottleId = selection.value;
                            newSelection.push(selectionObject);
                        }
                        setSelections(newSelection);
                    }

                    form.push(
                        <>
                            <div className="fields"
                                style={{ justifyContent: "center" }}
                            >
                                <div className="eight wide field">
                                    <label>
                                        {t("translation.cellarRegistration.bottlingOf")}
                                        {wineStoragesFromCurrentBatch[i].name} ({currentBatch.wineStorages[i].litersForReport} liters)
                                        <input
                                            type="number"
                                            {...register(wineStoragesFromCurrentBatch[i].id)}
                                            min="0"
                                            style={{ textAlign: "center" }} />
                                    </label>
                                </div>
                            </div>

                            <div className="fields"
                                style={{ justifyContent: "center" }}>
                                <div className="eight wide field">
                                    <label>
                                        {t("translation.cellarRegistration.selectTypeOfBottle")}
                                        <Select
                                            required="required"
                                            onChange={(selection, action) => {
                                                handleChange(selection, action);
                                            }}
                                            name={`Selection ${wineStoragesFromCurrentBatch[i].id}`}
                                            options={bottleTypes.map((type) => ({
                                                label: type.name,
                                                value: type.id,
                                            }))}

                                        />
                                    </label>
                                </div>
                            </div>
                        </>
                    )
                }
            }
        }
        return form;
    }


    const onSubmit = (data) => {
        let newRegistration = {};
        newRegistration.batchId = cellarRegistrationData.batchId;
        newRegistration.date = new Date(data.date);
        newRegistration.email = currentUser.email;
        newRegistration.harvestYearId = currentBatch.harvestYearId;
        newRegistration.stepId = cellarRegistrationData.stepId;
        newRegistration.visualisation = true;
        newRegistration.waitingForReview = false;
        newRegistration.agriculturalParcels = currentBatch.agriculturalParcels;
        newRegistration.bottling = createBottlingForRegistration(data);
        newRegistration.wineStorages = updatedBatch.wineStorages;
        try {
            updateBatch(currentWinery, currentBatch.id, updatedBatch);

        } catch (error) {
            console.error(error);
        }

        try {
            createRegistration(currentWinery, newRegistration);
        } catch (error) {
            console.error(error);
        }

        navigate('/');
    }

    function createBottlingForRegistration(data) {
        let bottling = [];

        selections.forEach((selection) => {
            let bottlingObject = {};
            bottlingObject.bottleId = selection.bottleId;
            bottlingObject.wineStorageId = selection.wineStorageId;
            for (const [key, value] of Object.entries(data)) {
                if (key === bottlingObject.wineStorageId) {
                    bottlingObject.liters = Number(value);
                }
            }
            bottling.push(bottlingObject);
        })


        //update batch so the liters in the wineStorage match with the liters deducted in the registration
        let newBatch = { ...currentBatch }
        for (const [key, value] of Object.entries(data)) {
            for (let j = 0; j < currentBatch.wineStorages.length; j++) {
                if (currentBatch.wineStorages[j].id === key) {
                    newBatch.wineStorages[j].litersForReport = (Number(currentBatch.wineStorages[j].litersForReport) - Number(value));
                    newBatch.wineStorages[j].litersForReport = Number(currentBatch.wineStorages[j].litersForReport)
                }
            }
        }
        updatedBatch = { ...newBatch }


        return bottling;
    }


    return (
        <div>
            {currentStep !== undefined && currentBatch !== undefined &&
                <div>
                    <h1>{t('translation.cellarRegistration.registrationOf')} {currentStep.nameEN} {t('translation.words.for')} {currentBatch.name}</h1>
                    <GoBackButton />
                    <br />
                    <br />
                    <div className="ui grid stackable">
                        <form
                            className={formClassname}
                            style={{
                                border: "3px solid #B3B3B3",
                                marginTop: "100px",
                                margin: "auto",
                                borderRadius: "50px",
                                background: "#edeaea",
                                borderColor: "#ac9e66",
                                maxWidth: '960px'
                            }}
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <div
                                className="fields"
                                style={{ justifyContent: "center", marginTop: "20px" }}
                            >
                                <div className="twelve wide field">
                                    <label>
                                        <p>{t("translation.cellarRegistration.registrationDate")}</p>
                                        <input
                                            type="date"
                                            {...register('date')}
                                            required="required"
                                            style={{ textAlign: "center" }} />
                                    </label>
                                </div>
                            </div>
                            {generateWineStorages()}

                            <button
                                type="submit"
                                className="ui submit large grey button right floated button-vineyards"
                                style={{ float: "none", marginTop: "10px", width: "25%" }}
                            >
                                {t("translation.harvestRegistration.finishRegistration")}
                            </button>
                        </form>
                    </div>
                </div>
            }
        </div>
    );
}

export default CustomBottling;