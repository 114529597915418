import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { PageTitle } from '../utils/PageTitle';

function Login() {
  PageTitle("Login");
  const { register, handleSubmit, reset } = useForm();
  const [isLoading, setLoading] = useState(false);
  const [showPassError, setPassError] = useState('hidden');
  const [showError, setError] = useState('hidden');
  const { login } = useAuth();
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (currentUser !== null) {
      navigate("/");
    }
  }, [currentUser, navigate]);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      await login(data.email, data.password);
      reset();
    } catch (error) {
      console.error(error);
      if (data.email === '' || data.password === '') {
        setError('show');
        setPassError('hidden');
      } else {
        setPassError('show');
        setError('hidden');
      }
    }

    if (currentUser) {
      navigate("/", { replace: true });
    } else {
      setLoading(false);
    }
  };
  const formClassName = `${isLoading ? 'loading' : ''}`;

  // Het weergeven van alle gegevens zodat een user kan inloggen

  return (
    <section className="banner">
      <div className="bannertext">
        <div className="login-container" style={{ marginTop: '50px' }}>
          <div className="ui card login-card">
            <div className="content login-content">
              {isLoading
                ? <h4>Logging in . . .</h4>
                : <h4>Login</h4>
              }
              <form className={formClassName} onSubmit={handleSubmit(onSubmit)}>
                <div className="input-div login-div one">
                  <div className="i">
                    <i className="fas fa-user"></i>
                  </div>
                  <div className="div">
                    <input type="email" {...register('email')} placeholder="email" className="input" />
                  </div>
                </div>
                <div className="input-div login-div pass">
                  <div className="i">
                    <i className="fas fa-lock"></i>
                  </div>
                  <div className="div">
                    <i
                      className="fas fa-eye psswrd-icon-1 icon-active"
                      id="show-password"
                      onClick={() => {
                        var x = document.getElementById('password');
                        var icon = document.getElementById('show-password');
                        var icon2 = document.getElementById('hid-password');
                        icon.classList.toggle('icon-active');
                        icon2.classList.toggle('icon-active');
                        x.type = 'text';
                      }}
                    ></i>
                    <i
                      className="fas fa-eye-slash psswrd-icon-1"
                      id="hid-password"
                      onClick={() => {
                        var x = document.getElementById('password');
                        var icon = document.getElementById('show-password');
                        var icon2 = document.getElementById('hid-password');
                        icon.classList.toggle('icon-active');
                        icon2.classList.toggle('icon-active');
                        x.type = 'password';
                      }}
                    ></i>
                    <input
                      type="password"
                      {...register('password')}
                      id="password"
                      placeholder="password"
                      className="input" />
                  </div>
                </div>
                <p className={showPassError + ' errorText'}>
                  {t("vertaling.error1_login")}
                </p>
                <p className={showError + ' errorText'}>
                  {t("vertaling.error2_login")}
                </p>
                <div className="field actions" style={{ textAlign: 'right' }}>
                  <button
                    className="ui primary button nav-button-border"
                    type="submit"
                  >
                    Log in
                  </button>
                  {/* <Link to="/signup" style={{ color: 'white' }}>
                    <button className="ui primary button nav-button-border">
                      {t("vertaling.title_login")}
                    </button>
                  </Link> */}
                </div>
                <Link
                  to="/reset"
                  style={{ marginTop: '30', color: 'white', float: 'right' }}
                >
                  {t("vertaling.forgotpsw")}
                </Link>
              </form>
            </div>
            <div style={{ background: 'white' }}>
              <img
                src="/images/logo_craft.bmp"
                width="45%"
                alt="logo"
                style={{ margin: 'auto' }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;