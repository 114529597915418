import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../context/AuthContext';
import { useFirestore } from '../../context/FirestoreContext'
import Select from 'react-select';
import { PageTitle } from '../../utils/PageTitle';
import "./GovernmentDocuments.css";
import { useSessionStorage } from '../../logic/useSessionStorage';
import { sortHarvestYearsArray } from '../../utils/HarvestYear';
import GovernmentHarvestStockReport from './GovernmentHarvestStockReport'
import MaterialTable from '@material-table/core';
import GoBackButton from '../../components/FormComponents/GoBackButton';

const GovernmentDocuments = () => {
    PageTitle("Government Documents");

    const { currentUser } = useAuth();
    const [currentWinery, setCurrentWinery] = useSessionStorage('currentwinery', '');
    const { getWineryDocument } = useFirestore();
    const [currentWineryDoc, setCurrentWineryDoc] = useState(undefined);

    const { getHarvestYears } = useFirestore();
    const [harvestYears, setHarvestYears] = useState(undefined);

    const { getAgriculturalParcels } = useFirestore();
    const [agriculturalParcels, setAgriculturalParcels] = useState(undefined);

    const { getRegistrations } = useFirestore();
    const [registrations, setRegistrations] = useState(undefined);

    const { getCellarSteps } = useFirestore();
    const [cellarSteps, setCellarSteps] = useState(undefined);

    const { getBatches } = useFirestore();
    const [batches, setBatches] = useState(undefined);

    const { getBottleTypes } = useFirestore();
    const [bottleTypes, setBottleTypes] = useState(undefined);

    const [currentHarvestYear, setCurrentHarvestYear] = useSessionStorage("harvestyear", "");

    const [harvestData, setHarvestData] = useState([]);
    const [bottlingData, setBottlingData] = useState([]);
    const { t } = useTranslation();

    const [showReport, setShowReport] = useState(false);

    //firestore calls
    const getCurrentWineryDoc = async () => {
        let wineryDoc = await getWineryDocument(currentWinery);
        setCurrentWineryDoc(wineryDoc);
    }

    const getHarvestYearsFromDB = async () => {
        let harvestYears = await getHarvestYears(currentWinery);
        sortHarvestYearsArray(harvestYears);
        setHarvestYears(harvestYears);
    }

    const getAgriculturalParcelsFromDB = async () => {
        let agriculturalParcels = await getAgriculturalParcels(currentWinery);
        setAgriculturalParcels(agriculturalParcels);
    }

    const getRegistrationsFromDB = async () => {
        let registrations = await getRegistrations(currentWinery);
        setRegistrations(registrations);
    }

    const getCellarStepsFromDB = async () => {
        let cellarSteps = await getCellarSteps(currentWinery);
        setCellarSteps(cellarSteps);
    }

    const getBatchesFromDB = async () => {
        let batches = await getBatches(currentWinery);
        setBatches(batches);
    }

    const getBottleTypesFromDB = async () => {
        let bottleTypes = await getBottleTypes();
        setBottleTypes(bottleTypes);
    }

    useEffect(() => {
        getCurrentWineryDoc();
        getHarvestYearsFromDB();
        getAgriculturalParcelsFromDB();
        getRegistrationsFromDB();
        getCellarStepsFromDB();
        getBatchesFromDB();
        getBottleTypesFromDB();
    }, [])


    //agriculturalParcel & registration data --> Harvest declaration table data
    useEffect(() => {
        if (currentHarvestYear !== undefined && registrations !== undefined) {
            let data = [];
            const registrationsForCurrentYear = registrations.filter(registration => registration.harvestYearId === currentHarvestYear.id);
            //registration data
            let harvestRegistrationForCurrentYear = registrationsForCurrentYear.filter(registration => registration.stepId === "harvestStep");
            let harvestAgriculturalParcels = [];
            harvestRegistrationForCurrentYear.forEach((registration) => {
                let agriculturalParcelObject = {};
                agriculturalParcelObject.date = registration.date.toLocaleDateString("nl-nl")
                agriculturalParcelObject.sugar = registration.sugarsForReport;
                agriculturalParcelObject.acidity = registration.acidityForReport;

                let totalLiters = 0;
                if (registration.wineStorages !== undefined) {
                    registration.wineStorages.forEach((wineStorage) => {
                        totalLiters += wineStorage.litersForReport;
                    })
                }

                registration.agriculturalParcels.forEach((agri) => {
                    let agriculturalParcelFromDb = agriculturalParcels.find(parcel => parcel.id === agri.id);
    
                    // Create a new object for each agricultural parcel.
                    let agriculturalParcelObject = {
                        date: registration.date.toLocaleDateString("nl-nl"),
                        sugar: registration.sugarsForReport,
                        acidity: registration.acidityForReport,
                        name: agriculturalParcelFromDb.name,
                        id: agri.id,
                        area: agriculturalParcelFromDb.areaPlantedAre,
                        liters: Math.round(totalLiters * (agri.percentage / 100)),
                        numberOfPlants: agriculturalParcelFromDb.numberOfPlants
                    };
                    
                    harvestAgriculturalParcels.push(agriculturalParcelObject);
                })
            })
            setHarvestData(harvestAgriculturalParcels)
        }
    }, [currentHarvestYear])


    //bottling declaration
    useEffect(() => {
        let data = [];
        let cellarStepId = null;
        let bottlingRegistrations = [];

        //finding right cellarStepId and filtering all registrations based on it
        if (cellarSteps !== undefined) {
            let i = 0;

            while (cellarStepId == null && i < cellarSteps.length) {
                if (cellarSteps[i].nameEN.toLowerCase() === "bottling") cellarStepId = cellarSteps[i].id
                i++;
            }

            if (cellarStepId != null) {
                bottlingRegistrations = registrations.filter(reg => reg.stepId === cellarStepId && reg.harvestYearId === currentHarvestYear.id);
            }
        }

        bottlingRegistrations.forEach(registration => {
            let tableLineData = {};
            tableLineData.productionYear = currentHarvestYear.name;
            
            const matchingBatch = batches.find(batch => batch.id === registration.batchId);
            
            if (matchingBatch) {
                tableLineData.name = matchingBatch.name;
        
                registration.bottling.forEach(bottle => {
                    const matchingBottleType = bottleTypes.find(type => type.id === bottle.bottleId);
                    if(matchingBottleType){
                        tableLineData.liters = bottle.liters + "l";
                        tableLineData.bottleVolume = matchingBottleType.size + "l";
                    }
                    
                    data.push({...tableLineData});
                });
            } else {
                console.warn(`No matching batch found for batchId ${registration.batchId}`);
            }
        });

        setBottlingData(data);
    }, [currentHarvestYear])


    const handleHarvestYearChange = (selection) => {
        if (selection.value !== undefined) setCurrentHarvestYear(harvestYears.filter(year => year.id === selection.value)[0]);
    };   

    const generateReport = () => {
        setShowReport(true);
    }
    

    if (currentUser !== undefined && currentWineryDoc !== undefined && harvestYears !== undefined) {
        return (
            <div className="container">
                <div style={{ position: "relative", marginTop: "90px", display: "flex", justifyContent: "center" }}>
                 <GoBackButton />
                 </div>
                <div id="personalData">
                    <h2>{t("translation.governmentDocuments.personalData")}</h2>
                    <p>{t("translation.words.name")}: {currentUser.displayName}</p>
                    <p>{t("translation.words.email")}: {currentUser.email}</p>
                </div>

                <div id="wineryData">
                    <h2>{t("translation.governmentDocuments.wineryData")}</h2>
                    <p>{t("translation.words.name")}: {currentWineryDoc.name}</p>
                    <p>{t("translation.words.address")}: {currentWineryDoc.adress}</p>
                    <p>{t("translation.words.location")}: {currentWineryDoc.city}</p>
                    <p>{t("translation.words.BTWnumber")}: {currentWineryDoc.btw}</p>
                </div>
                <div id="selector">
                    <Select
                        placeholder={t('translation.governmentDocuments.selectYear')}
                        onChange={(selection) => handleHarvestYearChange(selection)}
                        options={harvestYears.map((year) => ({
                            value: year.id,
                            label: year.name,
                        }))}
                    />
                </div>


               

                    {currentHarvestYear &&
                        <>
                            
                            <div className="harvestTable">
                            <div className="generateHarvestStockReport">
                                <GovernmentHarvestStockReport />
                            </div>
                                <MaterialTable
                                    title={t('translation.governmentDocuments.harvestDeclaration.title')} 
                                    data={harvestData}
                                    columns={[
                                        {
                                            title: t('translation.governmentDocuments.harvestDeclaration.harvestNumber'),
                                            field: 'date',
                                        },
                                        {
                                            title: t('translation.governmentDocuments.harvestDeclaration.vineVariety'),
                                            field: "name"
                                        },
                                        {
                                            title: t('translation.governmentDocuments.harvestDeclaration.acreage'),
                                            field: "area"
                                        },
                                        {
                                            title: t('translation.governmentDocuments.harvestDeclaration.parcelIdentification'),
                                            field: "-"
                                        },
                                        {
                                            title: t('translation.governmentDocuments.harvestDeclaration.vineAmount'),
                                            field: "numberOfPlants"
                                        },
                                        {
                                            title: t('translation.governmentDocuments.harvestDeclaration.harvestInOwnCellar'),
                                            field: "liters"
                                        },
                                        {
                                            title: t('translation.governmentDocuments.harvestDeclaration.color'),
                                            field: "-"
                                        },
                                        {
                                            title: t('translation.governmentDocuments.harvestDeclaration.sugar'),
                                            field: "sugar"
                                        },
                                        {
                                            title: t('translation.governmentDocuments.harvestDeclaration.acidity'),
                                            field: "acidity"
                                        }
                                    ]}
                                    options={{
                                        headerStyle: { backgroundColor: '#cecaca', color: '#FFF', zIndex: "auto" },
                                        emptyRowsWhenPaging: false,
                                        paging: true,
                                        pageSize: 50,
                                        pageSizeOptions: [10, 20, 25, 50,],
                                    }}
                                />
                            </div>

                            <div className="bottlingTable">
                                <MaterialTable
                                    title={t('translation.governmentDocuments.bottlingDeclaration.title')}
                                    data={bottlingData}
                                    columns={[
                                        {
                                            title: t('translation.governmentDocuments.bottlingDeclaration.name'),
                                            field: 'name',
                                        },
                                        {
                                            title: t('translation.governmentDocuments.bottlingDeclaration.litersInBottle'),
                                            field: 'liters',
                                        },
                                        {
                                            title: t('translation.governmentDocuments.bottlingDeclaration.bottleVolume'),
                                            field: 'bottleVolume',
                                        },
                                        {
                                            title: t('translation.governmentDocuments.bottlingDeclaration.productionYear'),
                                            field: 'productionYear',
                                        },
                                        {
                                            title: t('translation.governmentDocuments.bottlingDeclaration.recognition'),
                                            field: 'recognition',
                                        },
                                    ]}
                                    options={{
                                        headerStyle: { backgroundColor: '#cecaca', color: '#FFF', zIndex: "auto" },
                                        emptyRowsWhenPaging: false,
                                        paging: true,
                                        pageSize: 50,
                                        pageSizeOptions: [10, 20, 25, 50,],
                                    }}
                                />
                            </div>
                        </>
                    }
            </div>
        )
    } else {
        return (
            <div></div>
        )
    }
}

export default GovernmentDocuments