import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const GoBackButton = ({ location }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const goBack = () => {
        navigate(-1);
    };

    const navigateToLocation = () => {
        navigate(location, { replace: true })
    }

    if (location) {
        return (
            <button onClick={navigateToLocation} className="backbutton">
                {t("vertaling.goback")}
            </button>
        )
    } else {
        return (
            <button onClick={goBack} className="backbutton">
                {t("vertaling.goback")}
            </button>
        )
    }
}

export default GoBackButton