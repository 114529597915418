// Import statements organized by source
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { doc, collection, onSnapshot } from "firebase/firestore";

// Relative imports
import { updateVineyardDoc } from '../../firebase/vineyard';
import { firestore } from '../../firebase/config';
import { useSessionStorage } from '../../logic/useSessionStorage';
import { PageTitle } from '../../utils/PageTitle';
import GoBackButton from "../../components/FormComponents/GoBackButton";

const VineyardInfo = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const { register, setValue, handleSubmit } = useForm({
        defaultValues: {
            name: "",
            height: "",
            soil: "",
            adress: "",
            city: "",
            country: "",
            sunorientation: "",
        },
    });

    const [vineyardDocument, setVineyardDocument] = useState({});
    const [isLoading, setLoading] = useState(true);
    const [currentWinery, setCurrentWinery] = useSessionStorage('currentwinery', '');
    const [showErrorOrientation, setShowErrorOrientation] = useState('hidden');
    const [sunOrientations, setSunOrientations] = useState([]);
    const [selectedSunId, setSelectedSunId] = useState("");
    const { i18n } = useTranslation();
    const lang = i18n.language;
    const [viewName, setViewName] = useState(t("vertaling.vineyard_grapevarieties"));
    let regLang = '';

    // Page setup
    useEffect(() => {
        PageTitle("Vineyard Info");
    }, []);

    if (lang === 'en') {
        regLang = 'nameEN';
    } else if (lang === 'fr') {
        regLang = 'nameFR';
    } else if (lang === 'nl') {
        regLang = 'nameNL';
    }

    console.log(selectedSunId);


    // Fetching vineyard information and sun orientations
    useEffect(() => {
        setLoading(true);

        const docRef = doc(firestore, "winegrowers", currentWinery, "vineyards", params.id);
        const sunRef = collection(firestore, "sunorientation");

        const unsubscribeVineyard = onSnapshot(docRef, (doc) => {
            if (doc.exists()) {
                setVineyardDocument(doc.data());
            }
            setLoading(false);
        });

        const unsubscribeSun = onSnapshot(sunRef, (querySnapshot) => {
            const sunOrientationData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));
            setSunOrientations(sunOrientationData);
        });

        return () => {
            unsubscribeVineyard();
            unsubscribeSun();
        };
    }, [currentWinery, params.id]);

    useEffect(() => {
        if (sunOrientations.length > 0 && vineyardDocument.sunorientation) {
            const matchingSunOrientation = sunOrientations.find(orientation => orientation.id === vineyardDocument.sunorientation);
            if (matchingSunOrientation) {
                setSelectedSunId({
                    value: matchingSunOrientation.id,
                    label: matchingSunOrientation[regLang],
                });
            }
        }
    }, [sunOrientations, vineyardDocument]);

    // Setting form values when vineyardDocument changes
    useEffect(() => {
        const fields = ['country', 'name', 'greenArea', 'height', 'soil', 'adress', 'city', 'sunorientation'];
        fields.forEach(field => {
            if (vineyardDocument[field]) {
                    setValue(field, vineyardDocument[field]);
                }
        });

    }, [vineyardDocument, setValue]);

    // Form submission
    const onSubmit = async (data) => {
        setLoading(true);
        if (!selectedSunId) {
            setShowErrorOrientation("show");
            setLoading(false);
            return;
        }
        data.sunorientation = selectedSunId.value;
        try {
            await updateVineyardDoc({ vid: params.id, ...data });
            navigate("/myvineyards");
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    // UI and form event handlers
    const handleSunChange = (selection) => setSelectedSunId(selection);

    return (
        <div
            className="add-form-container"
            style={{
                maxWidth: 960,
                margin: "50px auto",
                marginTop: "20px",
            }}
        >
            <h1 style={{ color: "#7f7f7f" }}>{t('vertaling.vineyardInfo')}</h1>
            <GoBackButton />
            <br />
            <div
                className="ui grid stackable"
                style={{ justifyContent: "center", marginTop: "-90px" }}
            >
                <form
                    className={`ui big form twelve wide column ${isLoading ? "loading" : ""}`}
                    style={{
                        border: "3px solid #B3B3B3",
                        marginTop: "100px",
                        borderRadius: "50px",
                        borderColor: "#ac9e66",
                    }}
                    onSubmit={handleSubmit(onSubmit)}
                >

                    <div className="fields" style={{ justifyContent: "center" }}>
                        <div className="twelve wide field">
                            <label>
                                <p>{t("translation.name")}</p>
                                <input
                                    type="text"
                                    {...register('name')}
                                    htmlFor="name"
                                    required="required"
                                    defaultValue={vineyardDocument.name}
                                    style={{ textAlign: "center" }} />
                            </label>
                        </div>
                    </div>

                    <div className="fields" style={{ justifyContent: 'center' }}>
                        <div className="twelve wide field">
                            <label>
                                <p>{t("translation.greenArea")}</p>
                                <input
                                    type="text"
                                    {...register('greenArea')}
                                    htmlFor="greenArea"
                                    required="required"
                                    step={0.0001}
                                    defaultValue={vineyardDocument.greenArea}
                                    style={{ textAlign: "center" }} />
                            </label>
                        </div>
                    </div>


                    <div className="fields" style={{ justifyContent: "center" }}>
                        <div className="twelve wide field">
                            <label>
                                <p>{t("vertaling.height")}</p>
                                <input
                                    type="number"
                                    {...register('height')}
                                    htmlFor="height"
                                    min="0"
                                    step=".1"
                                    defaultValue={vineyardDocument.height}
                                    style={{ textAlign: "center" }} />
                            </label>
                        </div>
                    </div>

                    <div className="fields" style={{ justifyContent: "center" }}>
                        <div className="twelve wide field">
                            <label>
                                <p>{t("vertaling.soil")}</p>
                                <input
                                    type="text"
                                    {...register('soil')}
                                    htmlFor="soil"
                                    defaultValue={vineyardDocument.soil}
                                    style={{ textAlign: "center" }} />
                            </label>
                        </div>
                    </div>
                    <div className="fields" style={{ justifyContent: "center" }}>
                        <div className="twelve wide field">
                            <label>
                                {t("vertaling.sunorientation")}
                                <Select
                                    placeholder={t("vertaling.selectsunorientation")}
                                    options={sunOrientations
                                        .sort((a, b) => (a.order > b.order ? 1 : -1))
                                        .map((name, id) => ({
                                            value: name.id,
                                            label: name[regLang],
                                        }))}
                                    value={selectedSunId}
                                    onChange={(selection, action) =>
                                        handleSunChange(selection, action)
                                    }
                                    className="specialty"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        colors: {
                                            ...theme.colors,
                                            primary25: "#dacfa4b2",
                                            primary: "#AC9E66",
                                        },
                                    })}
                                />
                            </label>
                            <p className={showErrorOrientation + " errorMethod"}>
                                {t("vertaling.errorOrientation")}
                            </p>
                        </div>
                    </div>
                    <div
                        className="fields"
                        style={{ justifyContent: "center", paddingBottom: "20px" }}
                    >
                        <div className="twelve wide field">
                            <label>
                                <p>{t("vertaling.adress")}</p>
                                <input
                                    type="text"
                                    {...register('adress')}
                                    htmlFor="adress"
                                    defaultValue={vineyardDocument.adress}
                                    style={{ textAlign: "center" }} />
                            </label>
                        </div>
                    </div>

                    <div
                        className="fields"
                        style={{ justifyContent: "center", paddingBottom: "20px" }}
                    >
                        <div className="twelve wide field">
                            <label>
                                <p>{t("vertaling.city")}</p>
                                <input
                                    type="text"
                                    {...register('city')}
                                    htmlFor="city"
                                    defaultValue={vineyardDocument.city}
                                    style={{ textAlign: "center" }} />
                            </label>
                        </div>
                    </div>

                    <div className="fields" style={{ justifyContent: "center" }}>
                        <div className="twelve wide field">
                            <label>
                                <p>{t("translation.country")}</p>
                                <input
                                    type="text"
                                    {...register('country')}
                                    htmlFor="country"
                                    defaultValue={vineyardDocument.country}
                                    style={{ textAlign: "center" }} />
                            </label>
                        </div>
                    </div>

                    <div className="fields">
                        <div className="field" style={{ display: "none" }}>
                            <label>
                                <p>{t("vertaling.vineyard1")}</p>
                                <input
                                    {...register('winegrower')}
                                    htmlFor="winegrower"
                                    value={currentWinery}
                                    readOnly />
                            </label>
                        </div>
                    </div>

                    <button
                        type="submit"
                        className="ui submit large grey button right floated button-vineyards"
                        style={{ float: "none", marginTop: "30px", width: "25%" }}
                    >
                        {t("vertaling.updatevineyard")}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default VineyardInfo;