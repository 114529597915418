import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react'
import DashboardButton from '../../../components/Dashboard/Button'
import DashboardButtonWithNumber from '../../../components/Dashboard/ButtonWithNumber'
import Divider from '../../../components/Dashboard/Divider'
import { Roles } from '../../../logic/Roles'

const RegistrationButtons = ({ userPermissionLevel, amountOfRegsToApprove }) => {
    const [hidden, setHidden] = useState(false);
    const { t } = useTranslation();


    useEffect(() => {
        let element = document.querySelector(".registrationButtons");
        element.childNodes.length === 0 ? setHidden(true) : setHidden(false);
    }, []);


    return (
        <>
            <Divider title={t('translation.words.registration')} hidden={hidden} />
            <div className="registrationButtons row" style={{ margin: "0", width: "100%" }}>

            <DashboardButton
                    url={'/vineyardsRegistration/selectVineyardStep'}
                    imgAlt={"dashboard-icon"}
                    imgSrc={"/images/DashboardIcons/RegisterVineyard.svg"}
                    title={t("translation.vineyardRegistration.addRegistration")}
                    text={t("translation.vineyardRegistration.registerVineyardStepDescription")}
                    userPermissionLevel={userPermissionLevel}
                    btnPermissionLevel={Roles().EMPLOYEE.permissionLevel}
                />

                <DashboardButton
                    url={"/harvestRegistration/selectAgriculturalParcels"}
                    imgAlt={"dashboard-icon"}
                    imgSrc={"/images/DashboardIcons/RegisterHarvest.svg"}
                    title={t("translation.harvestRegistration.registerHarvest")}
                    text={t("translation.harvestRegistration.registerHarvestDescription")}
                    userPermissionLevel={userPermissionLevel}
                    btnPermissionLevel={Roles().EMPLOYEE.permissionLevel}
                />

                <DashboardButton
                    url={"/cellarRegistration/selectStep"}
                    imgAlt={"dashboard-icon"}
                    imgSrc={"/images/DashboardIcons/RegisterCellar.svg"}
                    title={t("translation.cellarRegistration.registerCellarStep")}
                    text={t("translation.cellarRegistration.registerCellarStepDescription")}
                    userPermissionLevel={userPermissionLevel}
                    btnPermissionLevel={Roles().ADMIN.permissionLevel}
                />

                <DashboardButtonWithNumber
                    url={"/reviewregistrations"}
                    imgAlt={"dashboard-icon"}
                    imgSrc={"/images/DashboardIcons/Approve.svg"}
                    title={t("vertaling.approve_registration")}
                    text={t("vertaling.approve_registrations")}
                    number={amountOfRegsToApprove}
                    userPermissionLevel={userPermissionLevel}
                    btnPermissionLevel={9}
                />
            </div>
        </>
    )
}

export default RegistrationButtons