import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { firestore } from "../../firebase/config";
import { updateParcelDocument } from "../../firebase/parcel";
import { PageTitle } from "../../utils/PageTitle";
import { useSessionStorage } from "../../logic/useSessionStorage";
import GoBackButton from "../../components/FormComponents/GoBackButton";
import { doc, onSnapshot } from "firebase/firestore";

const ParcelDetails = () => {
    PageTitle("Parcel Information");
    const navigate = useNavigate();
    const params =useParams();
    const [currentWinery, setCurrentWinery] = useSessionStorage(
        'currentwinery',
       ''
    );
    var docData = "";
    const { register, setValue, handleSubmit } = useForm();
    const [parcelDocument, setParcelDocument] = useState([]);
    const [error, setError] = useState("hidden");
    const [isLoading, setLoading] = useState(true);
    const { t } = useTranslation();
    const formClassname = `ui big form twelve wide column ${isLoading ? "loading" : ""}`;
    

    useEffect(() => {
        setLoading(true)
        const docRef = doc(firestore, "winegrowers", currentWinery, "parcels", params.id)

        const unsubscribe = onSnapshot(docRef, (doc) => {
            if (doc.exists) {
                docData = doc.data();
                setParcelDocument(docData);
                setLoading(false);
            }
        });
        return () => unsubscribe();
    }, [params.id]);

    const onSubmit = async (data) => {
        try {
                await updateParcelDocument(currentWinery, params.id, data);
                navigate(`/myparcels`);
        } catch (error) {
            console.error(error);
        }
    };


    return (
        <div>
            {isLoading === false && (
                <div
                    className="add-form-container"
                    style={{
                        maxWidth: 960,
                        margin: "50px auto",
                        marginTop: "110px",
                    }}
                >
                   <h1 style={{ color: "#7f7f7f" }}>{t('translation.parcelInfo')}</h1>

                    <GoBackButton />
                    <br />

                    <div className="ui grid stackable">
                        <form
                            className={formClassname}
                            style={{
                                border: "3px solid #B3B3B3",
                                marginTop: "100px",
                                borderRadius: "50px",
                                borderColor: "#ac9e66",
                            }}
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <div
                                className="fields"
                                style={{ justifyContent: "center", marginTop: "20px" }}
                            >
                                <div className="twelve wide field">
                                    <label>
                                        <p>{t("translation.areaPlantedAre")}</p>
                                        <input
                                            type="text"
                                            {...register('areaSizeAre')}
                                            htmlFor="areaSizeAre"
                                            defaultValue={parcelDocument.areaSizeAre} />
                                    </label>
                                </div>
                                <div className="twelve wide field">
                                    <label>
                                        <p>{t("translation.areaPlantedCentiare")}</p>
                                        <input
                                            type="text"
                                            {...register('areaSizeCentiare')}
                                            htmlFor="areaSizeAreCentiare"
                                            defaultValue={parcelDocument.areaSizeCentiare} />
                                    </label>
                                </div>
                            </div>
                            <div
                                className="fields"
                                style={{ justifyContent: "center", marginTop: "20px" }}
                            >
                                <div className="twelve wide field">
                                    <label>
                                        <p>{t("translation.landRegistrationNumber")}</p>
                                        <input
                                            type="text"
                                            {...register('landRegistrationNumber')}
                                            htmlFor="landRegistrationNumber"
                                            defaultValue={parcelDocument.landRegistrationNumber} />
                                    </label>
                                </div>
                            </div>
                            <div
                                className="fields"
                                style={{ justifyContent: "center", marginTop: "20px" }}
                            >
                                <div className="twelve wide field">
                                    <label>
                                        <p>{t("translation.name")}</p>
                                        <input
                                            type="text"
                                            {...register('name')}
                                            htmlFor="name"
                                            required="required"
                                            defaultValue={parcelDocument.name} />
                                    </label>
                                </div>
                            </div>
                            <div
                                className="fields"
                                style={{ justifyContent: "center", marginTop: "20px" }}
                            >
                                <div className="twelve wide field">
                                    <label>
                                        <p>{t("translation.parcelNumber")}</p>
                                        <input
                                            type="number"
                                            {...register('number')}
                                            htmlFor="number"
                                            required="required"
                                            defaultValue={parcelDocument.number} />
                                    </label>
                                </div>
                            </div>
                            <div
                                className="fields"
                                style={{ justifyContent: "center", marginTop: "20px" }}
                            >
                                <div className="twelve wide field">
                                    <label>
                                        <p>{t("translation.agriculturalRegion")}</p>
                                        <input
                                            type="text"
                                            {...register('agriculturalRegion')}
                                            htmlFor="agriculturalRegion"
                                            defaultValue={parcelDocument.agriculturalRegion} />
                                    </label>
                                </div>
                            </div>
                            <div
                                className="fields"
                                style={{ justifyContent: "center", marginTop: "20px" }}
                            >
                                <div className="twelve wide field">
                                    <label>
                                        <p>{t("vertaling.adress")}</p>
                                        <input
                                            type="text"
                                            {...register('address')}
                                            htmlFor="address"
                                            defaultValue={parcelDocument.address} />
                                    </label>
                                </div>
                            </div>
                            <div
                                className="fields"
                                style={{ justifyContent: "center", marginTop: "20px" }}
                            >
                                <div className="twelve wide field">
                                    <label>
                                        <p>{t("translation.zipCode")}</p>
                                        <input
                                            type="text"
                                            {...register('zipCode')}
                                            htmlFor="zipCode"
                                            defaultValue={parcelDocument.zipCode} />
                                    </label>
                                </div>
                            </div>
                            <div
                                className="fields"
                                style={{ justifyContent: "center", marginTop: "20px" }}
                            >
                                <div className="twelve wide field">
                                    <label>
                                        <p>{t("translation.municipality")}</p>
                                        <input
                                            type="text"
                                            {...register('municipality')}
                                            htmlFor="municipality"
                                            defaultValue={parcelDocument.municipality} />
                                    </label>
                                </div>
                            </div>
                            <div
                                className="fields"
                                style={{ justifyContent: "center", marginTop: "20px" }}
                            >
                                <div className="twelve wide field">
                                    <label>
                                        <p>{t("translation.country")}</p>
                                        <input
                                            type="text"
                                            {...register('country')}
                                            htmlFor="country"
                                            defaultValue={parcelDocument.country} />
                                    </label>
                                </div>
                            </div>
                            <div
                                className="fields"
                                style={{ justifyContent: "center", marginTop: "20px" }}
                            >

                            </div>
                            <button
                                type="submit"
                                className="ui submit large grey button right floated button-vineyards"
                                style={{ float: "none", marginTop: "30px", width: "25%" }}
                            >
                                {t("translation.updateParcel")}
                            </button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ParcelDetails;