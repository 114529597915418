import { useTranslation } from 'react-i18next';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import GoBackButton from "../components/FormComponents/GoBackButton";
import { firestore } from '../firebase/config';
import { useSessionStorage } from '../logic/useSessionStorage';
import '../Styles/TraceBatch.css';
import { dateObjectToDate, timestampToDate } from '../utils/DateConversions';
import { PageTitle } from '../utils/PageTitle';
import { doc, query, where, onSnapshot, collection, orderBy } from "firebase/firestore";

const TraceBatch = () => {
    PageTitle("Trace Batch");
    const navigate = useNavigate();
    const params = useParams();
    const lang = localStorage.getItem('Lang');
    const [currentWinery, setCurrentWinery] = useSessionStorage('currentwinery', '');
    const [batchesList, setBatches] = useState([]);
    const [vineyardContent, setVineyardContent] = useState(false);
    const [cellarContent, setCellarContent] = useState(true);
    const [cellarStepsList, setCellarStepList] = useState('');
    const [vineyardStepsList, setVineyardStepsList] = useState('');
    const [vineyardsList, setVineyards] = useState('');
    const [vineyardRegs, setVineyardRegs] = useState([]);
    const [vineyardDateState, setVineyardDateState] = useState()
    const [cellarRegs, setCellarRegs] = useState([]);
    const { t } = useTranslation();

    // Referenties naar firestore database (bv : ophalen van collecties uit firestore db)
    // Alle waarden in de variabelen gaan verloren éénmaal de useEffect volledig uitgevoerd is

    const getImageUrl = async (id) => {
        const storage = getStorage();
        const storageRef = ref(storage, 'images/' + id + '.png');

        try {
            const url = await getDownloadURL(storageRef);
            return url;
        } catch (error) {
            return '';
        }
    };

    const sortByDate = (arr, dateKey) => {
        return arr.sort((a, b) => new Date(a[dateKey]) - new Date(b[dateKey]));
    };

    const updateStepIdByLanguage = (regs, stepList, lang) => {
        for (let i = 0; i < regs.length; i++) {
            for (let h = 0; h < stepList.length; h++) {
                if (stepList[h].id === regs[i].stepId) {
                    if (lang === '"en"') {
                        regs[i].stepId = stepList[h].name;
                    } else if (lang === '"fr"') {
                        regs[i].stepId = stepList[h].nameFR;
                    } else if (lang === '"it"') {
                        regs[i].stepId = stepList[h].nameIT;
                    } else if (lang === '"ne"') {
                        regs[i].stepId = stepList[h].nameNL;
                    }
                }
            }
        }
    }

    const transformDoc = (doc) => ({ id: doc.id, ...doc.data() });

    const processSnapshot = (querySnapshot, transformFunc) => querySnapshot.docs.map(transformFunc);

    const subscribeToCollection = (collectionRef, setState) => {
        const unsubscribe = onSnapshot(collectionRef,
            (querySnapshot) => {
                const items = processSnapshot(querySnapshot, transformDoc);
                setState(items);
            },
            (error) => {
                console.error("Error subscribing to collection: ", error);
            }
        );
        return () => unsubscribe();
    };

    useEffect(() => {
        const batchesRef = query(collection(firestore, 'winegrowers', currentWinery, 'batches'), orderBy('batch'));

        const unsubscribeBatch = onSnapshot(batchesRef, (querySnapshot) => {
            let batches = querySnapshot.docs.map(transformDoc);

            batches = sortByDate(batches, 'createdate');

            batches.forEach(batch => {
                batch.createdate = timestampToDate(batch.createdate, "DD MMM YYYY, H:mm:ss");
            });

            setBatches(batches);
        });
        if (params.batch) {
            const unsubscribeVineyardSteps = subscribeToCollection(query(collection(firestore, 'vineyardsteps')), setVineyardStepsList);
            const unsubscribeCellarSteps = subscribeToCollection(query(collection(firestore, 'cellarsteps')), setCellarStepList);
            const unsubscribeVineyards = subscribeToCollection(query(collection(firestore, 'winegrowers', currentWinery, 'vineyards')), setVineyards);
            return () => {
                unsubscribeBatch();
                unsubscribeCellarSteps();
                unsubscribeVineyardSteps();
                unsubscribeVineyards();
            }
        }
        return () => {
            unsubscribeBatch();
        };
    }, [currentWinery]);

    useEffect(() => {

        if (params.batch) {
            const winegrowerCollection = collection(firestore, 'winegrowers');
            const currentWineryDoc = doc(winegrowerCollection, currentWinery);
            const registrationsCollection = collection(currentWineryDoc, 'registrations');
            const regRef = query(registrationsCollection, where('waitingForReview', '==', false), orderBy('date'));

            const unsubscribe = onSnapshot(regRef, async (querySnapshot) => {
                let regs = querySnapshot.docs.map(transformDoc);

                // overlopen van alle registraties
                for (let i = 0; i < regs.length; i++) {
                    regs[i].date = timestampToDate(regs[i].date, "DD MMM YYYY, H:mm:ss");;

                    // overlopen van alle batches en linken aan de batchId / sourcebatchId in de registraties collectie
                    for (let m = 0; m < batchesList.length; m++) {
                        if (batchesList[m].id === regs[i].batchId) {
                            regs[i].batchId = batchesList[m].batch;
                        }
                        if (batchesList[m].id === regs[i].sourceBatchId) {
                            regs[i].sourceBatchId = batchesList[m].batch;
                        }
                        if (regs[i].splitBatches !== undefined) {
                            for (let n = 0; n < regs[i].splitBatches.length; n++) {
                                if (regs[i].splitBatches[n] === batchesList[m].id) {
                                    regs[i].splitBatches[n] = batchesList[m].batch;
                                }
                            }
                        }
                        if (regs[i].sourceBatchIds !== undefined) {
                            for (let n = 0; n < regs[i].sourceBatchIds.length; n++) {
                                if (regs[i].sourceBatchIds[n] === batchesList[m].id) {
                                    regs[i].sourceBatchIds[n] = batchesList[m].batch;
                                }
                            }
                        }
                    }

                    updateStepIdByLanguage(regs, vineyardStepsList, lang);
                    updateStepIdByLanguage(regs, cellarStepsList, lang);
                }

                let cellarRegsFilter = [];
                // overlopen van alle registraties en toevoegen van alle registraties aan regsFilter array
                //waarbij batchId OF sourceBatchId gelijk zijn aan opgevraagde batch in de selectlist.
                //Ook kijken we of er een split of merge is waarbij we de correct splitBatch opslaan of verschillende sourceBatchIds.

                for (let i = 0; i < regs.length; i++) {
                    if (regs[i].type === 'cellarStep') {
                        if (regs[i].batchId === params.batch) {
                            cellarRegsFilter.push(regs[i]);
                        }
                        if (regs[i].sourceBatchId === params.batch) {
                            cellarRegsFilter.push(regs[i]);
                        }
                        if (regs[i].sourceBatchIds !== undefined) {
                            for (let j = 0; j < regs[i].sourceBatchIds.length; j++) {
                                if (regs[i].sourceBatchIds[j] === params.batch) {
                                    cellarRegsFilter.push(regs[i]);
                                }
                            }
                        }
                        if (regs[i].splitBatches !== undefined) {
                            for (let j = 0; j < regs[i].splitBatches.length; j++) {
                                if (regs[i].splitBatches[j] === params.batch) {
                                    cellarRegsFilter.push(regs[i]);
                                }
                            }
                        }
                    }
                }

                if (cellarRegsFilter.length > 0) {
                    cellarRegsFilter.sort(function (a, b) {
                        return new Date(a.date) - new Date(b.date);
                    });

                    await Promise.all(cellarRegsFilter.map(async (reg) => {
                        const url = await getImageUrl(reg.id);
                        reg.imageUrl = url;
                    }))

                    setCellarRegs(cellarRegsFilter)
                } else {
                    setCellarRegs([])
                }

                let vineyardRegsFilter = [];

                let lastVineyardStep;
                for (let i = 0; i < regs.length; i++) {
                    if (regs[i].type === 'vineyardStep') {
                        if (regs[i].batchId === params.batch) {
                            if (!lastVineyardStep ||
                                new Date(regs[i].date) > new Date(lastVineyardStep.date)) {
                                vineyardRegsFilter.push(regs[i]);
                                lastVineyardStep = regs[i]
                            }
                        }
                    }
                }

                if (vineyardRegsFilter.length > 0) {
                    vineyardRegsFilter.sort(function (a, b) {
                        return new Date(a.date) - new Date(b.date);
                    });

                    await Promise.all(vineyardRegsFilter.map(async (reg) => {
                        const url = await getImageUrl(reg.id);
                        reg.imageUrl = url;
                    }))

                    const firstdate = undefined;
                    const lastdate = vineyardRegsFilter[vineyardRegsFilter.length - 1].date;

                    setVineyardDateState({
                        firstdate: firstdate ? dateObjectToDate(firstdate) : '',
                        lastdate: lastdate ? dateObjectToDate(lastdate) : ''
                    })

                    setVineyardRegs(vineyardRegsFilter)
                } else {
                    setVineyardRegs([])
                }
            });
            return () => {
                unsubscribe();
            };
        }

    }, [params.batch]);

    return (
        <div>
            <h1>{t("vertaling.trace_batch")}</h1>
            <div className='container tracebatch-content'>
                <div className='row header'>
                    <div style={{ width: '350px' }}>
                        <Select
                            id="batch-select"
                            placeholder={t('vertaling.selectBatch')}
                            options={batchesList.map((name, id) => ({
                                value: name.id,
                                label: name.batch,
                            }))}
                            value={params.batch && batchesList.some((b) => b.batch === params.batch) && {
                                value: params.batch ? batchesList.find((b) => b.batch === params.batch)?.id : '',
                                label: params.batch,
                            }}
                            onChange={(selection, action) => navigate(`/tracebatch/${selection.label}`)}
                            className="specialty"
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 4,
                                colors: {
                                    ...theme.colors,
                                    primary25: '#dacfa4b2',
                                    primary: '#AC9E66',
                                },
                            })}
                        />
                    </div>

                    <GoBackButton />
                    <br />
                </div>
                {vineyardRegs.length > 0 && (
                    <div className='dropdown' onClick={() => setVineyardContent(!vineyardContent)}>
                        <span> {t('vertaling.vineyard_steps')} </span>
                        <span className={`arrow ${vineyardContent ? "arrow-down" : "arrow-right"}`}>{">"}</span>
                    </div>
                )}

                {vineyardContent && vineyardRegs.length > 0 && (
                    <div className='steps'>
                        <div id='vineyard-link'>
                            <div onClick={() => {
                                navigate({
                                    pathname: `/tracevineyard/${vineyardsList.find((v) => v.vid === vineyardRegs[0].locationId).name}`,
                                    state: vineyardDateState
                                })
                            }
                            }>
                                {vineyardsList.find((v) => v.vid === vineyardRegs[0].locationId)?.name}
                            </div>
                        </div>
                        {
                            vineyardRegs.map((object, index) => (
                                <div>
                                    <div>
                                        <div>{index + 1}</div>
                                        <div>
                                            {object.stepId} {t('vertaling.on')}{' '}{object.date}
                                        </div>
                                    </div>
                                    <div>
                                        <div
                                            key={index}
                                        >
                                            {object.description ? (
                                                <p>
                                                    {object.description}
                                                </p>
                                            ) : (
                                                ''
                                            )}
                                            {object.batchId ? <p>Batch: {object.batchId}</p> : ''}
                                            {object.sourceBatchId ? (
                                                <p>{t('vertaling.source_batch')} {object.sourceBatchId}</p>
                                            ) : (
                                                ''
                                            )}
                                            {object.temperature ? (
                                                <p>
                                                    {t('vertaling.temperature')}{' '}
                                                    {object.temperature}
                                                </p>
                                            ) : (
                                                ''
                                            )}
                                            {object.amountOfBottles ? (
                                                <p>
                                                    {t('vertaling.amountOfBottles')}{' '}
                                                    {object.amountOfBottles}
                                                </p>
                                            ) : (
                                                ''
                                            )}
                                            {object.amount ? (
                                                <p>
                                                    {t('vertaling.amount')}{' '}
                                                    {object.amount}
                                                </p>
                                            ) : (
                                                ''
                                            )}

                                            {object.amountDrying ? (
                                                <p>
                                                    {t('vertaling.amountDrying')}{' '}
                                                    {object.amountDrying}
                                                </p>
                                            ) : (
                                                ''
                                            )}

                                            {object.wayOfHarvest ? (
                                                <p>
                                                    {t('vertaling.harvestMethod')}{' '}
                                                    {object.wayOfHarvest}
                                                </p>
                                            ) : (
                                                ''
                                            )}

                                            {object.density ? (
                                                <p>
                                                    {t('vertaling.density')}{' '}
                                                    {object.density}
                                                </p>
                                            ) : (
                                                ''
                                            )}

                                            {object.acidity ? (
                                                <p>
                                                    {t('vertaling.acidity')}{' '}
                                                    {object.acidity}
                                                </p>
                                            ) : (
                                                ''
                                            )}

                                            {object.alcohol ? (
                                                <p>
                                                    {t('vertaling.alcohol')}{' '}
                                                    {object.alcohol}
                                                </p>
                                            ) : (
                                                ''
                                            )}

                                            {object.massDensity ? (
                                                <p>
                                                    {t('vertaling.massDensity')}{' '}
                                                    {object.massDensity}
                                                </p>
                                            ) : (
                                                ''
                                            )}

                                            {object.ph ? (
                                                <p>
                                                    {t('vertaling.ph')} {object.ph}
                                                </p>
                                            ) : (
                                                ''
                                            )}

                                            {object.sugars ? (
                                                <p>
                                                    {t('vertaling.sugars')}{' '}
                                                    {object.sugars}
                                                </p>
                                            ) : (
                                                ''
                                            )}

                                            {object.typeOfFermentation ? (
                                                <p>
                                                    {t('vertaling.fermentationType')}{' '}
                                                    {object.typeOfFermentation}
                                                </p>
                                            ) : (
                                                ''
                                            )}

                                            {object.products ? (
                                                <p>
                                                    {t('vertaling.productsAdded')}{' '}
                                                    {object.products}
                                                </p>
                                            ) : (
                                                ''
                                            )}

                                            {object.productsUsed ? (
                                                <p>
                                                    {t('vertaling.productUsed')}{' '}
                                                    {object.productsUsed}
                                                </p>
                                            ) : (
                                                ''
                                            )}

                                            {object.amountUsed ? <p> {object.amountUsed}</p> : ''}

                                            {object.sourceBatchIds ? (
                                                <p>
                                                    {t('vertaling.merged')}{' '}
                                                    {object.sourceBatchIds.map((item2, index2) => {
                                                        return (
                                                            <Link
                                                                to={`/tracebatch/${item2}`}
                                                                key={index2}
                                                                style={{
                                                                    display: 'contents',
                                                                    color: '#ac9e66',
                                                                    cursor: 'pointer',
                                                                }}
                                                            >
                                                                {' '}
                                                                <span style={{ textDecoration: 'underline' }}>
                                                                    {item2}
                                                                </span>{' '}
                                                            </Link>
                                                        );
                                                    })}
                                                </p>
                                            ) : (
                                                ''
                                            )}

                                            {object.splitBatches ? (
                                                <p>
                                                    {t('vertaling.Split')}{' '}
                                                    {t('vertaling.into')}:
                                                    {object.splitBatches.map((item3, index3) => {
                                                        return (
                                                            <Link
                                                                to={`/tracebatch/${item3}`}
                                                                key={index3}
                                                                style={{
                                                                    display: 'contents',
                                                                    color: '#ac9e66',
                                                                    cursor: 'pointer',
                                                                }}
                                                            >
                                                                {' '}
                                                                <span style={{ textDecoration: 'underline' }}>
                                                                    {item3}
                                                                </span>{' '}
                                                            </Link>
                                                        );
                                                    })}
                                                </p>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                        <div>
                                            {object.imageUrl && (
                                                <img src={object.imageUrl} />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                )}

                {cellarRegs.length > 0 && (
                    <div className='dropdown' onClick={() => setCellarContent(!cellarContent)}>
                        <span> {t('vertaling.cellar_steps')} </span>
                        <span className={`arrow ${cellarContent ? "arrow-down" : "arrow-right"}`}>{">"}</span>
                    </div>
                )}
                {cellarContent && cellarRegs.length > 0 ? (
                    <div className='steps'>
                        {
                            cellarRegs.map((object, index) => (
                                <div>
                                    <div>
                                        <div>{index + 1}</div>
                                        <div>
                                            {object.stepId} {t('vertaling.on')}{' '}{object.date}
                                        </div>
                                    </div>
                                    <div>
                                        <div
                                            key={index}
                                        >
                                            {object.description ? (
                                                <p>
                                                    {object.description}
                                                </p>
                                            ) : (
                                                ''
                                            )}
                                            {object.temperature ? (
                                                <p>
                                                    {t('vertaling.temperature')}{' '}
                                                    {object.temperature}
                                                </p>
                                            ) : (
                                                ''
                                            )}
                                            {object.amountOfBottles ? (
                                                <p>
                                                    {t('vertaling.amountOfBottles')}{' '}
                                                    {object.amountOfBottles}
                                                </p>
                                            ) : (
                                                ''
                                            )}
                                            {object.amount ? (
                                                <p>
                                                    {t('vertaling.amount')}{' '}
                                                    {object.amount}
                                                </p>
                                            ) : (
                                                ''
                                            )}

                                            {object.amountDrying ? (
                                                <p>
                                                    {t('vertaling.amountDrying')}{' '}
                                                    {object.amountDrying}
                                                </p>
                                            ) : (
                                                ''
                                            )}

                                            {object.wayOfHarvest ? (
                                                <p>
                                                    {t('vertaling.harvestMethod')}{' '}
                                                    {object.wayOfHarvest}
                                                </p>
                                            ) : (
                                                ''
                                            )}

                                            {object.density ? (
                                                <p>
                                                    {t('vertaling.density')}{' '}
                                                    {object.density}
                                                </p>
                                            ) : (
                                                ''
                                            )}

                                            {object.acidity ? (
                                                <p>
                                                    {t('vertaling.acidity')}{' '}
                                                    {object.acidity}
                                                </p>
                                            ) : (
                                                ''
                                            )}

                                            {object.alcohol ? (
                                                <p>
                                                    {t('vertaling.alcohol')}{' '}
                                                    {object.alcohol}
                                                </p>
                                            ) : (
                                                ''
                                            )}

                                            {object.massDensity ? (
                                                <p>
                                                    {t('vertaling.massDensity')}{' '}
                                                    {object.massDensity}
                                                </p>
                                            ) : (
                                                ''
                                            )}

                                            {object.ph ? (
                                                <p>
                                                    {t('vertaling.ph')} {object.ph}
                                                </p>
                                            ) : (
                                                ''
                                            )}

                                            {object.sugars ? (
                                                <p>
                                                    {t('vertaling.sugars')}{' '}
                                                    {object.sugars}
                                                </p>
                                            ) : (
                                                ''
                                            )}

                                            {object.typeOfFermentation ? (
                                                <p>
                                                    {t('vertaling.fermentationType')}{' '}
                                                    {object.typeOfFermentation}
                                                </p>
                                            ) : (
                                                ''
                                            )}

                                            {object.products ? (
                                                <p>
                                                    {t('vertaling.productsAdded')}{' '}
                                                    {object.products}
                                                </p>
                                            ) : (
                                                ''
                                            )}

                                            {object.productsUsed ? (
                                                <p>
                                                    {t('vertaling.productUsed')}{' '}
                                                    {object.productsUsed}
                                                </p>
                                            ) : (
                                                ''
                                            )}

                                            {object.amountUsed ? <p>c {object.amountUsed}</p> : ''}

                                            {object.sourceBatchIds ? (
                                                <div className='merge'>
                                                    <div>
                                                        {object.sourceBatchIds.map((item2, index2) => {
                                                            if (params.batch === item2) {
                                                                return (
                                                                    <span
                                                                        style={{ color: '#ac9e66', fontWeight: 'bold' }}>
                                                                        {item2}
                                                                    </span>
                                                                )
                                                            } else {
                                                                return (
                                                                    <Link
                                                                        to={`/tracebatch/${item2}`}
                                                                        key={index2}
                                                                        style={{
                                                                            display: 'contents',
                                                                            color: '#ac9e66',
                                                                            cursor: 'pointer',
                                                                        }}
                                                                    >
                                                                        {' '}
                                                                        <span style={{ textDecoration: 'underline' }}>
                                                                            {item2}
                                                                        </span>{' '}
                                                                    </Link>
                                                                )
                                                            }
                                                        })}
                                                    </div>
                                                    <div className='bracket'>
                                                        <div>
                                                            <div></div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {params.batch === object.batchId ? (
                                                            <span style={{ color: '#ac9e66', fontWeight: 'bold' }}>
                                                                {object.batchId}
                                                            </span>
                                                        ) : (
                                                            <Link
                                                                to={`/tracebatch/${object.batchId}`}
                                                                style={{
                                                                    display: 'contents',
                                                                    color: '#ac9e66',
                                                                    cursor: 'pointer',
                                                                }}
                                                            >
                                                                {' '}
                                                                <span style={{ textDecoration: 'underline' }}>
                                                                    {object.batchId}
                                                                </span>{' '}
                                                            </Link>
                                                        )}

                                                    </div>
                                                </div>
                                            ) : (
                                                ''
                                            )}

                                            {object.splitBatches ? (
                                                <div className='split'>
                                                    <div>
                                                        {params.batch === object.sourceBatchId ? (
                                                            <span style={{ color: '#ac9e66', fontWeight: 'bold' }}>
                                                                {object.sourceBatchId}
                                                            </span>
                                                        ) : (
                                                            <Link
                                                                to={`/tracebatch/${object.sourceBatchId}`}
                                                                style={{
                                                                    display: 'contents',
                                                                    color: '#ac9e66',
                                                                    cursor: 'pointer',
                                                                }}
                                                            >
                                                                {' '}
                                                                <span style={{ textDecoration: 'underline' }}>
                                                                    {object.sourceBatchId
                                                                    }
                                                                </span>{' '}
                                                            </Link>
                                                        )}

                                                    </div>
                                                    <div className='bracket'>
                                                        <div>
                                                            <div></div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {object.splitBatches.map((item3, index3) => {
                                                            if (params.batch === item3) {
                                                                return (
                                                                    <span
                                                                        style={{ color: '#ac9e66', fontWeight: 'bold' }}>
                                                                        {item3}
                                                                    </span>
                                                                )
                                                            } else {
                                                                return (
                                                                    <Link
                                                                        to={`/tracebatch/${item3}`}
                                                                        key={index3}
                                                                        style={{
                                                                            display: 'contents',
                                                                            color: '#ac9e66',
                                                                            cursor: 'pointer',
                                                                        }}
                                                                    >
                                                                        {' '}
                                                                        <span style={{ textDecoration: 'underline' }}>
                                                                            {item3}
                                                                        </span>{' '}
                                                                    </Link>
                                                                )
                                                            }
                                                        })}
                                                    </div>
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                        <div>
                                            {object.imageUrl && (
                                                <img src={object.imageUrl} />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                ) : (
                    <div style={{ height: 40 }}></div>
                )}

                {vineyardRegs.length === 0 && cellarRegs.length === 0 && batchesList.some((b) => b.batch === params.batch) && (
                    <h1 style={{ color: "#ac9e66db" }}>{t('vertaling.no_batch_registrations')}</h1>
                )}

            </div>
        </div>
    );
};

export default TraceBatch;