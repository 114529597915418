import { useTranslation } from 'react-i18next';
import { deleteField } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { useFirestore } from '../../context/FirestoreContext';
import { PageTitle } from '../../utils/PageTitle';
import { updateProfile, updatePassword } from "firebase/auth"

const FinishRegistration = () => {
  PageTitle("Finish Registration");
  const { currentUser } = useAuth();
  const { currentUserDoc } = useFirestore();
  const { updateCurrentUserDoc } = useFirestore();
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();
  const [isLoading, setLoading] = useState(false);
  const [errorFirst, setErrorFirst] = useState('hidden');
  const [errorLast, setErrorLast] = useState('hidden');
  const [showErrorPass, setErrorPass] = useState('hidden');
  const [showErrorConfirm, setErrorConfirm] = useState('hidden');
  const [errorFirebase, setErrorFirebase] = useState('hidden');
  const [errorFirebaseText, setErrorFirebaseText] = useState('');
  const [errorReturn, setErrorReturn] = useState('hidden');
  const [returnError, setReturnError] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    if (currentUserDoc !== undefined && currentUser !== undefined &&
      (currentUserDoc.registrationFinished === undefined || currentUserDoc.registrationFinished === true)) {
      navigate('/', { replace: true });
    }
  }, [currentUserDoc])

  const onSubmit = async (data, e) => {
    try {
      let updatedUser = {
        firstname: '',
        lastname: '',
        registrationFinished: deleteField(),
      };

      let ready = true;
      setLoading(true);
      if (data.firstname === '') {
        setErrorFirst('show');
        ready = false;
      } else {
        updatedUser.firstname = data.firstname;
        setErrorFirst('hidden');
      }
      if (data.lastname === '') {
        setErrorLast('show');
        ready = false;
      } else {
        updatedUser.lastname = data.lastname;
        setErrorLast('hidden');
      }
      if (data.password === '') {
        setErrorPass('show');
        ready = false;
      } else {
        setErrorPass('hidden');
      }
      if (data.password.trim() !== data.passwordConfirm.trim()) {
        setErrorConfirm('show');
        ready = false;
      } else {
        setErrorConfirm('hidden');
      }
      if (ready) {
        let authenticationUpdatedSuccessfully = true;
        try {
          
          // Update display name
          await updateProfile(currentUser, { displayName: `${data.firstname} ${data.lastname}` });
          setErrorFirebase('hidden');
        } catch (err) {
          console.log("Error updating display name:", err);
          authenticationUpdatedSuccessfully = false;
          setErrorFirebase('show');
          setErrorFirebaseText(err.message);
        }
      
        try {
          // Update password
          await updatePassword(currentUser, data.password);
          setErrorFirebase('hidden');
          
        } catch (err) {
          console.log("Error updating password:", err);
          authenticationUpdatedSuccessfully = false;
          setErrorFirebase('show');
          setErrorFirebaseText(err.message);
        }
      
        // Update Firestore data
        if (authenticationUpdatedSuccessfully) {
          try {
            await updateCurrentUserDoc(updatedUser);
            console.log("Firestore data updated");
          } catch (err) {
            console.log("Error updating Firestore data:", err);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const showPassword = () => {
    let x = document.getElementById('password');
    let icon = document.getElementById('show-password');
    let icon2 = document.getElementById('hid-password');
    icon.classList.toggle('icon-active');
    icon2.classList.toggle('icon-active');
    x.type = 'text';
  }

  const hidePassword = () => {
    let x = document.getElementById('password');
    let icon = document.getElementById('show-password');
    let icon2 = document.getElementById('hid-password');
    icon.classList.toggle('icon-active');
    icon2.classList.toggle('icon-active');
    x.type = 'password';
  }
  const showPasswordConfirmation = () => {
    let x = document.getElementById('passwordConfirm');
    let icon = document.getElementById('show-password2');
    let icon2 = document.getElementById('hid-password2');
    icon.classList.toggle('icon-active');
    icon2.classList.toggle('icon-active');
    x.type = 'text';
  }
  const hidePasswordConfirmation = () => {
    let x = document.getElementById('passwordConfirm');
    let icon = document.getElementById('show-password2');
    let icon2 = document.getElementById('hid-password2');
    icon.classList.toggle('icon-active');
    icon2.classList.toggle('icon-active');
    x.type = 'password';
  }

  const formClassname = `ui big form twelve wide column ${isLoading ? 'loading' : ''
    }`;

  return (
    <div className="add-form-container" style={{ maxWidth: 960, margin: '50px auto', marginTop: '90px' }}>
      {currentUser !== undefined &&
        <div className="ui grid stackable">
          <form className={formClassname} onSubmit={handleSubmit(onSubmit)}>
            <h1>
              {t("vertaling.complete_employee")}
            </h1>
            <div className="fields">
              <div className="sixteen wide field">
                <label>
                  {t("vertaling.email")}
                  <input type="email" name="email" value={currentUser.email} required disabled />
                </label>
              </div>
            </div>

            <div className="fields">
              <div className="sixteen wide field">
                <label>
                  {t("vertaling.firstname")}
                  <input type="text" {...register('firstname')} required />
                </label>
              </div>
            </div>

            <div className="fields">
              <div className="sixteen wide field">
                <label>
                  {t("vertaling.lastname")}
                  <input type="text" {...register('lastname')} required />
                </label>
              </div>
            </div>
            <div className=" field">
              <i
                className="fas fa-eye psswrd-icon-7 icon-active"
                id="show-password"
                onClick={() => showPassword()}
              ></i>
              <i
                className="fas fa-eye-slash psswrd-icon-7"
                id="hid-password"
                onClick={() => hidePassword()}
              ></i>
              <label>
                {t("vertaling.password")}
                <input type="password" {...register('password')} id="password" required />
              </label>
              <p className={showErrorPass + ' errorText'}>
                {t("vertaling.errorPsw")}
              </p>
            </div>
            <div className="field">
              <i
                className="fas fa-eye psswrd-icon-8 icon-active"
                id="show-password2"
                onClick={() => showPasswordConfirmation()}
              ></i>
              <i
                className="fas fa-eye-slash psswrd-icon-8"
                id="hid-password2"
                onClick={() => hidePasswordConfirmation()}
              ></i>
              <label>
                {t("vertaling.cfpassword")}
                <input
                  type="password"
                  id="passwordConfirm"
                  {...register('passwordConfirm')}
                  required />
              </label>
            </div>
            <button type="submit" className="ui submit large grey button right floated">
              {t('vertaling.submit')}
            </button>
          </form>

          <div className="flex_error">
            <p className={showErrorConfirm + ' errorMethod'}>{t("vertaling.errorPswMatch")}</p>
            <p className={errorFirebase + ' errorMethod'}>{errorFirebaseText}</p>
          </div>
        </div>
      }
    </div>
  );
};

export default FinishRegistration;