import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { onSnapshot, query, collection, where, orderBy } from "firebase/firestore";
import { firestore } from '../../firebase/config';
import { Link, useNavigate } from 'react-router-dom';
import { PageTitle } from '../../utils/PageTitle';
import GoBackButton from "../../components/FormComponents/GoBackButton";
import MaterialTable from '@material-table/core';

const WinegrowersList = () => {
    PageTitle("Winegrower List");
    const [winegrowers, setWineGrowers] = useState([]);
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        const growersRef = query(
            collection(firestore, 'winegrowers'), 
            where('approved', '==', true), 
            orderBy('name')
        );

        const unsubscribe = onSnapshot(growersRef, (querySnapshot) => {
            const winegrowers = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setWineGrowers(winegrowers);
        });
        return () => unsubscribe();
    }, []);
    // Weergeven van een formulier waarbij een wijnboer alle gegevens moet invullen

    return (
        <div style={{ marginTop: '90px' }}>
            <h1>{t("vertaling.title_winegrowers")}</h1>
            <GoBackButton location={"/"} />
            <br />
            <br />
            <div className="newbatch">
                <Link to="/winegrowers/add" style={{ color: 'white' }}>
                    <button
                        className="ui primary button login"
                        style={{ marginTop: '60px' }}
                    >
                        {t("vertaling.add_winegrower")}
                    </button>
                </Link>
            </div>
            <div className="batchesList">
                <MaterialTable
                    title=""
                    data={winegrowers}
                    columns={[
                        {
                            title: t('vertaling.winery'),
                            field: 'name',
                        },
                        { title: t('vertaling.city'), field: 'city' },
                        {
                            title: t('vertaling.companyCountry'),
                            field: 'country',
                        },
                    ]}
                    options={{
                        search: true,
                        filtering: true,
                        exportButton: true,
                        headerStyle: {
                            backgroundColor: '#cecaca',
                            color: '#FFF',
                        },
                        emptyRowsWhenPaging: false,
                        paging: true,
                        pageSize: 50,
                        pageSizeOptions: [
                            10,
                            20,
                            25,
                            50,
                        ],
                    }}
                    actions={[
                        {
                            icon: 'save',
                            tooltip: 'Save User',
                            onClick: (event, rowData) =>
                                navigate(`/winegrowers/profile/${rowData.id}`),
                        },
                    ]}
                    components={{
                        Action: (props) => (
                            <>
                                <button
                                    className="ui primary button login"
                                    onClick={(event) => props.action.onClick(event, props.data)}
                                    color="primary"
                                    style={{ textTransform: 'none' }}
                                >
                                    {t("vertaling.details")}
                                </button>

                                <button
                                    className="ui red button"
                                    onClick={() => navigate(`/winegrowers/delete/${props.data.id}`)}
                                    style={{ textTransform: 'none' }}
                                >
                                    {t("translation.words.delete")}
                                </button>
                            </>
                        ),
                    }}
                    // Dit wordt gebruikt om alles te vertalen in de tabel die hierboven wordt gedefineerd
                    localization={{
                        body: {
                            emptyDataSourceMessage: t('vertaling.emptyDataSourceMessage'),
                            addTooltip: t('vertaling.addTooltip'),
                            deleteTooltip: t('vertaling.deleteTooltip'),
                            editTooltip: t('vertaling.editTooltip'),
                            filterRow: {
                                filterTooltip: t('vertaling.filterTooltip'),
                            },
                            editRow: {
                                deleteText: t('vertaling.deleteText'),
                                cancelTooltip: t('vertaling.cancelTooltip'),
                                saveTooltip: t('vertaling.submit '),
                            },
                        },
                        grouping: {
                            placeholder: t('vertaling.placeholder'),
                            groupedBy: t('vertaling.groupedBy'),
                        },
                        header: {
                            actions: t('vertaling.actions'),
                        },
                        pagination: {
                            labelDisplayedRows: t('vertaling.labelDisplayedRows'),
                            labelRowsPerPage: t('vertaling.labelRowsPerPage'),
                            firstAriaLabel: t('vertaling.firstAriaLabel'),
                            firstTooltip: t('vertaling.firstAriaLabel'),
                            previousAriaLabel: t('vertaling.previousAriaLabel'),
                            previousTooltip: t('vertaling.previousAriaLabel'),
                            nextAriaLabel: t('vertaling.nextAriaLabel'),
                            nextTooltip: t('vertaling.nextAriaLabel'),
                            lastAriaLabel: t('vertaling.lastAriaLabel'),
                            lastTooltip: t('vertaling.lastAriaLabel'),
                        },
                        toolbar: {
                            addRemoveColumns: t('vertaling.addRemoveColumns'),
                            nRowsSelected: t('vertaling.nRowsSelected'),
                            showColumnsTitle: t('vertaling.showColumnsTitle'),
                            showColumnsAriaLabel: t('vertaling.showColumnsTitle'),
                            exportTitle: t('vertaling.exportTitle'),
                            exportAriaLabel: t('vertaling.exportTitle'),
                            exportName: t('vertaling.exportName'),
                            searchTooltip: t('vertaling.searchTooltip'),
                            searchPlaceholder: t('vertaling.searchTooltip'),
                            exportCSVName: t('vertaling.exportName'),
                            exportPDFName: t('vertaling.exportPDFName'),
                        },
                    }}
                />
            </div>
        </div>
    );
};

export default WinegrowersList;