import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { firestore } from '../../firebase/config';
import { updateBatchDocument } from '../../firebase/batch';
import { useSessionStorage } from '../../logic/useSessionStorage';
import { PageTitle } from '../../utils/PageTitle';
import GoBackButton from "../../components/FormComponents/GoBackButton";
import { doc, getDoc, onSnapshot, query } from 'firebase/firestore';


const EditBatch = () => {
    PageTitle("Edit Batch");
    const navigate = useNavigate();
    const params = useParams();
    const { register, setValue, handleSubmit } = useForm();
    const [batchDocument, setBatchDocument] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [currentWinery, setCurrentWinery] = useSessionStorage( 'currentwinery', '');
    var documentData = '';
    const { t } = useTranslation();

    // Referenties naar firestore database (bv : ophalen van collecties uit firestore db)
    // Alle waarden in de variabelen gaan verloren éénmaal de useEffect volledig uitgevoerd is



    useEffect(() => {
        const docRef = doc(firestore, 'winegrowers', currentWinery, 'batches', params.id);

        const unsubscribe = onSnapshot(docRef, (doc) => {
            if (doc.exists) {
                documentData = doc.data();

                documentData.createDate = documentData.createDate
                    .toDate()
                    .toLocaleString('sv-SE', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                    })
                    .replace(' ', 'T');

                setBatchDocument(documentData);
                const formData = Object.entries(documentData).forEach(([key, value]) => {
                    setValue(key, value);
                });
                setValue(formData);
            }
        });
        return () => unsubscribe();
    }, [setValue, params.id]);

    const onSubmit = async (data) => {
        try {
            setLoading(true);
            await updateBatchDocument(data, currentWinery, batchDocument.id);
        } catch (error) {
        } finally {
            setLoading(false);
            navigate(`/batches`);
        }
    };

    if (!batchDocument) {
        return null;
    }

    const formClassname = `ui big form twelve wide column ${isLoading ? 'loading' : ''
        }`;

    // Het weergeven van alle gegevens van een wijnboer voordat deze wordt goedgekeurd

    return (
        <div
            className="add-form-container"
            style={{ maxWidth: 960, margin: '50px auto', marginTop: '110px' }}
        >
            <h2>{t("vertaling.title_batchEdit")}</h2>
            <br />
            <GoBackButton />
            <br />
            <div className="ui grid stackable">
                <form
                    className={formClassname}
                    style={{ margin: 'auto', marginTop: '50px' }}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <div className="fields">
                        <div className="eight wide field">
                            <label>
                                {t("vertaling.name")}
                                <input type="text" {...register('name')} htmlFor="name" required />
                            </label>
                        </div>

                        <div className="eight wide field">
                            <label>
                                {t("vertaling.colCreatieDatum")}
                                <input
                                    type="datetime-local"
                                    {...register('createDate')}
                                    htmlFor="createDate"
                                    required />
                            </label>
                        </div>
                    </div>
                    <div className="fields">
                        <div className="eight wide field">
                            <label>
                                {t("vertaling.colCreatedBy")}
                                <input type="text" {...register('email')} htmlFor="email" required disabled />
                            </label>
                        </div>

                        <div className="eight wide field">
                            <label>
                                {t("vertaling.colHistoric")}
                                <input type="text" {...register('historic')} htmlFor="historic" required />
                            </label>
                        </div>
                    </div>

                    <div className="fields">
                        <div className="eight wide field">
                            <label>
                                {t("vertaling.colAvailable")}
                                <select
                                    className="specialty"
                                    {...register('available')}
                                    style={{ marginTop: '0' }}
                                    required>
                                    <option value="true">
                                        {t('vertaling.colAvailable')}
                                    </option>
                                    <option value="false">
                                        {t('vertaling.colNoAvailable')}
                                    </option>
                                </select>
                            </label>
                        </div>
                    </div>

                    <button
                        type="submit"
                        className="ui submit large grey button right floated"
                    >
                        {t("vertaling.submit_batchEdit")}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default EditBatch;