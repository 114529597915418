import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { firestore } from '../../firebase/config';
import { updateBarrel } from '../../firebase/Cellar';
import { useSessionStorage } from '../../logic/useSessionStorage';
import { PageTitle } from '../../utils/PageTitle';
import GoBackButton from "../../components/FormComponents/GoBackButton";
import { doc, onSnapshot } from 'firebase/firestore';


const EditBarrel = () => {
    PageTitle("Edit Barrel");
    const [barrel, setBarrel] = useState([]);
    const [currentWinery, setCurrentWinery] = useSessionStorage('currentwinery', '');
    const { register, setValue, handleSubmit } = useForm();
    const [isLoading, setLoading] = useState(false);
    const [isWood, setIsWood] = useState(false);
    const [cellarId, setCellarId] = useState([]);
    const [woodTypeRequired, setWoodTypeRequired] = useState(false);
    const params = useParams();
    const navigate = useNavigate();
    let documentData = '';
    const { t } = useTranslation();

    useEffect(() => {
        // Referenties naar firestore database (bv : ophalen van collecties uit firestore db)
        // Alle waarden in de variabelen gaan verloren éénmaal de useEffect volledig uitgevoerd is

        const barrelsRef = doc(firestore, 'winegrowers', currentWinery, 'wineStorages', params.id);
        const unsubscribe = onSnapshot(barrelsRef, (doc) => {
            if (doc.exists) {
                documentData = doc.data();

                if (documentData.type === 'wood') {
                    setIsWood(true);
                }

                if (documentData.dateFirstUsed !== null) {
                    documentData.birthdate = documentData.dateFirstUsed
                        .toDate()
                        .toLocaleString('sv-SE', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                        });
                }

                setCellarId(documentData.cellarId);
                setBarrel(documentData);
                // Manier om default values aan formulier toe te voegen
                const formData = Object.entries(documentData).forEach(([key, value]) => {
                    setValue(key, value);
                });
            }
        });
        return () => unsubscribe();
    }, []);

    const onSubmit = async (data) => {
        try {
            setLoading(true);
            const mergedData = { ...barrel, ...data, cellarId, currentWinery };
            await updateBarrel(mergedData, params.id, currentWinery)
        } catch (error) {
        } finally {
            setLoading(false);
            navigate(-1);
        }
    };

    if (!barrel) {
        return null;
    }

    const formClassname = `ui big form twelve wide column ${isLoading ? 'loading' : ''
        }`;

    if (document.getElementById('type-select') !== null) {
        document
            .getElementById('type-select')
            .addEventListener('change', function () {
                if (this.value === 'wood') {
                    setIsWood(true);
                    setWoodTypeRequired(true);
                } else {
                    setIsWood(false);
                    setWoodTypeRequired(false);
                }
            });
    }

    let showWood = `hidden`;
    if (isWood) {
        showWood = `show`;
    }

    return (
        // Een vat/tank weergeven en aanpassen

        <div
            className="add-form-container"
            style={{ maxWidth: 960, margin: '50px auto', marginTop: '130px' }}
        >
            <h2>{t("translation.barrels.barrelInfo")}</h2>
            <GoBackButton />
            <br />
            <div className="ui grid stackable" style={{ marginTop: '30px' }}>
                <form
                    className={formClassname}
                    style={{ margin: 'auto' }}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <div className="fields">
                        <div className="eight wide field">
                            <label>
                                {t("vertaling.name")}
                                <input type="text" {...register('name')} htmlFor="name" required />
                            </label>
                        </div>

                        <div className="eight wide field">
                            <label>
                                {t("translation.barrels.totalVolume")}
                                <input
                                    type="number"
                                    {...register('totalVolume')}
                                    htmlFor="totalVolume"
                                    min="0"
                                    step="0.01"
                                    required />
                            </label>
                        </div>
                    </div>
                    <div className='field'>
                        <label>
                            {t('translation.barrels.numberOfBarrels')}
                            <input
                                type='number'
                                {...register('number')}
                                htmlFor='number'
                                min='0'
                                step='1'
                                required />
                        </label>
                    </div>
                    <div className="field">
                        <label>
                            {t("vertaling.type_vat")}
                            <select
                                className="specialty"
                                {...register('type')}
                                htmlFor="type"
                                id="type-select">
                                <option value="inox">Inox</option>
                                <option value="wood">
                                    {t('vertaling.wood')}
                                </option>
                                <option value="concrete">
                                    {t('vertaling.typeConcrete')}
                                </option>
                                <option value="amfora">Amfora</option>
                                <option value="egg">Egg</option>
                                <option value="plastic">Plastic</option>
                                <option value="glass">Glass</option>
                            </select>
                        </label>
                    </div>
                    <div className="fields">
                        <div className={showWood + ' eight wide field'}>
                            <label>
                                {t("vertaling.type_wood")}
                                <input
                                    {...register('woodtype')}
                                    htmlFor="woodtype"
                                    type="text"
                                    required={woodTypeRequired} />
                            </label>
                        </div>
                        <div className="eight wide field">
                            <label>
                                {t("vertaling.date")}
                                <input
                                    type="date"
                                    {...register('birthdate')}
                                    htmlFor="birthdate"
                                    disabled={!isWood} />
                            </label>
                        </div>
                    </div>
                    <div className="eight wide field" style={{display: 'none'}}>
                        <label>
                            {t('vertaling.cellar')}
                            <input {...register('cellar')} htmlFor="cellar" value={cellarId} readOnly />
                        </label>
                    </div>
                    <div className="eight wide field" style={{display: 'none'}}>
                        <label>
                            {t('vertaling.winegrower')}
                            <input
                                {...register('winegrower')}
                                htmlFor="winegrower"
                                value={currentWinery}
                                readOnly />
                        </label>
                    </div>

                    <button
                        type="submit"
                        className="ui submit large grey button right floated"
                    >
                        {t("vertaling.submit")}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default EditBarrel;