// Importeren van React packages en bibliotheken en andere React componenten

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import GoBackButton from "../../components/FormComponents/GoBackButton";
import { useAuth } from '../../context/AuthContext';
import { updateProfile } from "firebase/auth"
import { useFirestore } from '../../context/FirestoreContext';
import { PageTitle } from '../../utils/PageTitle';

const Profile = () => {
  PageTitle("Profile");
  const { currentUser } = useAuth();
  const { currentUserDoc } = useFirestore();
  const { updateCurrentUserDoc } = useFirestore();
  const [isLoading, setLoading] = useState(true);
  const [userData, setUserdata] = useState({firstname: '', lastname: '', email: ''});
  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      firstname: userData.firstname,
      lastname: userData.lastname,
      email: userData.email
    }
  });
  
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Referenties naar firestore database (bv : ophalen van collecties uit firestore db)
  // Alle waarden in de variabelen gaan verloren éénmaal de useEffect volledig uitgevoerd is

  useEffect(() => {
    if (currentUserDoc !== undefined) {
      setUserdata(currentUserDoc);
      setValue('firstname', currentUserDoc.firstname);
      setValue('lastname', currentUserDoc.lastname);
      setValue('email', currentUserDoc.email);
      setLoading(false);
    }
  }, [currentUserDoc]);

  //submitting form
  const onSubmit = async (data) => {
    try {
      let updatedUser = {
        firstname: data.firstname,
        lastname: data.lastname,
      };
      let newDisplayName = `${data.firstname} ${data.lastname}`;

      setLoading(true);
      await updateCurrentUserDoc(updatedUser);
      await updateProfile(currentUser, { displayName: newDisplayName, });

      updateNavbarDisplayName(newDisplayName);
      navigate("/");
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const updateNavbarDisplayName = (displayName) => {
    const displayNameNode = document.getElementById("NavbarDisplayName");
    displayNameNode.innerHTML = displayName;
  }

  const deleteAccount = () => {
    navigate("DeleteAccount");
  }

  const formClassname = `ui big form twelve wide column ${isLoading ? 'loading' : ''} `;

  return (
    <div
      className="add-form-container"
      style={{
        maxWidth: 960,
        margin: '50px auto',
        marginTop: '80px',
      }}>
      <h1>{t("vertaling.title_profile")}</h1>
      <GoBackButton />
      <br />
      <div className="ui grid stackable" style={{ justifyContent: 'center', marginTop: '-90px' }}>
        <form
          className={formClassname}
          style={{
            border: '3px solid #B3B3B3',
            marginTop: '100px',
            borderRadius: '50px',
            background: '#edeaea',
            borderColor: '#ac9e66',
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="fields" style={{ justifyContent: 'center' }}>
            <div className="twelve wide field">
              <label>
                {t("vertaling.firstname")}
                {isLoading
                  ? <input
                  type="text"
                  {...register('firstname')}
                  htmlFor="firstname"
                  required="required" />
                  : <input
                  type="text"
                  {...register('firstname')}
                  htmlFor="firstname"
                  required="required"
                  defaultValue={isLoading ? '' : userData.firstname} />
                }
              </label>
            </div>
          </div>

          <div className="fields" style={{ justifyContent: 'center' }}>
            <div className="twelve wide field">
              <label>
                {t("vertaling.lastname")}
                {isLoading
                  ? <input
                  type="text"
                  {...register('lastname')}
                  htmlFor="lastname"
                  required="required" />
                  : <input
                  type="text"
                  {...register('lastname')}
                  htmlFor="lastname"
                  required="required"
                  defaultValue={isLoading ? '' : userData.lastname} />
                }
              </label>
            </div>
          </div>

          <div className="fields" style={{ justifyContent: 'center' }}>
            <div className="twelve wide field">
              <label>
                {t("vertaling.email")}
                {isLoading
                  ? <input
                  type="text"
                  {...register('email')}
                  htmlFor="email"
                  required="required"
                  disabled />
                  : <input
                  type="text"
                  {...register('email')}
                  htmlFor="email"
                  required="required"
                  value={isLoading ? '' : userData.email}
                  disabled />
                }
              </label>
            </div>
          </div>

          <button type="submit" className="ui submit large grey button right floated bttn-profile" style={{ float: 'none', marginTop: '30px', width: '25%' }}>
            {t("vertaling.update_profile")}
          </button>
          <button onClick={deleteAccount} className="ui submit large red button right floated bttn-profile" style={{ float: 'none', marginTop: '30px', width: '25%' }}>
            {t("translation.deleteAccount")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Profile;