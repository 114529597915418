import { doc, setDoc, updateDoc, collection, addDoc, deleteDoc } from 'firebase/firestore';
import { firestore } from './config';

export const createVineyardDocument = async (step) => {
  const docRef = doc(firestore, `vineyardsteps/${step.id}`);
  const stepProfile = {
    name: step.name, 
    number: step.number,
  };
  return setDoc(docRef, stepProfile);
};

export const updateStepDocument = async (step) => {
  const docRef = doc(firestore, 'vineyardsteps/WX1yqfLkRr7XG8wJ1lZ1');
  return updateDoc(docRef, step);
};

export const updateContentVineyardStep = async (winegrower, vineyardStep, data) => {
  const docRef = doc(firestore, `winegrowers/${winegrower}/vineyardSteps/${vineyardStep}`);
  return setDoc(docRef, data);
};

export const createVineyardStep = async (winegrower, data) => {
  const colRef = collection(firestore, `winegrowers/${winegrower}/vineyardSteps`);
  const vineyardStep = {
    ...data,
    number: Number(data.number),
  };
  return addDoc(colRef, vineyardStep);
};

export const createVineyardStepContent = async (winegrower, vineyardStepId, data) => {
  const docRef = doc(firestore, `winegrowers/${winegrower}/vineyardSteps/${vineyardStepId}`);
  return setDoc(docRef, data);
};

export const removeVineyardStep = async (winegrower, vineyardStepId) => {
  const docRef = doc(firestore, `winegrowers/${winegrower}/vineyardSteps/${vineyardStepId}`);
  return deleteDoc(docRef);
};

export const updateVineyardStep = async (winegrower, vineyardStepId, data) => {
  const docRef = doc(firestore, `winegrowers/${winegrower}/vineyardSteps/${vineyardStepId}`);
  return updateDoc(docRef, data);
};
