import { useTranslation } from 'react-i18next';
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { collection, query, onSnapshot, where } from 'firebase/firestore';
import { PageTitle } from "../../utils/PageTitle";
import { useSessionStorage } from "../../logic/useSessionStorage";
import styles from "../Parcel/MyParcels.module.css";
import { firestore } from "../../firebase/config";
import GoBackButton from "../../components/FormComponents/GoBackButton";
import MaterialTable from '@material-table/core';

const MyVineyards = () => {
    PageTitle("Vineyards");
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [vineyards, setVineyards] = useState([]);
    const [currentWinery, setCurrentWinery] = useSessionStorage("currentwinery", "");
    const [agriculturalParcels, setAgriculturalParcels] = useState([]);
    const [selectedVineyard, setSelectedVineyard] = useState('');
    const [nameSelectedVineyard, setNameSelectedVineyard] = useState(t('translation.none'));
    const [errorMessage, setErrorMessage] = useState('');


    useEffect(() => {
        const vineyardsRef = collection(firestore, 'winegrowers', currentWinery, 'vineyards');
        const unsubscribe = onSnapshot(vineyardsRef, (querySnapshot) => {
          const vineyards = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setVineyards(vineyards);
        });
    
        return () => unsubscribe();
      }, [currentWinery, firestore]);

    const fetchAgriculturalParcels = (event, rowData) => {
        setSelectedVineyard(rowData.id);
        setNameSelectedVineyard(rowData.name);
        const agriculturalParcelsQuery = query(
            collection(firestore, 'winegrowers', currentWinery, 'agriculturalParcels'),
            where('vineyardId', '==', rowData.id)
          );

          const unsubscribe = onSnapshot(agriculturalParcelsQuery, (snapshot) => {
            const agriculturalParcelDoc = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setAgriculturalParcels(agriculturalParcelDoc);
        });
        return () => unsubscribe();
    };

    const naviagteToNewArgiculturalParcel = () => {
        if (selectedVineyard !== '') {
            navigate(`/myparcels/myagriculturalparcels/create/${selectedVineyard}`)
        } else {
            setErrorMessage(
                <h2 className='alert alert-danger'>
                    {t('translation.vineyardNotSelected')}
                </h2>
            )
        }
    }

    return (
        <div style={{ position: "relative", marginTop: "90px" }}>
            <h1 style={{ color: "#7f7f7f" }}>{t('vertaling.myvineyards')}</h1>
            <GoBackButton location={"/"}/>
            <br />
            <p style={{ color: "#7f7f7f" }}>{t('vertaling.explainVineyardDetailUI')}</p>
            <div className="newvineyard">
                <Link to="/createvineyard" style={{ color: "white" }}>
                    <button className="ui primary button login" style={{ marginTop: "25px" }}>
                        {t('vertaling.createvineyard')}
                    </button>
                </Link>
            </div>
            <div className="batchesList">
                <MaterialTable
                    title=""
                    data={vineyards}
                    onRowClick={fetchAgriculturalParcels}
                    columns={[
                        {
                            title: t('vertaling.name'),
                            field: "name",
                        },
                        {
                            title: t('vertaling.city'),
                            field: "city",
                        },
                        {
                            title: t('vertaling.adress'),
                            field: "adress",
                        },
                        {
                            title: t('vertaling.height'),
                            field: "height",
                        },
                        {
                            title: t('vertaling.soil'),
                            field: "soil",
                        },
                    ]}
                    options={{
                        search: true,
                        filtering: true,
                        exportButton: true,
                        exportAllData: true,
                        headerStyle: {
                            backgroundColor: "#cecaca",
                            color: "#FFF",
                        },
                        emptyRowsWhenPaging: false,
                        paging: true,
                        pageSize: 50,
                        pageSizeOptions: [
                            10,
                            20,
                            25,
                            50,
                        ],
                    }}
                    actions={[
                        {
                            icon: "save",
                            tooltip: "Save User",
                            onClick: (event, rowData) =>
                                navigate(`/vineyardinfo/${rowData.id}`),
                        },
                    ]}
                    components={{
                        Action: (props) => (
                            <div className="flex_grape">
                                <button
                                    className="ui primary button login"
                                    onClick={(event) => props.action.onClick(event, props.data)}
                                    color="primary"
                                    style={{ textTransform: "none" }}
                                >
                                   {t('vertaling.details')}
                                </button>
                            </div>
                        ),
                    }}
                    localization={{
                        body: {
                            emptyDataSourceMessage: t('vertaling.emptyDataSourceMessage'),
                            addTooltip: t('vertaling.addTooltip'),
                            deleteTooltip: t('vertaling.deleteTooltip'),
                            editTooltip: t('vertaling.editTooltip'),
                            filterRow: {
                                filterTooltip: t('vertaling.filterTooltip'),
                            },
                            editRow: {
                                deleteText: t('vertaling.deleteText'),
                                cancelTooltip: t('vertaling.cancelTooltip'),
                                saveTooltip: t('vertaling.submit '),
                            },
                        },
                        grouping: {
                            placeholder: t('vertaling.placeholder'),
                            groupedBy: t('vertaling.groupedBy'),
                        },
                        header: {
                            actions: t('vertaling.actions'),
                        },
                        pagination: {
                            labelDisplayedRows: t('vertaling.labelDisplayedRows'),
                            labelRowsPerPage: t('vertaling.labelRowsPerPage'),
                            firstAriaLabel: t('vertaling.firstAriaLabel'),
                            firstTooltip: t('vertaling.firstAriaLabel'),
                            previousAriaLabel: t('vertaling.previousAriaLabel'),
                            previousTooltip: t('vertaling.previousAriaLabel'),
                            nextAriaLabel: t('vertaling.nextAriaLabel'),
                            nextTooltip: t('vertaling.nextAriaLabel'),
                            lastAriaLabel: t('vertaling.lastAriaLabel'),
                            lastTooltip: t('vertaling.lastAriaLabel'),
                        },
                        toolbar: {
                            addRemoveColumns: t('vertaling.addRemoveColumns'),
                            nRowsSelected: t('vertaling.nRowsSelected'),
                            showColumnsTitle: t('vertaling.showColumnsTitle'),
                            showColumnsAriaLabel: t('vertaling.showColumnsTitle'),
                            exportTitle: t('vertaling.exportTitle'),
                            exportAriaLabel: t('vertaling.exportTitle'),
                            exportName: t('vertaling.exportName'),
                            searchTooltip: t('vertaling.searchTooltip'),
                            searchPlaceholder: t('vertaling.searchTooltip'),
                            exportCSVName: t('vertaling.exportName'),
                            exportPDFName: t('vertaling.exportPDFName'),
                        },
                    }}
                />
            </div>
            <br />
            <h1 style={{ color: "#7f7f7f", marginTop: 40, marginBottom: 0 }}>{t('translation.grapeVarietyOverview')}</h1>
            <h5>
                <span style={{ color: "#7f7f7f", marginTop: 40, marginBottom: 0 }}>{t('translation.selectedVineyard')}</span>
                {nameSelectedVineyard}
            </h5>

            {errorMessage}
            
            <p style={{ color: "#7f7f7f", marginTop: 40, marginBottom: 0 }}>{t('translation.agriculturalParcelExplanation')}</p>

            <div className="newvineyard">
                <button
                    className="ui primary button login"
                    style={{ marginTop: "25px" }}
                    onClick={naviagteToNewArgiculturalParcel}
                >
                    {t('translation.createAgriculturalParcel')}
                </button>
            </div>



            <div className={styles.parcel_table}>
                <MaterialTable
                    title=""
                    data={agriculturalParcels}
                    columns={[
                        {
                            title: t('translation.name'),
                            field: "name",
                        },
                        {
                            title: t('translation.grapeVariety'),
                            field: "grapeVariety",
                        },
                        {
                            title: t('translation.numberOfPlants'),
                            field: "numberOfPlants",
                            type: "numeric",
                            cellStyle: {
                                textAlign: "left",
                            },
                        },
                        {
                            title: t('translation.areaPlantedAre'),
                            field: 'areaPlantedAre',
                            render: rowData => {
                                const areaPlantedAre = rowData.areaPlantedAre !== null ? rowData.areaPlantedAre : 0;
                                const areaPlantedCentiare = rowData.areaPlantedCentiare !== null ? rowData.areaPlantedCentiare : 0;               
                                const result = areaPlantedAre + areaPlantedCentiare / 100;               
                                return <div>{isNaN(result) ? 0 : result}</div>;
                              },
                        }
                    ]}
                    options={{
                        search: true,
                        filtering: true,
                        exportButton: true,
                        exportAllData: true,
                        headerStyle: {
                            backgroundColor: "#cecaca",
                            color: "#FFF",
                            textAlign: "left",
                        },
                        emptyRowsWhenPaging: false,
                        paging: true,
                        pageSize: 50,
                        pageSizeOptions: [
                            10,
                            20,
                            25,
                            50,
                        ],
                    }}
                    actions={[
                        {
                            onClick: (event, rowData) =>
                                navigate(`/agriculturalparcel/details/${rowData.id}/${rowData.parcelId}/${rowData.vineyardId}`)
                        },
                    ]}
                    components={{
                        Action: (props) => (
                            <div className="flex_grape">
                                <button
                                    className="ui primary button login"
                                    onClick={(event) => props.action.onClick(event, props.data)}
                                    color="primary"
                                    style={{ textTransform: "none" }}
                                >
                                    {t("vertaling.details")}
                                </button>
                            </div>
                        ),
                    }}
                />
            </div>
        </div>
    );
};

export default MyVineyards;