const Slider = ({ id, checked, onChange }) => {
    if (onChange) {
        return (
            <div>
                <label className="switch" htmlFor={id}>
                    <input type="checkbox" id={id} defaultChecked={checked} onClick={onChange} />
                    <div className="slider round"></div>
                </label>
            </div>
        )
    } else {
        return (
            <div>
                <label className="switch" htmlFor={id}>
                    <input type="checkbox" id={id} checked={checked} readOnly />
                    <div className="slider round" style={{cursor: "default"}}></div>
                </label>
            </div>
        )
    }
}

export default Slider;