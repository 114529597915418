import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import GoBackButton from '../../components/FormComponents/GoBackButton';
import { firestore } from '../../firebase/config';
import { useFirestore } from '../../context/FirestoreContext'
import { useSessionStorage } from '../../logic/useSessionStorage';
import { useForm } from 'react-hook-form';
import { createCellarRegistration } from '../../firebase/registrations';
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from 'react-router-dom';
import { doc, onSnapshot, serverTimestamp } from 'firebase/firestore';
import { use } from 'i18next';

const RegisterCellarStep = () => {
  const [cellarStepData, setCellarStepData] = useState(undefined);
  const [currentBatch, setCurrentBatch] = useState(undefined);
  const [currentVineyardUser, setCurrentVineyardUser] = useState(undefined);
  const [currentNavStep, setCurrentNavStep] = useState([]);
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const { currentUser } = useAuth();
  const [batch, setBatch] = useSessionStorage('batch', '');
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { currentUserDoc } = useFirestore();

  const [currentWinery, setCurrentWinery] = useSessionStorage(
    'currentwinery',
    ''
  );
  const [cellarRegData, setCellarRegData] = useSessionStorage('cellarReg');
  const params = useParams();
  const formClassname = 'ui big form twelve wide column';
  const { register, handleSubmit } = useForm();
  let currentLang = '';




  useEffect(() => {
    if (lang === 'en') {
      currentLang = 'nameEN';
    } else if (lang === 'fr') {
      currentLang = 'nameFR';
    } else if (lang === 'nl') {
      currentLang = 'nameNL';
    }

    const stepNavRef = doc(firestore, 'winegrowers', currentWinery, 'cellarSteps', params.stepId);
    const unsubscribeNavStep = onSnapshot(stepNavRef, (doc) => {
      if (doc.exists) {
        setCurrentNavStep(doc.data()[currentLang]);
      }
    });
    
    setCurrentVineyardUser(currentUserDoc);
    const cellarStepRef = doc(firestore, 'winegrowers', currentWinery, 'cellarSteps', params.stepId);
    const batchRef = doc(firestore, 'winegrowers', currentWinery, 'batches', cellarRegData.batchId);

    const unsubscribeCellar = onSnapshot(cellarStepRef, (doc) => {
      if (doc.exists) {
        setCellarStepData(doc.data());
      }
    });

    const unsubscribeBatch = onSnapshot(batchRef, (doc) => {
      if (doc.exists) {
        setCurrentBatch(doc.data());
      }
    })


    return () => {
      unsubscribeBatch();
      unsubscribeCellar();
      unsubscribeNavStep();
    }
  }, [cellarRegData.batchId, currentWinery, params.stepId]);

  const onSubmit = async (data) => {
    try {
      generateRegistration(data).then(async (newReg) => {
        await createCellarRegistration(currentWinery, newReg);
      });
      navigate("/cellarRegistration/selectStep")
    } catch (error) {
      console.error(error);
    }
  };

  const generateRegistration = async (data) => {
    let registration;
    for (const [key, value] of Object.entries(data)) {
      if (!isNaN(Number(value))) {
        data[key] = Number(value);
      }
    }
    registration = { ...data };
    registration.accuracy = 0;
    registration.agriculturalParcels = currentBatch.agriculturalParcels;
    registration.batchId = cellarRegData.batchId;
    registration.date = serverTimestamp();
    registration.email = currentUser.email
    registration.harvestYear = currentBatch.harvestYearId;
    registration.latitude = null
    registration.longitude = null
    registration.stepId = params.stepId
    registration.type = "cellarStep"
    registration.visualisation = true
    if (currentVineyardUser.wineries.some(winery => winery.winegrower === currentWinery && winery.trustedForRegistration)) {
      registration.waitingForReview = false;
    } else if (currentVineyardUser.wineries.some(winery => winery.winegrower === currentWinery && !winery.trustedForRegistration)) {
      registration.waitingForReview = true;
    }
    registration.wineStorages = currentBatch.wineStorages;
    return registration;
  };

  let form = [];
  let inForm = [];

  function generateForm() {
    let currentLang = '';
    if (cellarStepData.content !== undefined) {
      let options = {};
      for (const [key, value] of Object.entries(cellarStepData.content)) {
        if (value['type'] === 'option') {
          if (options.hasOwnProperty(value['key'])) {
            options[value['key']].push(value['textEN']);
          } else {
            options[value['key']] = [];
            options[value['key']].push(value['textEN']);
          }
        }
      }

      if (lang === 'en') {
        currentLang = 'textEN';
      } else if (lang === 'fr') {
        currentLang = 'textFR';
      } else if (lang === 'nl') {
        currentLang = 'textNL';
      }

      let requiredField;
      for (const [key, value] of Object.entries(cellarStepData.content)) {
        requiredField = value['validation'] === 'notEmpty';
        if (value['type'] === 'multilinetext' || value['type'] === 'text') {
          form.push(
            <div
              className="fields"
              style={{ justifyContent: 'center', marginTop: '20px' }}
            >
              {requiredField === true && (
                <div className="twelve wide field">
                  <label>
                    {value[currentLang]}
                    <input
                      type="text"
                      {...register(value['key'])}
                      htmlFor={value['key']}
                      style={{ textAlign: 'center' }}
                      required="required" />
                  </label>
                </div>
              )}
              {requiredField === false && (
                <div className="twelve wide field">
                  <label>
                    {value[currentLang]}
                    <input
                      type="text"
                      {...register(value['key'])}
                      htmlFor={value['key']}
                      style={{ textAlign: 'center' }} />
                  </label>
                </div>
              )}
            </div>
          );
          inForm.push(value['key']);
        } else if (value['type'] === 'label') {
          form.push(
            <div
              className="fields"
              style={{
                justifyContent: 'center',
                marginTop: '20px',
                marginBottom: '-20px ',
                fontWeight: 'bold',
              }}
            >
            </div>
          );
        } else if (value['type'] === 'decimal') {
          form.push(
            <div
              className="fields"
              style={{ justifyContent: 'center', marginTop: '20px' }}
            >
              <div className="twelve wide field">
                <label>
                  {value[currentLang]}
                  {requiredField === true && (
                    <input
                      type="number"
                      {...register(value['key'])}
                      htmlFor={value['key']}
                      style={{ textAlign: 'center' }}
                      required />
                  )}
                  {requiredField === false && (
                    <input
                      type="number"
                      {...register(value['key'])}
                      htmlFor={value['key']}
                      style={{ textAlign: 'center' }} />
                  )}
                </label>
              </div>
            </div>
          );
          inForm.push(value['key']);
        } else if (value['type'] === 'int') {
          form.push(
            <div
              className="fields"
              style={{ justifyContent: 'center', marginTop: '20px' }}
            >
              <div className="twelve wide field">
                <label>
                  {value[currentLang]}
                  {requiredField === true && (
                    <input
                      type="number"
                      {...register(value['key'])}
                      htmlFor={value['key']}
                      style={{ textAlign: 'center' }}
                      required />
                  )}
                  {requiredField === false && (
                    <input
                      type="number"
                      {...register(value['key'])}
                      htmlFor={value['key']}
                      style={{ textAlign: 'center' }} />
                  )}
                </label>
              </div>
            </div>
          );
          inForm.push(value['key']);
        }
      }
      return form;
    }
  }

  return (
    <div>
      <h1>{t("translation.cellarRegistration.registerCellarStep") + " " + currentNavStep + " " + batch}</h1>
      <GoBackButton />
      <br />
      <br />
      <div>
        <form
          className={formClassname}
          style={{
            border: '3px solid #B3B3B3',
            marginTop: '150px',
            margin: 'auto',
            borderRadius: '50px',
            background: '#edeaea',
            borderColor: '#ac9e66',
            maxWidth: '960px',
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          {cellarStepData !== undefined && (
            <div>
              {generateForm()}
              <button
                type="submit"
                className="ui submit large grey button right floated button-vineyards"
                style={{
                  float: 'none',
                  marginTop: '20px',
                  marginBottom: '20px',
                  width: '25%',
                }}
              >
                {t("translation.cellarRegistration.createCellarRegistration")}
              </button>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default RegisterCellarStep;