import { useTranslation } from 'react-i18next';
import {useEffect, useState} from "react";
import {firestore} from "../../firebase/config";
import {useSessionStorage} from "../../logic/useSessionStorage";
import { collection, doc, onSnapshot, query } from 'firebase/firestore';
import {useNavigate} from "react-router-dom";
import MaterialTable from '@material-table/core';

const AddWinestorage = () => {
    const [wineStorages, setWineStorages] = useState([]);
    const [selectedRows, setSelectedRows] = useState([])
    const [currentWinery, setCurrentWinery] = useSessionStorage(
        "currentwinery",
        ""
    );
    const [harvestData, setHarvestData] = useSessionStorage("harvestData", "");
    const [cellarRegistrationData, setCellarRegistrationData] = useSessionStorage("cellarReg", "");
    const navigate = useNavigate();
    const { t } = useTranslation();


    useEffect(() => {
        const wineStoragesRef = collection(firestore, "winegrowers", currentWinery, "wineStorages");
        
        const unsubscribe = onSnapshot(query(wineStoragesRef), (snapshot) => {
            const wineStoragesDb = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            for (let i = 0; i < wineStoragesDb.length; i++) {
                if (wineStoragesDb[i].endDate !== null) {
                    wineStoragesDb.splice(i, 1);
                }
            }
            setWineStorages(wineStoragesDb);
        })
        return () => unsubscribe();
    }, [])

    const handleRowSelectionChange = (rows) => {
        let arraySelectedRowsId = []
        for (let i = 0; i < rows.length; i++) {
            arraySelectedRowsId.push(rows[i].id);
        }

        setSelectedRows(arraySelectedRowsId);
    }

    const handleOnSubmit = (data) => {
        if (harvestData !== "") {
            let newHarvestData;
            newHarvestData = {...harvestData}
            let selectedWineStorages = [];
            for (let i = 0; i < wineStorages.length; i++) {
                if (selectedRows.includes(wineStorages[i].id)) {
                    let arrayData = {};
                    arrayData.id = wineStorages[i].id;
                    selectedWineStorages.push(arrayData);
                }
            }
            newHarvestData.wineStorages = selectedWineStorages;
            setHarvestData(newHarvestData);
            setTimeout(() => {
                navigate('/harvestRegistration/addLitersWineStorage');
            }, 500)
        } else {
            let newCellarData;
            newCellarData = {...cellarRegistrationData};
            let selectedWineStorages = [];
            for (let i = 0; i < wineStorages.length; i++) {
                if (selectedRows.includes(wineStorages[i].id)) {
                    let arrayData = {};
                    arrayData.id = wineStorages[i].id;
                    selectedWineStorages.push(arrayData);
                }
                newCellarData.wineStorages = selectedWineStorages;
                setCellarRegistrationData(newCellarData);
                setTimeout(() => {
                    navigate('/cellarRegistration/addLitersWineStorage')
                }, 500)
            }
        }


    }

    return (
        <div>
            <h3 style={{color: "#7f7f7f", marginTop: "30px"}}>
          {t('translation.harvestRegistration.selectWineStorage')}
        </h3>
            <div style={{maxWidth: "960px", margin: "auto"}}>
                <MaterialTable
                    title=""
                    data={wineStorages}
                    columns={[
                        {
                            title: t('translation.words.name'),
                            field: "name"
                        },
                        {
                            title: t('translation.barrels.totalVolume'),
                            field: "totalVolume"
                        },
                        {
                            title: t('vertaling.type_vat'),
                            field: "type"
                        }
                    ]}
                    onSelectionChange={(rows) => handleRowSelectionChange(rows)}
                    options={{
                        selection: true,
                        headerStyle: {
                            backgroundColor: "#cecaca",
                            color: "#FFF",
                            textAlign: "left",
                        },
                        filterCellStyle: {
                            alignContent: "left",
                        },
                        emptyRowsWhenPaging: false,
                        paging: true,
                        pageSize: 50,
                        pageSizeOptions: [
                            5,
                            10,
                            20,
                            25,
                            50,
                        ],
                    }}
                />
                <button
                    onClick={handleOnSubmit}
                    className="ui submit large grey button right floated  button-vinyards"
                    style={{float: "none", marginTop: "30px", width: "25%"}}
                >
                    {t("translation.words.next")}
                </button>
            </div>
        </div>
    );
}
export default AddWinestorage;