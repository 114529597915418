import 'firebase/auth';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import DashboardButton from '../../components/Dashboard/Button';
import DashboardButtonWithNumber from '../../components/Dashboard/ButtonWithNumber';
import { useFirestore } from '../../context/FirestoreContext';
import { collection, query, where, onSnapshot, getDocs, doc } from 'firebase/firestore';
import { PageTitle } from '../../utils/PageTitle';
import { getPermissionLevel, Roles } from '../../logic/Roles';
import { firestore } from '../../firebase/config';
import { useSessionStorage } from '../../logic/useSessionStorage';
import ConfigurationButtons from './ButtonLists/ConfigurationButtons';
import RegistrationButtons from './ButtonLists/RegistrationButtons';
import ReportingButtons from './ButtonLists/ReportingButtons';
import AdminButtons from './ButtonLists/AdminButtons';

const Dashboard = () => {
  PageTitle("Dashboard");
  const [currentWineryId, setCurrentWineryId] = useSessionStorage('currentwinery', '');
  const [wineriesForUser, setWineriesForUser] = useState([]);
  const [currentWinery, setCurrentWinery] = useState(undefined);
  const [amountOfWineriesToAccept, setAmountOfWineriesToAccept] = useState(0);
  const [amountOfEmplToApprove, setAmountOfEmplToApprove] = useState(0);
  const [amountOfRegsToApprove, setAmountOfRegsToApprove] = useState(0);

  const [permissionLevel, setPermissionLevel] = useState(0);
  const { currentUserDoc } = useFirestore();
  const { approvedWineries } = useFirestore();
  const { allUserDocs } = useFirestore();
  let [loading, setLoading] = useState(false);
  const { t } = useTranslation();


  //Permission level & number displays
  useEffect(() => {
    if (currentUserDoc !== undefined) {
      //Searching role permissions
      if (currentUserDoc.role === Roles().ADMIN.name) {
        setPermissionLevel(Roles().ADMIN.permissionLevel);
      } else {
        if (currentWineryId !== '' && currentWineryId !== undefined) {
          let winery = currentUserDoc.wineries.find((w) => w.winegrower === currentWineryId)
          setPermissionLevel(winery ? getPermissionLevel(winery.role) : 0);
        }
      }

      //number displays
      setAmountOfWineriesToAccept(currentUserDoc.wineries.filter((w) => w.accepted === false).length);

      if (allUserDocs !== undefined) {
        let employees = allUserDocs.filter(user =>
          user.role !== Roles().ADMIN.name &&
          user.wineries !== undefined &&
          user.wineries.some(winery => winery.winegrower === currentWineryId && winery.approved === false)
        )

        setAmountOfEmplToApprove(employees.length);
      }
    }
  }, [currentWineryId, currentUserDoc]);

  //getting all wineries the user has access to
  useEffect(() => {
    if (permissionLevel === Roles().ADMIN.permissionLevel) {
      setWineriesForUser(approvedWineries);
    } else if (currentUserDoc !== undefined) {
      const userWineryIds = currentUserDoc.wineries.filter((w) => w.accepted === true && w.approved === true).map(w => w.winegrower);
      const wineryList = approvedWineries.filter(w => userWineryIds.includes(w.id));

      setWineriesForUser(wineryList);
    }
  }, [permissionLevel, approvedWineries, currentUserDoc]);

  //getting the selected winery
  useEffect(() => {
    setCurrentWinery(wineriesForUser.find(w => w.id === currentWineryId));
  }, [wineriesForUser, currentWineryId]);

  const handleWineryChange = (selection) => {
    if (selection.value !== undefined) setCurrentWineryId(selection.value);
  };

  useEffect(() => {
    try{
    if (permissionLevel === Roles().WINEGROWER.permissionLevel || (permissionLevel === Roles().ADMIN.permissionLevel && currentWineryId !== '' && currentWineryId !== undefined)) {
      const registrationsRef = collection(firestore, 'winegrowers', currentWineryId, 'registrations');
      const vineyardRegistrationsQuery = query(registrationsRef, where('waitingForReview', '==', true));
  
      getDocs(vineyardRegistrationsQuery).then(snapshot => {
        const regsToApprove = snapshot.docs.length;
        setAmountOfRegsToApprove(regsToApprove);
      }).catch(error => {
        console.error("Error fetching registration documents:", error);
      });
    }
  }catch(error){
    console.error("Error fetching registration documents:", error);
  }
  }, [permissionLevel, currentWinery]);
  
  return (
    <section>
      <div className="container" style={{ marginTop: '40px' }}>
        <div style={{ display: 'flex' }}>
        <h1 className="dbTitle">{t('vertaling.title_dashboard')}</h1>
        </div>

        {/* top row of buttons */}
        <div className="row db-row dashboardButtons" style={{ marginTop: '40px' }}>
          <DashboardButton
            url={"/profile"}
            imgAlt={"dashboard-icon"}
            imgSrc={"/images/DashboardIcons/Profile.svg"}
            title={t("vertaling.h3_dashboard")}
            text={t("vertaling.p3_dashboard")} />

          {permissionLevel === Roles().ADMIN.permissionLevel ? (
            <>
              <DashboardButton
                url={"winegrowers/add"}
                imgAlt={"dashboard-icon"}
                imgSrc={"/images/DashboardIcons/AddCellars.svg"}
                title={t("vertaling.title_registratie")}
                text={t("vertaling.add_winegrower")} />

              <DashboardButton
                url={"/winegrowers"}
                imgAlt={"dashboard-icon"}
                imgSrc={"/images/DashboardIcons/Users.svg"}
                title={t("vertaling.h2_dashboard")}
                text={t("vertaling.p2_dashboard")} />

              <DashboardButton
                url={"/chemicalsImporter"}
                imgAlt={"chemicals-icon"}
                imgSrc={"/images/DashboardIcons/Chemicals.svg"}
                title={t("vertaling.h15_dashboard")}
                text={t("vertaling.p15_dashboard")} />
            </>
          ) : (
            <>
              <DashboardButton
                url={"/MyWineries"}
                imgAlt={"dashboard-icon"}
                imgSrc={"/images/DashboardIcons/Vineyards.svg"}
                title={t("vertaling.h13_dashboard")}
                text={t("vertaling.p13_dashboard")} />

              <DashboardButtonWithNumber
                url={"/invitations"}
                imgAlt={"dashboard-icon"}
                imgSrc={"/images/DashboardIcons/Approve.svg"}
                title={t("vertaling.h14_dashboard")}
                text={t("vertaling.p14_dashboard")}
                number={amountOfWineriesToAccept} />

            </>
          )}
        </div>
        {wineriesForUser.length !== 0 && (
        <div className="selectDashboard">
        <p style={{ color: "#7f7f7f" }}>{t('vertaling.selectWineryPrompt')}</p>
            <Select placeholder={t('vertaling.selectWinery')} className="specialty"
              options={wineriesForUser?.map(({ name, id, approved }) => (
                {
                  value: id,
                  label: name,
                  // isDisabled: approved,
                }
              ))}
              onChange={(selection, action) => handleWineryChange(selection, action)}
              value={currentWineryId ? { value: currentWineryId, label: currentWinery?.name } : null}
              theme={(theme) => ({
                ...theme,
                borderRadius: 4,
                colors: {
                  ...theme.colors,
                  primary25: '#dacfa4b2',
                  primary: '#AC9E66',
                },
              })}
            />
          </div>
        )}

        {/* bottom row of buttons for admins & winegrowers*/}
        {currentWinery !== undefined && (
          <>
            <h1 className='dbTitle' style={{ margin: "50px 0 0 0" }}>{currentWinery.name}</h1>

            <div className="row db-row">
              <AdminButtons userPermissionLevel={permissionLevel} />

              <RegistrationButtons
                userPermissionLevel={permissionLevel}
                amountOfRegsToApprove={amountOfRegsToApprove} />

              <ReportingButtons userPermissionLevel={permissionLevel} />

              <ConfigurationButtons
                userPermissionLevel={permissionLevel}
                amountOfEmplToApprove={amountOfEmplToApprove} />
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default Dashboard;