import { getFirestore } from "firebase/firestore";
import { collection, doc, addDoc, deleteDoc, updateDoc, setDoc, Timestamp } from "firebase/firestore";

const firestore = getFirestore();

export const createBatchDocument = async (winegrower, batchData) => {
  const colRef = collection(firestore, `winegrowers/${winegrower}/batches`);
  return await addDoc(colRef, batchData);
};

export const deleteBatch = async (winegrower, batchId) => {
  const docRef = doc(firestore, `winegrowers/${winegrower}/batches/${batchId}`);
  return await deleteDoc(docRef);
};

export const createBatchFromDrying = async (winegrowerId, batch) => {
  const colRef = collection(firestore, `winegrowers/${winegrowerId}/batches`);
  return await addDoc(colRef, batch);
};

export const updateBatchDocument = async (data, winery, batchId) => {
  data.createDate = Timestamp.fromDate(new Date(data.createDate));
  const docRef = doc(firestore, `winegrowers/${winery}/batches/${batchId}`);
  return await updateDoc(docRef, data);
};

export const updateBatch = async (winegrower, batchId, batch) => {
  const docRef = doc(firestore, `winegrowers/${winegrower}/batches/${batchId}`);
  await setDoc(docRef, batch);
};
