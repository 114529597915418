export const Roles = () => {
    return {
        ADMIN: {
            name: "admin",
            permissionLevel: 3,
        },
        WINEGROWER: {
            name: "winegrower",
            permissionLevel: 2,
        },
        EMPLOYEE: {
            name: "employee",
            permissionLevel: 1,
        },
    }
}

export const getPermissionLevel = (roleName) => {
    if(roleName === undefined || roleName === null || roleName === "") return 0
    
    let roles = Object.values(Roles())
    let filteredArray = roles.filter(role => role.name === roleName.toLowerCase())

    if(filteredArray.length === 0) {
        return 0
    } else {
        return filteredArray[0].permissionLevel;
    }
}