const Checkbox = ({label, value, id, onChange}) => {
    return (
        <div className="align-items-center">
            <div className="form-check">
                <input className='form-check-input' type="checkbox" checked={value}
                       onChange={onChange} id={id}/>
                <label className='form-check-label'>
                    {label}
                </label>
            </div>
        </div>
    );
};

export default Checkbox