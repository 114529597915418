import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Slider from '../../components/Slider';
import { useFirestore } from '../../context/FirestoreContext';
import { updateUserDocument } from '../../firebase/user';
import { PageTitle } from '../../utils/PageTitle';
import { Roles } from '../../logic/Roles';
import { useSessionStorage } from '../../logic/useSessionStorage';
import GoBackButton from "../../components/FormComponents/GoBackButton";
import MaterialTable from '@material-table/core';

const Employees = () => {
    PageTitle("Employees");
    const navigate = useNavigate();
    const { allUserDocs } = useFirestore();
    const [employees, setEmployees] = useState([]);
    const [currentWinery, setCurrentWinery] = useSessionStorage('currentwinery', '');
    // const [trustedForRegistrationsChecked, setTrustedForRegistrationsChecked] = useState(false);
    const { t } = useTranslation();

    // Referenties naar firestore database (bv : ophalen van collecties uit firestore db)
    // Alle waarden in de variabelen gaan verloren éénmaal de useEffect volledig uitgevoerd is

    useEffect(() => {
        if (allUserDocs !== undefined) {
            let tempList = [];
            allUserDocs.forEach((u) => {
                // if (u.role !== Roles().ADMIN.name) {
                    const winery = u.wineries?.find((w) => w.winegrower === currentWinery);
                    if (winery && winery.approved) {
                        tempList.push({
                            id: u.id,
                            firstname: u.firstname,
                            lastname: u.lastname,
                            email: u.email,
                            accepted: winery.accepted,
                            wineries: [winery],
                        });
                    }
                // }
            });

            setEmployees(tempList);
        }
    }, [allUserDocs]);


    // Het weergeven van alle gegevens van alle werknemers in een tabel

    return (
        <div style={{ marginTop: '90px' }}>
            <h1>{t("vertaling.h5_dashboard")}</h1>
            <GoBackButton location={"/"} />
            <br />
            <div className="newbatch">
                <Link to="/employees/add" style={{ color: 'white' }}>
                    <button
                        className="ui primary button login"
                        style={{ marginTop: '65px' }}
                    >
                        {t("vertaling.toevoegen_werknemer")}
                    </button>
                </Link>
            </div>
            <div className="batchesList">
                <MaterialTable
                    title=""
                    data={employees}
                    columns={[
                        {
                            title: t('vertaling.firstname'),
                            field: 'firstname',
                        },
                        {
                            title: t('vertaling.lastname'),
                            field: 'lastname',
                        },
                        {
                            title: t('vertaling.email'),
                            field: 'email',
                        },
                        // {
                        //     title: t('vertaling.trusted_regis'),
                        //     render: (rowData) => {
                        //         return (
                        //             <Slider
                        //                 id={'checkboxTrustedForRegistration_' + employees.findIndex((row) => row.id === rowData.id)}
                        //                 checked={rowData.wineries[0].trustedForRegistration}
                        //                 onChange={(data) => {
                        //                     const userProfile = {
                        //                         wineries: rowData.wineries,
                        //                     };

                        //                     userProfile.wineries[0].trustedForRegistration = data.target.checked;
                        //                     updateUserDocument(rowData.id, userProfile);
                        //                 }} />
                        //         );
                        //     },
                        // },
                        {
                            title: t('translation.invitationAccepted'),
                            render: (rowData) => {
                                return (
                                    <Slider
                                        id={'checkboxInvitationAccepted_' + employees.findIndex((row) => row.id === rowData.id)}
                                        checked={rowData.wineries[0].accepted} />
                                );
                            },
                        },
                        {
                            render: (rowData) => {
                                return (
                                    <button
                                        onClick={() => {
                                            navigate(`/employees/deactivate/${rowData.email}`);
                                        }}
                                        color="primary"
                                        className="ui primary button login"
                                        style={{ textTransform: 'none' }}
                                    >
                                        {t("vertaling.deactivate")}
                                    </button>
                                );
                            },
                        },
                    ]}
                    options={{
                        search: true,
                        filtering: true,
                        exportButton: true,
                        exportAllData: true,
                        headerStyle: {
                            backgroundColor: '#cecaca',
                            color: '#FFF',
                        },
                        emptyRowsWhenPaging: false,
                        paging: true,
                        pageSize: 50,
                        pageSizeOptions: [
                            10,
                            20,
                            25,
                            50,
                        ],
                    }}
                    // Dit wordt gebruikt om alles te vertalen in de tabel die hierboven wordt gedefineerd
                    localization={{
                        body: {
                            emptyDataSourceMessage: t('vertaling.emptyDataSourceMessage'),
                            addTooltip: t('vertaling.addTooltip'),
                            deleteTooltip: t('vertaling.deleteTooltip'),
                            editTooltip: t('vertaling.editTooltip'),
                            filterRow: {
                                filterTooltip: t('vertaling.filterTooltip'),
                            },
                            editRow: {
                                deleteText: t('vertaling.deleteText'),
                                cancelTooltip: t('vertaling.cancelTooltip'),
                                saveTooltip: t('vertaling.submit '),
                            },
                        },
                        grouping: {
                            placeholder: t('vertaling.placeholder'),
                            groupedBy: t('vertaling.groupedBy'),
                        },
                        header: {
                            actions: t('vertaling.actions'),
                        },
                        pagination: {
                            labelDisplayedRows: t('vertaling.labelDisplayedRows'),
                            labelRowsPerPage: t('vertaling.labelRowsPerPage'),
                            firstAriaLabel: t('vertaling.firstAriaLabel'),
                            firstTooltip: t('vertaling.firstAriaLabel'),
                            previousAriaLabel: t('vertaling.previousAriaLabel'),
                            previousTooltip: t('vertaling.previousAriaLabel'),
                            nextAriaLabel: t('vertaling.nextAriaLabel'),
                            nextTooltip: t('vertaling.nextAriaLabel'),
                            lastAriaLabel: t('vertaling.lastAriaLabel'),
                            lastTooltip: t('vertaling.lastAriaLabel'),
                        },
                        toolbar: {
                            addRemoveColumns: t('vertaling.addRemoveColumns'),
                            nRowsSelected: t('vertaling.nRowsSelected'),
                            showColumnsTitle: t('vertaling.showColumnsTitle'),
                            showColumnsAriaLabel: t('vertaling.showColumnsTitle'),
                            exportTitle: t('vertaling.exportTitle'),
                            exportAriaLabel: t('vertaling.exportTitle'),
                            exportName: t('vertaling.exportName'),
                            searchTooltip: t('vertaling.searchTooltip'),
                            searchPlaceholder: t('vertaling.searchTooltip'),
                            exportCSVName: t('vertaling.exportName'),
                            exportPDFName: t('vertaling.exportPDFName'),
                        },
                    }}
                />
            </div>
        </div>
    );
};

export default Employees;