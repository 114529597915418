import { PageTitle } from "../../utils/PageTitle";
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from "../../logic/useSessionStorage";
import { useEffect, useState } from "react";
import { firestore } from "../../firebase/config";
import GoBackButton from "../../components/FormComponents/GoBackButton";
import { Link, useNavigate } from "react-router-dom";
import { removeCellarStep } from "../../firebase/cellarSteps";
import { onSnapshot, collection, doc, query } from "firebase/firestore";
import MaterialTable from '@material-table/core';

const MyCellarSteps = () => {
    PageTitle('Cellar Steps');
    const [currentWinery, setCurrentWinery] = useSessionStorage('currentwinery', '');
    const [cellarSteps, setCellarSteps] = useState([]);
    const { i18n } = useTranslation();
    const lang = i18n.language;
    const navigate = useNavigate();
    const { t } = useTranslation();
    let currentLang ='';

    useEffect(() => {
        const cellarStepRef = collection(firestore, 'winegrowers', currentWinery, 'cellarSteps');

        const unsubscribe = onSnapshot(cellarStepRef, async (querySnapshot) => {
            const cellarStepDoc = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data()
            }));
            cellarStepDoc.sort((a, b) => (a.number > b.number ? 1 : -1));
            setCellarSteps(cellarStepDoc);
        })
        return () => {
            unsubscribe();
        }
    }, [currentWinery, lang])

    if (lang === 'en') {
        currentLang = "nameEN";
     } else if (lang === 'fr') {
         currentLang = "nameFR";
     } else if (lang === 'it') {
         currentLang = "nameIT";
     } else if (lang === 'nl') {
         currentLang = "nameNL";
     }

    function editCellarStep(cellarStepId) {
        navigate(`/cellarsteps/cellarstepdetail/${cellarStepId}`);
    }

    async function deleteCellarStep(cellarStepId) {
        try {
            await removeCellarStep(currentWinery, cellarStepId);
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <>
            <div style={{ position: "relative", marginTop: "90px" }}>
            <h1 style={{ color: "#7f7f7f" }}>{t('translation.cellarSteps.myCellarSteps')}</h1>
            </div>
            <GoBackButton />
            <br />
            <div>
                <div className='newvineyard'>
                    <Link to="/cellarsteps/newcellarstep" style={{ color: "white" }}>
                        <button
                            className="ui primary button login"
                            style={{ marginTop: "65px" }}>
                            {t('translation.cellarSteps.newCellarStep')}
                        </button>
                    </Link>
                </div>
                <br />
                <div className="batchesList">

                    <MaterialTable
                        title=""
                        data={cellarSteps}
                        columns={[{
                            title: t('translation.vineyardSteps.number'),
                            field: "number",
                            sorting: true
                        }, {
                            title: t('translation.vineyardSteps.stepName'), field: currentLang,
                        }, {
                            title: "", field: "", cellStyle: {
                                cellWidth: "6%",
                            }, sorting: false, render: (rowData) => (<>
                                <button
                                    className="ui primary button login"
                                    onClick={() => {
                                        deleteCellarStep(rowData.id);
                                    }}
                                >
                                    {t("translation.words.delete")}
                                </button>
                                <button
                                    className="ui primary button login"
                                    onClick={() => {
                                        editCellarStep(rowData.id);
                                    }}
                                >
                                    {t("translation.words.edit")}
                                </button>
                            </>),
                        },

                        ].map((c) => ({
                            render: (row) => (<Link to={`/cellarsteps/cellarstepslist/${row.id}`}>
                                {c.lookup ? c.lookup[row[c.field]] : row[c.field]}
                            </Link>), ...c,
                        }))}
                        options={{
                            tableLayout: "auto",
                            filtering: true,
                            exportButton: true,
                            exportAllData: true,
                            headerStyle: {
                                backgroundColor: "#cecaca", color: "#FFF",
                            },

                            emptyRowsWhenPaging: false,
                            paging: true,
                            pageSize: 50,
                            pageSizeOptions: [10, 20, 25, 50,],
                        }}
                        components={{
                            Action: (props) => (<div className="flex_grape">
                                <button
                                    className="ui primary button login"
                                    onClick={(event) => props.action.onClick(event, props.data)}
                                    color="primary"
                                    style={{ textTransform: "none" }}
                                >
                                    {t("vertaling.details")}
                                </button>
                                <button
                                    className="ui primary button login "
                                    onClick={(event) => props.action.create(event, props.data)}
                                    color="primary"
                                    style={{ textTransform: "none", minWidth: 175 }}
                                >
                                    {t("vertaling.grapevariety_btn")}
                                </button>
                            </div>)
                        }}
                    />
                </div>
            </div>
        </>

    )
};

export default MyCellarSteps;