import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { doc, onSnapshot } from 'firebase/firestore';
import { firestore } from '../../firebase/config';
import { updateWinegrowerProfileDocument } from '../../firebase/winegrower';
import { PageTitle } from '../../utils/PageTitle';
import { useSessionStorage } from '../../logic/useSessionStorage';
import GoBackButton from "../../components/FormComponents/GoBackButton";

const WinegrowerProfile = () => {
    PageTitle("Winegrower Profile");
    const [isLoading, setLoading] = useState(true);
    const [currentWinery, setCurrentWinery] = useSessionStorage('currentwinery', '');
    const [winegrowerData, setWinegrowerData] = useState([]);
    const { register, handleSubmit, setValue } = useForm({
        defaultValues: {
          winegrowername: winegrowerData.name,
          phone: winegrowerData.phone,
          adress: winegrowerData.adress,
          city: winegrowerData.city,
          zip: winegrowerData.zip,
          country: winegrowerData.country,
          btw: winegrowerData.btw,
          excise: winegrowerData.excise,
          website: winegrowerData.website
        }
      });
    const navigate = useNavigate();
    const { t } = useTranslation();

    // Referenties naar firestore database (bv : ophalen van collecties uit firestore db)
    // Alle waarden in de variabelen gaan verloren éénmaal de useEffect volledig uitgevoerd is

    useEffect(() => {
        if (winegrowerData) {
          setValue('winegrowername', winegrowerData.name);
          setValue('phone', winegrowerData.phone);
          setValue('adress', winegrowerData.adress);
          setValue('city', winegrowerData.city);
          setValue('zip', winegrowerData.zip);
          setValue('country', winegrowerData.country);
          setValue('btw', winegrowerData.btw);
          setValue('excise', winegrowerData.excise);
          setValue('website', winegrowerData.website);
        }
      }, [winegrowerData, setValue]);

    useEffect(() => {
        const winegrowersRef = doc(firestore, 'winegrowers', currentWinery);

        var growerData = '';

        const unsubscribe = onSnapshot(winegrowersRef, (doc) => {
            if (doc.exists) {
                growerData = doc.data();
                setWinegrowerData(growerData);

                setLoading(false);
            }
        });
        return () => {
            unsubscribe();
        };
    }, [currentWinery]);

    //submitting form
    const onWinegrowerSubmit = async (data) => {
        try {
            setLoading(true);
            const winegrowerProfile = {
                ...winegrowerData,
                active: true,
                adress: data.adress,
                approved: true,
                btw: data.btw,
                city: data.city,
                country: data.country,
                excise: data.excise,
                name: data.winegrowername,
                phone: data.phone,
                website: data.website,
                zip: Number(data.zip),
            };
            await updateWinegrowerProfileDocument(currentWinery, winegrowerProfile);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
            navigate(-1);
        }
    };

    const formClassname = `ui big form twelve wide column ${isLoading ? 'loading' : ''
        }`;

    // Weergeven van alle gegevens van een wijnhuisprofiel

    return (
        <div
            className="add-form-container"
            style={{
                maxWidth: 960,
                margin: '50px auto',
                marginTop: '30px',
            }}
        >
            <h2 style={{ paddingBottom: '20px' }}>
          {t('vertaling.h4_dashboard')}
        </h2>
            <br />

            <GoBackButton />
            <form
                className={formClassname}
                style={{
                    border: '3px solid #B3B3B3',
                    marginTop: '50px',
                    borderRadius: '50px',
                    borderColor: '#ac9e66',
                }}
                onSubmit={handleSubmit(onWinegrowerSubmit)}
            >
                <div
                    className="fields"
                    style={{ justifyContent: 'center', marginTop: '30px' }}
                >
                    <div className="six wide field">
                        <label>
                            {t("vertaling.winery")}
                            <input
                                type="text"
                                {...register('winegrowername')}
                                htmlFor="winegrowername"
                                required="required"
                                defaultValue={winegrowerData.name} />
                        </label>
                    </div>

                    <div className="six wide field">
                        <label>
                            {t("vertaling.companyTel")}
                            <input
                                type="tel"
                                {...register('phone')}
                                htmlFor="phone"
                                defaultValue={winegrowerData.phone}
                                required="required" />
                        </label>
                    </div>
                </div>

                <div className="fields" style={{ justifyContent: 'center' }}>
                    <div className="six wide field">
                        <label>
                            {t("vertaling.companyAdress")}
                            <input
                                type="text"
                                {...register('adress')}
                                htmlFor="adress"
                                defaultValue={winegrowerData.adress}
                                required="required" />
                        </label>
                    </div>

                    <div className="six wide field">
                        <label>
                            {t("vertaling.city")}
                            <input
                                type="text"
                                {...register('city')}
                                htmlFor="city"
                                defaultValue={winegrowerData.city}
                                required="required" />
                        </label>
                    </div>
                </div>

                <div className="fields" style={{ justifyContent: 'center' }}>
                    <div className="six wide field">
                        <label>
                            {t("vertaling.companyZip")}
                            <input
                                type="number"
                                {...register('zip')}
                                htmlFor="zip"
                                defaultValue={winegrowerData.zip}
                                required="required" />
                        </label>
                    </div>

                    <div className="six wide field">
                        <label>
                            {t("vertaling.companyCountry")}
                            <input
                                type="text"
                                {...register('country')}
                                htmlFor="country"
                                defaultValue={winegrowerData.country}
                                required="required" />
                        </label>
                    </div>
                </div>

                <div className="fields" style={{ justifyContent: 'center' }}>
                    <div className="six wide field">
                        <label>
                            {t("vertaling.companyVat")}
                            <input
                                type="text"
                                {...register('btw')}
                                htmlFor="btw"
                                required="required"
                                defaultValue={winegrowerData.btw} />
                        </label>
                    </div>

                    <div className="six wide field">
                        <label>
                            {t("vertaling.companyExcise")}
                            <input
                                type="text"
                                {...register('excise')}
                                htmlFor="excise"
                                required="required"
                                defaultValue={winegrowerData.excise} />
                        </label>
                    </div>
                </div>

                <div className="fields" style={{ justifyContent: 'center' }}>
                    <div className="twelve wide field">
                        <label>
                            {t("vertaling.companyWebsite")}
                            <input
                                type="text"
                                {...register('website')}
                                htmlFor="website"
                                defaultValue={winegrowerData.website}
                                required="required" />
                        </label>
                    </div>
                </div>

                <div style={{ justifyContent: 'center', paddingBottom: '20px' }}>
                    <button
                        type="submit"
                        className="ui submit large grey button right floated bttn-profile"
                        style={{ float: 'none', marginTop: '10px', width: '25%' }}
                    >
                        {t("vertaling.update_wijnhuis")}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default WinegrowerProfile;