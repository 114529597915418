import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import GoBackButton from '../../components/FormComponents/GoBackButton';
import { firestore } from '../../firebase/config';
import { useSessionStorage } from '../../logic/useSessionStorage';
import { collection, query, where, onSnapshot } from "firebase/firestore";
import MaterialTable from '@material-table/core';

const BarrelReport = () => {
  const [currentWinery] = useSessionStorage('currentwinery', '');
  const [cellarRegistrations, setCellarRegistrations] = useState(undefined);
  const [wineStorages, setWineStorages] = useState(undefined);
  const [reports, setReports] = useState(undefined);
  const { t } = useTranslation();

  useEffect(() => {
    const registrationsQuery = query(collection(firestore, "winegrowers", currentWinery, "registrations"), where("type", "==", "cellarStep"));
    const wineStoragesRef = collection(firestore, "winegrowers", currentWinery, "wineStorages");
  
    const unsubscribeRegs = onSnapshot(registrationsQuery, (snapshot) => {
      const cellarRegistrationData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCellarRegistrations(cellarRegistrationData);
    });
  
    const unsubscribeStorages = onSnapshot(wineStoragesRef, (snapshot) => {
      const wineStorageData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setWineStorages(wineStorageData);
    });
    return () => {
      unsubscribeRegs();
      unsubscribeStorages();
    };
  }, [currentWinery]);

  useEffect(() => {
    if (wineStorages !== undefined && cellarRegistrations !== undefined) {
      buildReportingObject(cellarRegistrations, wineStorages);
    }
  }, []);

  const buildReportingObject = (cellarRegistrations, wineStorages) => {
    let reportsCollection = [];
    wineStorages.forEach((wineStorage) => {
      let report = {
        wineStorage: wineStorage.name,
        wineStorageId: wineStorage.id,
      };
      reportsCollection.push(report);
    });

    reportsCollection.forEach((report) => {
      cellarRegistrations.forEach((reg) => {
        if (reg.wineStorages !== undefined) {
          var totalAmount = 0;
          reg.wineStorages.forEach((wineStorage) => {
            if (totalAmount === 0) {
              totalAmount = wineStorage.litersForReport;
            } else {
              totalAmount = totalAmount + wineStorage.litersForReport;
            }
            report.totalAmount = totalAmount;
            if (wineStorage.id === report.wineStorageId) {
              if (report.amount === undefined) {
                report.amount = wineStorage.litersForReport;
              } else {
                report.amount =
                  Number(report.amount) + Number(wineStorage.litersForReport);
              }
            }

            if (reg.agriculturalParcels !== undefined) {
              var grapeVarieties = [];
              reg.agriculturalParcels.forEach((agriculturalParcel) => {
                var grapeVariety = {};
                grapeVariety.name = agriculturalParcel.id;
                grapeVariety.amount = (report.totalAmount / 100) * agriculturalParcel.percentage;
                grapeVarieties.push(grapeVariety);
              })
              if (report.agriculturalParcels !== undefined) {
                report.agriculturalParcels.forEach((reportParcel) => {
                  grapeVarieties.forEach((grapeVariety) => {
                    if (reportParcel.id === grapeVariety.id) {
                      reportParcel.amount += grapeVariety.amount;
                    }
                  })
                })
              } else {
                console.log("report.agriculturalParcels is undefined")
                report.agriculturalParcels = grapeVarieties;
              }
            }
          });
        }
      });
    });
    setReports(reportsCollection);
  };

  return (
    <div>
      <h1>{t("translation.barrelReport.title")}</h1>
      <h2>{t("vertaling.moduleMaintenance")}</h2>
      <br />
      <br />
      <GoBackButton />
      <div className="batchesList">
        {reports !== undefined && (
          <MaterialTable
            data={reports}
            columns={[
              { title: 'Naam van wijnvat', field: 'wineStorage' },
              { title: 'Aantal liter (l)', field: 'amount' },
            ]}
            title=""
          />
        )}
      </div>
    </div>
  );
};
export default BarrelReport;