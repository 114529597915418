import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { firestore } from '../../firebase/config';
import { Link, useNavigate } from 'react-router-dom';
import { useSessionStorage } from '../../logic/useSessionStorage';
import { PageTitle } from '../../utils/PageTitle';
import { doc, collection, onSnapshot, query } from 'firebase/firestore';
import MaterialTable from '@material-table/core';
import GoBackButton from '../../components/FormComponents/GoBackButton';


import { timestampToDate } from '../../utils/DateConversions';

const BatchesV2 = () => {
  PageTitle('Batches');
  const navigate = useNavigate();
  const [batches, setBatches] = useState([]);
  const [batchesData, setBatchesData] = useState([]);
  const [wineStorages, setWineStorages] = useState([]);
  const [ agriculturalParcels, setAgriculturalParcels ] = useState([])
  const [ loading, setLoading ] = useState(true);
  const [currentWinery, setCurrentWinery] = useSessionStorage('currentwinery', '');
  const { t } = useTranslation();

  useEffect(() => {
    // Referenties naar firestore database (bv : ophalen van collecties uit firestore db)
    // Alle waarden in de variabelen gaan verloren éénmaal de useEffect volledig uitgevoerd is

    const batchesRef = collection(firestore, 'winegrowers', currentWinery, 'batches');
    const wineStoragesRef = collection(firestore, 'winegrowers', currentWinery, 'wineStorages');
    const agriculturalParcelRef = collection(firestore, 'winegrowers', currentWinery, 'agriculturalParcels');

    const unsubscribeBatches = onSnapshot(query(batchesRef), (querySnapshot) => {
      const batches = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBatchesData(batches);
    });

    const unsubscribeWineStorages = onSnapshot(query(wineStoragesRef), (querySnapshot) => {
      const storages = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setWineStorages(storages);
    });

    const unsubscribeAgriculturalParcels = onSnapshot(query(agriculturalParcelRef), (querySnapshot) => {
      const parcels = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAgriculturalParcels(parcels);
      setLoading(false);
    });

    if(loading === false){
      for (let i = 0; i < batchesData.length; i++) {
        batchesData[i].createDate = timestampToDate(batchesData[i].createDate);
        addNameToWineStorage(i);
        addNameToAgriculturalParcel(i);
      }

      batchesData.forEach((batch) => {
        let wineStoragesString = '';
        let agriculturalParcelString = '';
        if (batch.wineStorages !== undefined) {
          batch.wineStorages.forEach((storage) => {
            if (storage.name !== undefined) {
              wineStoragesString += `${storage.name}: ${storage.litersForReport}L, `;
            }
          });
          batch.wineStoragesString = wineStoragesString;
        }
        if(batch.agriculturalParcels !== undefined){
          batch.agriculturalParcels.forEach((parcel) => {
            if(parcel.name !== undefined){
              let roundedPercentage = Math.round(parcel.percentage)
              agriculturalParcelString += `${parcel.name}: ${roundedPercentage}%, `;
            }
          })
          batch.agriculturalParcelString = agriculturalParcelString;
        }
      });
      setBatches(batchesData);
    }


    function addNameToAgriculturalParcel(i) {
      if (batchesData[i].agriculturalParcels !== undefined) {
        batchesData[i].agriculturalParcels.forEach((parcel) => {
          parcel.name = agriculturalParcels.find((ap) => ap.id === parcel.id).name;
        });
      }
    }

    function addNameToWineStorage(i) {
      if (batchesData[i].wineStorages !== undefined) {
        batchesData[i].wineStorages.forEach((storage) => {
          storage.name = wineStorages.find((ws) => ws.id === storage.id).name;
        });
      }
    }

    return () => {
      unsubscribeBatches();
      unsubscribeWineStorages();
      unsubscribeAgriculturalParcels();
    };
  }, [currentWinery, loading]);

  return (
    // Het weergeven van alle gegevens van alle batches in een tabel
    <div style={{ position: 'relative' }}>
      <h1 style={{ marginTop: '30px', color: '#bcbcbc' }}>
          {t('vertaling.title_batches')}
        </h1>
<div>
      <GoBackButton />
      </div>
      <div className="newbatch">
      </div>
        <div className="batchesList">
          <MaterialTable
            title={<Link to="/createbatch" style={{ color: 'white' }}>
            <button
              className="ui primary button login"
            >
              {t("vertaling.add_batches")}
            </button>
          </Link>}
            data={batches}
            columns={[
              {
                title: t('vertaling.add_batch'),
                field: 'name',
              },
              {
                title: t('vertaling.colCreatieDatum'),
                field: 'createDate',
                sorting: true,
                render: rowData => timestampToDate(rowData.createDate),
              },
              {
                title: t('vertaling.colCreatedBy'),
                field: 'email',
              },
              {
                title: t('vertaling.colHistoric'),
                field: 'historic',
              },
              {
                title: t('translation.createBatch.wineStorages'),
                field: 'wineStoragesString',
              },
              {
                title: t('translation.createBatch.grapeVariety'),
                field: 'agriculturalParcelString',
              }
            ].map((c) => ({
              render: (row) => (
                <Link to={`/batchedit/${row.id}`}>
                  {c.lookup ? c.lookup[row[c.field]] : row[c.field]}
                </Link>
              ),
              ...c,
            }))}
            options={{
              search: true,
              filtering: true,
              exportButton: true,
              exportAllData: true,
              headerStyle: {
                backgroundColor: '#cecaca',
                color: '#FFF',
              },
              emptyRowsWhenPaging: false,
              paging: true,
              pageSize: 50,
              pageSizeOptions: [
                10,
                20,
                25,
                50,
              ],
            }}
            // Dit wordt gebruikt om alles te vertalen in de tabel die hierboven wordt gedefineerd
            localization={{
              body: {
                emptyDataSourceMessage: t('vertaling.emptyDataSourceMessage'),
                addTooltip: t('vertaling.addTooltip'),
                deleteTooltip: t('vertaling.deleteTooltip'),
                editTooltip: t('vertaling.editTooltip'),
                filterRow: {
                  filterTooltip: t('vertaling.filterTooltip'),
                },
                editRow: {
                  deleteText: t('vertaling.deleteText'),
                  cancelTooltip: t('vertaling.cancelTooltip'),
                  saveTooltip: t('vertaling.submit '),
                },
              },
              grouping: {
                placeholder: t('vertaling.placeholder'),
                groupedBy: t('vertaling.groupedBy'),
              },
              header: {
                actions: t('vertaling.actions'),
              },
              pagination: {
                labelDisplayedRows: t('vertaling.labelDisplayedRows'),
                labelRowsPerPage: t('vertaling.labelRowsPerPage'),
                firstAriaLabel: t('vertaling.firstAriaLabel'),
                firstTooltip: t('vertaling.firstAriaLabel'),
                previousAriaLabel: t('vertaling.previousAriaLabel'),
                previousTooltip: t('vertaling.previousAriaLabel'),
                nextAriaLabel: t('vertaling.nextAriaLabel'),
                nextTooltip: t('vertaling.nextAriaLabel'),
                lastAriaLabel: t('vertaling.lastAriaLabel'),
                lastTooltip: t('vertaling.lastAriaLabel'),
              },
              toolbar: {
                addRemoveColumns: t('vertaling.addRemoveColumns'),
                nRowsSelected: t('vertaling.nRowsSelected'),
                showColumnsTitle: t('vertaling.showColumnsTitle'),
                showColumnsAriaLabel: t('vertaling.showColumnsTitle'),
                exportTitle: t('vertaling.exportTitle'),
                exportAriaLabel: t('vertaling.exportTitle'),
                exportName: t('vertaling.exportName'),
                searchTooltip: t('vertaling.searchTooltip'),
                searchPlaceholder: t('vertaling.searchTooltip'),
                exportCSVName: t('vertaling.exportName'),
                exportPDFName: t('vertaling.exportPDFName'),
              },
            }}
          />
        </div>
      <br />
    </div>
  );
};

export default BatchesV2;