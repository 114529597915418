// Importeren van React packages en bibliotheken en andere React componenten

import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '../../utils/PageTitle';
import GoBackButton from "../../components/FormComponents/GoBackButton";

const Addregistration = () => {
    PageTitle("Add Registration");
    const navigate = useNavigate();
    const { t } = useTranslation();

    // Hier leggen we een referentie naar het aanmaken van een wijngaardregistratie en kelderregistratie

    return (
        <div style={{ marginTop: '90px' }}>
            <h1>{t("vertaling.title_addregis")}</h1>
            <GoBackButton />
            <br />
            <div
                style={{
                    marginTop: '70px',
                    position: 'absolute',
                    left: '50%',
                    transform: 'translateX(-47%)',
                }}
            >
                <Link to="/vineyardsRegistration" style={{ color: 'white' }}>
                    <button
                        className="ui primary button login"
                        style={{ float: 'none', marginRight: '20px' }}
                    >
                        {t("vertaling.vineyard_regis")}
                    </button>
                </Link>
                <Link to="/cellarsRegistration" style={{ color: 'white' }}>
                    <button
                        className="ui primary button login"
                        style={{ float: 'none', marginLeft: '20px' }}
                    >
                        {t("vertaling.cellar_regis")}
                    </button>
                </Link>
            </div>
            <br />
        </div>
    );
};

export default Addregistration;