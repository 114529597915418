import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import DashboardButton from '../../../components/Dashboard/Button'
import Divider from '../../../components/Dashboard/Divider'
import { Roles } from '../../../logic/Roles'

const AdminButtons = ({ userPermissionLevel }) => {
    const [hidden, setHidden] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        let element = document.querySelector(".adminButtons");
        element.childNodes.length === 0 ? setHidden(true) : setHidden(false);
    });


    return (
        <>
            <Divider title={"Admin"} hidden={hidden} />
            <div className="adminButtons row" style={{ margin: "0", width: "100%" }}>
                <DashboardButton
                    url={'/vineyardsteps/myvineyardsteps'}
                    imgAlt={"dashboard-icon"}
                    imgSrc={"/images/DashboardIcons/Vineyards.svg"}
                    title={t("translation.vineyardSteps.myVineyardSteps")}
                    text={t("translation.vineyardSteps.myVineyardStepsDescription")}
                    userPermissionLevel={userPermissionLevel}
                    btnPermissionLevel={Roles().ADMIN.permissionLevel}
                />

                <DashboardButton
                    url={'/harvestSteps/myHarvestSteps'}
                    imgAlt={"dashboard-icon"}
                    imgSrc={"/images/DashboardIcons/Harvest.svg"}
                    title={t("translation.myHarvestSteps.myHarvestSteps")}
                    text={t("translation.myHarvestSteps.myHarvestStepsDescription")}
                    userPermissionLevel={userPermissionLevel}
                    btnPermissionLevel={Roles().ADMIN.permissionLevel}
                />


                <DashboardButton
                    url={'/cellarsteps/mycellarsteps'}
                    imgAlt={"dashboard-icon"}
                    imgSrc={"/images/DashboardIcons/Cellar.svg"}
                    title={t("translation.cellarSteps.myCellarSteps")}
                    text={t("translation.cellarSteps.myCellarStepsDescription")}
                    userPermissionLevel={userPermissionLevel}
                    btnPermissionLevel={Roles().ADMIN.permissionLevel}
                />
            </div>
        </>
    )
}

export default AdminButtons