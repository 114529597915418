import React from 'react';
import { ChemicalsExcelImporter } from '../../components/ImporterProcessor/ChemicalsImporterProcessor';
import { useTranslation } from 'react-i18next';
import GoBackButton from '../../components/FormComponents/GoBackButton';

const ChemicalsImporter = () => {
  const { t } = useTranslation();
  return (
    <div style={{ padding: '20px' }}>
    <h1 style={{ color: "#7f7f7f" }}>
      {t('translation.excelImportPage.excelImport')}
    </h1>
    <GoBackButton />
    <p style={{ color: "#7f7f7f" }}>
      {t('translation.excelImportPage.source')}
    </p>
    <p style={{ color: "#7f7f7f" }}>
      {t('translation.excelImportPage.stepsBeforeUpload')}
    </p>

    <ChemicalsExcelImporter />
  </div>
  );
};

export default ChemicalsImporter;
