import { getFirestore, doc, collection, onSnapshot } from 'firebase/firestore';  // Firebase v9 imports
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { firestore } from '../../firebase/config';
import { createVineyardVarietyDoc } from '../../firebase/vineyard';
import { PageTitle } from '../../utils/PageTitle';
import { useSessionStorage } from '../../logic/useSessionStorage';
import GoBackButton from "../../components/FormComponents/GoBackButton";
import { useTranslation } from 'react-i18next';

const CreateGrapeVariety = () => {
  PageTitle("Create Grape Variety");
  const navigate = useNavigate();
  const params = useParams();
  const { register, handleSubmit } = useForm();
  const [isLoading, setLoading] = useState([]);
  const [selectedPruningId, setSelectedPruningId] = useState('');
  const [pruningmethods, SetPruningMethods] = useState([]);
  const [vineyardDocument, setVineyardDocument] = useState([]);
  const [availableArea, setAvailableArea] = useState(0);
  const [availableAreahtmlHa, setShowAvailableAreaHa] = useState(0);
  const [availableAreahtmlCa, setShowAvailableAreaCa] = useState(0);
  const [showErrorMethod, setShowErrorMethod] = useState('hidden');
  const { t } = useTranslation();

  const [currentWinery, setCurrentWinery] = useSessionStorage(
    'currentwinery',
    ''
  );
  var areaUsed = 0;
  var totalArea = 0;
  let help = 10000;

  useEffect(() => {
    setLoading(true);
    const pruningRef = collection(firestore, 'pruningmethod');

    setLoading(true);

    const docRef = doc(firestore, 'winegrowers', currentWinery, 'vineyards', params.id);

     const unsubscribe = onSnapshot(docRef, (docSnapshot) => {
      if (doc.exists) {
        const docData = doc.data();
        setVineyardDocument(docData);
        totalArea = docData.perceel_opp_ca + docData.perceel_opp_ha * help;
      }

    });

      //pruning methods
      const unsubscribePruning = onSnapshot(pruningRef, (querySnapshot) => {
        const pruningmethod = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        SetPruningMethods(pruningmethod);

      });
        const varietyRef = collection(firestore, 'winegrowers', currentWinery, 'vineyards', params.id, 'varieties');

        if (varietyRef != null) {
          const unsubscribeVarieties = onSnapshot(varietyRef, (querySnapshot) => {
            const varities = querySnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            for (var i = 0; i < varities.length; i++) {
              areaUsed +=
                varities[i].perceel_opp_ha * help + varities[i].perceel_opp_ca;
            }
            setAvailableArea(totalArea - areaUsed);
            setLoading(false);
          });
        }
        return () => {
          unsubscribe();
          unsubscribePruning();
        };
  }, []);

  //submitting form
  const onSubmit = async (data) => {
    try {
      setLoading(true);
      if (selectedPruningId === '') {
        setShowErrorMethod('show');
        setLoading(false);
      } else {
        setShowErrorMethod('hidden');
        data.pruningmethod = selectedPruningId;
        data.winegrower = currentWinery;
        await createVineyardVarietyDoc({ uid: params.id, ...data });
        setLoading(false);
        navigate(`/myvineyards`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const showAvailableArea = () => {
    const result =
      availableArea - availableAreahtmlHa * help - availableAreahtmlCa;
    return result;
  };

  //Pruningmethod selected
  const handlePruningChange = (selection, action) => {
    setSelectedPruningId(selection.value);
  };

  const formClassname = `ui big form twelve wide column ${isLoading ? 'loading' : ''
    } `;

  return (
    <div
      className="add-form-container"
      style={{ maxWidth: 960, margin: '50px auto', marginTop: '90px' }}
    >
      <h1>{t("vertaling.grapevariety_title")}</h1>
      <GoBackButton />
      <br />
      <div
        className="ui grid stackable"
        style={{ justifyContent: 'center', marginTop: '-50px' }}
      >
        <form
          className={formClassname}
          style={{
            border: '3px solid #B3B3B3',
            marginTop: '100px',
            borderRadius: '50px',
            background: '#edeaea',
            borderColor: '#ac9e66',
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="fields" style={{ justifyContent: 'center' }}>
            <div className="twelve wide field">
              <label>
                <p>{t("vertaling.name")}</p>
                <input
                  type="text"
                  name="name"
                  htmlFor="name"
                  required="required"
                  disabled="true"
                  defaultValue={vineyardDocument.name}
                />
              </label>
            </div>
          </div>
          <div className="fields" style={{ justifyContent: 'center' }}>
            <div className="twelve wide field">
              <label>
                <p>{t("vertaling.grapevariety")}</p>
                <input
                  type="text"
                  {...register('grapevariety')}
                  htmlFor="grapevariety"
                  required="required" />
              </label>
            </div>
          </div>
          <div className="fields" style={{ justifyContent: 'center' }}>
            <div className="twelve wide field">
              <label>
                <p>{t("vertaling.planting_year")}</p>
                <input
                  type="number"
                  {...register('plantingyear')}
                  htmlFor="plantingyear"
                  required="required" />
              </label>
            </div>
          </div>
          <div className="fields" style={{ justifyContent: 'center' }}>
            <div className="twelve wide field">
              <label>
                <p>{t("vertaling.perceel_deel_opp")}</p>
                {showAvailableArea() >= 0 ? (
                  <p>available: {showAvailableArea()} ca</p>
                ) : (
                  <p style={{ color: 'red' }}>U hebt het limiet bereikt!</p>
                )}
                <div className="flex-row">
                  <input
                    style={{ marginRight: '10px' }}
                    type="number"
                    {...register('perceel_opp_ha')}
                    htmlFor="perceel_opp_ha"
                    required="required"
                    min="0"
                    step="1"
                    placeholder="ha"
                    onChange={(e) =>
                      setShowAvailableAreaHa(
                        e.target.value,
                        showAvailableArea()
                      )
                    } />
                  <input
                    type="number"
                    {...register('perceel_opp_ca')}
                    htmlFor="perceel_opp_ca"
                    required="required"
                    min="0"
                    step="1"
                    placeholder="ca"
                    onChange={(e) =>
                      setShowAvailableAreaCa(
                        e.target.value,
                        showAvailableArea()
                      )
                    } />
                </div>
              </label>
            </div>
          </div>
          <div className="fields" style={{ justifyContent: 'center' }}>
            <div className="twelve wide field">
              <label>
                <p>{t("vertaling.tendrils")}</p>
                <input
                  type="number"
                  {...register('tendrils')}
                  htmlFor="tendrils"
                  min="1"
                  required="required" />
              </label>
            </div>
          </div>
          <div className="fields" style={{ justifyContent: 'center' }}>
            <div className="twelve wide field">
              <label>
                <p>{t("vertaling.pruningmethod")}</p>
                <Select
                  required
                  placeholder={t('vertaling.selectpruningmethod')}
                  options={pruningmethods.map((name, id) => ({
                    value: name.id,
                    label: t(`vertaling.${name.name.replace(/\s/g, '')}`)
                  }))}
                  onChange={(selection, action) =>
                    handlePruningChange(selection, action)
                  }
                  className="specialty"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 5,
                    colors: {
                      ...theme.colors,
                      primary25: '#dacfa4b2',
                      primary: '#AC9E66',
                    },
                  })}
                />
              </label>
              <p className={showErrorMethod + ' errorMethod'}>
                {t("vertaling.errorPruningMethod")}
              </p>
            </div>
          </div>
          <button
            type="submit"
            disabled={showAvailableArea() >= 0 ? false : true}
            className="ui submit large grey button right floated button-vineyards"
            style={{ float: 'none', marginTop: '30px', width: '25%' }}
          >
            {t("vertaling.createvineyardvariety")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateGrapeVariety;