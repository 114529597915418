// Importeren van React packages en bibliotheken en andere React componenten

import React from 'react';
import { useTranslation } from 'react-i18next';
import 'firebase/auth';
import { PageTitle } from '../utils/PageTitle';

const MobileApps = () => {
  PageTitle("Mobile Apps");
  const { t } = useTranslation();
  return (
    <div>
      <h1>{t("vertaling.gotoapp")}</h1>
      <div className="app_links">
        <a href="https://apps.apple.com/be/app/craft-your-taste-by-tastefever/id1552749889?l=nl" target="blank">
          <img src="../images/appstore.png" alt='appstore logo'></img>
        </a>
        <a href="https://play.google.com/store/apps/details?id=be.exanteit.tastefever" target="blank">
          <img src="../images/googleplay.png" alt='googleplay logo'></img>
        </a>
      </div>
    </div>
  );
};

export default MobileApps;