import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { firestore } from "../../firebase/config";
import { useSessionStorage } from "../../logic/useSessionStorage";
import GoBackButton from "../../components/FormComponents/GoBackButton";
import { useNavigate } from "react-router-dom";
import { collection, onSnapshot } from 'firebase/firestore';
import MaterialTable from '@material-table/core';

const SelectAgriculturalParcelsNewHarvest = () => {
  const [agriculturalParcels, setAgriculturalParcels] = useState([]);
  const [vineyards, setVineyards] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [currentWinery, setCurrentWinery] = useSessionStorage(
    "currentwinery",
    ""
  );

  //let currentAgriculturalParcels;
  useEffect(() => {
    const agriculturalParcelRef = collection(firestore, "winegrowers", currentWinery, "agriculturalParcels");
    const vineyardRef = collection(firestore, "winegrowers", currentWinery, "vineyards");

    const unsubscribeAgricultural = onSnapshot(agriculturalParcelRef, (snapshot) => {
      const agriculturalParcelsList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAgriculturalParcels(agriculturalParcelsList);
    });

    const unsubscribeVineyard = onSnapshot(vineyardRef, (snapshot) => {
      let vineyardList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setVineyards(vineyardList);
    });

    return () => {
      unsubscribeAgricultural();
      unsubscribeVineyard();
    };
  }, [currentWinery]);


  if (agriculturalParcels.length !== 0 && vineyards.length !== 0) {
    agriculturalParcels.forEach((agriculturalParcel) => {
      agriculturalParcel.vineyard = vineyards.find(
        (vineyard) => vineyard.id === agriculturalParcel.vineyardId
      ).name;
    });
  }

  const handleRowSelectionChange = (rows) => {
    let arraySelectedRowsId = [];
    for (let i = 0; i < rows.length; i++) {
      arraySelectedRowsId.push(rows[i].id);
    }

    setSelectedRows(arraySelectedRowsId);
  };

  const handleOnSubmit = () => {
    let params = new Object(selectedRows);
    params = JSON.stringify(params);
    navigate(`/harvestRegistration/addHarvestRegistration/${params}`);
  };

  return (
    <>
      <div style={{ marginTop: "30px" }}>
            <GoBackButton location={"/"}/>
          <h3 style={{ color: "#7f7f7f" }}>
          {t('translation.vineyardRegistration.selectParcel')}
      </h3>

      <div style={{ maxWidth: "960px", margin: "auto" }}>
          <MaterialTable
            title=""
            data={agriculturalParcels}
            columns={[
              {
                title: t('translation.name'),
                field: "name",
              },
              {
                title: t('translation.harvestRegistration.vineyard'),
                field: "vineyard",
              },
              {
                title: t('translation.numberOfPlants'),
                field: "numberOfPlants",
              },
            ]}
            onSelectionChange={(rows) => handleRowSelectionChange(rows)}
            options={{
              selection: true,
              headerStyle: {
                backgroundColor: "#cecaca",
                color: "#FFF",
                textAlign: "left",
              },
              filterCellStyle: {
                alignContent: "left",
              },
              emptyRowsWhenPaging: false,
              paging: true,
              pageSize: 50,
              pageSizeOptions: [
                5,
                10,
                20,
                25,
                50,
              ],
            }}
          />
          <button
            onClick={handleOnSubmit}
            className="ui submit large grey button right floated  button-vinyards"
            style={{
              float: "none",
              marginTop: "30px",
              marginBottom: "30px",
              width: "25%",
            }}
          >
            {t('translation.words.next')}
          </button>
        </div>

      </div>
    </>

  );
};

export default SelectAgriculturalParcelsNewHarvest;